import React from 'react'
import { Link } from "react-router-dom"
const BARTH164Update = () => {
  return (
    <>
      <div className='container-fluid p-2 '>
        <div className='row'>
          <p className='bar1-para'>BAR-TH-164 : Chaudière biomasse individuelle</p>
          <div className='button-1 mt-1'>
            <Link to={"/update-calcult-barth164"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
          </div>
          <div className='col bar1-tittle'>
            <div className='bar1-tittle'>
              <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                <h3 className='bar1-head'>1. Secteur d’application</h3>
                <p className='bar1-para'>Maison individuelle existante en France métropolitaine.</p>
                <h3 className='bar1-head'>2. Dénomination</h3>
                <p className='bar1-para'>Rénovation thermique globale d’une maison individuelle existant</p>
                <p className='bar1-para'>L’approche globale consiste à déterminer et à mettre en œuvre un bouquet de travaux optimal sur le plan technico-
                  économique.</p>
                <p className='bar1-para'>Cette opération n’est pas cumulable avec d’autres opérations pouvant donner lieu à la délivrance de certificats
                  d'économies d'énergie pour des travaux concernant le chauffage, la production d’eau chaude sanitaire, la ventilation,
                  l’isolation de l’enveloppe de la maison ou les systèmes d’automatisation et de contrôle du bâtiment. </p>
                <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
                <p className='bar1-para'>Pour les opérations engagées jusqu’au 31 décembre 2020, pour chaque catégorie de travaux intégrée dans le projet
                  de  rénovation  globale  et  mentionnée  au  I  de  l'article  46  AX  de  l'annexe  III  au  code  général  des  impôts,  le
                  professionnel ayant réalisé l’opération est titulaire :<br></br>
                  <span className='ms-4 '>- soit d'une certification « Offre globale » conformément au deuxième tiret de l’article 1 et à l’annexe II de l’arrêté
                    du 1er décembre 2015 relatif aux critères de qualifications requis pour le bénéfice du crédit d'impôt pour la transition
                    énergétique  et  des  avances  remboursables  sans  intérêt  destinées  au  financement  de  travaux  de  rénovation  afin
                    d'améliorer la performance énergétique des logements anciens ;</span><br></br>
                  <span className='ms-4'>- soit d’un signe de qualité conforme aux exigences prévues à l’article 2 du décret n° 2014-812 du 16 juillet 2014
                    pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des impôts et du dernier alinéa
                    du 2 du I de l’article 244 quater U du code général des impôts et correspondant à la catégorie de travaux considérée. </span>
                </p>
                <p className='bar1-para'>Pour les opérations engagées à compter du 1er janvier 2021, pour chaque catégorie de travaux intégrée dans le projet
                  de rénovation globale et mentionnée aux 1° à 16° du I de l’article 1er du décret n° 2014-812 du 16 juillet 2014 pris
                  pour l’application du second alinéa du 2 de l’article 200 quater du code général des impôts et du dernier alinéa du 2
                  du I de l’article 244 quater U du code général des impôts, le professionnel réalisant l’opération est titulaire d’un
                  signe de qualité conforme aux exigences prévues à l’article 2 du même décret et dans les  textes  pris  pour  son
                  application. Ce signe de qualité correspond à des travaux relevant soit du 17° du I de l'article 1er du décret précité,
                  soit de l’une des catégories mentionnées aux 1° à 16° du I du même décret correspondant aux travaux réalisés.</p>
                <p className='bar1-para'>Une  étude  énergétique  est  réalisée,  préalablement  aux  travaux  de  rénovation  thermique  du  bâtiment,  par  un
                  prestataire remplissant les conditions du II de l’article 1er du décret n°2018-416 du 30 mai 2018 relatif aux conditions
                  de qualification des auditeurs. Cette étude énergétique respecte les dispositions du II de l’article 18 bis de l’annexe
                  4 du code général des impôts dans sa rédaction en vigueur au 15 février 2020.</p>
                <p className='bar1-para'>Le  logiciel  de  calcul  permettant  cette  étude  énergétique  est  adapté  à  une  maison  individuelle. Il dispose d’un
                  référentiel technique écrit, d’un numéro de version, d’une date et d’une durée de validité. Il utilise un moteur de
                  calcul réglementaire ou est validé par le CSTB, le CEREMA ou un autre bureau d’étude indépendant à l’issue d’une évaluation  technique  sur  des  cas-types  représentatifs  du  parc  français  de  maisons  individuelles  et  aux  frais  de
                  l’éditeur. La demande de validation est effectuée auprès de la DGEC et de l’ADEME par l’éditeur du logiciel. Cette
                  demande doit comporter le référentiel technique écrit, le numéro de version, la date et la durée de validité du logiciel,
                  ainsi que le rapport de test réalisé par le CSTB, le CEREMA ou un autre bureau d’études indépendant. Ce rapport
                  décrit les cas-types utilisés, et compare les résultats obtenus avec le moteur de calcul réglementaire. </p>
                <p className='bar1-para'>La liste des logiciels réputés satisfaire aux exigences de la présente fiche est rendue publique sur le site Internet du
                  ministère chargé de l’énergie, à l’adresse suivante : <a href='https://www.ecologie.gouv.fr/questions-reponses-sur-dispositif-cee'>https://www.ecologie.gouv.fr/questions-reponses-sur-dispositif-cee.</a> </p>
                <p className='bar1-para'>Les  travaux  permettent  d'atteindre  une  performance  énergétique  globale  minimale  du  logement,  déterminée  par
                  l’étude énergétique, qui satisfait aux deux critères suivants :<br></br>
                  <span className='ms-4'>- consommation conventionnelle annuelle en énergie primaire après travaux, rapportée à la surface habitable de la
                    maison, inférieure à 331 kWh/m².an sur les usages chauffage, refroidissement et production d’eau chaude sanitaire ;</span><br></br>
                  <span className='ms-4'>– gain énergétique d’au moins 35 % par rapport à la consommation conventionnelle annuelle en énergie primaire
                    avant travaux pour les trois usages définis ci-dessus.</span><br></br>

                </p>
                <p className='bar1-para'>Les émissions annuelles de gaz à effet de serre après rénovation, rapportée à la surface habitable de la maison, sont
                  inférieures ou égales à la valeur initiale de ces émissions avant travaux. Les données utilisées pour les hypothèses
                  de  calcul  du  contenu  carbone  sont  celles  de  la  Base  Carbone®  de l’ADEME hébergée à l’adresse suivante :
                  <a href='https://www.presentation-design.fr/'> www.bilans-ges-ademe.fr</a>.</p>
                <p className='bar1-para'>La preuve de réalisation de l’opération mentionne la liste des travaux réalisés avec leurs niveaux de performance.</p>

                <p className='bar1-para'>Les documents justificatifs spécifiques à l’opération sont :<br></br>
                  <span className='ms-4'>– la synthèse de l’étude énergétique ainsi que sa mise à jour éventuelle, précisant les données de consommations
                    conventionnelles  annuelles  en  énergie  primaire  et  en  énergie  finale,  avant  et  après  travaux  ainsi  que  le  gain
                    énergétique  apporté  par  la  rénovation  du  bâtiment  et  les  quantités  annuelles  de  gaz  à  effet  de  serre  émis  dans
                    l'atmosphère avant et après travaux du fait de la quantité d'énergie consommée, rapportés à la surface habitable de
                    la  maison.  Cette  synthèse  précise  le  nom  du  logiciel de  calcul  utilisé dans le cadre de l’opération, ainsi que son
                    numéro de version ; </span><br></br>
                  <span className='ms-4'>– la liste des travaux préconisés avec leurs niveaux de performance et la correspondance avec la liste des travaux
                    réalisés, datée et signée par le bénéficiaire, le professionnel ayant réalisé l’étude énergétique et chaque professionnel
                    mettant en œuvre ou assurant la maîtrise d’œuvre de tout ou partie de l’opération, permettant d’atteindre les
                    performances énergétiques requises ;</span><br></br>
                  <span className='ms-4'>– la liste des entreprises ayant effectué les travaux de rénovation en indiquant la nature de ces travaux et la référence
                    de leur qualification ou certification lorsque celle-ci est requise. </span><br></br> </p>
                <p className='bar1-para'>La synthèse de l’étude énergétique ainsi que sa mise à jour éventuelle, sont datées et signées par le professionnel
                  les ayant réalisées. Elles comportent les mentions des valeurs suivantes :<br></br>
                  <span className='ms-4'>– la  consommation  conventionnelle  (en  kWh/m².an)  de  la  maison  (sans déduction de la production d’électricité
                    autoconsommée ou exportée), en précisant les usages considérés : <br></br>
                    <span className='ml-30'>–     d’énergie primaire, avant les travaux de rénovation : Cep initial ; </span><br></br>
                    <span className='ml-30'>–     d’énergie primaire, après les travaux de rénovation : Cep projet ; </span><br></br>
                    <span className='ml-30'>–     d’énergie finale, avant les travaux de rénovation : Cef initial ; </span><br></br>
                    <span className='ml-30'>–     d’énergie finale, après les travaux de rénovation : Cef projet ; </span><br></br>
                    <span className='ml-30'>–     d’énergie primaire, avant les travaux de rénovation : Cep initial ; </span><br></br>
                    <span className='ml-30'>–     le rejet de CO2 exprimé en kgeqCO2/m².an, avant les travaux de rénovation ;  </span><br></br>
                    <span className='ml-30'>–     le rejet de CO2 exprimé en kgeqCO2/m².an, après les travaux de rénovation. </span><br></br>

                  </span><br></br>
                  <span className='ms-4 mt-0'>– la surface habitable de la maison rénovée, exprimée en m² : Shab.  </span><br></br>
                </p> <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                  <span className='bar1-para'>30 ans.</span></h3><p className="bar1-head mt-4">5. Montant de certificats en kWh cumac</p> <div className="row">
                  <div className="col-lg-5 offset">
                    <table className="table table-bordered">
                      <thead>
                        <tr className='bar1-para'>
                          <th>(Cef initial – Cef projet) x S <sub>hab </sub>x 18 </th></tr>
                      </thead>
                    </table>
                  </div>
                </div>
                <p className="bar1-para mt-4">(Cef  initial  –  Cef  projet)  est  la  différence  entre  la  consommation  conventionnelle  initiale  (Cef  initial)  et  la
                  consommation  conventionnelle  du  projet  de  rénovation  (Cef  projet)  en  énergie  finale,  rapportée  à  la  surface
                  habitable de la maison, respectivement avant et après travaux (exprimée en kWh/m².an), calculées avec le même
                  logiciel,  référencé  par  la  présente  fiche  (et  sans  déduction de  la  production d’électricité  autoconsommée  ou
                  exportée). </p>
                <p className="bar1-para mt-4">S<sub>hab</sub> est la surface habitable (exprimée en m²) de la maison rénovée. </p>

                <div className='bar1-content mt-4 pb-4'>
                  <p className='bar1-para para-bar '>Fourniture et pose par un professionnel RGE d’un procédé d’isolation en comble perdu ou en rampant de toiture dans un bâtiment résidentiel existant. Un pare-vapeur (ou équivalent) est posé pour protéger l’isolant thermique de l'humidité et de la condensation. </p>
                  <p className="bar1-head para-bar1 ">Éligible si : </p>
                  <ul className='ul-bar1'>  <li><span className='bar1-ul '>Le devis : </span>comporte la date de la pré-visite technique.</li>
                    <li><span className='bar1-ul'>Le début des travaux</span> respecte un délai<span className='bar1-ul'> de 8 jours après la date de signature</span> de la commande.</li>
                    <li><span className='bar1-ul'>L’isolant :</span> a une résistance thermique ≥ à 7 m².K/W en comble perdu et ≥ à 6 m².K/W en rampant de toiture, est certifié ACERMI (ou équivalent), est de classe A de protection au feu (ou équivalent ou pose d’une couche supplémentaire).</li>
                    <li><span className='bar1-ul'>Sont installés :</span>  un coffrage autour des conduits de fumées et des éclairages encastrés ; une rehausse rigide au niveau de la trappe d’accès.</li></ul>
                  <p className='bar1-para para-bar1'><span className='bar1-head '>La facture indique obligatoirement :</span> fourniture et pose d’une isolation de comble ou toiture, marque, référence, épaisseur, résistance thermique, n° ACERMI et surface réellement isolée (+ l’identité du sous-traitant, si c’est le cas), les aménagements réalisés, la présence d’un pare-vapeur + la date de pré-visite technique. </p>
                  <p className='bar1-para para-bar1'><span className='bar1-head '>Contrôle post-chantier :</span> tous les chantiers font l’objet d’un questionnaire de satisfaction client, certains font l’objet d’une inspection qualité post-travaux par un organisme indépendant (attesté COFRAC).</p>
                  <p className='bar1-para para-bar1'><span className='bar1-head '>Attention :</span> Sans modification sous 5 ans, cette opération sera abrogée à partir du 1er mai 2027.</p>
                  <p className='bar1-para para-bar1'><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime. En cliquant sur le Info , vous obtenez plus de détail sur une question.</p> </div>
                {/* <div className='button-1'>
                <button type='button' className='btn-on' >Site du Ministère <TbMathGreater /> </button>
              </div>
              <div className='button-1 mb-3'>
                <button type='button' className='btn-on' ><AiOutlineDownload />Télécharger le fiche </button>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BARTH164Update