import React from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { MdDeleteSweep } from "react-icons/md";
import { Link } from "react-router-dom"
import axios from 'axios'
import { useEffect, useState, useRef } from 'react';
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from 'react-toastify';
// XLSX
import * as XLSX from 'xlsx';
// DataTables
import $ from 'jquery'; // Import jQuery
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net/js/jquery.dataTables.min.js';

function AllDataShow() {
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toastsuccess = (val) => toast.success(val);
  const toasterror = (val) => toast.error(val);

  const Token = localStorage.getItem('Token');
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/getall-multistep`, {
        headers:
          { authorization: Token }
      }).then(res => {
        console.log(res?.data);
        setData(res?.data);
        setIsLoading(false);
      })
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }
  const tableRef = useRef(null);

  useEffect(() => {
    if (!isLoading) {
      const table = $(tableRef.current).DataTable({
        language: {
          search: 'éléments',
        }
      }); return () => {
        table.destroy();
      };
    }
  }, [isLoading]);

  if (isLoading) {
    return <Spinner animation="border" className='text-success' />;
  };
  // DELETE
  const handleDelete = async (id) => {
    console.log('id', id);
    try {
      if (window.confirm("Do You Want to Delete?")) {
        const deleteresponse = await axios.delete(`${process.env.REACT_APP_API_URL}/delete-multistep/${id}`,
          {
            headers: { authorization: Token },
          }
        );
        if (deleteresponse?.status === 200) {
          fetchData();
          toastsuccess(deleteresponse?.data?.message);
        };
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  // MULTI DELETE
  const handleCheckboxChange = (id) => {
    console.log('id:', id);
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allItemIds = data?.AllData?.map((item) => item?.multistep1?.id);
      setSelectedIds(allItemIds);
    }
    setSelectAll(!selectAll);
  };
  // MULTI DELETE
  const handleMultiDelete = async () => {
    try {
      if (selectedIds.length > 0 && window.confirm("Voulez-vous supprimer les éléments sélectionnés ?")) {
        const multidelete = await axios.post(
          `${process.env.REACT_APP_API_URL}/multi-delete-multistep`,
          { ids: selectedIds },
          {
            headers: { authorization: Token },
          }
        );
        if (multidelete?.status === 200) {
          toastsuccess(multidelete?.data?.message);
          fetchData();
        }
      } else {
        toasterror("Veuillez sélectionner au moins un élément à supprimer");
        // alert("Please select at least one item to delete.");
      }
    } catch (error) {
      toasterror(error?.multidelete?.data?.message);
    }
  };
  // EXPORT DATA
  const exportToExcel = async () => {
    console.log(selectedIds);
    try {
      if (selectedIds.length === 0) {
        toasterror("Veuillez sélectionner au moins un élément à exporter");
        return;
      }

      const getSingleData = await axios.post(
        `${process.env.REACT_APP_API_URL}/get-all-specific-multistep`, { ids: selectedIds },
        {
          headers: { authorization: Token },
        }
      );
      if (getSingleData?.status === 200) {
        console.log(getSingleData);
        const selectedData = getSingleData?.data?.AllData;
        console.log(selectedData);

        const formattedData = selectedData?.map(item => {
          return {
            "service sélectionné": item.multistep1.serviceselect,
            "Bénéficiaire raisonsocial": item.multistep1.beneficiary_socialreason,
            "beneficiary nomcommercial": item.multistep1.beneficiary_nomcommercial,
            "beneficiaire nsiret": item.multistep1.beneficiary_nsiret,
            "nom représentant bénéficiaire": item.multistep1.beneficiary_representativename,
            "prénom bénéficiaire": item.multistep1.beneficiary_firstname,
            "bénéficiaire safonction": item.multistep1.beneficiary_hisfunction,
            "Adresse du bénéficiaire": item.multistep1.beneficiary_address,
            "beneficiaire cp": item.multistep1.beneficiary_cp,
            "ville bénéficiaire": item.multistep1.beneficiary_city,
            "email bénéficiaire": item.multistep1.beneficiary_email,
            "bénéficiaire nfixe": item.multistep1.beneficiary_nfixed,
            "bénéficiaire nMobile": item.multistep1.beneficiary_nMobile,
            "adresse chantier": item.multistep1.chantier_address,
            "chantier_cp": item.multistep1.chantier_cp,
            "ville de chantier": item.multistep1.chantier_city,
            "nom du quartier du chantier": item.multistep1.chantier_wardname,
            "choisir le type de chantier": item.multistep1.chantier_choosetype,
            "chantier grande précarité": item.multistep1.chantier_greatprecariousness,
            "chantier précaire": item.multistep1.chantier_precarious,
            "Code arrondissement chantier": item.multistep1.chantier_districtCode,
            "contact chantier": item.multistep1.chantier_contact,
            "téléphone de chantier": item.multistep1.chantier_telephone,
            "bâtiment de type chantier": item.multistep1.chantier_typeofbuilding,
            "bâtiment de l'âge de chanti": item.multistep1.chantier_ageofbuilding,
            "mode chauffage chantier": item.multistep1.chantier_heatingmode,
            "surface au sol du bâtiment du chantier": item.multistep1.chantier_buildingfloorarea,
            "opérations b2b": item.multistep1.b2boperations,
            "Dossier No": item.multistep1.documents,

            "adresse estimée": item.multistep1.estimate_address,
            "montant estimé de la prime bénéficiaire": item.multistep1.estimate_amountofbeneficiarypremium,
            "date de présence estimée _et_ signature du client": item.multistep1.estimate_attendancedate_and_signatureofthecustomer,
            "devis l'adresse du bénéficiaire": item.multistep1.estimate_beneficiaryaddress,
            "devis email bénéficiaire": item.multistep1.estimate_beneficiaryemail,
            "devis le prénom du bénéficiaire": item.multistep1.estimate_beneficiaryfirstname,
            "devis la fonction bénéficiaire": item.multistep1.estimate_beneficiaryfunction,
            "devis le nom du bénéficiaire": item.multistep1.estimate_beneficiaryname,
            "devis bénéficiaire téléphone": item.multistep1.estimate_beneficiaryphone,
            "devis bénéficiaire siret": item.multistep1.estimate_beneficiarysiret,
            "marque d'devis": item.multistep1.estimate_brand,
            "ville devis": item.multistep1.estimate_city,
            "devis cp": item.multistep1.estimate_cp,
            "devis bon pour accord": item.multistep1.estimate_goodforagreement,
            "date_devis_manuscrite": item.multistep1.estimate_handwrittendate,
            "devis identité du bénéficiaire": item.multistep1.estimate_identityofthe_beneficiary,
            "devis la conformité au rôle incitatif": item.multistep1.estimate_incentiverole_compliance,
            "devis les coordonnées de l'installateur": item.multistep1.estimate_installer_contactdetails,
            "devis la classe d'isolation": item.multistep1.estimate_insulationclass,
            "estimation mention de ce rôle incitatif": item.multistep1.estimate_mentionofthe_incentiverole,
            "devis mentions exigées par la fiche d'opération": item.multistep1.estimate_mentionsrequiredby_theoperationsheet,
            "devis nsiret": item.multistep1.estimate_nsiret,
            "estimer les autres mentions matérielles obligatoires": item.multistep1.estimate_othermandatory_materialmentions,
            "devis téléphone": item.multistep1.estimate_phone,
            "lieu de travail devis": item.multistep1.estimate_placeofwork,
            "devis la présence du standard": item.multistep1.estimate_presenceofthestandard,
            "estimation du bon de commande non": item.multistep1.estimate_purchaseorderno,
            "date d'émission du devis d'estimation": item.multistep1.estimate_quoteissuedate,
            "estimation devis": item.multistep1.estimate_quoteno,
            "prime rai devis": item.multistep1.estimate_raipremium,
            "référence du devis": item.multistep1.estimate_reference,
            "devis monsieur installateur": item.multistep1.estimate_siretinstaller,
            "devis la raison sociale": item.multistep1.estimate_socialreason,
            "devis les coordonnées du sous-traitant": item.multistep1.estimate_subcontractor_contactdetails,
            "devis nom prénom": item.multistep1.estimate_surnamefirstname,
            "nom commercial estimé": item.multistep1.estimate_tradename,

            "devis marque": item.multistep1.estimate_marque,
            "devis classé de l'isolant": item.multistep1.estimate_classedelisolant,
            "devis les mentions matérielles": item.multistep1.estimate_materialmentions,

            "nom du bénéficiaire de la facture": item.multistep1.bill_beneficiaryname,
            "prénom du bénéficiaire de la facture": item.multistep1.bill_beneficiaryfirstname,
            "téléphone du bénéficiaire de la facture": item.multistep1.bill_beneficiaryphone,
            "facture bénéficiaire siret": item.multistep1.bill_beneficiarysiret,
            "facture cp": item.multistep1.bill_cp,
            "diamètre et épaisseur du bec": item.multistep1.bill_diameter_and_thicknesses,
            "facture identité du bénéficiaire": item.multistep1.bill_identityofthe_beneficiary,
            "facturer les coordonnées de l'installateur": item.multistep1.bill_installercontact_details,
            "date d'émission de la facture": item.multistep1.bill_invoiceissuedate,
            "facture numéro de facture": item.multistep1.bill_invoiceNumber,
            "facture tenant lieu de travaux": item.multistep1.bill_lieudes_travaux,
            "bill nsiret": item.multistep1.bill_nsiret,
            "feuille d'opération de facture": item.multistep1.bill_operationsheet,
            "facture de téléphone": item.multistep1.bill_phone,
            "devis de facture": item.multistep1.bill_quoteno,
            "installateur facture siret": item.multistep1.bill_siretinstaller,
            "facture raison sociale": item.multistep1.bill_socialreason,
            "facturer les coordonnées du sous-traitant": item.multistep1.bill_subcontractor_contactdetails,
            "nom commercial de la facture": item.multistep1.bill_tradename,
            "facture varate": item.multistep1.bill_vatrate,
            "adresse du bénéficiaire de la facture": item.multistep1.bill_beneficiaryaddress,
            "e-mail du bénéficiaire de la facture": item.multistep1.bill_beneficiaryemail,
            "fonction de bénéficiaire de la facture": item.multistep1.bill_beneficiaryfunction,
            "adresse de facture": item.multistep1.bill_address,
            "ville de facture": item.multistep1.bill_city,
            "attestation conforme au format ah": item.multistep1.attestation_ahformatcompliant,
            "attestation bénéficiaires signature et cachet": item.multistep1.attestation_beneficiarys_signatureandstamp,
            "cadre complet d'attestation": item.multistep1.attestation_complete_bframe,
            "attestation complète qbvsframe": item.multistep1.attestation_complete_qbvsframe,
            "attestation fFull cFrame": item.multistep1.attestation_fFull_cFrame,
            "attestation plein cadre": item.multistep1.attestation_full_aframe,
            "signature d'attestation et cachet": item.multistep1.attestation_signature_and_stamp,
            "commentaires": item.multistep1.comments,
            "état final": item.multistep1.final_status,
            // TABLE 2
            "opération bénéficiaire": item.multistep2.beneficiaryoperation,
            "énergie de chauffage": item.multistep2.heatingenergy,
            "fenêtres de toit": item.multistep2.roofwindows,
            "fenêtres ou portes-fenêtres": item.multistep2.windowsorpatiodoors,
            "pas d'appartement": item.multistep2.noofapartment,
            "bâtiment résidentiel": item.multistep2.residentialbuilding,
            "montant de la prime": item.multistep2.premiumamount,
            "industrie du bâtiment": item.multistep2.buildingindustry,
            "zone chauffée": item.multistep2.heatedzone,
            "début des travaux": item.multistep2.startofwork,
            "secteur tertiaire": item.multistep2.tertiarysector,
            "douze mois": item.multistep2.twelvemonths,
            "type de posture": item.multistep2.typeofpose,
            "plus de 2 ans": item.multistep2.morethan2years,
            "chauffé ou non chauffé": item.multistep2.heatedorUnheated,
            "système bms": item.multistep2.bmssystem,
            "système de GTB géré": item.multistep2.managedbmssystem,
            "nouveau système bms": item.multistep2.newbmssystem,
            "préoccupation de fonctionnement": item.multistep2.operationconcern,
            "signature": item.multistep2.signature,
            "Chaudière": item.multistep2.boiler,
            "réseau hydraulique": item.multistep2.hydraulicnetwork,
            "vieille isolation": item.multistep2.oldinsulation,
            "montant principal": item.multistep2.primeamount,
            "bâtiment tertiaire": item.multistep2.tertiary_building,
            "pommes de douche de classe ZZ": item.multistep2.classZZshowerheads,
            "classe ZZ non réglementé": item.multistep2.classZZunregulated,
            "pommes de douche de classe Z": item.multistep2.classZshowerheads,
            "classe Z non réglementé": item.multistep2.classZunregulated,
            "kilo d'eau": item.multistep2.kilowater,
            "nouveaux radiateurs": item.multistep2.newradiators,
            "petgp": item.multistep2.petgp,
            "partgpetp": item.multistep2.partgpetp,
            "Des jours après": item.multistep2.daysafter,

            "construction de plus de 2 ans": item.multistep2.buildingmorethan2years,
            "Code postal": item.multistep2.codepostal,
            "zone chauffée et zone non chauffée": item.multistep2.heatedareaandanunheated,
            "zone chauffée et zone non chauffée 2": item.multistep2.heatedareaandanunheated2,
            "commande": item.multistep2.order,
            "toiture": item.multistep2.roof,
            "surface": item.multistep2.surface,
            "isolation thermique": item.multistep2.thermalinsulation,
            "zone": item.multistep2.area,
            "obliger le nom": item.multistep2.oblige,
            "nom du partenaire": item.multistep2.partenaire,
            "surface oblige": item.multistep2.oblige_surface,
            "obliger Valeur Prix": item.multistep2.obligeValuePrice,
            "partenaire Valeur Prix": item.multistep2.partenaireValuePrice,
            "Type de hotte voisine": item.multistep2.neighborhoodType,
            "obliger la valeur cumac": item.multistep2.obligecumacvalue,
            "obliger le montant total de la prime": item.multistep2.obligetotalpremiumamount,
            "obliger la participation aux bénéfices": item.multistep2.obligeprofitshare,
            "obligeshareinstallerHT": item.multistep2.obligeshareinstallerHT,
            "obliger partager installateur HT": item.multistep2.obligeVAT,
            "obliger le prix total": item.multistep2.obligeTotalPrice,
            "obliger le bénéficiaire de la prime": item.multistep2.obligebeneficiarypremium,
            "valeur cumac partenaire": item.multistep2.partnercumacvalue,
            "montant total de la prime du partenaire": item.multistep2.partnertotalpremiumamount,
            "participation aux bénéfices des partenaires": item.multistep2.partnerprofitshare,
            "part partenaire installateur HT": item.multistep2.partnershareinstallerHT,
            "TVA partenaire": item.multistep2.partnerVAT,
            "prix total du partenaire": item.multistep2.partnertotalprice,
            "partenaire bénéficiaire prime": item.multistep2.partnerbeneficiarypremium,
            "date de signature du bénéficiaire sur AH": item.multistep2.beneficiarysignaturedateonAH,
            "date de signature du bénéficiaire le SH": item.multistep2.beneficiarysignaturedateonSH,
            "billDate": item.multistep2.billDate,
            "date de facture": item.multistep2.contributionframedate,
            "date de la visite du bureau de contrôle": item.multistep2.datecontrolofficevisit,
            "date d'estimation": item.multistep2.estimatedate,
            "date de signature de l'installateur sur AH": item.multistep2.installersignaturedateonAH,
            "date d'édition du devis": item.multistep2.quoteeditiondate,
            "date de signature du devis": item.multistep2.quotesignaturedate,
            "date de début de travail": item.multistep2.startdateofwork,
            "magasin de données valides": item.multistep2.validdatasstore,

            "adresse supplémentaire": item.multistep2.additionaladdress,
            "adresse de travail": item.multistep2.addressofwork,
            "marque": item.multistep2.brand,
            "marque v2": item.multistep2.brandv2,
            "ville": item.multistep2.city,
            "date d'engagement": item.multistep2.dateofcommitment,
            "date de preuve": item.multistep2.dateofproof,
            "référence de la facture": item.multistep2.invoicereference,
            "éclairage": item.multistep2.lighting,
            "luminaires": item.multistep2.luminaires,
            "modèle": item.multistep2.model,
            "modèle v2": item.multistep2.modelv2,
            "pas de nouvelles lumières": item.multistep2.noofnewlights,
            "pas de nouvelles lumières v2": item.multistep2.noofnewlightsv2,
            "référence": item.multistep2.reference,
            "référence v2": item.multistep2.referencev2,
            "les types": item.multistep2.types,
            "les types v2": item.multistep2.typev2,
            "type d'installation": item.multistep2.installation_type,

            "bâtiment de 2 ans": item.multistep2.building2yearsold,
            "chauffage hydraulique": item.multistep2.hydraulicheating,
            "installation": item.multistep2.installation,

            "adressedestravaux": item.multistep2.adressedestravaux,
            "adressedestravaux2": item.multistep2.adressedestravaux2,
            "e-mail b2c": item.multistep2.b2cemail,
            "surface de plancher du bâtiment": item.multistep2.buildingFloorArea,
            "choisissez Type": item.multistep2.chooseType,
            "cp de travaux": item.multistep2.cpdestravaux,
            "menages": item.multistep2.menages,
            "nfixe": item.multistep2.nfixe,
            "nfiscal": item.multistep2.nfiscal,
            "nmobile": item.multistep2.nmobile,
            "nom du client:": item.multistep2.nomduclient,
            "nreffiscal": item.multistep2.nreffiscal,
            "prénom du client": item.multistep2.prénomduclient,
            "avis d'imposition": item.multistep2.taxnotice,
            "ville des travaux": item.multistep2.villedestravaux,
            "adresse des travaux": item.multistep2.addressofworks,
            "rendez-vous b2c": item.multistep2.b2c_date,
            "initiale cef": item.multistep2.cef_initial,
            "projet cef": item.multistep2.cef_projet,
            "cèpe initiale": item.multistep2.cep_initial,
            "projet cep": item.multistep2.cep_projet,
            "référentiel de certification": item.multistep2.certification_reference,
            "Date d'engagement": item.multistep2.commitmentdate,
            "date de l'énergie": item.multistep2.date_of_energy,
            "gain d'énergie": item.multistep2.energy_gain,
            "référentiel d'étude énergétique": item.multistep2.energy_study_reference,
            "travail sur le terrain": item.multistep2.field_work,
            "prénom": item.multistep2.first_name,
            "serre": item.multistep2.greenhouse,
            "superficie pompe à chaleur": item.multistep2.heatpump_surface,
            "maison individuelle": item.multistep2.individual_house,
            "personnes morales": item.multistep2.legalpersons,
            "nom du représentant": item.multistep2.representative_name,
            "numéro de sirène": item.multistep2.siren_number,
            "numéro de siret": item.multistep2.siret_number,
            "raison social": item.multistep2.social_reason,
            "nom du logiciel": item.multistep2.software_name,
            "une version de logiciel": item.multistep2.software_version,
            "types de bâtiment": item.multistep2.typeOfBuilding,
            "partie classique": item.multistep2.partclassique,
            "mode de chauffage": item.multistep2.heatingMode,
            "marque de boîte": item.multistep2.box_brand,
            "référence du boîtier": item.multistep2.casing_reference,
            "date d'engagement": item.multistep2.date_of_commitment,
            "logements desservis": item.multistep2.dwellings_served,
            "classe énergétique": item.multistep2.energy_class,
            "échangeur thermique": item.multistep2.exchanger_thermal,
            "échangeur statique": item.multistep2.static_exchanger,
            "types d'installation": item.multistep2.type_installation,
            "référence unitaire": item.multistep2.unit_reference,
            "marque de soupape": item.multistep2.valve_brand,
            "cop": item.multistep2.cop,
            "type de logement": item.multistep2.housing_type,
            "nom": item.multistep2.nom,
            "prénom 1": item.multistep2.prénom,
            "nom de travail": item.multistep2.work_name,
            "basse température": item.multistep2.lowtemperature,
            "notes de dimensionnement": item.multistep2.sizing_note,
            "énergie saisonnière": item.multistep2.seasonal_energy,
            "type de pompe à chaleur": item.multistep2.heatpump_type,
            "étiquette chaudière 7": item.multistep2.boiler_7label,
            "opérateur de chaudière": item.multistep2.boiler_operated,
            "chaudières 225": item.multistep2.boilers_225,
            "réservoir tampon": item.multistep2.buffer_tank,
            "gazeux organique": item.multistep2.gaseous_organic,
            "oxydes d'azote": item.multistep2.nitrogen_oxides,
            "chaleur nominale": item.multistep2.rated_heat,
            "classe réglementaire": item.multistep2.regulator_class,
            "carbone saisonnier": item.multistep2.seasonal_carbon,
            "particules saisonnières": item.multistep2.seasonal_particulate,
            "note de dimensionnement": item.multistep2.sizingnote,
            "bois": item.multistep2.wood,

            "couverture_isolation": item.multistep2.cover_insulation,
            "couvre le système": item.multistep2.covers_system,
            "diamètre 20 à 65 V1": item.multistep2.diameter20to65V1,
            "diamètre 20 à 65 V2": item.multistep2.diameter20to65V2,
            "diamètre 66 à 100 V1": item.multistep2.diameter66to100V1,
            "diamètre 66 à 100 V2": item.multistep2.diameter66to100V2,
            "diamètre 101 à 150 V1": item.multistep2.diameter101to150V1,
            "diamètre 101 à 150 V2": item.multistep2.diameter101to150V2,
            "échangeur de chaleur V1": item.multistep2.heatexchangerV1,
            "échangeur de chaleur V2": item.multistep2.heatexchangerV2,
            "tuyaux isolés": item.multistep2.pipes_insulated,
            "résidentiel deux ans": item.multistep2.residentieltwoyears,
            "points singuliers": item.multistep2.singular_points,
          };
        });

        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'winenergycrm-export');

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'winenergy_data.xlsx';
        a.click();
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log(error?.message);
      toasterror(error?.message);
    }
  };

  return (
    <div className="container-fluid-fluid ">
      <div className="row black-bg m-1">
        <div className="col-lg-3">
          <div className='mt-3 ms-3 text-start'>
            <MdDeleteSweep className='icon_size ' title='Delete Selected Items' onClick={handleMultiDelete} />
          </div>
        </div>
        <div className='col-lg-9 d-flex justify-content-end'>
          <div className='mt-3 '>
            <p className='bar1-para  '>Afficher <span className="text-sizes ms-2 border-1px">50</span></p>
          </div>
          <div className='mt-3 ms-2 '>
            <p className='bar1-para '>éléments
              <input type="text" className=" text-sizes  ms-2" placeholder='Recherche' size="7" ></input></p>
          </div>
        </div>
      </div>

      <div className='table-responsive'>
        <table ref={tableRef} className="table">

          <thead>
            <tr>
              <th scope="col text-left"><input type='checkbox' checked={selectAll} onChange={handleSelectAllChange}></input></th>
              <th scope="col" className='bar1-para'>Operation</th>
              <th scope="col" className='bar1-para'>Date</th>
              <th scope="col" className='bar1-para'>Organisme</th>
              <th scope="col" className='bar1-para'>Numéro</th>
              <th scope="col" className='bar1-para'>Chiffre affaire</th>
              <th scope="col" className='bar1-para'>Total Cumac</th>
              <th scope="col" className='bar1-para'>Status</th>
              <th scope="col" className='bar1-para'>Edit</th>
              <th scope="col" className='bar1-para'>Delete</th>
            </tr>
          </thead>

          <tbody>
            {data?.AllData?.map((item, index) => (
              <tr key={item?.multistep1?.id} className='bg-light'>
                <th scope="row"><input type="checkbox" checked={selectedIds.includes(item?.multistep1?.id)} onChange={() => handleCheckboxChange(item?.multistep1?.id)} /></th>
                <td className='bar1-para'>{item?.multistep1?.serviceselect}</td>
                <td className='bar1-para'>{item?.multistep1?.createdAt.split('T')[0]}</td>
                <td className='bar1-para'>{item?.multistep1?.beneficiary_socialreason}</td>
                <td className='bar1-para'>{item?.multistep1?.beneficiary_email}</td>
                <td className='bar1-para'>Nom chantier</td>
                <td className='bar1-para'>{item?.multistep2?.partnertotalprice}</td>
                <td className='bar1-para'>{item?.multistep1?.final_status}</td>
                <td><Link to={`/update-create-dossier/${item?.multistep1?.id}`}><FiEdit2 className='bar1-para pe-auto border-0' /></Link></td>
                <td><RiDeleteBin6Line className='bar1-para pe-auto border-0' onClick={() => handleDelete(item?.multistep1?.id)} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


      <div>
        <div className="text-end mt-4">
          <Button className='btns-color2 links' onClick={exportToExcel}>
            Export
          </Button>
        </div>


      </div>
    </div>
  )
}

export default AllDataShow;