import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs";
function Bar113() {
  const navigate = useNavigate();
  const handlePrev = () => {
    navigate(-1);
  };
  return (
    <div className='container-fluid py-3 px-4'>
      <div className='row'>
        <p className='bar1-para '>BAR-TH-113 : Chaudière biomasse individuelle <span onClick={handlePrev}  className='border-0 bg-white pe-auto'><BsArrowLeft className='left-arow pe-auto' /></span></p>
        <div className='button-1 mt-1'>
          {/* <button type='button' className='btn-on' data-bs-dismiss="modal" aria-label='close'>Calculez le montant </button> */}
          <Link to={"/calcult-bar-th-113"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
        </div>
        <div className='col bar1-tittle'>
          <div className='bar1-tittle'>
            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
              <h3 className='bar1-head'>1. Secteur d’application</h3>
              <p className='bar1-para'>Maisons individuelles existantes.</p>
              <h3 className='bar1-head'>2. Dénomination</h3>
              <p className='bar1-para'>Mise en place d’une chaudière biomasse individuelle.</p>
              <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
              <p className='bar1-para'>Mise en place d’une chaudière biomasse individuelle.</p>
              <p className='bar1-para'>Le professionnel réalisant l’opération est titulaire d’un signe de qualité conforme aux exigences prévues à l’article
                2 du décret n° 2014-812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du
                code général des impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des
                textes pris pour son application. Ce signe de qualité correspond à des travaux relevant du 3° du I de l'article 1er du
                décret précité.</p>
              <p className='bar1-para'>La puissance thermique nominale de la chaudière est inférieure ou égale à 70 kW. Elle utilise de la biomasse ligneuse
                notamment à base de bûches de bois, de copeaux de bois, de bois comprimé sous forme de granulés, de bois
                comprimé sous forme de briquettes ou de sciure de bois. Elle est équipée d’un régulateur relevant de l’une des
                classes IV, V, VI, VII ou VIII telles que définies au paragraphe 6.1 de la communication de la Commission 2014/C
                207/02</p>
              <p className='bar1-para'>Une chaudière à alimentation automatique est associée à un silo d'un volume minimal de 225 litres, neuf ou existant.
                Une chaudière à alimentation manuelle est associée à un ballon tampon, neuf ou existant.</p>
              <p className='bar1-para'>L’efficacité énergétique saisonnière (ηs) de la chaudière selon le règlement (EU) n°2015/1189 de la commission du
                28 avril 2015 est supérieure ou égale à<br></br>
                <span className='ms-4 '>- pour les opérations engagées à compter du 1er janvier 2021 et jusqu’au 31 décembre 2021 :<br></br>
                  <span className='ml-30'>- 77 % pour les chaudières de puissance thermique nominale inférieure ou égale à 20 kW ; </span><br></br>
                  <span className='ml-30'>- 78 % pour les chaudières de puissance thermique nominale supérieure à 20 kW ; </span><br></br>
                </span><br></br>
                <span className='ms-4'>- pour les opérations engagées à compter du 1er janvier 2022 :<br></br>
                  <span className='ml-30'>- 77 % pour les chaudières de puissance thermique nominale inférieure ou égale à 20 kW ; </span><br></br>
                  <span className='ml-30'>-  79 % pour les chaudières de puissance thermique nominale supérieure à 20 kW.</span><br></br>
                </span><br></br>
              </p>

              <p className='bar1-para'>L’efficacité énergétique saisonnière prise en compte est celle de la chaudière seule pour les besoins de chauffage
                des locaux (hors dispositif de régulation).</p>
              <p className='bar1-para'>La chaudière installée répond aux critères suivants selon son mode de chargement :<br></br>
                <span className='ms-4'>- pour une chaudière à alimentation manuelle  <br></br>
                  <span className='ml-30'>– les émissions saisonnières de particules sont inférieures ou égales à 40 mg/Nm3 ;</span><br></br>
                  <span className='ml-30'>– les émissions saisonnières de monoxyde de carbone (CO) sont inférieures ou égales à 600 mg/Nm3 ; </span><br></br>
                  <span className='ml-30'>– les émissions saisonnières d'oxydes d'azote (NOx) sont inférieures ou égales à 200 mg/Nm3;</span><br></br>
                  <span className='ml-30'>– les émissions saisonnières de composés organiques gazeux sont inférieures ou égales à 20 mg/Nm3;</span><br></br>
                </span><br></br>
                <span className='ms-4'>- pour une chaudière à alimentation automatique :  <br></br>
                  <span className='ml-30'>– les émissions saisonnières de particules sont inférieures ou égales à 30 mg/Nm3;  </span><br></br>
                  <span className='ml-30'>– les émissions saisonnières de monoxyde de carbone (CO) sont inférieures ou égales à 400 mg/Nm3 ;</span><br></br>
                  <span className='ml-30'>– les émissions saisonnières d'oxydes d'azote (NOx) sont inférieures ou égales à 200 mg/Nm3;  </span><br></br>
                  <span className='ml-30'>– les émissions saisonnières de composés organiques gazeux sont inférieures ou égales à 16 mg/Nm3. </span><br></br>
                </span><br></br>
              </p>
              <p className='bar1-para'>Les valeurs en concentration sont exprimées en mg/Nm3 et calculées ou mesurées à 10 % d’O2 conformément aux
                dispositions du règlement (UE) 2015/1189 de la Commission du 28 avril 2015.</p>
              <p className='bar1-para'>Une chaudière possédant le label Flamme verte 7* est réputée satisfaire les conditions ci-dessus relatives aux
                émissions atmosphériques. </p>
              <p className='bar1-para'>Le professionnel rédige une note de dimensionnement de la chaudière par rapport aux déperditions calculées à
                T = Tbase.  Les  déperditions  concernent  les  pièces  du  logement  desservies  par  le  réseau  de  chauffage,  sans
                considération des éventuels autres générateurs présents. Cette note est remise au bénéficiaire à l’achèvement des
                travaux.
              </p>
              <p className='bar1-para'>
                La  preuve  de  la réalisation  de  l’opération  mentionne  la  mise en  place d’une chaudière  biomasse  ligneuse,  sa
                puissance nominale, l’installation d’un régulateur ainsi que la classe de celui-ci et, le cas échéant, l’installation d’un
                silo  et  son  volume,  ou  l’installation  d’un  ballon  tampon.  Elle  indique  également  son  efficacité  énergétique
                saisonnière (ηs) selon le règlement (EU) 2015/1189 de la Commission du 28 avril 2015 et le niveau de ses émissions
                saisonnières de particules, de monoxyde de carbone, d’oxyde d’azote et de composés organiques gazeux selon ce
                même règlement, ou la mention du label flamme verte 7* obtenu.<br></br> </p>
              <p className='bar1-para'>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un matériel avec ses marque et
                référence  et  elle  est  accompagnée  d’un  document  issu  du  fabricant  ou  d’un  organisme  établi  dans  l'Espace
                économique européen et accrédité selon la norme NF EN ISO/IEC 17065 par le Comité français d'accréditation
                (COFRAC) ou tout autre organisme d'accréditation signataire de l'accord européen multilatéral pertinent pris dans
                le cadre de la coordination européenne des organismes d'accréditation.</p>
              <p className='bar1-para'>Ce document indique que le matériel de marque et référence mis en place est une chaudière biomasse équipée d’un
                silo d’au moins 225 litres ou d’un ballon tampon, et d’un régulateur et mentionne la classe du régulateur. Il précise
                la  puissance  nominale  de  la  chaudière  et  son  efficacité  énergétique  saisonnière  (ηs)  selon  le  règlement  (UE)
                2015/1189 de la Commission du 28 avril 2015 ainsi que les valeurs des émissions saisonnières de polluants selon
                ce même règlement ou, le cas échéant, que la chaudière mise en place possède le label flamme verte 7*.</p>
              <p className='bar1-para'>Les documents justificatifs spécifiques à l’opération sont <br></br>
                <span className='ms-4'>- la note de dimensionnement susmentionnée ; </span> <br></br>
                <span className='ms-4'>– la décision de qualification ou de certification du professionnel délivrée selon les mêmes exigences que celles
                  prévues à l’article 2 du décret susmentionné.</span><br></br>
              </p>
               <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                <span className='bar1-para'>17 ans.</span></h3>

              <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac</p>
              <div className="row">
                <div className="col-4">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th >Zone géographique</th>
                        <th >Montant en kWh cumac</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center'><td>H1</td>
                        <td>41 300</td> </tr>
                      <tr className='bar1-para text-center'><td>H2</td>
                        <td>33 800</td></tr>
                      <tr className='bar1-para text-center'><td>H3</td>
                        <td>26 300</td></tr></tbody>
                  </table>
                </div>
              </div>
              <div className='bar1-content mt-4 pb-4'>
                <p className='bar1-para para-bar '>Fourniture et pose par un professionnel RGE d’un procédé d’isolation en comble perdu ou en rampant de toiture dans un bâtiment résidentiel existant. Un pare-vapeur (ou équivalent) est posé pour protéger l’isolant thermique de l'humidité et de la condensation. </p>
                <p className="bar1-head para-bar1 ">Éligible si : </p>
                <ul className='ul-bar1'>  <li><span className='bar1-ul '>Le devis : </span>comporte la date de la pré-visite technique.</li>
                  <li><span className='bar1-ul'>Le début des travaux</span> respecte un délai<span className='bar1-ul'> de 8 jours après la date de signature</span> de la commande.</li>
                  <li><span className='bar1-ul'>L’isolant :</span> a une résistance thermique ≥ à 7 m².K/W en comble perdu et ≥ à 6 m².K/W en rampant de toiture, est certifié ACERMI (ou équivalent), est de classe A de protection au feu (ou équivalent ou pose d’une couche supplémentaire).</li>
                  <li><span className='bar1-ul'>Sont installés :</span>  un coffrage autour des conduits de fumées et des éclairages encastrés ; une rehausse rigide au niveau de la trappe d’accès.</li></ul>
                <p className='bar1-para para-bar1'><span className='bar1-head '>La facture indique obligatoirement :</span> fourniture et pose d’une isolation de comble ou toiture, marque, référence, épaisseur, résistance thermique, n° ACERMI et surface réellement isolée (+ l’identité du sous-traitant, si c’est le cas), les aménagements réalisés, la présence d’un pare-vapeur + la date de pré-visite technique. </p>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Contrôle post-chantier :</span> tous les chantiers font l’objet d’un questionnaire de satisfaction client, certains font l’objet d’une inspection qualité post-travaux par un organisme indépendant (attesté COFRAC).</p>

                <p className='bar1-para para-bar1'><span className='bar1-head '>Attention :</span> Sans modification sous 5 ans, cette opération sera abrogée à partir du 1er mai 2027.</p>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime. En cliquant sur le Info , vous obtenez plus de détail sur une question.</p>

              </div>
              {/* <div className='button-1'>
                <button type='button' className='btn-on' >Site du Ministère <TbMathGreater /> </button>
              </div>
              <div className='button-1 mb-3'>
                <button type='button' className='btn-on' ><AiOutlineDownload />Télécharger le fiche </button>
              </div> */}
            </div>
          </div>
          </div>
      </div>
    </div>
  )
}

export default Bar113;