import React from 'react'
import { Link } from "react-router-dom"
const BATTH146Update = () => {
    return (
        <>
            <div className='container-fluid  '>
                <div className='row'>
                    <p className='bar1-para '>BAT-TH-146 : Isolation d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire</p>
                    <div className='button-1 mt-1'>
                        <Link to={"/update-calcult-batth146"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
                    </div>
                    <div className='col bar1-tittle'>
                        <div className='bar1-tittle'>
                            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                                <h3 className='bar1-head'>1. Secteur d’application</h3>
                                <p className='bar1-para'>Bâtiment tertaire existant.
                                </p>
                                <h3 className='bar1-head'>2. Dénomination</h3>
                                <p className='bar1-para'>Mise en place d’une isolation sur un réseau hydraulique de chauffage existant ou d’eau chaude sanitaire existant, situé hors du volume chauffé, pour un système de chauffage collectif existant maintenu en température (bouclé ou tracé). L’isolation du réseau hydraulique de chauffage ou d’eau chaude sanitaire (ECS) n’est pas éligible en cas de remplacement de l’installation de chauffage collectif ou de production de l’eau chaude sanitaire effectué après le 1er janvier 2018.</p>
                                <h3 className='bar1-head m-0'>3. Conditions pour la délivrance de certificats</h3>
                                <p className='bar1-para '>La mise en place est réalisée par un professionnel.<br></br>

                                </p>
                                <p className='bar1-para'>Le réseau hydraulique de chauffage ou d’eau chaude sanitaire est situé hors du volume chauffé. Le volume chauffé est défini au fascicule 1 des règles Th-U utilisées dans la méthode de calcul Th-C-E ex prévue par l’arrêté du 13 juin 2008 relatif à la performance énergétique des bâtiments existants de surface supérieure à 1000 mètres carrés, lorsqu’ils font l’objet de travaux de rénovation importants et approuvée par l’arrêté du 8 août 2008.</p>
                                <p className='bar1-para'>L’isolation est effectuée sur un réseau non isolé ou dont l’isolation existante est de classe inférieure ou égale à 2 selon la norme NF EN 12 828+A1:2014.</p>
                                <p className='bar1-para'>L’isolant mis en place est de classe supérieure ou égale à 4 selon la norme NF EN 12 828+A1:2014. Le remplacement d’une canalisation par une canalisation pré-isolée est éligible à la présente fiche si l’isolant mis en place présente les caractéristiques minimales ci-dessus. </p>
                                <p className='bar1-para'>La preuve de réalisation de l’opération mentionne :<br></br>
                                    <span className='ms-4'>– la mise en place d’une isolation sur un réseau hydraulique existant de chauffage ou d’ECS ou la pose d’une canalisation pré-isolée en remplacement d’une canalisation existante;</span><br></br>
                                    <span className='ms-4'>– la longueur isolée de réseau hors des volumes chauffés ;</span><br></br>
                                    <span className='ms-4'>– les marque et référence de l’isolant installé ou de la canalisation pré-isolée mise en place ;</span><br></br>
                                    <span className='ms-4'>– la classe de l’isolant installé selon la norme NF EN 12 828+A1:2014 ;</span>
                                    <span className='ms-4'>– en cas de remplacement d’un isolant de classe inférieure ou égale à 2, la longueur d’ancien isolant déposée et les caractéristiques de celui-ci (type d’isolant, épaisseur et si possible marque et référence).
                                    </span>
                                </p>
                                <p className='bar1-para'>Les travaux d’isolation du réseau de chauffage ou d’ECS font l’objet, après réalisation, d’un contrôle par un organisme d’inspection. Un rapport de conformité établi par cet organisme atteste la vérification :<br></br>
                                    <span className='ms-4'>– la mise en place d’une isolation sur un réseau hydraulique existant de chauffage ou d’ECS ou la pose d’une canalisation pré-isolée en remplacement d’une canalisation existante</span><br></br>
                                    <span className='ms-4'>– des caractéristiques de l’isolant mis en place :</span><br></br>
                                    <span className='ms-4'>– marque et référence ;</span><br></br>
                                    <span className='ms-4'>– et classe selon la norme NF EN 12 828 + A1:2014 ;</span>
                                    <span className='ms-4'>– de la longueur, hors des volumes chauffés, du réseau isolé lors de l’opération ;</span>
                                    <span className='ms-4'>– de la date de mise en service de l’installation de chauffage collectif et/ou de production de l’eau chaude sanitaire en précisant s’il s’agit d’une vérification sur site ou documentaire.</span><br></br>
                                </p>
                                <p className='bar1-para'>Le rapport de conformité mentionne la date de la visite sur site de l’organisme et identifie l’opération réalisée par la référence de la preuve de réalisation de l’opération, la raison sociale et le numéro de SIREN du professionnel, l’identité du bénéficiaire et le lieu de réalisation de l’opération.</p>
                                <p className='bar1-para'>L’organisme d’inspection est accrédité selon la norme NF EN ISO/CEI 17020 ou toute version ultérieure, en tant qu’organisme d’inspection de type A pour le domaine 15.1.5 « Inspection d’opérations standardisées d’économies d’énergie dans le cadre du dispositif de délivrance des certificats d’économies d’énergie » par le Comité français d’accréditation (COFRAC) ou tout autre organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris dans le cadre de European co-operation for Accreditation (EA), coordination européenne des organismes d’accréditation.</p>
                                <p className='bar1-para'>Les documents justificatifs spécifiques à l’opération sont le rapport de conformité établi par l’organisme d’inspection et et la justification de l’accréditation de l’organisme d’inspection. </p>
                                <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                                    <span className='bar1-para'>20 ans.</span></h3>
                                <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac</p>
                                <div className="row">
                                    <div className="col-4">
                                        <table className='table table-bordered table-border p-0'>
                                            <tr className='bar1-head text-center p-0 m-0'>
                                                <th className='p-1' colSpan="3">Montant en kWh cumac par metre de reseau isolé</th></tr>
                                            <tr className=' bar1-para text-center '>
                                                <th className='border-thin p-0' rowspan="4">Zone climatique</th></tr>
                                            <tr className='bar1-para text-center p-0'>
                                                <td className='border-thin p-0'>H1</td>
                                                <td className='p-1' >4 300</td></tr>
                                            <tr className='bar1-para text-center p-0'>
                                                <td className='border-thin p-0' >H2</td>
                                                <td className='p-1'>4 000</td></tr>
                                            <tr className='bar1-para text-center p-0'>
                                                <td className='border-thin p-0'>H3</td>
                                                <td className='p-1'>3 600</td></tr>
                                        </table>
                                    </div>
                                    <div className="col-lg-1 ">
                                        <h1 className=' mt-5 '>*</h1>
                                    </div>
                                    <div className="col-lg-3">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th colSpan="3">Longueur isolée du reseau de chauffage ou d'ECS hors du volume chauffé</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para text-center h-100'><td className='p-4  text-center'>L</td></tr></tbody>
                                        </table></div>
                                </div><div className='bar1-content mt-4 pb-4'>
                                    <p className='bar1-para para-bar1 m-0'><span className='bar1-head '>Éligible si :</span> Installation par un professionnel d’une isolation classe 4 ou + sur des réseaux hydrauliques de chauffage ou d’ECS maintenus en température (bouclé ou tracé), dans un bâtiment tertiaire de plus de 2 ans. Le réseau hydraulique existant est situé hors-volume chauffé (avec ou sans émetteur) et est non isolé ou isolé par un isolant de classe 2 ou inférieure. Les canalisations pré-isolées sont éligibles si son isolant est de classe 4. </p>
                                    <p className="bar1-head para-bar1 m-0 "> Sont exclus</p>
                                    <ul className='ul-bar1'>
                                        <li className='bar1-para'>Si la chaudière ou les réseaux hydrauliques ont été remplacés après le 1er janvier 2018</li>
                                        <li className='bar1-para'>Si une isolation de classe 3 ou + est existante sur les canalisations.</li>
                                    </ul>
                                    <p className='bar1-para para-bar1'><span className='bar1-head '>Important :</span> en fin de chantier, un rapport de conformité doit être établi par un organisme accrédité COFRAC. Il atteste de la longueur de tuyaux isolés - la marque, référence, épaisseur, et classe de l’isolant installé - les dates d’installation et le type de contrôle effectué.Vous ignorez une réponse : cette simulation vous donne un montant et permet de demander et d’obtenir cette prime. Après opération, les données précises seront collectées auprès de l’organisme de contrôle et la prime sera automatiquement réévaluée. En cliquant sur le + Info -> , vous obtenez plus de détail sur une question.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BATTH146Update