import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";

const Calcult133Update = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    // ----------------------------------------------
    // useEffect(() => {
    //     // Retrieve stored form values from local storage
    //     const storedFormValues = sessionStorage.getItem('step-6');

    //     if (storedFormValues) {
    //         // Parse the stored form values and set them as initial values
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.codepostal = parsedFormValues.codepostal;
    //         initialValues.buildingindustry = parsedFormValues.buildingindustry;
    //         initialValues.classZshowerheads = parsedFormValues.classZshowerheads;
    //         initialValues.classZZshowerheads = parsedFormValues.classZZshowerheads;
    //         initialValues.classZunregulated = parsedFormValues.classZunregulated;
    //         initialValues.classZZunregulated = parsedFormValues.classZZunregulated;
    //         initialValues.kilowater = parsedFormValues.kilowater;
    //     }
    // }, []);
    const [initialValues, setInitialValues] = useState(
        {
        codepostal: '',
        buildingindustry: '',
        classZshowerheads: '',
        classZZshowerheads: '',
        classZunregulated: '',
        classZZunregulated: '',
        kilowater: '',
    });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            codepostal: MultiUpdate?.multisteptable2?.codepostal || '',
            buildingindustry: MultiUpdate?.multisteptable2?.buildingindustry || '',
            classZshowerheads: MultiUpdate?.multisteptable2?.classZshowerheads || '',
            classZZshowerheads: MultiUpdate?.multisteptable2?.classZZshowerheads || '',
            classZunregulated: MultiUpdate?.multisteptable2?.classZunregulated || '',
            classZZunregulated: MultiUpdate?.multisteptable2?.classZZunregulated || '',
            kilowater: MultiUpdate?.multisteptable2?.kilowater || '',
        }));
    }, []);
    const validate = (values) => {
        const errors = {};

        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.classZshowerheads) {
            errors.classZshowerheads = "Nombre de pommes de douche de classe Z installées requise";
        }
        if (!values.classZZshowerheads) {
            errors.classZZshowerheads = "Nombre de pommes de douche de classe ZZ ou « WaterSense » installées requise";
        }
        if (!values.classZunregulated) {
            errors.classZunregulated = "Nombre d’aérateurs de robinet non régulés de classe Z installés requise";
        }
        if (!values.classZZunregulated) {
            errors.classZZunregulated = "Nombre d’aérateurs de robinet non régulés de classe Z installés requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/update-estimate");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    // useEffect(() => {
    //     const storedFormData = sessionStorage.getItem('step-6');
    //     if (storedFormData) {
    //         setStoredData(JSON.parse(storedFormData));
    //     }
    // }, []);
  return (
    <div>
              <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col'>
                                <p className='bar1-para'>Calculez et obtenez votre prime / Votre projet : Systèmes hydro-économes (France métropolitaine)</p>
                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-sm-12">
                                        <p className='bar1-head mt-3'>Code postal du bâtiment tertiaire* :</p>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <input
                                            type="number"
                                            name='codepostal'
                                            id='codepostal'
                                            value={values.codepostal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                    </div>
                                </div>

                                <hr></hr>
                                <div>
                                    <p className="bar1-head  mt-4">Secteur d'activité du bâtiment ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value='Bureaux'
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Bureaux"} />
                                            <span className='span-bar bar1-para'>Bureaux</span></label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value='Enseignement'
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Enseignement"} />
                                            <span className='span-bar bar1-para '> Enseignement </span>
                                        </label>

                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input" type="radio"
                                                value='Hotelleric et habitat communautaire'
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Hotelleric et habitat communautaire"} />
                                            <span className='span-bar bar1-para'>Hotelleric et habitat communautaire</span></label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input" type="radio"
                                                value='Sante'
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Sante"} />
                                            <span className='span-bar bar1-para'> Santé</span></label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input" type="radio"
                                                value='Etablissements sportifs'
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Etablissements sportifs"} />
                                            <span className='span-bar bar1-para '>
                                                Etablissements sportifs </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input" type="radio"
                                                value='Autres secteurs'
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Autres secteurs"} />
                                            <span className='span-bar bar1-para '> Autres secteurs  </span>
                                        </label>
                                    </div>
                                </div>
                                <div className='button-1'>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className='bar1-head mt-3'>Nombre de pommes de douche de classe Z installées
                                    <input
                                        type="number"
                                        name='classZshowerheads'
                                        id='classZshowerheads'
                                        value={values.classZshowerheads}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1'}></input>
                                    {touched.classZshowerheads && errors.classZshowerheads && <div className="error-message">{errors.classZshowerheads}</div>}
                                </p>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div>
                                <hr></hr>
                                <p className='bar1-head mt-3'>Nombre de pommes de douche de classe ZZ ou « WaterSense » installées
                                    <input
                                        type="number"
                                        name='classZZshowerheads'
                                        id='classZZshowerheads'
                                        value={values.classZZshowerheads}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1'}></input>
                                    {touched.classZZshowerheads && errors.classZZshowerheads && <div className="error-message">{errors.classZZshowerheads}</div>}</p>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div>
                                <hr></hr>
                                <p className='bar1-head mt-3'>Nombre d’aérateurs de robinet non régulés de classe Z installés
                                    <input
                                        type="number"
                                        name='classZunregulated'
                                        id='classZunregulated'
                                        value={values.classZunregulated}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.classZunregulated && errors.classZunregulated ? 'error' : 'form-control1'}></input>
                                    {touched.classZunregulated && errors.classZunregulated && <div className="error-message">{errors.classZunregulated}</div>}
                                </p>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div>
                                <hr></hr>
                                <p className='bar1-head mt-3'>Nombre d’aérateurs de robinet autorégulés installés
                                    <input type="number"
                                        name='classZZunregulated'
                                        id='classZZunregulated'
                                        value={values.classZZunregulated}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.classZZunregulated && errors.classZZunregulated ? 'error' : 'form-control1'} />
                                    {touched.classZZunregulated && errors.classZZunregulated && <div className="error-message">{errors.classZZunregulated}</div>}</p>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div>
                                <hr></hr><p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                <div className='text-start'>
                                    <label >
                                        <input
                                            type='number'
                                            name='kilowater'
                                            id='kilowater'
                                            value={values.kilowater}
                                            onChange={handleChange}
                                            className="input-box" placeholder='3' ></input>
                                        <span className='span-bar bar1-para '>Euros du Méga / Prix du kilowater</span>
                                    </label>
                                </div><hr></hr>
                                <div className='row mt-5 mb-2'>
                                    <div className='col text-start'>
                                        <button type='button' onClick={handlePrev} className=' btns-color' >Précédent </button>
                                    </div>
                                    <div className="col text-end">
                                        <button type='submit' className='btns-color' >Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    </div>
  )
}

export default Calcult133Update
