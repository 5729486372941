import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// TOASTIFY
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Residentiel from './components/operations/Residentiel';
import Index from './components/Index';
import Bar101 from './components/operations/Bar1';
import Calcult from './components/calcult/Calcult';
import Bar102 from './components/operations/Bar2';
import Bar103 from './components/operations/Bar3';
import Bat133 from './components/operations/Bat133';
import Bat33 from './components/calcult/Calcult133';
import Install from './components/liste/Create-install';
import Sidebar1 from './components/Sidebar1';
import Dossier from './components/dossiers/Create-dossier';
import B2b from './components/dossiers/B2b';
import Oblige from './components/liste/Create-oblige';
import Listeoblige from './components/liste/Liste-oblige';
import Dashboard from './components/Dashboard';
import Bat146 from './components/operations/Bat146';
import Calcult146 from './components/calcult/Calcult146';
import Bat104 from './components/operations/Bat104';
import Calcult104 from './components/calcult/Calcult104';
import Bat116 from './components/operations/Bat116';
import Calcult116 from './components/calcult/Calcult116';
import Barse104 from './components/operations/Barse104';
import Calcultse104 from './components/calcult/Calcultse104';
import Bar160 from './components/operations/Bar160';
import Calcult160 from './components/calcult/Calcult160';
import Bat101 from './components/operations/Bat101';
import Calcult101 from './components/calcult/Calcult101';
import Bat102 from './components/operations/Bat102';
import Calcult102 from './components/calcult/Calcult102';
import Bat103 from './components/operations/Bat103';
import Calcult103 from './components/calcult/Calcult103';
import Bat155 from './components/operations/Bat155';
import ResEc104 from './components/operations/ResEc104';
import Calcult155 from './components/calcult/Calcult155';
import Bar104 from './components/operations/Bar4';
import Calcult4 from './components/calcult/Bar-culcult4';
import Bar161 from './components/operations/Bar161';
import Calcult161 from './components/calcult/Bat-culcult161';
import Adduser from './components/users/Adduser';
import Edituser from './components/users/Edituser';
import B2c from './components/dossiers/B2c';
import Estimation from './components/parametre/Estimation';
import Control from './components/parametre/control';
import Devis from './components/devis/Devis';
import Facture from './components/factures/Facture';
import Attestation from './components/parametre/Attestation';
import Valider from './components/parametre/Valider-geoportail';
import Invalider from './components/parametre/Valider-comment';
import Coordonnees from './components/parametre/Coordonnees';
import SubMenu from './components/parametre/SubMenu';
import Permissions from './components/parametre/Permissions';
import Uploads, { Upload } from './components/parametre/Upload-docs';
import Filedashboard from './components/dossiers/File-dashboard';
import Export from './components/Export-model';
import Opertionb2c from './components/operations/B2c-opertions';
import ResEc104Calcult from './components/calcult/ResEc104Calcult';
import Bar164 from './components/operations/Bar164';
import Bar113 from './components/operations/Bar113';
import Barth101 from './components/operations/Bar101';
import Barth104 from './components/operations/Bar104';
import Barth143 from './components/operations/Bar143';
import Barth148 from './components/operations/Bar148';
import Barth125 from './components/operations/Bar125';
import Edition from './components/Edition-de-dossier';
import Editionc from './components/Edition-de-b2c';
import B2b_Beneficiary from './components/dossiers/B2b_Beneficiary';
import B2b_Worksite from './components/dossiers/B2b_Worksite';
import EmptyFiles from './components/emptyfiles/EmptyFiles';
import FinalValidate from './components/emptyfiles/FinalValidate';
import ChangePassword from './components/users/ChangePassword';
import UpdateOblige from './components/liste/UpdateOblige';
import UpdateInstaller from './components/liste/UpdateInstaller';
import ListeUsers from './components/users/ListeUsers';
import CalcultBARTH164 from './components/calcult/CalcultBARTH164';
import CalcultBARTH125 from './components/calcult/CalcultBARTH125';
import CalcultBARTH148 from './components/calcult/CalcultBARTH148';
import CalcultBARTH143 from './components/calcult/CalcultBARTH143';
import CalcultBARTH101 from './components/calcult/CalcultBARTH101';
import CalcultBARTH104 from './components/calcult/CalcultBARTH104';
import CalcultBARTH113 from './components/calcult/CalcultBARTH113';
import AllDataShow from './components/liste/AllDataShow';
import Liste from './components/liste/Liste-install';
import UpdateCreateDossier from './components/update/liste/UpdateCreateDossier';
import UpdateBeneficiary from './components/update/dossiers/UpdateBeneficiary';
import ChantierUpdate from './components/update/dossiers/ChantierUpdate';
import ResidensielUpdate from './components/update/dossiers/ResidensielUpdate';
import BAREN101Update from './components/update/operations/BAREN101Update';
import BAREN102Update from './components/update/operations/BAREN102Update';
import BAREN103Update from './components/update/operations/BAREN103Update';
import BAREN104Update from './components/update/operations/BAREN104Update';
import BARSE104Update from './components/update/operations/BARSE104Update';
import BARTH160Update from './components/update/operations/BARTH160Update';
import BARTH161Update from './components/update/operations/BARTH161Update';
import BATEN101Update from './components/update/operations/BATEN101Update';
import BATEN102Update from './components/update/operations/BATEN102Update';
import BATEN103Update from './components/update/operations/BATEN103Update';
import BATTH116Update from './components/update/operations/BATTH116Update';
import BATTH146Update from './components/update/operations/BATTH146Update';
import BATTH104Update from './components/update/operations/BATTH104Update';
import BATEQ133Update from './components/update/operations/BATEQ133Update';
import RESEC104Update from './components/update/operations/RESEC104Update';
import BATTH155Update from './components/update/operations/BATTH155Update';
import CalcultUpdate from './components/update/calcult/CalcultUpdate';
import EstimationUpdate from './components/update/parametre/EstimationUpdate';
import UploadDocsUpdate from './components/update/parametre/UploadDocsUpdate';
import ControlContributionUpdate from './components/update/parametre/ControlContributionUpdate';
import ValiderGeoprtailUpdate from './components/update/parametre/ValiderGeoprtailUpdate';
import ValiderCommantUpdate from './components/update/parametre/ValiderCommantUpdate';
import DevisUpdate from './components/update/devis/DevisUpdate';
import FactureUpdate from './components/update/factures/FactureUpdate';
import AttestationUpdate from './components/update/parametre/AttestationUpdate';
import EmptyfilesUpdate from './components/update/emptyfiles/EmptyfilesUpdate';
import FinalValidateUpdate from './components/update/emptyfiles/FinalValidateUpdate';
import B2CUpdate from './components/update/liste/B2CUpdate';
import Empty404 from './components/Empty404';
import B2COperationsUpdare from './components/update/liste/B2COperationsUpdare';
import Calcult102Update from './components/update/calcult/Calcult102Update'
import CalcultBAREN104Update from './components/update/calcult/CalcultBAREN104Update';
import CalcultBARSE104Update from './components/update/calcult/CalcultBARSE104Update';
import Calcult160Update from './components/update/calcult/Calcult160Update';
import CalcultBARTH161Update from './components/update/calcult/CalcultBARTH161Update';
import ResEc104CalcultUpdate from './components/update/calcult/ResEc104CalcultUpdate';
import ObligeorInstaller from './components/dossiers/ObligeorInstaller';
import CalcultBATEN101Update from './components/update/calcult/CalcultBATEN101Update';
import Calcult103Update from './components/update/calcult/Calcult103Update';
import Calcult116Update from './components/update/calcult/Calcult116Update';
import Calcult146Update from './components/update/calcult/Calcult146Update';
import Calcult104Update from './components/update/calcult/Calcult104Update';
import Calcult133Update from './components/update/calcult/Calcult133Update';
import Calcult155Update from './components/update/calcult/Calcult155Update';
import BARTH164Update from './components/update/operations/BARTH164Update';
import BARTH125Update from './components/update/operations/BARTH125Update';
import BARTH148Update from './components/update/operations/BARTH148Update';
import BARTH101Update from './components/update/operations/BARTH101Update';
import BARTH143Update from './components/update/operations/BARTH143Update';
import BARTH104Update from './components/update/operations/BARTH104Update';
import BARTH113Update from './components/update/operations/BARTH113Update';
import CalcultBARTH164Update from './components/update/calcult/CalcultBARTH164Update';
import CalcultBARTH125Update from './components/update/calcult/CalcultBARTH125Update';
import CalcultBARTH148Update from './components/update/calcult/CalcultBARTH148Update';
import CalcultBARTH143Update from './components/update/calcult/CalcultBARTH143Update';
import CalcultBARTH104Update from './components/update/calcult/CalcultBARTH104Update';
import CalcultBARTH113Update from './components/update/calcult/CalcultBARTH113Update';
import ValidInvalid from './components/liste/ValidInvalid';
import ForgotPassword from './components/users/ForgotPassword';
import ProtectedRoute from './ProtectedRoute';
import Synopsis from './components/parametre/Synopsis';
import Uploaddocstest from './components/parametre/Uploaddocstest';

const App = () => {


  return (
    <div className="App">
      <Router>
        <Sidebar1 />
        <Routes>
          <Route exact path='/' element={<Index />} />
          <Route exact path='/login' element={<Index />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/adduser' element={<ProtectedRoute><Adduser /></ProtectedRoute>} />
          <Route path='/edituser/:id' element={<ProtectedRoute><Edituser /></ProtectedRoute>} />
          <Route path='/bat146' element={<ProtectedRoute><Bat146 /></ProtectedRoute>} />
          <Route path='/bat116' element={<ProtectedRoute><Bat116 /></ProtectedRoute>} />
          <Route path='/bar160' element={<ProtectedRoute><Bar160 /></ProtectedRoute>} />
          <Route path='/bar161' element={<ProtectedRoute><Bar161 /></ProtectedRoute>} />
          <Route path='/residential' element={<ProtectedRoute><Residentiel /></ProtectedRoute>} />
          <Route path='/bat101' element={<ProtectedRoute><Bat101 /></ProtectedRoute>} />
          <Route path='/bar1' element={<ProtectedRoute><Bar101 /></ProtectedRoute>} />
          <Route path='/calcultse104' element={<ProtectedRoute><Calcultse104 /></ProtectedRoute>} />
          <Route path='/calcult146' element={<ProtectedRoute><Calcult146 /></ProtectedRoute>} />
          <Route path='/calcult104' element={<ProtectedRoute><Calcult104 /></ProtectedRoute>} />
          <Route path='/calcult116' element={<ProtectedRoute><Calcult116 /></ProtectedRoute>} />
          <Route path='/calcult160' element={<ProtectedRoute><Calcult160 /></ProtectedRoute>} />
          <Route path='/calcult101' element={<ProtectedRoute><Calcult101 /></ProtectedRoute>} />
          <Route path='/calcult102' element={<ProtectedRoute><Calcult102 /></ProtectedRoute>} />
          <Route path='/calcult103' element={<ProtectedRoute><Calcult103 /></ProtectedRoute>} />
          <Route path='/calcult155' element={<ProtectedRoute><Calcult155 /></ProtectedRoute>} />
          <Route path='/calcult161' element={<ProtectedRoute><Calcult161 /></ProtectedRoute>} />
          <Route path='/calcult4' element={<ProtectedRoute><Calcult4 /></ProtectedRoute>} />
          <Route path='/calcult' element={<ProtectedRoute><Calcult /></ProtectedRoute>} />
          <Route path='/bar2' element={<ProtectedRoute><Bar102 /></ProtectedRoute>} />
          <Route path='/bat102' element={<ProtectedRoute><Bat102 /></ProtectedRoute>} />
          <Route path='/bat103' element={<ProtectedRoute><Bat103 /></ProtectedRoute>} />
          <Route path='/bat155' element={<ProtectedRoute><Bat155 /></ProtectedRoute>} />
          <Route path='/RES-EC-104' element={<ProtectedRoute><ResEc104 /></ProtectedRoute>} />
          <Route path='/RES-EC-104-Calcult' element={<ProtectedRoute><ResEc104Calcult /></ProtectedRoute>} />
          <Route path='/bar3' element={<ProtectedRoute><Bar103 /></ProtectedRoute>} />
          <Route path='/bar4' element={<ProtectedRoute><Bar104 /></ProtectedRoute>} />
          <Route path='/bat133' element={<ProtectedRoute><Bat133 /></ProtectedRoute>} />
          <Route path='/bar-se-104' element={<ProtectedRoute><Barse104 /></ProtectedRoute>} />
          <Route path='/bat104' element={<ProtectedRoute><Bat104 /></ProtectedRoute>} />
          <Route path='/bat133-calcult' element={<ProtectedRoute><Bat33 /></ProtectedRoute>} />
          <Route path='/create-install' element={<ProtectedRoute><Install /></ProtectedRoute>} />
          <Route path='/create-dossier' element={<ProtectedRoute><Dossier /></ProtectedRoute>} />
          <Route path='/create-dossier/b2b' element={<ProtectedRoute><B2b /></ProtectedRoute>} />
          <Route path='/create-dossier/b2b-beneficiaire' element={<ProtectedRoute><B2b_Beneficiary /></ProtectedRoute>} />
          <Route path='/create-dossier/b2b-chantier' element={<ProtectedRoute><B2b_Worksite /></ProtectedRoute>} />
          <Route path='/create-dossier/b2c' element={<ProtectedRoute><B2c /></ProtectedRoute>} />
          <Route path='/b2c/opertions' element={<ProtectedRoute><Opertionb2c /></ProtectedRoute>} />
          <Route path='/create-oblige' element={<ProtectedRoute><Oblige /></ProtectedRoute>} />
          <Route path='/update-oblige/:id' element={<ProtectedRoute><UpdateOblige /></ProtectedRoute>} />
          <Route path='/update-installer/:id' element={<ProtectedRoute><UpdateInstaller /></ProtectedRoute>} />
          <Route path='/liste-oblige' element={<ProtectedRoute>< Listeoblige /></ProtectedRoute>} />
          <Route path='/liste-install' element={<ProtectedRoute><Liste /></ProtectedRoute>} />
          <Route path='/users-dashboard/' element={<ProtectedRoute><ListeUsers /></ProtectedRoute>} />
          <Route path='/devis' element={<ProtectedRoute><Devis /></ProtectedRoute>} />
          <Route path='/facture' element={<ProtectedRoute><Facture /></ProtectedRoute>} />
          {/* <Route path='/dashboard' element={<Dashboard />} /> */}
          <Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path='/estimation-page' element={<ProtectedRoute><Estimation /></ProtectedRoute>} />
          <Route path='/control-contribution' element={<ProtectedRoute><Control /></ProtectedRoute>} />
          <Route path='/attestation' element={<ProtectedRoute><Attestation /></ProtectedRoute>} />
          <Route path='/valider-geoportail' element={<ProtectedRoute><Valider /></ProtectedRoute>} />
          <Route path='/valider-comment' element={<ProtectedRoute><Invalider /></ProtectedRoute>} />
          <Route path='/coordonnees' element={<ProtectedRoute><Coordonnees /></ProtectedRoute>} />
          <Route path='/upload-docs' element={<ProtectedRoute><Uploads /></ProtectedRoute>} />
          <Route path='/sub-menu' element={<ProtectedRoute><SubMenu /></ProtectedRoute>} />
          <Route path='/Permissions' element={<ProtectedRoute><Permissions /></ProtectedRoute>} />
          <Route path='/file-dashboard' element={<ProtectedRoute><Filedashboard /></ProtectedRoute>} />
          <Route path='/export-model' element={<ProtectedRoute><Export /></ProtectedRoute>} />
          <Route path='/bar164' element={<ProtectedRoute><Bar164 /></ProtectedRoute>} />
          <Route path='/bar113' element={<ProtectedRoute><Bar113 /></ProtectedRoute>} />
          <Route path='/bar-th-101' element={<ProtectedRoute><Barth101 /></ProtectedRoute>} />
          <Route path='/bar-th-104' element={<ProtectedRoute><Barth104 /></ProtectedRoute>} />
          <Route path='/bar-th-143' element={<ProtectedRoute><Barth143 /></ProtectedRoute>} />
          <Route path='/bar-th-148' element={<ProtectedRoute><Barth148 /></ProtectedRoute>} />
          <Route path='/bar-th-125' element={<ProtectedRoute><Barth125 /></ProtectedRoute>} />
          <Route path='/edition-de-dossier/:id' element={<ProtectedRoute><Edition /></ProtectedRoute>} />
          <Route path='/edition-de-dossier/b2c/:id' element={<ProtectedRoute><Editionc /></ProtectedRoute>} />
          <Route path='/emptyfiles' element={<ProtectedRoute><EmptyFiles /></ProtectedRoute>} />
          <Route path='/finalvalidate' element={<ProtectedRoute><FinalValidate /></ProtectedRoute>} />
          <Route path='/change-password' element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
          <Route path='/calcult-bar-th-164' element={<ProtectedRoute><CalcultBARTH164 /></ProtectedRoute>} />
          <Route path='/calcult-bar-th-125' element={<ProtectedRoute><CalcultBARTH125 /></ProtectedRoute>} />
          <Route path='/calcult-bar-th-148' element={<ProtectedRoute><CalcultBARTH148 /></ProtectedRoute>} />
          <Route path='/calcult-bar-th-143' element={<ProtectedRoute><CalcultBARTH143 /></ProtectedRoute>} />
          <Route path='/calcult-bar-th-101' element={<ProtectedRoute><CalcultBARTH101 /></ProtectedRoute>} />
          <Route path='/calcult-bar-th-104' element={<ProtectedRoute><CalcultBARTH104 /></ProtectedRoute>} />
          <Route path='/calcult-bar-th-113' element={<ProtectedRoute><CalcultBARTH113 /></ProtectedRoute>} />
          <Route path='/all-datas' element={<ProtectedRoute><AllDataShow /></ProtectedRoute>} />
          <Route path='/update-create-dossier/:id' element={<ProtectedRoute><UpdateCreateDossier /></ProtectedRoute>} />
          <Route path='/update-b2b-beneficiaire' element={<ProtectedRoute><UpdateBeneficiary /></ProtectedRoute>} />
          <Route path='/update-b2b-chantier' element={<ProtectedRoute><ChantierUpdate /></ProtectedRoute>} />
          <Route path='/update-b2b-residensiel' element={<ProtectedRoute><ResidensielUpdate /></ProtectedRoute>} />
          <Route path='/update-bar-en-101' element={<ProtectedRoute><BAREN101Update /></ProtectedRoute>} />
          <Route path='/update-bar-en-102' element={<ProtectedRoute><BAREN102Update /></ProtectedRoute>} />
          <Route path='/update-bar-en-103' element={<ProtectedRoute><BAREN103Update /></ProtectedRoute>} />
          <Route path='/update-bar-en-104' element={<ProtectedRoute><BAREN104Update /></ProtectedRoute>} />
          <Route path='/update-bar-se-104' element={<ProtectedRoute><BARSE104Update /></ProtectedRoute>} />
          <Route path='/update-bar-th-160' element={<ProtectedRoute><BARTH160Update /></ProtectedRoute>} />
          <Route path='/update-bar-th-161' element={<ProtectedRoute><BARTH161Update /></ProtectedRoute>} />
          <Route path='/update-bat-en-101' element={<ProtectedRoute><BATEN101Update /></ProtectedRoute>} />
          <Route path='/update-bat-en-102' element={<ProtectedRoute><BATEN102Update /></ProtectedRoute>} />
          <Route path='/update-bat-en-103' element={<ProtectedRoute><BATEN103Update /></ProtectedRoute>} />
          <Route path='/update-bat-th-116' element={<ProtectedRoute><BATTH116Update /></ProtectedRoute>} />
          <Route path='/update-bat-th-146' element={<ProtectedRoute><BATTH146Update /></ProtectedRoute>} />
          <Route path='/update-bat-th-104' element={<ProtectedRoute><BATTH104Update /></ProtectedRoute>} />
          <Route path='/update-bat-eq-133' element={<ProtectedRoute><BATEQ133Update /></ProtectedRoute>} />
          <Route path='/update-res-ec-104' element={<ProtectedRoute><RESEC104Update /></ProtectedRoute>} />
          <Route path='/update-bat-th-155' element={<ProtectedRoute><BATTH155Update /></ProtectedRoute>} />
          <Route path='/update-calcult' element={<ProtectedRoute><CalcultUpdate /></ProtectedRoute>} />
          <Route path='/update-estimate' element={<ProtectedRoute><EstimationUpdate /></ProtectedRoute>} />
          <Route path='/update-upload-docs' element={<ProtectedRoute><UploadDocsUpdate /></ProtectedRoute>} />
          <Route path='/update-control' element={<ProtectedRoute><ControlContributionUpdate /></ProtectedRoute>} />
          <Route path='/update-valider-geoportail' element={<ProtectedRoute><ValiderGeoprtailUpdate /></ProtectedRoute>} />
          <Route path='/update-valider-commant' element={<ProtectedRoute><ValiderCommantUpdate /></ProtectedRoute>} />
          <Route path='/update-devis' element={<ProtectedRoute><DevisUpdate /></ProtectedRoute>} />
          <Route path='/update-facture' element={<ProtectedRoute><FactureUpdate /></ProtectedRoute>} />
          <Route path='/update-attestation' element={<ProtectedRoute><AttestationUpdate /></ProtectedRoute>} />
          <Route path='/update-error' element={<ProtectedRoute><EmptyfilesUpdate /></ProtectedRoute>} />
          <Route path='/update-finalvalitation' element={<ProtectedRoute><FinalValidateUpdate /></ProtectedRoute>} />
          <Route path='/update-b2c' element={<ProtectedRoute><B2CUpdate /></ProtectedRoute>} />
          <Route path='/empty-404' element={<ProtectedRoute><Empty404 /></ProtectedRoute>} />
          <Route path='/b2c-operation-update' element={<ProtectedRoute><B2COperationsUpdare /></ProtectedRoute>} />
          <Route path='/update-calcult-baten102' element={<ProtectedRoute><Calcult102Update /></ProtectedRoute>} />
          <Route path='/update-calcult-baren104' element={<ProtectedRoute><CalcultBAREN104Update /></ProtectedRoute>} />
          <Route path='/update-calcult-barse104' element={<ProtectedRoute><CalcultBARSE104Update /></ProtectedRoute>} />
          <Route path='/update-calcult-barth160' element={<ProtectedRoute><Calcult160Update /></ProtectedRoute>} />
          <Route path='/update-calcult-barth161' element={<ProtectedRoute><CalcultBARTH161Update /></ProtectedRoute>} />
          <Route path='/update-calcult-resec104' element={<ProtectedRoute><ResEc104CalcultUpdate /></ProtectedRoute>} />
          <Route path='/installer-oblige' element={<ProtectedRoute><ObligeorInstaller /></ProtectedRoute>} />
          {/* <Route path='/installer-oblige' element={<ObligeorInstaller />} /> */}
          <Route path='/update-calcult-baten101' element={<ProtectedRoute><CalcultBATEN101Update /></ProtectedRoute>} />
          <Route path='/update-calcult-baten103' element={<ProtectedRoute><Calcult103Update /></ProtectedRoute>} />
          <Route path='/update-calcult-batth116' element={<ProtectedRoute><Calcult116Update /></ProtectedRoute>} />
          <Route path='/update-calcult-batth146' element={<ProtectedRoute><Calcult146Update /></ProtectedRoute>} />
          <Route path='/update-calcult-batth104' element={<ProtectedRoute><Calcult104Update /></ProtectedRoute>} />
          <Route path='/update-calcult-bateq133' element={<ProtectedRoute><Calcult133Update /></ProtectedRoute>} />
          <Route path='/update-calcult-batth155' element={<ProtectedRoute><Calcult155Update /></ProtectedRoute>} />
          <Route path='/update-bar-th-164' element={<ProtectedRoute><BARTH164Update /></ProtectedRoute>} />
          <Route path='/update-bar-th-125' element={<ProtectedRoute><BARTH125Update /></ProtectedRoute>} />
          <Route path='/update-bar-th-148' element={<ProtectedRoute><BARTH148Update /></ProtectedRoute>} />
          <Route path='/update-bar-th-101' element={<ProtectedRoute><BARTH101Update /></ProtectedRoute>} />
          <Route path='/update-bar-th-143' element={<ProtectedRoute><BARTH143Update /></ProtectedRoute>} />
          <Route path='/update-bar-th-104' element={<ProtectedRoute><BARTH104Update /></ProtectedRoute>} />
          <Route path='/update-bar-th-113' element={<ProtectedRoute><BARTH113Update /></ProtectedRoute>} />
          <Route path='/update-calcult-barth164' element={<ProtectedRoute><CalcultBARTH164Update /></ProtectedRoute>} />
          <Route path='/update-calcult-barth125' element={<ProtectedRoute><CalcultBARTH125Update /></ProtectedRoute>} />
          <Route path='/update-calcult-barth148' element={<ProtectedRoute><CalcultBARTH148Update /></ProtectedRoute>} />
          <Route path='/update-calcult-barth143' element={<ProtectedRoute><CalcultBARTH143Update /></ProtectedRoute>} />
          <Route path='/update-calcult-barth104' element={<ProtectedRoute><CalcultBARTH104Update /></ProtectedRoute>} />
          <Route path='/update-calcult-barth113' element={<ProtectedRoute><CalcultBARTH113Update /></ProtectedRoute>} />
          <Route path='/status/:statusValue' element={<ProtectedRoute><ValidInvalid /></ProtectedRoute>} />
          <Route path="/synopsis" element={<ProtectedRoute><Synopsis/></ProtectedRoute>} />
          {/* <Route path="/uploadtest" element={<ProtectedRoute><Uploaddocstest/></ProtectedRoute>} /> */}
        </Routes>
      </Router>
      {/* </BrowserRouter> */}
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
