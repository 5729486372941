import React from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
const B2COperationsUpdare = () => {
    const navigate = useNavigate();
    const handleChange = (values) => {
        console.log('values', values);
        var datas = { 'b2bOperations': 'b2c', 'documents': values };
        if (values === 'BAR-TH-164') {
            navigate('/update-bar-th-164');
        }
        if (values === 'BAR-TH-125') {
            navigate('/update-bar-th-125');
        }
        if (values === 'BAR-TH-148') {
            navigate('/update-bar-th-148');
        }
        if (values === 'BAR-TH-143') {
            navigate('/update-bar-th-143');
        }
        if (values === 'BAR-TH-101') {
            navigate('/update-bar-th-101');
        }
        if (values === 'BAR-TH-104') {
            navigate('/update-bar-th-104');
        }
        if (values === 'BAR-TH-113') {
            navigate('/update-bar-th-113');
        }
        sessionStorage.setItem("step-5", JSON.stringify(datas));
    }
    return (
        <>
            <div className="container-fluid-fluid pl-30">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column reside">
                                <Nav.Item>
                                    <Nav.Link className='reside1' eventKey="first">B2C</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane className='bg-white mt-4' eventKey="first">
                                    <div className='reside-page  justify-content-between d-flex mt-4'>
                                        <span onClick={() => handleChange('BAR-TH-164')} className='reside-content text-center links'>BAR-TH-164</span>
                                        <span className='reside-content1  '>Rénovation globale d’une maison individuelle (France métropolitaine)</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2'>
                                        <span onClick={() => handleChange('BAR-TH-125')} className='reside-content text-center links'>BAR-TH-125</span>
                                        <span className='reside-content1  '>Système de ventilation double flux autoréglable ou modulé à haute performance (France métropolitaine) </span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2 '>
                                        <span onClick={() => handleChange('BAR-TH-148')} className='reside-content text-center links'>BAR-TH-148</span>
                                        <span className='reside-content1  '>Chauffe-eau thermodynamique à accumulation </span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2'>
                                        <span onClick={() => handleChange('BAR-TH-143')} className='reside-content text-center links'>BAR-TH-143</span>
                                        <span className='reside-content1  '>Système solaire combiné (France métropolitaine)</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2'>
                                        <span onClick={() => handleChange('BAR-TH-101')} className='reside-content text-center links'>BAR-TH-101</span>
                                        <span className='reside-content1  '>Chauffe-eau solaire individuel  (France métropolitaine)</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2'>
                                        <span onClick={() => handleChange('BAR-TH-104')} className='reside-content text-center links'>BAR-TH-104</span>
                                        <span className='reside-content1  '>Pompe à chaleur de type air/eau ou eau/eau </span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2'>
                                        <span onClick={() => handleChange('BAR-TH-113')} className='reside-content text-center links'>BAR-TH-113</span>
                                        <span className='reside-content1  '>Chaudière biomasse individuelle</span>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}

export default B2COperationsUpdare