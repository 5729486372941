import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
function Calcult104() {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    // ----------------------------------------------
    useEffect(() => {
        // Retrieve stored form values from local storage
        const storedFormValues = sessionStorage.getItem('step-6');

        if (storedFormValues) {
            // Parse the stored form values and set them as initial values
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.tertiaryBuilding = parsedFormValues.tertiaryBuilding;
            initialValues.newradiators = parsedFormValues.newradiators;
            initialValues.codepostal = parsedFormValues.codepostal;
            initialValues.boiler = parsedFormValues.boiler;
            initialValues.heatingEnergy = parsedFormValues.heatingEnergy;
            initialValues.buildingindustry = parsedFormValues.buildingindustry;
            initialValues.surface_area = parsedFormValues.surface_area;
            initialValues.premiumamount = parsedFormValues.premiumamount;
        }
    }, []);
    const initialValues = {
        tertiaryBuilding: '',
        newradiators: '',
        codepostal: '',
        boiler: '',
        heatingEnergy: '',
        buildingindustry: '',
        surface_area: '',
        premiumamount: '',

    };
    const validate = (values) => {
        const errors = {};
        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.surface_area) {
            errors.surface_area = "Surface chauffée par les radiateurs équipés de robinets thermostatiques (en m²) requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/estimation-page");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-6');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid px-4 py-3'>
                        <div className='row'>
                            <div className='col'>
                                <p className='bar1-para '>BAT-TH-104 : Robinet thermostatique</p>
                                <p className="bar1-head  mt-4">Bâtiment Tertiaire de plus de 2 ans ? </p>
                                <div className='text-start'>
                                    <label className='para-bar1'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="tertiaryBuilding"
                                            id="tertiaryBuilding"
                                            value="Oui"
                                            onChange={handleChange}
                                            checked={values.tertiaryBuilding === "Oui"} />
                                        <span className='span-bar bar1-para'> Oui </span>
                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="tertiaryBuilding"
                                            id="tertiaryBuilding"
                                            value="Non"
                                            onChange={handleChange}
                                            checked={values.tertiaryBuilding === "Non"} />
                                        <span className='span-bar bar1-para '> Non </span>
                                    </label>
                                </div>
                                <div className='button-1'>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>

                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-sm-12">
                                        <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <input
                                            type="number"
                                            name='codepostal'
                                            id='codepostal'
                                            value={values.codepostal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                    </div>
                                </div>
                                <hr></hr>
                                <p className="bar1-head  mt-4">Installation sur des radiateurs neufs ?
                                </p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="newradiators"
                                            id="newradiators"
                                            value="OUI"
                                            onChange={handleChange}
                                            checked={values.newradiators === "OUI"} />
                                        <span className='span-bar bar1-para'>
                                            OUI, les radiateurs ont moins de 2 ans  </span></label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="newradiators"
                                            id="newradiators"
                                            value="NON"
                                            onChange={handleChange}
                                            checked={values.newradiators === "NON"} />
                                        <span className='span-bar bar1-para'>
                                            NON, les radiateurs ont plus de 2 ans
                                        </span>
                                    </label> </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">La chaudière est existante depuis plus de 2 ans ? </p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input" type="radio"
                                            name="boiler"
                                            id="boiler"
                                            value="OUI"
                                            onChange={handleChange}
                                            checked={values.boiler === "OUI"} />
                                        <span className='span-bar bar1-para'>OUI, plus de deux ans </span></label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input" type="radio"
                                            name="boiler"
                                            id="boiler"
                                            value="NON"
                                            onChange={handleChange}
                                            checked={values.boiler === "NON"} />
                                        <span className='span-bar bar1-para '>
                                            NON, moins de deux ans
                                        </span>
                                    </label>
                                </div>

                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <div>
                                    <p className="bar1-head  mt-4">Énergie de chauffage utilisée ?</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="heatingEnergy"
                                                id="heatingEnergy"
                                                value="Electricité"
                                                onChange={handleChange}
                                                checked={values.heatingEnergy === "Electricité"} />
                                            <span className='span-bar bar1-para'>
                                                Electricité
                                            </span></label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="heatingEnergy"
                                                id="heatingEnergy"
                                                value="Gaz"
                                                onChange={handleChange}
                                                checked={values.heatingEnergy === "Gaz"} />
                                            <span className='span-bar bar1-para '>
                                                Gaz (de ville, butane, propane...)
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="heatingEnergy"
                                                id="heatingEnergy"
                                                value="Fioul"
                                                onChange={handleChange}
                                                checked={values.heatingEnergy === "Fioul"} />
                                            <span className='span-bar bar1-para '>
                                                Fioul
                                            </span>
                                        </label> </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="heatingEnergy"
                                                id="heatingEnergy"
                                                value="Bois"
                                                onChange={handleChange}
                                                checked={values.heatingEnergy === "Bois"} />
                                            <span className='span-bar bar1-para '> Bois (biomasse) </span>
                                        </label></div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr></div>
                                <div>
                                    <p className="bar1-head  mt-4">Secteur d'activité du bâtiment ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Bureaux"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Bureaux"} />
                                            <span className='span-bar bar1-para'>
                                                Bureaux
                                            </span></label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Enseignement"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Enseignement"} />
                                            <span className='span-bar bar1-para '>
                                                Enseignement
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Commerces"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Commerces"} />
                                            <span className='span-bar bar1-para '>
                                                Commerces
                                            </span>
                                        </label>  </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Hôtellerie - Restauration"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Hôtellerie - Restauration"} />
                                            <span className='span-bar bar1-para '>
                                                Hôtellerie / Restauration
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Santé"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Santé"} />
                                            <span className='span-bar bar1-para '>
                                                Santé
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Autres secteurs tertiaires"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Autres secteurs tertiaires"} />
                                            <span className='span-bar bar1-para '> Autres secteurs tertiaires </span>
                                        </label>
                                    </div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>
                                    <div className="row">
                                        <div className="col"><p className='bar1-head mt-3'>Surface chauffée par les radiateurs équipés de robinets thermostatiques (en m²)</p></div>
                                        <div className="col"> <input
                                            type="number"
                                            name="surface_area"
                                            id="surface_area"
                                            value={values.surface_area}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1'}
                                            placeholder='0' ></input>
                                            {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}</div>
                                    </div>
                                    <hr></hr>
                                </div>
                                <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                <div className='text-start'>
                                    <label >
                                        <input
                                            type="text"
                                            name='premiumamount'
                                            id='premiumamount'
                                            value={values.premiumamount}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="input-box" ></input>
                                        <span className='span-bar bar1-para '>Euros du Méga / Prix du kilowater</span>
                                    </label>

                                </div><hr></hr>
                                <div className='row mt-5 mb-2'>
                                    <div className='col text-start'>
                                        <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                    </div>
                                    <div className="col text-end">
                                        <button type='submit' className='btns-color'>Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default Calcult104;