import React from 'react'
import { Link } from "react-router-dom"
const BATEN102Update = () => {
    return (
        <>
            <div className='container-fluid  '>
                <div className='row'>
                    <p className='bar1-para '>BAT-EN-102 : Isolation des murs</p>
                    <div className='button-1 mt-1'>
                        <Link to={"/update-calcult-baten102"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
                    </div>
                    <div className='col bar1-tittle'>
                        <div className='bar1-tittle'>
                            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                                <h3 className='bar1-head'>1. Secteur d’application</h3>
                                <p className='bar1-para'>Locaux du secteur tertiaire existants réservés à une utilisation professionnelle.</p>
                                <h3 className='bar1-head'>2. Dénomination</h3>
                                <p className='bar1-para'>Mise en place d’un doublage isolant (complexe ou sur ossature) sur murs par l’intérieur ou par l’extérieur.</p>
                                <h3 className='bar1-head m-0'>3. Conditions pour la délivrance de certificats</h3>
                                <p className='bar1-para '>La mise en place est réalisée par un professionnel.</p>
                                <p className='bar1-para'>La résistance thermique R de l’isolation installée est supérieure ou égale à 3,7 m².K/W.</p>
                                <p className='bar1-para'>La résistance thermique est évaluée selon la norme NF EN 12664, la norme NF EN 12667 ou la norme NF EN 12939 pour les isolants non réfléchissants et selon la norme NF EN 16012+A1 pour les isolants réfléchissants.</p>

                                <p className="bar1-para ">La preuve de la réalisation de l’opération mentionne : </p>
                                <ul className=' bar1-para '>  <li>la mise en place d’une isolation ;</li>
                                    <li>et la surface d’isolant installé ;</li>
                                    <li>et la résistance thermique de l’isolation mise en place évaluée, suivant la nature de l’isolant, selon l’une des normes susvisées.</li>
                                </ul>
                                <p className='bar1-para'>À défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un matériau avec ses marque et référence et la surface installée, et elle est complétée par un document issu du fabricant ou d’un organisme établi dans l’Espace économique européen et accrédité selon la norme NF EN 45011 par le Comité français d’accréditation (COFRAC) ou tout autre organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris dans le cadre de European co-operation for Accreditation (EA), coordination européenne des organismes d’accréditation.</p>
                                <p className='bar1-para'>Ce document indique que le matériau de marque et référence mis en place est un isolant et précise ses caractéristiques thermiques (résistance thermique ; ou conductivité thermique et épaisseur). En cas de mention d’une date de validité, ce document est considéré comme valable jusqu’à un an après sa date de fin de validité.</p>
                                <p className='bar1-para'>Pour les références proposées en différentes épaisseurs, la preuve de réalisation, si elle ne mentionne pas la résistance thermique de l’isolation installée, doit impérativement en préciser l’épaisseur.</p>
                                <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                                    <span className='bar1-para'>30 ans.</span></h3>
                                <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac
                                </p>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center'><th colSpan="3">Montant en kWh cumac par m<sup>2</sup> d'isolant </th></tr>
                                                <tr className='bar1-head text-center'>
                                                    <th rowSpan="2">zone climatique</th>
                                                    <th colSpan="2">Energie de chauffage</th>
                                                </tr>
                                                <tr className='bar1-head text-center'><td>Electricite</td><td>Combustible</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para text-center'><td>H1</td>
                                                    <td>3 000</td>
                                                    <td>4 800</td>
                                                </tr>
                                                <tr className='bar1-para text-center'><td>H2</td>
                                                    <td>2 500</td>
                                                    <td>3 900</td>
                                                </tr>
                                                <tr className='bar1-para text-center'><td>H3</td>
                                                    <td>1 600</td>
                                                    <td>2 600</td>
                                                </tr></tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-1 text-center">
                                        <h1 className='postion-tops text-center'>*</h1>
                                    </div>
                                    <div className="col-lg-3">
                                        <table className="table table-bordered table-border ">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th >Secteur d'activite</th>
                                                    <th >Facteur correctif </th></tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para '><td>Bureaux,<br />Enseignement,<br />Commerces</td>
                                                    <td className='text-center'>0,6</td></tr>
                                                <tr className='bar1-para '><td>Hotelleric -<br /> Restauration </td>
                                                    <td className='text-center'>0,7</td></tr><tr className='bar1-para '><td>Sante</td>
                                                    <td className='text-center'>1,3</td></tr><tr className='bar1-para '><td>Autres sectcurs</td>
                                                    <td className='text-center'>0,6</td></tr></tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-1 text-center">
                                        <h1 className='postion-tops text-center'>*</h1>
                                    </div>
                                    <div className="col-lg-3">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th colSpan="3">Surface d'isolant en m<sup>2</sup></th></tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para text-center h-100'><td className='pad-80  text-center'>s</td></tr></tbody>
                                        </table></div>
                                </div>
                                <div className='bar1-content mt-4 pb-4'>
                                    <p className='bar1-para para-bar1'><span className='bar1-head '>Eligible :</span> mise en place par un professionnel dans un bâtiment TERTIAIRE d’un doublage isolant (complexe ou sur ossature) sur les murs par l’intérieur ou par l’extérieur. </p>
                                    <p className='bar1-head para-bar1 m-0'>La facture mentionne :</p>
                                    <ul className='ul-bar1'>
                                        <li className='bar1-para'>La mise en place d’une isolation des murs;</li>
                                        <li className='bar1-para'> La surface d’isolant installé ;</li>
                                        <li className='bar1-para'>La résistance thermique (supérieure ou égale à 3,7 m² K/W) et évaluée selon la norme NF EN 12664, la norme NF EN 12667 ou la norme NF EN 12939 pour les isolants non réfléchissants et selon la norme NF EN 16012+A1 pour les isolants réfléchissants.</li>
                                    </ul>
                                    <p className='bar1-para para-bar1'><span className='bar1-head '>Vous ignorez une réponse :</span> cette simulation vous donne un montant et permet d’obtenir cette prime. Après opération, les données précises seront collectées auprès de l’installateur et la prime sera automatiquement réévaluée.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BATEN102Update