import React from 'react'
import { Link } from "react-router-dom"
import axios from 'axios'
import { useEffect, useState } from 'react';
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from 'react-toastify';
function ListeUsers() {
  const [data, setData] = useState([]);
  // const [selectedIds, setSelectedIds] = useState([]);
  const toastsuccess = (val) => toast.success(val);
  // installerorobligelocalid
  const installerorobligelocal = localStorage.getItem('installerorobligelocal');
  const installerorobligelocalid = localStorage.getItem('installerorobligelocalid');
  console.log('installerorobligelocal', installerorobligelocal, 'installerorobligelocalid', installerorobligelocalid);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const Token = localStorage.getItem('Token');
    let url;

    if (installerorobligelocal === 'Super Manager') {
      console.log('hii');
      url = `${process.env.REACT_APP_API_URL}/getall-users`;
    } else {
      console.log('hello');
      url = `${process.env.REACT_APP_API_URL}/getuser/${installerorobligelocalid}`;
    }
    try {
      const response = await axios.get(url, {
        headers: { authorization: Token }
      });
      // console.log(response.data);
      if (installerorobligelocal === 'Super Manager') {
        console.log(response.data);
        setData(response.data);
      } else {
        console.log(response.data);
        setData(response.data);
      }
      // else if (response.data.user) {
      //   console.log(response.data);
      //   setData(response.data);
      // }
    } catch (error) {
      console.error(error);
    }
  };
  // DELETE
  const handleDelete = async (id) => {
    console.log('id', id);
    const Token = localStorage.getItem('Token');
    try {
      if (window.confirm("Do You Want to Delete?")) {
        const deleteresponse = await axios.delete(`${process.env.REACT_APP_API_URL}/deleteuser/${id}`,
          {
            headers: { authorization: Token },
          }
        );
        if (deleteresponse?.status === 200) {
          toastsuccess(deleteresponse?.data?.message);
          fetchData();
        };
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };


  return (
    <div className="container-fluid-fluid pl-30">
      <div className="row text_top">
        <div className="col d-flex justify-between black-bg ">
          {/* <div className='mt-3'>
          <MdDeleteSweep className='icon_size pe-auto' title='Delete Selected Items' onClick={handleMultiDelete} />
          </div> */}
          <div>
            <p className='bar1-head mt-3'>USERS DASHBOARD</p>
          </div>
          <div className='mt-2'>
            {installerorobligelocal === 'Super Manager' ? (<><button type='button' className='btns-color mt-1' ><Link to={"/adduser"} className='text-blacks text-decoration-none text-white'>
              ADD USER
            </Link></button></>) : null}
          </div>

        </div>
      </div>
      <div className='table-responsive'>
        <table className="table">
          <thead>
            <tr>
              {/* <th scope="col"><MdDeleteSweep className='icon_size ' title='Delete Selected Items' onClick={handleMultiDelete} /></th> */}
              <th scope="col" className='bar1-para'>User Name</th>
              <th scope="col" className='bar1-para'>Email</th>
              <th scope="col" className='bar1-para'>Role</th>
              <th scope="col" className='bar1-para'>Phone</th>
              <th scope="col" className='bar1-para'>City</th>
              <th scope="col" className='bar1-para'>Edit</th>
              {installerorobligelocal === 'Super Manager' ? (<><th scope="col" className='bar1-para'>Delete</th></>) : null}
              {/* <th scope="col" className='bar1-para'>Edit</th>
              <th scope="col" className='bar1-para'>Delete</th> */}
            </tr>
          </thead>

          <tbody>
            {data?.userData?.map((item, index) => (
              <tr key={item?.users?.id} className='bg-light'>
                {/* <th scope="row"><input type="checkbox" checked={selectedIds.includes(item?.multistep1?.id)} onChange={() => handleCheckboxChange(item?.multistep1?.id)} /></th> */}
                <td className='bar1-para'>{item?.users?.name}</td>
                <td className='bar1-para'>{item?.users?.email_id}</td>
                <td className='bar1-para'>{item?.role?.rolename}</td>
                <td className='bar1-para'>{item?.users?.phone}</td>
                <td className='bar1-para'>{item?.users?.city}</td>
                <td><Link to={`/edituser/${item?.users?.id}`}><FiEdit2 className='bar1-para pe-auto border-0' /></Link></td>
                {installerorobligelocal === 'Super Manager' ? (<><td><RiDeleteBin6Line className='bar1-para pe-auto border-0' onClick={() => handleDelete(item?.users?.id)} /></td></>) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div >

  )
}

export default ListeUsers


