import React, { useState, useRef } from 'react'
import upload from '../../../assets/upload 1.svg'
import { Link, useNavigate } from 'react-router-dom';
import useFileUpload from 'react-use-file-upload';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoDocumentTextSharp } from "react-icons/io5";
const Loader = () => {
    return <div>Loading...</div>;
};
const UploadDocsUpdate = () => {
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const toastsuccess = (val) => toast.success(val);
    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();

    const inputRef = useRef();

    // FILE UPLOAD
    const Token = localStorage.getItem('Token');
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });
        try {
            axios.post(`${process.env.REACT_APP_API_URL}/upload-files`, formData, {
                headers: {
                    authorization: Token,
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                console.log(response);
                sessionStorage.setItem('userFiles', JSON.stringify(response?.data?.files));
                toastsuccess(response?.data?.message);
            })
        } catch (error) {
            console.error(error?.response?.data?.message);
            toastsuccess(error?.response?.data?.message);
        }
    };
    const handlePrev = () => {
        navigate(-1);
    };
    if (isLoading) {
        return <Loader />;
    };

    // let decodedFileName;
    const fileSpliting = MultiUpdate?.files?.map((fileitem) => {
        console.log(fileitem?.files);
        const parts = fileitem?.files.split('/');
        console.log(parts);
        // const parts = file.files.split('/');
        const fileNamePart = parts[parts.length - 1];
        // const decodedFileName = decodeURIComponent(fileNamePart);
        return fileNamePart;
    })
    console.log(fileSpliting);
    return (
        <>
            <div className="container-fluid-fluid pl-30">
                <div className="bar1-para">Votre project : Réf ISE0015455 : Isolation des combles ou de toiture / Envoyer un document</div>
                <div className="row mt-5 justify-space ">
                    <div className="col-lg-7 mt-3">
                        <p className='bar1-para bar1-ul'>Attestation sur l’Honneur.pdf</p>
                        <p className='bar1-para bar1-ul'>Facture.pdf</p>
                        <p className='bar1-para bar1-ul'>Devis.pdf </p>
                        <p className='bar1-para bar1-ul'>Cadre.pdf</p>
                        <div className='form-data-text'>
                            {
                                fileSpliting?.map((fileitem) => (
                                    <p className='bar1-para bar1-ul'>{fileitem}</p>
                                ))

                            }
                            {/* <p>{decodedFileName}</p> */}
                        </div>
                        {/* LOCAL STORAGE DATA SHOW */}
                        <div className="form-container-fluid">
                            <div >
                                <ul className='form-data-text'>
                                    {fileNames?.map((name) => (
                                        <li key={name}>
                                            <span className='bar1-para bar1-ul'><IoDocumentTextSharp /> {name}</span> <span onClick={() => removeFile(name)}><RiDeleteBin6Line /></span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 border-lined ">
                        <div className='text-center mt-3'>
                            <p className='bar1-head text-center '>Téléchargement de fichiers</p>
                            <img src={upload} alt="upload icon" className="text-center ht-100 "></img>
                            <p className='bar1-para1 mt-3'>Sélectionnez un fichier ou faites-le glisser ici</p>
                            <div className=''>
                                <button type='button ' className='btn-upload' onClick={() => inputRef.current.click()}>Sélectionnez un fichier  </button>
                                <div
                                    onDragEnter={handleDragDropEvent}
                                    onDragOver={handleDragDropEvent}
                                    onDrop={(e) => {
                                        handleDragDropEvent(e);
                                        setFiles(e, 'a');
                                    }}
                                >
                                    <input
                                        ref={inputRef}
                                        type="file"
                                        multiple
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                            setFiles(e, 'a');
                                            inputRef.current.value = null;
                                        }}
                                    />
                                </div>
                            </div>
                            {files?.length > 0 && (
                                <>
                                    <p className='bar1-para1 mt-3'>Type de fichier : {fileTypes.join(', ')} <br></br>
                                        Taille du fichier : {totalSize}</p>
                                </>
                            )}
                            {files?.length == 0 && (
                                <p className='bar1-para1 mt-3'>Type de fichier : DOC, PDF, JPEG<br />Taille du fichier : 1 MB</p>
                            )}
                        </div>
                    </div>
                    <div className='mt-5 mb-3  text-end'>
                        <button type='button' className='btns-color3' onClick={() => clearAllFiles()}>Annuler </button>
                        {/* <button type='button' className='btns-color3' onClick={handleSubmit}>Télécharger</button> */}
                        <button type='button' className='btns-color3' onClick={handleSubmit}>Télécharger</button>
                    </div>

                    <div className='row mt-5 mb-2'>
                        <div className='col text-start'>
                            <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                        </div>
                        <div className="col text-end">
                            <Link to='/update-control' className='btns-color px-5 py-2'>Suivant</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadDocsUpdate