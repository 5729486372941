import React, { useState, useEffect } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { Link, useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import axios from 'axios';
import OperationB2C from './update/B2C/OperationB2C';
import DocumentB2C from './update/B2C/DocumentB2C';
import ControleB2C from './update/B2C/ControleB2C';
import DevisDFAB2C from './update/B2C/DevisDFAB2C';
import EstimateB2C from './update/B2C/EstimateB2C';
import InfosB2C from './update/B2C/InfosB2C';
import ErrorsPageB2C from './update/B2C/ErrorsPageB2C';
import StatusB2C from './update/B2C/StatusB2C';
import { Spinner } from 'react-bootstrap'
function Editionc() {
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const Token = localStorage.getItem('Token');
    // FIRST STEP CODE
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const options = {
                headers: { authorization: Token }
            };
            try {
                const [multistepResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/get-multistep/${id}`, options),
                ]);
                setIsLoading(false);
                setData(multistepResponse.data);
            } catch (error) {
                setIsLoading(false);
                console.error(error);
            }
        };

        fetchData();
    }, [Token]);
    //    MULTIUPDATE DATAS
    const MultiUpdate = data;
    const handlePrev = () => {
        navigate(-1);
    };
    if (isLoading) {
        return <Spinner animation="border" className='text-success' />;
    };
    return (
        <div className="container-fluid-fluid pl-30">
            <p className='bar1-para mt-3 d-flex justify-content-between'>B2C / EDITION DE DOSSIER <button onClick={handlePrev} className='handlePrevlinks'><BsArrowLeft className='left-arow' /></button></p>
            <div className="row text_top">
                <div className="col"><p className='bar1-head mt-3'>DOSSIER : {MultiUpdate?.files?.length}N°   </p></div>
                <div className="col">
                    <p className='bar1-head mt-3'>Créé le : {MultiUpdate?.multisteptable1?.createdAt.split('T')[0]} à {MultiUpdate?.multisteptable1?.createdAt.split('T')[1].split('.')[0]}</p>
                </div>
                <div className="col">
                    <p className='bar1-head mt-3'>Statut : {MultiUpdate?.multisteptable1?.final_status}</p>
                </div>
            </div>
            <div className="row text_top">
                <div className="col-lg-12 d-flex justify-between gray-bg1">
                    <div className='mt-3'>
                        <p className='bar1-head'>OUI<br /><span className='bar1-para'>ELIGIBILITÉ</span></p>
                    </div>
                    <div className='mt-3'>
                        <p className='bar1-head'>{MultiUpdate?.multisteptable2?.area}<br /><span className='bar1-para'>ZONE</span></p>
                    </div>
                    <div className='mt-3'>
                        <p className='bar1-head'>4<br /><span className='bar1-para'>RATIO PRIME</span></p>
                    </div>

                    <div className='mt-3'>
                        <p className='bar1-head'>{Number(MultiUpdate?.multisteptable2?.partnercumacvalue)}<br /><span className='bar1-para'>CUMAC</span></p>
                    </div>
                    <div className='mt-3'>
                        <p className='bar1-head'>{Number(MultiUpdate?.multisteptable2?.partnertotalpremiumamount)} €<br /><span className='bar1-para'>MONTANT TOTALE DE LA PRIME</span></p>
                    </div>
                    <div className='mt-3'>
                        <p className='bar1-head'>{Number(MultiUpdate?.multisteptable2?.partnertotalprice)}<br /><span className='bar1-para'>TOTAL TTC</span></p>
                    </div>
                </div>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col>
                        <Nav variant="pills" className=" m-0 reside w-100 justify-between">
                            <Nav.Item className='width-150'>
                                <Nav.Link className='reside1 reside2 text-center' eventKey="first">B2C</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='width-150'>
                                <Nav.Link className='reside1 reside2 text-center' eventKey="second">Operation</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='width-150'>
                                <Nav.Link className='reside1 reside2 text-center' eventKey="six">Estimate</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='width-150'>
                                <Nav.Link className='reside1 reside2 text-center' eventKey="third">Document</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='width-150'>
                                <Nav.Link className='reside1 reside2 text-center' eventKey="four">Contrôle</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='width-150'>
                                <Nav.Link className='reside1 reside2 text-center' eventKey="five">Devis et facture</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='width-150'>
                                <Nav.Link className='reside1 reside2 text-center' eventKey="seven">Errors</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='width-150'>
                                <Nav.Link className='reside1 reside2 text-center' eventKey="eight">statut</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane className='bg-white mt-4 reside2' eventKey="first">
                                <InfosB2C MultiUpdate={MultiUpdate} />
                            </Tab.Pane>
                            <Tab.Pane className='bg-white mt-4' eventKey="second">
                                <OperationB2C MultiUpdate={MultiUpdate} />
                            </Tab.Pane>
                            <Tab.Pane className='bg-white mt-4 ' eventKey="six">
                                <EstimateB2C MultiUpdate={MultiUpdate} />
                            </Tab.Pane>
                            <Tab.Pane className='bg-white mt-4 ' eventKey="third">
                                <DocumentB2C />
                            </Tab.Pane>
                            <Tab.Pane className='bg-white mt-4' eventKey="four">
                                <ControleB2C MultiUpdate={MultiUpdate} />
                            </Tab.Pane>
                            <Tab.Pane className='bg-white mt-4 ' eventKey="five">
                                <DevisDFAB2C MultiUpdate={MultiUpdate} />
                            </Tab.Pane>
                            <Tab.Pane className='bg-white mt-4 ' eventKey="seven">
                                <ErrorsPageB2C  MultiUpdate={MultiUpdate}/>
                            </Tab.Pane>
                            <Tab.Pane className='bg-white mt-4 ' eventKey="eight">
                                <StatusB2C MultiUpdate={MultiUpdate} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}

export default Editionc