import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs";
function Barth148() {
    const navigate = useNavigate();
    const handlePrev = () => {
      navigate(-1);
    };
    return (
        <div className='container-fluid py-3 px-4'>
            <div className='row'>
                <p className='bar1-para '>BAR-TH-148 : Chauffe-eau thermodynamique à accumulation <span onClick={handlePrev}  className='border-0 bg-white pe-auto'><BsArrowLeft className='left-arow pe-auto' /></span></p>
                <div className='button-1 mt-1'>
                    <Link to={"/calcult-bar-th-148"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
                </div>
                <div className='col bar1-tittle'>
                    <div className='bar1-tittle'>
                        <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                            <h3 className='bar1-head'>1. Secteur d’application</h3>
                            <p className='bar1-para'>Bâtiments résidentiels existants. </p>
                            <h3 className='bar1-head'>2. Dénomination</h3>
                            <p className='bar1-para'>Mise en place d’un chauffe-eau thermodynamique individuel à accumulation.</p>
                            <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
                            <p className='bar1-para'>Le COP de l’équipement mesuré conformément aux conditions de la norme EN 16147 est :  <br></br>
                                <span className='ms-4'>- u supérieur à 2,5 pour une installation sur air extrait ; </span><br></br>
                                <span className='ms-4'>-  et supérieur à 2,4 pour toutes autres installations. </span>
                            </p>
                            <p className='bar1-para'>Si le bénéficiaire est une personne physique,  le professionnel qui procède  à la  réalisation de
                                l’opération doit être titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues
                                à l’article 2 du décret n° 2014-812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de
                                l’article 200 quater du code général des impôts et du dernier alinéa du 2 du I de l’article 244 quater U
                                du  code  général  des  impôts  et  des  textes  pris  pour  son  application.  Ce  signe  de  qualité  correspond  à
                                des travaux relevant du 7 du I de l’article 46 AX de l’annexe III du code général des impôts.  </p>
                            <p className='bar1-para'>La preuve de réalisation de l’opération mentionne la mise en place d’un chauffe-eau thermodynamique
                                à  accumulation  et  le  COP  de  l’équipement  installé  explicitement  mesuré  selon  les  conditions  de  la
                                norme EN 16147. </p>

                            <p className='bar1-para'>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un équipement avec ses
                                marque et référence et elle est complétée par un document issu du fabricant indiquant que
                                l’équipement de marque et référence installé est un chauffe-eau thermodynamique à accumulation. Ce
                                document  précise  le  COP  de  l’équipement  installé  explicitement  mesuré  selon  les  conditions  de  la
                                norme EN 16147.
                            </p>
                            <p className='bar1-para'>Le document justificatif spécifique à l’opération est la décision de qualification ou de certification du
                                professionnel délivrée selon les mêmes exigences que celles prévues à l’article 2 du décret susvisé.
                            </p>
                            <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                                <span className='bar1-para'>17 ans.</span></h3>

                            <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac</p>
                            <p className='bar1-ul bar1-para '>
                                Pour les opérations engagées du 01/01/2015 au 25/09/2017 inclus : </p>

                            <div className="row">
                                <div className="col-4">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th >Type de logement</th>
                                                <th >Montant unitaire en kWh cumac </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'>
                                                <td>Maison individuelle </td>
                                                <td>21 100</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Appartement</td>
                                                <td>16 200</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <p className='bar1-ul bar1-para mt-3'>Pour les opérations engagées à partir du 26/09/2017 :</p>

                            <div className="row">
                                <div className="col-4">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th >Type de logement</th>
                                                <th >Montant unitaire en
                                                    kWh cumac </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'>
                                                <td>Maison individuelle </td>
                                                <td>15 600</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Appartement</td>
                                                <td>11 900</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='bar1-content mt-4 pb-4'>
                                <p className='bar1-para para-bar '>Fourniture et pose par un professionnel RGE d’un procédé d’isolation en comble perdu ou en rampant de toiture dans un bâtiment résidentiel existant. Un pare-vapeur (ou équivalent) est posé pour protéger l’isolant thermique de l'humidité et de la condensation. </p>
                                <p className="bar1-head para-bar1 ">Éligible si : </p>
                                <ul className='ul-bar1'>  <li><span className='bar1-ul '>Le devis : </span>comporte la date de la pré-visite technique.</li>
                                    <li><span className='bar1-ul'>Le début des travaux</span> respecte un délai<span className='bar1-ul'> de 8 jours après la date de signature</span> de la commande.</li>
                                    <li><span className='bar1-ul'>L’isolant :</span> a une résistance thermique ≥ à 7 m².K/W en comble perdu et ≥ à 6 m².K/W en rampant de toiture, est certifié ACERMI (ou équivalent), est de classe A de protection au feu (ou équivalent ou pose d’une couche supplémentaire).</li>
                                    <li><span className='bar1-ul'>Sont installés :</span>  un coffrage autour des conduits de fumées et des éclairages encastrés ; une rehausse rigide au niveau de la trappe d’accès.</li></ul>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>La facture indique obligatoirement :</span> fourniture et pose d’une isolation de comble ou toiture, marque, référence, épaisseur, résistance thermique, n° ACERMI et surface réellement isolée (+ l’identité du sous-traitant, si c’est le cas), les aménagements réalisés, la présence d’un pare-vapeur + la date de pré-visite technique. </p>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>Contrôle post-chantier :</span> tous les chantiers font l’objet d’un questionnaire de satisfaction client, certains font l’objet d’une inspection qualité post-travaux par un organisme indépendant (attesté COFRAC).</p>

                                <p className='bar1-para para-bar1'><span className='bar1-head '>Attention :</span> Sans modification sous 5 ans, cette opération sera abrogée à partir du 1er mai 2027.</p>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime. En cliquant sur le Info , vous obtenez plus de détail sur une question.</p>

                            </div>
                            {/* <div className='button-1'>
                                <button type='button' className='btn-on' >Site du Ministère <TbMathGreater /> </button>
                            </div>
                            <div className='button-1 mb-3'>
                                <button type='button' className='btn-on' ><AiOutlineDownload />Télécharger le fiche </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Barth148;