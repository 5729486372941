import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";

const Calcult160Update = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    // ----------------------------------------------
    // useEffect(() => {
    //     const storedFormValues = sessionStorage.getItem('step-6');

    //     if (storedFormValues) {
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.beneficiaryOperation = parsedFormValues.beneficiaryOperation;
    //         initialValues.signature = parsedFormValues.signature;
    //         initialValues.residential_2years_old = parsedFormValues.residential_2years_old;
    //         initialValues.codepostal = parsedFormValues.codepostal;
    //         initialValues.installation = parsedFormValues.installation;
    //         initialValues.hydraulicheating = parsedFormValues.hydraulicheating;
    //         initialValues.hydraulicnetwork = parsedFormValues.hydraulicnetwork;
    //         initialValues.primeamount = parsedFormValues.primeamount;
    //         initialValues.replaced_after_2018 = parsedFormValues.replaced_after_2018;
    //     }
    // }, []);
    const [initialValues, setInitialValues] = useState(
        {
        beneficiaryOperation: '',
        residential_2years_old: '',
        signature: '',
        codepostal: '',
        installation: '',
        hydraulicheating: '',
        hydraulicnetwork: '',
        primeamount: '',
        replaced_after_2018: '',
        departmental_precariousness: '',
        total_homes_affectedv1: '',
        approved_housing: '',
        total_homes_affectedv2: '',
        empty_households: '',
        classified_precarious: '',
        highly_precarious: '',
        without_proof: '',
        district_city_policy: '',
    });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            beneficiaryOperation: MultiUpdate?.multisteptable2?.beneficiaryOperation || '',
            residential_2years_old: MultiUpdate?.multisteptable2?.residential_2years_old || '',
            signature: MultiUpdate?.multisteptable2?.signature || '',
            codepostal: MultiUpdate?.multisteptable2?.codepostal || '',
            installation: MultiUpdate?.multisteptable2?.installation || '',
            hydraulicheating: MultiUpdate?.multisteptable2?.hydraulicheating || '',
            hydraulicnetwork: MultiUpdate?.multisteptable2?.hydraulicnetwork || '',
            primeamount: MultiUpdate?.multisteptable2?.primeamount || '',
            replaced_after_2018: MultiUpdate?.multisteptable2?.replaced_after_2018 || '',
        }));
    }, []);
    const validate = (values) => {
        const errors = {};
        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.hydraulicnetwork) {
            errors.hydraulicnetwork = "Longueur totale du (ou des) réseau(x) hydraulique(s) isolé(s) (en m linéaire) requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/update-estimate");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
   
  return (
    <div>
              <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid  '>
                        <div className='row'>
                            <div className='col'>
                                <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Isolation d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire</p>
                                <div className='bar1-content mt-4 pb-4'>
                                    <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                    <div className=''>
                                        <label className=' para-bar1'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="beneficiaryOperation"
                                                id="beneficiaryOperation"
                                                value="Bailleur Social"
                                                onChange={handleChange}
                                                checked={values.beneficiaryOperation === "Bailleur Social"} />
                                            <span className='bar1-para span-bar'>
                                                Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                    Copropriété privée, Syndic </span>et autres cas</span>
                                        </label>
                                        <label className=' para-bar1 mt-1'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="beneficiaryOperation"
                                                id="beneficiaryOperation"
                                                value="Copropriété privée"
                                                onChange={handleChange}
                                                checked={values.beneficiaryOperation === "Copropriété privée"} />
                                            <span className='span-bar bar1-para'>
                                                <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                            </span>
                                        </label>
                                        <div className='button-1 para-bar1'>
                                            <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Bailleur Social */}
                                {values.beneficiaryOperation === 'Bailleur Social' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <div className='p-4'>
                                            <label className=' para-bar1'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="departmental_precariousness"
                                                    id="departmental_precariousness"
                                                    value="précarité départementale"
                                                    onChange={handleChange}
                                                    checked={values.departmental_precariousness === "précarité départementale"} />
                                                <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                            </label>
                                            <label className=' para-bar1 mt-1'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="departmental_precariousness"
                                                    id="departmental_precariousness"
                                                    value="de précarité réel"
                                                    onChange={handleChange}
                                                    checked={values.departmental_precariousness === "de précarité réel"} />
                                                <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                )}

                                {/* Depatmental */}
                                {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <div className='p-3'>
                                            <label className=' para-bar1 d-block'>
                                                <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                <input
                                                    type='number'
                                                    name="total_homes_affectedv1"
                                                    id="total_homes_affectedv1"
                                                    value={values.total_homes_affectedv1}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                <input
                                                    type='number'
                                                    name="approved_housing"
                                                    id="approved_housing"
                                                    value={values.approved_housing}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                <input
                                                    type='number'
                                                    name="empty_households"
                                                    id="empty_households"
                                                    value={values.empty_households}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                        </div>
                                    </div>
                                )}
                                {/* reel */}
                                {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <div className='p-3'>
                                            <label className=' para-bar1 d-block'>
                                                <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                <input
                                                    type='total_homes_affectedv2'
                                                    name="total_homes_affectedv2"
                                                    id="total_homes_affectedv2"
                                                    value={values.total_homes_affectedv2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1"></input>
                                            </label>
                                            <label className='para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                <input
                                                    type='number'
                                                    name="classified_precarious"
                                                    id="classified_precarious"
                                                    value={values.classified_precarious}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                <input
                                                    type='number'
                                                    name="highly_precarious"
                                                    id="highly_precarious"
                                                    value={values.highly_precarious}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                <input
                                                    type='number'
                                                    name="without_proof"
                                                    id="without_proof"
                                                    value={values.without_proof}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                        </div>
                                    </div>
                                )}

                                {/* Copriete */}
                                {values.beneficiaryOperation === 'Copropriété privée' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                        <div className=''>
                                            <label className=' para-bar1 d-block'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="district_city_policy"
                                                    id="district_city_policy"
                                                    value="Yes"
                                                    onChange={handleChange}
                                                    checked={values.district_city_policy === "Yes"} />
                                                <span className='bar1-para span-bar'>Oui</span>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="district_city_policy"
                                                    id="district_city_policy"
                                                    value="Non"
                                                    onChange={handleChange}
                                                    checked={values.district_city_policy === "Non"} />
                                                <span className='span-bar bar1-para'>Non</span>
                                            </label>
                                        </div>
                                    </div>
                                )}
                                <p className='bar1-head mt-3'>Date du devis de signature :
                                    <input type="date"
                                        name='signature'
                                        id='signature'
                                        value={values.signature}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-control1" ></input></p>
                                <hr></hr>
                                <p className="bar1-head  mt-4">Bâtiment(s) résidentiel(s) collectif de plus de 2 ans ?</p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Yes'
                                            name="residential_2years_old"
                                            id="residential_2years_old"
                                            onChange={handleChange}
                                            checked={values.residential_2years_old === "Yes"} />
                                        <span className='span-bar bar1-para'>Oui</span>

                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='No'
                                            name="residential_2years_old"
                                            id="residential_2years_old"
                                            onChange={handleChange}
                                            checked={values.residential_2years_old === "No"} />
                                        <span className='span-bar bar1-para '> Non</span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-sm-12">
                                        <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <input
                                            type="number"
                                            name='codepostal'
                                            id='codepostal'
                                            value={values.codepostal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                    </div>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">L’opération consiste en la mise en place ?</p>
                                <div className='text-start'>
                                    <label className='para-bar1'>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            value='hydraulic heating'
                                            name="installation"
                                            id="installation"
                                            onChange={handleChange}
                                            checked={values.installation === "hydraulic heating"} />
                                        <span className='span-bar bar1-para'>d’une isolation sur un réseau hydraulique de chauffage ou d’ECS</span> </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            value='pre-insulated pipe'
                                            name="installation"
                                            id="installation"
                                            onChange={handleChange}
                                            checked={values.installation === "pre-insulated pipe"} />
                                        <span className='span-bar bar1-para '>d’une canalisation pré-isolée</span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">Le réseau hydraulique de chauffage ou d’ECS est installé depuis plus de 2 ans ?</p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Yes'
                                            name="hydraulicheating"
                                            id="hydraulicheating"
                                            onChange={handleChange}
                                            checked={values.hydraulicheating === "Yes"} />
                                        <span className='span-bar bar1-para'>Oui</span> </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='No'
                                            name="hydraulicheating"
                                            id="hydraulicheating"
                                            onChange={handleChange}
                                            checked={values.hydraulicheating === "No"} />
                                        <span className='span-bar bar1-para '>
                                            Non
                                        </span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">La chaudière ou le réseau hydraulique a été refait/remplacé après le 01/01/2018 ?</p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Yes'
                                            name="replaced_after_2018"
                                            id="replaced_after_2018"
                                            onChange={handleChange}
                                            checked={values.replaced_after_2018 === "Yes"} />
                                        <span className='span-bar bar1-para'> Oui</span></label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='NO'
                                            name="replaced_after_2018"
                                            id="replaced_after_2018"
                                            onChange={handleChange}
                                            checked={values.replaced_after_2018 === "No"} />
                                        <span className='span-bar bar1-para '>Non, aucune modification après le 01/01/2018</span>
                                    </label></div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-sm-12">
                                        <p className='bar1-head mt-3'>Longueur totale du (ou des) réseau(x) hydraulique(s) isolé(s) (en m linéaire)* :</p>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <input
                                            type="text"
                                            name='hydraulicnetwork'
                                            id='hydraulicnetwork'
                                            value={values.hydraulicnetwork}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.hydraulicnetwork && errors.hydraulicnetwork ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.hydraulicnetwork && errors.hydraulicnetwork && <div className="error-message">{errors.hydraulicnetwork}</div>}
                                    </div>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div>
                                <hr></hr>
                                <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                <div className='text-start'>
                                    <label >
                                        <input
                                            type="number"
                                            name='primeamount'
                                            id='primeamount'
                                            value={values.primeamount}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="input-box"  ></input>
                                        <span className='span-bar bar1-para' >Euros du Méga / Prix du kilowater</span>
                                    </label>

                                </div><hr></hr>

                                <div className='row mt-5 mb-2'>
                                    <div className='col text-start'>
                                        <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                    </div>
                                    <div className="col text-end">
                                        <button type='submit' className='btns-color'>Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )
            }
        </Formik>
    </div>
  )
}

export default Calcult160Update
