import React, { useState, useRef } from 'react'
import upload from '../../assets/upload 1.svg'
import { useNavigate } from 'react-router-dom';
import useFileUpload from 'react-use-file-upload';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RiDeleteBin6Line, RiEyeLine } from "react-icons/ri";
import { IoDocumentTextSharp } from "react-icons/io5";
import { Spinner } from 'react-bootstrap'

function Uploads() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);
    const Token = localStorage.getItem('Token');
    const inputRef = useRef();

    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();
    console.log(files);
    // FILE UPLOAD
    const MAX_TOTAL_SIZE_MB = 10;
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (files.length > 4) {
            toasterror('Vous ne pouvez télécharger que 4 fichiers au maximum.');
            return;
        }  // Calculate total size of uploaded files
        const totalUploadedSize = files.reduce((total, file) => total + file.size, 0);

        if (totalUploadedSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
            toasterror('La taille totale des fichiers ne peut pas dépasser 10 Mo.');
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });
        if (files.length > 0) {
            try {
                axios.post(`${process.env.REACT_APP_API_URL}/upload-files`, formData, {
                    headers: {
                        authorization: Token,
                        'Content-Type': 'multipart/form-data',
                    },
                }).then((response) => {
                    console.log(response);
                    sessionStorage.setItem('userFiles', JSON.stringify(response?.data?.files));
                    setIsLoading(false);
                    toastsuccess(response?.data?.message);
                    navigate('/control-contribution');
                })
            } catch (error) {
                console.error(error?.response?.data?.message);
                setIsLoading(false);
                toasterror(error?.response?.data?.message);
            }
        } else {
            navigate('/control-contribution');
        }
    };

    const handlePrev = () => {
        navigate(-1);
    };


    const handleFileClick = (name) => {
        // Assuming the name corresponds to the file you want to open
        const file = files.find((file) => file.name === name);

        if (file) {
            const fileUrl = URL.createObjectURL(file);
            window.open(fileUrl, "_blank");
        } else {
            // Handle the case where the file with the given name is not found
        }
    };
    if (isLoading) {
        return <Spinner animation="border" className='text-success' />;
    };
    return (
        <div className="container-fluid px-4 py-3">
            <div className="bar1-para">Votre project : Réf ISE0015455 : Isolation des combles ou de toiture / Envoyer un document</div>
            <div className="row mt-5 justify-space ">
                <div className="col-lg-7 mt-3">
                    {/* <p className='bar1-para bar1-ul'>Attestation sur l’Honneur.pdf</p>
                    <p className='bar1-para bar1-ul'>Facture.pdf</p>
                    <p className='bar1-para bar1-ul'>Devis.pdf </p>
                    <p className='bar1-para bar1-ul'>Cadre.pdf</p> */}

                    {/* LOCAL STORAGE DATA SHOW */}
                    <div className="form-container-fluid">
                        <div >
                            <ul className='form-data-text'>
                                {fileNames?.map((name) => (
                                    <li key={name}>
                                        <span className='bar1-para bar1-ul'>
                                            <IoDocumentTextSharp /> {name}</span> <span onClick={() => removeFile(name)}><RiDeleteBin6Line /></span>
                                        <span><button type='button' className='border-0 bg-white' onClick={() => handleFileClick(name)}><RiEyeLine /></button></span>
                                        {/* <Link to={name} target='_blank' className='bar1-para bar1-ul'><IoDocumentTextSharp /> {name}</Link> <span onClick={() => removeFile(name)}><RiDeleteBin6Line /></span> */}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 border-lined ">
                    <div className='text-center mt-3'>
                        <p className='bar1-head text-center '>Téléchargement de fichiers</p>
                        <img src={upload} alt="upload icon" className="text-center ht-100 "></img>
                        <p className='bar1-para1 mt-3'>Sélectionnez un fichier ou faites-le glisser ici</p>
                        <div className=''>
                            <button type='button ' className='btn-upload' onClick={() => inputRef.current.click()}>Sélectionnez un fichier  </button>
                            <div
                                onDragEnter={handleDragDropEvent}
                                onDragOver={handleDragDropEvent}
                                onDrop={(e) => {
                                    handleDragDropEvent(e);
                                    setFiles(e, 'a');
                                }}
                            >
                                <input
                                    ref={inputRef}
                                    type="file"
                                    multiple
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setFiles(e, 'a');
                                        inputRef.current.value = null;
                                    }}
                                />
                            </div>
                        </div>
                        {files?.length > 0 && (
                            <>
                                <p className='bar1-para1 mt-3'>Type de fichier : {fileTypes.join(', ')} <br></br>
                                    Taille du fichier : {totalSize}</p>
                            </>
                        )}
                        {files?.length == 0 && (
                            <p className='bar1-para1 mt-3'>Type de fichier : DOC, PDF, JPEG<br />Taille du fichier : 1 MB</p>
                        )}
                    </div>
                </div>
                <div className='mt-5 mb-3  text-end'>
                    {/* <button type='button' className='btns-color3' onClick={() => clearAllFiles()}>Annuler </button> */}
                    {/* <button type='button' className='btns-color3' onClick={handleSubmit}>Télécharger</button> */}
                </div>

                <div className='row mt-5 mb-2'>
                    <div className='col text-start'>
                        <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                    </div>
                    <div className="col text-end">
                        {/* <Link to='/control-contribution' className='btns-color px-5 py-2'>Suivant</Link> */}
                        <button onClick={handleSubmit} className='btns-color px-5 py-2'>Suivant</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Uploads;