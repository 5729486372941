import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// FORMIK
import { Formik } from 'formik';
import axios from 'axios'
import { toast } from 'react-toastify';
function Adduser() {
    const navigate = useNavigate();
    const toastsuccess = (val) => toast.success(val);
    const initialValues = {
        name: '',
        firstName: '',
        role: '',
        address: '',
        cp: '',
        city: '',
        emailId: '',
        N_fixed: '',
        phone: '',
        password: '',
        oblige_id: '',
        installer_id: '',
    }
    // VALIDATE
    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Nom requise';
        }
        if (!values.role) {
            errors.role = 'Sa fonction requise';
        }
        if (!values.emailId) {
            errors.emailId = 'Email requise';
        }
        if (!values.password) {
            errors.password = 'Password requise';
        }
        return errors;
    }
    const Token = localStorage.getItem('Token');
    const handleSubmit = async (values, { resetForm }) => {
        console.log(values);
        try {
            const adduser = await axios.post(`${process.env.REACT_APP_API_URL}/adduser`, values, {
                headers:
                    { authorization: Token }
            });
            if (adduser.status === 200) {
                console.log(adduser);
                navigate('/users-dashboard');
                toastsuccess(adduser?.data?.message);
            }
        } catch (error) {
            console.error(error?.adduser?.data?.message);
            toastsuccess(error?.adduser?.data?.message);
        }
        resetForm();
    }

    const [partnersDetails, setpartnersDetails] = useState([]);
    const [obligeDetails, setobligeDetails] = useState([]);

    useEffect(() => {
        const Token = localStorage.getItem('Token');
        const fetchData = async () => {
            const option = {
                headers:
                    { authorization: Token }
            }
            try {
                await axios.get(`${process.env.REACT_APP_API_URL}/getallname-installer`, option)
                    .then(res => {
                        console.log(res?.data);
                        setpartnersDetails(res?.data);
                    })
            } catch (error) {
                console.error(error);
            }
            try {
                await axios.get(`${process.env.REACT_APP_API_URL}/getallname-oblige`, option)
                    .then(res => {
                        console.log(res?.data);
                        setobligeDetails(res?.data);
                    })
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid-fluid pl-30'>
                        <p className='bar1-head'>Ajouter un utilisateur</p>

                        <div className='row '>
                            <div className='col-sm-5 input-box1'>
                                <p className='bar1-para'>Nom*</p>
                                <input type="text"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.name && errors.name ? 'error' : 'form-control1 text-initial w-100 m-0'}
                                    placeholder='Nom' />
                                {touched.name && errors.name && <div className="error-message">{errors.name}</div>}
                            </div>

                            <div className='col-sm-2 input-box1'>
                                <p className='bar1-para'>Prénom</p>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.firstName && errors.firstName ? 'error' : ' w-100 form-controlz'} />
                            </div>
                            <div className='col-sm-5 input-box1'>
                                <p className='bar1-para'>Sa fonction*</p>
                                <div className='d-flex'>
                                    <select
                                        name='role'
                                        id='role'
                                        value={values.role}
                                        onChange={handleChange}
                                        className={touched.role && errors.role ? 'error' : 'form-select ps-3 btn-buttons '} aria-label="role">
                                        <option defaultValue >-- Choisir un type --</option>
                                        <option value="Agent administratif" >Agent administratif</option>
                                        <option value="Agent bureau de contrôle">Agent bureau de contrôle</option>
                                        <option value="Agent technique">Agent technique</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Super Manager">Super Manager</option>
                                        <option value="Technicien">Technicien</option>
                                        <option value="Installer">Installer</option>
                                        <option value="Oblige">Oblige</option>
                                    </select>
                                    {touched.role && errors.role && <div className="error-message">{errors.role}</div>}
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-6 input-box1'>
                                <p className='bar1-para'>Adresse</p>
                                <input
                                    type="text"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.address && errors.address ? 'error' : 'form-control1 w-100 m-0'} />
                            </div>
                            <div className='col-sm-2 input-box1'>
                                <p className='bar1-para'>CP</p>
                                <input
                                    type="number"
                                    name="cp"
                                    value={values.cp}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.cp && errors.cp ? 'error' : 'form-control1 w-100 m-0'} />
                            </div>
                            <div className='col-sm-4 input-box1'>
                                <p className='bar1-para'>Ville</p>
                                <input
                                    type="text"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.city && errors.city ? 'error' : 'form-control1 w-100 m-0'} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-6 input-box1'>
                                <p className='bar1-para'>Email*</p>
                                <input
                                    type="email"
                                    name="emailId"
                                    value={values.emailId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.emailId && errors.emailId ? 'error' : 'form-control1 w-100 m-0'} />
                                {touched.emailId && errors.emailId && <div className="error-message">{errors.emailId}</div>}
                            </div>
                            <div className='col-sm-2 input-box1'>
                                <p className='bar1-para'>N fixe</p>
                                <input
                                    type="number"
                                    name="N_fixed"
                                    value={values.N_fixed}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.N_fixed && errors.N_fixed ? 'error' : 'form-control1 w-100 m-0'} />
                            </div>
                            <div className='col-sm-4 input-box1'>
                                <p className='bar1-para'>N mobile</p>
                                <input
                                    type="text"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.phone && errors.phone ? 'error' : 'form-control1 w-100 m-0'} />
                            </div>
                            <div className='col-sm-4 input-box1'>
                                <p className='bar1-para'>Password</p>
                                <input
                                    type="text"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.password && errors.password ? 'error' : 'form-control1 w-100 m-0'} />
                            </div>
                            {values?.role === 'Installer' &&
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>Installer</p>
                                    <select name='installer_id' id='installer_id' onChange={handleChange} aria-label="PARTENAIRE/INSTALLATEUR" className='form-select ps-3 btn-buttons'>
                                        <option>-- Choisir un installer --</option>
                                        {partnersDetails?.installer?.map((items) => (
                                            <option key={items?.id} value={items?.id}>{items?.tradename}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {values?.role === 'Oblige' &&
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>Oblige</p>
                                    <select
                                        name='oblige_id' id='oblige_id' onChange={handleChange} aria-label="OBLIGÉ" className='form-select ps-3 btn-buttons'>
                                        <option>-- Choisir un oblige --</option>
                                        {obligeDetails?.oblige?.map((items) => (
                                            <option key={items?.id} value={items?.id}>{items?.tradename}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                        </div>
                        <div className='mt-4 mb-3  text-start'>
                            <button type='submit' className='btns-color'>Continuer </button>
                        </div>
                    </div>
                </form>
            )
            }
        </Formik >
    )
}

export default Adduser