import React from 'react'
import { Link } from "react-router-dom"
const RESEC104Update = () => {
    return (
        <>
        <div className='container-fluid '>
                <div className='row'>
                    <p className='bar1-para '>RES-EC-104 : Rénovation d’éclairage extérieur  </p>
                    <div className='button-1 mt-1'>
                       <Link to={"/update-calcult-resec104"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
                    </div>
                    <div className='col bar1-tittle'>
                        <div className='bar1-tittle'>
                            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                                <h3 className='bar1-head'>1. Secteur d’application</h3>
                                <p className='bar1-para'>Éclairage public extérieur existant, autoroutier, routier, urbain, dit « fonctionnel », permettant tous les types de
                                    circulation (motorisée, cycliste). </p>
                                <p className='bar1-para'>Éclairage existant d’ambiances urbaines : rues, avenues, parcs, allées, voies piétonnes.</p>
                                <p className='bar1-para'>Éclairage extérieur privé existant : voiries, parkings, parcs, etc. </p>
                                <p className='bar1-para'>Cette opération ne concerne ni les illuminations de mise en valeur des sites ni l’éclairage des terrains de sport. </p>
                                <h3 className='bar1-head'>2. Dénomination de l’opération</h3>
                                <p className='bar1-para'>Rénovation d’éclairage extérieur par dépose de luminaires et mise en place de luminaires neufs dont la source
                                    lumineuse peut être remplacée. </p>

                                <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
                                <p className='bar1-para'>Est éligible à cette action toute rénovation pour laquelle chaque luminaire neuf respecte les exigences suivantes : </p>
                                <p className='bar1-para'>- ensemble optique fermé d’un degré de protection (IP) de 65 minimum ; </p>
                                <p className='bar1-para'>- cas n°1 : efficacité lumineuse ≥ 90 lumens par Watt et ULOR ≤ 1 % (ou, pour les luminaires à LED, ULR ≤ 3%). </p>
                                <p className='bar1-para'>- cas n°2 : efficacité lumineuse ≥ 70 lumens par Watt et ULOR ≤ 10 % (ou, pour les luminaires à LED,
                                    ULR ≤ 15 %) </p>
                                <p className='bar1-para'>L'efficacité lumineuse est le ratio entre le flux lumineux initial total sortant et la puissance totale du système (y
                                    compris les auxiliaires). Les luminaires utilisés pour l’éclairage fonctionnel des voies de circulation doivent
                                    respecter les conditions du cas n°1.
                                </p>
                                <p className='bar1-para'>La mise en place est réalisée par un professionnel.</p>

                                <h3 className='bar1-head'> La preuve de réalisation de l'opération mentionne :</h3>
                                <ol className='ul-bar1 text-start'>
                                    <li> - la dépose des luminaires existants ; </li>
                                    <li>la mise en place de luminaires neufs ;</li>
                                    <li>et le nombre et les caractéristiques des luminaires neufs installés : degré de protection de l'ensemble optique
                                        fermé (IP), efficacité lumineuse en lumen par Watt, et ULOR (ou ULR pour les luminaires à LED). </li>
                                </ol>


                                <p className='bar1-para'>Par dérogation aux points 2 et 3 ci-dessus, la preuve de réalisation de l'opération mentionne la mise en place d'un
                                    nombre donné d'équipements, identifiés par leurs marque et référence précises, et est accompagnée par un document
                                    issu du fabricant. Ce document mentionne que l'équipement de marque et référence mis en place est un luminaire,
                                    avec ses caractéristiques : degré de protection de l'ensemble optique fermé (IP), efficacité lumineuse en lumen par
                                    Watt, et ULOR (ou ULR pour les luminaires à LED).
                                </p>

                                <h3 className='bar1-head'>4. Durée de vie conventionnelle</h3>
                                <p className='bar1-para'>30 ans. </p>
                                <h3 className='bar1-head'>5. Montant de certificats en kWh cumac</h3>
                                <h3 className='bar1-head my-2'>Pour le cas n°1 :</h3>
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th colSpan="3">Montant en kWh cumac par luminaire installé</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para text-center h-100'> <td className='paddings-bottom  text-center'>9300</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-1 ">
                                        <h1 className=''>*</h1>
                                    </div>
                                    <div className="col-lg-3">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th colSpan="3">Nombre de luminaires installés</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para text-center h-100'> <td className='paddings-bottom  text-center'>N1</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/*  */}
                                <h3 className='bar1-head'>Pour le cas n°2 :</h3>
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th colSpan="3">Montant en kWh cumac par luminaire installé</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para text-center h-100'> <td className='paddings-bottom  text-center'>7200</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-1 ">
                                        <h1 className=' '>*</h1>
                                    </div>
                                    <div className="col-lg-3">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th colSpan="3">Nombre de luminaires installés</th> </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para text-center h-100'> <td className='paddings-bottom  text-center'>N2 </td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RESEC104Update