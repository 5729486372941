import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    category: '',
    selectedTab: '',
    B2BFormData: {},
    // login: !!localStorage.getItem('Token'),
    Token: localStorage.getItem('Token'),
    B2BFormProcess: [sessionStorage.getItem('step-2'), sessionStorage.getItem('step-3'), sessionStorage.getItem('step-4'), sessionStorage.getItem('step-5'), sessionStorage.getItem('step-6'), sessionStorage.getItem('step-7'), sessionStorage.getItem('userFiles'), sessionStorage.getItem('step-8'), sessionStorage.getItem('step-9'), sessionStorage.getItem('step-10'), sessionStorage.getItem('step-11'), sessionStorage.getItem('step-12'), sessionStorage.getItem('step-13'), sessionStorage.getItem('step-14'),sessionStorage.getItem('step-15')],
    MultiUpdate: [sessionStorage.getItem('multiupdate')],
}

export const ReduxSlice = createSlice({
    name: 'winenergy',
    initialState: initialState,
    reducers: {
        setCategory: (state, action) => {
            state.category = action.payload;
            state.selectedTab = '';
            state.B2BFormData = {};
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
            state.B2BFormData = {};
        },
        setB2BFormData: (state, action) => {
            state.B2BFormData = action.payload;
        },
        // loginState: (state, action) => {
        //     state.login = action.payload.loginSet;
        // },
    }
})
// export const { setCategory, setSelectedTab, setB2BFormData, loginState } = ReduxSlice.actions;
export const { setCategory, setSelectedTab, setB2BFormData } = ReduxSlice.actions;
export default ReduxSlice.reducer;