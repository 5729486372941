import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
// Import Redux toolkit
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
const UpdateCreateDossier = () => {
  const { id } = useParams();
  console.log(id);
  const Token = localStorage.getItem('Token');

  const [data, setData] = useState(null);
  const [partnersDetails, setPartnersDetails] = useState([]);
  const [obligeDetails, setObligeDetails] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      const options = {
        headers: { authorization: Token }
      };

      try {
        const [multistepResponse, installerResponse, obligeResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/get-multistep/${id}`, options),
          axios.get(`${process.env.REACT_APP_API_URL}/getallname-installer`, options),
          axios.get(`${process.env.REACT_APP_API_URL}/getallname-oblige`, options)
        ]);
        setData(multistepResponse.data);
        sessionStorage.setItem("multiupdate", JSON.stringify(multistepResponse.data));
        setPartnersDetails(installerResponse.data);
        setObligeDetails(obligeResponse.data);
        console.log('multistepResponse.data', multistepResponse.data, 'installerResponse.data', installerResponse.data, 'obligeResponse.data', obligeResponse.data);
      } catch (error) {
        console.error(error); 
      }
    };

    fetchData();
  }, [Token]);
  // INITIAL VALUES
  const [initialValues, setInitialValues] = useState({
    id: '',
    serviceSelect: '',
    installer_id: '',
    oblige_id: '',
  });
  useEffect(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      id: id,
      serviceSelect: data?.multisteptable1?.serviceselect || '',
      installer_id: data?.multisteptable1?.installer_id || '',
      oblige_id: data?.multisteptable1?.oblige_id || '',
    }));
  }, [data]);
  console.log(data?.multisteptable1?.serviceselect);

  // VALIDATE
  const validate = (values) => {
    const errors = {};

    return errors;
  }
  // Values Submit
  const handleSubmit = (values) => {
    console.log({ "values": values });
    sessionStorage.setItem("step-2", JSON.stringify(values));
    if (values?.serviceSelect === 'b2b') {
      navigate('/update-b2b-beneficiaire');
    } else if (values?.serviceSelect === 'b2c') {
      navigate('/update-b2c');
    }
  }
  return (
    <>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="container-fluid-fluid pl-30 ">
              <p className='bar1-head '> Je creé un dossier  </p>
              <Row>
                <Col>
                  <div className="b2bbutton">
                    <input type="radio" id="b2b" name='serviceSelect' value="b2b" onChange={handleChange} checked={values.serviceSelect === 'b2b'} />
                    <label className="btn btn-default radio-createdesire" htmlFor="b2b">B2B</label>
                  </div>
                  <div className="b2bbutton">
                    <input type="radio" id="b2c" name='serviceSelect' value="b2c" onChange={handleChange} checked={values.serviceSelect === 'b2c'} />
                    <label className="btn btn-default radio-createdesire" htmlFor="b2c">B2C</label>
                  </div>
                  {touched.serviceSelect && errors.serviceSelect && (
                    <div className="error-message">{errors.serviceSelect}</div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={12}>
                  <div className='top-marign '>
                    <Form.Select name='installer_id' id='installer_id' value={values.installer_id} onChange={handleChange} aria-label="PARTENAIRE/INSTALLATEUR" className='reside1 reside1 w-30'>
                      <option>PARTENAIRE/INSTALLATEUR</option>
                      {partnersDetails?.installer?.map((items) => (
                        <option key={items?.id} value={items?.id}>{items?.tradename}</option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className='top-marign'>
                    <Form.Select name='oblige_id' id='oblige_id' value={values.oblige_id} onChange={handleChange} aria-label="OBLIGÉ" className='reside1 reside1 w-30'>
                      <option>OBLIGÉ</option>
                      {obligeDetails?.oblige?.map((items) => (
                        <option key={items?.id} value={items?.id}>{items?.tradename}</option>
                      ))}
                    </Form.Select>
                  </div>
                  {touched.installerPartner && errors.installerPartner && (
                    <div className="error-message">{errors.installerPartner}</div>
                  )}
                </Col>
              </Row>
              <div className='row mt-5 mb-2'>
                <div className="col text-end">
                  <button type='submit' className='btns-color' >Suivant</button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default UpdateCreateDossier