import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
function ResEc104Calcult() {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const same_addressget = JSON.parse(sessionStorage.getItem('step-3'));
    console.log('same_address', same_addressget);
    const same_address = same_addressget.beneficiary_address;
    const same_cp = same_addressget.beneficiary_cp;
    const same_ville = same_addressget.beneficiary_city;
    console.log('same_address', same_address);
    const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
    // ----------------------------------------------
    useEffect(() => {
        const storedFormValues = sessionStorage.getItem('step-6');

        if (storedFormValues) {
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.dateofcommitment = parsedFormValues.dateofcommitment;
            initialValues.dateofproof = parsedFormValues.dateofproof;
            initialValues.codepostal = parsedFormValues.codepostal;
            initialValues.invoicereference = parsedFormValues.invoicereference;
            initialValues.addressofwork = parsedFormValues.addressofwork;
            initialValues.additionaladdress = parsedFormValues.additionaladdress;
            initialValues.luminaires = parsedFormValues.luminaires;
            initialValues.lighting = parsedFormValues.lighting;
            initialValues.brand = parsedFormValues.brand;
            initialValues.reference = parsedFormValues.reference;
            initialValues.model = parsedFormValues.model;
            initialValues.types = parsedFormValues.types;
            initialValues.noofnewlights = parsedFormValues.noofnewlights;
            initialValues.brandv2 = parsedFormValues.brandv2;
            initialValues.modelv2 = parsedFormValues.modelv2;
            initialValues.typev2 = parsedFormValues.typev2;
            initialValues.noofnewlightsv2 = parsedFormValues.noofnewlightsv2;
            initialValues.city = parsedFormValues.city;
        }
    }, []);
    const initialValues = {
        dateofcommitment: '',
        dateofproof: '',
        codepostal: '',
        invoicereference: '',
        addressofwork: '',
        additionaladdress: '',
        luminaires: '',
        lighting: '',
        brand: '',
        reference: '',
        model: '',
        types: '',
        noofnewlights: '',
        brandv2: '',
        modelv2: '',
        typev2: '',
        noofnewlightsv2: '',
        city: '',
    };
    const handleCheckboxChange = (e, setFieldValue) => {

        const isChecked = e.target.checked;
        setIsSameAddressChecked(isChecked);
        if (isChecked) {
            setFieldValue('addressofwork', same_address);
            setFieldValue('codepostal', same_cp);
            setFieldValue('city', same_ville);
        } else {
            setFieldValue('addressofwork', initialValues.addressofwork);
            setFieldValue('codepostal', initialValues.codepostal);
            setFieldValue('city', initialValues.city);
        }
    };
    const validate = (values) => {
        const errors = {};
        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.dateofproof) {
            errors.dateofproof = "*Date de preuve de réalisation de l’opération (ex : date de la facture)  requise";
        }
        if (!values.dateofcommitment) {
            errors.dateofcommitment = "*Date d’engagement de l'opération (ex : date d’acceptation du devis) requise";
        }
        // if (!values.brand) {
        //     errors.brand = "Marque requise";
        // }
        // if (!values.reference) {
        //     errors.reference = "Référence requise";
        // }
        if (!values.city) {
            errors.city = "Ville requise";
        }
        if (!values.luminaires) {
            errors.luminaires = "Les luminaires déposés sont existants depuis plus de 2 ans à la date d'engagement de l'opération requise";
        }
        if (!values.lighting) {
            errors.lighting = "Type d’éclairage requise";
        }
        if (!values.noofnewlights && !values.noofnewlightsv2) {
            errors.noofnewlights = "Nombre de luminaires neufs installés requise requise";
            errors.noofnewlightsv2 = "Nombre de luminaires neufs installés requise requise";
        }
        // if (!values.noofnewlights) {
        //     errors.noofnewlights = "Nombre de luminaires neufs installés requise";
        // }
        // if (!values.noofnewlightsv2) {
        //     errors.noofnewlightsv2 = "Nombre de luminaires neufs installés requise";
        // }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/estimation-page");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-6');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit,setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid px-4 py-3'>
                        <div className='row'>
                            <div className='col'>
                                <p className='bar1-para '>Annexe 1 à la fiche d’opération standardisée RES-EC-104,
                                    définissant le contenu de la partie A de l’attestation sur l’honneur.</p>
                                <p className="bar1-head  mt-4">A/ RES-EC-104 (v.A14.1) : Rénovation d’éclairage extérieur par dépose de luminaires et mise en place de
                                    luminaires neufs dont la source lumineuse peut être remplacée.</p>
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-sm-12">
                                        <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : date d’acceptation du devis) :</p>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <input
                                            type="date"
                                            name='dateofcommitment'
                                            id='dateofcommitment'
                                            value={values.dateofcommitment}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.dateofcommitment && errors.dateofcommitment ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.dateofcommitment && errors.dateofcommitment && <div className="error-message">{errors.dateofcommitment}</div>}
                                    </div>
                                </div>
                                <hr />

                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-sm-12">
                                        <p className='bar1-head mt-3'>*Date de preuve de réalisation de l’opération (ex : date de la facture) :</p>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <input
                                            type="date"
                                            name='dateofproof'
                                            id='dateofproof'
                                            value={values.dateofproof}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.dateofproof && errors.dateofproof ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.dateofproof && errors.dateofproof && <div className="error-message">{errors.dateofproof}</div>}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-sm-12">
                                        <p className='bar1-head mt-3'>Référence de la facture :</p>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <input
                                            type="text"
                                            name='invoicereference'
                                            id='invoicereference'
                                            value={values.invoicereference}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.invoicereference && errors.invoicereference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.invoicereference && errors.invoicereference && <div className="error-message">{errors.invoicereference}</div>}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-sm-12 text-left">
                                        <label className='bar1-head mt-3'>*Adresse des travaux (périmètre précis de réalisation de l’opération) :</label>
                                        &nbsp; <input
                                            type='checkbox'
                                            id='same_address'
                                            checked={isSameAddressChecked}
                                            onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                        /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <input
                                            type="text"
                                            name='addressofwork'
                                            id='addressofwork'
                                            value={values.addressofwork}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.addressofwork && errors.addressofwork ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.addressofwork && errors.addressofwork && <div className="error-message">{errors.addressofwork}</div>}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-sm-12">
                                        <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <input
                                            type="text"
                                            name='additionaladdress'
                                            id='additionaladdress'
                                            value={values.additionaladdress}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.additionaladdress && errors.additionaladdress && <div className="error-message">{errors.additionaladdress}</div>}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-sm-12">
                                        <p className='bar1-head mt-3'>*Code postal :</p>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <input
                                            type="number"
                                            name='codepostal'
                                            id='codepostal'
                                            value={values.codepostal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-sm-12">
                                        <p className='bar1-head mt-3'>*Ville :</p>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <input
                                            type="text"
                                            name='city'
                                            id='city'
                                            value={values.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                    </div>
                                </div>
                                <hr />
                                <p className="bar1-head  mt-4">*Les luminaires déposés sont existants depuis plus de 2 ans à la date d'engagement de l'opération :</p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Oui'
                                            name="luminaires"
                                            id="luminaires"
                                            onChange={handleChange}
                                            checked={values.luminaires === "Oui"} />
                                        <span className='span-bar bar1-para'>
                                            Oui
                                        </span>
                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Non'
                                            name="luminaires"
                                            id="luminaires"
                                            onChange={handleChange}
                                            checked={values.luminaires === "Non"} />
                                        <span className='span-bar bar1-para '>
                                            Non
                                        </span>
                                    </label>
                                </div>
                                {touched.luminaires && errors.luminaires && <div className="error-message">{errors.luminaires}</div>}
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>

                                <p className="bar1-head  mt-4">*Type d’éclairage : </p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='public lighting'
                                            name="lighting"
                                            id="lighting"
                                            onChange={handleChange}
                                            checked={values.lighting === "public lighting"} />
                                        <span className='span-bar bar1-para'>
                                            Eclairage public de type « fonctionnel » (autoroutier, routier, urbain, permettant tous les types de circulation : motorisée ou
                                            cycliste) </span>

                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='private type lighting'
                                            name="lighting"
                                            id="lighting"
                                            onChange={handleChange}
                                            checked={values.lighting === "private type lighting"} />
                                        <span className='span-bar bar1-para '>Eclairage de type « ambiance » ou privé (rues, avenues, parcs, allées, voies piétonnes, parkings, etc.)
                                            NB : Cette fiche ne concerne ni les illuminations de mise en valeur des sites ni l’éclairage des terrains de sport. </span>
                                    </label>
                                </div>
                                {touched.lighting && errors.lighting && <div className="error-message">{errors.lighting}</div>}
                                <p className="bar1-head  mt-4"><u>Cas n°1 :</u>  Efficacité lumineuse ≥ 90 lumens par Watt, IP ≥ 65 et ULOR ≤ 1 % (ou, pour les luminaires à LED, ULR ≤ 3%). </p>
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-sm-12">
                                        <p className='bar1-head mt-3'>*Marque :</p>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <input
                                            type="text"
                                            name='brand'
                                            id='brand'
                                            value={values.brand}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {/* {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>} */}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-sm-12">
                                        <p className='bar1-head mt-3'>*Référence :</p>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <input
                                            type="text"
                                            name='reference'
                                            id='reference'
                                            value={values.reference}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {/* {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>} */}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-sm-12">
                                        <p className='bar1-head mt-3'>*Modèle:</p>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <input
                                            type="text"
                                            name='model'
                                            id='model'
                                            value={values.model}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.model && errors.model ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {/* {touched.model && errors.model && <div className="error-message">{errors.model}</div>} */}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-sm-12">
                                        <p className='bar1-head mt-3'>*Type:</p>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <input
                                            type="text"
                                            name='types'
                                            id='types'
                                            value={values.types}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.types && errors.types ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {/* {touched.types && errors.types && <div className="error-message">{errors.types}</div>} */}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-sm-12">
                                        <p className='bar1-head mt-3'>*Nombre de luminaires neufs installés :</p>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <input
                                            type="number"
                                            name='noofnewlights'
                                            id='noofnewlights'
                                            value={values.noofnewlights}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.noofnewlights && errors.noofnewlights ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.noofnewlights && errors.noofnewlights && <div className="error-message">{errors.noofnewlights}</div>}
                                    </div>
                                </div>
                                <hr />

                                <p className="bar1-head  mt-4"><u>Cas n°2:</u> Efficacité lumineuse ≥ 70 lumens par Watt, IP ≥ 65 et ULOR ≤ 10 % (ou, pour les luminaires à LED,
                                    ULR ≤ 15 %) </p>
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-sm-12">
                                        <p className='bar1-head mt-3'>*Marque :</p>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <input
                                            type="text"
                                            name='brandv2'
                                            id='brandv2'
                                            value={values.brandv2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.brandv2 && errors.brandv2 ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {/* {touched.brandv2 && errors.brandv2 && <div className="error-message">{errors.brandv2}</div>} */}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-sm-12">
                                        <p className='bar1-head mt-3'>*Référence :</p>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <input
                                            type="text"
                                            name='referencev2'
                                            id='referencev2'
                                            value={values.referencev2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.referencev2 && errors.referencev2 ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {/* {touched.referencev2 && errors.referencev2 && <div className="error-message">{errors.referencev2}</div>} */}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-sm-12">
                                        <p className='bar1-head mt-3'>*Modèle:</p>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <input
                                            type="text"
                                            name='modelv2'
                                            id='modelv2'
                                            value={values.modelv2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.modelv2 && errors.modelv2 ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {/* {touched.modelv2 && errors.modelv2 && <div className="error-message">{errors.modelv2}</div>} */}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-sm-12">
                                        <p className='bar1-head mt-3'>*Type:</p>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <input
                                            type="text"
                                            name='typev2'
                                            id='typev2'
                                            value={values.typev2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.typev2 && errors.typev2 ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {/* {touched.typev2 && errors.typev2 && <div className="error-message">{errors.typev2}</div>} */}
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-sm-12">
                                        <p className='bar1-head mt-3'>*Nombre de luminaires neufs installés :</p>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <input
                                            type="number"
                                            name='noofnewlightsv2'
                                            id='noofnewlightsv2'
                                            value={values.noofnewlightsv2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.noofnewlightsv2 && errors.noofnewlightsv2 ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.noofnewlightsv2 && errors.noofnewlightsv2 && <div className="error-message">{errors.noofnewlightsv2}</div>}
                                    </div>
                                </div>
                                <hr />
                                <p className="bar1-head  mt-4">NB : l’efficacité lumineuse correspond au flux lumineux initial total sortant divisé par la puissance totale du système (y compris
                                    les auxiliaires). Dans le cas d’un éclairage fonctionnel, seul le cas n°1 est accepté</p>

                                <div className='row mt-5 mb-2'>
                                    <div className='col text-start'>
                                        <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                    </div>
                                    <div className="col text-end">
                                        <button type='submit' className='btns-color'>Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )
            }
        </Formik>
    )
}

export default ResEc104Calcult;