import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs";
function Bar101() {
  const navigate = useNavigate();
  const handlePrev = () => {
    navigate(-1);
  };
  return (
    <div className='container-fluid px-4 py-3'>
      <div className='row'>
        <p className='bar1-para '>BAR-EN-101 : Isolation de combles ou de toitures  <span onClick={handlePrev}  className='border-0 bg-white pe-auto'><BsArrowLeft className='left-arow pe-auto' /></span></p>
        <div className='button-1 mt-1'>
          <Link to={"/calcult"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
        </div>
        <div className='col bar1-tittle'>
          <div className='bar1-tittle'>
            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
              <h3 className='bar1-head'>1. Secteur d’application</h3>
              <p className='bar1-para'>Bâtiments résidentiels existants.</p>
              <h3 className='bar1-head'>2. Dénomination</h3>
              <p className='bar1-para'>Mise en place d’une isolation thermique en comble perdu ou en rampant de toiture.</p>
              <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
              <p className='bar1-para'>La résistance thermique R de l’isolation installée est supérieure ou égale à :<br></br>
                <span className='ms-4'>7 m2.K/W en comble perdu ;</span><br></br>
                <span className='ms-4'>6 m².K/W en rampant de toiture.</span>
              </p>
              <p className='bar1-para'>La résistance thermique est évaluée selon la norme NF EN 12664, la norme NF EN 12667 ou la norme NF EN 12939 pour les isolants non réfléchissants et selon la norme NF EN 16012+A1 pour les isolants réfléchissants.</p>
              <p className='bar1-para'>Un pare-vapeur ou tout autre dispositif pern1ettant d’atteindre un résultat équivalent est mis en place, lorsqu’il est nécessaire de protéger les matériaux d’isolation thermique contre les transferts d’humidité pour garantir la performance de l’ouvrage.</p>
              <p className='bar1-para'>La mise en place est réalisée par un professionnel. </p>
              <p className='bar1-para'>Le professionnel effectue, au plus tard avant l’établissement du devis, une visite du bâtiment au cours de laquelle il valide que la mise en place des isolants dans les combles ou en toiture de ce bâtiment est en adéquation avec ce dernier.
              </p>
              <p className='bar1-para'>Le professionnel effectue, au plus tard avant l’établissement du devis, une visite du bâtiment au cours de laquelle il valide que la mise en place des isolants dans les combles ou en toiture de ce bâtiment est en adéquation avec ce dernier.</p>
              <p className='bar1-para'>Un délai minimal de sept jours francs est respecté entre la date d’acceptation du devis et la date de début des travaux (pose de l’isolant).</p>
              <p className='bar1-para'>Le professionnel ayant réalisé l’opération est titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues à l’article 2 du décret 11° 2014-812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des impôts et du dernier alinéa du 2 du Ide l’article 244 quater U du code général des impôts et des textes pris pour son application. </p>

              <p className='bar1-para'>Ce signe de qualité correspond à des travaux relevant du 4 du I de l’article 46 AX de l’annexe III du code général des impôts.</p>

              <p className='bar1-para'>La preuve de la réalisation de l’opération comporte les mentions de :<br></br>
                <span className='ms-4'>– la mise en place d’une isolation de combles ou de toiture ;</span><br></br>
                <span className='ms-4'>– les marque et référence ainsi que l’épaisseur et la surface d’isolant installé ;.</span><br></br>
                <span className='ms-4'>– la résistance thermique de l’isolation mise en place évaluée, suivant la nature de l’isolant, selon l’une des normes susvisées ;</span><br></br>
                <span className='ms-4'>– la date de la visite du bâtiment.</span>
              </p>

              <p className='bar1-para'>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un matériau avec ses marque et référence et la surface de matériau installée ainsi que date de la visite du bâtiment par le professionnel et elle est complétée par un document issu du fabricant ou d’un organisme établi dans l’Espace économique européen et accrédité selon la norme NF EN 45011 par le Comité français d’accréditation (COFRAC) ou tout autre organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris dans le cadre de European co-operation for Accreditation (EA), coordination européenne des organismes d’accréditation.</p>
              <p className='bar1-para'>référence mis en place est un isolant et précise ses caractéristiques thermiques (résistance thermique ; ou conductivité thermique et épaisseur) évaluées, suivant la nature de l’isolant, selon l’une des normes susvisées. En cas de mention d’une date de validité, ce document est considéré comme valable jusqu’à un an après sa date de fin de validité. Pour les références proposées en différentes épaisseurs, la preuve de réalisation, si elle ne mentionne pas la résistance thermique de l’isolation installée, doit impérativement en préciser l’épaisseur.</p>
              <h3 className="bar1-head mt-4">Le document justificatif spécifique à l’opération est la décision de qualification ou de certification du professionnel délivrée selon les mêmes exigences que celles prévues à l’article 2 du décret susvisé.</h3>
              <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                <span className='bar1-para'>30 ans.</span></h3>

              <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac</p>
              <div className="row">
                <div className="col-4">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th colSpan="3">Montant en kWh cumac par m<sup>2</sup> d'isolant en fonction de la zone climatique</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center'>
                        <td>H1</td>
                        <td>H2</td>
                        <td>H3</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>1 700</td>
                        <td>1 400</td>
                        <td>900</td>
                      </tr> </tbody>
                  </table>
                </div>
                <div className="col-lg-1 ">
                  <h1 className='postion-tops '>*</h1>
                </div>
                <div className="col-lg-3">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th colSpan="3">Surface d'isolant<br />(m<sup>2</sup>)</th></tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center h-100'> <td className='paddings-bottom  text-center'>s</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='bar1-content mt-4 pb-4'>
                <p className='bar1-para para-bar '>Fourniture et pose par un professionnel RGE d’un procédé d’isolation en comble perdu ou en rampant de toiture dans un bâtiment résidentiel existant. Un pare-vapeur (ou équivalent) est posé pour protéger l’isolant thermique de l'humidité et de la condensation. </p>
                <p className="bar1-head para-bar1 ">Éligible si : </p>
                <ul className='ul-bar1'>  <li><span className='bar1-ul '>Le devis : </span>comporte la date de la pré-visite technique.</li>
                  <li><span className='bar1-ul'>Le début des travaux</span> respecte un délai<span className='bar1-ul'> de 8 jours après la date de signature</span> de la commande.</li>
                  <li><span className='bar1-ul'>L’isolant :</span> a une résistance thermique ≥ à 7 m².K/W en comble perdu et ≥ à 6 m².K/W en rampant de toiture, est certifié ACERMI (ou équivalent), est de classe A de protection au feu (ou équivalent ou pose d’une couche supplémentaire).</li>
                  <li><span className='bar1-ul'>Sont installés :</span>  un coffrage autour des conduits de fumées et des éclairages encastrés ; une rehausse rigide au niveau de la trappe d’accès.</li></ul>
                <p className='bar1-para para-bar1'><span className='bar1-head '>La facture indique obligatoirement :</span> fourniture et pose d’une isolation de comble ou toiture, marque, référence, épaisseur, résistance thermique, n° ACERMI et surface réellement isolée (+ l’identité du sous-traitant, si c’est le cas), les aménagements réalisés, la présence d’un pare-vapeur + la date de pré-visite technique. </p>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Contrôle post-chantier :</span> tous les chantiers font l’objet d’un questionnaire de satisfaction client, certains font l’objet d’une inspection qualité post-travaux par un organisme indépendant (attesté COFRAC).</p>

                <p className='bar1-para para-bar1'><span className='bar1-head '>Attention :</span> Sans modification sous 5 ans, cette opération sera abrogée à partir du 1er mai 2027.</p>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime. En cliquant sur le Info , vous obtenez plus de détail sur une question.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Bar101;