import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
const ResidensielUpdate = () => {
    const navigate = useNavigate();
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    const [b2bOperations, setB2bOperations] = useState(MultiUpdate?.multisteptable1?.b2boperations || '');
    console.log('b2bOperations', b2bOperations);

    const handleChange = (values) => {
        var datas = { 'b2bOperations': b2bOperations, 'documents': values };
        console.log('values', values);
        if (values === 'BAR-EN-101') {
            navigate('/update-bar-en-101');
        }
        if (values === 'BAR-EN-102') {
            navigate('/update-bar-en-102');
        }
        if (values === 'BAR-EN-103') {
            navigate('/update-bar-en-103');
        }
        if (values === 'BAR-EN-104') {
            navigate('/update-bar-en-104');
        }
        if (values === 'BAR-SE-104') {
            navigate('/update-bar-se-104');
        }
        if (values === 'BAR-TH-160') {
            navigate('/update-bar-th-160');
        }
        if (values == 'BAR-TH-161') {
            navigate('/update-bar-th-161');
        }
        // Tertiare
        if (values === 'BAT-EN-101') {
            navigate('/update-bat-en-101');
        }
        if (values == 'BAT-EN-102') {
            navigate('/update-bat-en-102');
        }
        if (values === 'BAT-EN-103') {
            navigate('/update-bat-en-103');
        }
        if (values === 'BAT-TH-116') {
            navigate('/update-bat-th-116');
        }
        if (values === 'BAT-TH-146') {
            navigate('/update-bat-th-146');
        }
        if (values === 'BAT-TH-104') {
            navigate('/update-bat-th-104');
        }
        if (values === 'BAT-EQ-133') {
            navigate('/update-bat-eq-133');
        }
        if (values === 'BAT-TH-155') {
            navigate('/update-bat-th-155');
        }
        if (values === 'RES-EC-104') {
            navigate('/update-res-ec-104');
        }

        sessionStorage.setItem("step-5", JSON.stringify(datas));
    }
    // TAB LIST VALUES

    const handlePrev = () => {
        navigate(-1);
    };
    return (
        <>
            <div className="container-fluid-fluid pl-30">
                <Tab.Container id="left-tabs-example">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column reside mt-0">
                                <Nav.Item >
                                    <Nav.Link className={`reside1 ${b2bOperations === 'Résidentiel' && 'active'}`} onClick={() => setB2bOperations('Résidentiel')} eventKey="Résidentiel">Résidentiel</Nav.Link>
                                </Nav.Item>
                                <Nav.Item >
                                    <Nav.Link className={`reside1 ${b2bOperations === 'Tertiaire' && 'active'}`} onClick={() => setB2bOperations('Tertiaire')} eventKey="Tertiaire">Tertiaire</Nav.Link>
                                </Nav.Item>
                                <Nav.Item >
                                    <Nav.Link className={`reside1 ${b2bOperations === 'RESEAU' && 'active'}`} onClick={() => setB2bOperations('RESEAU')} eventKey="RESEAU">RESEAU</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane className='bg-white mt-4' eventKey="Résidentiel">
                                    {/* <div className={`reside-page  justify-content-between d-flex mt-4 ${MultiUpdate?.multisteptable1?.documents === 'BAR-EN-101' && 'active'}`} > */}
                                    <div className='reside-page  justify-content-between d-flex mt-2' >
                                        <span onClick={() => handleChange('BAR-EN-101')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAR-EN-101' && 'document-checked'}`}>BAR-EN-101</span>
                                        <span className='reside-content1'>Isolation des combles ou de toiture</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2'>
                                        <span onClick={() => handleChange('BAR-EN-102')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAR-EN-102' && 'document-checked'}`}>BAR-EN-102</span>
                                        <span className='reside-content1  '>Isolation de mur ou façade</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2 '>
                                        <span onClick={() => handleChange('BAR-EN-103')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAR-EN-103' && 'document-checked'}`}>BAR-EN-103</span>
                                        <span className='reside-content1'>Isolation du sol ou plancher</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2'>
                                        <span onClick={() => handleChange('BAR-EN-104')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAR-EN-104' && 'document-checked'}`}>BAR-EN-104</span>
                                        <span className='reside-content1'>Remplacement de fenêtre ou porte fenêtre isolante
                                            (double vitrage)</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2'>

                                        <span onClick={() => handleChange('BAR-SE-104')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAR-SE-104' && 'document-checked'}`}>BAR-SE-104</span>
                                        <span className='reside-content1'>Equilibrage du chauffage entre appartements</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2'>

                                        <span onClick={() => handleChange('BAR-TH-160')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAR-TH-160' && 'document-checked'}`}>BAR-TH-160</span>
                                        <span className='reside-content1'>Isolation d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-2'>
                                        <span onClick={() => handleChange('BAR-TH-161')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAR-TH-161' && 'document-checked'}`}>BAR-TH-161</span>
                                        <span className='reside-content1'>Isolation des points singuliers d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire</span>
                                    </div>

                                </Tab.Pane>
                                {/* SECOND */}
                                <Tab.Pane className='bg-white mt-4' eventKey="Tertiaire">
                                    <div className='reside-page  justify-content-between d-flex mt-2'>
                                        <span onClick={() => handleChange('BAT-EN-101')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAT-EN-101' && 'document-checked'}`}>BAT-EN-101</span>
                                        <span className='reside-content1'>Isolation des combles ou de toiture</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-1'>
                                        <span onClick={() => handleChange('BAT-EN-102')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAT-EN-102' && 'document-checked'}`}>BAT-EN-102</span>
                                        <span className='reside-content1  '>Isolation de mur ou façade</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-1 '>
                                        <span onClick={() => handleChange('BAT-EN-103')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAT-EN-103' && 'document-checked'}`}>BAT-EN-103</span>
                                        <span className='reside-content1  '>Isolation du sol ou plancher</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-1'>

                                        <span onClick={() => handleChange('BAT-TH-116')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAT-TH-116' && 'document-checked'}`}>BAT-TH-116</span>
                                        <span className='reside-content1  '>Système de gestion technique du bâtiment tertiaire</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-1'>

                                        <span onClick={() => handleChange('BAT-TH-146')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAT-TH-146' && 'document-checked'}`}>BAT-TH-146</span>
                                        <span className='reside-content1  '>Calorifugeage de réseau de chauffage ou ECS</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-1'>

                                        <span onClick={() => handleChange('BAT-TH-104')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAT-TH-104' && 'document-checked'}`}>BAT-TH-104</span>
                                        <span className='reside-content1  '>Robinet thermostatique pour radiateurs</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-1'>
                                        <span onClick={() => handleChange('BAT-EQ-133')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAT-EQ-133' && 'document-checked'}`}>BAT-EQ-133</span>
                                        <span className='reside-content1  '>Systèmes hydro-économes (France métropolitaine)</span>
                                    </div>
                                    <div className='reside-page  justify-content-between d-flex mt-1'>
                                        <span onClick={() => handleChange('BAT-TH-155')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'BAT-TH-155' && 'document-checked'}`}>BAT-TH-155</span>
                                        <span className='reside-content1  '>Isolation des points singuliers d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire</span>
                                    </div>
                                </Tab.Pane>
                                {/* THREE */}
                                <Tab.Pane className='bg-white mt-4' eventKey="RESEAU">
                                    <div className='reside-page  justify-content-between d-flex mt-2'>
                                        <span onClick={() => handleChange('RES-EC-104')} className={`reside-content text-center links ${MultiUpdate?.multisteptable1?.documents === 'RES-EC-104' && 'document-checked'}`}>RES-EC-104</span>
                                        <span className='reside-content1'>Rénovation d’éclairage extérieur</span>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <div className='row mt-5 mb-2'>
                    <div className='col text-start'>
                        <button type='button' onClick={handlePrev} className='btns-color' >Précédent </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResidensielUpdate