import React from 'react'
import { Link } from "react-router-dom"
const BATTH104Update = () => {
    return (
        <>
            <div className='container-fluid  '>
                <div className='row'>
                    <p className='bar1-para '>BAT-TH-104 : Robinet thermostatique </p>
                    <div className='button-1 mt-1'>
                        <Link to={"/update-calcult-batth104"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
                    </div>
                    <div className='col bar1-tittle'>
                        <div className='bar1-tittle'>
                            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                                <h3 className='bar1-head'>1. Secteur d’application</h3>
                                <p className='bar1-para'>Locaux du secteur tertiaire chauffés et existants réservés à une utilisation professionnelle.</p>
                                <h3 className='bar1-head'>2. Dénomination</h3>
                                <p className='bar1-para'>Mise en place de robinets thermostatiques sur des radiateurs existants raccordés à un système de chauffage central à combustible avec chaudière existante.</p>
                                <h3 className='bar1-head m-0'>3. Conditions pour la délivrance de certificats</h3>
                                <p className='bar1-para '>La mise en place est réalisée par un professionnel.<br></br></p>
                                <p className='bar1-para'>La preuve de réalisation de l’opération mentionne la mise en place de robinets thermostatiques et leur nombre.</p>
                                <p className='bar1-para'>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un ou plusieurs équipement(s) avec ses (leurs) marque(s) et référence(s), ainsi que le nombre installé, et elle est accompagnée du (des) document(s) issu(s) du (des) fabricant(s) indiquant que le (ou les) équipement(s) de marques et références mis en place est (sont) un (des) robinet(s) thermostatique(s).</p>
                                <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                                    <span className='bar1-para'>20 ans.</span></h3>
                                <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac <br></br>
                                    <span className='bar1-para'>S est égale à la surface chauffée par les radiateurs équipés de robinets thermostatiques dans le cadre de la présente opération.</span></p>

                                <div className="row">
                                    <div className="col-lg-4">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center '>
                                                    <th className='p-5' colSpan="3">Montant unitaire en kWh cumac/m<sup>2</sup></th> </tr></thead>
                                            <tbody > <tr className=' bar1-para text-center '>
                                                <th className='border-thin text-center ' rowspan="4">Zone climatique</th> </tr>
                                                <tr className='bar1-para text-center p-0'>
                                                    <td className='border-thin p-0'  >H1</td>
                                                    <td className='p-1' >100</td></tr>
                                                <tr className='bar1-para text-center p-0'>
                                                    <td className='border-thin p-0' >H2</td>
                                                    <td className='p-1'>81</td></tr>
                                                <tr className='bar1-para text-center p-0'>
                                                    <td className='border-thin p-0'>H3</td>
                                                    <td className='p-1'>54</td> </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-1 text-center">
                                        <h1 className='postion-tops text-center'>*</h1>
                                    </div>
                                    <div className="col-lg-2">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th className='p-4' colSpan="3">Surface chauffée en m<sup>2</sup></th> </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para text-center h-100 '><td className='pad-50 text-center'>s</td> </tr> </tbody>
                                        </table> </div>
                                    <div className="col-lg-1 text-center">
                                        <h1 className='postion-tops text-center'>*</h1>
                                    </div>
                                    <div className="col-lg-3">
                                        <table className="table table-bordered table-border ">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th >Secteur d'activite</th>
                                                    <th >Facteur correctif </th> </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para '><td>Bureaux</td>
                                                    <td className='text-center'>1,2</td></tr>
                                                <tr className='bar1-para '> <td>Enseignement</td>
                                                    <td className='text-center'>0,8</td> </tr>
                                                <tr className='bar1-para '> <td>Sante</td>
                                                    <td className='text-center'>1</td> </tr>
                                                <tr className='bar1-para '> <td>Commerces</td>
                                                    <td className='text-center'>0,9</td></tr>
                                                <tr className='bar1-para '><td>Hotelleric -<br /> Restauration </td><td className='text-center'>1,3</td>
                                                </tr><tr className='bar1-para'><td>Autres sectcurs</td>
                                                    <td className='text-center'>0,8</td> </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='bar1-content mt-4 pb-4'>
                                    <p className='bar1-para para-bar1 m-0'>Fourniture et pose de robinets thermostatiques sur des radiateurs existants raccordés à un chauffage central Gaz, Fioul ou Biomasse ayant une chaudière existante depuis plus de 2 ans, dans un batiment du secteur TERTIAIRE. </p>
                                    <p className='bar1-para para-bar1 mt-1'><span className='bar1-head '>Sont exclus :</span> les équipements sur radiateurs neufs et les équipements sur un système de chauffage de moins de deux ans.</p>
                                    <p className='bar1-para para-bar1 mt-1'><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime.</p>
                                    <p className='bar1-para para-bar1 '>En cliquant sur le + Info -> , vous obtenez plus de détail sur une question.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BATTH104Update