import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
const OperationB2C = (props) => {
    const Token = localStorage.getItem('Token');
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);

    //    MULTIUPDATE DATAS
    const MultiUpdate = props.MultiUpdate;
    const [initialValues, setInitialValues] = useState({
        id: '',
        b2boperations: MultiUpdate?.multisteptable1?.b2boperations,
        documents: MultiUpdate?.multisteptable1?.documents,
        // 164
        commitmentdate: '',
        dateofproof: '',
        codepostal: '',
        invoicereference: '',
        legalpersons: '',
        addressofworks: '',
        additionaladdress: '',
        city: '',
        individual_house: '',
        heatpump_surface: '',
        cep_initial: '',
        cep_projet: '',
        cef_initial: '',
        cef_projet: '',
        energy_gain: '',
        greenhouse: '',
        b2c_social_reason: '',
        b2c_siren_number: '',
        date_of_energy: '',
        energy_study_reference: '',
        software_name: '',
        software_version: '',
        representative_name: '',
        b2c_first_name: '',
        siret_number: '',
        field_work: '',
        certification_reference: '',
        b2c_date: '',
        // 125
        date_of_commitment: '',
        type_installation: '',
        dwellings_served: '',
        energy_class: '',
        exchanger_thermal: '',
        installation_type: '',
        static_exchanger: '',
        box_brand: '',
        casing_reference: '',
        valve_brand: '',
        unit_reference: '',
        // 148
        work_name: '',
        housing_type: '',
        cop: '',
        brand: '',
        reference: '',
        nom: '',
        prénom: '',
        // 143
        lowtemperature: '',
        // 104
        seasonal_energy: '',
        sizing_note: '',
        heatpump_type: '',
        // 113
        sizingnote: '',
        wood: '',
        rated_heat: '',
        regulator_class: '',
        boilers_225: '',
        buffer_tank: '',
        boiler_operated: '',
        boiler_7label: '',
        seasonal_particulate: '',
        seasonal_carbon: '',
        nitrogen_oxides: '',
        gaseous_organic: '',
    });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            id: MultiUpdate?.multisteptable1?.id,
            b2boperations: MultiUpdate?.multisteptable1?.b2boperations || '',
            documents: MultiUpdate?.multisteptable1?.documents || '',
            // 164
            commitmentdate: MultiUpdate?.multisteptable2?.commitmentdate || '',
            dateofproof: MultiUpdate?.multisteptable2?.dateofproof || '',
            codepostal: MultiUpdate?.multisteptable2?.codepostal || '',
            invoicereference: MultiUpdate?.multisteptable2?.invoicereference || '',
            legalpersons: MultiUpdate?.multisteptable2?.legalpersons || '',
            addressofworks: MultiUpdate?.multisteptable2?.addressofworks || '',
            additionaladdress: MultiUpdate?.multisteptable2?.additionaladdress || '',
            city: MultiUpdate?.multisteptable2?.city || '',
            individual_house: MultiUpdate?.multisteptable2?.individual_house || '',
            heatpump_surface: MultiUpdate?.multisteptable2?.heatpump_surface || '',
            cep_initial: MultiUpdate?.multisteptable2?.cep_initial || '',
            cep_projet: MultiUpdate?.multisteptable2?.cep_projet || '',
            cef_initial: MultiUpdate?.multisteptable2?.cef_initial || '',
            cef_projet: MultiUpdate?.multisteptable2?.cef_projet || '',
            energy_gain: MultiUpdate?.multisteptable2?.energy_gain || '',
            greenhouse: MultiUpdate?.multisteptable2?.greenhouse || '',
            b2c_social_reason: MultiUpdate?.multisteptable2?.social_reason || '',
            b2c_siren_number: MultiUpdate?.multisteptable2?.siren_number || '',
            date_of_energy: MultiUpdate?.multisteptable2?.date_of_energy || '',
            energy_study_reference: MultiUpdate?.multisteptable2?.energy_study_reference || '',
            software_name: MultiUpdate?.multisteptable2?.software_name || '',
            software_version: MultiUpdate?.multisteptable2?.software_version || '',
            representative_name: MultiUpdate?.multisteptable2?.representative_name || '',
            b2c_first_name: MultiUpdate?.multisteptable2?.first_name || '',
            siret_number: MultiUpdate?.multisteptable2?.siret_number || '',
            field_work: MultiUpdate?.multisteptable2?.field_work || '',
            certification_reference: MultiUpdate?.multisteptable2?.certification_reference || '',
            b2c_date: MultiUpdate?.multisteptable2?.b2c_date || '',
            // 125
            date_of_commitment: MultiUpdate?.multisteptable2?.date_of_commitment || '',
            type_installation: MultiUpdate?.multisteptable2?.type_installation || '',
            dwellings_served: MultiUpdate?.multisteptable2?.dwellings_served || '',
            energy_class: MultiUpdate?.multisteptable2?.energy_class || '',
            exchanger_thermal: MultiUpdate?.multisteptable2?.exchanger_thermal || '',
            installation_type: MultiUpdate?.multisteptable2?.installation_type || '',
            static_exchanger: MultiUpdate?.multisteptable2?.static_exchanger || '',
            box_brand: MultiUpdate?.multisteptable2?.box_brand || '',
            casing_reference: MultiUpdate?.multisteptable2?.casing_reference || '',
            valve_brand: MultiUpdate?.multisteptable2?.valve_brand || '',
            unit_reference: MultiUpdate?.multisteptable2?.unit_reference || '',
            // 148
            work_name: MultiUpdate?.multisteptable2?.work_name || '',
            housing_type: MultiUpdate?.multisteptable2?.housing_type || '',
            cop: MultiUpdate?.multisteptable2?.cop || '',
            brand: MultiUpdate?.multisteptable2?.brand || '',
            reference: MultiUpdate?.multisteptable2?.reference || '',
            nom: MultiUpdate?.multisteptable2?.nom || '',
            prénom: MultiUpdate?.multisteptable2?.prénom || '',
            // 143
            lowtemperature: MultiUpdate?.multisteptable2?.lowtemperature || '',
            // 
            seasonal_energy: MultiUpdate?.multisteptable2?.seasonal_energy || '',
            sizing_note: MultiUpdate?.multisteptable2?.sizing_note || '',
            heatpump_type: MultiUpdate?.multisteptable2?.heatpump_type || '',
            // 113
            sizingnote: MultiUpdate?.multisteptable2?.sizingnote || '',
            wood: MultiUpdate?.multisteptable2?.wood || '',
            rated_heat: MultiUpdate?.multisteptable2?.rated_heat || '',
            regulator_class: MultiUpdate?.multisteptable2?.regulator_class || '',
            boilers_225: MultiUpdate?.multisteptable2?.boilers_225 || '',
            buffer_tank: MultiUpdate?.multisteptable2?.buffer_tank || '',
            boiler_operated: MultiUpdate?.multisteptable2?.boiler_operated || '',
            boiler_7label: MultiUpdate?.multisteptable2?.boiler_7label || '',
            seasonal_particulate: MultiUpdate?.multisteptable2?.seasonal_particulate || '',
            seasonal_carbon: MultiUpdate?.multisteptable2?.seasonal_carbon || '',
            nitrogen_oxides: MultiUpdate?.multisteptable2?.nitrogen_oxides || '',
            nitrogen_oxides: MultiUpdate?.multisteptable2?.nitrogen_oxides || '',
        }));
    }, [MultiUpdate]);

    // VALIDATE
    const validate = (values) => {
        const errors = {};
        return errors;
    }
    const same_address = MultiUpdate?.multisteptable1?.beneficiary_address;
    const same_cp = MultiUpdate?.multisteptable2?.beneficiary_cp;
    const same_ville = MultiUpdate?.multisteptable2?.beneficiary_city;
    console.log('same_address', same_address);
    const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
    const handleCheckboxChange = (e, setFieldValue) => {

        const isChecked = e.target.checked;
        setIsSameAddressChecked(isChecked);
        if (isChecked) {
            setFieldValue('addressofworks', same_address);
            setFieldValue('codepostal', same_cp);
            setFieldValue('city', same_ville);
        } else {
            setFieldValue('addressofworks', initialValues.addressofworks);
            setFieldValue('codepostal', initialValues.codepostal);
            setFieldValue('city', initialValues.city);
        }
    };
    const handleSubmit = async (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-5", JSON.stringify(values));
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/update-multistep`, values,
                {
                    headers: { authorization: Token }
                }).then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        console.log('res.message', response?.data?.message);
                        toastsuccess(response?.data?.message);
                    }
                })
        } catch (error) {
            console.log(error.response?.data?.message);
            toasterror(error.response?.data?.message);
        }
    }
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="container-fluid pb-4">
                            <div className='row mt-3 mb-4'>
                                <div className="save_button_container d-flex justify-content-between align-items-center">
                                    <p className="bar1-head  mt-4">Operation infos </p> <button type='submit' className='btns-color'>Sauvegarder</button>
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para '>Operation</p>
                                    <div className='d-flex'>
                                        <select
                                            name='b2boperations'
                                            id='b2boperations'
                                            value={values.b2boperations}
                                            onChange={handleChange}
                                            className="form-select ps-3 btn-buttons" aria-label="Operation">
                                            <option defaultValue>-- Choisir un operation --</option>
                                            <option value="b2c">B2C</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>Operation Nom</p>
                                    <div className='d-flex'>
                                        <select
                                            name='documents'
                                            id='documents'
                                            value={values.documents}
                                            onChange={handleChange}
                                            className="form-select ps-3  btn-buttons" aria-label="Operation Nom">
                                            <option defaultValue>-- Choisir un operation nom --</option>
                                            <option value="BAR-TH-164">BAR-TH-164</option>
                                            <option value="BAR-TH-125">BAR-TH-125</option>
                                            <option value="BAR-TH-148">BAR-TH-148</option>
                                            <option value="BAR-TH-143">BAR-TH-143</option>
                                            <option value="BAR-TH-101">BAR-TH-101</option>
                                            <option value="BAR-TH-104">BAR-TH-104</option>
                                            <option value="BAR-TH-113">BAR-TH-113</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* Operations */}

                            {values.b2boperations === 'b2c' && values.documents === 'BAR-TH-164' ?
                                <div className='container-fluid px-4'>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para'>A/ BAR-TH-101 (v. A17.1) : Mise en place d’un chauffe-eau solaire individuel (CESI) </p>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="date"
                                                        name='commitmentdate'
                                                        id='commitmentdate'
                                                        value={values.commitmentdate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="date"
                                                        name='dateofproof'
                                                        id='dateofproof'
                                                        value={values.dateofproof}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className='form-control1 mb-0 w-100' ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='invoicereference'
                                                        id='invoicereference'
                                                        value={values.invoicereference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className='form-control1 mb-0 w-100' ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Pour les personnes morales, nom du site des travaux ou nom de la copropriété :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        name='legalpersons'
                                                        id='legalpersons'
                                                        value={values.legalpersons}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.legalpersons && errors.legalpersons ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.legalpersons && errors.legalpersons && <div className="error-message">{errors.legalpersons}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12 text-left">
                                                    <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                        type='checkbox'
                                                        id='same_address'
                                                        checked={isSameAddressChecked}
                                                        onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                                    /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type='text'
                                                        name='addressofworks'
                                                        id='addressofworks'
                                                        value={values.addressofworks}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                                    {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type='text'
                                                        name='additionaladdress'
                                                        id='additionaladdress'
                                                        value={values.additionaladdress}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Code postal : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Ville :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='city'
                                                        id='city'
                                                        value={values.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">*Maison individuelle existant depuis plus de 2 ans à la date d'engagement de l'opération : </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Oui"
                                                        name="individual_house"
                                                        id="individual_house"
                                                        onChange={handleChange}
                                                        checked={values.individual_house === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Non"
                                                        name="individual_house"
                                                        id="individual_house"
                                                        onChange={handleChange}
                                                        checked={values.individual_house === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.individual_house && errors.individual_house && <div className="error-message">{errors.individual_house}</div>}
                                            <hr></hr>
                                            <p className='bar1-para'>Caractéristiques des capteurs solaires :</p>
                                            <p className='bar1-para'>Les capteurs solaires ont une certification CSTBat ou Solar Keymark ou équivalente. </p>
                                            <p className='bar1-para'>Les capteurs solaires ne sont pas des capteurs hybrides. </p>
                                            <p className='bar1-para'>A ne remplir que si les marque et référence de l’équipement ne sont pas mentionnées sur la preuve de réalisation de l’opération : </p>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Marque :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='brand'
                                                        id='brand'
                                                        value={values.brand}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Référence : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='reference'
                                                        id='reference'
                                                        value={values.reference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-para'>Si le bénéficiaire est une personne physique, le professionnel qui procède à la réalisation de l’opération est
                                                titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues à l’article 2 du décret n° 2014-
                                                812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des
                                                impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris pour
                                                son application. <br></br> Ce signe de qualité correspond à des travaux relevant du 7 du I de l’article 46 AX de l’annexe III du code général
                                                des impôts </p>
                                            <p className='bar1-para'>Identité du professionnel titulaire du signe de qualité ayant réalisé l'opération, s'il n’est pas le signataire de cette
                                                attestation (sous-traitance par exemple) :</p>

                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Nom</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='nom'
                                                        id='nom'
                                                        value={values.nom}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.nom && errors.nom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.nom && errors.nom && <div className="error-message">{errors.nom}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Prénom</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='prénom'
                                                        id='prénom'
                                                        value={values.prénom}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.prénom && errors.prénom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.prénom && errors.prénom && <div className="error-message">{errors.prénom}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Raison sociale : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='b2c_social_reason'
                                                        id='b2c_social_reason'
                                                        value={values.b2c_social_reason}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.b2c_social_reason && errors.b2c_social_reason ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.b2c_social_reason && errors.b2c_social_reason && <div className="error-message">{errors.b2c_social_reason}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*N° SIREN :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='siret_number'
                                                        id='siret_number'
                                                        value={values.siret_number}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.siret_number && errors.siret_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.siret_number && errors.siret_number && <div className="error-message">{errors.siret_number}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {values.b2boperations === 'b2c' && values.documents === 'BAR-TH-125' ?
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para'>A/ BAR-TH-125 (v. A36.3) : Mise en place d’un système de ventilation mécanique contrôlée double flux
                                                autoréglable en installation individuelle ou collective, ou modulé avec bouches d’extraction hygroréglables
                                                en installation individuelle seulement</p>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="date"
                                                        name='commitmentdate'
                                                        id='commitmentdate'
                                                        value={values.commitmentdate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="date"
                                                        name='dateofproof'
                                                        id='dateofproof'
                                                        value={values.dateofproof}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className='form-control1 mb-0 w-100' ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='invoicereference'
                                                        id='invoicereference'
                                                        value={values.invoicereference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className='form-control1 mb-0 w-100' ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Pour les personnes morales, nom du site des travaux ou nom de la copropriété :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        name='legalpersons'
                                                        id='legalpersons'
                                                        value={values.legalpersons}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.legalpersons && errors.legalpersons ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.legalpersons && errors.legalpersons && <div className="error-message">{errors.legalpersons}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12 text-left">
                                                    <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                        type='checkbox'
                                                        id='same_address'
                                                        checked={isSameAddressChecked}
                                                        onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                                    /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type='text'
                                                        name='addressofworks'
                                                        id='addressofworks'
                                                        value={values.addressofworks}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                                    {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type='text'
                                                        name='additionaladdress'
                                                        id='additionaladdress'
                                                        value={values.additionaladdress}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Code postal : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Ville :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='city'
                                                        id='city'
                                                        value={values.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">*Bâtiment résidentiel existant depuis plus de 2 ans à la date d'engagement de l'opération :</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Oui"
                                                        name="date_of_commitment"
                                                        id="date_of_commitment"
                                                        onChange={handleChange}
                                                        checked={values.date_of_commitment === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Non"
                                                        name="date_of_commitment"
                                                        id="date_of_commitment"
                                                        onChange={handleChange}
                                                        checked={values.date_of_commitment === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.date_of_commitment && errors.date_of_commitment && <div className="error-message">{errors.date_of_commitment}</div>}
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">*Type d’installation :</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Collective installation"
                                                        name="type_installation"
                                                        id="type_installation"
                                                        onChange={handleChange}
                                                        checked={values.type_installation === "Collective installation"} />
                                                    <span className='span-bar bar1-para'>Installation collective où plusieurs logements sont desservis par le système de ventilation.</span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Individual installation"
                                                        name="type_installation"
                                                        id="type_installation"
                                                        onChange={handleChange}
                                                        checked={values.type_installation === "Individual installation"} />
                                                    <span className='span-bar bar1-para '>Installation individuelle où un seul logement est desservi par le système de ventilation.</span>
                                                </label>
                                            </div>
                                            {touched.type_installation && errors.type_installation && <div className="error-message">{errors.type_installation}</div>}
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>A ne remplir que dans le cas d’une installation collective :<br></br>*Nombre de logements desservis :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='dwellings_served'
                                                        id='dwellings_served'
                                                        value={values.dwellings_served}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.dwellings_served && errors.dwellings_served ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.dwellings_served && errors.dwellings_served && <div className="error-message">{errors.dwellings_served}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <p className='bar1-para'>A ne remplir que dans le cas d’une installation individuelle :</p>
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Surface habitable (m²) : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='heatpump_surface'
                                                        id='heatpump_surface'
                                                        value={values.heatpump_surface}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.heatpump_surface && errors.heatpump_surface ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.heatpump_surface && errors.heatpump_surface && <div className="error-message">{errors.heatpump_surface}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Classe énergétique du système de ventilation selon le règlement européen (UE) n° 1254/2014 : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='energy_class'
                                                        id='energy_class'
                                                        value={values.energy_class}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.energy_class && errors.energy_class ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.energy_class && errors.energy_class && <div className="error-message">{errors.energy_class}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Efficacité thermique de l’échangeur (%) : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='exchanger_thermal'
                                                        id='exchanger_thermal'
                                                        value={values.exchanger_thermal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.exchanger_thermal && errors.exchanger_thermal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.exchanger_thermal && errors.exchanger_thermal && <div className="error-message">{errors.exchanger_thermal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-para'>NB : Efficacité thermique certifiée, et mesurée selon la norme NF EN 13141-7 ou le matériel est certifié NF 205 ou équivalent.</p>
                                            <p className="bar1-head  mt-4">*Type d’installation :</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Individual self-adjusting"
                                                        name="installation_type"
                                                        id="installation_type"
                                                        onChange={handleChange}
                                                        checked={values.installation_type === "Individual self-adjusting"} />
                                                    <span className='span-bar bar1-para'>Individuelle autoréglable</span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Individual modulated"
                                                        name="installation_type"
                                                        id="installation_type"
                                                        onChange={handleChange}
                                                        checked={values.installation_type === "Individual modulated"} />
                                                    <span className='span-bar bar1-para '>Individuelle modulée</span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Self-adjusting collective"
                                                        name="installation_type"
                                                        id="installation_type"
                                                        onChange={handleChange}
                                                        checked={values.installation_type === "Self-adjusting collective"} />
                                                    <span className='span-bar bar1-para '>Collective autoréglable</span>
                                                </label>
                                            </div>
                                            {touched.installation_type && errors.installation_type && <div className="error-message">{errors.installation_type}</div>}
                                            <hr></hr>
                                            <p className='bar1-para'>Le caisson double flux est collectif.</p>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Efficacité de l’échangeur statique (%) :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='static_exchanger'
                                                        id='static_exchanger'
                                                        value={values.static_exchanger}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.static_exchanger && errors.static_exchanger ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.static_exchanger && errors.static_exchanger && <div className="error-message">{errors.static_exchanger}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-para'>NB : l’efficacité énergétique de l’échangeur est déterminée selon la norme NF EN 51-763 ou NF EN 308, ou le matériel est
                                                certifié Eurovent Certified Performance Echangeurs à plaques air-air (AAHE) ou Echangeur régénératif (AARE) ou équivalent.</p>
                                            <p className='bar1-para'>A ne remplir que si les marque et référence de l’équipement ne sont pas mentionnées sur la preuve de réalisation de l’opération :</p>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Marque du caisson :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='box_brand'
                                                        id='box_brand'
                                                        value={values.box_brand}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.box_brand && errors.box_brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.box_brand && errors.box_brand && <div className="error-message">{errors.box_brand}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Référence du caisson : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='casing_reference'
                                                        id='casing_reference'
                                                        value={values.casing_reference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.casing_reference && errors.casing_reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.casing_reference && errors.casing_reference && <div className="error-message">{errors.casing_reference}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Marque des bouches d’extraction :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='valve_brand'
                                                        id='valve_brand'
                                                        value={values.valve_brand}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.valve_brand && errors.valve_brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.valve_brand && errors.valve_brand && <div className="error-message">{errors.valve_brand}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Référence des bouches d’extraction : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='unit_reference'
                                                        id='unit_reference'
                                                        value={values.unit_reference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.unit_reference && errors.unit_reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.unit_reference && errors.unit_reference && <div className="error-message">{errors.unit_reference}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-para'>Pour les opérations engagées à compter du 1er janvier 2021, le professionnel réalisant l’opération est titulaire d’un signe de
                                                qualité conforme aux exigences prévues à l’article 2 du décret n° 2014-812 du 16 juillet 2014 pris pour l’application du second
                                                alinéa du 2 de l’article 200 quater du code général des impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code
                                                général des impôts et des textes pris pour son application. Ce signe de qualité correspond à des travaux relevant du 8° du I de
                                                l'article 1er du décret précité</p>

                                        </div>
                                    </div>
                                </div> : null}
                            {values.b2boperations === 'b2c' && values.documents === 'BAR-TH-148' ?
                                <div className='container-fluid-fluid'>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para'>A/ BAR-TH-148 (v. A15.2) : Mise en place d’un chauffe-eau thermodynamique individuel à
                                                accumulation.</p>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="date"
                                                        name='commitmentdate'
                                                        id='commitmentdate'
                                                        value={values.commitmentdate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="date"
                                                        name='dateofproof'
                                                        id='dateofproof'
                                                        value={values.dateofproof}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className='form-control1 mb-0 w-100' ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='invoicereference'
                                                        id='invoicereference'
                                                        value={values.invoicereference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className='form-control1 mb-0 w-100' ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Nom du site des travaux (pour les personnes morales) :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        name='work_name'
                                                        id='work_name'
                                                        value={values.work_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.work_name && errors.work_name ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.work_name && errors.work_name && <div className="error-message">{errors.work_name}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12 text-left">
                                                    <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                        type='checkbox'
                                                        id='same_address'
                                                        checked={isSameAddressChecked}
                                                        onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                                    /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type='text'
                                                        name='addressofworks'
                                                        id='addressofworks'
                                                        value={values.addressofworks}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                                    {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type='text'
                                                        name='additionaladdress'
                                                        id='additionaladdress'
                                                        value={values.additionaladdress}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Code postal : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Ville :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='city'
                                                        id='city'
                                                        value={values.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">*Bâtiment résidentiel existant depuis plus de 2 ans à la date d'engagement de l'opération :</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Oui"
                                                        name="date_of_commitment"
                                                        id="date_of_commitment"
                                                        onChange={handleChange}
                                                        checked={values.date_of_commitment === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Non"
                                                        name="date_of_commitment"
                                                        id="date_of_commitment"
                                                        onChange={handleChange}
                                                        checked={values.date_of_commitment === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.date_of_commitment && errors.date_of_commitment && <div className="error-message">{errors.date_of_commitment}</div>}
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">*Type de logement :</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Maison_individuelle"
                                                        name="housing_type"
                                                        id="housing_type"
                                                        onChange={handleChange}
                                                        checked={values.housing_type === "Maison_individuelle"} />
                                                    <span className='span-bar bar1-para'>Maison individuelle</span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Appartement"
                                                        name="housing_type"
                                                        id="housing_type"
                                                        onChange={handleChange}
                                                        checked={values.housing_type === "Appartement"} />
                                                    <span className='span-bar bar1-para '>Appartement</span>
                                                </label>
                                            </div>
                                            {touched.housing_type && errors.housing_type && <div className="error-message">{errors.housing_type}</div>}
                                            <hr></hr>
                                            <p className='bar1-para'>Caractéristiques du chauffe-eau thermodynamique :</p>
                                            <p className="bar1-head  mt-4">*Type d’installation : </p>
                                            <div className='text-start'>
                                                <label className='para-bar1'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="sur air extrait"
                                                        name="installation_type"
                                                        id="installation_type"
                                                        onChange={handleChange}
                                                        checked={values.installation_type === "sur air extrait"} />
                                                    <span className='span-bar bar1-para '> sur air extrait </span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="autres types d’installations"
                                                        name="installation_type"
                                                        id="installation_type"
                                                        onChange={handleChange}
                                                        checked={values.installation_type === "autres types d’installations"} />
                                                    <span className='span-bar bar1-para '> autres types d’installations </span>
                                                </label>
                                            </div>
                                            <hr></hr>
                                            {touched.installation_type && errors.installation_type && <div className="error-message">{errors.installation_type}</div>}
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*COP </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='cop'
                                                        id='cop'
                                                        value={values.cop}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.cop && errors.cop ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.cop && errors.cop && <div className="error-message">{errors.cop}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-para'>NB : Le coefficient de performance (COP) de l’équipement est mesuré conformément aux conditions de la
                                                norme EN 16147. </p>
                                            <p className='bar1-para'>À ne remplir que si les marque et référence du chauffe-eau thermodynamique ne sont pas mentionnées sur la
                                                preuve de réalisation de l’opération : </p>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Marque :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='brand'
                                                        id='brand'
                                                        value={values.brand}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Référence : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='reference'
                                                        id='reference'
                                                        value={values.reference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-para'>Si le bénéficiaire est une personne physique, le professionnel qui procède à la réalisation de l’opération est
                                                titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues à l’article 2 du décret n° 2014-
                                                812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des
                                                impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris pour
                                                son application. <br></br> Ce signe de qualité correspond à des travaux relevant du 7 du I de l’article 46 AX de l’annexe III du code général
                                                des impôts </p>
                                            <p className='bar1-para'>Identité du professionnel titulaire du signe de qualité ayant réalisé l'opération, s'il n’est pas le signataire de cette
                                                attestation (sous-traitance par exemple) :</p>

                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Nom</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='nom'
                                                        id='nom'
                                                        value={values.nom}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.nom && errors.nom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.nom && errors.nom && <div className="error-message">{errors.nom}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Prénom</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='prénom'
                                                        id='prénom'
                                                        value={values.prénom}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.prénom && errors.prénom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.prénom && errors.prénom && <div className="error-message">{errors.prénom}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Raison sociale : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='b2c_social_reason'
                                                        id='b2c_social_reason'
                                                        value={values.b2c_social_reason}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.b2c_social_reason && errors.b2c_social_reason ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.b2c_social_reason && errors.b2c_social_reason && <div className="error-message">{errors.b2c_social_reason}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*N° SIREN :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='siret_number'
                                                        id='siret_number'
                                                        value={values.siret_number}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.siret_number && errors.siret_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.siret_number && errors.siret_number && <div className="error-message">{errors.siret_number}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>
                                    </div>
                                </div> : null}
                            {values.b2boperations === 'b2c' && values.documents === 'BAR-EN-143' ? <div className='container-fluid-fluid'>
                                <div className='row'>
                                    <div className='col'>
                                        <p className='bar1-para'>A/ BAR-TH-143 (v. A51.5) : Mise en place d’un système solaire combiné (SSC) destiné au chauffage et à la
                                            production d’eau chaude sanitaire</p>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='commitmentdate'
                                                    id='commitmentdate'
                                                    value={values.commitmentdate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='dateofproof'
                                                    id='dateofproof'
                                                    value={values.dateofproof}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className='form-control1 mb-0 w-100' ></input>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='invoicereference'
                                                    id='invoicereference'
                                                    value={values.invoicereference}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className='form-control1 mb-0 w-100' ></input>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Pour les personnes morales, nom du site des travaux ou nom de la copropriété :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    name='legalpersons'
                                                    id='legalpersons'
                                                    value={values.legalpersons}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.legalpersons && errors.legalpersons ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.legalpersons && errors.legalpersons && <div className="error-message">{errors.legalpersons}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12 text-left">
                                                <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                    type='checkbox'
                                                    id='same_address'
                                                    checked={isSameAddressChecked}
                                                    onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                                /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type='text'
                                                    name='addressofworks'
                                                    id='addressofworks'
                                                    value={values.addressofworks}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                                {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type='text'
                                                    name='additionaladdress'
                                                    id='additionaladdress'
                                                    value={values.additionaladdress}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Code postal : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='codepostal'
                                                    id='codepostal'
                                                    value={values.codepostal}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Ville :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='city'
                                                    id='city'
                                                    value={values.city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <p className="bar1-head  mt-4">*Maison individuelle existant depuis plus de 2 ans à la date d'engagement de l'opération : </p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Oui"
                                                    name="individual_house"
                                                    id="individual_house"
                                                    onChange={handleChange}
                                                    checked={values.individual_house === "Oui"} />
                                                <span className='span-bar bar1-para'>
                                                    Oui
                                                </span>

                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Non"
                                                    name="individual_house"
                                                    id="individual_house"
                                                    onChange={handleChange}
                                                    checked={values.individual_house === "Non"} />
                                                <span className='span-bar bar1-para '>
                                                    Non
                                                </span>
                                            </label>
                                        </div>
                                        {touched.individual_house && errors.individual_house && <div className="error-message">{errors.individual_house}</div>}
                                        <hr></hr>
                                        <p className="bar1-head  mt-4">*Le système est couplé à des émetteurs de chauffage central de type basse température : </p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="OUI"
                                                    name="lowtemperature"
                                                    id="lowtemperature"
                                                    onChange={handleChange}
                                                    checked={values.lowtemperature === "OUI"} />
                                                <span className='span-bar bar1-para'> OUI</span>

                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Non"
                                                    name="lowtemperature"
                                                    id="lowtemperature"
                                                    onChange={handleChange}
                                                    checked={values.lowtemperature === "Non"} />
                                                <span className='span-bar bar1-para '> NON</span>
                                            </label>
                                        </div>
                                        {touched.lowtemperature && errors.lowtemperature && <div className="error-message">{errors.lowtemperature}</div>}
                                        <hr></hr>
                                        <p className='bar1-para'><u>Caractéristique des capteurs solaires :</u></p>
                                        <p className="bar1-head  mt-4">*Type d’installation : </p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="sur air extrait"
                                                    name="installation_type"
                                                    id="installation_type"
                                                    onChange={handleChange}
                                                    checked={values.installation_type === "sur air extrait"} />
                                                <span className='span-bar bar1-para'> sur air extrait </span>

                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="autres types d’installations"
                                                    name="installation_type"
                                                    id="installation_type"
                                                    onChange={handleChange}
                                                    checked={values.installation_type === "autres types d’installations"} />
                                                <span className='span-bar bar1-para '> autres types d’installations </span>
                                            </label>
                                        </div>
                                        {touched.installation_type && errors.installation_type && <div className="error-message">{errors.installation_type}</div>}
                                        <hr></hr>

                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*COP </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='cop'
                                                    id='cop'
                                                    value={values.cop}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.cop && errors.cop ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.cop && errors.cop && <div className="error-message">{errors.cop}</div>}
                                            </div>
                                        </div>

                                        <hr></hr>
                                        <p className='bar1-para'>NB : Le coefficient de performance (COP) de l’équipement est mesuré conformément aux conditions de la
                                            norme EN 16147. </p>
                                        <p className='bar1-para'>À ne remplir que si les marque et référence du chauffe-eau thermodynamique ne sont pas mentionnées sur la
                                            preuve de réalisation de l’opération : </p>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Marque :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='brand'
                                                    id='brand'
                                                    value={values.brand}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Référence : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='reference'
                                                    id='reference'
                                                    value={values.reference}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <p className='bar1-para'>Si le bénéficiaire est une personne physique, le professionnel qui procède à la réalisation de l’opération est
                                            titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues à l’article 2 du décret n° 2014-
                                            812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des
                                            impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris pour
                                            son application. <br></br> Ce signe de qualité correspond à des travaux relevant du 7 du I de l’article 46 AX de l’annexe III du code général
                                            des impôts </p>
                                        <p className='bar1-para'>Identité du professionnel titulaire du signe de qualité ayant réalisé l'opération, s'il n’est pas le signataire de cette
                                            attestation (sous-traitance par exemple) :</p>

                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Nom</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='nom'
                                                    id='nom'
                                                    value={values.nom}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.nom && errors.nom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.nom && errors.nom && <div className="error-message">{errors.nom}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Prénom</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='prénom'
                                                    id='prénom'
                                                    value={values.prénom}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.prénom && errors.prénom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.prénom && errors.prénom && <div className="error-message">{errors.prénom}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Raison sociale : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='b2c_social_reason'
                                                    id='b2c_social_reason'
                                                    value={values.b2c_social_reason}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.b2c_social_reason && errors.b2c_social_reason ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.b2c_social_reason && errors.b2c_social_reason && <div className="error-message">{errors.b2c_social_reason}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*N° SIREN :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='siret_number'
                                                    id='siret_number'
                                                    value={values.siret_number}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.siret_number && errors.siret_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.siret_number && errors.siret_number && <div className="error-message">{errors.siret_number}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                    </div>
                                </div>
                            </div> : null}
                            {values.b2boperations === 'b2c' && values.documents === 'BAR-TH-101' ?
                                <div className='container-fluid '>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para'>A/ BAR-TH-101 (v. A17.1) : Mise en place d’un chauffe-eau solaire individuel (CESI) </p>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="date"
                                                        name='commitmentdate'
                                                        id='commitmentdate'
                                                        value={values.commitmentdate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="date"
                                                        name='dateofproof'
                                                        id='dateofproof'
                                                        value={values.dateofproof}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className='form-control1 mb-0 w-100' ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='invoicereference'
                                                        id='invoicereference'
                                                        value={values.invoicereference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className='form-control1 mb-0 w-100' ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Pour les personnes morales, nom du site des travaux ou nom de la copropriété :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        name='legalpersons'
                                                        id='legalpersons'
                                                        value={values.legalpersons}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.legalpersons && errors.legalpersons ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.legalpersons && errors.legalpersons && <div className="error-message">{errors.legalpersons}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12 text-left">
                                                    <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                        type='checkbox'
                                                        id='same_address'
                                                        checked={isSameAddressChecked}
                                                        onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                                    /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type='text'
                                                        name='addressofworks'
                                                        id='addressofworks'
                                                        value={values.addressofworks}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                                    {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type='text'
                                                        name='additionaladdress'
                                                        id='additionaladdress'
                                                        value={values.additionaladdress}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Code postal : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Ville :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='city'
                                                        id='city'
                                                        value={values.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">*Maison individuelle existant depuis plus de 2 ans à la date d'engagement de l'opération : </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Oui"
                                                        name="individual_house"
                                                        id="individual_house"
                                                        onChange={handleChange}
                                                        checked={values.individual_house === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Non"
                                                        name="individual_house"
                                                        id="individual_house"
                                                        onChange={handleChange}
                                                        checked={values.individual_house === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.individual_house && errors.individual_house && <div className="error-message">{errors.individual_house}</div>}
                                            <hr></hr>
                                            <p className='bar1-para'>Caractéristiques des capteurs solaires :</p>
                                            <p className='bar1-para'>Les capteurs solaires ont une certification CSTBat ou Solar Keymark ou équivalente. </p>
                                            <p className='bar1-para'>Les capteurs solaires ne sont pas des capteurs hybrides. </p>
                                            <p className='bar1-para'>A ne remplir que si les marque et référence de l’équipement ne sont pas mentionnées sur la preuve de réalisation de l’opération : </p>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Marque :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='brand'
                                                        id='brand'
                                                        value={values.brand}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Référence : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='reference'
                                                        id='reference'
                                                        value={values.reference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-para'>Si le bénéficiaire est une personne physique, le professionnel qui procède à la réalisation de l’opération est
                                                titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues à l’article 2 du décret n° 2014-
                                                812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des
                                                impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris pour
                                                son application. <br></br> Ce signe de qualité correspond à des travaux relevant du 7 du I de l’article 46 AX de l’annexe III du code général
                                                des impôts </p>
                                            <p className='bar1-para'>Identité du professionnel titulaire du signe de qualité ayant réalisé l'opération, s'il n’est pas le signataire de cette
                                                attestation (sous-traitance par exemple) :</p>

                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Nom</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='nom'
                                                        id='nom'
                                                        value={values.nom}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.nom && errors.nom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.nom && errors.nom && <div className="error-message">{errors.nom}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Prénom</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='prénom'
                                                        id='prénom'
                                                        value={values.prénom}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.prénom && errors.prénom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.prénom && errors.prénom && <div className="error-message">{errors.prénom}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Raison sociale : </p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='b2c_social_reason'
                                                        id='b2c_social_reason'
                                                        value={values.b2c_social_reason}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.b2c_social_reason && errors.b2c_social_reason ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.b2c_social_reason && errors.b2c_social_reason && <div className="error-message">{errors.b2c_social_reason}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <p className='bar1-head mt-3'>*N° SIREN :</p>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='siret_number'
                                                        id='siret_number'
                                                        value={values.siret_number}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.siret_number && errors.siret_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.siret_number && errors.siret_number && <div className="error-message">{errors.siret_number}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>
                                    </div>
                                </div> : null}
                            {values.b2boperations === 'b2c' && values.documents === 'BAR-TH-104' ? <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col'>
                                        <p className='bar1-para'>A/ BAR-TH-104 (v. A41.3) : Mise en place d’une pompe à chaleur (PAC) de type air/eau ou eau/eau</p>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="date"
                                                    name='commitmentdate'
                                                    id='commitmentdate'
                                                    value={values.commitmentdate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="date"
                                                    name='dateofproof'
                                                    id='dateofproof'
                                                    value={values.dateofproof}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className='form-control1 mb-0 w-100' ></input>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='invoicereference'
                                                    id='invoicereference'
                                                    value={values.invoicereference}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className='form-control1 mb-0 w-100' ></input>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Pour les personnes morales, nom du site des travaux ou nom de la copropriété :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    name='legalpersons'
                                                    id='legalpersons'
                                                    value={values.legalpersons}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.legalpersons && errors.legalpersons ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.legalpersons && errors.legalpersons && <div className="error-message">{errors.legalpersons}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12 text-left">
                                                <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                    type='checkbox'
                                                    id='same_address'
                                                    checked={isSameAddressChecked}
                                                    onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                                /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type='text'
                                                    name='addressofworks'
                                                    id='addressofworks'
                                                    value={values.addressofworks}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                                {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type='text'
                                                    name='additionaladdress'
                                                    id='additionaladdress'
                                                    value={values.additionaladdress}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Code postal : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='codepostal'
                                                    id='codepostal'
                                                    value={values.codepostal}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Ville :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='city'
                                                    id='city'
                                                    value={values.city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <p className="bar1-head  mt-4">*Bâtiment résidentiel existant depuis plus de 2 ans à la date d'engagement de l'opération :</p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Oui"
                                                    name="date_of_commitment"
                                                    id="date_of_commitment"
                                                    onChange={handleChange}
                                                    checked={values.date_of_commitment === "Oui"} />
                                                <span className='span-bar bar1-para'>
                                                    Oui
                                                </span>
                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Non"
                                                    name="date_of_commitment"
                                                    id="date_of_commitment"
                                                    onChange={handleChange}
                                                    checked={values.date_of_commitment === "Non"} />
                                                <span className='span-bar bar1-para '>
                                                    Non
                                                </span>
                                            </label>
                                        </div>
                                        {touched.date_of_commitment && errors.date_of_commitment && <div className="error-message">{errors.date_of_commitment}</div>}
                                        <hr></hr>
                                        <p className="bar1-head  mt-4">*Type de logement </p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Maison_individuelle"
                                                    name="housing_type"
                                                    id="housing_type"
                                                    onChange={handleChange}
                                                    checked={values.housing_type === "Maison_individuelle"} />
                                                <span className='span-bar bar1-para'>Maison individuelle</span>
                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Appartement"
                                                    name="housing_type"
                                                    id="housing_type"
                                                    onChange={handleChange}
                                                    checked={values.housing_type === "Appartement"} />
                                                <span className='span-bar bar1-para '>Appartement</span>
                                            </label>
                                        </div>
                                        {touched.housing_type && errors.housing_type && <div className="error-message">{errors.housing_type}</div>}
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Surface chauffée par la PAC installée (m²) :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='heatpump_surface'
                                                    id='heatpump_surface'
                                                    value={values.heatpump_surface}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.heatpump_surface && errors.heatpump_surface ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.heatpump_surface && errors.heatpump_surface && <div className="error-message">{errors.heatpump_surface}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <p className='bar1-para'>Caractéristiques de la pompe à chaleur (PAC) :</p>
                                        <p className="bar1-head  mt-4">*La pompe à chaleur est de type air/eau ou eau/eau et elle est conçue pour fonctionner à (une seule case à cocher) :</p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Basse température"
                                                    name="heatpump_type"
                                                    id="heatpump_type"
                                                    onChange={handleChange}
                                                    checked={values.heatpump_type === "Basse température"} />
                                                <span className='span-bar bar1-para'>Basse température</span></label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Moyenne ou haute température"
                                                    name="heatpump_type"
                                                    id="heatpump_type"
                                                    onChange={handleChange}
                                                    checked={values.heatpump_type === "Moyenne ou haute température"} />
                                                <span className='span-bar bar1-para'>Moyenne ou haute température</span>
                                            </label>
                                        </div>
                                        {touched.heatpump_type && errors.heatpump_type && <div className="error-message">{errors.heatpump_surface}</div>}
                                        <hr></hr>
                                        <p className="bar1-head  mt-4">*Une note de dimensionnement a été remise au bénéficiaire :</p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Oui"
                                                    name="sizing_note"
                                                    id="sizing_note"
                                                    onChange={handleChange}
                                                    checked={values.sizing_note === "Oui"} />
                                                <span className='span-bar bar1-para'>OUI</span></label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Non"
                                                    name="sizing_note"
                                                    id="sizing_note"
                                                    onChange={handleChange}
                                                    checked={values.sizing_note === "Non"} />
                                                <span className='span-bar bar1-para'>NON</span>
                                            </label>
                                        </div>
                                        {touched.sizing_note && errors.sizing_note && <div className="error-message">{errors.sizing_note}</div>}
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Efficacité énergétique saisonnière (ηs) (en %) :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='seasonal_energy'
                                                    id='seasonal_energy'
                                                    value={values.seasonal_energy}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.seasonal_energy && errors.seasonal_energy ? 'error' : 'form-control1 mb-0 w-100'} placeholder='la valeur doit être supérieure à 102'></input>
                                                {touched.seasonal_energy && errors.seasonal_energy && <div className="error-message">{errors.seasonal_energy}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <p className='bar1-para'>L’efficacité énergétique saisonnière (ηs) est calculée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013.
                                            NB : L’efficacité énergétique saisonnière prise en compte est celle de la pompe à chaleur seule pour les besoins de chauffage
                                            des locaux (hors dispositif de régulation).</p>
                                        <p className='bar1-para'>A ne remplir que si les marque et référence de la pompe à chaleur ne sont pas mentionnées sur la preuve de réalisation de
                                            l’opération :</p>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Marque :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='brand'
                                                    id='brand'
                                                    value={values.brand}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Référence : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='reference'
                                                    id='reference'
                                                    value={values.reference}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <p className='bar1-para'>Le professionnel ayant réalisé l’opération est titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues
                                            à l’article 2 du décret n° 2014-812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du
                                            code général des impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris
                                            pour son application. Ce signe de qualité correspond à des travaux relevant du 5° (dans le cas d’une PAC assurant uniquement
                                            le chauffage du logement) ou du 5° et du 6° (dans le cas d’une PAC assurant le chauffage du logement et de l’eau chaude
                                            sanitaire) du I de l’article 1er du décret précité.</p>
                                        <p className='bar1-para'>Identité du professionnel titulaire du signe de qualité ayant réalisé l'opération, s'il n’est pas le signataire de cette
                                            attestation (sous-traitance par exemple) :</p>

                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Nom</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='nom'
                                                    id='nom'
                                                    value={values.nom}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.nom && errors.nom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.nom && errors.nom && <div className="error-message">{errors.nom}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Prénom</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='prénom'
                                                    id='prénom'
                                                    value={values.prénom}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.prénom && errors.prénom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.prénom && errors.prénom && <div className="error-message">{errors.prénom}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Raison sociale : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='b2c_social_reason'
                                                    id='b2c_social_reason'
                                                    value={values.b2c_social_reason}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.b2c_social_reason && errors.b2c_social_reason ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.b2c_social_reason && errors.b2c_social_reason && <div className="error-message">{errors.b2c_social_reason}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*N° SIREN :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='siret_number'
                                                    id='siret_number'
                                                    value={values.siret_number}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.siret_number && errors.siret_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.siret_number && errors.siret_number && <div className="error-message">{errors.siret_number}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                    </div>
                                </div>
                            </div> : null}
                            {values.b2boperations === 'b2c' && values.documents === 'BAR-TH-113' ? <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col'>
                                        <p className='bar1-para'>A/ BAR-TH-113 (v. A41.3) : Mise en place d’une chaudière biomasse individuelle.</p>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='commitmentdate'
                                                    id='commitmentdate'
                                                    value={values.commitmentdate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='dateofproof'
                                                    id='dateofproof'
                                                    value={values.dateofproof}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className='form-control1 mb-0 w-100' ></input>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='invoicereference'
                                                    id='invoicereference'
                                                    value={values.invoicereference}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className='form-control1 mb-0 w-100' ></input>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Pour les personnes morales, nom du site des travaux ou nom de la copropriété :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    name='legalpersons'
                                                    id='legalpersons'
                                                    value={values.legalpersons}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.legalpersons && errors.legalpersons ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.legalpersons && errors.legalpersons && <div className="error-message">{errors.legalpersons}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12 text-left">
                                                <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                    type='checkbox'
                                                    id='same_address'
                                                    checked={isSameAddressChecked}
                                                    onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                                /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type='text'
                                                    name='addressofworks'
                                                    id='addressofworks'
                                                    value={values.addressofworks}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                                {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type='text'
                                                    name='additionaladdress'
                                                    id='additionaladdress'
                                                    value={values.additionaladdress}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Code postal : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='codepostal'
                                                    id='codepostal'
                                                    value={values.codepostal}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Ville :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='city'
                                                    id='city'
                                                    value={values.city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <p className="bar1-head  mt-4">*Maison individuelle existant depuis plus de 2 ans à la date d'engagement de l'opération : </p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Oui"
                                                    name="individual_house"
                                                    id="individual_house"
                                                    onChange={handleChange}
                                                    checked={values.individual_house === "Oui"} />
                                                <span className='span-bar bar1-para'>
                                                    Oui
                                                </span>

                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Non"
                                                    name="individual_house"
                                                    id="individual_house"
                                                    onChange={handleChange}
                                                    checked={values.individual_house === "Non"} />
                                                <span className='span-bar bar1-para '>
                                                    Non
                                                </span>
                                            </label>
                                        </div>
                                        {touched.individual_house && errors.individual_house && <div className="error-message">{errors.individual_house}</div>}
                                        <hr></hr>
                                        <p className="bar1-head  mt-4">*Une note de dimensionnement a été remise au bénéficiaire : </p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="Oui"
                                                    name="sizingnote"
                                                    id="sizingnote"
                                                    onChange={handleChange}
                                                    checked={values.sizingnote === "Oui"} />
                                                <span className='span-bar bar1-para'>
                                                    Oui
                                                </span>

                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="Non"
                                                    name="sizingnote"
                                                    id="sizingnote"
                                                    onChange={handleChange}
                                                    checked={values.sizingnote === "Non"} />
                                                <span className='span-bar bar1-para '>
                                                    Non
                                                </span>
                                            </label>
                                        </div>
                                        {touched.sizingnote && errors.sizingnote && <div className="error-message">{errors.sizingnote}</div>}
                                        <hr></hr>
                                        {/* <p className="bar1-head  mt-4">*Caractéristiques de la chaudière biomasse :</p> */}
                                        <p className="bar1-head  mt-4">*La biomasse utilisée est de la biomasse ligneuse à base de bûches de bois, de copeaux de bois, de bois comprimé sous forme
                                            de granulés, de bois comprimé sous forme de briquettes ou de sciure de bois :</p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="Oui"
                                                    name="wood"
                                                    id="wood"
                                                    onChange={handleChange}
                                                    checked={values.wood === "Oui"} />
                                                <span className='span-bar bar1-para'>
                                                    Oui
                                                </span>

                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="Non"
                                                    name="wood"
                                                    id="wood"
                                                    onChange={handleChange}
                                                    checked={values.wood === "Non"} />
                                                <span className='span-bar bar1-para '>
                                                    Non
                                                </span>
                                            </label>
                                        </div>
                                        {touched.wood && errors.wood && <div className="error-message">{errors.wood}</div>}
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Efficacité énergétique saisonnière (ηs) de la chaudière biomasse (en %) :  </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='seasonal_energy'
                                                    id='seasonal_energy'
                                                    value={values.seasonal_energy}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.seasonal_energy && errors.seasonal_energy ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.seasonal_energy && errors.seasonal_energy && <div className="error-message">{errors.seasonal_energy}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Puissance thermique nominale P de la chaudière biomasse en kW :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='rated_heat'
                                                    id='rated_heat'
                                                    value={values.rated_heat}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.rated_heat && errors.rated_heat ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.rated_heat && errors.rated_heat && <div className="error-message">{errors.rated_heat}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Classe du régulateur :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='regulator_class'
                                                    id='regulator_class'
                                                    value={values.regulator_class}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.regulator_class && errors.regulator_class ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.regulator_class && errors.regulator_class && <div className="error-message">{errors.regulator_class}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <p className="bar1-head  mt-4">* Pour les chaudières à alimentation automatique, présence d’un silo d’au moins 225 litres :</p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="Oui"
                                                    name="boilers_225"
                                                    id="boilers_225"
                                                    onChange={handleChange}
                                                    checked={values.boilers_225 === "Oui"} />
                                                <span className='span-bar bar1-para'>
                                                    Oui
                                                </span>

                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="Non"
                                                    name="boilers_225"
                                                    id="boilers_225"
                                                    onChange={handleChange}
                                                    checked={values.boilers_225 === "Non"} />
                                                <span className='span-bar bar1-para '>
                                                    Non
                                                </span>
                                            </label>
                                        </div>
                                        {touched.boilers_225 && errors.boilers_225 && <div className="error-message">{errors.boilers_225}</div>}
                                        <hr></hr>
                                        <p className="bar1-head  mt-4">* Pour les chaudières à alimentation manuelle, présence d’un ballon tampon :</p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="Oui"
                                                    name="buffer_tank"
                                                    id="buffer_tank"
                                                    onChange={handleChange}
                                                    checked={values.buffer_tank === "Oui"} />
                                                <span className='span-bar bar1-para'>
                                                    Oui
                                                </span>

                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="Non"
                                                    name="buffer_tank"
                                                    id="buffer_tank"
                                                    onChange={handleChange}
                                                    checked={values.buffer_tank === "Non"} />
                                                <span className='span-bar bar1-para '>
                                                    Non
                                                </span>
                                            </label>
                                        </div>
                                        {touched.buffer_tank && errors.buffer_tank && <div className="error-message">{errors.buffer_tank}</div>}
                                        <hr></hr>
                                        <p className='bar1-para'>NB1 : l’efficacité énergétique saisonnière est calculée selon le règlement (EU) 2015/1189 de la Commission du 28 avril 2015.</p>
                                        <p className='bar1-para'>NB2 : l’efficacité énergétique saisonnière prise en compte est celle de la chaudière seule pour les besoins de chauffage des
                                            locaux (hors dispositif de régulation).</p>

                                        <hr></hr>
                                        <p className="bar1-head  mt-4">*Le chargement de la chaudière est opéré de manière (une seule case à cocher) :</p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="automatique"
                                                    name="boiler_operated"
                                                    id="boiler_operated"
                                                    onChange={handleChange}
                                                    checked={values.boiler_operated === "automatique"} />
                                                <span className='span-bar bar1-para'>automatique</span>

                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="manuelle"
                                                    name="boiler_operated"
                                                    id="boiler_operated"
                                                    onChange={handleChange}
                                                    checked={values.boiler_operated === "manuelle"} />
                                                <span className='span-bar bar1-para '>manuelle</span>
                                            </label>
                                        </div>
                                        {touched.boiler_operated && errors.boiler_operated && <div className="error-message">{errors.boiler_operated}</div>}
                                        <hr></hr>
                                        <p className="bar1-head  mt-4">*La chaudière installée possède le label Flamme Verte 7* : </p>
                                        <div className='text-start'>
                                            <label className='para-bar1 '>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="Oui"
                                                    name="boiler_7label"
                                                    id="boiler_7label"
                                                    onChange={handleChange}
                                                    checked={values.boiler_7label === "Oui"} />
                                                <span className='span-bar bar1-para'>OUI</span>

                                            </label>
                                        </div>
                                        <div className='text-start mt-2'>
                                            <label className='para-bar1 text-initical'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    value="Non"
                                                    name="boiler_7label"
                                                    id="boiler_7label"
                                                    onChange={handleChange}
                                                    checked={values.boiler_7label === "Non"} />
                                                <span className='span-bar bar1-para '>NON</span>
                                            </label>
                                        </div>
                                        {touched.boiler_7label && errors.boiler_7label && <div className="error-message">{errors.boiler_7label}</div>}
                                        <hr></hr>
                                        <p className='bar1-para'>*Si la chaudière installée ne possède pas le label Flamme Verte 7*, ses émissions saisonnières de polluants à 10 % d’O2 sont à renseigner ci-dessous :</p>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>- Émissions saisonnières de particules en mg/Nm3</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='seasonal_particulate'
                                                    id='seasonal_particulate'
                                                    value={values.seasonal_particulate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.seasonal_particulate && errors.seasonal_particulate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.seasonal_particulate && errors.seasonal_particulate && <div className="error-message">{errors.seasonal_particulate}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>- Émissions saisonnières de monoxyde de carbone (CO) en mg/Nm3</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='seasonal_carbon'
                                                    id='seasonal_carbon'
                                                    value={values.seasonal_carbon}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.seasonal_carbon && errors.seasonal_carbon ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.seasonal_carbon && errors.seasonal_carbon && <div className="error-message">{errors.seasonal_carbon}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>- Émissions saisonnières d'oxydes d'azote (NOx) en mg/Nm3</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='nitrogen_oxides'
                                                    id='nitrogen_oxides'
                                                    value={values.nitrogen_oxides}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.nitrogen_oxides && errors.nitrogen_oxides ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.nitrogen_oxides && errors.nitrogen_oxides && <div className="error-message">{errors.nitrogen_oxides}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>- Émissions saisonnières de composés organiques gazeux en mg/Nm3</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='gaseous_organic'
                                                    id='gaseous_organic'
                                                    value={values.gaseous_organic}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.gaseous_organic && errors.gaseous_organic ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.gaseous_organic && errors.gaseous_organic && <div className="error-message">{errors.gaseous_organic}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <p className='bar1-para'>A ne remplir que si les marque et référence de la chaudière ne sont pas mentionnées sur la preuve de réalisation de l’opération :</p>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Marque :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='brand'
                                                    id='brand'
                                                    value={values.brand}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Référence : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='reference'
                                                    id='reference'
                                                    value={values.reference}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <p className='bar1-para'>Le professionnel réalisant l’opération est titulaire d’un signe de qualité conforme aux exigences prévues à l’article 2 du décret
                                            n° 2014-812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des impôts
                                            et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris pour son application. Ce
                                            signe de qualité correspond à des travaux relevant du 3° du I de l'article 1er du décret précité.</p>

                                        <p className='bar1-para'>Identité du professionnel titulaire du signe de qualité ayant réalisé l’opération, s’il n’est pas le signataire de cette attestation
                                            (sous-traitant par exemple) :</p>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Nom</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='nom'
                                                    id='nom'
                                                    value={values.nom}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.nom && errors.nom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.nom && errors.nom && <div className="error-message">{errors.nom}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Prénom</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='prénom'
                                                    id='prénom'
                                                    value={values.prénom}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.prénom && errors.prénom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.prénom && errors.prénom && <div className="error-message">{errors.prénom}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*Raison sociale : </p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='b2c_social_reason'
                                                    id='b2c_social_reason'
                                                    value={values.b2c_social_reason}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.b2c_social_reason && errors.b2c_social_reason ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.b2c_social_reason && errors.b2c_social_reason && <div className="error-message">{errors.b2c_social_reason}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-sm-12">
                                                <p className='bar1-head mt-3'>*N° SIREN :</p>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input
                                                    type="text"
                                                    name='siret_number'
                                                    id='siret_number'
                                                    value={values.siret_number}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.siret_number && errors.siret_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.siret_number && errors.siret_number && <div className="error-message">{errors.siret_number}</div>}
                                            </div>
                                        </div>
                                        <hr></hr>
                                    </div>
                                </div>
                            </div> : null}
                            {/*      {values.b2boperations === 'b2c' && values.documents === 'BAR-EN-101' ? <p></p>: null}*/}
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default OperationB2C