import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Formik } from 'formik';
const B2CUpdate = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    // ----------------------------------------------
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    // useEffect(() => {
    //     const storedFormValues = sessionStorage.getItem('step-4');

    //     if (storedFormValues) {
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.chantier_chooseType = parsedFormValues.chantier_chooseType;
    //         initialValues.b2c_nomduclient = parsedFormValues.b2c_nomduclient;
    //         initialValues.b2c_prénomduclient = parsedFormValues.b2c_prénomduclient;
    //         initialValues.beneficiary_email = parsedFormValues.beneficiary_email;
    //         initialValues.b2c_nfixe = parsedFormValues.b2c_nfixe;
    //         initialValues.b2c_nmobile = parsedFormValues.b2c_nmobile;
    //         initialValues.b2c_adressedestravaux = parsedFormValues.b2c_adressedestravaux;
    //         initialValues.b2c_cpdestravaux = parsedFormValues.b2c_cpdestravaux;
    //         initialValues.b2c_villedestravaux = parsedFormValues.b2c_villedestravaux;
    //         initialValues.b2c_adressedestravaux2 = parsedFormValues.b2c_adressedestravaux2;
    //         initialValues.b2c_nfiscal = parsedFormValues.b2c_nfiscal;
    //         initialValues.b2c_nreffiscal = parsedFormValues.b2c_nreffiscal;
    //         initialValues.b2c_menages = parsedFormValues.b2c_menages;
    //         initialValues.b2c_taxnotice = parsedFormValues.b2c_taxnotice;
    //         initialValues.chantier_buildingFloorArea = parsedFormValues.chantier_buildingFloorArea;
    //     }
    // }, []);
    // useFormik
    const [initialValues, setInitialValues] = useState(
        {
            chantier_chooseType: '',
            b2c_nomduclient: '',
            b2c_prénomduclient: '',
            beneficiary_email: '',
            b2c_nfixe: '',
            b2c_nmobile: '',
            b2c_adressedestravaux: '',
            b2c_cpdestravaux: '',
            b2c_villedestravaux: '',
            b2c_adressedestravaux2: '',
            b2c_nfiscal: '',
            b2c_nreffiscal: '',
            b2c_menages: '',
            b2c_taxnotice: false,
            chantier_buildingFloorArea: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            chantier_chooseType: MultiUpdate?.multisteptable1?.chantier_choosetype || '', 
            b2c_nomduclient: MultiUpdate?.multisteptable2?.nomduclient || '', 
            b2c_prénomduclient:  MultiUpdate?.multisteptable2?.prénomduclient || '',
            beneficiary_email:  MultiUpdate?.multisteptable2?.nomduclient || '',
            b2c_nfixe:  MultiUpdate?.multisteptable2?.nfixe || '',
            b2c_nmobile:  MultiUpdate?.multisteptable2?.nmobile || '',
            b2c_adressedestravaux:  MultiUpdate?.multisteptable2?.adressedestravaux || '',
            b2c_cpdestravaux:  MultiUpdate?.multisteptable2?.cpdestravaux || '',
            b2c_villedestravaux:  MultiUpdate?.multisteptable2?.villedestravaux || '',
            b2c_adressedestravaux2:  MultiUpdate?.multisteptable2?.adressedestravaux2 || '',
            b2c_nfiscal:  MultiUpdate?.multisteptable2?.nfiscal || '',
            b2c_nreffiscal:  MultiUpdate?.multisteptable2?.nreffiscal || '',
            b2c_menages:  MultiUpdate?.multisteptable2?.menages || '',
            b2c_taxnotice:  MultiUpdate?.multisteptable2?.taxnotice || false,
            chantier_buildingFloorArea:  MultiUpdate?.multisteptable1?.chantier_buildingfloorarea || '',
        }));
    }, []);
    // VALIDE
    const validate = (values) => {
        const errors = {};

        if (!values.chantier_chooseType) {
            errors.chantier_chooseType = 'Mes bénéficiaires requise';
        }

        if (!values.b2c_nomduclient) {
            errors.b2c_nomduclient = 'b2c_nomduclient requise';
        }
        if (!values.b2c_prénomduclient) {
            errors.b2c_prénomduclient = 'b2c_prénomduclient requise';
        }
        if (!values.beneficiary_email) {
            errors.beneficiary_email = 'email requise';
        }
        if (!values.b2c_adressedestravaux) {
            errors.b2c_adressedestravaux = 'b2c_adressedestravaux requise';
        }
        if (!values.b2c_cpdestravaux) {
            errors.b2c_cpdestravaux = 'b2c_cpdestravaux requise';
        }
        if (!values.b2c_villedestravaux) {
            errors.b2c_villedestravaux = 'b2c_villedestravaux requise';
        }
        if (!values.b2c_adressedestravaux2) {
            errors.b2c_adressedestravaux2 = 'b2c_adressedestravaux2 requise';
        }
        if (!values.chantier_buildingFloorArea) {
            errors.chantier_buildingFloorArea = 'Surface au sol du batiment requise';
        }
        return errors;
    };

    const handleSubmit = (values) => {
        console.log({ 'values': values });
        sessionStorage.setItem("step-4", JSON.stringify(values));
        navigate("/b2c-operation-update");
    };
    const handlePrev = () => {
        navigate(-1);
    };

    // useEffect(() => {
    //     const storedFormData = sessionStorage.getItem('step-4');
    //     if (storedFormData) {
    //         setStoredData(JSON.parse(storedFormData));
    //     }
    // }, []);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid-fluid pl-30'>
                            <p className='bar1-head'>Créer un nouveau dossier / B2C</p>
                            <div className='row'>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para '>*Mes bénéficiaires</p>
                                    <div className=''>
                                        <select name='chantier_chooseType'
                                            value={values.chantier_chooseType}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.chantier_chooseType && errors.chantier_chooseType ? 'error' : 'form-select ps-3 btn-buttons'}
                                            aria-label="Default select example">
                                            <option defaultValue>Choisir un type</option>
                                            <option value="Classique">Classique</option>
                                            <option value="QPV">QPV </option>
                                            {/* <option value="Bailleursocial">Bailleur social</option> */}
                                        </select>
                                        {touched.chantier_chooseType && errors.chantier_chooseType && <div className="error-message">{errors.chantier_chooseType}</div>}
                                    </div>
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>*Nom du client</p>
                                    <input
                                        type="text"
                                        name="b2c_nomduclient"
                                        value={values.b2c_nomduclient}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_nomduclient && errors.b2c_nomduclient ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.b2c_nomduclient && errors.b2c_nomduclient && <div className="error-message">{errors.b2c_nomduclient}</div>}
                                </div>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para'>*Prénom du client</p>
                                    <input type="text"
                                        name="b2c_prénomduclient"
                                        value={values.b2c_prénomduclient}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_prénomduclient && errors.b2c_prénomduclient ? 'error' : 'form-control1 w-100 m-0'} >
                                    </input>
                                    {touched.b2c_prénomduclient && errors.b2c_prénomduclient && <div className="error-message">{errors.b2c_prénomduclient}</div>}
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>*Email</p>
                                    <input type="text"
                                        name="beneficiary_email"
                                        value={values.beneficiary_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_email && errors.beneficiary_email ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.beneficiary_email && errors.beneficiary_email && <div className="error-message">{errors.beneficiary_email}</div>}
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>N fixe</p>
                                    <input type="number"
                                        name="b2c_nfixe"
                                        value={values.b2c_nfixe}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_nfixe && errors.b2c_nfixe ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.b2c_nfixe && errors.b2c_nfixe && <div className="error-message">{errors.b2c_nfixe}</div>}
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>N mobile</p>
                                    <input type="text"
                                        name="b2c_nmobile"
                                        value={values.b2c_nmobile}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_nmobile && errors.b2c_nmobile ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.b2c_nmobile && errors.b2c_nmobile && <div className="error-message">{errors.b2c_nmobile}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>*Adresse des travaux</p>
                                    <input type="text" name="b2c_adressedestravaux"
                                        value={values.b2c_adressedestravaux}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_adressedestravaux && errors.b2c_adressedestravaux ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.b2c_adressedestravaux && errors.b2c_adressedestravaux && <div className="error-message">{errors.b2c_adressedestravaux}</div>}
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>*CP des travaux</p>
                                    <input type="number" name="b2c_cpdestravaux"
                                        value={values.b2c_cpdestravaux}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_cpdestravaux && errors.b2c_cpdestravaux ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.b2c_cpdestravaux && errors.b2c_cpdestravaux && <div className="error-message">{errors.b2c_cpdestravaux}</div>}
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>*Ville des travaux</p>
                                    <input type="text" name="b2c_villedestravaux"
                                        value={values.b2c_villedestravaux}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_villedestravaux && errors.b2c_villedestravaux ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.b2c_villedestravaux && errors.b2c_villedestravaux && <div className="error-message">{errors.b2c_villedestravaux}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>*Adresse des travaux</p>
                                    <input type="text" name="b2c_adressedestravaux2"
                                        value={values.b2c_adressedestravaux2}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_adressedestravaux2 && errors.b2c_adressedestravaux2 ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.b2c_adressedestravaux2 && errors.b2c_adressedestravaux2 && <div className="error-message">{errors.b2c_adressedestravaux2}</div>}
                                </div>
                                <div className='col-sm-3 input-box1 text-center'>
                                    <div className='ms-2 mt-42'>
                                        <input type="checkbox"
                                            name='b2c_taxnotice'
                                            id='b2c_taxnotice'
                                            checked={values.b2c_taxnotice}
                                            onChange={handleChange} />
                                        <span className='span-bar bar1-para'>
                                            Verifier l’avis d’impot
                                        </span>
                                        {touched.b2c_taxnotice && errors.b2c_taxnotice && (
                                            <div className="error-message">{errors.b2c_taxnotice}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-3 input-box1'>
                                    <p className='bar1-para'>*Menages</p>
                                    <input type="text" name="b2c_menages"
                                        value={values.b2c_menages}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_menages && errors.b2c_menages ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.b2c_menages && errors.b2c_menages && <div className="error-message">{errors.b2c_menages}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>N° fiscal</p>
                                    <input type="text" name="b2c_nfiscal"
                                        value={values.b2c_nfiscal}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_nfiscal && errors.b2c_nfiscal ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.b2c_nfiscal && errors.b2c_nfiscal && <div className="error-message">{errors.b2c_nfiscal}</div>}
                                </div>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>N° Ref fiscal</p>
                                    <input type="text" name="b2c_nreffiscal"
                                        value={values.b2c_nreffiscal}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.b2c_nfiscal && errors.b2c_nreffiscal ? 'error' : 'form-control1 w-100 m-0'}></input>
                                    {touched.b2c_nreffiscal && errors.b2c_nreffiscal && <div className="error-message">{errors.b2c_nreffiscal}</div>}
                                </div>

                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1  '>
                                    <p className='bar1-para m-0'>Surface au sol du batiment*</p>
                                    <input type="number"
                                        name="chantier_buildingFloorArea"
                                        value={values.chantier_buildingFloorArea}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_buildingFloorArea && errors.chantier_buildingFloorArea ? 'error' : 'form-cont w-100 ms-0'} />
                                    {touched.chantier_buildingFloorArea && errors.chantier_buildingFloorArea && <div className="error-message">{errors.chantier_buildingFloorArea}</div>}
                                </div>
                            </div>
                            <div className='row mt-5 mb-2'>
                                <div className='col text-start'>
                                    <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                </div>
                                <div className="col text-end">
                                    <button type='submit' className='btns-color'>Suivant</button>
                                </div>
                            </div>

                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default B2CUpdate