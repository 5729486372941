import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// FORMIK
import { Formik } from 'formik';
import { QPVCALVALUE } from '../arraystore/CountryCodeArray'

const B2b_Worksite = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const same_addressget = JSON.parse(sessionStorage.getItem('step-3'));
    console.log('same_address', same_addressget);
    const same_address = same_addressget.beneficiary_address;
    const same_cp = same_addressget.beneficiary_cp;
    const same_ville = same_addressget.beneficiary_city;
    console.log('same_address', same_address);
    const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);

    // ----------------------------------------------
    useEffect(() => {
        // Retrieve stored form values from local storage
        const storedFormValues = sessionStorage.getItem('step-4');

        if (storedFormValues) {
            // Parse the stored form values and set them as initial values
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.chantier_address = parsedFormValues.chantier_address;
            initialValues.chantier_cp = parsedFormValues.chantier_cp;
            initialValues.chantier_city = parsedFormValues.chantier_city;
            initialValues.chantier_wardName = parsedFormValues.chantier_wardName;
            initialValues.chantier_chooseType = parsedFormValues.chantier_chooseType;
            initialValues.chantier_districtCode = parsedFormValues.chantier_districtCode;
            initialValues.chantier_greatPrecariousness = parsedFormValues.chantier_greatPrecariousness;
            initialValues.chantier_precarious = parsedFormValues.chantier_precarious;
            initialValues.chantier_contact = parsedFormValues.chantier_contact;
            initialValues.chantier_telephone = parsedFormValues.chantier_telephone;
            initialValues.chantier_ageOfBuilding = parsedFormValues.chantier_ageOfBuilding;
            initialValues.chantier_heatingMode = parsedFormValues.chantier_heatingMode;
            initialValues.chantier_buildingFloorArea = parsedFormValues.chantier_buildingFloorArea;
            initialValues.chantier_typeOfBuilding = parsedFormValues.chantier_typeOfBuilding;
        }
    }, []);
    const initialValues = {
        chantier_address: '',
        chantier_cp: '',
        chantier_city: '',
        chantier_wardName: '',
        chantier_chooseType: '',
        chantier_districtCode: '',
        chantier_greatPrecariousness: '',
        chantier_precarious: '',
        chantier_contact: '',
        chantier_telephone: '',
        chantier_ageOfBuilding: '',
        chantier_heatingMode: '',
        chantier_buildingFloorArea: '',
        chantier_typeOfBuilding: '',
    }
    // ... Other code ...

    const handleCheckboxChange = (e, setFieldValue) => {

        const isChecked = e.target.checked;
        setIsSameAddressChecked(isChecked);
        if (isChecked) {
            setFieldValue('chantier_address', same_address);
            setFieldValue('chantier_cp', same_cp);
            setFieldValue('chantier_city', same_ville);
        } else {
            setFieldValue('chantier_address', initialValues.chantier_address);
            setFieldValue('chantier_cp', initialValues.chantier_cp);
            setFieldValue('chantier_city', initialValues.chantier_city);
        }
    };
    // VALIDE
    const validate = (values) => {
        const errors = {};
        if (!values.chantier_address) {
            errors.chantier_address = 'Adresse requise';
        }
        if (!values.chantier_cp) {
            errors.chantier_cp = 'CP requise';
        } else if (!/^\d{5}$/i.test(values.chantier_cp)) {
            errors.chantier_cp = 'Cinq chiffres au minimum uniquement';
        }
        if (!values.chantier_city) {
            errors.chantier_city = 'Ville requise';
        }
        if (!values.chantier_ageOfBuilding) {
            errors.chantier_ageOfBuilding = 'Age de(s) batiment(s) requise';
        }
        if (!values.chantier_heatingMode) {
            errors.chantier_heatingMode = 'Mode de chauffage requise';
        }
        if (!values.chantier_typeOfBuilding) {
            errors.chantier_typeOfBuilding = 'Type de batiment requise';
        }
        // if (!values.chantier_buildingFloorArea) {
        //     errors.chantier_buildingFloorArea = 'Surface au sol du batiment requise';
        // }
        if (values.chantier_chooseType === 'QPV' && !values.chantier_districtCode) {
            errors.chantier_districtCode = 'Code de quartier requise';
        }
        return errors;
    }
    // ARRAY VALUES
    console.log('QPVCALVALUE', QPVCALVALUE);

    const getCPValue = (values, setFieldValue) => {
        console.log('chantier_cp', values.chantier_cp);
        setFieldValue('chantier_cp', values.chantier_cp);
        if (values.chantier_cp) {
            const cpsplit = String(values.chantier_cp).substr(0, 2);
            // const cpsplit = values.chantier_cp.substr(0, 2);
            // const cpsplit = Math.floor(values.chantier_cp/100);
            console.log('two digit code', cpsplit);
            const cpValues = QPVCALVALUE[cpsplit] || '';
            console.log('cpValues', cpValues);
            setFieldValue('chantier_greatPrecariousness', cpValues.chantier_greatPrecariousness || '');
            setFieldValue('chantier_precarious', cpValues.chantier_precarious || '');
        }

    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-4", JSON.stringify(values));
        navigate('/residential');
    }
    // BACK BUTTON
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-4');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="container-fluid px-4">
                            <p className='bar1-head  '>Chantier</p>
                            <div className='row mt-2'>
                                <div className='col-sm-6 input-box1 text-left'>
                                    <label className='bar1-para mb-3'>Adresse*</label> &nbsp; <input
                                        type='checkbox'
                                        id='same_address'
                                        checked={isSameAddressChecked}
                                        onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                    /> <label htmlFor='same_address' className='bar1-para '>Même adresse</label>
                                    <input type="text"
                                        name="chantier_address"
                                        value={values.chantier_address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_address && errors.chantier_address ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.chantier_address && errors.chantier_address && <div className="error-message">{errors.chantier_address}</div>}
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>CP*</p>
                                    <input type="number"
                                        name="chantier_cp"
                                        value={values.chantier_cp}
                                        onChange={handleChange}
                                        onKeyUp={() => getCPValue(values, setFieldValue)}
                                        onBlur={handleBlur}
                                        className={touched.chantier_cp && errors.chantier_cp ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.chantier_cp && errors.chantier_cp && <div className="error-message">{errors.chantier_cp}</div>}
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>Ville*</p>
                                    <input type="text"
                                        name="chantier_city"
                                        value={values.chantier_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_city && errors.chantier_city ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.chantier_city && errors.chantier_city && <div className="error-message">{errors.chantier_city}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-3 input-box1'>
                                    <p className='bar1-para'>Nom de quartier</p>
                                    <input
                                        type="text"
                                        name="chantier_wardName"
                                        value={values.chantier_wardName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_wardName && errors.chantier_wardName ? 'error' : 'form-control1 w-100 m-0'} />

                                </div>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para '>Type de quartier*</p>
                                    <div className='d-flex'>
                                        <select name='chantier_chooseType' id='chantier_chooseType' value={values.chantier_chooseType} onChange={handleChange} className="form-select ps-3 btn-buttons" aria-label="Choisir un type">
                                            <option value>Choisir un type</option>
                                            <option value="Classique">Classique</option>
                                            <option value="QPV">QPV</option>
                                            {/* <option value="Bailleur social">Bailleur social</option> */}
                                        </select>
                                    </div>
                                    {values.chantier_chooseType === 'QPV' && (
                                        <div className='row row-cols-2 row-cols-lg-2 mt-3'>
                                            <div className="col"> <p className='bar1-para m-0'>Grande precarite*</p></div>
                                            <div className="col">
                                                <input type="text"
                                                    name="chantier_greatPrecariousness"
                                                    value={values.chantier_greatPrecariousness}
                                                    onChange={handleChange}
                                                    className={touched.chantier_greatPrecariousness && errors.chantier_greatPrecariousness ? 'error' : 'form-control1 w-100'} required />
                                                {/* {touched.greatPrecariousness && errors.greatPrecariousness && <div className="error-message">{errors.chantier_city}</div>} */}
                                            </div>
                                            <div className="col mt-3"> <p className='bar1-para m-0'>Precarite*</p></div>
                                            <div className="col mt-3">
                                                <input type="text"
                                                    name="chantier_precarious"
                                                    value={values.chantier_precarious}
                                                    onChange={handleChange}
                                                    className={touched.chantier_precarious && errors.chantier_precarious ? 'error' : 'form-control1 w-100'} required /></div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>Code de quartier</p>
                                    <input type="number"
                                        name="chantier_districtCode"
                                        value={values.chantier_districtCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className='form-control1 w-100 m-0' />
                                    {touched.chantier_districtCode && errors.chantier_districtCode && <div className="error-message">{errors.chantier_districtCode}</div>}
                                </div>

                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para'>Contact</p>
                                    <input type="number"
                                        name="chantier_contact"
                                        value={values.chantier_contact}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_contact && errors.chantier_contact ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                                <div className='col-sm-3 input-box1'>
                                    <p className='bar1-para'>Telephone</p>
                                    <input type="number"
                                        name="chantier_telephone"
                                        value={values.chantier_telephone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_telephone && errors.chantier_telephone ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para '>Type de batiment*</p>
                                    <div className=''>
                                        <select
                                            name='chantier_typeOfBuilding'
                                            id='chantier_typeOfBuilding'
                                            value={values.chantier_typeOfBuilding}
                                            onChange={handleChange}
                                            className={touched.chantier_typeOfBuilding && errors.chantier_typeOfBuilding ? 'error' : 'form-select btn-buttons ps-3'} aria-label="Mode de chauffage">
                                            <option defaultValue='Mode de chauffage'>choisir batiment</option>
                                            <option value="Bureaux">Bureaux </option>
                                            <option value="Enseignement">Enseignement</option>
                                            <option value="Commerces">Commerces</option>
                                            <option value="Autres secteurs">Autres secteurs</option>
                                            <option value="Hotellerie Restauration">Hotellerie Restauration</option>
                                            <option value="Sante">Sante</option>
                                        </select>
                                        {touched.chantier_typeOfBuilding && errors.chantier_typeOfBuilding && <div className="error-message">{errors.chantier_typeOfBuilding}</div>}
                                    </div>
                                </div>

                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-3 input-box1'>
                                    <p className='bar1-para '>Age de(s) batiment(s)*</p>
                                    <div className=''>
                                        <select
                                            name='chantier_ageOfBuilding'
                                            id='chantier_ageOfBuilding'
                                            value={values.chantier_ageOfBuilding}
                                            onChange={handleChange}
                                            className={touched.chantier_ageOfBuilding && errors.chantier_ageOfBuilding ? 'error' : 'form-select ps-3 btn-buttons'}
                                            aria-label="Plus de 2ans">
                                            <option defaultValue='Age de(s) batiment(s)'>Age de(s) batiment(s)</option>
                                            <option value="Plus de 2ans">Plus de 2ans</option>
                                        </select>
                                        {touched.chantier_ageOfBuilding && errors.chantier_ageOfBuilding && <div className="error-message">{errors.chantier_ageOfBuilding}</div>}
                                    </div>
                                </div>
                                <div className='col-sm-3 input-box1'>
                                    <p className='bar1-para '>Mode de chauffage*</p>
                                    <div className=''>
                                        <select name='chantier_heatingMode'
                                            id='chantier_heatingMode'
                                            value={values.chantier_heatingMode}
                                            onChange={handleChange}
                                            className={touched.chantier_heatingMode && errors.chantier_heatingMode ? 'error' : 'form-select btn-buttons ps-3'} aria-label="Mode de chauffage">
                                            <option defaultValue='Mode de chauffage'>Mode de chauffage</option>
                                            <option value="Electrique">Electrique </option>
                                            <option value="Combustible">Combustible</option>
                                        </select>
                                        {touched.chantier_heatingMode && errors.chantier_heatingMode && <div className="error-message">{errors.chantier_heatingMode}</div>}
                                    </div>
                                </div>
                                <div className='col-sm-6 input-box1  '>
                                    <p className='bar1-para m-0'>Surface au sol du batiment</p>
                                    <input type="number"
                                        name="chantier_buildingFloorArea"
                                        value={values.chantier_buildingFloorArea}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_buildingFloorArea && errors.chantier_buildingFloorArea ? 'error' : 'form-cont w-100 ms-0'} />
                                    {/* {touched.chantier_buildingFloorArea && errors.chantier_buildingFloorArea && <div className="error-message">{errors.chantier_buildingFloorArea}</div>} */}
                                </div>
                            </div>
                            <div className='row mt-5 mb-2'>
                                <div className='col text-start'>
                                    <button type='button' onClick={handlePrev} className=' btns-color' >Précédent </button>
                                </div>
                                <div className="col text-end">
                                    <button type='submit' className='btns-color' >Suivant</button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default B2b_Worksite