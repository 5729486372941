import React from 'react'

import { Col, Nav, Row, Tab } from 'react-bootstrap';

function Permissions() {
    return (
        <div className="container-fluid-fluid pl-30">
            <div className="row text_top">
                <div className="col-sm-4 ">
                    <div className=' '>
                        <p className='bar1-para btn-on6 text-white text-center p-2 '>Les profils des utilisateurs</p>
                    </div>
                </div>
                <div className="col    ">

                    <div className='btn-on7 d-flex text-center justify-between'>

                        <div className='postionss text-center ms-3'>
                            <p className=' bar1-para text-white text-center'>Permission      </p>
                        </div>
                        <div className='postionss '>
                            <p className='bar1-para  text-white text-center'>Détail</p>
                        </div>
                        <div className='postionss '>
                            <p className='bar1-para text-white text-center'>Afficher</p>
                        </div>
                        <div className=' postionss'>
                            <p className='bar1-para text-white text-center'>Ajouter</p>
                        </div>
                        <div className=' postionss'>
                            <p className='bar1-para text-white text-center'>Modifier</p>
                        </div>
                        <div className='postionss '>
                            <p className='bar1-para text-white text-center'>Modifier</p>
                        </div>
                        <div className='postionss mr-15'>
                            <p className='bar1-para text-white text-center'>Toutes</p>
                        </div>

                    </div>


                </div>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={4}>
                        <Nav variant="pills" className="border-sizeers d-block text-start">
                            <Nav.Item>
                                <Nav.Link className='bg-white ' eventKey="first " >Agent administratif</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className='bg-white ' eventKey="second">Agent bureau de contrôle</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className='bg-white ' eventKey="third">Agent technique</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className='bg-white ' eventKey="four">Manager</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className='bg-white ' eventKey="five">Super Manager</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className='bg-white ' eventKey="six">Technicien</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className='bg-white ' eventKey="sevan">Installateurs</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className='bg-white ' eventKey="eight">Oblige</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className='bg-white ' eventKey="nine">Polluers</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>

                </Row>
            </Tab.Container>

        </div>
    )
}

export default Permissions;