import React, { useState, useEffect } from 'react'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
const CalcultBATEN101Update = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    // ----------------------------------------------
    // useEffect(() => {
    //     const storedFormValues = sessionStorage.getItem('step-6');

    //     if (storedFormValues) {
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.tertiarysector = parsedFormValues.tertiarysector;
    //         initialValues.codepostal = parsedFormValues.codepostal;
    //         initialValues.typeofpose = parsedFormValues.typeofpose;
    //         initialValues.surface = parsedFormValues.surface;
    //         initialValues.startofwork = parsedFormValues.startofwork;
    //         initialValues.twelvemonths = parsedFormValues.twelvemonths;
    //         initialValues.thermalinsulation = parsedFormValues.thermalinsulation;
    //         initialValues.buildingindustry = parsedFormValues.buildingindustry;
    //     }
    // }, []);
    const [initialValues, setInitialValues] = useState(
        {
            tertiarysector: '',
            codepostal: '',
            typeofpose: '',
            buildingindustry: '',
            surface: '',
            startofwork: '',
            twelvemonths: '',
            thermalinsulation: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            tertiarysector: MultiUpdate?.multisteptable2?.tertiarysector || '',
            codepostal: MultiUpdate?.multisteptable2?.codepostal || '',
            typeofpose: MultiUpdate?.multisteptable2?.typeofpose || '',
            buildingindustry: MultiUpdate?.multisteptable2?.buildingindustry || '',
            surface: MultiUpdate?.multisteptable2?.surface || '',
            startofwork: MultiUpdate?.multisteptable2?.startofwork || '',
            twelvemonths: MultiUpdate?.multisteptable2?.twelvemonths || '',
            thermalinsulation: MultiUpdate?.multisteptable2?.thermalinsulation || '',
        }));
    }, []);

    const validate = (values) => {
        const errors = {};
        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/update-estimate");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    // useEffect(() => {
    //     const storedFormData = sessionStorage.getItem('step-6');
    //     if (storedFormData) {
    //         setStoredData(JSON.parse(storedFormData));
    //     }
    // }, []);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}  enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid   '>
                            <div className='row'>
                                <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Isolation d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire </p>
                                <div className='col'>
                                    <p className="bar1-head  mt-4">Bâtiment secteur tertiaire existant depuis plus de 2 ans ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="tertiarysector"
                                                id="tertiarysector"
                                                value="yes"
                                                onChange={handleChange}
                                                checked={values.tertiarysector === "yes"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span> </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="tertiarysector"
                                                id="tertiarysector"
                                                value="no"
                                                onChange={handleChange}
                                                checked={values.tertiarysector === "no"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>

                                    <div className="row align-items-center">
                                        <div className="col-lg-4 col-sm-12">
                                            <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                        </div>
                                        <div className="col-lg-3 col-sm-12">
                                            <input
                                                type="number"
                                                name='codepostal'
                                                id='codepostal'
                                                value={values.codepostal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">L’isolation est effectuée entre une zone chauffée et une zone non chauffée ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="yes"
                                                name="thermalinsulation"
                                                id="thermalinsulation"
                                                onChange={handleChange}
                                                checked={values.thermalinsulation === "yes"} />
                                            <span className='span-bar bar1-para'> Oui</span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="no"
                                                name="thermalinsulation"
                                                id="thermalinsulation"
                                                onChange={handleChange}
                                                checked={values.thermalinsulation === "no"} />
                                            <span className='span-bar bar1-para '> Non</span>
                                        </label>
                                    </div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>
                                    <p className="bar1-head  mt-4">Type de pose ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="typeofpose"
                                                id="typeofpose"
                                                value="floor of an undeveloped space"
                                                onChange={handleChange}
                                                checked={values.typeofpose === "floor of an undeveloped space"} />
                                            <span className='span-bar bar1-para'>
                                                en comble perdu uniquement (ex : sol d’un espace non-aménagé)
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="typeofpose"
                                                id="typeofpose"
                                                value="sloping wall of the roof"
                                                onChange={handleChange}
                                                checked={values.typeofpose === "sloping wall of the roof"} />
                                            <span className='span-bar bar1-para '>
                                                en rampant de toiture uniquement (ex : paroi inclinée du toit)
                                            </span>
                                        </label>

                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="typeofpose"
                                                id="typeofpose"
                                                value="lost attic and roof slope"
                                                onChange={handleChange}
                                                checked={values.typeofpose === "lost attic and roof slope"} />
                                            <span className='span-bar bar1-para '>
                                                en comble perdu et en rampant de toiture
                                            </span>
                                        </label>

                                    </div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>
                                    <p className="bar1-head  mt-4">Secteur d'activité du bâtiment :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Bureaux"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Bureaux"} />
                                            <span className='span-bar bar1-para'>
                                                Bureaux
                                            </span></label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Enseignement"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Enseignement"} />
                                            <span className='span-bar bar1-para '>
                                                Enseignement
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Commerces"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Commerces"} />
                                            <span className='span-bar bar1-para '>
                                                Commerces
                                            </span>
                                        </label>  </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Hôtellerie - Restauration"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Hôtellerie - Restauration"} />
                                            <span className='span-bar bar1-para '>
                                                Hôtellerie / Restauration
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Santé"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Santé"} />
                                            <span className='span-bar bar1-para '>
                                                Santé
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Autres secteurs tertiaires"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Autres secteurs tertiaires"} />
                                            <span className='span-bar bar1-para '> Autres secteurs tertiaires </span>
                                        </label>
                                    </div>

                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>
                                    <div className="row">
                                        <div className="col-4">
                                            <p className='bar1-head mt-3'>Surface totale isolée (en m²) :</p></div>
                                        <div className="col-4 text-start align-items-center d-flex"><input type="number"
                                            name="surface"
                                            id="surface"
                                            value={values.surface}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.surface && errors.surface ? 'error' : 'form-control1'}
                                            placeholder='0,00' ></input>
                                        </div>
                                    </div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">Pour lutter contre la fraude, le début des travaux ne peut commencer que 8 jours après la date de commande ?</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="startofwork"
                                                id="startofwork"
                                                value="Yes"
                                                onChange={handleChange}
                                                checked={values.startofwork === "Yes"} />
                                            <span className='span-bar bar1-para'>
                                                Oui, un délai minimum de 8 jours est/sera respecté
                                            </span></label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="startofwork"
                                                id="startofwork"
                                                value="No"
                                                onChange={handleChange}
                                                checked={values.startofwork === "No"} />
                                            <span className='span-bar bar1-para '>
                                                Non, la pose est/sera réalisée moins de 8 jours après la commande
                                            </span>
                                        </label>
                                    </div><hr />
                                    <p className="bar1-head  mt-4">Aucune opération identique n’a été réalisée sur ce bâtiment depuis douze mois ?</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="twelvemonths"
                                                id="twelvemonths"
                                                value="Oui"
                                                onChange={handleChange}
                                                checked={values.twelvemonths === "Oui"} />
                                            <span className='span-bar bar1-para'>
                                                Oui, aucune opération identique sur ce bâtiment depuis douze mois
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="twelvemonths"
                                                id="twelvemonths"
                                                value="Non"
                                                onChange={handleChange}
                                                checked={values.twelvemonths === "Non"} />
                                            <span className='span-bar bar1-para '>
                                                Non, une opération identique a été réalisée il y a moins de douze mois sur ce bâtiment
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5 mb-2'>
                                <div className='col text-start'>
                                    <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                </div>
                                <div className="col text-end">
                                    <button type='submit' className='btns-color'>Suivant</button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default CalcultBATEN101Update