import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom"
import { Formik, FieldArray } from 'formik';
import axios from 'axios'
import { toast } from 'react-toastify';
function Edituser() {
    const { id } = useParams();
    console.log(id);
    const navigate = useNavigate();
    const Token = localStorage.getItem('Token');
    const toastsuccess = (val) => toast.success(val);
    // FETCH DATA
    const [data, setData] = useState(null);

    const installerorobligelocal = localStorage.getItem('installerorobligelocal');
    const installerorobligelocalid = localStorage.getItem('installerorobligelocalid');
    console.log('installerorobligelocal', installerorobligelocal, 'installerorobligelocalid', installerorobligelocalid);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/getuser/${id}`, {
                headers:
                    { authorization: Token }
            });
            if (response.status === 200) {
                console.log(response?.data?.userData[0]);
                setData(response?.data?.userData[0]);
            }
        } catch (error) {
            console.error(error?.response?.data?.message);
        }
    }
    const [initialValues, setInitialValues] = useState(
        {
            user_id: '',
            name: '',
            firstName: '',
            role: '',
            address: '',
            cp: '',
            city: '',
            emailId: '',
            N_fixed: '',
            phone: '',
        }
    );
    console.log(data);
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            user_id: data?.users?.id,
            name: data?.users?.name,
            firstName: data?.users?.firstname,
            role: data?.role?.rolename,
            address: data?.users?.address,
            cp: data?.users?.cp,
            city: data?.users?.city,
            emailId: data?.users?.email_id,
            N_fixed: data?.users?.n_fixed,
            phone: data?.users?.phone,
        }));
    }, [data]);
    console.log(data?.users?.cp);
    // VALIDE
    const validate = (values) => {
        console.log("validate", values);
        const errors = {};
        return errors;
    }
    // HANDLE SUBMIT && DATA POST
    const handleSubmit = async (values, { resetForm }) => {
        console.log(values);
        try {
            const updateresponse = await axios.post(`${process.env.REACT_APP_API_URL}/update-user`, values, {
                headers:
                    { authorization: Token }
            });
            if (updateresponse.status === 200) {
                console.log(updateresponse?.data);
                toastsuccess(updateresponse?.data?.message);
                navigate('/users-dashboard');
            }
        } catch (error) {
            console.error(error?.updateresponse?.data?.message);
            toastsuccess(error?.updateresponse?.data?.message);
        }
        resetForm();
    }
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid-fluid pl-30'>
                        <p className='bar1-head'>Modifier un utilisateur</p>

                        <div className='row '>
                            <div className='col-sm-5 input-box1'>
                                <p className='bar1-para'>Nom*</p>
                                <input type="text"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.name && errors.name ? 'error' : 'form-control1 text-initial w-100 m-0'} placeholder='Nom'></input>
                                {touched.name && errors.name && <div className="error-message">{errors.name}</div>}
                            </div>
                            <div className='col-sm-2 input-box1'>
                                <p className='bar1-para'>Prénom</p>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.firstName && errors.firstName ? 'error' : ' w-100 form-controlz'} placeholder='Prénom' ></input>
                            </div>
                            {installerorobligelocal === 'Super Manager' ? (<> <div className='col-sm-5 input-box1'>
                                <p className='bar1-para'>Sa fonction*</p>
                                <div className='d-flex'>
                                    <select
                                        name='role'
                                        id='role'
                                        value={values.role}
                                        onChange={handleChange}
                                        className={touched.role && errors.role ? 'error' : 'form-select ps-3 btn-buttons'} aria-label="Role">
                                        <option defaultValue >-- Choisir un type --</option>
                                        <option value="Agent administratif" >Agent administratif</option>
                                        <option value="Agent bureau de contrôle">Agent bureau de contrôle</option>
                                        <option value="Agent technique">Agent technique</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Super Manager">Super Manager</option>
                                        <option value="Technicien">Technicien</option>
                                        <option value="Installer">Installer</option>
                                        <option value="Oblige">Oblige</option>
                                    </select>
                                    {touched.role && errors.role && <div className="error-message">{errors.role}</div>}
                                </div>
                            </div></>) : null}
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-6 input-box1'>
                                <p className='bar1-para'>Adresse</p>
                                <input
                                    type="text"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.address && errors.address ? 'error' : 'form-control1 w-100 m-0'} ></input>
                            </div>
                            <div className='col-sm-2 input-box1'>
                                <p className='bar1-para'>CP</p>
                                <input type="number"
                                    name="cp"
                                    value={values.cp}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.cp && errors.cp ? 'error' : 'form-control1 w-100 m-0'} ></input>
                            </div>
                            <div className='col-sm-4 input-box1'>
                                <p className='bar1-para'>Ville</p>
                                <input type="text"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.city && errors.city ? 'error' : 'form-control1 w-100 m-0'} ></input>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-6 input-box1'>
                                <p className='bar1-para'>Email</p>
                                <input
                                    type="email"
                                    name="emailId"
                                    value={values.emailId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.emailId && errors.emailId ? 'error' : 'form-control1 w-100 m-0'} />
                                {touched.emailId && errors.emailId && <div className="error-message">{errors.emailId}</div>}
                            </div>
                            <div className='col-sm-2 input-box1'>
                                <p className='bar1-para'>N fixe</p>
                                <input
                                   type="number"
                                    name="N_fixed"
                                    value={values.N_fixed}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.N_fixed && errors.N_fixed ? 'error' : 'form-control1 w-100 m-0'} />
                            </div>
                            <div className='col-sm-4 input-box1'>
                                <p className='bar1-para'>N mobile</p>
                                <input
                                    type="text"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.phone && errors.phone ? 'error' : 'form-control1 w-100 m-0'} />
                            </div>
                        </div>
                        {/* <div className='row mt-3'>
                <div className='col-sm-6 input-box1'>
                    <p className='bar1-para '>Changer le mot de passe</p>
                    <input type="text" className="form-control1 text-initial w-100 m-0" placeholder='Nouveau mot de passe'></input>
                </div>

                <div className='col-sm-6 input-box1'>

                    <input type="text" className=" w-100 form-controlz" placeholder='Confirmer le nouveau mot de passe' ></input>
                </div>
            </div> */}
                        <div className='mt-4 mb-3  text-start'>
                            <button type='submit' className='btns-color ' >Continuer</button>
                        </div>
                    </div>
                </form>
            )}
        </Formik >
    )
}

export default Edituser