import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs";
function Bar161() {
  const navigate = useNavigate();
  const handlePrev = () => {
    navigate(-1);
  };
  return (
    <div className='container-fluid py-3 px-4'>
      <div className='row'>
        <p className='bar1-para '>BAR-TH-161 : Isolation points singuliers – Résidentiel<span onClick={handlePrev}  className='border-0 bg-white pe-auto'><BsArrowLeft className='left-arow pe-auto' /></span></p>
        <div className='button-1 mt-1'>
          {/* <button type='button' className='btn-on' data-bs-dismiss="modal" aria-label='close'>Calculez le montant </button> */}
          <Link to={"/calcult161"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
        </div>
        <div className='col bar1-tittle'>
          <div className='bar1-tittle'>
            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
              <h3 className='bar1-head'>1. Secteur d’application</h3>
              <p className='bar1-para'>Bâtiments résidentiels existants.</p>
              <p className='bar1-para'>Cette opération ne s’applique pas à l’isolation des points singuliers d’une sous-station d’un réseau de chaleur ou d’une chaufferie dès lors qu’elle réduit les émissions de gaz à effet de serre d’une installation classée visée à l’article L 229-5 du code de l’environnement exploitée par le bénéficiaire.</p>
              <p className='bar1-para'>Cette opération n’est pas cumulable avec les opérations relevant de la fiche RES-CH-104 « Réhabilitation d’un poste de livraison de chaleur d’un bâtiment résidentiel ».</p>
              <h3 className='bar1-head'>2. Dénomination</h3>
              <p className='bar1-para'>Mise en place de housses pour l’isolation de points singuliers sur un réseau hydraulique isolé de chauffage et/ou d’eau chaude sanitaire, situé dans une sous-station ou dans une chaufferie pour un système collectif.</p>
              <p className='bar1-para'>Une housse isolante est constituée d’une enveloppe souple garnie d’une âme isolante qui est maintenue en place par un système de fermeture intégré à la housse (sangles, bandes auto-agrippantes, crochets…) afin d’isoler complètement le ou les points singuliers. Les manchons isolants ne sont pas éligibles.</p>
              <p className='bar1-para'>Une chaufferie est un local abritant des appareils de production de chaleur par combustion.
                Une sous-station est un local abritant les appareils qui assurent, soit par mélange, soit par échange, le transfert de chaleur d’un réseau de distribution dit réseau primaire à un réseau d’utilisation dit réseau secondaire.</p>
              <h3 className='bar1-head '>3. Conditions pour la délivrance de certificats</h3>
              <p className='bar1-para '>La mise en place est réalisée par un professionnel.<br></br>            </p>
              <p className='bar1-para'>Un point singulier est une pièce de type vanne, réducteur, robinet, clapet, filtre, séparateur, compteur, détendeur, manchette, purgeur, pompe. Pour l’application de cette fiche, un échangeur à plaques est considéré comme un point singulier. Une pièce et son jeu de bride sont comptabilisés comme un seul point singulier. Un jeu de bride permettant le raccordement de deux réseaux est comptabilisé comme un seul point singulier. Un arrêt de tuyauterie équipé d’une bride est comptabilisé comme un seul point singulier. Sont exclus les coudes, soudures et tuyauteries ainsi que tous les points singuliers sur un circuit de condensats ouverts.</p>
              <p className='bar1-para '>Un même point singulier ne peut pas faire l’objet d’une demande de certificats d’économies d’énergie pour cette opération plus d’une fois durant la durée de vie conventionnelle mentionnée au 4.<br></br>  </p>
              <p className='bar1-para'>La housse est souple, démontable et équipée d’un système de fermeture.</p>
              <p className='bar1-para'>La housse est constituée d’un isolant à base de laine minérale et répond aux exigences de la normes NF EN 14303 définissant les spécifications des produits isolants thermiques pour l’équipement du bâtiment et les installations industrielles pour les produits manufacturées à base de laines minérales. Sa température maximale de service est supérieure à 200°C.</p>
              <p className='bar1-para'>La résistance thermique de l’isolant (rapport entre l’épaisseur et la conductivité thermique déclarées) est supérieure ou égale à :</p>
              <ul className='ul-bar1'>
                <li className='bar1-para'>1,5 m2.K/W à une température moyenne de 50°C,</li>
                <li className='bar1-para'>1,0 m2.K/W à une température moyenne de 100°C.</li>
              </ul>
              <p className='bar1-para'>La conductivité thermique et l’épaisseur déclarées de l’âme isolante ainsi que la température maximale de service sont mesurées dans les conditions définies par la norme NF EN 14303.</p>
              <p className='bar1-para'>La preuve de la réalisation de l’opération mentionne la mise en place de housses souples, démontables et équipées d’un système de fermeture pour l’isolation de points singuliers en chaufferie ou en sous-station, le nombre de housses installées selon la température correspondant au fluide utilisé, en distinguant ceux destinés à l’isolation d’un échangeur à plaques, leur résistance thermique à la température exigée ainsi que le diamètre nominal des points singuliers isolés. La preuve de réalisation de l’opération précise la marque et le modèle de la housse isolante ainsi que la nature de l’isolant constitutif et sa température maximale de service.</p>
              <p className='bar1-para'>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’équipements d’isolation de points singuliers en chaufferie ou en sous-station avec leurs marques et références, le nombre d’équipements installés selon la température correspondant au fluide utilisé en distinguant ceux destinés à l’isolation d’un échangeur à plaques et indique le diamètre nominal des points singuliers isolés. Elle est complétée par un document issu du fabricant indiquant que les équipements de marques et références installés sont des housses souples, démontables
                et équipées d’un système de fermeture pour l’isolation de points singuliers. Ce document précise la résistance thermique de l’isolant à la température exigée (ou à défaut sa conductivité thermique et son épaisseur déclarées), la nature de l’isolant constitutif et sa température maximale de service. Il précise les références des normes utilisées pour déterminer les différentes caractéristiques de l’isolant.</p>
              <p className='bar1-para'>Un état récapitulatif des housses isolantes mises en place et des points singuliers isolés est établi par le professionnel à l’issue des travaux.</p>
              <p className='bar1-para'>Cet état récapitulatif est daté et signé par le professionnel et le bénéficiaire de l’opération.</p>
              <p className='bar1-para '>Il comporte :<br></br>
                <span >– le lieu d’implantation des matelas en chaufferie ou sous-station ; </span><br></br>
                <span >– les marques, références ou numéros de repérage internes des points singuliers isolés par les housses ainsi que le diamètre nominal des canalisations auxquelles sont raccordés les points singuliers ;</span><br></br>
                <span >– les marques et références des housses installées, la résistance thermique de l’âme isolante à la température exigée, la température maximale de service de leur âme isolante et, le cas échéant, les numéros de repérage</span><br></br>
                <span >– la température du fluide caloporteur.</span><br></br>
              </p>
               <p className='bar1-para'>Les travaux d’isolation des points singuliers font l’objet, après réalisation, d’un contrôle sur site par un organisme d’inspection. Un rapport de contrôle, établi par cet organisme, atteste :<br></br>
                <span >– de la mise en place de housses isolantes sur des points singuliers d’un réseau d’une sous-station ou d’une chaufferie, le nombre de housse mises en place (housses souples, démontables et équipées d’un système de fermeture) et le diamètre nominal des canalisations auxquelles sont raccordés les points singuliers ; </span><br></br>
                <span >– des marques et références et, le cas échéant, des numéros de repérage internes des housses installées ; </span><br></br>
                <span >– du récolement avec l’état récapitulatif établi, signé et daté par le professionnel à l’issue des travaux et des différences constatées. </span><br></br>
              </p> <p className='bar1-para'>L’organisme d’inspection procède à la vérification aléatoire d’au moins 10 % des points singuliers isolés (nombre arrondi à l’unité supérieure) par démontage des housses puis remise en place (type de point singulier, diamètre des canalisations, température du fluide caloporteur, marques et références des housses, nature de l’isolant, résistance thermique de l’âme isolante à la température exigée, température maximale de service de l’âme isolante), complétée au besoin par un examen documentaire. </p>
              <p className='bar1-para'>Cette vérification ne doit révéler aucun écart avec l’état récapitulatif établi, signé et daté par le professionnel à l’issue des travaux. </p>
              <p className='bar1-para'>Le rapport mentionne la date de la visite sur site de l’organisme et identifie l’opération réalisée par la référence de la preuve de réalisation de l’opération, la raison sociale et le numéro SIREN du professionnel, l’identité du bénéficiaire et le lieu de réalisation de l’opération.</p>
              <p className='bar1-para'>L’organisme d’inspection est accrédité selon la norme NF EN ISO/CEI 17020 ou toute version ultérieure, en tant qu’organisme d’inspection de type A pour le domaine 15.1.5 « Inspection d’opérations standardisées d’économies d’énergie dans le cadre du dispositif de délivrance des certificats d’économies d’énergie » par le Comité français d’accréditation (COFRAC) ou tout autre organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris dans le cadre de European co-operation for Accreditation (EA), coordination européenne des organismes d’accréditation.</p>
              <p className='bar1-para'>Les documents justificatifs spécifiques à l’opération sont l’état récapitulatif établi, signé et daté par le professionnel et le bénéficiaire à l’issue des travaux, le rapport de contrôle établi par l’organisme d’inspection à l’issue des travaux et la justification de l’accréditation de l’organisme d’inspection.</p>
              <p className='bar1-head  '>4. Durée de vie conventionnelle</p>
              <p className='bar1-para m-0'>10 ans pour une température du fluide comprise entre 50°C et 120°C inclus.</p>
              <p className='bar1-para '>5 ans pour une température du fluide supérieure à 120°C.</p>
              <p className='bar1-head  '>5. Montant de certificats en kWh cumac</p>
              <h3 className="ul-bar1 bar1-ul text-start ">Pour un point singulier hors échangeur à plaque :</h3>

              <div className="row">
                <div className="col-lg-5">
                  <table className='table table-bordered table-border '>
                    <tr className='bar1-para text-center p-0'>
                      <th className='border-thin p-0' >Diametre norminal(DN) dc la canalisation(mm)</th>
                      <th className='border-thin p-0' >Zone climatique</th>
                      <th className='border-thin p-0'>Montant en kWhcumac par housse isolanic mise en place <br /> 50&#8451; &le; T<sub>fluide</sub>&le; 120&#8451;</th>
                      <th className='p-0'>Montant en kWhcumac par housse isolanic mise en place T<sub>fluide</sub>&gt; 120&#8451; </th>
                    </tr>
                    <tr className=' bar1-para text-center p-0'>
                      <th className='border-thin p-0' rowspan="4">20 &le; DN &le; 65 </th></tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H1</td>
                      <td className='border-thin p-0'>11 700</td>
                      <td className='p-0'>12 900</td></tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H2</td>
                      <td className='border-thin p-0'> 10 500</td>
                      <td className='p-0'>11 600</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0'>H3</td>
                      <td className='border-thin p-0'>8 800</td>
                      <td className='p-0'> 9 700</td>
                    </tr>
                    <tr className=' bar1-para text-center p-0'>
                      <th className='border-thin p-0' rowspan="4">65 &le; DN &le; 100</th></tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H1</td>
                      <td className='border-thin p-0'>25 100</td>
                      <td className='p-0'>27 800</td></tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H2</td>
                      <td className='border-thin p-0'> 22 700</td>
                      <td className='p-0'>25 100</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0'>H3</td>
                      <td className='border-thin p-0'>18 900</td>
                      <td className='p-0'>20 900</td>
                    </tr>
                    <tr className=' bar1-para text-center p-0'>
                      <th className='border-thin p-0' rowspan="4">100 &lt; DN </th></tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0'>H1</td>
                      <td className='border-thin p-0'>40 900</td>
                      <td className='p-0'>45 400</td></tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H2</td>
                      <td className='border-thin p-0'> 37 000</td>
                      <td className='p-0'>41 000</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0'>H3</td>
                      <td className='border-thin p-0'>30 800</td>
                      <td className='p-0'>34 100</td>
                    </tr>
                  </table>
                </div>
                <div className="col-lg-1 ">
                  <h1 className='postion-tops pt-5'>*</h1>
                </div>
                <div className="col-lg-2">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th colSpan="3">Nombre d'echangcurs a plaqucs isoles</th></tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center h-100'><td className='pad-140 text-center'>N</td></tr></tbody>
                  </table>
                </div>
              </div>
              <h3 className="ul-bar1 bar1-ul text-start mt-4">Pour un échangeur à plaques :</h3>
              <div className="row">
                <div className="col-6">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th >Zone climatique</th>
                        <th >Montant en kWh cumac par echangcur  isolé <br /> 50&#8451; &le; T<sub>fluide</sub>&le; 120&#8451;</th>
                        <th >Montant en kWh cumac par echangcur  isolé <br /> T<sub>fluide</sub> &gt; 120&#8451;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center'>
                        <td>H1</td>
                        <td>77 200</td>
                        <td>88 000</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>H2</td>
                        <td>73 500</td>
                        <td>83 900</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>H3</td>
                        <td>66 900</td>
                        <td>76 300</td>
                      </tr>
                       </tbody>
                  </table>
                </div>
                <div className="col-lg-1 ">
                  <h1 className='postion-tops '>*</h1>
                </div>
                <div className="col-lg-2">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th colSpan="3">Nombre d'echangcur a plaqucs isolés</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center h-100'> <td className='pad-50 text-center'>N</td></tr></tbody>
                  </table>
                </div>
              </div>
              <h3 className="ul-bar1 text-start mt-4">Tfluide <span className='bar1-ul '>est la température du fluide caloporteur.</span></h3>
              <div className='bar1-content mt-4 pb-4'>
                 <p className='bar1-para para-bar1 m-0'><span className='bar1-head '>Eligible :</span> mise en place de housses pour l’isolation de points singuliers sur un réseau hydraulique isolé de chauffage et/ou ECS, situé dans une sous-station ou dans une chaufferie du secteur RESIDENTIEL. </p>
                <ul className='ul-bar1'>
                  <li className='bar1-para'><span className='bar1-head '>Un point singulier</span> est une pièce de type vanne, réducteur, robinet, clapet, filtre, séparateur, compteur, détendeur, manchette, purgeur, pompe ou un échangeur à plaques.</li>
                  <li className='bar1-para'> <span className='bar1-head '>Une housse isolante</span> une enveloppe souple et démontable garnie d’une âme isolante en laine minérale maintenue en place par un système de fermeture intégré à la housse.</li>
                  <li className='bar1-para'><span className='bar1-head '>Une chaufferie</span> est un local abritant des appareils de production de chaleur par combustion.</li>
                  <li className='bar1-para'><span className='bar1-head '>Une sous-station</span> est un local abritant les appareils qui assurent, soit par mélange, soit par échange, le transfert de chaleur d'un réseau de distribution entre réseau primaire et un réseau secondaire.</li>
                </ul>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Sont exclus :</span> Les manchons isolants, un site bénéficiant de la fiche RES-CH-104, un site classé visé à l’article L 229-5 (réduction des émissions de gaz à effet de serre), les coudes, les soudures et tuyauteries ainsi que les circuits de condensats ouverts. </p>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Vous ignorez une réponse :</span> cette simulation vous donne un premier montant. Après opération, les données précises du rapport d’inspection post-travaux COFRAC seront retenus et la prime sera automatiquement réévaluée. Cliquez sur ❶ pour obtenir plus de détail sur une question</p>

              </div>
              {/* <div className='button-1'>
                <button type='button' className='btn-on' >Site du Ministère <TbMathGreater /> </button>
              </div>
              <div className='button-1 mb-3'>
                <button type='button' className='btn-on' ><AiOutlineDownload />Télécharger le fiche </button>
              </div> */}
            </div>
          </div>
          </div>
      </div>
    </div>
)
}

export default Bar161;