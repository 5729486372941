import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
function Calcultse104() {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    // ----------------------------------------------
    useEffect(() => {
        // Retrieve stored form values from local storage
        const storedFormValues = sessionStorage.getItem('step-6');

        if (storedFormValues) {
            // Parse the stored form values and set them as initial values
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.beneficiaryOperation = parsedFormValues.beneficiaryOperation;
            initialValues.residentialBuilding = parsedFormValues.residentialBuilding;
            initialValues.codepostal = parsedFormValues.codepostal;
            initialValues.noofApartment = parsedFormValues.noofApartment;
            initialValues.premiumamount = parsedFormValues.premiumamount;
            initialValues.departmental_precariousness = parsedFormValues.departmental_precariousness;
            initialValues.total_homes_affectedv1 = parsedFormValues.total_homes_affectedv1;
            initialValues.approved_housing = parsedFormValues.approved_housing;
            initialValues.total_homes_affectedv2 = parsedFormValues.total_homes_affectedv2;
            initialValues.empty_households = parsedFormValues.empty_households;
            initialValues.classified_precarious = parsedFormValues.classified_precarious;
            initialValues.highly_precarious = parsedFormValues.highly_precarious;
            initialValues.without_proof = parsedFormValues.without_proof;
            initialValues.district_city_policy = parsedFormValues.district_city_policy;
        }
    }, []);
    const initialValues = {
        beneficiaryOperation: '',
        residentialBuilding: '',
        codepostal: '',
        noofApartment: '',
        premiumamount: '',
        departmental_precariousness: '',
        total_homes_affectedv1: '',
        approved_housing: '',
        total_homes_affectedv2: '',
        empty_households: '',
        classified_precarious: '',
        highly_precarious: '',
        without_proof: '',
        district_city_policy: '',
    };
    const validate = (values) => {
        const errors = {};

        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.noofApartment) {
            errors.noofApartment = "Nombre d’appartements équilibrés requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/estimation-page");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-6');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid px-4 py-3'>
                        <div className='row'>
                            <div className='col'>
                                <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Isolation des combles ou de toiture :</p>
                                <div className='bar1-content mt-4 pb-4'>
                                    <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                    <div className=''>
                                        <label className=' para-bar1'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="beneficiaryOperation"
                                                id="beneficiaryOperation"
                                                value="Bailleur Social"
                                                onChange={handleChange}
                                                checked={values.beneficiaryOperation === "Bailleur Social"} />
                                            <span className='bar1-para span-bar'>
                                                Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                    Copropriété privée, Syndic </span>et autres cas</span>
                                        </label>
                                        <label className=' para-bar1 mt-1'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="beneficiaryOperation"
                                                id="beneficiaryOperation"
                                                value="Copropriété privée"
                                                onChange={handleChange}
                                                checked={values.beneficiaryOperation === "Copropriété privée"} />
                                            <span className='span-bar bar1-para'>
                                                <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                            </span>
                                        </label>
                                        <div className='button-1 para-bar1'>
                                            <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Bailleur Social */}
                                {values.beneficiaryOperation === 'Bailleur Social' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <div className='p-4'>
                                            <label className=' para-bar1'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="departmental_precariousness"
                                                    id="departmental_precariousness"
                                                    value="précarité départementale"
                                                    onChange={handleChange}
                                                    checked={values.departmental_precariousness === "précarité départementale"} />
                                                <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                            </label>
                                            <label className=' para-bar1 mt-1'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="departmental_precariousness"
                                                    id="departmental_precariousness"
                                                    value="de précarité réel"
                                                    onChange={handleChange}
                                                    checked={values.departmental_precariousness === "de précarité réel"} />
                                                <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                )}

                                {/* Depatmental */}
                                {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <div className='p-3'>
                                            <label className=' para-bar1 d-block'>
                                                <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                <input
                                                    type='number'
                                                    name="total_homes_affectedv1"
                                                    id="total_homes_affectedv1"
                                                    value={values.total_homes_affectedv1}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                <input
                                                    type='number'
                                                    name="approved_housing"
                                                    id="approved_housing"
                                                    value={values.approved_housing}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                <input
                                                    type='number'
                                                    name="empty_households"
                                                    id="empty_households"
                                                    value={values.empty_households}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                        </div>
                                    </div>
                                )}
                                {/* reel */}
                                {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <div className='p-3'>
                                            <label className=' para-bar1 d-block'>
                                                <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                <input
                                                    type='total_homes_affectedv2'
                                                    name="total_homes_affectedv2"
                                                    id="total_homes_affectedv2"
                                                    value={values.total_homes_affectedv2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1"></input>
                                            </label>
                                            <label className='para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                <input
                                                    type='number'
                                                    name="classified_precarious"
                                                    id="classified_precarious"
                                                    value={values.classified_precarious}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                <input
                                                    type='number'
                                                    name="highly_precarious"
                                                    id="highly_precarious"
                                                    value={values.highly_precarious}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                <input
                                                    type='number'
                                                    name="without_proof"
                                                    id="without_proof"
                                                    value={values.without_proof}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                        </div>
                                    </div>
                                )}

                                {/* Copriete */}
                                {values.beneficiaryOperation === 'Copropriété privée' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                        <div className=''>
                                            <label className=' para-bar1 d-block'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="district_city_policy"
                                                    id="district_city_policy"
                                                    value="Oui"
                                                    onChange={handleChange}
                                                    checked={values.district_city_policy === "Oui"} />
                                                <span className='bar1-para span-bar'>Oui</span>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="district_city_policy"
                                                    id="district_city_policy"
                                                    value="Non"
                                                    onChange={handleChange}
                                                    checked={values.district_city_policy === "Non"} />
                                                <span className='span-bar bar1-para'>Non</span>
                                            </label>
                                        </div>
                                    </div>
                                )}
                                <p className="bar1-head  mt-4">Bâtiment résidentiel existant depuis plus de 2 ans ?</p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input" type="radio"
                                            name="residentialBuilding"
                                            id="residentialBuilding"
                                            value="Oui"
                                            onChange={handleChange}
                                            checked={values.residentialBuilding === "Oui"} />
                                        <span className='span-bar bar1-para'>
                                            Oui
                                        </span>
                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="residentialBuilding"
                                            id="residentialBuilding"
                                            value="Non"
                                            onChange={handleChange}
                                            checked={values.residentialBuilding === "Non"} />
                                        <span className='span-bar bar1-para '>
                                            Non
                                        </span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>

                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-sm-12">
                                        <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <input
                                            type="number"
                                            name='codepostal'
                                            id='codepostal'
                                            value={values.codepostal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-sm-12">
                                        <p className='bar1-head mt-3'>Nombre d’appartements équilibrés* :</p>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <input
                                            type="number"
                                            name='noofApartment'
                                            id='noofApartment'
                                            value={values.noofApartment}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.noofApartment && errors.noofApartment ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.noofApartment && errors.noofApartment && <div className="error-message">{errors.noofApartment}</div>}
                                    </div>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div>
                                <hr></hr>
                                <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                <div className='text-start'>
                                    <label >
                                        <input
                                            type="number"
                                            name='premiumamount'
                                            id='premiumamount'
                                            value={values.premiumamount}
                                            onChange={handleChange}
                                            onBlur={handleBlur} className="input-box" placeholder='3' ></input>
                                        <span className='span-bar bar1-para'>Euros du Méga / Prix du kilowater </span>
                                    </label>

                                </div><hr></hr>

                                <div className='row mt-5 mb-2'>
                                    <div className='col text-start'>
                                        <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                    </div>
                                    <div className="col text-end">
                                        <button type='submit' className='btns-color'>Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )
            }
        </Formik >
    )
}

export default Calcultse104;