import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Formik } from 'formik';
import axios from 'axios';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
function Control() {
    const [obligeStore, setObligeStore] = useState(null);
    const [installerStore, setInstallerStore] = useState(null);
    const navigate = useNavigate();
    const [storedData, setStoredData] = useState(null);
    // ----------------------------------------------
    useEffect(() => {
        // Retrieve stored form values from local storage
        const storedFormValues = sessionStorage.getItem('step-8');

        if (storedFormValues) {
            // Parse the stored form values and set them as initial values
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.contributionFrameDate = parsedFormValues.contributionFrameDate;
            initialValues.quoteEditiondate = parsedFormValues.quoteEditiondate;
            initialValues.estimateDate = parsedFormValues.estimateDate;
            initialValues.quoteSignatureDate = parsedFormValues.quoteSignatureDate;
            initialValues.startDateOfWork = parsedFormValues.startDateOfWork;
            initialValues.billDate = parsedFormValues.billDate;
            initialValues.dateControlOfficeVisit = parsedFormValues.dateControlOfficeVisit;
            initialValues.beneficiarySignatureDateOnAH = parsedFormValues.beneficiarySignatureDateOnAH;
            initialValues.installerSignatureDateonAH = parsedFormValues.installerSignatureDateonAH;
            // initialValues.beneficiarySignatureDateonsh = parsedFormValues.beneficiarySignatureDateonsh;
        }
    }, []);
    // useFormik
    const initialValues = {
        contributionFrameDate: '',
        quoteEditiondate: '',
        estimateDate: '',
        quoteSignatureDate: '',
        startDateOfWork: '',
        billDate: '',
        dateControlOfficeVisit: '',
        beneficiarySignatureDateOnAH: '',
        installerSignatureDateonAH: '',
        // beneficiarySignatureDateonsh: '',
    }
    const validate = (values) => {
        console.log('values', values);
        const errors = {};

        return errors;
    };
    const handleSubmit = (values) => {
        console.log("Estimation Page Values", values);
        sessionStorage.setItem("step-8", JSON.stringify(values));
        navigate("/valider-geoportail");
        window.open('https://www.geoportail.gouv.fr/', '_blank');
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-8');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);

    // const disableDates = (contributionFrameDate) => {
    //     if (!contributionFrameDate) return null;
    //     const [day, month, year] = contributionFrameDate.split('.');
    //     const contributionDate = new Date(`${year}-${month}-${day}`);
    //     contributionDate.setDate(contributionDate.getDate() + 1);
    //     return contributionDate;
    // };

    // const disableDates = (vals) => {
    //     const contributionDate = new Date(vals);
    //     contributionDate.setDate(contributionDate.getDate());

    //     const yyyy = contributionDate.getFullYear();
    //     let mm = contributionDate.getMonth() + 1;
    //     let dd = contributionDate.getDate();

    //     if (mm < 10) {
    //         mm = '0' + mm;
    //     }
    //     if (dd < 10) {
    //         dd = '0' + dd;
    //     }

    //     return `${yyyy}-${mm}-${dd}`;
    // };
    useEffect(() => {
        const allestimate = JSON.parse(sessionStorage.getItem('step-2'));
        const Token = localStorage.getItem('Token');

        const fetchData = async () => {
            const option = {
                headers: { authorization: Token }
            };

            try {
                const [installerResponse, obligeResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/get-installer/${allestimate.installer_id}`, option),
                    axios.get(`${process.env.REACT_APP_API_URL}/get-oblige/${allestimate.oblige_id}`, option)
                ]);

                setInstallerStore(installerResponse?.data);
                setObligeStore(obligeResponse?.data);
                console.log(installerResponse?.data, obligeResponse?.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);
    let contract_start;
    const separateDate = obligeStore?.oblige?.map((item) => {
        console.log(item?.partnership_contract_start_date);
        contract_start = item?.partnership_contract_start_date;
    })
    console.log('contract_start', contract_start);
    
    const disableDates = (contributionFrameDate) => {
        if (!contributionFrameDate) return null;

        // Parse the contributionFrameDate
        const [day, month, year] = contributionFrameDate.split('.');
        const contributionDate = new Date(`${year}-${month}-${day}`);

        // Allow selection from the contributionFrameDate onwards
        return contributionDate;
    };
    const disableDatesOnApi = (contract_start) => {
        if (!contract_start) return null;
        const [day, month, year] = contract_start.split('.');
        const contributionDate = new Date(`${year}-${month}-${day}`);
        return contributionDate;
    };

    // const disableDatesOnApi = (vals) => {
    //     const contributionDate = new Date(vals);
    //     contributionDate.setDate(contributionDate.getDate());

    //     const yyyy = contributionDate.getFullYear();
    //     let mm = contributionDate.getMonth() + 1;
    //     let dd = contributionDate.getDate();

    //     if (mm < 10) {
    //         mm = '0' + mm;
    //     }
    //     if (dd < 10) {
    //         dd = '0' + dd;
    //     }

    //     return `${yyyy}-${mm}-${dd}`;
    // };


    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid px-4 py-3'>
                        <div className="row">
                            <div className="col">
                                <p className='bar1-head'>Contrôle</p>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2">
                            <div className="col input-box1 mt-3">
                                <p className='bar1-para'>Date de cadre de contribution</p>
                                <DatePicker
                                    selected={values.contributionFrameDate ? new Date(values.contributionFrameDate) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                            const formattedDate = adjustedDate.toISOString().split('T')[0]; // Format: 'yyyy-MM-dd'
                                            setFieldValue('contributionFrameDate', formattedDate);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    name="contributionFrameDate"
                                    dateFormat="dd.MM.yyyy"
                                    className='f-left '
                                    placeholderText='dd/mm/yyyy'
                                />

                            </div>
                            <div className="col input-box1 mt-3">
                                <p className='bar1-para'>Date édition devis</p>
                                <DatePicker
                                    name="quoteEditiondate"
                                    selected={values.quoteEditiondate ? new Date(values.quoteEditiondate) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                            const formattedDate = adjustedDate.toISOString().split('T')[0];
                                            setFieldValue('quoteEditiondate', formattedDate);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    dateFormat="dd.MM.yyyy"
                                    className='f-left'
                                    placeholderText='dd/mm/yyyy'
                                    minDate={disableDatesOnApi(contract_start)}
                                />
                                {/* <input type='date'
                                    name="quoteEditiondate"
                                    value={values.quoteEditiondate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.quoteEditiondate && errors.quoteEditiondate ? 'error' : 'f-left ps-3 '}
                                    min={disableDatesOnApi(contract_start)} />
                                {touched.quoteEditiondate && errors.quoteEditiondate && <div className="error-message">{errors.quoteEditiondate}</div>} */}
                            </div>

                            <div className="col input-box1 mt-3">
                                <p className='bar1-para'>Date de previsite </p>
                                <DatePicker
                                    name="estimateDate"
                                    selected={values.estimateDate ? new Date(values.estimateDate) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                            const formattedDate = adjustedDate.toISOString().split('T')[0];
                                            setFieldValue('estimateDate', formattedDate);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    dateFormat="dd.MM.yyyy"
                                    className='f-left'
                                    placeholderText='dd/mm/yyyy'
                                    minDate={disableDates(values.contributionFrameDate)}
                                />
                            </div>

                            <div className="col input-box1 mt-3">
                                <p className='bar1-para'>Date signature devis</p>
                                <DatePicker
                                    name="quoteSignatureDate"
                                    selected={values.quoteSignatureDate ? new Date(values.quoteSignatureDate) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                            const formattedDate = adjustedDate.toISOString().split('T')[0];
                                            setFieldValue('quoteSignatureDate', formattedDate);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    dateFormat="dd.MM.yyyy"
                                    className='f-left'
                                    placeholderText='dd/mm/yyyy'
                                    minDate={disableDates(values.contributionFrameDate)}
                                />

                            </div>
                            <div className="col input-box1 mt-3">
                                <p className='bar1-para'>Date debut des travaux</p>
                                <DatePicker
                                    name="startDateOfWork"
                                    selected={values.startDateOfWork ? new Date(values.startDateOfWork) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                            const formattedDate = adjustedDate.toISOString().split('T')[0];
                                            setFieldValue('startDateOfWork', formattedDate);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    dateFormat="dd.MM.yyyy"
                                    className='f-left'
                                    placeholderText='dd/mm/yyyy'
                                    minDate={disableDates(values.contributionFrameDate)}
                                />
                            </div>
                            <div className="col input-box1 mt-3">
                                <p className='bar1-para'>Date de facture</p>
                                <DatePicker
                                    name="billDate"
                                    selected={values.billDate ? new Date(values.billDate) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                            const formattedDate = adjustedDate.toISOString().split('T')[0];
                                            setFieldValue('billDate', formattedDate);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    dateFormat="dd.MM.yyyy"
                                    className='f-left'
                                    placeholderText='dd/mm/yyyy'
                                    minDate={disableDates(values.contributionFrameDate)}
                                />

                            </div>
                            <div className="col input-box1 mt-3">
                                <p className='bar1-para'>Date visite bureau de contrôle</p>
                                <DatePicker
                                    name="dateControlOfficeVisit"
                                    selected={values.dateControlOfficeVisit ? new Date(values.dateControlOfficeVisit) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                            const formattedDate = adjustedDate.toISOString().split('T')[0];
                                            setFieldValue('dateControlOfficeVisit', formattedDate);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    dateFormat="dd.MM.yyyy"
                                    className='f-left'
                                    placeholderText='dd/mm/yyyy'
                                    minDate={disableDates(values.contributionFrameDate)}
                                />

                            </div>

                            <div className="col input-box1 mt-3">
                                <p className='bar1-para'>Date signature du beneficiare sur AH</p>
                                <DatePicker
                                    name="beneficiarySignatureDateOnAH"
                                    selected={values.beneficiarySignatureDateOnAH ? new Date(values.beneficiarySignatureDateOnAH) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                            const formattedDate = adjustedDate.toISOString().split('T')[0];
                                            setFieldValue('beneficiarySignatureDateOnAH', formattedDate);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    dateFormat="dd.MM.yyyy"
                                    className='f-left'
                                    placeholderText='dd/mm/yyyy'
                                    minDate={disableDates(values.contributionFrameDate)}
                                />
                            </div>
                            <div className="col input-box1 mt-3">
                                <p className='bar1-para'>Date signature de l'installateur sur AH</p>
                                <DatePicker
                                    name="installerSignatureDateonAH"
                                    selected={values.installerSignatureDateonAH ? new Date(values.installerSignatureDateonAH) : null}
                                    onChange={(date) => {
                                        if (date) {
                                            const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                            const formattedDate = adjustedDate.toISOString().split('T')[0];
                                            setFieldValue('installerSignatureDateonAH', formattedDate);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    dateFormat="dd.MM.yyyy"
                                    className='f-left'
                                    placeholderText='dd/mm/yyyy'
                                    minDate={disableDates(values.contributionFrameDate)}
                                />

                            </div>
                        </div>
                        <div className='row mt-5 mb-2 d-flex align-items-center'>
                            <div className='col text-start'>
                                <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                            </div>

                            <div className='col mt-5 mb-2 text-end'>
                                <Link to='https://www.geoportail.gouv.fr/' target="_blank">
                                    <button type='submit' className='btns-color2 links' > Valider Geoportail</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default Control