import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"
// FORMIK
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
const DevisDFAB2C = (props) => {
    const { id } = useParams();
    const [shouldUseMultiUpdate, setShouldUseMultiUpdate] = useState(true);
    const Token = localStorage.getItem('Token');
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);
    const MultiUpdate = props.MultiUpdate;
    console.log('MultiUpdate', MultiUpdate);
    const [initialValues, setInitialValues] = useState(
        {
            id: id,
            estimate_installerContactDetails: false,
            estimate_subcontractorContactDetails: false,
            estimate_socialReason: '',
            estimate_tradeName: '',
            estimate_nSiret: '',
            estimate_address: '',
            estimate_cp: '',
            estimate_city: '',
            estimate_phone: '',
            estimate_siretInstaller: '',
            estimate_identityOftheBeneficiary: false,
            estimate_quoteIssueDate: false,
            estimate_mentionsRequiredByTheOperationSheet: false,
            estimate_beneficiarySiret: '',
            estimate_beneficiaryAddress: false,
            estimate_beneficiaryName: false,
            estimate_beneficiaryPhone: false,
            estimate_beneficiaryFirstName: false,
            estimate_beneficiaryEmail: false,
            estimate_beneficiaryFunction: false,
            estimate_placeOfWork: false,
            estimate_purchaseOrderNo: false,
            estimate_incentiveRoleCompliance: '',
            estimate_mentionOfTheIncentiveRole: false,
            estimate_quoteNo: '',
            estimate_goodForAgreement: false,
            estimate_handwrittenDate: false,
            estimate_surnameFirstName: false,
            estimate_brand: false,
            estimate_reference: false,
            estimate_presenceOfTheStandard: false,
            estimate_attendanceDateandSignatureOfTheCustomer: false,
            estimate_insulationClass: false,
            estimate_otherMandatoryMaterialMentions: false,
            estimate_amountOfBeneficiaryPremium: '',
            estimate_raiPremium: false,
            estimate_comments: '',
            Marque: '',
            Classedelisolant: '',
            materialmentions: '',
            // 
            bill_installerContactDetails: false,
            bill_subcontractorContactDetails: false,
            bill_socialReason: '',
            bill_tradeName: '',
            bill_nSiret: '',
            bill_address: '',
            bill_cp: '',
            bill_city: '',
            bill_phone: '',
            bill_siretInstaller: '',
            bill_identityOfTheBeneficiary: false,
            bill_invoiceIssueDate: false,
            bill_beneficiarySiret: '',
            bill_operationSheet: false,
            bill_beneficiaryAddress: false,
            bill_beneficiaryName: false,
            bill_beneficiaryFirstName: false,
            bill_beneficiaryPhone: false,
            bill_beneficiaryEmail: false,
            bill_beneficiaryFunction: false,
            bill_lieuDesTravaux: false,
            bill_invoiceNumber: false,
            bill_vatRate: false,
            bill_diametersAndThicknesses: false,
            bill_quoteNo: '',
            bill_comments: '',
            // 
            attestation_comments: '',
            synopsisComments: '',
            attestation_ahFormatCompliant: false,
            attestation_fFullCFrame: false,
            attestation_fullAframe: false,
            attestation_signatureAndStamp: false,
            attestation_completeBframe: false,
            attestation_completeQbvsFrame: false,
            attestation_beneficiarysSignatureandStamp: false,
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            id: id,
            estimate_installerContactDetails: Boolean(+MultiUpdate?.multisteptable1?.estimate_installer_contactdetails) || false,
            estimate_subcontractorContactDetails: Boolean(+MultiUpdate?.multisteptable1?.estimate_subcontractor_contactdetails) || false,
            estimate_socialReason: MultiUpdate?.multisteptable1?.estimate_socialreason || '',
            estimate_tradeName: MultiUpdate?.multisteptable1?.estimate_tradename || '',
            estimate_nSiret: MultiUpdate?.multisteptable1?.estimate_nsiret || '',
            estimate_address: MultiUpdate?.multisteptable1?.estimate_address || '',
            estimate_cp: MultiUpdate?.multisteptable1?.estimate_cp || '',
            estimate_city: MultiUpdate?.multisteptable1?.estimate_city || '',
            estimate_phone: MultiUpdate?.multisteptable1?.estimate_phone || '',
            estimate_siretInstaller: MultiUpdate?.multisteptable1?.estimate_siretinstaller || '',
            estimate_identityOftheBeneficiary: Boolean(+MultiUpdate?.multisteptable1?.estimate_identityofthe_beneficiary) || false,
            estimate_quoteIssueDate: Boolean(+MultiUpdate?.multisteptable1?.estimate_quoteissuedate) || false,
            estimate_mentionsRequiredByTheOperationSheet: Boolean(+MultiUpdate?.multisteptable1?.estimate_mentionsrequiredby_theoperationsheet) || false,
            estimate_beneficiarySiret: MultiUpdate?.multisteptable1?.estimate_beneficiarysiret || '',
            estimate_beneficiaryAddress: Boolean(+MultiUpdate?.multisteptable1?.estimate_beneficiaryaddress) || false,
            estimate_beneficiaryName: Boolean(+MultiUpdate?.multisteptable1?.estimate_beneficiaryname) || false,
            estimate_beneficiaryPhone: Boolean(+MultiUpdate?.multisteptable1?.estimate_beneficiaryphone) || false,
            estimate_beneficiaryFirstName: Boolean(+MultiUpdate?.multisteptable1?.estimate_beneficiaryfirstname) || false,
            estimate_beneficiaryEmail: Boolean(+MultiUpdate?.multisteptable1?.estimate_beneficiaryemail) || false,
            estimate_beneficiaryFunction: Boolean(+MultiUpdate?.multisteptable1?.estimate_beneficiaryfunction) || false,
            estimate_placeOfWork: Boolean(+MultiUpdate?.multisteptable1?.estimate_placeofwork) || false,
            estimate_purchaseOrderNo: Boolean(+MultiUpdate?.multisteptable1?.estimate_purchaseorderno) || false,
            estimate_incentiveRoleCompliance: MultiUpdate?.multisteptable1?.estimate_incentiverole_compliance || '',
            estimate_mentionOfTheIncentiveRole: Boolean(+MultiUpdate?.multisteptable1?.estimate_mentionofthe_incentiverole) || false,
            estimate_quoteNo: MultiUpdate?.multisteptable1?.estimate_quoteno || '',
            estimate_goodForAgreement: Boolean(+MultiUpdate?.multisteptable1?.estimate_goodforagreement) || false,
            estimate_handwrittenDate: Boolean(+MultiUpdate?.multisteptable1?.estimate_handwrittendate) || false,
            estimate_surnameFirstName: Boolean(+MultiUpdate?.multisteptable1?.estimate_surnamefirstname) || false,
            estimate_brand: Boolean(+MultiUpdate?.multisteptable1?.estimate_brand) || false,
            estimate_reference: Boolean(+MultiUpdate?.multisteptable1?.estimate_reference) || false,
            estimate_presenceOfTheStandard: Boolean(+MultiUpdate?.multisteptable1?.estimate_presenceofthestandard) || false,
            estimate_attendanceDateandSignatureOfTheCustomer: Boolean(+MultiUpdate?.multisteptable1?.estimate_attendancedate_and_signatureofthecustomer) || false,
            estimate_insulationClass: Boolean(+MultiUpdate?.multisteptable1?.estimate_insulationclass) || false,
            estimate_otherMandatoryMaterialMentions: Boolean(+MultiUpdate?.multisteptable1?.estimate_othermandatory_materialmentions) || false,
            estimate_amountOfBeneficiaryPremium: MultiUpdate?.multisteptable1?.estimate_amountofbeneficiarypremium || '',
            estimate_raiPremium: Boolean(+MultiUpdate?.multisteptable1?.estimate_raipremium) || false,
            estimate_comments: MultiUpdate?.multisteptable1?.estimate_comments || '',
            Marque: MultiUpdate?.multisteptable1?.estimate_marque || '',
            Classedelisolant: MultiUpdate?.multisteptable1?.estimate_classedelisolant || '',
            materialmentions: MultiUpdate?.multisteptable1?.estimate_materialmentions || '',
            // 
            bill_installerContactDetails: Boolean(+MultiUpdate?.multisteptable1?.bill_installercontact_details) || false,
            bill_subcontractorContactDetails: Boolean(+MultiUpdate?.multisteptable1?.bill_subcontractor_contactdetails) || false,
            bill_socialReason: MultiUpdate?.multisteptable1?.bill_socialreason || '',
            bill_tradeName: MultiUpdate?.multisteptable1?.bill_tradename || '',
            bill_nSiret: MultiUpdate?.multisteptable1?.bill_nsiret || '',
            bill_address: MultiUpdate?.multisteptable1?.bill_address || '',
            bill_cp: MultiUpdate?.multisteptable1?.bill_cp || '',
            bill_city: MultiUpdate?.multisteptable1?.bill_city || '',
            bill_phone: MultiUpdate?.multisteptable1?.bill_beneficiaryphone || '',
            bill_siretInstaller: MultiUpdate?.multisteptable1?.bill_siretinstaller || '',
            bill_identityOfTheBeneficiary: Boolean(+MultiUpdate?.multisteptable1?.bill_identityofthe_beneficiary) || false,
            bill_invoiceIssueDate: Boolean(+MultiUpdate?.multisteptable1?.bill_invoiceissuedate) || false,
            bill_beneficiarySiret: MultiUpdate?.multisteptable1?.bill_beneficiarysiret || '',
            bill_operationSheet: Boolean(+MultiUpdate?.multisteptable1?.bill_operationsheet) || false,
            bill_beneficiaryAddress: Boolean(+MultiUpdate?.multisteptable1?.bill_beneficiaryaddress) || false,
            bill_beneficiaryName: Boolean(+MultiUpdate?.multisteptable1?.bill_beneficiaryname) || false,
            bill_beneficiaryFirstName: Boolean(+MultiUpdate?.multisteptable1?.bill_beneficiaryfirstname) || false,
            bill_beneficiaryPhone: Boolean(+MultiUpdate?.multisteptable1?.bill_beneficiaryphone) || false,
            bill_beneficiaryEmail: Boolean(+MultiUpdate?.multisteptable1?.bill_beneficiaryemail) || false,
            bill_beneficiaryFunction: Boolean(+MultiUpdate?.multisteptable1?.bill_beneficiaryfunction) || false,
            bill_lieuDesTravaux: Boolean(+MultiUpdate?.multisteptable1?.bill_lieudes_travaux) || false,
            bill_invoiceNumber: Boolean(+MultiUpdate?.multisteptable1?.bill_invoicenumber) || false,
            bill_vatRate: Boolean(+MultiUpdate?.multisteptable1?.bill_vatrate) || false,
            bill_diametersAndThicknesses: Boolean(+MultiUpdate?.multisteptable1?.bill_diameter_and_thicknesses) || false,
            bill_quoteNo: MultiUpdate?.multisteptable1?.bill_quoteno || '',
            bill_comments: MultiUpdate?.multisteptable1?.bill_comments || '',
            // 
            attestation_comments: MultiUpdate?.multisteptable1?.attestation_comments || '',
            synopsisComments: MultiUpdate?.multisteptable1?.synopsisComments || '',
            attestation_ahFormatCompliant: Boolean(+MultiUpdate?.multisteptable1?.attestation_ahformatcompliant) || false,
            attestation_fFullCFrame: Boolean(+MultiUpdate?.multisteptable1?.attestation_fFull_cFrame) || false,
            attestation_fullAframe: Boolean(+MultiUpdate?.multisteptable1?.attestation_full_aframe) || false,
            attestation_signatureAndStamp: Boolean(+MultiUpdate?.multisteptable1?.attestation_signature_and_stamp) || false,
            attestation_completeBframe: Boolean(+MultiUpdate?.multisteptable1?.attestation_complete_bframe) || false,
            attestation_completeQbvsFrame: Boolean(+MultiUpdate?.multisteptable1?.attestation_complete_qbvsframe) || false,
            attestation_beneficiarysSignatureandStamp: Boolean(+MultiUpdate?.multisteptable1?.attestation_beneficiarys_signatureandstamp) || false,
        }));
    }, [MultiUpdate]);

    console.log('MultiUpdate?.multisteptable1?.estimate_installer_contactdetails', Boolean(+MultiUpdate?.multisteptable1?.attestation_signature_and_stamp));
    // VALID
    const validate = (values) => {
        console.log("validate", values);
        const errors = {};

        return errors;
    }
    const handleSubmit = async (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-10", JSON.stringify(values));
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/update-multistep`, values,
                {
                    headers: { authorization: Token }
                }).then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        console.log('res.message', response?.data?.message);
                        toastsuccess(response?.data?.message);
                    }
                })
        } catch (error) {
            console.log(error.response?.data?.message);
            toasterror(error.response?.data?.message);
        }
    }
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid '>
                            <div className="row">
                                <div className="save_button_container d-flex justify-content-between align-items-center">
                                    <p className="bar1-head  mt-4">Devis </p> <button type='submit' className='btns-color'>Sauvegarder</button>
                                </div>
                                <div className="col text-start">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="estimate_installerContactDetails" name="estimate_installerContactDetails" checked={values.estimate_installerContactDetails} onChange={() => {
                                            setFieldValue('estimate_installerContactDetails', !values.estimate_installerContactDetails);
                                            setFieldValue('estimate_subcontractorContactDetails', false);
                                        }} />
                                        <label className="form-check-label bar1-para" htmlFor="estimate_installerContactDetails">Coordonnées Installateur</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input " type="checkbox" id="estimate_subcontractorContactDetails" name="estimate_subcontractorContactDetails" checked={values.estimate_subcontractorContactDetails} onChange={() => {
                                            setFieldValue('estimate_subcontractorContactDetails', !values.estimate_subcontractorContactDetails);
                                            setFieldValue('estimate_installerContactDetails', false);
                                        }} />
                                        <label className="form-check-label bar1-para" htmlFor="estimate_subcontractorContactDetails">Coordonnées de sous traitant</label>
                                    </div>
                                </div>
                                {values.estimate_subcontractorContactDetails === true && (
                                    <>
                                        <div className='row mt-3'>
                                            <div className='col-sm-5 input-box1'>
                                                <p className='bar1-para'>Raison sociale*</p>
                                                <input
                                                    type="text"
                                                    name="estimate_socialReason"
                                                    value={values.estimate_socialReason}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_socialReason && errors.estimate_socialReason ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.estimate_socialReason && errors.estimate_socialReason && <div className="error-message">{errors.estimate_socialReason}</div>}
                                            </div>
                                            <div className='col-sm-2 input-box1'>
                                                <p className='bar1-para'>Nom commercial</p>
                                                <input type="text"
                                                    name="estimate_tradeName"
                                                    value={values.estimate_tradeName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_tradeName && errors.estimate_tradeName ? 'error' : 'form-control1 w-100 m-false'} />
                                            </div>
                                            <div className='col-sm-5 input-box1'>
                                                <p className='bar1-para bar1-ul'>N Siren*</p>
                                                <input type="number"
                                                    name="estimate_nSiret"
                                                    value={values.estimate_nSiret}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_nSiret && errors.estimate_nSiret ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.estimate_nSiret && errors.estimate_nSiret && <div className="error-message">{errors.estimate_nSiret}</div>}
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-sm-6 input-box1'>
                                                <p className='bar1-para'>Adresse*</p>
                                                <input type="text"
                                                    name="estimate_address"
                                                    value={values.estimate_address}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_address && errors.estimate_address ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.estimate_address && errors.estimate_address && <div className="error-message">{errors.estimate_address}</div>}
                                            </div>
                                            <div className='col-sm-2 input-box1'>
                                                <p className='bar1-para'>CP*</p>
                                                <input type="number"
                                                    name="estimate_cp"
                                                    value={values.estimate_cp}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_cp && errors.estimate_cp ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.estimate_cp && errors.estimate_cp && <div className="error-message">{errors.estimate_cp}</div>}
                                            </div>
                                            <div className='col-sm-4 input-box1'>
                                                <p className='bar1-para'>Ville*</p>
                                                <input type="text"
                                                    name="estimate_city"
                                                    value={values.estimate_city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_city && errors.estimate_city ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.estimate_city && errors.estimate_city && <div className="error-message">{errors.estimate_city}</div>}
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-sm-3 input-box1'>
                                                <p className='bar1-para'>Telephone*</p>
                                                <input type="text"
                                                    name="estimate_phone"
                                                    value={values.estimate_phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_phone && errors.estimate_phone ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.estimate_phone && errors.estimate_phone && <div className="error-message">{errors.estimate_phone}</div>}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="row my-3">
                                <div className="col text-start">
                                    <div className='bar1-para d-flex '>
                                        SIRET INSTALLATEUR &nbsp; <a href='https://avis-situation-sirene.insee.fr/' target='_blank' className='bar1-ul'> (vérification si ok avec SIRET installateur dans fiche installateur)</a>
                                        <div className='d-flex flex-column ms-3'>
                                            <input type="text "
                                                name="estimate_siretInstaller"
                                                value={values.estimate_siretInstaller}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_siretInstaller && errors.estimate_siretInstaller ? 'error' : 'form-control1  m-false'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <div className='bar1-para   pt-2'>Télécharger le document
                                            <div className='d-flex align-items-center'>
                                                <input
                                                    type='file'
                                                    name="estimate_file"
                                                    onChange={(event) => {
                                                        setFieldValue('estimate_file', event.currentTarget.files[false]);
                                                    }}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_file && errors.estimate_file ? 'error' : 'form-control1  m-false'}
                                                />
                                                {/* Display the selected file name */}
                                                {values.estimate_file && (
                                                    <div className='ms-2'>{values.estimate_file.name}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <div className='bar1-para  align-items-center pt-2'>SIRET bénéficiaire
                                            <div className=''>
                                                <input
                                                    name="estimate_beneficiarySiret"
                                                    value={values.estimate_beneficiarySiret}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_beneficiarySiret && errors.estimate_beneficiarySiret ? 'error' : 'form-control1  m-false'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_identityOftheBeneficiary" name="estimate_identityOftheBeneficiary" checked={values.estimate_identityOftheBeneficiary} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_identityOftheBeneficiary">Identité du bénéficiaire</label>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_quoteIssueDate" name="estimate_quoteIssueDate" checked={values.estimate_quoteIssueDate} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_quoteIssueDate">Date d’émission du devis</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_mentionsRequiredByTheOperationSheet" name="estimate_mentionsRequiredByTheOperationSheet" checked={values.estimate_mentionsRequiredByTheOperationSheet} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_mentionsRequiredByTheOperationSheet">Mentions exigées par la fiche d’opération</label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_beneficiaryName" name="estimate_beneficiaryName" checked={values.estimate_beneficiaryName} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryName">Nom bénéficiaire</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_beneficiaryAddress" name="estimate_beneficiaryAddress" checked={values.estimate_beneficiaryAddress} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryAddress">Adresse bénéficiaire</label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_beneficiaryPhone" name="estimate_beneficiaryPhone" checked={values.estimate_beneficiaryPhone} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryPhone">Telephone bénéficiaire</label>
                                    </div>
                                </div>

                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_beneficiaryEmail" name="estimate_beneficiaryEmail" checked={values.estimate_beneficiaryEmail} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryEmail">Email bénéficiaire</label>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_beneficiaryFirstName" name="estimate_beneficiaryFirstName" checked={values.estimate_beneficiaryFirstName} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryFirstName">Prenom bénéficiaire</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_beneficiaryFunction" name="estimate_beneficiaryFunction" checked={values.estimate_beneficiaryFunction} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryFunction">Fonction bénéficiaire</label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_placeOfWork" name="estimate_placeOfWork" checked={values.estimate_placeOfWork} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_placeOfWork">Lieu des travaux</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_purchaseOrderNo" name="estimate_purchaseOrderNo" checked={values.estimate_purchaseOrderNo} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_purchaseOrderNo">N° de devis/Bon de commande</label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="bar1-para  ">Conformité du rôle Incitatif
                                        <input
                                            type="radio"
                                            value='Oui'
                                            name="estimate_incentiveRoleCompliance"
                                            id="estimate_incentiveRoleCompliance"
                                            onChange={handleChange}
                                            checked={values.estimate_incentiveRoleCompliance === "Oui"}
                                            className="form-check-input ms-3" />
                                        <span className='span-bar bar1-para '>
                                            Oui
                                        </span>
                                        <input
                                            type="radio"
                                            value='Non'
                                            name="estimate_incentiveRoleCompliance"
                                            id="estimate_incentiveRoleCompliance"
                                            onChange={handleChange}
                                            checked={values.estimate_incentiveRoleCompliance === "Non"}
                                            className="form-check-input ms-3" />
                                        <span className='span-bar bar1-para '>
                                            Non
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-3">

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_mentionOfTheIncentiveRole" name="estimate_mentionOfTheIncentiveRole" checked={values.estimate_mentionOfTheIncentiveRole} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_mentionOfTheIncentiveRole">Mention du rôle incitatif</label>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <div className='bar1-para d-flex align-items-center'>N° de Devis
                                            <div className='d-flex ms-3'>
                                                <input type="text"
                                                    name="estimate_quoteNo"
                                                    value={values.estimate_quoteNo}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_quoteNo && errors.estimate_quoteNo ? 'error' : 'form-control1  m-false'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_goodForAgreement" name="estimate_goodForAgreement" checked={values.estimate_goodForAgreement} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_goodForAgreement">Bon pour accord </label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="estimate_handwrittenDate" name="estimate_handwrittenDate" checked={values.estimate_handwrittenDate} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_handwrittenDate">Date manuscrite </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="estimate_surnameFirstName" name="estimate_surnameFirstName" checked={values.estimate_surnameFirstName} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_surnameFirstName">Nom, Prenom, Fonction manuscrite </label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="estimate_reference" name="estimate_reference" checked={values.estimate_reference} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_reference">Référence</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1 d-flex">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="estimate_brand" name="estimate_brand" checked={values.estimate_brand} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_brand">Marque</label>
                                    </div>
                                    {values.estimate_brand == true && (
                                        <>
                                            <input type="text"
                                                name="Marque"
                                                value={values.Marque}
                                                onChange={handleChange} className='form-control1 mx-3 m-false' />
                                        </>
                                    )}
                                </div>
                                <div className="col input-box1 d-flex">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="estimate_presenceOfTheStandard" name="estimate_presenceOfTheStandard" checked={values.estimate_presenceOfTheStandard} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_presenceOfTheStandard">Presence de la norme</label>
                                    </div>

                                </div>

                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="estimate_attendanceDateandSignatureOfTheCustomer" name="estimate_attendanceDateandSignatureOfTheCustomer" checked={values.estimate_attendanceDateandSignatureOfTheCustomer} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_attendanceDateandSignatureOfTheCustomer">Présence Date et signature du client</label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start d-flex  align-items-center">
                                        <label className="form-check-label bar1-para mx-2" htmlFor="inlineCheckbox1">Montant de la Prime Beneficiare(€)</label>
                                        <div className="col input-box1">
                                            <div className="text-start ">
                                                <input
                                                    type="text "
                                                    name="estimate_amountOfBeneficiaryPremium"
                                                    value={values.estimate_amountOfBeneficiaryPremium}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.estimate_amountOfBeneficiaryPremium && errors.estimate_amountOfBeneficiaryPremium ? 'error' : 'form-control1  m-false'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1 d-flex ">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="estimate_insulationClass" name="estimate_insulationClass" checked={values.estimate_insulationClass} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_insulationClass">Classe de l’isolant</label>
                                    </div>
                                    {values.estimate_insulationClass == true && (
                                        <>
                                            <input type="text"
                                                name="Classedelisolant"
                                                value={values.Classedelisolant}
                                                onChange={handleChange} className='form-control1 mx-3 m-false' />
                                        </>
                                    )}
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="estimate_raiPremium" name="estimate_raiPremium" checked={values.estimate_raiPremium} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_raiPremium">Prime RAI = Prime DEVIS</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <div className="col input-box1 d-flex">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="estimate_otherMandatoryMaterialMentions" name="estimate_otherMandatoryMaterialMentions" checked={values.estimate_otherMandatoryMaterialMentions} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="estimate_otherMandatoryMaterialMentions">Autre mentions obligatoire matériel</label>
                                    </div>
                                    {values.estimate_otherMandatoryMaterialMentions == true && (
                                        <>
                                            <input type="text"
                                                name="materialmentions"
                                                value={values.materialmentions}
                                                onChange={handleChange} className='form-control1 mx-3 m-false' />
                                        </>
                                    )}
                                </div>
                                <div className="col-6">
                                    <div className="mb-3 text-start">
                                        <label htmlFor="exampleFormControlTextarea1" className="bar1-para text-start">Commentaires :</label>

                                        <div className=" flex-row align-items-start">
                                            <textarea
                                                name="estimate_comments"
                                                value={values.estimate_comments}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_comments && errors.estimate_comments ? 'error' : 'form-control ml-1 shadow-none textarea'}>
                                            </textarea>
                                            {touched.estimate_comments && errors.estimate_comments && <div className="error-message">{errors.estimate_comments}</div>}
                                        </div></div>
                                </div>
                            </div>
                        </div >
                        <div className='container-fluid'>
                            <div className="row ">
                                <p className='bar1-head '> Facture </p>
                                <div className="col text-start">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="bill_installerContactDetails" name="bill_installerContactDetails" checked={values.bill_installerContactDetails} onChange={() => {
                                            setFieldValue('bill_installerContactDetails', !values.bill_installerContactDetails);
                                            setFieldValue('bill_subcontractorContactDetails', false);
                                        }} />
                                        <label className="form-check-label bar1-para" htmlFor="bill_installerContactDetails">Coordonnées Installateur</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input " type="checkbox" id="bill_subcontractorContactDetails" name="bill_subcontractorContactDetails" checked={values.bill_subcontractorContactDetails} onChange={() => {
                                            setFieldValue('bill_subcontractorContactDetails', !values.bill_subcontractorContactDetails);
                                            setFieldValue('bill_installerContactDetails', false);
                                        }} />
                                        <label className="form-check-label bar1-para" htmlFor="bill_subcontractorContactDetails">Coordonnées de sous traitant</label>
                                    </div>
                                </div>
                                {values.bill_subcontractorContactDetails == true && (
                                    <>
                                        <div className='row mt-3'>
                                            <div className='col-sm-5 input-box1'>
                                                <p className='bar1-para'>Raison sociale*</p>
                                                <input
                                                    type="text"
                                                    name="bill_socialReason"
                                                    value={values.bill_socialReason}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.bill_socialReason && errors.bill_socialReason ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.bill_socialReason && errors.bill_socialReason && <div className="error-message">{errors.bill_socialReason}</div>}
                                            </div>
                                            <div className='col-sm-2 input-box1'>
                                                <p className='bar1-para'>Nom commercial</p>
                                                <input type="text"
                                                    name="bill_tradeName"
                                                    value={values.bill_tradeName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.bill_tradeName && errors.bill_tradeName ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.bill_tradeName && errors.bill_tradeName && <div className="error-message">{errors.bill_tradeName}</div>}
                                            </div>
                                            <div className='col-sm-5 input-box1'>
                                                <p className='bar1-para bar1-ul'>N Siren*</p>
                                                <input type="number"
                                                    name="bill_nSiret"
                                                    value={values.bill_nSiret}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.bill_nSiret && errors.bill_nSiret ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.bill_nSiret && errors.bill_nSiret && <div className="error-message">{errors.bill_nSiret}</div>}
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-sm-6 input-box1'>
                                                <p className='bar1-para'>Adresse*</p>
                                                <input type="text"
                                                    name="bill_address"
                                                    value={values.bill_address}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.bill_address && errors.bill_address ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.bill_address && errors.bill_address && <div className="error-message">{errors.bill_address}</div>}
                                            </div>
                                            <div className='col-sm-2 input-box1'>
                                                <p className='bar1-para'>CP*</p>
                                                <input type="number"
                                                    name="bill_cp"
                                                    value={values.bill_cp}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.bill_cp && errors.bill_cp ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.bill_cp && errors.bill_cp && <div className="error-message">{errors.bill_cp}</div>}
                                            </div>
                                            <div className='col-sm-4 input-box1'>
                                                <p className='bar1-para'>Ville*</p>
                                                <input type="text"
                                                    name="bill_city"
                                                    value={values.bill_city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.bill_city && errors.bill_city ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.bill_city && errors.bill_city && <div className="error-message">{errors.bill_city}</div>}
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-sm-3 input-box1'>
                                                <p className='bar1-para'>Telephone*</p>
                                                <input type="text"
                                                    name="bill_phone"
                                                    value={values.bill_phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.bill_phone && errors.bill_phone ? 'error' : 'form-control1 w-100 m-false'} />
                                                {touched.bill_phone && errors.bill_phone && <div className="error-message">{errors.bill_phone}</div>}
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                            <div className="row my-3">
                                <div className="col text-start">
                                    <div className='bar1-para d-flex '> SIRET INSTALLATEUR &nbsp; <a href='https://avis-situation-sirene.insee.fr/' target='_blank' className='bar1-ul'> (vérification si ok avec SIRET installateur dans fiche installateur)</a>
                                        <div className=' ms-3'>
                                            <input
                                                type="text "
                                                name="bill_siretInstaller"
                                                value={values.bill_siretInstaller}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_siretInstaller && errors.bill_siretInstaller ? 'error' : 'form-control1  m-false'} />
                                        </div>

                                    </div>
                                </div>
                                {touched.bill_siretInstaller && errors.bill_siretInstaller && <div className="error-message">{errors.bill_siretInstaller}</div>}
                            </div>
                            <div className="row my-3">

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="bill_identityOfTheBeneficiary" name="bill_identityOfTheBeneficiary" checked={values.bill_identityOfTheBeneficiary} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_identityOfTheBeneficiary">Identité du bénéficiaire</label>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="bill_invoiceIssueDate" name="bill_invoiceIssueDate" checked={values.bill_invoiceIssueDate} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_invoiceIssueDate">Date d’émission du facture</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <div className='bar1-para d-flex align-items-center pt-2'>SIRET bénéficiaire
                                            <div className=' ms-3'>
                                                <input
                                                    type="text "
                                                    name="bill_beneficiarySiret"
                                                    value={values.bill_beneficiarySiret}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.bill_beneficiarySiret && errors.bill_beneficiarySiret ? 'error' : 'form-control1  m-false'} />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="bill_operationSheet" name="bill_operationSheet" checked={values.bill_operationSheet} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_operationSheet">Mentions exigées par la fiche d’opération</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="bill_beneficiaryAddress" name="bill_beneficiaryAddress" checked={values.bill_beneficiaryAddress} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryAddress">Adresse bénéficiaire</label>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="bill_beneficiaryName" name="bill_beneficiaryName" checked={values.bill_beneficiaryName} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryName">Nom bénéficiaire</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="bill_beneficiaryPhone" name="bill_beneficiaryPhone" checked={values.bill_beneficiaryPhone} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryPhone">Telephone bénéficiaire</label>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="bill_beneficiaryFirstName" name="bill_beneficiaryFirstName" checked={values.bill_beneficiaryFirstName} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryFirstName">Prenom bénéficiaire</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="bill_beneficiaryEmail" name="bill_beneficiaryEmail" checked={values.bill_beneficiaryEmail} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryEmail">Email bénéficiaire</label>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="bill_beneficiaryFunction" name="bill_beneficiaryFunction" checked={values.bill_beneficiaryFunction} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryFunction">Fonction bénéficiaire</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="bill_lieuDesTravaux" name="bill_lieuDesTravaux" checked={values.bill_lieuDesTravaux} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_lieuDesTravaux">Lieu des travaux</label>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="bill_invoiceNumber" name="bill_invoiceNumber" checked={values.bill_invoiceNumber} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_invoiceNumber">N° de facture/Bon de commande</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input" type="checkbox" id="bill_vatRate" name="bill_vatRate" checked={values.bill_vatRate} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_vatRate">Taux de TVA</label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start">
                                        <input className="form-check-input" type="checkbox" id="bill_diametersAndThicknesses" name="bill_diametersAndThicknesses" checked={values.bill_diametersAndThicknesses} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="bill_diametersAndThicknesses">Presence des diametres  et epaisseurs</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col input-box1 ">
                                    <div className="text-start ">
                                        <div className='bar1-para d-flex align-items-center'>N° de Facture
                                            <div className=' ms-3'>
                                                <input
                                                    type="text "
                                                    name="bill_quoteNo"
                                                    value={values.bill_quoteNo}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.bill_quoteNo && errors.bill_quoteNo ? 'error' : 'form-control1  m-false'} />
                                                {touched.bill_quoteNo && errors.bill_quoteNo && <div className="error-message">{errors.bill_quoteNo}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3 text-start">
                                        <label htmlFor="exampleFormControlTextarea1" className="bar1-para text-start">Commentaires :</label>

                                        <div className=" flex-row align-items-start">
                                            <textarea
                                                name="bill_comments"
                                                value={values.bill_comments}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_comments && errors.bill_comments ? 'error' : 'form-control ml-1 shadow-none textarea'}>
                                            </textarea>
                                            {touched.bill_comments && errors.bill_comments && <div className="error-message">{errors.bill_comments}</div>}
                                        </div></div>
                                </div>
                            </div>
                        </div>
                        <div className='container-fluid mt-3 pb-4'>
                            <p className='bar1-head  '> Attestation sur l’Honneur </p>
                            <div className="row ">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_ahFormatCompliant" name="attestation_ahFormatCompliant" checked={values.attestation_ahFormatCompliant} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_ahFormatCompliant">Format AH conforme </label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_fFullCFrame" name="attestation_fFullCFrame" checked={values.attestation_fFullCFrame} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_fFullCFrame">Cadre(s) C complet</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_fullAframe" name="attestation_fullAframe" checked={values.attestation_fullAframe} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_fullAframe">Cadre(s) A complet</label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_signatureAndStamp" name="attestation_signatureAndStamp" checked={values.attestation_signatureAndStamp} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_signatureAndStamp">Signature et cachet du professionnel</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_completeBframe" name="attestation_completeBframe" checked={values.attestation_completeBframe} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_completeBframe">Cadre(s) B complet</label>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_completeQbvsFrame" name="attestation_completeQbvsFrame" checked={values.attestation_completeQbvsFrame} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_completeQbvsFrame">Cadre QPV / BS complet</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_beneficiarysSignatureandStamp" name="attestation_beneficiarysSignatureandStamp" checked={values.attestation_beneficiarysSignatureandStamp} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_beneficiarysSignatureandStamp">Signature et cachet du bénéficiaire</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3 text-start">
                                        <label htmlFor="exampleFormControlTextarea1" className="bar1-para text-start">Commentaires :</label>

                                        <div className=" flex-row align-items-start">
                                            <textarea
                                                name="attestation_comments"
                                                value={values.attestation_comments}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.attestation_comments && errors.attestation_comments ? 'error' : 'form-control ml-1 shadow-none textarea'}>
                                            </textarea>
                                            {touched.attestation_comments && errors.attestation_comments && <div className="error-message">{errors.attestation_comments}</div>}
                                        </div></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p className='bar1-head'> Instruction </p>
                                    <div className="col">
                                        <div className="mb-3 text-start">
                                            <label htmlFor="exampleFormControlTextarea1" className="bar1-para text-start">Commentaires :</label>
                                            <div className=" flex-row align-items-start">
                                                <textarea
                                                    name="synopsisComments"
                                                    value={values.synopsisComments}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.synopsisComments && errors.synopsisComments ? 'error' : 'form-control ml-1 shadow-none textarea'}>
                                                </textarea>
                                                {touched.synopsisComments && errors.synopsisComments && <div className="error-message">{errors.synopsisComments}</div>}
                                            </div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default DevisDFAB2C