import React, { useState } from 'react'


function Coordonnees() {

    const [showInputs, setShowInputs] = useState(false);

    const handleSelectChange = (event) => {
        setShowInputs(event.target.value === 'show');
    };

    return (
        <div className='container-fluid-fluid pl-30'>

          <div className='row mt-3'>
                <div className='col-sm-5 input-box1'>
                    <p className='bar1-para'>Raison sociale*</p>
                    <input type="text" className="form-control1 w-100 m-0" ></input>
                </div>
                <div className='col-sm-2 input-box1'>
                    <p className='bar1-para'>Nom commercial</p>
                    <input type="text" className="form-control1 w-100 m-0" ></input>
                </div>
                <div className='col-sm-5 input-box1'>
                    <p className='bar1-para bar1-ul'>N Siren*</p>
                    <input type="text" className="form-control1 w-100 m-0" ></input>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-sm-6 input-box1'>
                    <p className='bar1-para'>Adresse*</p>
                    <input type="text" className="form-control1 w-100 m-0" ></input>
                </div>
                <div className='col-sm-2 input-box1'>
                    <p className='bar1-para'>CP*</p>
                    <input type="text" className="form-control1 w-100 m-0" ></input>
                </div>
                <div className='col-sm-4 input-box1'>
                    <p className='bar1-para'>Ville*</p>
                    <input type="text" className="form-control1 w-100 m-0" ></input>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-sm-3 input-box1'>
                    <p className='bar1-para'>Telephone*</p>
                    <input type="text" className="form-control1 w-100 m-0" ></input>
                </div>
            </div>
</div>
    )
}

export default Coordonnees