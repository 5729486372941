import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'
const ValidInvalid = () => {
    const [data, setData] = useState([]);
    const { statusValue } = useParams();
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const Token = localStorage.getItem('Token');
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/get-valid-or-invalid/${statusValue}`, {
                headers:
                    { authorization: Token }
            }).then(res => {
                console.log(res?.data?.Data);
                setData(res?.data?.Data);
            })
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <>
            <div className="container-fluid-fluid">
                <div className="row">
                    <div className='table-responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className='bar1-para'>Service sélectionné </th>
                                    <th scope="col" className='bar1-para'>Oblige</th>
                                    <th scope="col" className='bar1-para'>Installateur</th>
                                    <th scope="col" className='bar1-para'>Operation</th>
                                    <th scope="col" className='bar1-para'>Dossier number</th>
                                    <th scope="col" className='bar1-para'>Raison sociale</th>
                                    <th scope="col" className='bar1-para'>CP</th>
                                    <th scope="col" className='bar1-para'>Ville</th>
                                    <th scope="col" className='bar1-para'>Date de facture</th>
                                    <th scope="col" className='bar1-para'>Statut</th>
                                    <th scope="col" className='bar1-para'>Exporté Statut</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data?.map((item, index) => (
                                    <tr key={item?.installer?.id} className='bg-light'>
                                        <td className='bar1-para'>{item?.multistep1?.documents}</td>
                                        <td className='bar1-para'>{item?.multistep2?.oblige_name}</td>
                                        <td className='bar1-para'>{item?.multistep2?.partenaire_name}</td>
                                        <td className='bar1-para'>{item?.multistep1?.serviceselect}</td>
                                        <td className='bar1-para'>{item?.multistep1?.estimate_siretinstaller}</td>
                                        <td className='bar1-para'>{item?.multistep1?.beneficiary_socialreason}</td> 
                                        <td className='bar1-para'>{item?.multistep1?.beneficiary_cp}</td> 
                                        <td className='bar1-para'>{item?.multistep1?.beneficiary_city}</td> 
                                        <td className='bar1-para'>{item?.multistep1?.createdAt.split('T')[0]}</td> 
                                        <td className='bar1-para'>{item?.multistep1?.final_status}</td> 
                                        <td className='bar1-para'>{item?.multistep1?.isdataExported}</td> 
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ValidInvalid