import React from 'react'
import { Link, useNavigate } from "react-router-dom"


function Valider() {
    const navigate = useNavigate();
    const handleChange = (values) => {
        var validDatas = { 'validDatasStore': values };
        console.log('values', values);
        if (values === 'Valide') {
            navigate('/devis');
        }
        if (values === 'Invalide') {
            navigate('/valider-comment');
        }
        sessionStorage.setItem("step-9", JSON.stringify(validDatas));
    }
    return (
        <div className='container-fluid px-4'>
            <p className='bar1-para'>Contrôle / Valider Geoportail</p>
            <div className="row">
                <div className="col">
                    <div className='mt-3 mb-3  text-start'>
                        <button type='button' onClick={() => handleChange('Valide')} className='btns-color2' >Valide </button>
                        <button type='button' onClick={() => handleChange('Invalide')} className='btns-color2' >Invalide </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Valider