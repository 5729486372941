import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { QPVCALVALUE } from '../../arraystore/CountryCodeArray'
// FORMIK
import { toast } from 'react-toastify';
import { Formik } from 'formik';
const InfosB2B = (props) => {
    const navigate = useNavigate();
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);
    const Token = localStorage.getItem('Token');
    const MultiUpdate = props.MultiUpdate;
    console.log('MultiUpdate', MultiUpdate);
    const [initialValues, setInitialValues] = useState({
        id: '',
        beneficiary_socialReason: MultiUpdate?.multisteptable1?.beneficiary_socialreason || '',
        beneficiary_nomCommercial: MultiUpdate?.multisteptable1?.beneficiary_nomcommercial || '',
        beneficiary_nSiret: MultiUpdate?.multisteptable1?.beneficiary_nsiret || '',
        beneficiary_representativeName: MultiUpdate?.multisteptable1?.beneficiary_socialreason || '',
        beneficiary_firstName: MultiUpdate?.multisteptable1?.beneficiary_firstname || '',
        beneficiary_hisFunction: MultiUpdate?.multisteptable1?.beneficiary_hisfunction || '',
        beneficiary_address: MultiUpdate?.multisteptable1?.beneficiary_socialreason || '',
        beneficiary_cp: MultiUpdate?.multisteptable1?.beneficiary_cp || '',
        beneficiary_city: MultiUpdate?.multisteptable1?.beneficiary_city || '',
        beneficiary_email: MultiUpdate?.multisteptable1?.beneficiary_email || '',
        beneficiary_nFixed: MultiUpdate?.multisteptable1?.beneficiary_nfixed || '',
        beneficiary_nMobile: MultiUpdate?.multisteptable1?.beneficiary_nMobile || '',
        // 
        chantier_address: MultiUpdate?.multisteptable1?.chantier_address || '',
        chantier_cp: MultiUpdate?.multisteptable1?.chantier_cp || '',
        chantier_city: MultiUpdate?.multisteptable1?.chantier_city || '',
        chantier_wardName: MultiUpdate?.multisteptable1?.chantier_wardname || '',
        chantier_chooseType: MultiUpdate?.multisteptable1?.chantier_choosetype || '',
        chantier_districtCode: MultiUpdate?.multisteptable1?.chantier_districtcode || '',
        chantier_greatPrecariousness: MultiUpdate?.multisteptable1?.chantier_greatprecariousness || '',
        chantier_precarious: MultiUpdate?.multisteptable1?.chantier_precarious || '',
        chantier_contact: MultiUpdate?.multisteptable1?.chantier_contact || '',
        chantier_telephone: MultiUpdate?.multisteptable1?.chantier_telephone || '',
        chantier_ageOfBuilding: MultiUpdate?.multisteptable1?.chantier_ageofbuilding || '',
        chantier_heatingMode: MultiUpdate?.multisteptable1?.chantier_heatingmode || '',
        chantier_buildingFloorArea: MultiUpdate?.multisteptable1?.chantier_buildingfloorarea || '',
        chantier_typeOfBuilding: MultiUpdate?.multisteptable1?.chantier_typeofbuilding || '',
    });

    //   
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            id: MultiUpdate?.multisteptable1?.id,
            beneficiary_socialReason: MultiUpdate?.multisteptable1?.beneficiary_socialreason || '',
            beneficiary_nomCommercial: MultiUpdate?.multisteptable1?.beneficiary_nomcommercial || '',
            beneficiary_nSiret: MultiUpdate?.multisteptable1?.beneficiary_nsiret || '',
            beneficiary_representativeName: MultiUpdate?.multisteptable1?.beneficiary_socialreason || '',
            beneficiary_firstName: MultiUpdate?.multisteptable1?.beneficiary_firstname || '',
            beneficiary_hisFunction: MultiUpdate?.multisteptable1?.beneficiary_hisfunction || '',
            beneficiary_address: MultiUpdate?.multisteptable1?.beneficiary_socialreason || '',
            beneficiary_cp: MultiUpdate?.multisteptable1?.beneficiary_cp || '',
            beneficiary_city: MultiUpdate?.multisteptable1?.beneficiary_city || '',
            beneficiary_email: MultiUpdate?.multisteptable1?.beneficiary_email || '',
            beneficiary_nFixed: MultiUpdate?.multisteptable1?.beneficiary_nfixed || '',
            beneficiary_nMobile: MultiUpdate?.multisteptable1?.beneficiary_nMobile || '',
            // 
            chantier_address: MultiUpdate?.multisteptable1?.chantier_address || '',
            chantier_cp: MultiUpdate?.multisteptable1?.chantier_cp || '',
            chantier_city: MultiUpdate?.multisteptable1?.chantier_city || '',
            chantier_wardName: MultiUpdate?.multisteptable1?.chantier_wardname || '',
            chantier_chooseType: MultiUpdate?.multisteptable1?.chantier_choosetype || '',
            chantier_districtCode: MultiUpdate?.multisteptable1?.chantier_districtcode || '',
            chantier_greatPrecariousness: MultiUpdate?.multisteptable1?.chantier_greatprecariousness || '',
            chantier_precarious: MultiUpdate?.multisteptable1?.chantier_precarious || '',
            chantier_contact: MultiUpdate?.multisteptable1?.chantier_contact || '',
            chantier_telephone: MultiUpdate?.multisteptable1?.chantier_telephone || '',
            chantier_ageOfBuilding: MultiUpdate?.multisteptable1?.chantier_ageofbuilding || '',
            chantier_heatingMode: MultiUpdate?.multisteptable1?.chantier_heatingmode || '',
            chantier_buildingFloorArea: MultiUpdate?.multisteptable1?.chantier_buildingfloorarea || '',
            chantier_typeOfBuilding: MultiUpdate?.multisteptable1?.chantier_typeofbuilding || '',
        }));
    }, [MultiUpdate]);
    // 
    const getCPValue = (values, setFieldValue) => {
        console.log('chantier_cp', values.chantier_cp);
        setFieldValue('chantier_cp', values.chantier_cp);
        if (values.chantier_cp) {
            const cpsplit = String(values.chantier_cp).substr(0, 2);
            console.log('two digit code', cpsplit);
            const cpValues = QPVCALVALUE[cpsplit] || '';
            console.log('cpValues', cpValues);
            setFieldValue('chantier_greatPrecariousness', cpValues.chantier_greatPrecariousness || '');
            setFieldValue('chantier_precarious', cpValues.chantier_precarious || '');
        }
    }
    // VALIDATE
    const validate = (values) => {
        const errors = {};
        return errors;
    }
    // Values Submit
    const handleSubmit = async (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-4", JSON.stringify(values));
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/update-multistep`, values,
                {
                    headers: { authorization: Token }
                }).then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        console.log('res.message', response?.data?.message);
                        toastsuccess(response?.data?.message);
                    }
                })
        } catch (error) {
            console.log(error.response?.data?.message);
            toasterror(error.response?.data?.message);
        }
    }
    const installerorobligelocal = localStorage.getItem('installerorobligelocal');
    console.log('installerorobligelocal', installerorobligelocal);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="contsiner-fluid pb-4">
                            <div className="save_button_container d-flex justify-content-between align-items-center">
                                <p className="bar1-head  mt-4">Infos client </p> <button type='submit' className='btns-color'>Sauvegarder</button>
                            </div>

                            <div className='row'>
                                <div className='col-sm input-box1'>
                                    <p className='bar1-para'>Raison sociale*</p>
                                    <input type="text"
                                        name="beneficiary_socialReason"
                                        value={values.beneficiary_socialReason}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_socialReason && errors.beneficiary_socialReason ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.beneficiary_socialReason && errors.beneficiary_socialReason && <div className="error-message">{errors.beneficiary_socialReason}</div>}
                                </div>
                                <div className='col-sm input-box1'>
                                    <p className='bar1-para'>Nom commercial</p>
                                    <input type="text"
                                        name="beneficiary_nomCommercial"
                                        value={values.beneficiary_nomCommercial}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_nomCommercial && errors.beneficiary_nomCommercial ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                                <div className='col-sm input-box1'>
                                    <p className='bar1-para'>N Siren*</p>
                                    <input type="number"
                                        name="beneficiary_nSiret"
                                        value={values.beneficiary_nSiret}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_nSiret && errors.beneficiary_nSiret ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.beneficiary_nSiret && errors.beneficiary_nSiret && <div className="error-message">{errors.beneficiary_nSiret}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm input-box1'>
                                    <p className='bar1-para'>Nom du signataire</p>
                                    <input type="text"
                                        name="beneficiary_representativeName"
                                        value={values.beneficiary_representativeName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_representativeName && errors.beneficiary_representativeName ? 'error' : 'form-control1 text-initial w-100 m-0'} placeholder='Nom' />
                                </div>
                                <div className='col-sm input-box1'> <p className='bar1-para'>Prenom</p>
                                    <input type="text"
                                        name="beneficiary_firstName"
                                        value={values.beneficiary_firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_firstName && errors.beneficiary_firstName ? 'error' : ' w-100 form-controlz'} placeholder='Prénom' />
                                </div>
                                <div className='col-sm input-box1'>
                                    <p className='bar1-para'>Sa fonction</p>
                                    <input type="text"
                                        name="beneficiary_hisFunction"
                                        value={values.beneficiary_hisFunction}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_hisFunction && errors.beneficiary_hisFunction ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm input-box1'>
                                    <p className='bar1-para'>Adresse*</p>
                                    <input type="text"
                                        name="beneficiary_address"
                                        value={values.beneficiary_address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_address && errors.beneficiary_address ? 'error' : ' form-control1 w-100 m-0'} />
                                    {touched.beneficiary_address && errors.beneficiary_address && <div className="error-message">{errors.beneficiary_address}</div>}
                                </div>
                                <div className='col-sm input-box1'>
                                    <p className='bar1-para'>CP*</p>
                                    <input type="number"
                                        name="beneficiary_cp"
                                        value={values.beneficiary_cp}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_cp && errors.beneficiary_cp ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.beneficiary_cp && errors.beneficiary_cp && <div className="error-message">{errors.beneficiary_cp}</div>}
                                </div>
                                <div className='col-sm input-box1'>
                                    <p className='bar1-para'>Ville*</p>
                                    <input type="text"
                                        name="beneficiary_city"
                                        value={values.beneficiary_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_city && errors.beneficiary_city ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.beneficiary_city && errors.beneficiary_city && <div className="error-message">{errors.beneficiary_city}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>Email*</p>
                                    <input type="text"
                                        name="beneficiary_email"
                                        value={values.beneficiary_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_email && errors.beneficiary_email ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.beneficiary_email && errors.beneficiary_email && <div className="error-message">{errors.beneficiary_email}</div>}
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>N fixe**</p>
                                    <input type="number"
                                        name="beneficiary_nFixed"
                                        value={values.beneficiary_nFixed}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_nFixed && errors.beneficiary_nFixed ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>N mobile**</p>
                                    <input type="text"
                                        name='beneficiary_nMobile'
                                        value={values.beneficiary_nMobile}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_nMobile && errors.beneficiary_nMobile ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                            </div>
                            <p className='bar1-head mt-5'>
                                Infos chantier
                            </p>
                            <div className='row '>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>Adresse*</p>
                                    <input type="text"
                                        name="chantier_address"
                                        value={values.chantier_address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_address && errors.chantier_address ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.chantier_address && errors.chantier_address && <div className="error-message">{errors.chantier_address}</div>}
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>CP*</p>
                                    <input type="number"
                                        name="chantier_cp"
                                        value={values.chantier_cp}
                                        onChange={handleChange}
                                        onKeyUp={() => getCPValue(values, setFieldValue)}
                                        onBlur={handleBlur}
                                        className={touched.chantier_cp && errors.chantier_cp ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.chantier_cp && errors.chantier_cp && <div className="error-message">{errors.chantier_cp}</div>}
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>Ville*</p>
                                    <input type="text"
                                        name="chantier_city"
                                        value={values.chantier_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_city && errors.chantier_city ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.chantier_city && errors.chantier_city && <div className="error-message">{errors.chantier_city}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>Nom de quartier</p>
                                    <input
                                        type="text"
                                        name="chantier_wardName"
                                        value={values.chantier_wardName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_wardName && errors.chantier_wardName ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>Code de quartier</p>
                                    <input type="number"
                                        name="chantier_districtCode"
                                        value={values.chantier_districtCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className='form-control1 w-100 m-0' />
                                    {touched.chantier_districtCode && errors.chantier_districtCode && <div className="error-message">{errors.chantier_districtCode}</div>}
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para '>Type de quartier*</p>
                                    <div className='d-flex'>
                                        <select name='chantier_chooseType' id='chantier_chooseType' value={values.chantier_chooseType} onChange={handleChange} className="form-select ps-3 btn-buttons" aria-label="Choisir un type">
                                            <option value>Choisir un type</option>
                                            <option value="Classique">Classique</option>
                                            <option value="QPV">QPV</option>
                                            {/* <option value="Bailleur social">Bailleur social</option> */}
                                        </select>
                                    </div>
                                    {values.chantier_chooseType === 'QPV' && (
                                        <div className='row row-cols-2 row-cols-lg-2 mt-3'>
                                            <div className="col"> <p className='bar1-para m-0'>Grande precarite*</p></div>
                                            <div className="col">
                                                <input type="text"
                                                    name="chantier_greatPrecariousness"
                                                    value={values.chantier_greatPrecariousness}
                                                    onChange={handleChange}
                                                    className={touched.chantier_greatPrecariousness && errors.chantier_greatPrecariousness ? 'error' : 'form-control1 w-100'} required />
                                                {/* {touched.greatPrecariousness && errors.greatPrecariousness && <div className="error-message">{errors.chantier_city}</div>} */}
                                            </div>
                                            <div className="col mt-3"> <p className='bar1-para m-0'>Precarite*</p></div>
                                            <div className="col mt-3">
                                                <input type="text"
                                                    name="chantier_precarious"
                                                    value={values.chantier_precarious}
                                                    onChange={handleChange}
                                                    className={touched.chantier_precarious && errors.chantier_precarious ? 'error' : 'form-control1 w-100'} required /></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para'>Contact</p>
                                    <input type="text"
                                        name="chantier_contact"
                                        value={values.chantier_contact}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_contact && errors.chantier_contact ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                                <div className='col-sm-3 input-box1'>
                                    <p className='bar1-para'>Telephone</p>
                                    <input type="number"
                                        name="chantier_telephone"
                                        value={values.chantier_telephone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_telephone && errors.chantier_telephone ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para '>Type de batiment*</p>
                                    <div className=''>
                                        <select
                                            name='chantier_typeOfBuilding'
                                            id='chantier_typeOfBuilding'
                                            value={values.chantier_typeOfBuilding}
                                            onChange={handleChange}
                                            className={touched.chantier_typeOfBuilding && errors.chantier_typeOfBuilding ? 'error' : 'form-select btn-buttons ps-3'} aria-label="Mode de chauffage">
                                            <option defaultValue='Mode de chauffage'>choisir batiment</option>
                                            <option value="Bureaux">Bureaux </option>
                                            <option value="Enseignement">Enseignement</option>
                                            <option value="Commerces">Commerces</option>
                                            <option value="Autres secteurs">Autres secteurs</option>
                                            <option value="Hotellerie Restauration">Hotellerie Restauration</option>
                                            <option value="Sante">Sante</option>
                                        </select>
                                        {touched.chantier_typeOfBuilding && errors.chantier_typeOfBuilding && <div className="error-message">{errors.chantier_typeOfBuilding}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3 mb-5'>
                                <div className='col-sm-3 input-box1'>
                                    <p className='bar1-para '>Age de(s) batiment(s)*</p>
                                    <div className=''>
                                        <select
                                            name='chantier_ageOfBuilding'
                                            id='chantier_ageOfBuilding'
                                            value={values.chantier_ageOfBuilding}
                                            onChange={handleChange}
                                            className={touched.chantier_ageOfBuilding && errors.chantier_ageOfBuilding ? 'error' : 'form-select ps-3 btn-buttons'}
                                            aria-label="Plus de 2ans">
                                            <option defaultValue='Age de(s) batiment(s)'>Age de(s) batiment(s)</option>
                                            <option value="Plus de 2ans">Plus de 2ans</option>
                                        </select>
                                        {touched.chantier_ageOfBuilding && errors.chantier_ageOfBuilding && <div className="error-message">{errors.chantier_ageOfBuilding}</div>}
                                    </div>
                                </div>
                                <div className='col-sm-3 input-box1'>
                                    <p className='bar1-para '>Mode de chauffage*</p>
                                    <div className=''>
                                        <select name='chantier_heatingMode'
                                            id='chantier_heatingMode'
                                            value={values.chantier_heatingMode}
                                            onChange={handleChange}
                                            className={touched.chantier_heatingMode && errors.chantier_heatingMode ? 'error' : 'form-select btn-buttons ps-3'} aria-label="Mode de chauffage">
                                            <option defaultValue='Mode de chauffage'>Mode de chauffage</option>
                                            <option value="Electrique">Electrique </option>
                                            <option value="Combustible">Combustible</option>
                                        </select>
                                        {touched.chantier_heatingMode && errors.chantier_heatingMode && <div className="error-message">{errors.chantier_heatingMode}</div>}
                                    </div>
                                </div>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para '>Surface au sol du batiment</p>
                                    <input type="number"
                                        name="chantier_buildingFloorArea"
                                        value={values.chantier_buildingFloorArea}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_buildingFloorArea && errors.chantier_buildingFloorArea ? 'error' : 'form-select w-100 ms-0'} />
                                    {touched.chantier_buildingFloorArea && errors.chantier_buildingFloorArea && <div className="error-message">{errors.chantier_buildingFloorArea}</div>}
                                </div>
                            </div>
                            {installerorobligelocal === 'Super Manager' ? (
                                <>
                                    <p className='bar1-head mt-5'>PARTENAIRE/INSTALLATEUR</p>
                                    <div className="row">
                                        <div className="col"><input type='text' value={MultiUpdate?.multisteptable2?.partenaire_name} readOnly className='form-control1'></input> &nbsp;
                                            <Link to={`/update-installer/${MultiUpdate?.multisteptable1?.installer_id}`} target='_blank' rel='noopener noreferrer' className='bar1-para '>
                                                view partenaire tarif valo
                                            </Link></div>
                                    </div>
                                    <p className='bar1-head mt-5'>OBLIGÉ</p>
                                    <div className="row  mb-3">
                                        <div className="col"><input type='text' value={MultiUpdate?.multisteptable2?.oblige_name} readOnly className='form-control1'></input> &nbsp;
                                            <Link to={`/update-oblige/${MultiUpdate?.multisteptable1?.oblige_id}`} target='_blank' rel='noopener noreferrer' className='bar1-para '>
                                                view oblige tarif valo
                                            </Link></div>
                                    </div>
                                </>) : null}
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default InfosB2B