import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
const CalcultBARTH164 = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();

    // ----------------------------------------------
    useEffect(() => {
        const storedFormValues = sessionStorage.getItem('step-6');

        if (storedFormValues) {
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.commitmentdate = parsedFormValues.commitmentdate;
            initialValues.dateofproof = parsedFormValues.dateofproof;
            initialValues.codepostal = parsedFormValues.codepostal;
            initialValues.invoicereference = parsedFormValues.invoicereference;
            initialValues.legalpersons = parsedFormValues.legalpersons;
            initialValues.addressofworks = parsedFormValues.addressofworks;
            initialValues.additionaladdress = parsedFormValues.additionaladdress;
            initialValues.city = parsedFormValues.city;
            initialValues.individual_house = parsedFormValues.individual_house;
            initialValues.heatpump_surface = parsedFormValues.heatpump_surface;
            initialValues.cep_initial = parsedFormValues.cep_initial;
            initialValues.cep_projet = parsedFormValues.cep_projet;
            initialValues.cef_initial = parsedFormValues.cef_initial;
            initialValues.cef_projet = parsedFormValues.cef_projet;
            initialValues.energy_gain = parsedFormValues.energy_gain;
            initialValues.greenhouse = parsedFormValues.greenhouse;
            initialValues.b2c_social_reason = parsedFormValues.b2c_social_reason;
            initialValues.b2c_siren_number = parsedFormValues.b2c_siren_number;
            initialValues.date_of_energy = parsedFormValues.date_of_energy;
            initialValues.energy_study_reference = parsedFormValues.energy_study_reference;
            initialValues.software_name = parsedFormValues.software_name;
            initialValues.software_version = parsedFormValues.software_version;
            initialValues.representative_name = parsedFormValues.representative_name;
            initialValues.b2c_first_name = parsedFormValues.b2c_first_name;
            initialValues.siret_number = parsedFormValues.siret_number;
            initialValues.field_work = parsedFormValues.field_work;
            initialValues.certification_reference = parsedFormValues.certification_reference;
            initialValues.b2c_date = parsedFormValues.b2c_date;

        }
    }, []);
    const initialValues = {
        commitmentdate: '',
        dateofproof: '',
        codepostal: '',
        invoicereference: '',
        legalpersons: '',
        addressofworks: '',
        additionaladdress: '',
        city: '',
        individual_house: '',
        heatpump_surface: '',
        cep_initial: '',
        cep_projet: '',
        cef_initial: '',
        cef_projet: '',
        energy_gain: '',
        greenhouse: '',
        b2c_social_reason: '',
        b2c_siren_number: '',
        date_of_energy: '',
        energy_study_reference: '',
        software_name: '',
        software_version: '',
        representative_name: '',
        b2c_first_name: '',
        siret_number: '',
        field_work: '',
        certification_reference: '',
        b2c_date: '',

    };
    const same_addressget = JSON.parse(sessionStorage.getItem('step-4'));
    console.log('same_address', same_addressget);
    const same_address = same_addressget.beneficiary_address;
    const same_cp = same_addressget.beneficiary_cp;
    const same_ville = same_addressget.beneficiary_city;
    console.log('same_address', same_address);
    const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
    const handleCheckboxChange = (e, setFieldValue) => {

        const isChecked = e.target.checked;
        setIsSameAddressChecked(isChecked);
        if (isChecked) {
            setFieldValue('addressofworks', same_address);
            setFieldValue('codepostal', same_cp);
            setFieldValue('city', same_ville);
        } else {
            setFieldValue('addressofworks', initialValues.addressofworks);
            setFieldValue('codepostal', initialValues.codepostal);
            setFieldValue('city', initialValues.city);
        }
    };
    const validate = (values) => {
        const errors = {};

        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.commitmentdate) {
            errors.commitmentdate = "Date d’engagement de l'opération (ex : acceptation du devis) requise";
        }
        if (!values.legalpersons) {
            errors.legalpersons = "Pour les personnes morales, nom du site des travaux ou nom de la copropriété requise";
        }
        if (!values.addressofworks) {
            errors.addressofworks = "Adresse des travaux requise";
        }
        if (!values.city) {
            errors.city = "Ville requise";
        }
        if (!values.individual_house) {
            errors.individual_house = "Maison individuelle existant depuis plus de 2 ans à la date d'engagement de l'opération requise";
        }
        if (!values.heatpump_surface) {
            errors.heatpump_surface = "Surface habitable de la maison rénovée Shab (m²) requise";
        }
        if (!values.cep_initial) {
            errors.cep_initial = "Consommation conventionnelle en énergie primaire avant les travaux de rénovation : Cep initial (kWh/m².an) requise";
        }
        if (!values.cep_projet) {
            errors.cep_projet = "Consommation conventionnelle en énergie primaire après les travaux de rénovation : Cep projet (kWh/m².an) requise";
        }
        if (!values.cef_initial) {
            errors.cef_initial = "Consommation conventionnelle en énergie finale avant les travaux de rénovation : Cef initial (kWh/m².an) requise";
        }
        if (!values.cef_projet) {
            errors.cef_projet = "Consommation conventionnelle en énergie finale après les travaux de rénovation : Cef projet (kWh/m².an) requise";
        }
        if (!values.energy_gain) {
            errors.energy_gain = "Gain énergétique du projet par rapport à la consommation conventionnelle en énergie primaire avant travaux requise";
        }
        if (!values.b2c_social_reason) {
            errors.b2c_social_reason = "Raison sociale requise";
        }
        if (!values.b2c_siren_number) {
            errors.b2c_siren_number = "Numéro SIREN requise";
        }
        if (!values.date_of_energy) {
            errors.date_of_energy = "Date de l’étude énergétique requise";
        }
        if (!values.energy_study_reference) {
            errors.energy_study_reference = "Référence de l’étude énergétique requise";
        }
        if (!values.software_name) {
            errors.software_name = "Nom du logiciel et de son éditeur requise";
        }
        if (!values.software_version) {
            errors.software_version = "Date et n° de version requise";
        }
        if (!values.representative_name) {
            errors.representative_name = "Nom du représentant requise";
        }
        if (!values.b2c_first_name) {
            errors.b2c_first_name = "Prénom requise";
        }
        if (!values.siret_number) {
            errors.siret_number = "N° SIREN requise";
        }
        if (!values.field_work) {
            errors.field_work = "Domaine des travaux réalisés requise";
        }
        if (!values.certification_reference) {
            errors.certification_reference = "Référence de la qualification ou certification requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/estimation-page");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-6');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid px-4 py-3'>
                            <div className='row'>
                                <div className='col'>
                                    <p className='bar1-para'>A/ BAR-TH-164 (v. A36.2) : Rénovation thermique globale d’une maison individuelle existante</p>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="date"
                                                name='commitmentdate'
                                                id='commitmentdate'
                                                value={values.commitmentdate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="date"
                                                name='dateofproof'
                                                id='dateofproof'
                                                value={values.dateofproof}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className='form-control1 mb-0 w-100' ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='invoicereference'
                                                id='invoicereference'
                                                value={values.invoicereference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className='form-control1 mb-0 w-100' ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Pour les personnes morales, nom du site des travaux ou nom de la copropriété :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                name='legalpersons'
                                                id='legalpersons'
                                                value={values.legalpersons}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.legalpersons && errors.legalpersons ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.legalpersons && errors.legalpersons && <div className="error-message">{errors.legalpersons}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12 text-left">
                                            <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                type='checkbox'
                                                id='same_address'
                                                checked={isSameAddressChecked}
                                                onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                            /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type='text'
                                                name='addressofworks'
                                                id='addressofworks'
                                                value={values.addressofworks}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                            {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type='text'
                                                name='additionaladdress'
                                                id='additionaladdress'
                                                value={values.additionaladdress}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Code postal : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='codepostal'
                                                id='codepostal'
                                                value={values.codepostal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Ville :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='city'
                                                id='city'
                                                value={values.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*Maison individuelle existant depuis plus de 2 ans à la date d'engagement de l'opération :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="individual_house"
                                                id="individual_house"
                                                onChange={handleChange}
                                                checked={values.individual_house === "Oui"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="individual_house"
                                                id="individual_house"
                                                onChange={handleChange}
                                                checked={values.individual_house === "Non"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.individual_house && errors.individual_house && <div className="error-message">{errors.individual_house}</div>}
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Surface habitable de la maison rénovée Shab (m²) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='heatpump_surface'
                                                id='heatpump_surface'
                                                value={values.heatpump_surface}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.heatpump_surface && errors.heatpump_surface ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.heatpump_surface && errors.heatpump_surface && <div className="error-message">{errors.heatpump_surface}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>Caractéristiques du bâtiment données par l’étude énergétique :</p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Consommation conventionnelle en énergie primaire avant les travaux de rénovation : Cep initial (kWh/m².an) :  </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='cep_initial'
                                                id='cep_initial'
                                                value={values.cep_initial}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.cep_initial && errors.cep_initial ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.cep_initial && errors.cep_initial && <div className="error-message">{errors.cep_initial}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Consommation conventionnelle en énergie primaire après les travaux de rénovation : Cep projet (kWh/m².an) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='cep_projet'
                                                id='cep_projet'
                                                value={values.cep_projet}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.cep_projet && errors.cep_projet ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.cep_projet && errors.cep_projet && <div className="error-message">{errors.cep_projet}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Consommation conventionnelle en énergie finale avant les travaux de rénovation : Cef initial (kWh/m².an) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='cef_initial'
                                                id='cef_initial'
                                                value={values.cef_initial}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.cef_initial && errors.cef_initial ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.cef_initial && errors.cef_initial && <div className="error-message">{errors.cef_initial}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Consommation conventionnelle en énergie finale après les travaux de rénovation : Cef projet (kWh/m².an) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='cef_projet'
                                                id='cef_projet'
                                                value={values.cef_projet}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.cef_projet && errors.cef_projet ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.cef_projet && errors.cef_projet && <div className="error-message">{errors.cef_projet}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Gain énergétique du projet par rapport à la consommation conventionnelle en énergie primaire avant travaux :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='energy_gain'
                                                id='energy_gain'
                                                value={values.energy_gain}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.energy_gain && errors.energy_gain ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.energy_gain && errors.energy_gain && <div className="error-message">{errors.energy_gain}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>NB : les consommations conventionnelles (en kWh/m².an) du bâtiment s’entendent sans déduction de la production d’électricité
                                        (autoconsommée ou exportée) et prennent en compte les usages pour le chauffage, le refroidissement et la production d’eau
                                        chaude sanitaire.</p>
                                    <p className="bar1-head  mt-4">Les émissions de gaz à effet de serre après rénovation, exprimées en kgeqCO2/m².an, sont inférieures ou égales à la valeur
                                        initiale de ces émissions avant travaux : </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="greenhouse"
                                                id="greenhouse"
                                                onChange={handleChange}
                                                checked={values.greenhouse === "Oui"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="greenhouse"
                                                id="greenhouse"
                                                onChange={handleChange}
                                                checked={values.greenhouse === "Non"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>NB : Les données utilisées pour les hypothèses de calcul du contenu carbone sont celles de la Base Carbone® de l’ADEME
                                        hébergée à l’adresse suivante : <a href='http://www.bilans-ges-ademe.fr/'>www.bilans-ges-ademe.fr</a></p>
                                    <hr></hr>
                                    <p className='bar1-para'>Coordonnées de l’entreprise ayant effectué l’étude énergétique de l'opération au regard des exigences de la fiche standardisée :</p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Raison sociale :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='b2c_social_reason'
                                                id='b2c_social_reason'
                                                value={values.b2c_social_reason}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.b2c_social_reason && errors.b2c_social_reason ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.b2c_social_reason && errors.b2c_social_reason && <div className="error-message">{errors.b2c_social_reason}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Numéro SIREN : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='b2c_siren_number'
                                                id='b2c_siren_number'
                                                value={values.b2c_siren_number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.b2c_siren_number && errors.b2c_siren_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.b2c_siren_number && errors.b2c_siren_number && <div className="error-message">{errors.b2c_siren_number}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Date de l’étude énergétique :  </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='date_of_energy'
                                                id='date_of_energy'
                                                value={values.date_of_energy}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.date_of_energy && errors.date_of_energy ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.date_of_energy && errors.date_of_energy && <div className="error-message">{errors.date_of_energy}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Référence de l’étude énergétique :  </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='energy_study_reference'
                                                id='energy_study_reference'
                                                value={values.energy_study_reference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.energy_study_reference && errors.energy_study_reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.energy_study_reference && errors.energy_study_reference && <div className="error-message">{errors.energy_study_reference}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>Logiciel de calcul utilisé pour réaliser l’étude énergétique :</p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>* Nom du logiciel et de son éditeur :  </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='software_name'
                                                id='software_name'
                                                value={values.software_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.software_name && errors.software_name ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.software_name && errors.software_name && <div className="error-message">{errors.software_name}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>* Date et n° de version : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='software_version'
                                                id='software_version'
                                                value={values.software_version}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.software_version && errors.software_version ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.software_version && errors.software_version && <div className="error-message">{errors.software_version}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>NB : La liste des logiciels réputés satisfaire aux exigences de la présente fiche est rendue publique sur le site Internet du
                                        ministère chargé de l’énergie, à l’adresse suivante : <a href='https://www.ecologie.gouv.fr/questions-reponses-sur-dispositif-cee'>https://www.ecologie.gouv.fr/questions-reponses-sur-dispositif-cee.</a> </p>
                                    <p className='bar1-para'>NB : Cette opération n’est pas cumulable avec d’autres opérations pouvant donner lieu à la délivrance de certificats d'économies
                                        d'énergie pour des travaux concernant le chauffage, la production d’eau chaude sanitaire, la ventilation, l’isolation de
                                        l’enveloppe de la maison ou les systèmes d’automatisation et de contrôle du bâtiment.</p>
                                    <p className='bar1-para'><u>Qualification ou certification du (ou des) professionnel(s)</u></p>
                                    <p className='bar1-para'>En cas d’intervention de plusieurs professionnels, il convient de dupliquer pour chaque professionnel les informations du
                                        cartouche suivant en précisant le domaine des travaux qu’il a effectué :</p>
                                    <p className='bar1-para'>Identification du professionnel ayant réalisé les travaux :</p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Nom du représentant : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='representative_name'
                                                id='representative_name'
                                                value={values.representative_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.representative_name && errors.representative_name ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.representative_name && errors.representative_name && <div className="error-message">{errors.representative_name}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Prénom :  </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='b2c_first_name'
                                                id='b2c_first_name'
                                                value={values.b2c_first_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.b2c_first_name && errors.b2c_first_name ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.b2c_first_name && errors.b2c_first_name && <div className="error-message">{errors.b2c_first_name}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    {/* <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Raison sociale :   </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='software_version'
                                                id='software_version'
                                                value={values.software_version}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.software_version && errors.software_version ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.software_version && errors.software_version && <div className="error-message">{errors.software_version}</div>}
                                        </div>
                                    </div>
                                    <hr></hr> */}
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*N° SIREN :   </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='siret_number'
                                                id='siret_number'
                                                value={values.siret_number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.siret_number && errors.siret_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.siret_number && errors.siret_number && <div className="error-message">{errors.siret_number}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Domaine des travaux réalisés :    </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='field_work'
                                                id='field_work'
                                                value={values.field_work}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.field_work && errors.field_work ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.field_work && errors.field_work && <div className="error-message">{errors.field_work}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Référence de la qualification ou certification :    </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='certification_reference'
                                                id='certification_reference'
                                                value={values.certification_reference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.certification_reference && errors.certification_reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.certification_reference && errors.certification_reference && <div className="error-message">{errors.certification_reference}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Date : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="date"
                                                name='b2c_date'
                                                id='b2c_date'
                                                value={values.b2c_date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.b2c_date && errors.b2c_date ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>Pour les opérations engagées jusqu’au 31 décembre 2020, la qualification ou certification de l’entreprise est mentionnée dans
                                        le cas où cette dernière a réalisé des travaux entrant dans les domaines définis au I de l’article 46 AX de l’annexe III du code
                                        général des impôts.</p>
                                    <p className='bar1-para'>Pour les opérations engagées à compter du 1er janvier 2021, la qualification ou certification de l’entreprise est mentionnée dans
                                        le cas où cette dernière a réalisé des travaux relevant de l’une au moins des catégories de travaux mentionnées aux 1° à 17° du
                                        I de l’article 1er du décret n° 2014-812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater
                                        du code général des impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris
                                        pour son application.</p>
                                    <hr></hr>
                                    <div className='row mt-5 mb-2'>
                                        <div className='col text-start'>
                                            <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                        </div>
                                        <div className="col text-end">
                                            <button type='submit' className='btns-color'>Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default CalcultBARTH164