import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Formik } from 'formik';
const ControlContributionUpdate = () => {
    const navigate = useNavigate();
    const [storedData, setStoredData] = useState(null);
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    // ----------------------------------------------
    // useEffect(() => {
    //     const storedFormValues = sessionStorage.getItem('step-8');

    //     if (storedFormValues) {
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.contributionFrameDate = parsedFormValues.contributionFrameDate;
    //         initialValues.quoteEditiondate = parsedFormValues.quoteEditiondate;
    //         initialValues.estimateDate = parsedFormValues.estimateDate;
    //         initialValues.quoteSignatureDate = parsedFormValues.quoteSignatureDate;
    //         initialValues.startDateOfWork = parsedFormValues.startDateOfWork;
    //         initialValues.billDate = parsedFormValues.billDate;
    //         initialValues.dateControlOfficeVisit = parsedFormValues.dateControlOfficeVisit;
    //         initialValues.beneficiarySignatureDateOnAH = parsedFormValues.beneficiarySignatureDateOnAH;
    //         initialValues.installerSignatureDateonAH = parsedFormValues.installerSignatureDateonAH;
    //         initialValues.beneficiarySignatureDateonsh = parsedFormValues.beneficiarySignatureDateonsh;
    //     }
    // }, []);
    // useFormik
    const [initialValues, setInitialValues] = useState(
        {
            contributionFrameDate: '',
            quoteEditiondate: '',
            estimateDate: '',
            quoteSignatureDate: '',
            startDateOfWork: '',
            billDate: '',
            dateControlOfficeVisit: '',
            beneficiarySignatureDateOnAH: '',
            installerSignatureDateonAH: '',
            // beneficiarySignatureDateonsh: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            contributionFrameDate: MultiUpdate?.multisteptable2?.contributionframedate || '',
            quoteEditiondate:  MultiUpdate?.multisteptable2?.quotesignaturedate || '',
            estimateDate:  MultiUpdate?.multisteptable2?.estimatedate || '',
            quoteSignatureDate:  MultiUpdate?.multisteptable2?.quotesignaturedate || '',
            startDateOfWork:  MultiUpdate?.multisteptable2?.startofwork || '',
            billDate:  MultiUpdate?.multisteptable2?.b2c_date || '',
            dateControlOfficeVisit:  MultiUpdate?.multisteptable2?.datecontrolofficevisit || '',
            beneficiarySignatureDateOnAH:  MultiUpdate?.multisteptable2?.beneficiarysignaturedateonAH || '',
            installerSignatureDateonAH:  MultiUpdate?.multisteptable2?.installersignaturedateonAH || '',
            // beneficiarySignatureDateonsh:  MultiUpdate?.multisteptable1?.beneficiarysignaturedateonSH || '',
        }));
    }, []);
    const validate = (values) => {
        console.log('values', values);
        const errors = {};

        return errors;
    };
    const handleSubmit = (values) => {
        console.log("Estimation Page Values", values);
        sessionStorage.setItem("step-8", JSON.stringify(values));
        navigate("/update-valider-geoportail");
        window.open('https://www.geoportail.gouv.fr/', '_blank');
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    // useEffect(() => {
    //     const storedFormData = sessionStorage.getItem('step-8');
    //     if (storedFormData) {
    //         setStoredData(JSON.parse(storedFormData));
    //     }
    // }, []);
    const disableDates = (vals) => {
        const contributionDate = new Date(vals);
        contributionDate.setDate(contributionDate.getDate() + 1);

        const yyyy = contributionDate.getFullYear();
        let mm = contributionDate.getMonth() + 1;
        let dd = contributionDate.getDate();

        if (mm < 10) {
            mm = '0' + mm;
        }
        if (dd < 10) {
            dd = '0' + dd;
        }

        return `${yyyy}-${mm}-${dd}`;
    };
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid-fluid'>
                            <div className="row">
                                <div className="col">
                                    <p className='bar1-head'>Contrôle</p>
                                </div>
                            </div>
                            <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2">
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date de cadre de contribution</p>
                                    <input type='date'
                                        name="contributionFrameDate"
                                        value={values.contributionFrameDate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.contributionFrameDate && errors.contributionFrameDate ? 'error' : 'f-left ps-3'} />
                                </div>

                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date édition devis</p>
                                    <input type='date'
                                        name="quoteEditiondate"
                                        value={values.quoteEditiondate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.quoteEditiondate && errors.quoteEditiondate ? 'error' : 'f-left ps-3 '}
                                        min={disableDates(values.contributionFrameDate)} />
                                    {touched.quoteEditiondate && errors.quoteEditiondate && <div className="error-message">{errors.quoteEditiondate}</div>}
                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date de previsite </p>
                                    <input type='date'
                                        name="estimateDate"
                                        value={values.estimateDate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.estimateDate && errors.estimateDate ? 'error' : 'f-left ps-3'}
                                        min={disableDates(values.contributionFrameDate)} />
                                    {touched.estimateDate && errors.estimateDate && <div className="error-message">{errors.estimateDate}</div>}
                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date signature devis</p>
                                    <input type='date'
                                        name="quoteSignatureDate"
                                        value={values.quoteSignatureDate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.quoteSignatureDate && errors.quoteSignatureDate ? 'error' : 'f-left ps-3'}
                                        min={disableDates(values.contributionFrameDate)} />
                                    {touched.quoteSignatureDate && errors.quoteSignatureDate && <div className="error-message">{errors.quoteSignatureDate}</div>}
                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date debut des travaux</p>
                                    <input type='date'
                                        name="startDateOfWork"
                                        value={values.startDateOfWork}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.startDateOfWork && errors.startDateOfWork ? 'error' : 'f-left ps-3'}
                                        min={disableDates(values.contributionFrameDate)} />
                                    {touched.startDateOfWork && errors.startDateOfWork && <div className="error-message">{errors.startDateOfWork}</div>}
                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date de facture</p>
                                    <input type='date'
                                        name="billDate"
                                        value={values.billDate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.billDate && errors.billDate ? 'error' : 'f-left ps-3'}
                                        min={disableDates(values.contributionFrameDate)} />
                                    {touched.billDate && errors.billDate && <div className="error-message">{errors.billDate}</div>}
                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date visite bureau de contrôle</p>
                                    <input type='date'
                                        name="dateControlOfficeVisit"
                                        value={values.dateControlOfficeVisit}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.dateControlOfficeVisit && errors.dateControlOfficeVisit ? 'error' : 'f-left ps-3'}
                                        min={disableDates(values.contributionFrameDate)} />
                                    {touched.dateControlOfficeVisit && errors.dateControlOfficeVisit && <div className="error-message">{errors.dateControlOfficeVisit}</div>}
                                </div>

                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date signature du beneficiare sur AH</p>
                                    <input type='date'
                                        name="beneficiarySignatureDateOnAH"
                                        value={values.beneficiarySignatureDateOnAH}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiarySignatureDateOnAH && errors.beneficiarySignatureDateOnAH ? 'error' : 'f-left ps-3'}
                                        min={disableDates(values.contributionFrameDate)} />
                                    {touched.beneficiarySignatureDateOnAH && errors.beneficiarySignatureDateOnAH && <div className="error-message">{errors.beneficiarySignatureDateOnAH}</div>}
                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date signature de l'installateur sur AH</p>
                                    <input type='date'
                                        name="installerSignatureDateonAH"
                                        value={values.installerSignatureDateonAH}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.installerSignatureDateonAH && errors.installerSignatureDateonAH ? 'error' : 'f-left ps-3'}
                                        min={disableDates(values.contributionFrameDate)} />
                                    {touched.installerSignatureDateonAH && errors.installerSignatureDateonAH && <div className="error-message">{errors.installerSignatureDateonAH}</div>}
                                </div>

                                {/* <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date signature du beneficiare sur AH</p>
                                    <input type='date'
                                        name="beneficiarySignatureDateonsh"
                                        value={values.beneficiarySignatureDateonsh}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiarySignatureDateonsh && errors.beneficiarySignatureDateonsh ? 'error' : 'f-left ps-3'}
                                        min={disableDates(values.contributionFrameDate)} />
                                    {touched.beneficiarySignatureDateonsh && errors.beneficiarySignatureDateonsh && <div className="error-message">{errors.beneficiarySignatureDateonsh}</div>}
                                </div> */}
                            </div>

                            <div className='row mt-5 mb-2 d-flex align-items-center'>
                                <div className='col text-start'>
                                    <button type='button' onClick={handlePrev} className='btns-color'>Précédent </button>
                                </div>

                                <div className='col mt-5 mb-2 text-end'>
                                    <Link to='https://www.geoportail.gouv.fr/' target="_blank">
                                        <button type='submit' className='btns-color2 links' > Valider Geoportail</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default ControlContributionUpdate