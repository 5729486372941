import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ErrorLabels } from '../../arraystore/CountryCodeArray'
const ErrorsPageB2C = (props) => {
    const navigate = useNavigate();
    const MultiUpdate = props.MultiUpdate;
    const [matchedItems, setMatchedItems] = useState([]);
    const filterNullEmptyZeroFalseObjects = (obj) => {
        if (obj === null || obj === '' || obj === '0' || obj === false) {
            return null;
        }

        if (typeof obj === 'object') {
            const filteredObj = {};

            for (const key in obj) {
                const filteredValue = filterNullEmptyZeroFalseObjects(obj[key]);
                if (filteredValue !== null) {
                    filteredObj[key] = filteredValue;
                }
            }

            if (Object.keys(filteredObj).length > 0) {
                return filteredObj;
            }
        }

        return obj;
    };
    useEffect(() => {
        const filteredMultiUpdate = filterNullEmptyZeroFalseObjects(MultiUpdate);
        console.log('Filtered MultiUpdate:', filteredMultiUpdate);

        const filteredMultiUpdateArray = [filteredMultiUpdate];
        console.log('filteredMultiUpdateArray', filteredMultiUpdateArray);

        if (filteredMultiUpdateArray.length > 0) {
            const newMatchedItems = ErrorLabels?.filter((twoItem) => {
                const matchedId = twoItem.id;
                return filteredMultiUpdateArray?.some((multiUpdateItem) => {
                    if (multiUpdateItem && typeof multiUpdateItem === 'object') {
                        return Object.keys(multiUpdateItem).some((key) => {
                            return multiUpdateItem[key]?.hasOwnProperty(matchedId);
                        });
                    }
                    return false;
                });
            });
            setMatchedItems(newMatchedItems);
        }

        console.log('matchedItems', matchedItems);
    }, [MultiUpdate]);
    console.log('matchedItems', matchedItems);
    return (
        <>
            <section className='route-list'>
                <div className="container-fluid px-4 pb-4">
                    <div className="row">
                        <div className="col-md-12">
                            <ul>
                                {matchedItems?.map((errorlabel, i) => (
                                    <li key={i} className='bar1-para-error'><Link className='bar1-para-error'>{errorlabel?.value}</Link></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ErrorsPageB2C