import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Formik } from 'formik';
function B2c() {

    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    // ----------------------------------------------
    useEffect(() => {
        const storedFormValues = sessionStorage.getItem('step-4');

        if (storedFormValues) {
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.chantier_chooseType = parsedFormValues.chantier_chooseType;
            initialValues.nomduclient = parsedFormValues.nomduclient;
            initialValues.prénomduclient = parsedFormValues.prénomduclient;
            initialValues.beneficiary_email = parsedFormValues.beneficiary_email;
            initialValues.nfixe = parsedFormValues.nfixe;
            initialValues.nmobile = parsedFormValues.nmobile;
            initialValues.beneficiary_address = parsedFormValues.beneficiary_address;
            initialValues.beneficiary_cp = parsedFormValues.beneficiary_cp;
            initialValues.beneficiary_city = parsedFormValues.beneficiary_city;
            initialValues.adressedestravaux2 = parsedFormValues.adressedestravaux2;
            initialValues.nfiscal = parsedFormValues.nfiscal;
            initialValues.nreffiscal = parsedFormValues.nreffiscal;
            initialValues.menages = parsedFormValues.menages;
            initialValues.taxnotice = parsedFormValues.taxnotice;
        }
    }, []);
    // useFormik
    const initialValues = {
        chantier_chooseType: '',
        nomduclient: '',
        prénomduclient: '',
        beneficiary_email: '',
        nfixe: '',
        nmobile: '',
        beneficiary_address: '',
        beneficiary_cp: '',
        beneficiary_city: '',
        adressedestravaux2: '',
        nfiscal: '',
        nreffiscal: '',
        menages: '',
        taxnotice: false,
    };
    // VALIDE
    const validate = (values) => {
        console.log({values});
        const errors = {};

        if (!values.chantier_chooseType) {
            errors.chantier_chooseType = 'Mes bénéficiaires requise';
        }

        if (!values.nomduclient) {
            errors.nomduclient = 'Nom du client requise';
        }
        if (!values.prénomduclient) {
            errors.prénomduclient = 'Mes bénéficiaires requise';
        }
        if (!values.beneficiary_email) {
            errors.beneficiary_email = 'email requise';
        }
        if (!values.beneficiary_address) {
            errors.beneficiary_address = 'Adresse des travaux requise';
        }
        if (!values.beneficiary_cp) {
            errors.beneficiary_cp = 'CP des travaux requise';
        } else if (!/^\d{5}$/i.test(values.beneficiary_cp)) {
            errors.beneficiary_cp = 'Cinq chiffres au minimum uniquement';
        }
        if (!values.beneficiary_city) {
            errors.beneficiary_city = 'Ville des travaux requise';
        }
        if (!values.adressedestravaux2) {
            errors.adressedestravaux2 = 'Adresse des travaux requise';
        }

        return errors;
    };

    const handleSubmit = (values) => {
        console.log({ 'values': values });
        sessionStorage.setItem("step-4", JSON.stringify(values));
        navigate("/b2c/opertions");
    };
    const handlePrev = () => {
        navigate(-1);
    };

    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-4');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid px-4'>
                        <p className='bar1-head'>Créer un nouveau dossier / B2C</p>
                        <div className='row'>
                            <div className='col-sm-5 input-box1'>
                                <p className='bar1-para '>*Mes bénéficiaires</p>
                                <div className=''>
                                    <select name='chantier_chooseType'
                                        value={values.chantier_chooseType}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.chantier_chooseType && errors.chantier_chooseType ? 'error' : 'form-select ps-3 btn-buttons'}
                                        aria-label="Default select example">
                                        <option defaultValue>Choisir un type</option>
                                        <option value="Classique">Classique</option>
                                        <option value="QPV">QPV </option>
                                        {/* <option value="Bailleursocial">Bailleur social</option> */}
                                    </select>
                                    {touched.chantier_chooseType && errors.chantier_chooseType && <div className="error-message">{errors.chantier_chooseType}</div>}
                                </div>
                            </div>
                            <div className='col-sm-2 input-box1'>
                                <p className='bar1-para'>*Nom du client</p>
                                <input
                                    type="text"
                                    name="nomduclient"
                                    value={values.nomduclient}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.nomduclient && errors.nomduclient ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.nomduclient && errors.nomduclient && <div className="error-message">{errors.nomduclient}</div>}
                            </div>
                            <div className='col-sm-5 input-box1'>
                                <p className='bar1-para'>*Prénom du client</p>
                                <input type="text"
                                    name="prénomduclient"
                                    value={values.prénomduclient}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.prénomduclient && errors.prénomduclient ? 'error' : 'form-control1 w-100 m-0'} >
                                </input>
                                {touched.prénomduclient && errors.prénomduclient && <div className="error-message">{errors.prénomduclient}</div>}
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col-sm-6 input-box1'>
                                <p className='bar1-para'>*Email</p>
                                <input type="text"
                                    name="beneficiary_email"
                                    value={values.beneficiary_email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.beneficiary_email && errors.beneficiary_email ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.beneficiary_email && errors.beneficiary_email && <div className="error-message">{errors.beneficiary_email}</div>}
                            </div>
                            <div className='col-sm-2 input-box1'>
                                <p className='bar1-para'>N fixe</p>
                                <input type="number"
                                    name="nfixe"
                                    value={values.nfixe}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.nfixe && errors.nfixe ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.nfixe && errors.nfixe && <div className="error-message">{errors.nfixe}</div>}
                            </div>
                            <div className='col-sm-4 input-box1'>
                                <p className='bar1-para'>N mobile</p>
                                <input type="text"
                                    name="nmobile"
                                    value={values.nmobile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.nmobile && errors.nmobile ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.nmobile && errors.nmobile && <div className="error-message">{errors.nmobile}</div>}
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-6 input-box1'>
                                <p className='bar1-para'>*Adresse des travaux</p>
                                <input type="text" name="beneficiary_address"
                                    value={values.beneficiary_address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.beneficiary_address && errors.beneficiary_address ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.beneficiary_address && errors.beneficiary_address && <div className="error-message">{errors.beneficiary_address}</div>}
                            </div>
                            <div className='col-sm-2 input-box1'>
                                <p className='bar1-para'>*CP des travaux</p>
                                <input type="number" name="beneficiary_cp"
                                    value={values.beneficiary_cp}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.beneficiary_cp && errors.beneficiary_cp ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.beneficiary_cp && errors.beneficiary_cp && <div className="error-message">{errors.beneficiary_cp}</div>}
                            </div>
                            <div className='col-sm-4 input-box1'>
                                <p className='bar1-para'>*Ville des travaux</p>
                                <input type="text" name="beneficiary_city"
                                    value={values.beneficiary_city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.beneficiary_city && errors.beneficiary_city ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.beneficiary_city && errors.beneficiary_city && <div className="error-message">{errors.beneficiary_city}</div>}
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-6 input-box1'>
                                <p className='bar1-para'>*Adresse des travaux</p>
                                <input type="text" name="adressedestravaux2"
                                    value={values.adressedestravaux2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.adressedestravaux2 && errors.adressedestravaux2 ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.adressedestravaux2 && errors.adressedestravaux2 && <div className="error-message">{errors.adressedestravaux2}</div>}
                            </div>
                            <div className='col-sm-3 input-box1 text-center'>
                                <div className='ms-2 mt-42'>
                                    <input
                                        type="checkbox" id="taxnotice" name="taxnotice" checked={values.taxnotice} onChange={handleChange} />
                                    <label htmlFor='taxnotice' className='span-bar bar1-para'>
                                        Verifier l’avis d’impot
                                    </label>
                                    {/* {touched.taxnotice && errors.taxnotice && (
                                        <div className="error-message">{errors.taxnotice}</div>
                                    )} */}
                                </div>
                            </div>
                            <div className='col-sm-3 input-box1'>
                                <p className='bar1-para'>*Menages</p>
                                <input type="text" name="menages"
                                    value={values.menages}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.menages && errors.menages ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.menages && errors.menages && <div className="error-message">{errors.menages}</div>}
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-6 input-box1'>
                                <p className='bar1-para'>N° fiscal</p>
                                <input type="text" name="nfiscal"
                                    value={values.nfiscal}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.nfiscal && errors.nfiscal ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.nfiscal && errors.nfiscal && <div className="error-message">{errors.nfiscal}</div>}
                            </div>
                            <div className='col-sm-6 input-box1'>
                                <p className='bar1-para'>N° Ref fiscal</p>
                                <input type="text" name="nreffiscal"
                                    value={values.nreffiscal}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.nfiscal && errors.nreffiscal ? 'error' : 'form-control1 w-100 m-0'}></input>
                                {touched.nreffiscal && errors.nreffiscal && <div className="error-message">{errors.nreffiscal}</div>}
                            </div>
                        </div>
                        <div className='row mt-5 mb-2'>
                            <div className='col text-start'>
                                <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                            </div>
                            <div className="col text-end">
                                <button type='submit' className='btns-color'>Suivant</button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default B2c