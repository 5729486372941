import React from 'react'
import { Link } from "react-router-dom"
const BATEQ133Update = () => {
    return (
        <>
            <div className='container-fluid  '>
                <div className='row'>
                    <p className='bar1-para '>BAT-EQ-133 : Systèmes hydro-économes (France métropolitaine)</p>
                    <div className='button-1 mt-1'>
                        <Link to={"/update-calcult-bateq133"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
                    </div>
                    <div className='col bar1-tittle'>
                        <div className='bar1-tittle'>
                            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                                <h3 className='bar1-head'>1. Secteur d’application</h3>
                                <p className='bar1-para'>Bâtiment tertiaire et habitat communautaire en France métropolitaine.</p>
                                <h3 className='bar1-head'>2. Dénomination</h3>
                                <p className='bar1-para'>Mise en place de pommes de douche hydro-économes et/ou mise en place de régulateurs de jets sur tout ou partie des points de puisage de type lavabo ou évier.</p>
                                <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
                                <p className='bar1-para'>La mise en place est réalisée par un professionnel.<br></br></p>
                                <p className='bar1-para'>L’équipement est répertorié dans l’une des classes de débit suivantes :</p>
                                <p className='bar1-para'>Pour les pommes de douche :<br></br>
                                    <span >– classe Z (7,2 à 12 litres/minute) de la norme EN NF 1112 et avec l’exigence d’un débit maximum à 9 litres/minute à 3 bars de pression ;</span><br></br>
                                    <span >– ou classe ZZ de la norme EN NF 1112 ;</span>
                                    <span >– ou label « EPA Watersense » pour les débits inférieurs à 7,6 litres/minute (2 gallons par minute).</span>
                                    <span >– ou classe ZZ de la norme EN NF 1112 ;</span><br></br></p>
                                <p className='bar1-para'>Pour les régulateurs de jets :<br></br>
                                    <span >– aérateurs non régulés de classe Z (7,5 à 9 litres/minute) de la norme EN NF 246 ;– ou aérateurs autorégulés de débit inférieur à 7,5 litres/minute des normes américaines ASME/ANSI A112.18.1 et NSF 61 et ayant obtenu le label « EPA </span></p>
                                <p className='bar1-para'>Watersense » pour les débits inférieurs à 5,68 litres/minute (1,5 gallon par minute). </p>
                                <p className='bar1-para'>Les matériels sont marqués conformément aux normes NF ou norme NSF labellisée Watersense.La preuve de réalisation de l’opération mentionne le type (pomme de douche ou régulateur de jet), la marque et référence, le nombre et la classe des équipements acquis selon les normes précitées ou le label exigé.</p>
                                <p className='bar1-para'>La performance des équipements est reprise dans une fiche technique du fabricant précisant la marque et référence des équipements et incluant un schéma ou tableau du débit en fonction de la pression de 0 à 5 bars donnant l’efficacité des équipements selon les normes précitées. Cette fiche est archivée par le demandeur.</p>
                                <p className='bar1-head m-0 '>4. Durée de vie conventionnelle</p>
                                <p className='bar1-para '>6 ans.</p>
                                <p className='bar1-head m-0 '>5. Montant de certificats en kWh cumac</p>
                                <p className='bar1-ul bar1-para '>Pommes de douche</p>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center '>
                                                    <th >Classe de pommes de douche</th>
                                                    <th >Montant en kWh cumac per eqipement</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                <tr className='bar1-para text-center p-0'>
                                                    <td className='border-thin p-0'  > Classe Z</td>
                                                    <td className='p-1' >1200</td>
                                                </tr>
                                                <tr className='bar1-para text-center p-0'>
                                                    <td className='border-thin p-0' >Classe ZZ <br />ou<br /> Watersense</td>
                                                    <td className='p-1'>2000</td></tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-lg-1 text-center">
                                        <h1 className='postion-tops text-center'>*</h1>
                                    </div>
                                    <div className="col-lg-3">
                                        <table className="table table-bordered table-border ">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th >Secteur d'activite</th>
                                                    <th >Facteur correctif </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para '> <td>Sante</td>
                                                    <td className='text-center'>0,85</td>
                                                </tr><tr className='bar1-para '><td>Hotelleric et habitat communautaire </td>
                                                    <td className='text-center'>1</td></tr>
                                                <tr className='bar1-para '><td>Etablissements sportifs</td>
                                                    <td className='text-center'>4</td> </tr></tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-1 text-center">
                                        <h1 className='postion-tops text-center'>*</h1>
                                    </div>
                                    <div className="col-lg-2">
                                        <table className="table table-bordered table-border">
                                            <thead></thead>
                                            <tbody>
                                                <tr className='bar1-head text-center'>
                                                    <th className='p-4' colSpan="2" >Nombres de systèmes mis en place</th> </tr>
                                                <tr className='bar1-para text-center h-100 '> <td rowSpan="1" className='pad-50 text-center'>N1</td>
                                                    <td rowSpan="1" className='pad-50 text-center'>N2</td></tr></tbody>
                                        </table></div></div> <p className='bar1-ul bar1-para mt-4'>Aérateurs</p>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <table className="table table-bordered table-border">
                                            <thead>
                                                <tr className='bar1-head text-center '>
                                                    <th >Types d'aerateurs</th>
                                                    <th >Montant en kWh cumac per eqipement</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para text-center p-0'>
                                                    <td className='border-thin p-0'  >Aerateurs non regules Classe Z</td>
                                                    <td className='p-1' >340</td>
                                                </tr>
                                                <tr className='bar1-para text-center p-0'>
                                                    <td className='border-thin p-0' >Aerateurs auto-regules</td>
                                                    <td className='p-1'>630</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-1 text-center">
                                        <h1 className='postion-tops text-center'>*</h1>
                                    </div>
                                    <div className="col-lg-3">
                                        <table className="table table-bordered table-border ">
                                            <thead>
                                                <tr className='bar1-head text-center'>
                                                    <th >Secteur d'activite</th>
                                                    <th >Facteur correctif </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bar1-para '>
                                                    <td>Bureaux</td>
                                                    <td className='text-center'>1,7</td>
                                                </tr>
                                                <tr className='bar1-para '>
                                                    <td>Enseignement</td>
                                                    <td className='text-center'>4,3</td> </tr>
                                                <tr className='bar1-para '> <td>Hotelleric et habitat communautaire </td>
                                                    <td className='text-center'>1,0</td> </tr>
                                                <tr className='bar1-para '> <td>Sante</td>
                                                    <td className='text-center'>0,85</td></tr>
                                                <tr className='bar1-para '> <td>Etablissements sportifs</td>
                                                    <td className='text-center'>0,3</td></tr>
                                                <tr className='bar1-para '> <td>Autres sectcurs</td>
                                                    <td className='text-center'>0,8</td> </tr></tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-1 text-center">
                                        <h1 className='postion-tops text-center'>*</h1>
                                    </div>
                                    <div className="col-lg-2">
                                        <table className="table table-bordered table-border">
                                            <thead> </thead>
                                            <tbody>
                                                <tr className='bar1-head text-center'>
                                                    <th className='p-4' colSpan="2" >Nombres de systèmes mis en place</th></tr>
                                                <tr className='bar1-para text-center h-100 '><td rowSpan="1" className='pad-50 text-center'>N3</td>
                                                    <td rowSpan="1" className='pad-50 text-center'>N4</td> </tr></tbody>
                                        </table></div></div>
                                <p className='bar1-para  '>N1 étant le nombre de pommes de douche de classe Z mis en place.N2 étant le nombre de pommes de douche de classe ZZ ou Watersense mis en place.</p>
                                <p className="bar1-para ">N3 étant le nombre d’aérateurs non régulés de classe Z mis en place sur des lavabos ou éviers.N4 étant le nombre d’aérateurs auto-régulés mis en place sur des lavabos ou éviers. </p>
                                <div className='bar1-content mt-4 pb-4'>
                                    <ul className='ul-bar1 bar1-para '>  <li><span className='bar1-head  '>Secteur d’application : </span>Bâtiment tertiaire et habitat communautaire en France métropolitaine (Bureaux, Enseignement, Hôtellerie, Habitat communautaire, Santé, Établissements sportifs, collectivité publique, autres bâtiments du secteur tertiaire)</li>
                                        <li>Cette opération consiste dans l’acquisition de pommes de douche hydro-économes et/ou mise en place l’acquisition de régulateurs de jets sur tout ou partie des lavabos ou éviers</li>
                                        <li>Les produits possèdent un certificat ACS et sont marqués conformément aux normes NF ou norme NSF labellisée WaterSense.</li>
                                        <li><span className='bar1-head '>Vous ignorez la réponse à une question : </span>Les données de simulation vous donnent le montant le plus exact possible et vous permettent de demander et obtenir une prime. Mais, les données réelles seront collectées auprès du professionnel après travaux et votre prime sera alors automatiquement réévaluée.</li></ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BATEQ133Update