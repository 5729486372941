import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";

const CalcultBARTH148Update = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    // ----------------------------------------------
    // useEffect(() => {
    //     const storedFormValues = sessionStorage.getItem('step-6');

    //     if (storedFormValues) {
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.commitmentdate = parsedFormValues.commitmentdate;
    //         initialValues.dateofproof = parsedFormValues.dateofproof;
    //         initialValues.codepostal = parsedFormValues.codepostal;
    //         initialValues.invoicereference = parsedFormValues.invoicereference;
    //         initialValues.work_name = parsedFormValues.work_name;
    //         initialValues.addressofworks = parsedFormValues.addressofworks;
    //         initialValues.additionaladdress = parsedFormValues.additionaladdress;
    //         initialValues.city = parsedFormValues.city;
    //         initialValues.date_of_commitment = parsedFormValues.date_of_commitment;
    //         initialValues.brand = parsedFormValues.brand;
    //         initialValues.cop = parsedFormValues.cop;
    //         initialValues.housing_type = parsedFormValues.housing_type;
    //         initialValues.installation_type = parsedFormValues.installation_type;
    //         initialValues.reference = parsedFormValues.reference;
    //         initialValues.nom = parsedFormValues.nom;
    //         initialValues.prénom = parsedFormValues.prénom;
    //         initialValues.b2c_social_reason = parsedFormValues.b2c_social_reason;
    //         initialValues.siret_number = parsedFormValues.siret_number;
    //     }
    // }, []);
    const [initialValues, setInitialValues] = useState(
        {
            commitmentdate: '',
            dateofproof: '',
            codepostal: '',
            invoicereference: '',
            work_name: '',
            addressofworks: '',
            additionaladdress: '',
            city: '',
            date_of_commitment: '',
            housing_type: '',
            installation_type: '',
            cop: '',
            brand: '',
            reference: '',
            nom: '',
            prénom: '',
            b2c_social_reason: '',
            siret_number: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            commitmentdate: MultiUpdate?.multisteptable2?.commitmentdate || '',
            dateofproof: MultiUpdate?.multisteptable2?.dateofproof || '',
            codepostal: MultiUpdate?.multisteptable2?.codepostal || '',
            invoicereference: MultiUpdate?.multisteptable2?.invoicereference || '',
            work_name: MultiUpdate?.multisteptable2?.work_name || '',
            addressofworks: MultiUpdate?.multisteptable2?.addressofworks || '',
            additionaladdress: MultiUpdate?.multisteptable2?.additionaladdress || '',
            city: MultiUpdate?.multisteptable2?.city || '',
            date_of_commitment: MultiUpdate?.multisteptable2?.date_of_commitment || '',
            housing_type: MultiUpdate?.multisteptable2?.housing_type || '',
            installation_type: MultiUpdate?.multisteptable2?.installation_type || '',
            cop: MultiUpdate?.multisteptable2?.cop || '',
            brand: MultiUpdate?.multisteptable2?.brand || '',
            reference: MultiUpdate?.multisteptable2?.reference || '',
            nom: MultiUpdate?.multisteptable2?.nom || '',
            prénom: MultiUpdate?.multisteptable2?.prénom || '',
            b2c_social_reason: MultiUpdate?.multisteptable2?.social_reason || '',
            siret_number: MultiUpdate?.multisteptable2?.siret_number || '',
        }));
    }, []);
    const validate = (values) => {
        const errors = {};

        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.commitmentdate) {
            errors.commitmentdate = "Date d’engagement de l'opération (ex : acceptation du devis) requise";
        }
        if (!values.work_name) {
            errors.work_name = "Nom du site des travaux (pour les personnes morales) requise";
        }
        if (!values.addressofworks) {
            errors.addressofworks = "Adresse des travaux requise";
        }
        if (!values.city) {
            errors.city = "Ville requise";
        }
        if (!values.date_of_commitment) {
            errors.date_of_commitment = "Bâtiment résidentiel existant depuis plus de 2 ans à la date d'engagement de l'opération requise";
        }
        if (!values.housing_type) {
            errors.housing_type = "Type de logement requise";
        }
        if (!values.cop) {
            errors.cop = "COP requise";
        }
        if (!values.installation_type) {
            errors.installation_type = "Type d’installation requise";
        }
        if (!values.brand) {
            errors.brand = "Marque requise";
        }
        if (!values.reference) {
            errors.reference = "Référence requise";
        }
        if (!values.nom) {
            errors.nom = "Nom requise";
        }
        if (!values.prénom) {
            errors.prénom = "Prénom requise";
        }
        if (!values.b2c_social_reason) {
            errors.b2c_social_reason = "Raison sociale requise";
        }
        if (!values.siret_number) {
            errors.siret_number = "N° SIREN requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/update-estimate");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    // useEffect(() => {
    //     const storedFormData = sessionStorage.getItem('step-6');
    //     if (storedFormData) {
    //         setStoredData(JSON.parse(storedFormData));
    //     }
    // }, []);
    return (
        <div>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col'>
                                    <p className='bar1-para'>A/ BAR-TH-148 (v. A15.2) : Mise en place d’un chauffe-eau thermodynamique individuel à
                                        accumulation.</p>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="date"
                                                name='commitmentdate'
                                                id='commitmentdate'
                                                value={values.commitmentdate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="date"
                                                name='dateofproof'
                                                id='dateofproof'
                                                value={values.dateofproof}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className='form-control1 mb-0 w-100' ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='invoicereference'
                                                id='invoicereference'
                                                value={values.invoicereference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className='form-control1 mb-0 w-100' ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Nom du site des travaux (pour les personnes morales) :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                name='work_name'
                                                id='work_name'
                                                value={values.work_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.work_name && errors.work_name ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.work_name && errors.work_name && <div className="error-message">{errors.work_name}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Adresse des travaux :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type='text'
                                                name='addressofworks'
                                                id='addressofworks'
                                                value={values.addressofworks}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                            {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type='text'
                                                name='additionaladdress'
                                                id='additionaladdress'
                                                value={values.additionaladdress}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Code postal : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='codepostal'
                                                id='codepostal'
                                                value={values.codepostal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Ville :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='city'
                                                id='city'
                                                value={values.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*Bâtiment résidentiel existant depuis plus de 2 ans à la date d'engagement de l'opération :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="date_of_commitment"
                                                id="date_of_commitment"
                                                onChange={handleChange}
                                                checked={values.date_of_commitment === "Oui"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="date_of_commitment"
                                                id="date_of_commitment"
                                                onChange={handleChange}
                                                checked={values.date_of_commitment === "Non"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.date_of_commitment && errors.date_of_commitment && <div className="error-message">{errors.date_of_commitment}</div>}
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*Type de logement :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Maison_individuelle"
                                                name="housing_type"
                                                id="housing_type"
                                                onChange={handleChange}
                                                checked={values.housing_type === "Maison_individuelle"} />
                                            <span className='span-bar bar1-para'>Maison individuelle</span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Appartement"
                                                name="housing_type"
                                                id="housing_type"
                                                onChange={handleChange}
                                                checked={values.housing_type === "Appartement"} />
                                            <span className='span-bar bar1-para '>Appartement</span>
                                        </label>
                                    </div>
                                    {touched.housing_type && errors.housing_type && <div className="error-message">{errors.housing_type}</div>}
                                    <hr></hr>
                                    <p className='bar1-para'>Caractéristiques du chauffe-eau thermodynamique :</p>
                                    <p className="bar1-head  mt-4">*Type d’installation : </p>
                                    <div className='text-start'>
                                        <label className='para-bar1'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="sur air extrait"
                                                name="installation_type"
                                                id="installation_type"
                                                onChange={handleChange}
                                                checked={values.installation_type === "sur air extrait"} />
                                            <span className='span-bar bar1-para '> sur air extrait </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="autres types d’installations"
                                                name="installation_type"
                                                id="installation_type"
                                                onChange={handleChange}
                                                checked={values.installation_type === "autres types d’installations"} />
                                            <span className='span-bar bar1-para '> autres types d’installations </span>
                                        </label>
                                    </div>
                                    <hr></hr>
                                    {touched.installation_type && errors.installation_type && <div className="error-message">{errors.installation_type}</div>}
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*COP </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='cop'
                                                id='cop'
                                                value={values.cop}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.cop && errors.cop ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.cop && errors.cop && <div className="error-message">{errors.cop}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>NB : Le coefficient de performance (COP) de l’équipement est mesuré conformément aux conditions de la
                                        norme EN 16147. </p>
                                    <p className='bar1-para'>À ne remplir que si les marque et référence du chauffe-eau thermodynamique ne sont pas mentionnées sur la
                                        preuve de réalisation de l’opération : </p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Marque :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='brand'
                                                id='brand'
                                                value={values.brand}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Référence : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='reference'
                                                id='reference'
                                                value={values.reference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>Si le bénéficiaire est une personne physique, le professionnel qui procède à la réalisation de l’opération est
                                        titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues à l’article 2 du décret n° 2014-
                                        812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des
                                        impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris pour
                                        son application. <br></br> Ce signe de qualité correspond à des travaux relevant du 7 du I de l’article 46 AX de l’annexe III du code général
                                        des impôts </p>
                                    <p className='bar1-para'>Identité du professionnel titulaire du signe de qualité ayant réalisé l'opération, s'il n’est pas le signataire de cette
                                        attestation (sous-traitance par exemple) :</p>

                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Nom</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='nom'
                                                id='nom'
                                                value={values.nom}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.nom && errors.nom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.nom && errors.nom && <div className="error-message">{errors.nom}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Prénom</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='prénom'
                                                id='prénom'
                                                value={values.prénom}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.prénom && errors.prénom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.prénom && errors.prénom && <div className="error-message">{errors.prénom}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Raison sociale : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='b2c_social_reason'
                                                id='b2c_social_reason'
                                                value={values.b2c_social_reason}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.b2c_social_reason && errors.b2c_social_reason ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.b2c_social_reason && errors.b2c_social_reason && <div className="error-message">{errors.b2c_social_reason}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*N° SIREN :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='siret_number'
                                                id='siret_number'
                                                value={values.siret_number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.siret_number && errors.siret_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.siret_number && errors.siret_number && <div className="error-message">{errors.siret_number}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className='row mt-5 mb-2'>
                                        <div className='col text-start'>
                                            <button type='button' onClick={handlePrev} className=' btns-color'>Précédent</button>
                                        </div>
                                        <div className="col text-end">
                                            <button type='submit' className='btns-color'>Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default CalcultBARTH148Update
