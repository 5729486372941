import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { Formik } from 'formik';
const AttestationUpdate = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    // ----------------------------------------------
    // useEffect(() => {
    //     const storedFormValues = sessionStorage.getItem('step-12');

    //     if (storedFormValues) {
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.attestation_ahFormatCompliant = parsedFormValues.attestation_ahFormatCompliant;
    //         initialValues.attestation_fFullCFrame = parsedFormValues.attestation_fFullCFrame;
    //         initialValues.attestation_fullAframe = parsedFormValues.attestation_fullAframe;
    //         initialValues.attestation_signatureAndStamp = parsedFormValues.attestation_signatureAndStamp;
    //         initialValues.attestation_completeBframe = parsedFormValues.attestation_completeBframe;
    //         initialValues.attestation_completeQbvsFrame = parsedFormValues.attestation_completeQbvsFrame;
    //         initialValues.attestation_beneficiarysSignatureandStamp = parsedFormValues.attestation_beneficiarysSignatureandStamp;
    //     }
    // }, []);
    const [initialValues, setInitialValues] = useState(
        {
            attestation_ahFormatCompliant: false,
            attestation_fFullCFrame: false,
            attestation_fullAframe: false,
            attestation_signatureAndStamp: false,
            attestation_completeBframe: false,
            attestation_completeQbvsFrame: false,
            attestation_beneficiarysSignatureandStamp: false,
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            attestation_ahFormatCompliant: Boolean(MultiUpdate?.multisteptable1?.estimate_installer_contactdetails) || false,
            attestation_fFullCFrame: Boolean(MultiUpdate?.multisteptable1?.estimate_installer_contactdetails) || false,
            attestation_fullAframe: Boolean(MultiUpdate?.multisteptable1?.estimate_installer_contactdetails) || false,
            attestation_signatureAndStamp: Boolean(MultiUpdate?.multisteptable1?.estimate_installer_contactdetails) || false,
            attestation_completeBframe: Boolean(MultiUpdate?.multisteptable1?.estimate_installer_contactdetails) || false,
            attestation_completeQbvsFrame: Boolean(MultiUpdate?.multisteptable1?.estimate_installer_contactdetails) || false,
            attestation_beneficiarysSignatureandStamp: Boolean(MultiUpdate?.multisteptable1?.estimate_installer_contactdetails) || false,
        }));
    }, []);
    // VALIDE
    const validate = (values) => {
        console.log("validate", values);
        const errors = {};

        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-12", JSON.stringify(values));
        navigate('/update-error');
    }
    // BACK BUTTON
    const handlePrev = () => {
        navigate(-1);
    };
    // useEffect(() => {
    //     const storedFormData = sessionStorage.getItem('step-12');
    //     if (storedFormData) {
    //         setStoredData(JSON.parse(storedFormData));
    //     }
    // }, []);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid-fluid pl-30 mt-3 '>
                            <p className='bar1-head  '> Attestation sur l’Honneur </p>
                            <div className="row ">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_ahFormatCompliant" name="attestation_ahFormatCompliant" checked={values.attestation_ahFormatCompliant} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_ahFormatCompliant">Format AH conforme </label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_fFullCFrame" name="attestation_fFullCFrame" checked={values.attestation_fFullCFrame} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_fFullCFrame">Cadre(s) C complet</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_fullAframe" name="attestation_fullAframe" checked={values.attestation_fullAframe} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_fullAframe">Cadre(s) A complet</label>
                                    </div>
                                </div>
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_signatureAndStamp" name="attestation_signatureAndStamp" checked={values.attestation_signatureAndStamp} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_signatureAndStamp">Signature et cachet du professionnel</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_completeBframe" name="attestation_completeBframe" checked={values.attestation_completeBframe} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_completeBframe">Cadre(s) B complet</label>
                                    </div>
                                </div>

                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_completeQbvsFrame" name="attestation_completeQbvsFrame" checked={values.attestation_completeQbvsFrame} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_completeQbvsFrame">Cadre QPV / BS complet</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col input-box1">
                                    <div className="text-start ">
                                        <input className="form-check-input " type="checkbox" id="attestation_beneficiarysSignatureandStamp" name="attestation_beneficiarysSignatureandStamp" checked={values.attestation_beneficiarysSignatureandStamp} onChange={handleChange} />
                                        <label className="form-check-label bar1-para ms-2" htmlFor="attestation_beneficiarysSignatureandStamp">Signature et cachet du bénéficiaire</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5 mb-2'>
                                <div className='col text-start'>
                                    <button type='button' onClick={handlePrev} className=' btns-color' >Précédent </button>
                                </div>
                                <div className="col text-end">
                                    <button type='submit' className='btns-color' >Créer</button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default AttestationUpdate