import React from 'react'
import { Link } from "react-router-dom"
const BARSE104Update = () => {
  return (
    <>
    <div className='container-fluid'>
      <div className='row'>
        <p className='bar1-para '>BAR-SE-104 : Réglage des organes d’équilibrage d’une installation de chauffage à eau chaude </p>
        <div className='button-1 mt-1'>
          <Link to={"/update-calcult-barse104"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
        </div>
        <div className='col bar1-tittle'>
          <div className='bar1-tittle'>
            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
              <h3 className='bar1-head'>1. Secteur d’application</h3>
              <p className='bar1-para'>Appartements existants équipés d’une installation collective de chauffage à eau chaude.</p>
              <h3 className='bar1-head'>2. Dénomination</h3>
              <p className='bar1-para'>Réglage des organes d’équilibrage d’une installation de chauffage à eau chaude, destiné à assurer une température uniforme dans tous les locaux.Une installation collective de chauffage à eau chaude est considérée comme équilibrée si l’écart de température entre le logement le plus chauffé et le moins chauffé d’un même bâtiment est strictement inférieur à 2°C.</p>
              <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
              <p className='bar1-para m-0'>Le réglage des organes d’équilibrage, en pied de colonne et/ou au niveau des locaux, est réalisé par un professionnel.<br></br>            </p>
              <p className='bar1-para'>La preuve de la réalisation de l’opération mentionne le réglage des organes d’équilibrage.</p>
              <p className='bar1-para'>Les documents justificatifs spécifiques à l’opération sont :<br></br>
                <span >– un schéma hydraulique simplifié des installations de chauffage précisant l’implantation de toutes les vannes réglées et étiquetées sur site ;
                </span><br></br>
                <span >– une grille d’équilibrage dans laquelle sont précisés, pour chacune des vannes réglées :</span><br></br>
                <span >– le numéro de repérage ;</span><br></br>
                <span >– la marque et référence ou les caractéristiques hydrauliques (tableau de pertes de charge ou équivalent) de chaque </span><br></br>
              </p>
              <p className='bar1-para'>L’isolation est effectuée sur un réseau non isolé ou dont l’isolation existante est de classe inférieure ou égale à 2 selon la norme NF EN 12 828+A1:2014.</p>
              <p className='bar1-para'>L’isolant mis en place est de classe supérieure ou égale à 3 selon la norme NF EN 12 828+A1:2014.</p>
              <p className='bar1-para'>La preuve de réalisation de l’opération mentionne :<br></br>
                <span >– la mise en place d’une isolation sur un réseau hydraulique de chauffage ou d’ECS existant ; </span><br></br>
                <span >– la longueur isolée de réseau hors des volumes chauffés ;– les marque et référence de l’isolant installé ; </span><br></br>
                <span >– la classe de l’isolant installé selon la norme NF EN 12 828+A1:2014 ; </span><br></br>
                <span >– le cas échéant, la dépose de l’ancien isolant. </span>
              </p>
              <p className='bar1-para'>Les travaux d’isolation du réseau de chauffage ou d’ECS font l’objet, après réalisation, d’un contrôle par un organisme d’inspection. Un rapport de conformité établi par cet organisme atteste la vérification :<br></br>
                <span >– de la mise en place d’une isolation sur un réseau hydraulique existant de chauffage ou d’ECS ; </span><br></br>
                <span >– des caractéristiques de l’isolant mis en place : </span><br></br>
                <span >– marque et référence ; </span><br></br>
                <span >– et épaisseur ; </span><br></br>
                <span >– et classe selon la norme NF EN 12 828 + A1:2014 ; </span>
              </p>
              <p className='bar1-para'>type et diamètre de vanne réglée ;<br></br>
                <span >– le débit théorique visé ou, pour une température de départ donnée, la température de retour théorique visée ; </span><br></br>
                <span >– le débit final mesuré ou, pour une température de départ donnée, la température de retour finale mesurée ; </span><br></br>
                <span >– la valeur finale de réglage (nombre de tour, graduations ou équivalent). </span><br></br>
                <span >– un tableau d’enregistrement des températures moyennes sur un échantillon des logements, après équilibrage. </span><br></br></p>
              <p className='bar1-para'>L’écart de température entre l’appartement le plus chauffé et le moins chauffé doit être strictement inférieur à 2°C.Ces documents sont datés et signés par le professionnel, le tableau d’enregistrement des températures après équilibrage est, de plus, daté et signé par le bénéficiaire. </p>

              <p className='bar1-head m-0 '>4. Durée de vie conventionnelle</p>
              <p className='bar1-para '>10 ans.</p>
              <p className='bar1-head m-0 '>5. Montant de certificats en kWh cumac</p>
              <div className="row">
                <div className="col-4">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th >Zone climatique</th>
                        <th>Montant en kWh cumac par appartement</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center'><td>H1</td>
                        <td>9 800</td> </tr>
                      <tr className='bar1-para text-center'><td>H2</td>
                        <td>8 000</td></tr>
                      <tr className='bar1-para text-center'><td>H3</td><td>5 300</td></tr></tbody>
                  </table>
                </div>
                <div className="col-lg-1 ">
                  <h1 className='postion-tops '>*</h1>
                </div>
                <div className="col-lg-3">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'><th colSpan="3">Nombre d'appartements</th></tr>
                    </thead>
                    <tbody><tr className='bar1-para text-center h-100'><td className='pad-50 text-center'>N</td></tr></tbody>
                  </table>
                  </div>
              </div>
              <div className='bar1-content mt-4 pb-2'>
                <p className='bar1-para para-bar1 m-0'><span className='bar1-head '>Éligible :</span> Réglage, par un professionnel, des organes d’équilibrage d’une installation de chauffage à eau chaude dans un bâtiment résidentiel collectif existant avec un écart de température inférieur à 2°C. entre le logement le plus chauffé et le moins chauffé. </p>
                <p className='bar1-para para-bar1 '> Les documents justificatifs à produire sont : Schéma hydraulique simplifié, Grille d’équilibrage des vannes réglées, Tableau d’enregistrement des températures moyennes après équilibrage. </p>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Si vous ignorez la réponse à une question :</span> les données de simulation vous donnent le montant le plus exact possible et vous permettent de demander et obtenir une prime. Après travaux, les données réelles seront collectées auprès du professionnel et votre prime sera alors automatiquement réévaluée. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default BARSE104Update