import React from 'react'

const Empty404 = () => {
    return (
        <>
        <h1>404</h1>
        </>
    )
}

export default Empty404