export const ZoneClimatique = [
    { id: '01', value: 'H1' },
    { id: '02', value: 'H1' },
    { id: '03', value: 'H1' },
    { id: '05', value: 'H1' },
    { id: '08', value: 'H1' },
    { id: '10', value: 'H1' },
    { id: '14', value: 'H1' },
    { id: '15', value: 'H1' },
    { id: '19', value: 'H1' },
    { id: '21', value: 'H1' },
    { id: '23', value: 'H1' },
    { id: '25', value: 'H1' },
    { id: '27', value: 'H1' },
    { id: '28', value: 'H1' },
    { id: '38', value: 'H1' },
    { id: '39', value: 'H1' },
    { id: '42', value: 'H1' },
    { id: '43', value: 'H1' },
    { id: '45', value: 'H1' },
    { id: '51', value: 'H1' },
    { id: '52', value: 'H1' },
    { id: '54', value: 'H1' },
    { id: '55', value: 'H1' },
    { id: '57', value: 'H1' },
    { id: '58', value: 'H1' },
    { id: '59', value: 'H1' },
    { id: '60', value: 'H1' },
    { id: '61', value: 'H1' },
    { id: '62', value: 'H1' },
    { id: '63', value: 'H1' },
    { id: '67', value: 'H1' },
    { id: '68', value: 'H1' },
    { id: '69', value: 'H1' },
    { id: '70', value: 'H1' },
    { id: '71', value: 'H1' },
    { id: '73', value: 'H1' },
    { id: '74', value: 'H1' },
    { id: '75', value: 'H1' },
    { id: '76', value: 'H1' },
    { id: '77', value: 'H1' },
    { id: '78', value: 'H1' },
    { id: '80', value: 'H1' },
    { id: '87', value: 'H1' },
    { id: '88', value: 'H1' },
    { id: '89', value: 'H1' },
    { id: '90', value: 'H1' },
    { id: '91', value: 'H1' },
    { id: '92', value: 'H1' },
    { id: '93', value: 'H1' },
    { id: '94', value: 'H1' },
    { id: '95', value: 'H1' },
    // H2'
    { id: '04', value: 'H2' },
    { id: '07', value: 'H2' },
    { id: '09', value: 'H2' },
    { id: '12', value: 'H2' },
    { id: '16', value: 'H2' },
    { id: '17', value: 'H2' },
    { id: '18', value: 'H2' },
    { id: '22', value: 'H2' },
    { id: '24', value: 'H2' },
    { id: '26', value: 'H2' },
    { id: '29', value: 'H2' },
    { id: '31', value: 'H2' },
    { id: '32', value: 'H2' },
    { id: '33', value: 'H2' },
    { id: '35', value: 'H2' },
    { id: '36', value: 'H2' },
    { id: '37', value: 'H2' },
    { id: '40', value: 'H2' },
    { id: '41', value: 'H2' },
    { id: '44', value: 'H2' },
    { id: '46', value: 'H2' },
    { id: '47', value: 'H2' },
    { id: '48', value: 'H2' },
    { id: '49', value: 'H2' },
    { id: '50', value: 'H2' },
    { id: '53', value: 'H2' },
    { id: '64', value: 'H2' },
    { id: '65', value: 'H2' },
    { id: '72', value: 'H2' },
    { id: '79', value: 'H2' },
    { id: '81', value: 'H2' },
    { id: '82', value: 'H2' },
    { id: '84', value: 'H2' },
    { id: '85', value: 'H2' },
    { id: '86', value: 'H2' },
    // H3'
    { id: '06', value: 'H3' },
    { id: '11', value: 'H3' },
    { id: '13', value: 'H3' },
    { id: '20', value: 'H3' },
    { id: '30', value: 'H3' },
    { id: '34', value: 'H3' },
    { id: '66', value: 'H3' },
    { id: '83', value: 'H3' },
]
export const ErrorLabels = [
    { id: 'beneficiary_socialReason', value: 'Raison sociale', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_nomCommercial', value: 'Nom commercial', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_nSiret', value: 'N Siret', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_representativeName', value: 'Représentant / Nom', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_firstName', value: 'Prenom', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_hisFunction', value: 'Sa fonction', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_address', value: 'Adresse', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_cp', value: 'CP', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_city', value: 'Ville', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_email', value: 'Email', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_nFixed', value: 'N fixe', url: '/create-dossier/b2b-beneficiaire' },
    { id: 'beneficiary_nMobile', value: 'N mobile', url: '/create-dossier/b2b-beneficiaire' },
    // Work site
    { id: 'chantier_address', value: 'Adresse', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_cp', value: 'CP', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_city', value: 'Ville', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_wardName', value: 'Nom de quartier', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_chooseType', value: 'Type de quartier', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_districtCode', value: 'Code de quartier', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_contact', value: 'Contact', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_telephone', value: 'Telephone', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_ageOfBuilding', value: 'Type de batiment', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_heatingMode', value: 'Age de(s) batiment(s)', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_buildingFloorArea', value: 'Mode de chauffage', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_typeOfBuilding', value: 'Surface au sol du batiment', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_greatPrecariousness', value: 'Grande precarite', url: '/create-dossier/b2b-chantier' },
    { id: 'chantier_precarious', value: 'Surface au sol du batiment', url: '/create-dossier/b2b-chantier' },
    // B2c
    { id: 'nomduclient', value: 'Nom du client', url: '/create-dossier/b2c' },
    { id: 'prénomduclient', value: 'Prénom du client', url: '/create-dossier/b2c' },
    { id: 'nfixe', value: 'N fixe', url: '/create-dossier/b2c' },
    { id: 'nmobile', value: 'N mobile', url: '/create-dossier/b2c' },
    { id: 'adressedestravaux', value: 'Adresse des travaux', url: '/create-dossier/b2c' },
    { id: 'cpdestravaux', value: 'CP des travaux', url: '/create-dossier/b2c' },
    { id: 'villedestravaux', value: 'Ville des travaux', url: '/create-dossier/b2c' },
    { id: 'adressedestravaux2', value: 'Adresse des travaux', url: '/create-dossier/b2c' },
    { id: 'nfiscal', value: 'N° fiscal', url: '/create-dossier/b2c' },
    { id: 'nreffiscal', value: 'N° Ref fiscal', url: '/create-dossier/b2c' },
    { id: 'menages', value: 'Menages', url: '/create-dossier/b2c' },
    { id: 'taxnotice', value: 'Verifier l’avis d’impot', url: '/create-dossier/b2c' },
    // Calcult
    { id: 'roof', value: 'Le bénéficiaire de cette opération est', url: '/calcult' },
    { id: 'buildingmorethan2years', value: 'Le bâtiment a plus de 2 ans ?', url: '/calcult' },
    { id: 'codepostal', value: 'Code postal de l adresse des travaux', url: '/calcult' },
    { id: 'thermalinsulation', value: 'L’opération consiste en la mise en place d’un procédé d’isolation thermique', url: '/calcult' },
    { id: 'heatedareaandanunheated', value: 'Le procédé d’isolation thermique est effectué entre une zone chauffée et une zone non chauffée', url: '/calcult' },
    { id: 'surface', value: 'Surface totale isolée (en m²)' },
    { id: 'heatedareaandanunheated2', value: 'Le procédé d’isolation thermique est effectué entre une zone chauffée et une zone non chauffée ?', url: '/calcult' },
    { id: 'order', value: 'Pour lutter contre la fraude, le début des travaux ne peut commencer que 8 jours après la date de commande', url: '/calcult' },
    // BAR-EN-104
    { id: 'beneficiaryOperation', value: 'Le bénéficiaire de cette opération est', url: '/calcult4' },
    { id: 'buildingmorethan2years', value: 'Bâtiment résidentiel existant depuis plus de 2 ans', url: '/calcult4' },
    { id: 'heatingEnergy', value: 'Énergie de chauffage utilisée', url: '/calcult4' },
    { id: 'windowsorpatiodoors', value: 'Combien de fenêtres ou porte-fenêtres à remplacer', url: '/calcult4' },
    { id: 'roofwindows', value: 'Combien de fenêtres de toiture (type Velux) à remplacer', url: '/calcult4' },
    // BAR-SE-104
    { id: 'residentialBuilding', value: 'Bâtiment résidentiel existant depuis plus de 2 ans', url: '/calcultse104' },
    { id: 'noofApartment', value: 'Nombre d’appartements équilibrés', url: '/calcultse104' },
    { id: 'premiumamount', value: 'Définir le montant de la prime', url: '/calcultse104' },
    // BAR-TH-160
    { id: 'signature', value: 'Date du devis de signature', url: '/calcult160' },
    { id: 'residential_2years_old', value: 'Bâtiment(s) résidentiel(s) collectif de plus de 2 ans ?', url: '/calcult160' },
    { id: 'installation', value: 'L’opération consiste en la mise en place ?', url: '/calcult160' },
    { id: 'hydraulicheating', value: 'Le réseau hydraulique de chauffage ou d’ECS est installé depuis plus de 2 ans ?', url: '/calcult160' },
    { id: 'hydraulicnetwork', value: 'Longueur totale du (ou des) réseau(x) hydraulique(s) isolé(s) (en m linéaire)', url: '/calcult160' },
    { id: 'replaced_after_2018', value: 'La chaudière ou le réseau hydraulique a été refait/remplacé après le 01/01/2018 ?', url: '/calcult160' },
    // BAR-TH-161
    { id: 'residentieltwoyears', value: 'Opération dans un bâtiment RESIDENTIEL existant depuis plus de 2 ans ', url: '/calcult161' },
    { id: 'pipes_insulated', value: 'Les canalisations existantes sont déjà isolées ?', url: '/calcult161' },
    { id: 'singular_points', value: 'Les points singuliers à isoler sont tous situés dans une chaufferie ou dans une sous station ?', url: '/calcult161' },
    { id: 'covers_system', value: 'Les housses sont souples, démontables et équipées d’un système de fermeture ?', url: '/calcult161' },
    { id: 'cover_insulation', value: 'La housse est constituée d’un isolant à base laine de verre ou de roche ?', url: '/calcult161' },
    { id: 'diameter20to65V1', value: 'D’un diamètre nominal compris entre 20 mm et 65 mm (Qt)', url: '/calcult161' },
    { id: 'diameter66to100V1', value: 'D’un diamètre nominal compris entre 66 mm et 100 mm (Qt)', url: '/calcult161' },
    { id: 'diameter101to150V1', value: 'D’un diamètre nominal compris entre 101 mm et 150 mm (Qt)', url: '/calcult161' },
    { id: 'heatexchangerV1', value: 'Sur échangeur à plaque (Qt)', url: '/calcult161' },
    { id: 'diameter20to65V2', value: 'D’un diamètre nominal compris entre 20 mm et 65 mm (Qt)', url: '/calcult161' },
    { id: 'diameter66to100V2', value: 'D’un diamètre nominal compris entre 66 mm et 100 mm (Qt)', url: '/calcult161' },
    { id: 'diameter101to150V2', value: 'D’un diamètre nominal compris entre 101 mm et 150 mm (Qt)', url: '/calcult161' },
    { id: 'heatexchangerV2', value: 'Sur échangeur à plaque (Qt)', url: '/calcult161' },
    // RES-EC-104
    { id: 'dateofcommitment', value: "Date d’engagement de l'opération (ex : date d’acceptation du devis)", url: '/RES-EC-104-Calcult' },
    { id: 'dateofproof', value: 'Date de preuve de réalisation de l’opération (ex : date de la facture)', url: '/RES-EC-104-Calcult' },
    { id: 'invoicereference', value: 'Référence de la facture', url: '/RES-EC-104-Calcult' },
    { id: 'addressofwork', value: 'Adresse des travaux (périmètre précis de réalisation de l’opération)', url: '/RES-EC-104-Calcult' },
    { id: 'additionaladdress', value: 'Complément d’adresse', url: '/RES-EC-104-Calcult' },
    { id: 'luminaires', value: "Les luminaires déposés sont existants depuis plus de 2 ans à la date d'engagement de l'opération", url: '/RES-EC-104-Calcult' },
    { id: 'lighting', value: 'Type d’éclairage', url: '/RES-EC-104-Calcult' },
    { id: 'brand', value: 'Marque', url: '/RES-EC-104-Calcult' },
    { id: 'reference', value: 'Référence', url: '/RES-EC-104-Calcult' },
    { id: 'model', value: 'Modèle', url: '/RES-EC-104-Calcult' },
    { id: 'types', value: 'Type', url: '/RES-EC-104-Calcult' },
    { id: 'noofnewlights', value: 'Nombre de luminaires neufs installés', url: '/RES-EC-104-Calcult' },
    { id: 'brandv2', value: 'Marque', url: '/RES-EC-104-Calcult' },
    { id: 'modelv2', value: 'Modèle', url: '/RES-EC-104-Calcult' },
    { id: 'typev2', value: 'Type', url: '/RES-EC-104-Calcult' },
    { id: 'noofnewlightsv2', value: 'Nombre de luminaires neufs installés', url: '/RES-EC-104-Calcult' },
    { id: 'city', value: 'Ville', url: '/RES-EC-104-Calcult' },
    // BAT-EN-101
    { id: 'tertiarysector', value: 'Bâtiment secteur tertiaire existant depuis plus de 2 ans ?', url: '/calcult101' },
    { id: 'typeofpose', value: 'Type de pose ?', url: '/calcult101' },
    { id: 'buildingindustry', value: 'Secteur d activité du bâtiment', url: '/calcult101' },
    { id: 'startofwork', value: 'Pour lutter contre la fraude, le début des travaux ne peut commencer que 8 jours après la date de commande ?', url: '/calcult101' },
    { id: 'twelvemonths', value: 'Aucune opération identique n’a été réalisée sur ce bâtiment depuis douze mois ?', url: '/calcult101' },
    { id: 'thermalinsulation', value: 'L’isolation est effectuée entre une zone chauffée et une zone non chauffée ?', url: '/calcult101' },
    // BAT-TH-116
    { id: 'signature', value: 'Date du devis de signature', url: '/calcult116' },
    { id: 'bmssystem', value: 'S’agit-il d’un simple raccordement ou extension d’un bâtiment à un système de GTB existant ?', url: '/calcult116' },
    { id: 'operationconcern', value: 'L’opération concerne ?', url: '/calcult116' },
    { id: 'newBMSsystem', value: 'Le nouveau système de GTB est ?', url: '/calcult116' },
    { id: 'managedBMSsystem', value: 'Usage géré par le système GTB', url: '/calcult116' },
    { id: 'premiumamount', value: 'Définir le montant de la prime', url: '/calcult116' },
    //    BAT-TH-146
    { id: 'replaced_after_2018', value: 'La chaudière ou le réseau hydraulique a été refait/remplacé après le 01/01/2018 ?', url: '/calcult146' },
    { id: 'oldinsulation', value: 'Une ancienne isolation existe sur ces canalisations ?', url: '/calcult146' },
    //    BAT-TH-104 
    { id: 'tertiaryBuilding', value: 'Bâtiment Tertiaire de plus de 2 ans ?', url: '/calcult104' },
    { id: 'newradiators', value: 'Installation sur des radiateurs neufs ?', url: '/calcult104' },
    { id: 'boiler', value: 'La chaudière est existante depuis plus de 2 ans ?', url: '/calcult104' },
    // BAT-EQ-133
    { id: 'classZshowerheads', value: 'Nombre de pommes de douche de classe Z installées', url: '/bat133-calcult' },
    { id: 'classZZshowerheads', value: 'Nombre de pommes de douche de classe ZZ ou « WaterSense » installées', url: '/bat133-calcult' },
    { id: 'classZunregulated', value: 'Nombre d’aérateurs de robinet non régulés de classe Z installés', url: '/bat133-calcult' },
    { id: 'classZZunregulated', value: 'Nombre d’aérateurs de robinet autorégulés installés', url: '/bat133-calcult' },
    { id: 'kilowater', value: 'Définir le montant de la prime', url: '/bat133-calcult' },
    // BAR-TH-164
    { id: 'commitmentdate', value: 'Date d’engagement de l opération (ex : acceptation du devis)', url: '/calcult-bar-th-164' },
    { id: 'legalpersons', value: 'Pour les personnes morales, nom du site des travaux ou nom de la copropriété', url: '/calcult-bar-th-164' },
    { id: 'addressofworks', value: 'Adresse des travaux', url: '/calcult-bar-th-164' },
    { id: 'individual_house', value: 'Maison individuelle existant depuis plus de 2 ans à la date dengagement de lopération', url: '/calcult-bar-th-164' },
    { id: 'heatpump_surface', value: 'Surface habitable de la maison rénovée Shab (m²)', url: '/calcult-bar-th-164' },
    { id: 'cep_initial', value: 'Consommation conventionnelle en énergie primaire avant les travaux de rénovation : Cep initial (kWh/m².an)', url: '/calcult-bar-th-164' },
    { id: 'cep_projet', value: 'Consommation conventionnelle en énergie primaire après les travaux de rénovation : Cep projet (kWh/m².an)', url: '/calcult-bar-th-164' },
    { id: 'cef_initial', value: 'Consommation conventionnelle en énergie finale avant les travaux de rénovation : Cef initial (kWh/m².an)', url: '/calcult-bar-th-164' },
    { id: 'cef_projet', value: 'Consommation conventionnelle en énergie finale après les travaux de rénovation : Cef projet (kWh/m².an)', url: '/calcult-bar-th-164' },
    { id: 'energy_gain', value: 'Gain énergétique du projet par rapport à la consommation conventionnelle en énergie primaire avant travaux', url: '/calcult-bar-th-164' },
    { id: 'greenhouse', value: 'Les émissions de gaz à effet de serre après rénovation, exprimées en kgeqCO2/m².an, sont inférieures ou égales à la valeur initiale de ces émissions avant travaux', url: '/calcult-bar-th-164' },
    { id: 'b2c_social_reason', value: 'Raison sociale', url: '/calcult-bar-th-164' },
    { id: 'b2c_siren_number', value: 'Numéro SIREN', url: '/calcult-bar-th-164' },
    { id: 'date_of_energy', value: 'Date de l’étude énergétique', url: '/calcult-bar-th-164' },
    { id: 'energy_study_reference', value: 'Référence de l’étude énergétique', url: '/calcult-bar-th-164' },
    { id: 'software_name', value: 'Nom du logiciel et de son éditeur', url: '/calcult-bar-th-164' },
    { id: 'software_version', value: 'Date et n° de version ', url: '/calcult-bar-th-164' },
    { id: 'representative_name', value: 'Nom du représentant', url: '/calcult-bar-th-164' },
    { id: 'b2c_first_name', value: 'Prénom', url: '/calcult-bar-th-164' },
    { id: 'siret_number', value: 'N° SIREN', url: '/calcult-bar-th-164' },
    { id: 'field_work', value: 'Domaine des travaux réalisés', url: '/calcult-bar-th-164' },
    { id: 'certification_reference', value: 'Référence de la qualification ou certification ', url: '/calcult-bar-th-164'},
    { id: 'b2c_date', value: 'Date', url: '/calcult-bar-th-164' },
    //   BAR-TH-125
    { id: 'date_of_commitment', value: 'Bâtiment résidentiel existant depuis plus de 2 ans à la date d engagement de lopération ', url: '/calcult-bar-th-125' },
    { id: 'type_installation', value: 'Type d’installation', url: '/calcult-bar-th-125' },
    { id: 'dwellings_served', value: 'A ne remplir que dans le cas d une installation collective Nombre de logements desservis ', url: '/calcult-bar-th-125' },
    { id: 'energy_class', value: 'Classe énergétique du système de ventilation selon le règlement européen (UE) n° 1254/2014', url: '/calcult-bar-th-125' },
    { id: 'exchanger_thermal', value: 'Efficacité thermique de l’échangeur (%)', url: '/calcult-bar-th-125' },
    { id: 'installation_type', value: 'NB : Efficacité thermique certifiée, et mesurée selon la norme NF EN 13141-7 ou le matériel est certifié NF 205 ou équivalent. Type d’installation', url: '/calcult-bar-th-125' },
    { id: 'static_exchanger', value: 'Efficacité de l’échangeur statique (%) ', url: '/calcult-bar-th-125' },
    { id: 'box_brand', value: 'Marque du caisson', url: '/calcult-bar-th-125' },
    { id: 'casing_reference', value: 'Référence du caisson', url: '/calcult-bar-th-125' },
    { id: 'valve_brand', value: 'Marque des bouches d’extraction', url: '/calcult-bar-th-125' },
    { id: 'unit_reference', value: 'Référence des bouches d’extraction', url: '/calcult-bar-th-125' },
    // BAR-TH-148
    { id: 'work_name', value: 'Nom du site des travaux (pour les personnes morales)', url: '/calcult-bar-th-148' },
    { id: 'housing_type', value: 'Type de logement', url: '/calcult-bar-th-148' },
    { id: 'cop', value: 'COP', url: '/calcult-bar-th-148' },
    { id: 'prénom', value: 'Prénom', url: '/calcult-bar-th-148' },
    //   BAR-TH 143
    { id: 'lowtemperature', value: 'Le système est couplé à des émetteurs de chauffage central de type basse température', url: '/calcult-bar-th-143' },
    //   BAR-TH-104
    { id: 'heatpump_type', value: 'La pompe à chaleur est de type air/eau ou eau/eau et elle est conçue pour fonctionner à (une seule case à cocher)', url: '/calcult-bar-th-104' },
    { id: 'seasonal_energy', value: 'Efficacité énergétique saisonnière (ηs) (en %) ', url: '/calcult-bar-th-104' },
    { id: 'sizing_note', value: 'Une note de dimensionnement a été remise au bénéficiaire', url: '/calcult-bar-th-104' },
    //  BAR-TH 113
    { id: 'wood', value: 'La biomasse utilisée est de la biomasse ligneuse à base de bûches de bois, de copeaux de bois, de bois comprimé sous forme de granulés, de bois comprimé sous forme de briquettes ou de sciure de bois', url: '/calcult-bar-th-113' },
    { id: 'regulator_class', value: 'Classe du régulateur', url: '/calcult-bar-th-113' },
    { id: 'buffer_tank', value: ' Pour les chaudières à alimentation manuelle, présence d’un ballon tampon', url: '/calcult-bar-th-113' },
    { id: 'boiler_operated', value: 'Le chargement de la chaudière est opéré de manière (une seule case à cocher)', url: '/calcult-bar-th-113' },
    { id: 'boiler_7label', value: 'La chaudière installée possède le label Flamme Verte 7', url: '/calcult-bar-th-113' },
    { id: 'seasonal_particulate', value: ' Émissions saisonnières de particules en mg/Nm3', url: '/calcult-bar-th-113' },
    { id: 'seasonal_carbon', value: 'Émissions saisonnières de monoxyde de carbone (CO) en mg/Nm3', url: '/calcult-bar-th-113' },
    { id: 'nitrogen_oxides', value: 'Émissions saisonnières d oxydes d azote (NOx) en mg/Nm3', url: '/calcult-bar-th-113' },
    { id: 'gaseous_organic', value: 'Émissions saisonnières de composés organiques gazeux en mg/Nm3', url: '/calcult-bar-th-113' },
    // ESTIMATION PAGE
    { id: 'oblige', value: 'OBLIGE', url: '/estimation-page' },
    { id: 'partenaire', value: 'PARTENAIRE', url: '/estimation-page' },
    { id: 'obligeValuePrice', value: 'Tarif valo OBLIGE', url: '/estimation-page' },
    { id: 'partenaireValuePrice', value: 'Tarif valo PARTENAIRE', url: '/estimation-page' },
    { id: 'neighborhoodType', value: 'Type de quartier', url: '/estimation-page' },
    { id: 'heatingMode', value: 'Mode de chauffage', url: '/estimation-page' },
    { id: 'typeOfBuilding', value: 'Type de batiment', url: '/estimation-page' },
    { id: 'area', value: 'Zone', url: '/estimation-page' },
    { id: 'surface', value: 'Surface', url: '/estimation-page' },
    { id: 'partclassique', value: 'PART CLASSIQUE', url: '/estimation-page' },
    { id: 'partgpetp', value: 'PART GP ET P', url: '/estimation-page' },
    { id: 'petgp', value: 'P et GP', url: '/estimation-page' },
    { id: 'obligeCumacValue', value: 'VALEUR Kwh CUMAC', url: '/estimation-page' },
    { id: 'obligeTotalPremiumAmount', value: 'MONTANT TOTALE DE LA PRIME', url: '/estimation-page' },
    { id: 'obligeProfitShare', value: 'PART BENEFICIAIRE', url: '/estimation-page' },
    { id: 'obligeShareInstallerHT', value: 'PART INSTALLATEUR HT', url: '/estimation-page' },
    { id: 'obligeVAT', value: 'TVA', url: '/estimation-page' },
    { id: 'obligeTotalPrice', value: 'TOTAL TTC', url: '/estimation-page' },
    { id: 'obligeBeneficiaryPremium', value: 'PRIME BENEFICIAIRE', url: '/estimation-page' },
    { id: 'partnerCumacValue', value: 'VALEUR Kwh CUMAC', url: '/estimation-page' },
    { id: 'partnerTotalPremiumAmount', value: 'MONTANT TOTALE DE LA PRIME', url: '/estimation-page' },
    { id: 'partnerProfitShare', value: 'PART BENEFICIAIRE', url: '/estimation-page' },
    { id: 'partnerShareInstallerHT', value: 'PART INSTALLATEUR HT', url: '/estimation-page' },
    { id: 'partnerVAT', value: 'TVA', url: '/estimation-page' },
    { id: 'partnerTotalPrice', value: 'TOTAL TTC', url: '/estimation-page' },
    { id: 'partnerBeneficiaryPremium', value: 'PRIME BENEFICIAIRE', url: '/estimation-page' },
    // Control
    { id: 'contributionFrameDate', value: 'Date de cadre de contribution', url: '/control-contribution' },
    { id: 'quoteEditiondate', value: 'Date édition devis', url: '/control-contribution' },
    { id: 'estimateDate', value: 'Date de previsite', url: '/control-contribution' },
    { id: 'quoteSignatureDate', value: 'Date signature devis', url: '/control-contribution' },
    { id: 'startDateOfWork', value: 'Date debut des travaux', url: '/control-contribution' },
    { id: 'billDate', value: 'Date de facture', url: '/control-contribution' },
    { id: 'dateControlOfficeVisit', value: 'Date visite bureau de contrôle', url: '/control-contribution' },
    { id: 'beneficiarySignatureDateOnAH', value: 'Date signature du beneficiare sur AH', url: '/control-contribution' },
    { id: 'installerSignatureDateonAH', value: 'Date signature de l installateur sur AH', url: '/control-contribution' },
    // { id: 'beneficiarySignatureDateonsh', value: 'Date signature du beneficiare sur AH', url: '/control-contribution' },
    //    Comments
    { id: 'comments', value: 'Commentaires', url: '/valider-comment' },
    // Devis
    { id: 'estimate_installerContactDetails', value: 'Coordonnées Installateur', url: '/devis' },
    { id: 'estimate_subcontractorContactDetails', value: 'Coordonnées de sous traitant', url: '/devis' },
    { id: 'estimate_socialReason', value: 'Raison sociale', url: '/devis' },
    { id: 'estimate_tradeName', value: 'Nom commercial', url: '/devis' },
    { id: 'estimate_nSiret', value: 'N Siret', url: '/devis' },
    { id: 'estimate_address', value: 'Adresse', url: '/devis' },
    { id: 'estimate_cp', value: 'CP', url: '/devis' },
    { id: 'estimate_city', value: 'Ville', url: '/devis' },
    { id: 'estimate_phone', value: 'Telephone', url: '/devis' },
    { id: 'estimate_siretInstaller', value: 'SIRET INSTALLATEUR', url: '/devis' },
    { id: 'estimate_identityOftheBeneficiary', value: 'Identité du bénéficiaire', url: '/devis' },
    { id: 'estimate_quoteIssueDate', value: 'Date d’émission du devis', url: '/devis' },
    { id: 'estimate_mentionsRequiredByTheOperationSheet', value: 'Mentions exigées par la fiche d’opération', url: '/devis' },
    { id: 'estimate_beneficiarySiret', value: 'SIRET bénéficiaire', url: '/devis' },
    { id: 'estimate_beneficiaryAddress', value: 'Adresse bénéficiaire', url: '/devis' },
    { id: 'estimate_beneficiaryPhone', value: 'Telephone bénéficiaire', url: '/devis' },
    { id: 'estimate_beneficiaryFirstName', value: 'Prenom bénéficiaire', url: '/devis' },
    { id: 'estimate_beneficiaryEmail', value: 'Email bénéficiaire', url: '/devis' },
    { id: 'estimate_beneficiaryFunction', value: 'Fonction bénéficiaire', url: '/devis' },
    { id: 'estimate_placeOfWork', value: 'Lieu des travaux', url: '/devis' },
    { id: 'estimate_purchaseOrderNo', value: 'N° de devis/Bon de commande', url: '/devis' },
    { id: 'estimate_incentiveRoleCompliance', value: 'Conformité du rôle Incitatif', url: '/devis' },
    { id: 'estimate_goodForAgreement', value: 'Bon pour accord', url: '/devis' },
    { id: 'estimate_handwrittenDate', value: 'Date manuscrite', url: '/devis' },
    { id: 'estimate_surnameFirstName', value: 'Nom, Prenom, Fonction manuscrite', url: '/devis' },
    { id: 'estimate_brand', value: 'Marque', url: '/devis' },
    { id: 'estimate_reference', value: 'Référence', url: '/devis' },
    { id: 'estimate_presenceOfTheStandard', value: 'Presence de la norme', url: '/devis' },
    { id: 'estimate_attendanceDateandSignatureOfTheCustomer', value: 'Présence Date et signature du client', url: '/devis' },
    { id: 'estimate_insulationClass', value: 'Classe de l’isolant', url: '/devis' },
    { id: 'estimate_otherMandatoryMaterialMentions', value: 'Autre mentions obligatoire matériel', url: '/devis' },
    { id: 'estimate_amountOfBeneficiaryPremium', value: 'Autre mentions obligatoire matériel', url: '/devis' },
    { id: 'estimate_raiPremium', value: 'Prime RAI = Prime DEVIS', url: '/devis' },
    { id: 'Marque', value: 'Marque', url: '/devis' },
    { id: 'Classedelisolant', value: 'Classe de l’isolant', url: '/devis' },
    { id: 'materialmentions', value: 'Autre mentions obligatoire matériel', url: '/devis' },
    // FACTURE
    { id: 'installerContactDetails', value: 'Coordonnées Installateur', url: '/facture' },
    { id: 'bill_subcontractorContactDetails', value: 'Coordonnées de sous traitant', url: '/facture' },
    { id: 'bill_socialReason', value: 'Raison sociale', url: '/facture' },
    { id: 'bill_tradeName', value: 'Nom commercial', url: '/facture' },
    { id: 'bill_nSiret', value: 'N Siret', url: '/facture' },
    { id: 'bill_address', value: 'Adresse', url: '/facture' },
    { id: 'bill_cp', value: 'CP', url: '/facture' },
    { id: 'bill_city', value: 'Ville', url: '/facture' },
    { id: 'bill_phone', value: 'Telephone', url: '/facture' },
    { id: 'bill_siretInstaller', value: 'SIRET INSTALLATEUR ', url: '/facture' },
    { id: 'bill_identityOfTheBeneficiary', value: 'Identité du bénéficiaire', url: '/facture' },
    { id: 'bill_invoiceIssueDate', value: 'Date d’émission du facture', url: '/facture' },
    { id: 'bill_beneficiarySiret', value: 'SIRET bénéficiaire', url: '/facture' },
    { id: 'bill_operationSheet', value: 'Mentions exigées par la fiche d’opération', url: '/facture' },
    { id: 'bill_beneficiaryAddress', value: 'Adresse bénéficiaire', url: '/facture' },
    { id: 'bill_beneficiaryName', value: 'Nom bénéficiaire', url: '/facture' },
    { id: 'bill_beneficiaryFirstName', value: 'Prenom bénéficiaire', url: '/facture' },
    { id: 'bill_beneficiaryPhone', value: 'Telephone bénéficiaire', url: '/facture' },
    { id: 'bill_beneficiaryEmail', value: 'Email bénéficiaire', url: '/facture' },
    { id: 'bill_beneficiaryFunction', value: 'Fonction bénéficiaire', url: '/facture' },
    { id: 'bill_lieuDesTravaux', value: 'Lieu des travaux', url: '/facture' },
    { id: 'bill_invoiceNumber', value: 'N° de facture/Bon de commande', url: '/facture' },
    { id: 'bill_vatRate', value: 'Taux de TVA', url: '/facture' },
    { id: 'bill_diametersAndThicknesses', value: 'Presence des diametres et epaisseurs', url: '/facture' },
    { id: 'bill_quoteNo', value: 'N° de Devis', url: '/facture' },
    // ATTESTATION
    { id: 'attestation_ahFormatCompliant', value: 'Format AH conforme', url: '/attestation' },
    { id: 'attestation_fFullCFrame', value: 'Cadre(s) C complet', url: '/attestation' },
    { id: 'attestation_fullAframe', value: 'Cadre(s) A complet', url: '/attestation' },
    { id: 'attestation_signatureAndStamp', value: 'Signature et cachet du professionnel', url: '/attestation' },
    { id: 'attestation_completeBframe', value: 'Cadre(s) B complet', url: '/attestation' },
    { id: 'attestation_completeQbvsFrame', value: 'Cadre QPV / BS complet', url: '/attestation' },
    { id: 'attestation_beneficiarysSignatureandStamp', value: 'Signature et cachet du bénéficiaire', url: '/attestation' },
    // Devis,Facture,Attestation
    { id: 'estimate_comments', value: 'DevisCommentaires', url: '/devis' },
    { id: 'bill_comments', value: 'Factures Commentaires', url: '/facture' },
    { id: 'attestation_comments', value: 'Attestation sur l’Honneur Commentaires', url: '/attestation' },
    // Synopsis Commands
    { id: 'synopsisComments', value: 'Synopsis Comments', url: '/synopsis' },
]
// ---------------------------------------------------------------------------------------------------------------
export const QPVCALVALUE = {
    // '01': {
    //     'Grande_precarite': 55,
    //     'Precarite': 88,
    // },
    '01': {
        'chantier_greatPrecariousness': 55,
        'chantier_precarious': 88,
    },
    '02': {
        'chantier_greatPrecariousness': 63,
        'chantier_precarious': 91,
    },
    '03': {
        'chantier_greatPrecariousness': 67,
        'chantier_precarious': 93,
    },
    '04': {
        'chantier_greatPrecariousness': 67,
        'chantier_precarious': 92,
    },
    '05': {
        'chantier_greatPrecariousness': 53,
        'chantier_precarious': 88,
    },
    '06': {
        'chantier_greatPrecariousness': 43,
        'chantier_precarious': 80,
    },
    '07': {
        'chantier_greatPrecariousness': 67,
        'chantier_precarious': 94,
    },
    '08': {
        'chantier_greatPrecariousness': 64,
        'chantier_precarious': 93,
    },
    '09': {
        'chantier_greatPrecariousness': 74,
        'chantier_precarious': 95,
    },
    '10': {
        'chantier_greatPrecariousness': 63,
        'chantier_precarious': 92,
    },
    '11': {
        'chantier_greatPrecariousness': 74,
        'chantier_precarious': 95,
    },
    '12': {
        'chantier_greatPrecariousness': 67,
        'chantier_precarious': 94,
    },
    '13': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 89,
    },
    '14': {
        'chantier_greatPrecariousness': 60,
        'chantier_precarious': 91,
    },
    '15': {
        'chantier_greatPrecariousness': 65,
        'chantier_precarious': 93,
    },
    '16': {
        'chantier_greatPrecariousness': 70,
        'chantier_precarious': 93,
    },
    '17': {
        'chantier_greatPrecariousness': 67,
        'chantier_precarious': 93,
    },
    '18': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 91,
    },
    '19': {
        'chantier_greatPrecariousness': 68,
        'chantier_precarious': 93,
    },
    '2A': {
        'chantier_greatPrecariousness': 59,
        'chantier_precarious': 87,
    },
    '2B': {
        'chantier_greatPrecariousness': 63,
        'chantier_precarious': 89,
    },
    '21': {
        'chantier_greatPrecariousness': 58,
        'chantier_precarious': 90,
    },
    '22': {
        'chantier_greatPrecariousness': 71,
        'chantier_precarious': 95,
    },
    '23': {
        'chantier_greatPrecariousness': 65,
        'chantier_precarious': 92,
    },
    '24': {
        'chantier_greatPrecariousness': 67,
        'chantier_precarious': 93,
    },
    '25': {
        'chantier_greatPrecariousness': 64,
        'chantier_precarious': 91,
    },
    '26': {
        'chantier_greatPrecariousness': 70,
        'chantier_precarious': 94,
    },
    '27': {
        'chantier_greatPrecariousness': 59,
        'chantier_precarious': 90,
    },
    '28': {
        'chantier_greatPrecariousness': 57,
        'chantier_precarious': 87,
    },
    '29': {
        'chantier_greatPrecariousness': 69,
        'chantier_precarious': 95,
    },
    '30': {
        'chantier_greatPrecariousness': 77,
        'chantier_precarious': 95,
    },
    '31': {
        'chantier_greatPrecariousness': 63,
        'chantier_precarious': 90,
    },
    '32': {
        'chantier_greatPrecariousness': 64,
        'chantier_precarious': 91,
    },
    '33': {
        'chantier_greatPrecariousness': 55,
        'chantier_precarious': 88,
    },
    '34': {
        'chantier_greatPrecariousness': 68,
        'chantier_precarious': 93,
    },
    '35': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 92,
    },
    '36': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 92,
    },
    '37': {
        'chantier_greatPrecariousness': 67,
        'chantier_precarious': 93,
    },
    '38': {
        'chantier_greatPrecariousness': 60,
        'chantier_precarious': 90,
    },
    '39': {
        'chantier_greatPrecariousness': 64,
        'chantier_precarious': 91,
    },
    '40': {
        'chantier_greatPrecariousness': 64,
        'chantier_precarious': 92,
    },
    '41': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 92,
    },
    '42': {
        'chantier_greatPrecariousness': 63,
        'chantier_precarious': 92,
    },
    '43': {
        'chantier_greatPrecariousness': 68,
        'chantier_precarious': 93,
    },
    '44': {
        'chantier_greatPrecariousness': 62,
        'chantier_precarious': 91,
    },
    '45': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 91,
    },
    '46': {
        'chantier_greatPrecariousness': 70,
        'chantier_precarious': 94,
    },
    '47': {
        'chantier_greatPrecariousness': 72,
        'chantier_precarious': 94,
    },
    '48': {
        'chantier_greatPrecariousness': 59,
        'chantier_precarious': 89,
    },
    '49': {
        'chantier_greatPrecariousness': 56,
        'chantier_precarious': 87,
    },
    '50': {
        'chantier_greatPrecariousness': 59,
        'chantier_precarious': 90,
    },
    '51': {
        'chantier_greatPrecariousness': 51,
        'chantier_precarious': 85,
    },
    '52': {
        'chantier_greatPrecariousness': 60,
        'chantier_precarious': 90,
    },
    '53': {
        'chantier_greatPrecariousness': 60,
        'chantier_precarious': 92,
    },
    '54': {
        'chantier_greatPrecariousness': 60,
        'chantier_precarious': 89,
    },
    '55': {
        'chantier_greatPrecariousness': 67,
        'chantier_precarious': 92,
    },
    '56': {
        'chantier_greatPrecariousness': 66,
        'chantier_precarious': 94,
    },
    '57': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 89,
    },
    '58': {
        'chantier_greatPrecariousness': 63,
        'chantier_precarious': 92,
    },
    '59': {
        'chantier_greatPrecariousness': 63,
        'chantier_precarious': 92,
    },
    '60': {
        'chantier_greatPrecariousness': 55,
        'chantier_precarious': 87,
    },
    '61': {
        'chantier_greatPrecariousness': 63,
        'chantier_precarious': 92,
    },
    '62': {
        'chantier_greatPrecariousness': 66,
        'chantier_precarious': 93,
    },
    '63': {
        'chantier_greatPrecariousness': 59,
        'chantier_precarious': 90,
    },
    '64': {
        'chantier_greatPrecariousness': 60,
        'chantier_precarious': 90,
    },
    '65': {
        'chantier_greatPrecariousness': 66,
        'chantier_precarious': 93,
    },
    '66': {
        'chantier_greatPrecariousness': 72,
        'chantier_precarious': 94,
    },
    '67': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 89,
    },
    '68': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 90,
    },
    '69': {
        'chantier_greatPrecariousness': 58,
        'chantier_precarious': 89,
    },
    '70': {
        'chantier_greatPrecariousness': 66,
        'chantier_precarious': 93,
    },
    '71': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 91,
    },
    '72': {
        'chantier_greatPrecariousness': 61,
        'chantier_precarious': 92,
    },
    '73': {
        'chantier_greatPrecariousness': 53,
        'chantier_precarious': 87,
    },
    '74': {
        'chantier_greatPrecariousness': 52,
        'chantier_precarious': 85,
    },
    '75': {
        'chantier_greatPrecariousness': 51,
        'chantier_precarious': 80,
    },
    '76': {
        'chantier_greatPrecariousness': 54,
        'chantier_precarious': 87,
    },
    '77': {
        'chantier_greatPrecariousness': 62,
        'chantier_precarious': 92,
    },
    '78': {
        'chantier_greatPrecariousness': 53,
        'chantier_precarious': 87,
    },
    '79': {
        'chantier_greatPrecariousness': 62,
        'chantier_precarious': 93,
    },
    '80': {
        'chantier_greatPrecariousness': 64,
        'chantier_precarious': 91,
    },
    '81': {
        'chantier_greatPrecariousness': 74,
        'chantier_precarious': 96,
    },
    '82': {
        'chantier_greatPrecariousness': 77,
        'chantier_precarious': 96,
    },
    '83': {
        'chantier_greatPrecariousness': 62,
        'chantier_precarious': 90,
    },
    '84': {
        'chantier_greatPrecariousness': 70,
        'chantier_precarious': 94,
    },
    '85': {
        'chantier_greatPrecariousness': 63,
        'chantier_precarious': 94,
    },
    '86': {
        'chantier_greatPrecariousness': 65,
        'chantier_precarious': 92,
    },
    '87': {
        'chantier_greatPrecariousness': 63,
        'chantier_precarious': 92,
    },
    '88': {
        'chantier_greatPrecariousness': 62,
        'chantier_precarious': 92,
    },
    '89': {
        'chantier_greatPrecariousness': 68,
        'chantier_precarious': 93,
    },
    '90': {
        'chantier_greatPrecariousness': 64,
        'chantier_precarious': 90,
    },
    '91': {
        'chantier_greatPrecariousness': 55,
        'chantier_precarious': 89,
    },
    '92': {
        'chantier_greatPrecariousness': 46,
        'chantier_precarious': 82,
    },
    '93': {
        'chantier_greatPrecariousness': 62,
        'chantier_precarious': 90,
    },
    '94': {
        'chantier_greatPrecariousness': 53,
        'chantier_precarious': 86,
    },
    '95': {
        'chantier_greatPrecariousness': 58,
        'chantier_precarious': 89,
    },
    'Collectivités d’outre-mer': {
        'chantier_greatPrecariousness': 80,
        'chantier_precarious': 94,
    },
}
// ---------------------------------------------------------------------------------------------------------------
//H1 ARRAY RESTRUCTURE
export const H1CALCULATION = {
    'BAR-EN-101': {
        'Electrique': [
            { H1: 1700 },
            { H2: 1400 },
            { H3: 900 },
        ],
        'Combustible': [
            { H1: 1700 },
            { H2: 1400 },
            { H3: 900 },
        ]
    },
    'BAR-EN-102': {
        'Electrique': [
            { 'H1': 2400 },
            { 'H2': 2000 },
            { 'H3': 1300 },
        ],
        'Combustible': [
            { 'H1': 3800 },
            { 'H2': 3100 },
            { 'H3': 2100 },
        ]
    },
    'BAR-EN-103': {
        'Electrique': [
            { 'H1': 1600 },
            { 'H2': 1300 },
            { 'H3': 900 },
        ],
        'Combustible': [
            { 'H1': 1600 },
            { 'H2': 1300 },
            { 'H3': 900 },
        ],
    },

};
export const H1NCALCULATION = {
    'BAR-EN-104': {
        'Electrique': [
            { 'H1': 5200 },
            { 'H2': 4200 },
            { 'H3': 2800 },
        ],
        'Combustible': [
            { 'H1': 8200 },
            { 'H2': 6700 },
            { 'H3': 4500 },
        ],
    },
    'BAR-SE-104': {
        'Electrique': [
            { 'H1': 9800 },
            { 'H2': 8000 },
            { 'H3': 5300 },
        ],
        'Combustible': [
            { 'H1': 9800 },
            { 'H2': 8000 },
            { 'H3': 5300 },
        ],
    },

};
export const H1LCALCULATION = {
    'BAR-TH-160': {
        'Electrique': [
            { 'H1': 6700 },
            { 'H2': 5600 },
            { 'H3': 4900 },
        ],
        'Combustible': [
            { 'H1': 6700 },
            { 'H2': 5600 },
            { 'H3': 4900 },
        ],
    },
    'BAT-TH-146': {
        'Electrique': [
            { 'H1': 4300 },
            { 'H2': 4000 },
            { 'H3': 3600 },
        ],
        'Combustible': [
            { 'H1': 4300 },
            { 'H2': 4000 },
            { 'H3': 3600 },
        ],
    },

};

export const TYPEDEBATIMENT = {
    'BAT-EN-101': {
        'Electrique': [
            { 'H1': 2600 },
            { 'H2': 2100 },
            { 'H3': 1400 },
        ],
        'Combustible': [
            { 'H1': 2600 },
            { 'H2': 2100 },
            { 'H3': 1400 },
        ],
        'typedebatiment': [
            { 'Bureaux': 0.6 },
            { 'Enseignement': 0.6 },
            { 'Commerces': 0.6 },
            { 'Hotellerie Restauration': 0.7 },
            { 'Sante': 1.2 },
            { 'Autres secteurs': 0.6 },
        ],
    },
    'BAT-EN-102': {
        'Electrique': [
            { 'H1': 3000 },
            { 'H2': 2500 },
            { 'H3': 1600 },
        ],
        'Combustible': [
            { 'H1': 4800 },
            { 'H2': 3900 },
            { 'H3': 2600 },
        ],
        'typedebatiment': [
            { 'Bureaux': 0.6 },
            { 'Enseignement': 0.6 },
            { 'Commerces': 0.6 },
            { 'Hotellerie Restauration': 0.7 },
            { 'Sante': 1.3 },
            { 'Autres secteurs': 0.6 },
        ],
    },
    'BAT-EN-103': {
        'Electrique': [
            { 'H1': 5200 },
            { 'H2': 4200 },
            { 'H3': 2800 },
        ],
        'Combustible': [
            { 'H1': 5200 },
            { 'H2': 4200 },
            { 'H3': 2800 },
        ],
        'typedebatiment': [
            { 'Bureaux': 0.6 },
            { 'Enseignement': 0.6 },
            { 'Commerces': 0.6 },
            { 'Hotellerie Restauration': 0.7 },
            { 'Sante': 1.2 },
            { 'Autres secteurs': 0.6 },
        ],
    },
    'BAT-TH-104': {
        'Electrique': [
            { 'H1': 100 },
            { 'H2': 81 },
            { 'H3': 54 },
        ],
        'Combustible': [
            { 'H1': 100 },
            { 'H2': 81 },
            { 'H3': 54 },
        ],
        'typedebatiment': [
            { 'Bureaux': 1.2 },
            { 'Enseignement': 0.8 },
            { 'Commerces': 0.9 },
            { 'Hotellerie Restauration': 1.3 },
            { 'Sante': 1 },
            { 'Autres secteurs': 0.8 },
        ],
    },

};

export const GTBCALCULATIONV1 = {
    'BAT-TH-116': {
        'Electrique': [
            { 'H1': 1.1 },
            { 'H2': 0.9 },
            { 'H3': 0.6 },
        ],
        'Combustible': [
            { 'H1': 1.1 },
            { 'H2': 0.9 },
            { 'H3': 0.6 },
        ],
        'Chauffage': [
            { 'Bureaux': 400 },
            { 'Enseignement': 200 },
            { 'Commerces': 560 },
            { 'Hotellerie Restauration': 420 },
            { 'Sante': 200 },
            { 'Autres secteurs': 200 },
        ],
        'Refroidissement Climatisation': [
            { 'Bureaux': 260 },
            { 'Enseignement': 71 },
            { 'Commerces': 160 },
            { 'Hotellerie Restauration': 71 },
            { 'Sante': 71 },
            { 'Autres secteurs': 71 },
        ],
        'ECS': [
            { 'Bureaux': 16 },
            { 'Enseignement': 89 },
            { 'Commerces': 32 },
            { 'Hotellerie Restauration': 34 },
            { 'Sante': 95 },
            { 'Autres secteurs': 16 },
        ],
        'Eclairage': [
            { 'Bureaux': 190 },
            { 'Enseignement': 49 },
            { 'Commerces': 23 },
            { 'Hotellerie Restauration': 74 },
            { 'Sante': 12 },
            { 'Autres secteurs': 12 },
        ],
        'Auxiliaire': [
            { 'Bureaux': 19 },
            { 'Enseignement': 8 },
            { 'Commerces': 8 },
            { 'Hotellerie Restauration': 8 },
            { 'Sante': 28 },
            { 'Autres secteurs': 8 },
        ],
    },
    'BAT-EQ-133': {
        'Certificates': [
            { 'Bureaux': 0.1 },
            { 'Enseignement': 0.1 },
            { 'Hotelleric et habitat communautaire': 1 },
            { 'Sante': 0.85 },
            { 'Etablissements sportifs': 4 },
            { 'Autres secteurs': 0.1 },
        ],
    },

};
export const GTBCALCULATIONV2 = {
    'BAT-TH-116': {
        'Electrique': [
            { 'H1': 1.1 },
            { 'H2': 0.9 },
            { 'H3': 0.6 },
        ],
        'Combustible': [
            { 'H1': 1.1 },
            { 'H2': 0.9 },
            { 'H3': 0.6 },
        ],
        'Chauffage': [
            { 'Bureaux': 300 },
            { 'Enseignement': 120 },
            { 'Commerces': 300 },
            { 'Hotellerie Restauration': 230 },
            { 'Sante': 140 },
            { 'Autres secteurs': 120 },
        ],
        'Refroidissement Climatisation': [
            { 'Bureaux': 130 },
            { 'Enseignement': 35 },
            { 'Commerces': 66 },
            { 'Hotellerie Restauration': 35 },
            { 'Sante': 35 },
            { 'Autres secteurs': 35 },
        ],
        'ECS': [
            { 'Bureaux': 8 },
            { 'Enseignement': 45 },
            { 'Commerces': 3 },
            { 'Hotellerie Restauration': 17 },
            { 'Sante': 48 },
            { 'Autres secteurs': 3 },
        ],
        'Eclairage': [
            { 'Bureaux': 100 },
            { 'Enseignement': 24 },
            { 'Commerces': 23 },
            { 'Hotellerie Restauration': 40 },
            { 'Sante': 12 },
            { 'Autres secteurs': 12 },
        ],
        'Auxiliaire': [
            { 'Bureaux': 10 },
            { 'Enseignement': 5 },
            { 'Commerces': 5 },
            { 'Hotellerie Restauration': 5 },
            { 'Sante': 18 },
            { 'Autres secteurs': 5 },
        ],
    },
    'BAT-EQ-133': {
        'Certificates': [
            { 'Bureaux': 1.7 },
            { 'Enseignement': 4.3 },
            { 'Hotelleric et habitat communautaire': 1.0 },
            { 'Sante': 0.85 },
            { 'Etablissements sportifs': 0.3 },
            { 'Autres secteurs': 0.8 },
        ],
    },
}
// -----------B2C CALCULATIONS LIST-------------
export const H1B2CCALCULATION = {
    'BAR-TH-143': {
        'Montantcumac': [
            { 'H1': 134800 },
            { 'H2': 121000 },
            { 'H3': 100500 },
        ],
    },
    'BAR-TH-113': {
        'Montantcumac': [
            { 'H1': 41300 },
            { 'H2': 33800 },
            { 'H3': 26300 },
        ],
    },
}
export const H1B2CDATECALCULATION = {
    'BAR-TH-101': {
        '2017-09-26': [
            { 'H1': 22600 },
            { 'H2': 25400 },
            { 'H3': 29100 },
        ],
        '2017-09-27': [
            { 'H1': 21500 },
            { 'H2': 24100 },
            { 'H3': 27600 },
        ],
    },
    'BAR-TH-148': {
        '2017-09-25': [
            { 'Maison_individuelle': 21100 },
            { 'Appartement': 16200 },
        ],
        '2017-09-26': [
            { 'Maison_individuelle': 15600 },
            { 'Appartement': 11900 },
        ],
    },
}
export const H1B2CENERGYSURFACE = {
    'BAR-TH-104': {
        'Maison_individuelle': {
            '102-110': [
                { 'H1': 24500 },
                { 'H2': 20100 },
                { 'H3': 13400 },
            ],
            '110-119': [
                { 'H1': 32200 },
                { 'H2': 26400 },
                { 'H3': 17600 },
            ],
            '120-Belove': [
                { 'H1': 39700 },
                { 'H2': 32500 },
                { 'H3': 21700 },
            ],
        },
        'Appartement': {
            '102-110': [
                { 'H1': 52700 },
                { 'H2': 43100 },
                { 'H3': 28700 },
            ],
            '110-119': [
                { 'H1': 66400 },
                { 'H2': 54400 },
                { 'H3': 36200 },
            ],
            '120-Belove': [
                { 'H1': 79900 },
                { 'H2': 65400 },
                { 'H3': 43600 },
            ],
        }
    },
}
export const INSTALLATIONTYPE = {
    'BAR-TH-125': {
        'Self-adjusting collective': [
            { 'H1': 23000 },
            { 'H2': 18800 },
            { 'H3': 12500 },
        ],
        'Individual self-adjusting': [
            { 'H1': 39700 },
            { 'H2': 32500 },
            { 'H3': 21600 },
        ],
        'Individual modulated': [
            { 'H1': 42000 },
            { 'H2': 34400 },
            { 'H3': 22900 },
        ],
    }
}
export const PLATEHEAT = {
    'BAR-TH-161': {
        '50-120': {
            '20-65': [
                { 'H1': 11700 },
                { 'H2': 10500 },
                { 'H3': 8800 },
            ],
            '65-100': [
                { 'H1': 25100 },
                { 'H2': 22700 },
                { 'H3': 18900 },
            ],
            '100-Above': [
                { 'H1': 40900 },
                { 'H2': 37000 },
                { 'H3': 30800 },
            ],
            'single-50-120': [
                { 'H1': 77200 },
                { 'H2': 73500 },
                { 'H3': 66900 },
            ],
        },
        '120-Above': {
            '20-65': [
                { 'H1': 12900 },
                { 'H2': 11600 },
                { 'H3': 9700 },
            ],
            '65-100': [
                { 'H1': 27800 },
                { 'H2': 25100 },
                { 'H3': 20900 },
            ],
            '100-Above': [
                { 'H1': 45400 },
                { 'H2': 41000 },
                { 'H3': 34100 },
            ],
            'single-50-120': [
                { 'H1': 88000 },
                { 'H2': 83900 },
                { 'H3': 76300 },
            ],
        },
    },
    'BAT-TH-155': {
        '50-120': {
            '20-65': [
                { 'H1': 11700 },
                { 'H2': 10500 },
                { 'H3': 8800 },
            ],
            '65-100': [
                { 'H1': 25100 },
                { 'H2': 22700 },
                { 'H3': 18900 },
            ],
            '100-Above': [
                { 'H1': 40900 },
                { 'H2': 37000 },
                { 'H3': 30800 },
            ],
            'single-50-120': [
                { 'H1': 77200 },
                { 'H2': 73500 },
                { 'H3': 66900 },
            ],
        },
        '120-Above': {
            '20-65': [
                { 'H1': 12900 },
                { 'H2': 11600 },
                { 'H3': 9700 },
            ],
            '65-100': [
                { 'H1': 27800 },
                { 'H2': 25100 },
                { 'H3': 20900 },
            ],
            '100-Above': [
                { 'H1': 45400 },
                { 'H2': 41000 },
                { 'H3': 34100 },
            ],
            'single-50-120': [
                { 'H1': 88000 },
                { 'H2': 83900 },
                { 'H3': 76300 },
            ],
        },
        'typedebatiment': [
            { 'Bureaux': 0.35 },
            { 'Enseignement': 0.2 },
            { 'Commerces': 0.2 },
            { 'Hotellerie Restauration': 1 },
            { 'Sante': 1 },
            { 'Autres secteurs': 0.2 },
        ],
    }
}