import React,{useState,useEffect} from 'react'
import {useNavigate } from "react-router-dom"

// FORMIK
import { Formik } from 'formik';

function Invalider() {
    const navigate = useNavigate();
    const [storedData, setStoredData] = useState(null);
    // ----------------------------------------------
    useEffect(() => {
        // Retrieve stored form values from local storage
        const storedFormValues = sessionStorage.getItem('step-13');

        if (storedFormValues) {
            // Parse the stored form values and set them as initial values
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.comments = parsedFormValues.comments;
            
        }
    }, []);
    const initialValues = {
        comments: '',
    }
    const validate = (values) => {
        console.log("validate", values);
        const errors = {};
        if (!values.comments) {
            errors.comments = 'Commentaires requise';
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-13", JSON.stringify(values));
        navigate('/devis');
    }
    // BACK BUTTON
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-13');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid px-4'>
                        <p className='bar1-head mt-3'>Instruction</p>
                        <div className="row mt-2">
                            <div className="col">
                                <div className="mb-3 text-start">
                                    <label htmlFor="exampleFormControlTextarea1" className="bar1-para text-start">Commentaires :</label>

                                    <div className=" flex-row align-items-start">
                                        <textarea
                                            name="comments"
                                            value={values.comments}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.comments && errors.comments ? 'error' : 'form-control ml-1 shadow-none textarea'}>
                                        </textarea>
                                        {touched.comments && errors.comments && <div className="error-message">{errors.comments}</div>}
                                    </div></div>
                            </div>
                        </div>
                        <div className='row mt-5 mb-2'>
                            <div className='col text-start'>
                                <button type='button' onClick={handlePrev} className=' btns-color' >Précédent </button>
                            </div>
                            <div className="col text-end">
                                <button type='submit' className='btns-color' >Suivant</button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default Invalider