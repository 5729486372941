import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Formik } from 'formik';
import axios from 'axios';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
const ControleB2B = (props) => {
    const [obligeStore, setObligeStore] = useState(null);
    const Token = localStorage.getItem('Token');
    const MultiUpdate = props.MultiUpdate;
    console.log('Multiupdate', MultiUpdate);
    const navigate = useNavigate();
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);
    const [initialValues, setInitialValues] = useState(
        {
            id: MultiUpdate?.multisteptable2?.id,
            contributionFrameDate: '',
            quoteEditiondate: '',
            estimateDate: '',
            quoteSignatureDate: '',
            startDateOfWork: '',
            billDate: '',
            dateControlOfficeVisit: '',
            beneficiarySignatureDateOnAH: '',
            installerSignatureDateonAH: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            id: MultiUpdate?.multisteptable2?.id,
            contributionFrameDate: MultiUpdate?.multisteptable2?.contributionframedate || '',
            quoteEditiondate: MultiUpdate?.multisteptable2?.quotesignaturedate || '',
            estimateDate: MultiUpdate?.multisteptable2?.estimatedate || '',
            quoteSignatureDate: MultiUpdate?.multisteptable2?.quotesignaturedate || '',
            startDateOfWork: MultiUpdate?.multisteptable2?.startdateofwork || '',
            billDate: MultiUpdate?.multisteptable2?.billDate || '',
            dateControlOfficeVisit: MultiUpdate?.multisteptable2?.datecontrolofficevisit || '',
            beneficiarySignatureDateOnAH: MultiUpdate?.multisteptable2?.beneficiarysignaturedateonAH || '',
            installerSignatureDateonAH: MultiUpdate?.multisteptable2?.installersignaturedateonAH || '',
        }));
    }, [MultiUpdate]); 
    const validate = (values) => {
        console.log('values', values);
        const errors = {};

        return errors;
    };
    const handleSubmit = async (values) => {
        console.log("Estimation Page Values", values);
        sessionStorage.setItem("step-8", JSON.stringify(values));
        // window.open('https://www.geoportail.gouv.fr/', '_blank');
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/update-multistep`, values,
                {
                    headers: { authorization: Token }
                }).then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        console.log('res.message', response?.data?.message);
                        toastsuccess(response?.data?.message);
                    }
                })
        } catch (error) {
            console.log(error.response?.data?.message);
            toasterror(error.response?.data?.message);
        }
    }
    const disableDates = (contributionFrameDate) => {
        if (!contributionFrameDate) return null;
        const [day, month, year] = contributionFrameDate.split('.');
        const contributionDate = new Date(`${year}-${month}-${day}`);
        return contributionDate;
    };
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const option = {
    //             headers: { authorization: Token }
    //         };

    //         try {
    //             const [obligeResponse] = await Promise.all([
    //                 axios.get(`${process.env.REACT_APP_API_URL}/get-oblige/${MultiUpdate?.multisteptable1?.oblige_id}`, option)
    //             ]);
    //             setObligeStore(obligeResponse?.data);
    //             console.log(obligeResponse?.data);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };

    //     fetchData();
    // }, []);
    // let contract_start;
    // const separateDate = obligeStore?.oblige?.map((item) => {
    //     console.log(item?.partnership_contract_start_date);
    //     contract_start = item?.partnership_contract_start_date;
    // })
    // console.log('contract_start', contract_start);
    // const disableDatesOnApi = (contract_start) => {
    //     if (!contract_start) return null;
    //     const [day, month, year] = contract_start.split('.');
    //     const contributionDate = new Date(`${year}-${month}-${day}`);
    //     return contributionDate;
    // };
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid pb-4'>
                            <div className="row">
                                <div className="save_button_container d-flex justify-content-between align-items-center">
                                    <p className="bar1-head  mt-4">Contrôle </p> <button type='submit' className='btns-color'>Sauvegarder</button>
                                </div>
                            </div>
                            <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2">
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date de cadre de contribution</p>
                                    <DatePicker
                                        selected={values.contributionFrameDate ? new Date(values.contributionFrameDate) : null}
                                        values={values.contributionFrameDate ? new Date(values.contributionFrameDate) : null}
                                        onChange={(date) => {
                                            if (date) {
                                                const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                                const formattedDate = adjustedDate.toISOString().split('T')[0];
                                                setFieldValue('contributionFrameDate', formattedDate);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        name="contributionFrameDate"
                                        dateFormat="dd.MM.yyyy"
                                        className='f-left '
                                        placeholderText='dd/mm/yyyy'
                                    />

                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date édition devis</p>
                                    <DatePicker
                                        name="quoteEditiondate"
                                        selected={values.quoteEditiondate ? new Date(values.quoteEditiondate) : null}
                                        onChange={(date) => {
                                            if (date) {
                                                const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                                const formattedDate = adjustedDate.toISOString().split('T')[0];
                                                setFieldValue('quoteEditiondate', formattedDate);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd.MM.yyyy"
                                        className='f-left'
                                        placeholderText='dd/mm/yyyy'
                                    />
                                    {/* <input type='date'
                                    name="quoteEditiondate"
                                    value={values.quoteEditiondate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.quoteEditiondate && errors.quoteEditiondate ? 'error' : 'f-left ps-3 '}
                                    min={disableDatesOnApi(contract_start)} />
                                {touched.quoteEditiondate && errors.quoteEditiondate && <div className="error-message">{errors.quoteEditiondate}</div>} */}
                                </div>

                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date de previsite </p>
                                    <DatePicker
                                        name="estimateDate"
                                        selected={values.estimateDate ? new Date(values.estimateDate) : null}
                                        onChange={(date) => {
                                            const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                            const formattedDate = adjustedDate.toISOString().split('T')[0];
                                            setFieldValue('estimateDate', formattedDate);
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd.MM.yyyy"
                                        className='f-left'
                                        placeholderText='dd/mm/yyyy'
                                    />

                                </div>

                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date signature devis</p>
                                    <DatePicker
                                        name="quoteSignatureDate"
                                        selected={values.quoteSignatureDate ? new Date(values.quoteSignatureDate) : null}
                                        onChange={(date) => {
                                            if (date) {
                                                const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                                const formattedDate = adjustedDate.toISOString().split('T')[0];
                                                setFieldValue('quoteSignatureDate', formattedDate);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd.MM.yyyy"
                                        className='f-left'
                                        placeholderText='dd/mm/yyyy'
                                    />

                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date debut des travaux</p>
                                    <DatePicker
                                        name="startDateOfWork"
                                        selected={values.startDateOfWork ? new Date(values.startDateOfWork) : null}
                                        onChange={(date) => {
                                            if (date) {
                                                const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                                const formattedDate = adjustedDate.toISOString().split('T')[0];
                                                setFieldValue('startDateOfWork', formattedDate);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd.MM.yyyy"
                                        className='f-left'
                                        placeholderText='dd/mm/yyyy'
                                    />
                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date de facture</p>
                                    <DatePicker
                                        name="billDate"
                                        selected={values.billDate ? new Date(values.billDate) : null}
                                        onChange={(date) => {
                                            if (date) {
                                                const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                                const formattedDate = adjustedDate.toISOString().split('T')[0];
                                                setFieldValue('billDate', formattedDate);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd.MM.yyyy"
                                        className='f-left'
                                        placeholderText='dd/mm/yyyy'
                                    />

                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date visite bureau de contrôle</p>
                                    <DatePicker
                                        name="dateControlOfficeVisit"
                                        selected={values.dateControlOfficeVisit ? new Date(values.dateControlOfficeVisit) : null}
                                        onChange={(date) => {
                                            if (date) {
                                                const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                                const formattedDate = adjustedDate.toISOString().split('T')[0];
                                                setFieldValue('dateControlOfficeVisit', formattedDate);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd.MM.yyyy"
                                        className='f-left'
                                        placeholderText='dd/mm/yyyy'
                                    />

                                </div>

                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date signature du beneficiare sur AH</p>
                                    <DatePicker
                                        name="beneficiarySignatureDateOnAH"
                                        selected={values.beneficiarySignatureDateOnAH ? new Date(values.beneficiarySignatureDateOnAH) : null}
                                        onChange={(date) => {
                                            if (date) {
                                                const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                                const formattedDate = adjustedDate.toISOString().split('T')[0];
                                                setFieldValue('beneficiarySignatureDateOnAH', formattedDate);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd.MM.yyyy"
                                        className='f-left'
                                        placeholderText='dd/mm/yyyy'
                                    />
                                </div>
                                <div className="col input-box1 mt-3">
                                    <p className='bar1-para'>Date signature de l'installateur sur AH</p>
                                    <DatePicker
                                        name="installerSignatureDateonAH"
                                        selected={values.installerSignatureDateonAH ? new Date(values.installerSignatureDateonAH) : null}
                                        onChange={(date) => {
                                            if (date) {
                                                const adjustedDate = new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
                                                const formattedDate = adjustedDate.toISOString().split('T')[0];
                                                setFieldValue('installerSignatureDateonAH', formattedDate);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd.MM.yyyy"
                                        className='f-left'
                                        placeholderText='dd/mm/yyyy'
                                    />

                                </div>
                            </div>
                            <div className='row mt-2 mb-2 d-flex align-items-center'>
                                {/* <div className='col text-start'>
                                <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                            </div> */}
                                <div className='col mt-5 mb-2 text-end'>
                                    <Link to='https://www.geoportail.gouv.fr/' target="_blank" className='btns-color2 links'>  Valider Geoportail
                                        {/* <button type='submit' className='btns-color2 links' > Valider Geoportail</button> */}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default ControleB2B