import React from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { MdDeleteSweep } from "react-icons/md";
import { Link } from "react-router-dom"
import axios from 'axios'
import { useEffect, useState, useRef } from 'react';
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from 'react-toastify';
// XLSX
import * as XLSX from 'xlsx';
// DataTables
import $ from 'jquery'; // Import jQuery
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net/js/jquery.dataTables.min.js';
import 'datatables.net';
function Filedashboard() {
    const [data, setData] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);
    const Token = localStorage.getItem('Token');
    const tableRef = useRef(null);
    useEffect(() => {
        if (!isLoading) {
            const table = $(tableRef.current).DataTable({
                orderCellsTop: true,
                fixedHeader: true,
                language: {
                    search: 'éléments',
                },
            });

            // Enable searching for input elements in the header
            table.columns().every(function () {
                const that = this;
                $('input:not([type="checkbox"])', this.header()).on('keyup change', function () {
                    if (that.search() !== this.value) {
                        that.search(this.value).draw();
                    }
                });
                // $('input', this.header()).on('keyup change', function () {
                //     if (that.search() !== this.value) {
                //         that.search(this.value).draw();
                //     }
                // });
            });

            return () => {
                table.destroy();
            };
        }
    }, [isLoading]);

    const fetchData = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/getall-multistep`, {
                headers:
                    { authorization: Token }
            }).then(res => {
                console.log(res?.data);
                setData(res?.data);
                setIsLoading(false);
            })
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);


    // FIRST METHOD
    // useEffect(() => {
    //     if (!isLoading) {
    //         const table = $(tableRef.current).DataTable({
    //             orderCellsTop: true,
    //             fixedHeader: true,
    //             language: {
    //                 search: 'éléments',
    //             }
    //         }); return () => {
    //             table.destroy();
    //         };
    //     }
    // }, [isLoading]);


    if (isLoading) {
        return <Spinner animation="border" className='text-success' />;
    };
    // DELETE
    const handleDelete = async (id) => {
        console.log('id', id);
        try {
            if (window.confirm("Do You Want to Delete?")) {
                const deleteresponse = await axios.delete(`${process.env.REACT_APP_API_URL}/delete-multistep/${id}`,
                    {
                        headers: { authorization: Token },
                    }
                );
                if (deleteresponse?.status === 200) {
                    fetchData();
                    toastsuccess(deleteresponse?.data?.message);
                };
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };
    // MULTI DELETE
    const handleCheckboxChange = (id) => {
        console.log('id:', id);
        setSelectedIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelectedIds, id];
            }
        });
    };
    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedIds([]);
        } else {
            const allItemIds = data?.AllData?.map((item) => item?.multistep1?.id);
            setSelectedIds(allItemIds);
        }
        setSelectAll(!selectAll);
    };
    // MULTI DELETE
    const handleMultiDelete = async () => {
        try {
            if (selectedIds.length > 0 && window.confirm("Voulez-vous supprimer les éléments sélectionnés ?")) {
                const multidelete = await axios.post(
                    `${process.env.REACT_APP_API_URL}/multi-delete-multistep`,
                    { ids: selectedIds },
                    {
                        headers: { authorization: Token },
                    }
                );
                if (multidelete?.status === 200) {
                    toastsuccess(multidelete?.data?.message);
                    fetchData();
                }
            } else {
                toasterror("Veuillez sélectionner au moins un élément à supprimer");
                // alert("Please select at least one item to delete.");
            }
        } catch (error) {
            toasterror(error?.multidelete?.data?.message);
        }
    };
    // EXPORT DATA
    const exportToExcel = async () => {
        console.log(selectedIds);
        try {
            if (selectedIds.length === 0) {
                toasterror("Veuillez sélectionner au moins un élément à exporter");
                return;
            }
            const PostExportStatus = await axios.post(
                `${process.env.REACT_APP_API_URL}/update-export-status`, { ids: selectedIds },
                {
                    headers: { authorization: Token },
                }
            );
            if (PostExportStatus?.status === 200) {
                fetchData();
            }
            const getSingleData = await axios.post(
                `${process.env.REACT_APP_API_URL}/get-all-specific-multistep`, { ids: selectedIds },
                {
                    headers: { authorization: Token },
                }
            );
            if (getSingleData?.status === 200) {
                console.log(getSingleData);
                const selectedData = getSingleData?.data?.AllData;
                console.log(selectedData);

                const formattedData = selectedData?.map(item => {
                    return {
                        "service sélectionné": item.multistep1.serviceselect,
                        "Code équipement": item.multistep1.documents,
                        "Date de début": item.multistep2.startdateofwork,
                        "Date de fin": item.multistep2.estimatedate,
                        "Date de la facture": item.multistep2.contributionframedate,
                        "Tableau Recapitulatif champ 1": item.multistep1.beneficiary_socialreason,
                        "Tableau Recapitulatif champ 2": item.multistep1.beneficiary_nomcommercial,
                        "Tableau Recapitulatif champ 3": item.multistep1.beneficiary_nsiret,
                        "Tableau Recapitulatif champ 4": item.multistep1.beneficiary_representativename,
                        "Tableau Recapitulatif champ 5": item.multistep1.beneficiary_firstname,
                        "Tableau Recapitulatif champ 6": item.multistep1.beneficiary_hisfunction,
                        "Tableau Recapitulatif champ 7": item.multistep1.beneficiary_address,
                        "Tableau Recapitulatif champ 8": item.multistep1.beneficiary_cp,
                        "Tableau Recapitulatif champ 9": item.multistep1.beneficiary_city,
                        "Tableau Recapitulatif champ 10": item.multistep1.beneficiary_email,
                        "Tableau Recapitulatif champ 11": item.multistep1.beneficiary_nfixed,
                        "Tableau Recapitulatif champ 12": item.multistep1.beneficiary_nMobile,
                        "Tableau Recapitulatif champ 13": item.multistep1.chantier_address,
                        "Tableau Recapitulatif champ 14": item.multistep1.chantier_cp,
                        "Tableau Recapitulatif champ 15": item.multistep1.chantier_city,
                        "Tableau Recapitulatif champ 16": item.multistep1.chantier_wardname,
                        "Tableau Recapitulatif champ 17": item.multistep1.chantier_choosetype,
                        "Tableau Recapitulatif champ 18": item.multistep1.chantier_greatprecariousness,
                        "Tableau Recapitulatif champ 19": item.multistep1.chantier_precarious,
                        "Tableau Recapitulatif champ 20": item.multistep1.chantier_districtCode,
                        "Tableau Recapitulatif champ 21": item.multistep1.chantier_contact,
                        "Tableau Recapitulatif champ 22": item.multistep1.chantier_telephone,
                        "Montant du rôle actif et incitatif": item.multistep1.chantier_typeofbuilding,
                        "PAEE": item.multistep1.chantier_ageofbuilding,
                        "SME": item.multistep1.chantier_heatingmode,
                        "Bonus coup de pouce": item.multistep1.chantier_buildingfloorarea,
                        "Cas précarité": item.multistep1.b2boperations,
                        "Nombre total de ménages": item.multistep1.estimate_address,
                        "Nombre total de ménages occupant des logements sociaux": item.multistep1.estimate_amountofbeneficiarypremium,
                        "Nombre de ménages en situation de précarité": item.multistep1.estimate_attendancedate_and_signatureofthecustomer,
                        "Nombre de ménages en situation de grande précarité": item.multistep1.estimate_beneficiaryaddress,
                        "Pourcentage de ménages en situation de précarité /modeste": item.multistep1.estimate_beneficiaryemail,
                        "Pourcentage de ménages en situation de grande précarité /précarité": item.multistep1.estimate_beneficiaryfirstname,
                        "Type de logement": item.multistep1.estimate_beneficiaryfunction,
                        "Département": item.multistep1.estimate_beneficiaryname,
                        "Altitude": item.multistep1.estimate_beneficiaryphone,
                        "Iles": item.multistep1.estimate_beneficiarysiret,
                        "Nombre d'opérations": item.multistep1.estimate_brand,
                        "Résistance Thermique": item.multistep1.estimate_city,
                        "Energie de chauffage": item.multistep1.estimate_cp,
                        "Surface (m²)": item.multistep1.estimate_goodforagreement,
                        "PES": item.multistep1.estimate_handwrittendate,
                        "COP": item.multistep1.estimate_identityofthe_beneficiary,
                        "Nombre de pièces": item.multistep1.estimate_incentiverole_compliance,
                        "Surface habitable": item.multistep1.estimate_installer_contactdetails,
                        "Usage": item.multistep1.estimate_insulationclass,
                        "Type de chauffage": item.multistep1.estimate_mentionofthe_incentiverole,
                        "PTH": item.multistep1.estimate_mentionsrequiredby_theoperationsheet,
                        "Mètre linéaire (m)": item.multistep1.estimate_nsiret,
                        "Type": item.multistep1.estimate_othermandatory_materialmentions,
                        "Type appareil": item.multistep1.estimate_phone,
                        "Participation financière (en Euros)": item.multistep1.estimate_placeofwork,
                        "Secteur": item.multistep1.estimate_presenceofthestandard,
                        "Branche": item.multistep1.estimate_purchaseorderno,
                        "date d'émission du devis d'estimation": item.multistep1.estimate_quoteissuedate,
                        "Puissance chaudière": item.multistep1.estimate_quoteno,
                        "Application": item.multistep1.estimate_raipremium,
                        "Puissance (en Watts)": item.multistep1.estimate_reference,
                        "Puissance BTU": item.multistep1.estimate_siretinstaller,
                        "Puissance Tube": item.multistep1.estimate_socialreason,
                        "Puissance Lampe iodure": item.multistep1.estimate_subcontractor_contactdetails,
                        "Type Lampe": item.multistep1.estimate_surnamefirstname,
                        "Source d'énergie": item.multistep1.estimate_tradename,
                        "CRN - Chaleur renouvelable nette/an (en kWhth)": item.multistep1.estimate_marque,
                        "Type de canalisation": item.multistep1.estimate_classedelisolant,
                        "DN aller": item.multistep1.estimate_materialmentions,
                        "Duree moyenne": item.multistep1.bill_beneficiaryname,
                        "Mode de fonctionnement": item.multistep1.bill_beneficiaryfirstname,
                        "Puissance luminaire": item.multistep1.bill_beneficiaryphone,
                        "Type de moteur": item.multistep1.bill_beneficiarysiret,
                        "Type d'application": item.multistep1.bill_cp,
                        "Application moteur": item.multistep1.bill_diameter_and_thicknesses,
                        "Combustible": item.multistep1.bill_identityofthe_beneficiary,
                        "Longueur UTI": item.multistep1.bill_installercontact_details,
                        "Durée du contrat": item.multistep1.bill_invoiceissuedate,
                        "Type autobus": item.multistep1.bill_invoiceNumber,
                        "Type véhicule": item.multistep1.bill_lieudes_travaux,
                        "Longueur canalisation (m)": item.multistep1.bill_nsiret,
                        "C'ref": item.multistep1.bill_operationsheet,
                        "C'projet": item.multistep1.bill_phone,
                        "Shon (m²)": item.multistep1.bill_quoteno,
                        "Caisson": item.multistep1.bill_siretinstaller,
                        "Part des besoins": item.multistep1.bill_socialreason,
                        "TCB": item.multistep1.bill_subcontractor_contactdetails,
                        "Locaux": item.multistep1.bill_tradename,
                        "Lux": item.multistep1.bill_vatrate,
                        "Nombre de blocs": item.multistep1.bill_beneficiaryaddress,
                        "Durée de vie": item.multistep1.bill_beneficiaryemail,
                        "Réfrigérant": item.multistep1.bill_beneficiaryfunction,
                        "C (en % surface des bâtiments existants alimentés)": item.multistep1.bill_address,
                        "Type transformateur": item.multistep1.bill_city,
                        "Pertes à vide": item.multistep1.attestation_ahformatcompliant,
                        "Pertes en charge": item.multistep1.attestation_beneficiarys_signatureandstamp,
                        "Nombre moyen d'utilisateurs": item.multistep1.attestation_complete_bframe,
                        "Activité": item.multistep1.attestation_complete_qbvsframe,
                        "Température de la source (en °C)": item.multistep1.attestation_fFull_cFrame,
                        "P pompe cond": item.multistep1.attestation_full_aframe,
                        "Dcta": item.multistep1.attestation_signature_and_stamp,
                        "Delta T °C": item.multistep1.comments,
                        "T Condensation initiale °C": item.multistep1.final_status,
                        // TABLE 2
                        "Production de froid": item.multistep2.beneficiaryoperation,
                        "Montant des ventes de lubrifiants en m3": item.multistep2.heatingenergy,
                        "Performance « fuel economy » du lubrifiant en %": item.multistep2.roofwindows,
                        "Nombre de pneus": item.multistep2.windowsorpatiodoors,
                        "Nombre de kilomètres": item.multistep2.noofapartment,
                        "Classe énergétique": item.multistep2.residentialbuilding,
                        "Delta T condenseur": item.multistep2.premiumamount,
                        "Niveau d'évaporation": item.multistep2.buildingindustry,
                        "Type d'installation": item.multistep2.heatedzone,
                        "Somme": item.multistep2.startofwork,
                        "Energy Efficiency Ratio": item.multistep2.tertiarysector,
                        "Nombre de capteurs": item.multistep2.twelvemonths,
                        "Nombre de fenêtres ou porte-fenêtres": item.multistep2.typeofpose,
                        "Régulateur de jets": item.multistep2.morethan2years,
                        "Cadre de la demande": item.multistep2.heatedorUnheated,
                        "Durée de garantie de performance du CPE": item.multistep2.bmssystem,
                        "Facteur solaire": item.multistep2.managedbmssystem,
                        "Type de transport": item.multistep2.newbmssystem,
                        "Typologie d'autoroute ferroviaire": item.multistep2.operationconcern,
                        "Cef initial": item.multistep2.signature,
                        "Cef projet": item.multistep2.boiler,
                        "Ubât initial": item.multistep2.hydraulicnetwork,
                        "Ubât projet": item.multistep2.oldinsulation,
                        "Pourcentage d'économie d'énergie garantie par le CPE": item.multistep2.primeamount,
                        "T.km": item.multistep2.tertiary_building,
                        "P modifiée": item.multistep2.classZZshowerheads,
                        "P installée": item.multistep2.classZZunregulated,
                        "Conso. spécifique": item.multistep2.classZshowerheads,
                        "Production annuelle": item.multistep2.classZunregulated,
                        "Gain préchauffage": item.multistep2.kilowater,
                        "Ecart de température": item.multistep2.newradiators,
                        "Installation": item.multistep2.petgp,
                        "Récupération pour valorisation": item.multistep2.partgpetp,
                        "Bassin de navigation fluviale": item.multistep2.daysafter,
                        "Rg à 0°C": item.multistep2.buildingmorethan2years,
                        "Rg à -20°C": item.multistep2.codepostal,
                        "Hauteur du local h (en mètres)": item.multistep2.heatedareaandanunheated,
                        "Nombre d'effets": item.multistep2.heatedareaandanunheated2,
                        "Pourcentage": item.multistep2.order,
                        "Nombre de places maternité": item.multistep2.roof,
                        "Nombre de places post sevrage": item.multistep2.surface,
                        "Versement au fonds": item.multistep2.thermalinsulation,
                        "Facteur de proportionnalité": item.multistep2.area,
                        "Type montage": item.multistep2.oblige,
                        "Teff": item.multistep2.partenaire,
                        "Type meuble": item.multistep2.oblige_surface,
                        "Position du groupe de production": item.multistep2.obligeValuePrice,
                        "Coefficient de bonification": item.multistep2.partenaireValuePrice,
                        "Nature du fluide distribué": item.multistep2.neighborhoodType,
                        "Année de mise en circulation": item.multistep2.obligecumacvalue,
                        "Gain dû à l'équipement": item.multistep2.obligetotalpremiumamount,
                        "Longueur de meubles frigorifiques équipés de couvercles (m)": item.multistep2.obligeprofitshare,
                        "Nombre de points singuliers isolés": item.multistep2.obligeshareinstallerHT,
                        "Montant en kWh Cumac": item.multistep2.obligeVAT,
                        "Type de ventilation": item.multistep2.obligeTotalPrice,
                        "Type de séquenceur": item.multistep2.obligebeneficiarypremium,
                        "Nombre de compresseurs pilotés": item.multistep2.partnercumacvalue,
                        "Durée annuelle d'utilisation du réseau": item.multistep2.partnertotalpremiumamount,
                        "Nombre d?appartements": item.multistep2.partnerprofitshare,
                        "Type ?T condenseur": item.multistep2.partnershareinstallerHT,
                        "Coefficient": item.multistep2.partnerVAT,
                        "Volume": item.multistep2.partnertotalprice,
                        "Gain (%)": item.multistep2.partnerbeneficiarypremium,
                        "Concentration": item.multistep2.beneficiarysignaturedateonAH,
                        "COPré": item.multistep2.beneficiarysignaturedateonSH,
                        "Multisplit": item.multistep2.billDate,
                        "Taux (%)": item.multistep2.datecontrolofficevisit,
                        "Efficacité énergétique saisonnière": item.multistep2.installersignaturedateonAH,
                        "Cas": item.multistep2.quoteeditiondate,
                        "R": item.multistep2.quotesignaturedate,
                        "Indice de protection aux chocs (IK)": item.multistep2.validdatasstore,
                        "Système de gestion de l'éclairage": item.multistep2.additionaladdress,
                        "Dimensions du tube  LED": item.multistep2.addressofwork,
                        "Type d'acquisition": item.multistep2.brand,
                        "Mode de chauffage": item.multistep2.brandv2,
                        "Moyenne des émissions de CO2 en gCO2/km des véhicules acquis ou loués": item.multistep2.city,
                        "Puissance Lampe LED": item.multistep2.dateofcommitment,
                        "Rg pondéré": item.multistep2.dateofproof,
                        "Nombre d'entités": item.multistep2.invoicereference,
                        "Cefmax : consommation conventionnelle d'énergie maximale": item.multistep2.lighting,
                        "Cefbat : consommation conventionnelle d'énergie du bâtiment": item.multistep2.luminaires,
                        "Flux lumineux": item.multistep2.model,
                        "modèle v2": item.multistep2.modelv2,
                        "choix 1": item.multistep2.noofnewlights,
                        "choix 2": item.multistep2.noofnewlightsv2,
                        "choix 3": item.multistep2.reference,
                        "choix 4": item.multistep2.referencev2,
                        "choix 5": item.multistep2.types,
                        "choix 6": item.multistep2.typev2,
                        "Commentaire": item.multistep2.installation_type,
                        "Numéro d'identification du membre": item.multistep2.building2yearsold,
                        "chauffage hydraulique": item.multistep2.hydraulicheating,
                        "installation": item.multistep2.installation,
                        "adressedestravaux": item.multistep2.adressedestravaux,
                        "adressedestravaux2": item.multistep2.adressedestravaux2,
                        "e-mail b2c": item.multistep2.b2cemail,
                        "surface de plancher du bâtiment": item.multistep2.buildingFloorArea,
                        "choisissez Type": item.multistep2.chooseType,
                        "cp de travaux": item.multistep2.cpdestravaux,
                        "menages": item.multistep2.menages,
                        "nfixe": item.multistep2.nfixe,
                        "nfiscal": item.multistep2.nfiscal,
                        "nmobile": item.multistep2.nmobile,
                        "nom du client:": item.multistep2.nomduclient,
                        "nreffiscal": item.multistep2.nreffiscal,
                        "prénom du client": item.multistep2.prénomduclient,
                        "avis d'imposition": item.multistep2.taxnotice,
                        "ville des travaux": item.multistep2.villedestravaux,
                        "adresse des travaux": item.multistep2.addressofworks,
                        "rendez-vous b2c": item.multistep2.b2c_date,
                        "initiale cef": item.multistep2.cef_initial,
                        "projet cef": item.multistep2.cef_projet,
                        "cèpe initiale": item.multistep2.cep_initial,
                        "projet cep": item.multistep2.cep_projet,
                        "référentiel de certification": item.multistep2.certification_reference,
                        "Date d'engagement": item.multistep2.commitmentdate,
                        "date de l'énergie": item.multistep2.date_of_energy,
                        "gain d'énergie": item.multistep2.energy_gain,
                        "référentiel d'étude énergétique": item.multistep2.energy_study_reference,
                        "travail sur le terrain": item.multistep2.field_work,
                        "prénom": item.multistep2.first_name,
                        "serre": item.multistep2.greenhouse,
                        "superficie pompe à chaleur": item.multistep2.heatpump_surface,
                        "maison individuelle": item.multistep2.individual_house,
                        "personnes morales": item.multistep2.legalpersons,
                        "nom du représentant": item.multistep2.representative_name,
                        "numéro de sirène": item.multistep2.siren_number,
                        "numéro de siret": item.multistep2.siret_number,
                        "raison social": item.multistep2.social_reason,
                        "nom du logiciel": item.multistep2.software_name,
                        "une version de logiciel": item.multistep2.software_version,
                        "types de bâtiment": item.multistep2.typeOfBuilding,
                        "partie classique": item.multistep2.partclassique,
                        "mode de chauffage": item.multistep2.heatingMode,
                        "marque de boîte": item.multistep2.box_brand,
                        "référence du boîtier": item.multistep2.casing_reference,
                        "date d'engagement": item.multistep2.date_of_commitment,
                        "logements desservis": item.multistep2.dwellings_served,
                        "classe énergétique": item.multistep2.energy_class,
                        "échangeur thermique": item.multistep2.exchanger_thermal,
                        "échangeur statique": item.multistep2.static_exchanger,
                        "types d'installation": item.multistep2.type_installation,
                        "référence unitaire": item.multistep2.unit_reference,
                        "marque de soupape": item.multistep2.valve_brand,
                        "cop": item.multistep2.cop,
                        "type de logement": item.multistep2.housing_type,
                        "nom": item.multistep2.nom,
                        "prénom 1": item.multistep2.prénom,
                        "nom de travail": item.multistep2.work_name,
                        "basse température": item.multistep2.lowtemperature,
                        "notes de dimensionnement": item.multistep2.sizing_note,
                        "énergie saisonnière": item.multistep2.seasonal_energy,
                        "type de pompe à chaleur": item.multistep2.heatpump_type,
                        "étiquette chaudière 7": item.multistep2.boiler_7label,
                        "opérateur de chaudière": item.multistep2.boiler_operated,
                        "chaudières 225": item.multistep2.boilers_225,
                        "réservoir tampon": item.multistep2.buffer_tank,
                        "gazeux organique": item.multistep2.gaseous_organic,
                        "oxydes d'azote": item.multistep2.nitrogen_oxides,
                        "chaleur nominale": item.multistep2.rated_heat,
                        "classe réglementaire": item.multistep2.regulator_class,
                        "carbone saisonnier": item.multistep2.seasonal_carbon,
                        "particules saisonnières": item.multistep2.seasonal_particulate,
                        "note de dimensionnement": item.multistep2.sizingnote,
                        "bois": item.multistep2.wood,

                        "couverture_isolation": item.multistep2.cover_insulation,
                        "couvre le système": item.multistep2.covers_system,
                        "diamètre 20 à 65 V1": item.multistep2.diameter20to65V1,
                        "diamètre 20 à 65 V2": item.multistep2.diameter20to65V2,
                        "diamètre 66 à 100 V1": item.multistep2.diameter66to100V1,
                        "diamètre 66 à 100 V2": item.multistep2.diameter66to100V2,
                        "diamètre 101 à 150 V1": item.multistep2.diameter101to150V1,
                        "diamètre 101 à 150 V2": item.multistep2.diameter101to150V2,
                        "échangeur de chaleur V1": item.multistep2.heatexchangerV1,
                        "échangeur de chaleur V2": item.multistep2.heatexchangerV2,
                        "QPV": item.multistep2.pipes_insulated,
                        "Total précarité": item.multistep2.residentieltwoyears,
                        "Total classique": item.multistep2.singular_points,
                    };
                });

                const ws = XLSX.utils.json_to_sheet(formattedData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'winenergycrm-export');

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
                const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'winenergy_data.xlsx';
                a.click();
                URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log(error?.message);
            toasterror(error?.message);
        }
    };

    return (
        <div className="container-fluid px-4 py-2">
            <div className='table-responsive py-3'>
                <table ref={tableRef} className="table" id='FileDashboard'>
                    <MdDeleteSweep className='icon_size icon-absolute' title='Delete Selected Items' onClick={handleMultiDelete} />
                    <thead>
                        <tr>
                            <th scope="col text-left disable-sorting"><input type='checkbox' checked={selectAll} onChange={handleSelectAllChange} /></th>
                            <th scope="col" className='bar1-para'>Service sélectionné <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Oblige <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Installateur <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Operation <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Dossier number <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Raison sociale <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Address <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>CP <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Ville <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Date de facture <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Statut <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Exporté Statut <input type='text' className='form-control w-auto' placeholder='Filter' /></th>
                            <th scope="col" className='bar1-para'>Editer</th>
                            <th scope="col" className='bar1-para'>Supprimer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.AllData?.map((item, index) => (
                            <tr key={item?.multistep1?.id} className='bg-light'>
                                <th scope="row"><input type="checkbox" checked={selectedIds.includes(item?.multistep1?.id)} onChange={() => handleCheckboxChange(item?.multistep1?.id)} /></th>
                                <td className='bar1-para'>{item?.multistep1?.documents}</td>
                                <td className='bar1-para'>{item?.multistep2?.oblige_name}</td>
                                <td className='bar1-para'>{item?.multistep2?.partenaire_name}</td>
                                <td className='bar1-para'>{item?.multistep1?.serviceselect}</td>
                                <td className='bar1-para'>{item?.multistep1?.estimate_siretinstaller}</td>
                                {/* <td className='bar1-para'>{index + 1}</td> */}
                                <td className='bar1-para'>{item?.multistep1?.beneficiary_socialreason}</td>
                                <td className='bar1-para'>{item?.multistep1?.beneficiary_address}</td>
                                <td className='bar1-para'>{item?.multistep1?.beneficiary_cp}</td>
                                <td className='bar1-para'>{item?.multistep1?.beneficiary_city}</td>
                                <td className='bar1-para'>{item?.multistep1?.createdAt.split('T')[0]}</td>
                                <td className='bar1-para'>{item?.multistep1?.final_status}</td>
                                <td className='bar1-para'>{item?.multistep1?.isdataExported}</td>
                                {/* <td><Link to={`/update-create-dossier/${item?.multistep1?.id}`}><FiEdit2 className='bar1-para pe-auto border-0' /></Link></td> */}
                                <td><Link to={`/edition-de-dossier${item?.multistep1?.serviceselect === 'b2b' ? '' : '/b2c'}/${item?.multistep1?.id}`}><FiEdit2 className='bar1-para pe-auto border-0' /></Link></td>
                                <td><RiDeleteBin6Line className='bar1-para pe-auto border-0' onClick={() => handleDelete(item?.multistep1?.id)} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            <div>
                <div className="text-end mt-4">
                    <Button className='btns-color2 links' onClick={exportToExcel}>
                        Export
                    </Button>
                </div>


            </div>
        </div>
    )
}

export default Filedashboard;