import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs";

function Bar102() {
    const navigate = useNavigate();
    const handlePrev = () => {
      navigate(-1);
    };
    return (
        <div className="container-fluid px-4 py-3">
            <div className='row'>
                <p className='bar1-para '>BAR-EN-102 : Isolation de mur ou façade  <span onClick={handlePrev}  className='border-0 bg-white pe-auto'><BsArrowLeft className='left-arow pe-auto' /></span></p>
                <div className='button-1 mt-1 mb-4'>
                    <Link to={"/calcult"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
                </div>
                <div className=' bar1-tittle'>

                    <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                        <h3 className='bar1-head'>1. Secteur d’application</h3>
                        <p className='bar1-para'>Bâtiments résidentiels existants.</p>
                        <h3 className='bar1-head'>2. Dénomination</h3>
                        <p className='bar1-para'>Mise en place d’une isolation thermique en comble perdu ou en rampant de toiture.</p>
                        <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
                        <p className='bar1-para'>La résistance thermique R de l’isolation installée est supérieure ou égale à 3,7 m2.K/W.<br></br> </p>
                        <p className='bar1-para'>La mise en place est réalisée par un professionnel.</p>
                        <p className='bar1-para'>Si le bénéficiaire est une personne physique, le professionnel ayant réalisé l’opération est titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues à l’article 2 du décret n° 2014-812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris pour son application.</p>
                        <p className='bar1-para'>Ce signe de qualité correspond à des travaux relevant du 3 du I de l’article 46 AX de l’annexe III du code général des impôts.</p>
                        <p className='bar1-para'>La preuve de la réalisation de l’opération mentionne : </p>
                        <ul className='bar1-para'>
                            <li>la mise en place d’une isolation ;</li>
                            <li>et la surface d’isolant installé ;</li>
                            <li>et la résistance thermique de l’isolation installée.</li></ul>
                        <p className='bar1-para'>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un matériau avec ses marque et référence et la surface installée, et elle est complétée par un document issu du fabricant ou d’un organisme établidans l’Espace économique européen et accrédité selon la norme NF EN 45011 par le Comité français d’accréditation (COFRAC) ou tout autre organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris dans le cadre de European co-operation for Accreditation (EA), coordination européenne des organismes d’accréditation. Ce document indique que le matériau de marque et référence mis en place est un isolant et précise ses caractéristiques thermiques (résistance thermique ; ou conductivité thermique et épaisseur). En cas de mention d’une date de validité, ce document est considéré comme valable jusqu’à un an après sa date de fin de validité. Pour les références proposées en différentes épaisseurs, la preuve de réalisation, si elle ne mentionne pas la résistance thermique de l’isolation installée, doit impérativement en préciser l’épaisseur.</p>
                        <p className='bar1-para'>Le document justificatif spécifique à l’opération est la décision de qualification ou de certification du professionnel délivrée selon les mêmes exigences que celles prévues à l’article 2 du décret susvisé.</p>
                        <p className='bar1-para bar1-ul'>Nouvel arrêté : </p>

                        <p className='bar1-para'>« Art. 8-12. – I. – Des contrôles sont réalisés sur les opérations relevant des fiches d’opérations standardisées BAR-EN-102 “Isolation des murs”, BAR-EN-107 “Isolation des murs (France d’outre-mer)”, BAT-EN-102 “Isolation des murs”, BAT-EN-108 “Isolation des murs (France d’outre-mer)”, IND-EN-101 “Isolation des murs (France d’outre-mer)” et IND-UT-131 “Isolation thermique des parois planes ou cylindriques sur des installations industrielles (France métropolitaine)” annexées à l’arrêté du 22 décembre 2014 définissant les opérations standardisées d’économies d’énergie, préalablement au dépôt des demandes de certificats d’économies d’énergie auprès du Pôle national des certificats d’économies d’énergie (PNCEE).</p>

                        <p className='bar1-para'>«Les contrôles conduits sur le lieu des opérations conformément au premier alinéa de l’article 8-5 sont menés par un organisme de contrôle choisi par le demandeur et accrédité sous les conditions fixées au I de l’article 8-2 en respectant les dispositions des articles 8-6 et 8-8.  </p>

                        <p className='bar1-para'>«II. – Les contrôles sont menés sur des opérations sélectionnées de façon aléatoire par l’organisme de contrôle lorsque le demandeur a recours à cet organisme, ou par le demandeur de certificats lorsque celui-ci procède lui- même au contrôle par contact, au sein de la liste complète des opérations incluses dans un dossier de demande de certificats d’économies d’énergie de manière à ce que les contrôles satisfaisants couvrent, pour chaque dossier de demande et pour chaque fiche prise séparément:</p>
                        <p className='bar1-para'>«a) Pour les fiches BAR-EN-102 “Isolation des murs” et BAR-EN-107 “Isolation des murs (France d’outre- mer)”:</p>

                        <p className="bar1-para">« – au moins 10 % des opérations réalisées au bénéfice des ménages en situation de grande précarité ou de précarité énergétique, pour ce qui concerne les contrôles effectués sur le lieu des opérations conformément au premier alinéa de l’article 8-5. A ces derniers, s’ajoutent des contrôles effectués par contact conformément au deuxième alinéa de l’article 8-5, qui couvrent, cumulés aux contrôles sur le lieu des opérations, au moins 30 % des opérations réalisées au bénéfice des ménages en situation de grande précarité ou de précarité énergétique;</p>
                        <p className='bar1-para '>« – au moins 5 % des opérations réalisées au bénéfice des autres ménages, pour ce qui concerne les contrôles effectués sur le lieu des opérations conformément au premier alinéa de l’article 8-5. A ces derniers, s’ajoutent des contrôles effectués par contact conformément au deuxième alinéa de l’article 8-5, qui couvrent, cumulés aux contrôles sur le lieu des opérations, au moins 15 % des opérations réalisées au bénéfice des autres ménages; «b) Pour les fiches BAT-EN-102 “Isolation des murs”, BAT-EN-108 “Isolation des murs (France d’outre-mer)”, IND-EN-101 “Isolation des murs (France d’outre-mer)” et IND-UT-131 “Isolation thermique des parois planes ou cylindriques sur des installations industrielles (France métropolitaine)”:</p>
                        <p className="bar1-para ">« – au moins 5 % des opérations réalisées, pour ce qui concerne les contrôles effectués sur le lieu des opérations conformément au premier alinéa de l’article 8-5. A ces derniers, s’ajoutent des contrôles effectués par contact </p>
                        <p className='bar1-para '>conformément au deuxième alinéa de l’article 8-5, qui couvrent, cumulés aux contrôles sur le lieu des opérations, au moins 15 % des opérations réalisées. </p>
                        <p className='bar1-para '>«III. – Les suites des contrôles respectent les dispositions de l’article 8-8. «Le rapport mentionné à l’article 8-8 fournit également des éléments sur la qualité des travaux. Si l’un au moins des points vérifiés lors du contrôle révèle un écart, le contrôle est jugé non satisfaisant. «Doivent être vérifiés lors des contrôles sur le lieu de l’opération:</p>
                        <p className='bar1-para '>«a) Pour les systèmes d’isolation thermique par l’extérieur:</p>

                        <p className='bar1-para '>« – le fait que le bénéficiaire a reçu le devis, la facture et le cadre contribution défini à l’annexe 8 de l’arrêté du 4 septembre 2014 modifié susvisé, émis par le demandeur, sur déclaration du bénéficiaire;</p>

                        <p className='bar1-para '>« – la présentation, dans le devis, du descriptif du procédé d’isolation complet (comprenant a minima les éléments de finition). Les éléments de finition permettent notamment d’assurer la fixation satisfaisante et la protection des matériaux isolants, afin de garantir le maintien dans le temps de leurs caractéristiques physiques et de leurs performances;</p>

                        <p className='bar1-para '>« – la fixation satisfaisante et la protection des matériaux isolants contre le rayonnement solaire et les intempéries, afin de garantir le maintien dans le temps de leurs caractéristiques physiques et de leurs performances;</p>

                        <p className='bar1-para '>«b) Pour les systèmes d’isolation thermique par l’intérieur:</p>
                        <p className='bar1-para '>« – le fait que le bénéficiaire a reçu le devis, la facture et le cadre contribution défini à l’annexe 8 de l’arrêté du 4 septembre 2014 modifié susvisé, émis par le demandeur, sur déclaration du bénéficiaire;</p>
                        <p className='bar1-para '>« – la présentation, dans le devis, du descriptif du procédé d’isolation complet (comprenant a minima les éléments de finition). Les éléments de finition permettent notamment d’assurer la fixation satisfaisante et la protection des matériaux isolants, afin de garantir le maintien dans le temps de leurs caractéristiques physiques et de leurs performances;</p>
                        <p className='bar1-para '>« – la fixation satisfaisante et la protection des matériaux isolants contre l’usure liée à l’usage normal du bâtiment, afin de garantir le maintien dans le temps de leurs caractéristiques physiques et de leurs performances; « – la pose d’un </p>
                        <p className='bar1-para '>pare-vapeur lorsque celui-ci est nécessaire;</p>
                        <p className='bar1-para '>« – l’absence de dégradation des éléments de ventilation par les travaux d’isolation;</p>
                        <p className='bar1-para '>« – l’adaptation, lorsque celle-ci est nécessaire, des circuits électriques existants (prises et éclairages);</p>
                        <p className='bar1-para '>«Le rapport signale tout manquement manifeste aux règles de l’art. Pour tout manquement ou non-qualité manifeste, l’organisme de contrôle classe l’opération en non satisfaisante.</p>
                        <p className='bar1-para '>«Doivent être vérifiés lors des contrôles par contact:</p>
                        <p className='bar1-para '>« – l’existence des travaux d’isolation;</p>
                        <p className='bar1-para '>« – le fait que le bénéficiaire a reçu le devis, la facture et le cadre contribution défini à l’annexe 8 de l’arrêté du 4 septembre 2014 modifié susvisé, émis par le demandeur, sur déclaration du bénéficiaire;</p>
                        <p className='bar1-para '>« – l’absence de non-qualité manifeste détectée par le bénéficiaire sur les travaux effectués.</p>
                        <p className='bar1-para '>«Le demandeur de certificats archive et tient à la disposition des fonctionnaires et agents mentionnés à l’article</p>
                        <p className='bar1-para '>L. 222-9 du code de l’énergie les rapports de contrôle de l’ensemble des opérations contrôlées.</p>
                        <p className='bar1-para '>«Le demandeur de certificats archive et tient à la disposition des fonctionnaires et agents mentionnés à l’article L. 222-9 du code de l’énergie la synthèse des contrôles mentionnée au dernier alinéa de l’article 8-8.</p>
                        <p className='bar1-para '>«En cas de problème détecté lors des contrôles, il apporte des mesures correctives avant le dépôt du dossier de demande. Il archive et tient également à la disposition des fonctionnaires et agents mentionnés à l’article L. 222-9 du code de l’énergie l’ensemble des preuves des mesures correctives apportées.»</p>
                        <p className='bar1-head '>4. Durée de vie conventionnelle</p>
                        <p className='bar1-para '>30 ans.</p>
                        <p className='bar1-head '>5. Montant de certificats en kWh cumac</p>
                        {/* <div className='bar-img'>
                            <img src={bar2} className='bar1-img mt-4' ></img>
                        </div> */}
                        <div className="row">
                            <div className="col-4">
                                <table className="table table-bordered table-border">
                                    <thead>
                                        <tr className='bar1-head text-center'>
                                            <th colSpan="3">Montant en kWh cumac par m<sup>2</sup> d'isolant </th>

                                        </tr>
                                        <tr className='bar1-head text-center'>
                                            <th rowSpan="2">zone climatique</th>
                                            <th colSpan="2">Energie de chauffage</th>
                                        </tr>
                                        <tr className='bar1-head text-center'>
                                            <td>Electricite</td>
                                            <td>Combustible</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='bar1-para text-center'>
                                            <td>H1</td>
                                            <td>2 400</td>
                                            <td>3 800</td>
                                        </tr>
                                        <tr className='bar1-para text-center'>
                                            <td>H2</td>
                                            <td>2 000</td>
                                            <td>3 100</td>
                                        </tr>
                                        <tr className='bar1-para text-center'>
                                            <td>H3</td>
                                            <td>1 300</td>
                                            <td>2 100</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-1 ">
                                <h1 className='postion-tops '>*</h1>
                            </div>
                            <div className="col-lg-3">
                                <table className="table table-bordered table-border">
                                    <thead>
                                        <tr className='bar1-head text-center'>
                                            <th colSpan="3">Surface d'isolant<br />(m<sup>2</sup>)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='bar1-para text-center h-100'>
                                            <td className='pad-80  text-center'>s</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div className='bar1-content mt-4 pb-4'>
                            <p className='bar1-para para-bar '>La fourniture et pose par un professionnel RGE d’un procédé d’isolation (complexe ou sur ossature) sur un (des) mur(s) en façade ou en pignon dans un (ou des) appartement(s) ou maison(s) de plus de 2 ans. </p>
                            <p className="bar1-head para-bar1 ">Éligible si : </p>
                            <ul className='ul-bar1'>  <li><span className='bar1-ul '>Le devis : </span>comporte la date de la pré-visite technique.</li>
                                <li><span className='bar1-ul'>Le début des travaux</span> respecte un délai<span className='bar1-ul'> de 8 jours après la date de signature</span> de la commande.</li>
                                <li><span className='bar1-ul'>L’isolant :</span> a une résistance thermique ≥ à 7 m².K/W en comble perdu et ≥ à 6 m².K/W en rampant de toiture, est certifié ACERMI (ou équivalent), est de classe A de protection au feu (ou équivalent ou pose d’une couche supplémentaire).</li>
                                <li><span className='bar1-ul'>Sont installés :</span>  un coffrage autour des conduits de fumées et des éclairages encastrés ; une rehausse rigide au niveau de la trappe d’accès.</li></ul>
                            <p className='bar1-para para-bar1'><span className='bar1-head '>La facture indique obligatoirement :</span> fourniture et pose d’une isolation de comble ou toiture, marque, référence, épaisseur, résistance thermique, n° ACERMI et surface réellement isolée (+ l’identité du sous-traitant, si c’est le cas), les aménagements réalisés, la présence d’un pare-vapeur + la date de pré-visite technique. </p>
                            <p className='bar1-para para-bar1'><span className='bar1-head '>Contrôle post-chantier :</span> tous les chantiers font l’objet d’un questionnaire de satisfaction client, certains font l’objet d’une inspection qualité post-travaux par un organisme indépendant (attesté COFRAC).</p>

                            <p className='bar1-para para-bar1'><span className='bar1-head '>Attention :</span> Sans modification sous 5 ans, cette opération sera abrogée à partir du 1er mai 2027.</p>
                            <p className='bar1-para para-bar1'><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime. En cliquant sur le Info , vous obtenez plus de détail sur une question.</p>

                        </div>

                        {/* 
                        <div className='button-1'>
                            <button type='button' className='btn-on' >Site du Ministère <TbMathGreater /> </button>
                        </div>
                        <div className='button-1 mb-3'>
                            <button type='button' className='btn-on' ><AiOutlineDownload />Télécharger le fiche </button>
                        </div> */}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bar102;