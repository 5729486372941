
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Formik } from 'formik';
import { ZoneClimatique } from '../../arraystore/CountryCodeArray';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
// SECOND 
import { H1CALCULATION, H1NCALCULATION, H1LCALCULATION, TYPEDEBATIMENT, GTBCALCULATIONV1, GTBCALCULATIONV2, H1B2CCALCULATION, H1B2CDATECALCULATION, H1B2CENERGYSURFACE, INSTALLATIONTYPE, PLATEHEAT } from '../../arraystore/CountryCodeArray';

import axios from 'axios';

function EstimateB2C(props) {
  const [obligeStore, setObligeStore] = useState(null);
  const [installerStore, setInstallerStore] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toastsuccess = (val) => toast.success(val);
  const toasterror = (val) => toast.error(val);
  const MultiUpdate = props.MultiUpdate;
  console.log('MultiUpdate', MultiUpdate);
  const Token = localStorage.getItem('Token');
  const navigate = useNavigate();
  // DATA GET LOCAL STORAGE
  console.log('H1CALCULATION', H1CALCULATION, 'H1NCALCULATION', H1NCALCULATION, 'H1LCALCULATION', H1LCALCULATION, 'TYPEDEBATIMENT', TYPEDEBATIMENT, 'GTBCALCULATIONV1', GTBCALCULATIONV1, 'GTBCALCULATIONV1', GTBCALCULATIONV2, 'H1B2CCALCULATION', H1B2CCALCULATION, 'H1B2CDATECALCULATION', H1B2CDATECALCULATION, 'INSTALLATIONTYPE', INSTALLATIONTYPE);
  //    Zone VALUE
  // const categoryGet = JSON.parse(sessionStorage.getItem('step-5'));
  const SplitData = JSON.parse(sessionStorage.getItem('step-4'));
  const SplitDataZone = JSON.parse(sessionStorage.getItem('step-6'));
  // API GET data MultiUpdate

  useEffect(() => {
    const installer_id = MultiUpdate?.multisteptable1?.installer_id;
    const oblige_id = MultiUpdate?.multisteptable1?.oblige_id;
    const fetchData = async () => {
      const option = {
        headers: { authorization: Token }
      };
      try {
        const [installerResponse, obligeResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/get-installer/${installer_id}`, option),
          axios.get(`${process.env.REACT_APP_API_URL}/get-oblige/${oblige_id}`, option)
        ]);

        setInstallerStore(installerResponse?.data);
        setObligeStore(obligeResponse?.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [MultiUpdate]);
  console.log('obligeStore', obligeStore, 'installerStore', installerStore);

  if (isLoading) {
    return <Spinner animation="border" className='text-success' />;
  };

  // const codeSplit = SplitDataZone?.codepostal?.substr(0, 2);
  const codeSplit = MultiUpdate?.multisteptable2?.codepostal?.substr(0, 2);
  console.log('codeSplit', codeSplit);
  const filteredValue = ZoneClimatique?.filter(item => item.id === codeSplit);
  console.log('filteredValue', filteredValue);
  // PART CLASSIQUE && PART GP ET P
  let partgpetpValue;
  let partclassiqueValue;
  let surface_area = MultiUpdate?.multisteptable2?.surface;
  let categoryGet = MultiUpdate?.multisteptable1?.documents;
  if (categoryGet === 'BAR-EN-101') {
    partgpetpValue = Number(MultiUpdate?.multisteptable1?.chantier_precarious) / Number(surface_area);
    console.log('partgpetpValue', partgpetpValue);
    partclassiqueValue = 1 - partgpetpValue;
    console.log('partclassiqueValue', partclassiqueValue);
  }
  else if (categoryGet === 'BAR-EN-103') {
    partgpetpValue = Number(MultiUpdate?.multisteptable1?.chantier_precarious) / Number(surface_area);
    console.log('partgpetpValue', partgpetpValue);
    partclassiqueValue = 1 - partgpetpValue;
    console.log('partclassiqueValue', partclassiqueValue);
  }
  else if (categoryGet === 'BAR-TH-160') {
    partgpetpValue = Number(MultiUpdate?.multisteptable1?.chantier_precarious) / Number(surface_area);
    console.log('partgpetpValue', partgpetpValue);
    partclassiqueValue = 1 + partgpetpValue;
    console.log('partclassiqueValue', partclassiqueValue);
  }
  else if (categoryGet === 'BAR-EN-102') {
    partgpetpValue = Number(MultiUpdate?.multisteptable1?.chantier_precarious) / Number(surface_area);
    console.log('partgpetpValue', partgpetpValue);
    partclassiqueValue = 1 + partgpetpValue;
    console.log('partclassiqueValue', partclassiqueValue);
  } else {
    partgpetpValue = Number(MultiUpdate?.multisteptable1?.chantier_precarious) / Number(surface_area);
    console.log('partgpetpValue', partgpetpValue);
    partclassiqueValue = 1 - partgpetpValue;
    console.log('partclassiqueValue', partclassiqueValue);
  }

  // Oblige Price Change on Classic or QPV
  let obligePrice;
  const Neighborhood = MultiUpdate?.multisteptable1?.chantier_choosetype;
  console.log('Neighborhood', Neighborhood);
  if (Neighborhood === 'Classique') {
    obligePrice = obligeStore?.contact[0]?.classic_BtoB_rate;
    console.log('obligePrice', obligePrice);
  } else {
    obligePrice = obligeStore?.contact[0]?.precarious_BtoB_price;
    console.log('obligePrice', obligePrice);
  }
  // useFormik
  const initialValues = {
    id: MultiUpdate?.multisteptable1?.id,
    oblige: obligeStore?.oblige[0]?.tradename,
    partenaire: installerStore?.installer[0]?.tradename,
    obligeValuePrice: obligePrice,
    partenaireValuePrice: installerStore?.valo[0]?.value_price,
    neighborhoodType: MultiUpdate?.multisteptable1?.chantier_choosetype,
    heatingMode: MultiUpdate?.multisteptable1?.chantier_heatingmode,
    typeOfBuilding: MultiUpdate?.multisteptable1?.chantier_typeofbuilding,
    area: filteredValue[0]?.value,
    surface: MultiUpdate?.multisteptable2?.surface_area,
    partclassique: partclassiqueValue,
    partgpetp: partgpetpValue,
    petgp: MultiUpdate?.multisteptable2?.petgp,

    obligeCumacValue: '',
    obligeTotalPremiumAmount: '',
    obligeProfitShare: MultiUpdate?.multisteptable2?.obligeprofitshare,
    obligeShareInstallerHT: MultiUpdate?.multisteptable2?.obligeshareinstallerHT,
    obligeVAT: MultiUpdate?.multisteptable2?.obligeVAT,
    obligeTotalPrice: MultiUpdate?.multisteptable2?.obligetotalpremiumamount,
    // obligeBeneficiaryPremium: '',

    partnerCumacValue: '',
    partnerTotalPremiumAmount: '',
    partnerProfitShare: MultiUpdate?.multisteptable2?.partnerprofitshare,
    partnerShareInstallerHT: MultiUpdate?.multisteptable2?.partnershareinstallerHT,
    partnerVAT: MultiUpdate?.multisteptable2?.partnerVAT,
    partnerTotalPrice: MultiUpdate?.multisteptable2?.partnertotalprice,
    // partnerBeneficiaryPremium: '',
  };
  console.log('initialValues', initialValues.oblige);
  // VALIDE FUNCTION
  const validate = (values) => {
    console.log('values', values);
    const errors = {};
    if (!values.obligeProfitShare) {
      errors.obligeProfitShare = 'Oblige Part Beneficiaire requise';
    }
    if (!values.partnerProfitShare) {
      errors.partnerProfitShare = 'Partenaire Part Beneficiaire requise';
    }
    return errors;
  };


  // // ZONE CALCULATIONS
  const keys = Object.keys(H1CALCULATION);
  console.log('keys[0]', keys[0]);

  // // const Neighborhood = SplitData?.chantier_chooseType;
  // // console.log('Neighborhood', Neighborhood);
  // // let PETGP;
  let PETGP = (values, setFieldValue) => { };
  // // 1 - BAR-EN-101
  if (categoryGet === keys[0]) {
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = H1CALCULATION['BAR-EN-101'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    if ('Classique' === Neighborhood) {
      initialValues.obligeCumacValue = initialValues.surface * ZoneA;
      initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

      initialValues.partnerCumacValue = initialValues.surface * ZoneA;
      initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
    }
    if ('QPV' === Neighborhood) {
      PETGP = (values, setFieldValue) => {
        setFieldValue('petgp', values?.petgp);
        const VALEURCUMAC = (initialValues.surface * ZoneA * initialValues?.partgpetp) + (initialValues.surface * Number(values?.petgp) * initialValues?.partclassique);
        console.log(VALEURCUMAC);
        setFieldValue('obligeCumacValue', VALEURCUMAC);
        setFieldValue('partnerCumacValue', VALEURCUMAC);

        const MONTANTONE = VALEURCUMAC * gigabite;
        console.log('MONTANTONE', MONTANTONE);
        const MONTANTTWO = VALEURCUMAC * gigabitepartnaire;
        setFieldValue('obligeTotalPremiumAmount', MONTANTONE);
        setFieldValue('partnerTotalPremiumAmount', MONTANTTWO);
      }
    }

  }
  // // 2 - BAR-EN-102
  if (categoryGet === keys[1]) {
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = H1CALCULATION['BAR-EN-102'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    if ('Classique' === Neighborhood) {
      initialValues.obligeCumacValue = initialValues.surface * ZoneA;
      initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

      initialValues.partnerCumacValue = initialValues.surface * ZoneA;
      initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
    }
    if ('QPV' === Neighborhood) {
      const VALEURCUMAC = initialValues.surface * ZoneA * initialValues?.partclassique;
      console.log(VALEURCUMAC);
      initialValues.obligeCumacValue = VALEURCUMAC;
      initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

      initialValues.partnerCumacValue = VALEURCUMAC;
      initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;

    }

  }
  // // 3 - BAR-EN-103
  if (categoryGet === keys[2]) {
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = H1CALCULATION['BAR-EN-103'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    if ('Classique' === Neighborhood) {
      initialValues.obligeCumacValue = initialValues.surface * ZoneA;
      initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

      initialValues.partnerCumacValue = initialValues.surface * ZoneA;
      initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
    }
    if ('QPV' === Neighborhood) {
      PETGP = (values, setFieldValue) => {
        setFieldValue('petgp', values?.petgp);
        const VALEURCUMAC = (initialValues.surface * ZoneA * initialValues?.partgpetp) + (initialValues.surface * Number(values?.petgp) * initialValues?.partclassique);
        console.log(VALEURCUMAC);
        setFieldValue('obligeCumacValue', VALEURCUMAC);
        setFieldValue('partnerCumacValue', VALEURCUMAC);

        const MONTANTONE = VALEURCUMAC * gigabite;
        console.log('MONTANTONE', MONTANTONE);
        const MONTANTTWO = VALEURCUMAC * gigabitepartnaire;
        setFieldValue('obligeTotalPremiumAmount', MONTANTONE);
        setFieldValue('partnerTotalPremiumAmount', MONTANTTWO);
      }
    }
  }

  // // ------------------------N CALCULATION (N)-----------------------------
  const Ncalculationkeys = Object.keys(H1NCALCULATION);
  console.log('Ncalculationkeys[0]', Ncalculationkeys[0]);
  // 1 - BAR-EN-104
  if (categoryGet === Ncalculationkeys[0]) {
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = H1NCALCULATION['BAR-EN-104'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    console.log('filterValueZone', filterValueZone);
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    const Nadd = Number(MultiUpdate?.multisteptable2?.roofwindows) + Number(MultiUpdate?.multisteptable2?.windowsorpatiodoors);
    console.log('Nadd', Nadd);
    const NValue = ZoneA * Nadd;
    console.log('NValue', NValue);
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', gigabitepartnaire);
    // initialValues.obligeCumacValue = initialValues.surface * NValue;
    initialValues.obligeCumacValue = NValue;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    // initialValues.partnerCumacValue = initialValues.surface * NValue;
    initialValues.partnerCumacValue = NValue;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // 2 - BAR-SE-104
  if (categoryGet === Ncalculationkeys[1]) {
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = H1NCALCULATION['BAR-SE-104'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    console.log('filterValueZone', filterValueZone);
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    const Nadd = Number(MultiUpdate?.multisteptable2?.noofApartment);
    console.log('Nadd', Nadd);
    const NValue = ZoneA * Nadd;
    console.log('NValue', NValue);
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', gigabitepartnaire);
    // initialValues.obligeCumacValue = initialValues.surface * NValue;
    initialValues.obligeCumacValue = NValue;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    // initialValues.partnerCumacValue = initialValues.surface * NValue;
    initialValues.partnerCumacValue = NValue;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // ------------------------L CALCULATION (L)-----------------------------
  const Lcalculationkeys = Object.keys(H1LCALCULATION);
  console.log('Ncalculationkeys[0]', Lcalculationkeys[0]);
  // // 1 - BAR-TH-160
  if (categoryGet === Lcalculationkeys[0]) {
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = H1LCALCULATION['BAR-TH-160'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    console.log('filterValueZone', filterValueZone);
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log('ZoneA', Number(ZoneA));
    // const Nadd = Number(SplitDataZone?.hydraulicnetwork);
    const Nadd = Number(MultiUpdate?.multisteptable2?.surface_area);
    console.log('Nadd', Nadd);
    const NValue = ZoneA * Nadd;
    console.log('NValue', NValue);
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    if ('Classique' == Neighborhood) {
      // initialValues.obligeCumacValue = initialValues.surface * NValue;
      initialValues.obligeCumacValue = NValue;
      initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

      // initialValues.partnerCumacValue = initialValues.surface * NValue;
      initialValues.partnerCumacValue = NValue;
      initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
    }
    if ('QPV' == Neighborhood) {
      // const VALEURCUMAC = initialValues.surface * ZoneA * initialValues?.partclassique;
      const VALEURCUMAC = ZoneA * initialValues?.partclassique;
      console.log(VALEURCUMAC);
      initialValues.obligeCumacValue = VALEURCUMAC;
      initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

      initialValues.partnerCumacValue = VALEURCUMAC;
      initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
    }
  }
  // // 2 - BAT-TH-146
  if (categoryGet === Lcalculationkeys[1]) {
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = H1LCALCULATION['BAT-TH-146'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    console.log('filterValueZone', filterValueZone);
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    const Nadd = Number(MultiUpdate?.multisteptable2?.hydraulicnetwork);
    console.log('Nadd', Nadd);
    const NValue = ZoneA * Nadd;
    console.log('NValue', NValue);
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', gigabitepartnaire);
    // initialValues.obligeCumacValue = initialValues.surface * NValue;
    initialValues.obligeCumacValue = NValue;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    // initialValues.partnerCumacValue = initialValues.surface * NValue;
    initialValues.partnerCumacValue = NValue;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // ----------------------TYPEDEBATIMENT CALCULATION---------------------
  const typeofbuildingkeys = Object.keys(TYPEDEBATIMENT);
  console.log('keys[0]', typeofbuildingkeys[0]);
  // // 1 - BAT-EN-101
  if (categoryGet === typeofbuildingkeys[0]) {
    // ZONE VALUE
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = TYPEDEBATIMENT['BAT-EN-101'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    // Type de Batiment
    const typedebatiment = MultiUpdate?.multisteptable1?.chantier_typeofbuilding;
    console.log('typedebatiment', typedebatiment);
    console.log(ZoneValue.typedebatiment);
    const foundItem = ZoneValue?.typedebatiment?.find(item => Object.keys(item)[0] === typedebatiment);
    console.log(foundItem);
    const singleBatiment = Object.values(foundItem)[0];
    console.log(singleBatiment);
    // CALCULATION
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', gigabitepartnaire, 'gigabitepartnaire', gigabitepartnaire);

    const OBLIGECUMAC = initialValues.surface * ZoneA * singleBatiment;
    initialValues.obligeCumacValue = OBLIGECUMAC;
    const OBLIGEPREMIUMTOTALAMOUNT = initialValues.obligeCumacValue * gigabite;
    initialValues.obligeTotalPremiumAmount = OBLIGEPREMIUMTOTALAMOUNT;
    const PARTNAIRECUMAC = initialValues.surface * ZoneA * singleBatiment;
    initialValues.partnerCumacValue = PARTNAIRECUMAC;
    const PARTNAIRTOTALPREMIUMAMOUNT = initialValues.partnerCumacValue * gigabitepartnaire;
    initialValues.partnerTotalPremiumAmount = PARTNAIRTOTALPREMIUMAMOUNT;

  }
  // // 2 - BAT-EN-102
  if (categoryGet === typeofbuildingkeys[1]) {
    // ZONE VALUE
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = TYPEDEBATIMENT['BAT-EN-102'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    // Type de Batiment
    const typedebatiment = MultiUpdate?.multisteptable1?.chantier_typeofbuilding;
    console.log('typedebatiment', typedebatiment);
    console.log(ZoneValue.typedebatiment);
    const foundItem = ZoneValue?.typedebatiment.find(item => Object.keys(item)[0] === typedebatiment);
    console.log(foundItem);
    const singleBatiment = Object.values(foundItem)[0];
    console.log(singleBatiment);
    // CALCULATION
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', gigabitepartnaire, 'gigabitepartnaire', gigabitepartnaire);

    const OBLIGECUMAC = initialValues.surface * ZoneA * singleBatiment;
    initialValues.obligeCumacValue = OBLIGECUMAC;
    const OBLIGEPREMIUMTOTALAMOUNT = initialValues.obligeCumacValue * gigabite;
    initialValues.obligeTotalPremiumAmount = OBLIGEPREMIUMTOTALAMOUNT;
    const PARTNAIRECUMAC = initialValues.surface * ZoneA * singleBatiment;
    initialValues.partnerCumacValue = PARTNAIRECUMAC;
    const PARTNAIRTOTALPREMIUMAMOUNT = initialValues.partnerCumacValue * gigabitepartnaire;
    initialValues.partnerTotalPremiumAmount = PARTNAIRTOTALPREMIUMAMOUNT;

  }
  // // 3 - BAT-EN-103
  if (categoryGet === typeofbuildingkeys[2]) {
    // ZONE VALUE
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = TYPEDEBATIMENT['BAT-EN-103'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    // Type de Batiment
    const typedebatiment = MultiUpdate?.multisteptable1?.chantier_typeofbuilding;
    console.log('typedebatiment', typedebatiment);
    console.log(ZoneValue.typedebatiment);
    const foundItem = ZoneValue?.typedebatiment.find(item => Object.keys(item)[0] === typedebatiment);
    console.log(foundItem);
    const singleBatiment = Object.values(foundItem)[0];
    console.log(singleBatiment);
    // CALCULATION
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', gigabitepartnaire, 'gigabitepartnaire', gigabitepartnaire);

    const OBLIGECUMAC = initialValues.surface * ZoneA * singleBatiment;
    initialValues.obligeCumacValue = OBLIGECUMAC;
    const OBLIGEPREMIUMTOTALAMOUNT = initialValues.obligeCumacValue * gigabite;
    initialValues.obligeTotalPremiumAmount = OBLIGEPREMIUMTOTALAMOUNT;
    const PARTNAIRECUMAC = initialValues.surface * ZoneA * singleBatiment;
    initialValues.partnerCumacValue = PARTNAIRECUMAC;
    const PARTNAIRTOTALPREMIUMAMOUNT = initialValues.partnerCumacValue * gigabitepartnaire;
    initialValues.partnerTotalPremiumAmount = PARTNAIRTOTALPREMIUMAMOUNT;

  }
  // // 4 - BAT-TH-104
  if (categoryGet === typeofbuildingkeys[3]) {
    // ZONE VALUE
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = TYPEDEBATIMENT['BAT-TH-104'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    // Type de Batiment
    const typedebatiment = MultiUpdate?.multisteptable1?.chantier_typeofbuilding;
    console.log('typedebatiment', typedebatiment);
    console.log(ZoneValue.typedebatiment);
    const foundItem = ZoneValue?.typedebatiment.find(item => Object.keys(item)[0] === typedebatiment);
    console.log(foundItem);
    const singleBatiment = Object.values(foundItem)[0];
    console.log(singleBatiment);
    // CALCULATION
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', gigabitepartnaire, 'gigabitepartnaire', gigabitepartnaire);

    const OBLIGECUMAC = initialValues.surface * ZoneA * singleBatiment;
    initialValues.obligeCumacValue = OBLIGECUMAC;
    const OBLIGEPREMIUMTOTALAMOUNT = initialValues.obligeCumacValue * gigabite;
    initialValues.obligeTotalPremiumAmount = OBLIGEPREMIUMTOTALAMOUNT;
    const PARTNAIRECUMAC = initialValues.surface * ZoneA * singleBatiment;
    initialValues.partnerCumacValue = PARTNAIRECUMAC;
    const PARTNAIRTOTALPREMIUMAMOUNT = initialValues.partnerCumacValue * gigabitepartnaire;
    initialValues.partnerTotalPremiumAmount = PARTNAIRTOTALPREMIUMAMOUNT;
  }
  // // RES-EC-104 CALCULATION
  if (categoryGet === 'RES-EC-104') {
    console.log('RES-EC-104');
    // noofnewlights
    let csev1newlights = Number(MultiUpdate?.multisteptable2?.noofnewlights) * 9300;
    let csev2newlights = Number(MultiUpdate?.multisteptable2?.noofnewlightsv2) * 7200;
    let finalNo = csev1newlights + csev2newlights;
    console.log('csev1newlights, csev2newlights, finalNo', csev1newlights, csev2newlights, finalNo);
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', gigabitepartnaire);
    initialValues.obligeCumacValue = finalNo;
    // initialValues.obligeCumacValue = initialValues.surface * finalNo;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    // initialValues.partnerCumacValue = initialValues.surface * finalNo;
    initialValues.partnerCumacValue = finalNo;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // -----------------------------GTBCALCULATIONS-----------------------------
  const GTPkeys = Object.keys(GTBCALCULATIONV1);
  console.log('keys[0]', GTPkeys[0]);
  // // 1 BAT-TH-116
  if (categoryGet === GTPkeys[0]) {
    // ZONE CALCULATION SAME
    const heatingMode = MultiUpdate?.multisteptable1?.chantier_heatingmode;
    console.log('heatingMode', heatingMode);
    let ZoneValue = GTBCALCULATIONV1['BAT-TH-116'];
    console.log('ZoneValue', ZoneValue[heatingMode]);
    const filterValueZone = filteredValue[0]?.value;
    const filteredCalcZone = ZoneValue[heatingMode]?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    // Type de Batiment VERSION 1
    const typedebatiment = MultiUpdate?.multisteptable1?.chantier_typeofbuilding;
    const GTBStore = MultiUpdate?.multisteptable1?.managedbmssystem;
    const GTBfoundItem = GTBCALCULATIONV1['BAT-TH-116'];
    const SeparateGTPV1VALUES = GTBfoundItem[GTBStore];
    console.log('typedebatiment', typedebatiment, 'GTBStore', GTBStore, 'SeparateGTPV1VALUES', SeparateGTPV1VALUES);
    const foundItemV1 = SeparateGTPV1VALUES.find(item => Object.keys(item)[0]);
    const GTBV1VALUE = Object.values(foundItemV1)[0];
    console.log('foundItemV1', foundItemV1, 'GTBV1VALUE', GTBV1VALUE);
    // Type de Batiment VERSION 2
    const GTBfoundItemV2 = GTBCALCULATIONV2['BAT-TH-116'];
    const SeparateGTPV2VALUES = GTBfoundItemV2[GTBStore];
    console.log('GTBfoundItemV2', GTBfoundItemV2, 'SeparateGTPV2VALUES', SeparateGTPV2VALUES);
    const foundItemV2 = SeparateGTPV2VALUES.find(item => Object.keys(item)[0]);
    const GTBV2VALUE = Object.values(foundItemV2)[0];
    console.log('foundItemV2', foundItemV2, 'GTBV2VALUE', GTBV2VALUE, 'initialValues.surface', initialValues.surface);
    // FINAL RESULT
    const FinalRESULTGTB = (GTBV1VALUE + GTBV2VALUE) * ZoneA;
    console.log('FinalRESULTGTB', FinalRESULTGTB);

    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', gigabitepartnaire);
    initialValues.obligeCumacValue = initialValues.surface * FinalRESULTGTB;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = initialValues.surface * FinalRESULTGTB;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // 1 BAT-EQ-133
  if (categoryGet === GTPkeys[1]) {
    // Type de Batiment V1
    const typedebatiment = MultiUpdate?.multisteptable2?.buildingindustry;
    console.log('typedebatiment', typedebatiment);
    let ZoneValueV1 = GTBCALCULATIONV1['BAT-EQ-133'];
    console.log('ZoneValue', ZoneValueV1);
    const foundItemV1 = ZoneValueV1?.Certificates.find(item => Object.keys(item)[0] === typedebatiment);
    const singleBatimentV1 = Object.values(foundItemV1)[0];
    console.log('foundItemV1', foundItemV1, 'singleBatimentV1', singleBatimentV1);
    // Type de Batiment V2
    let ZoneValueV2 = GTBCALCULATIONV2['BAT-EQ-133'];
    console.log('ZoneValue', ZoneValueV2);
    const foundItemV2 = ZoneValueV2?.Certificates.find(item => Object.keys(item)[0] === typedebatiment);
    const singleBatimentV2 = Object.values(foundItemV2)[0];
    console.log('foundItemV2', foundItemV2, 'singleBatimentV2', singleBatimentV2);
    // CALCULATION
    const classeZV1 = singleBatimentV1 * 1200 * Number(MultiUpdate?.multisteptable2?.classZshowerheads);
    const classeZZV1 = singleBatimentV1 * 2000 * Number(MultiUpdate?.multisteptable2?.classZZshowerheads);
    const classeZV2 = singleBatimentV1 * 340 * Number(MultiUpdate?.multisteptable2?.classZunregulated);
    const classeZZV2 = singleBatimentV1 * 630 * Number(MultiUpdate?.multisteptable2?.classZZunregulated);
    console.log('classeZV1', classeZV1, 'classeZZV1', classeZZV1, 'classeZV2', classeZV2, 'classeZZV2', classeZZV2);
    const classeZV1TOTAL = classeZV1 + classeZZV1;
    const classeZV2TOTAL = classeZV2 + classeZZV2;
    const ZvaluesFinal = classeZV1TOTAL + classeZV2TOTAL;
    console.log('classeZV1TOTAL', classeZV1TOTAL, 'classeZV2TOTAL', classeZV2TOTAL, 'ZvaluesFinal', ZvaluesFinal);

    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', gigabitepartnaire);
    initialValues.obligeCumacValue = ZvaluesFinal;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = ZvaluesFinal;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // -----------------------------B2C Calculation-----------------------------
  // // ZONE CALCULATIONS
  const b2ckeys = Object.keys(H1B2CCALCULATION);
  console.log('keys[0]', b2ckeys[0]);

  // // 1 - BAR-TH-143
  if (categoryGet === b2ckeys[0]) {
    let ZoneValue = H1B2CCALCULATION['BAR-TH-143'];
    console.log('ZoneValue', ZoneValue['Montantcumac']);
    const filterValueZone = filteredValue[0]?.value;
    console.log('filterValueZone', filterValueZone);
    const filteredCalcZone = ZoneValue['Montantcumac']?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    initialValues.obligeCumacValue = ZoneA;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = ZoneA;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // 2 - BAR-TH-113
  if (categoryGet === b2ckeys[1]) {
    let ZoneValue = H1B2CCALCULATION['BAR-TH-113'];
    console.log('ZoneValue', ZoneValue['Montantcumac']);
    const filterValueZone = filteredValue[0]?.value;
    console.log('filterValueZone', filterValueZone);
    const filteredCalcZone = ZoneValue['Montantcumac']?.filter(item => Object.keys(item)[0] === filterValueZone);
    console.log(filteredCalcZone);
    const ZoneFinal = filteredCalcZone?.map(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      return value;
    });
    const ZoneA = Number(ZoneFinal);
    console.log(Number(ZoneA));
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    initialValues.obligeCumacValue = ZoneA;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = ZoneA;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // -----------------------------DATE CALCULATION-----------------------------
  const b2cdatekeys = Object.keys(H1B2CDATECALCULATION);
  console.log('keys[0]', b2cdatekeys[0]);

  // // 1 - BAR-TH-101
  if (categoryGet === b2cdatekeys[0]) {
    const DATEVALIDATE = MultiUpdate?.multisteptable2?.commitmentdate;
    console.log('DATEVALIDATE', DATEVALIDATE);
    // ZONE SPLIT
    let ZoneA;
    if (DATEVALIDATE <= '2017-09-26') {
      let ZoneValue = H1B2CDATECALCULATION['BAR-TH-101'];
      console.log('ZoneValue', ZoneValue['2017-09-26']);
      // H1,H2,H3 (Split Data)
      const filterValueZone = filteredValue[0]?.value;
      console.log('filterValueZone', filterValueZone);
      const filteredCalcZone = ZoneValue['2017-09-26']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
    } else {
      let ZoneValue = H1B2CDATECALCULATION['BAR-TH-101'];
      console.log('ZoneValue', ZoneValue['2017-09-27']);
      // H1,H2,H3 (Split Data)
      const filterValueZone = filteredValue[0]?.value;
      console.log('filterValueZone', filterValueZone);
      const filteredCalcZone = ZoneValue['2017-09-27']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
    }
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    initialValues.obligeCumacValue = ZoneA;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = ZoneA;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;

  }
  // // 1 - BAR-TH-148
  if (categoryGet === b2cdatekeys[1]) {
    const DATEVALIDATE = MultiUpdate?.multisteptable2?.commitmentdate;
    console.log('DATEVALIDATE', DATEVALIDATE);
    // ZONE SPLIT
    let ZoneA;
    if (DATEVALIDATE <= '2017-09-25') {
      let ZoneValue = H1B2CDATECALCULATION['BAR-TH-148'];
      console.log('ZoneValue', ZoneValue['2017-09-25']);
      // H1,H2,H3 (Split Data)
      const filterValueZone = SplitDataZone?.housing_type;
      console.log('filterValueZone', filterValueZone);
      const filteredCalcZone = ZoneValue['2017-09-25']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
    } else {
      let ZoneValue = H1B2CDATECALCULATION['BAR-TH-148'];
      console.log('ZoneValue', ZoneValue['2017-09-26']);
      // H1,H2,H3 (Split Data)
      const filterValueZone = SplitDataZone?.housing_type;
      console.log('filterValueZone', filterValueZone);

      const filteredCalcZone = ZoneValue['2017-09-26']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
    }
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    initialValues.obligeCumacValue = ZoneA;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = ZoneA;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // -----------------------------  ENERGY & SURFACE-----------------------------
  const b2cenergykeys = Object.keys(H1B2CENERGYSURFACE);
  console.log('keys[0]', b2cenergykeys[0]);

  // // 1 - BAR-TH-104
  if (categoryGet === b2cenergykeys[0]) {
    const house_type = MultiUpdate?.multisteptable2?.housing_type;
    console.log('house_type', house_type);
    // H1,H2,H3
    const filterValueZone = filteredValue[0]?.value;
    console.log('filterValueZone', filterValueZone);

    let ZoneValue = H1B2CENERGYSURFACE['BAR-TH-104'];
    console.log('ZoneValue', ZoneValue);
    let energy_value = MultiUpdate?.multisteptable2?.seasonal_energy;
    console.log('energy_value', energy_value);
    let surface_store = Number(MultiUpdate?.multisteptable2?.heatpump_surface);
    console.log('surface_store', surface_store);
    let separate_arrays;
    let ZoneA;
    let type_individual;
    let surfaceValue;
    if (house_type === 'Maison_individuelle') {
      type_individual = ZoneValue['Maison_individuelle'];
      console.log('type_individual', type_individual);
      // ENARGY
      if (102 <= energy_value && energy_value < 110) {
        separate_arrays = type_individual['102-110'];
        console.log('separate_arrays', separate_arrays);
        const filteredCalcZone = separate_arrays?.filter(item => Object.keys(item)[0] === filterValueZone);
        console.log(filteredCalcZone);
        const ZoneFinal = filteredCalcZone?.map(item => {
          const key = Object.keys(item)[0];
          const value = item[key];
          return value;
        });
        ZoneA = Number(ZoneFinal);
        console.log(Number(ZoneA));
      } else if (110 <= energy_value && energy_value < 120) {
        separate_arrays = type_individual['110-119'];
        console.log('separate_arrays', separate_arrays);
        const filteredCalcZone = separate_arrays?.filter(item => Object.keys(item)[0] === filterValueZone);
        console.log(filteredCalcZone);
        const ZoneFinal = filteredCalcZone.map(item => {
          const key = Object.keys(item)[0];
          const value = item[key];
          return value;
        });
        ZoneA = Number(ZoneFinal);
        console.log(Number(ZoneA));
      } else {
        separate_arrays = type_individual['120-Belove'];
        console.log('separate_arrays', separate_arrays);
        const filteredCalcZone = separate_arrays?.filter(item => Object.keys(item)[0] === filterValueZone);
        console.log(filteredCalcZone);
        const ZoneFinal = filteredCalcZone?.map(item => {
          const key = Object.keys(item)[0];
          const value = item[key];
          return value;
        });
        ZoneA = Number(ZoneFinal);
        console.log(Number(ZoneA));
      }
      // SURFACE
      if (surface_store < 35) {
        surfaceValue = 0.5;
      } else if (35 <= surface_store && surface_store < 60) {
        surfaceValue = 0.7;
      } else if (60 <= surface_store && surface_store < 70) {
        surfaceValue = 1;
      } else if (70 <= surface_store && surface_store < 90) {
        surfaceValue = 1.2;
      } else if (90 <= surface_store && surface_store < 110) {
        surfaceValue = 1.5;
      } else if (110 <= surface_store && surface_store <= 130) {
        surfaceValue = 1.9;
      } else {
        surfaceValue = 2.5;
      }
    } else {
      type_individual = ZoneValue['Appartement'];
      console.log('type_individual', type_individual);
      // ENARGY
      if (102 <= energy_value < 110) {
        separate_arrays = type_individual['102-110'];
        console.log('separate_arrays', separate_arrays);
        const filteredCalcZone = separate_arrays?.filter(item => Object.keys(item)[0] === filterValueZone);
        console.log(filteredCalcZone);
        const ZoneFinal = filteredCalcZone?.map(item => {
          const key = Object.keys(item)[0];
          const value = item[key];
          return value;
        });
        ZoneA = Number(ZoneFinal);
        console.log(Number(ZoneA));
      } else if (110 <= energy_value < 120) {
        separate_arrays = type_individual['110-119'];
        console.log('separate_arrays', separate_arrays);
        const filteredCalcZone = separate_arrays?.filter(item => Object.keys(item)[0] === filterValueZone);
        console.log(filteredCalcZone);
        const ZoneFinal = filteredCalcZone?.map(item => {
          const key = Object.keys(item)[0];
          const value = item[key];
          return value;
        });
        ZoneA = Number(ZoneFinal);
        console.log(Number(ZoneA));
      } else {
        separate_arrays = type_individual['120-Belove'];
        console.log('separate_arrays', separate_arrays);
        const filteredCalcZone = separate_arrays?.filter(item => Object.keys(item)[0] === filterValueZone);
        console.log(filteredCalcZone);
        const ZoneFinal = filteredCalcZone?.map(item => {
          const key = Object.keys(item)[0];
          const value = item[key];
          return value;
        });
        ZoneA = Number(ZoneFinal);
        console.log(Number(ZoneA));
      }
      // SURFACE
      if (surface_store < 70) {
        surfaceValue = 0.5;
      } else if (70 <= surface_store < 90) {
        surfaceValue = 0.7;
      } else if (90 <= surface_store < 110) {
        surfaceValue = 1;
      } else if (110 <= surface_store <= 130) {
        surfaceValue = 1.1;
      } else {
        surfaceValue = 1.6;
      }
    }
    console.log('final', ZoneA, 'surfaceValue', surfaceValue, 'final', ZoneA * surfaceValue);
    const energyzone_final = ZoneA * surfaceValue;
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    initialValues.obligeCumacValue = energyzone_final;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = energyzone_final;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // 1 BAR-TH-164
  if (categoryGet === 'BAR-TH-164') {
    const cef_initial = Number(MultiUpdate?.multisteptable2?.cef_initial);
    const cef_projet = Number(MultiUpdate?.multisteptable2?.cef_projet);
    const Shab = Number(MultiUpdate?.multisteptable2?.heatpump_surface);
    const finalcumac = (cef_initial - cef_projet) * Shab * 18;
    console.log('finalcumac', finalcumac);
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    initialValues.obligeCumacValue = finalcumac;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = finalcumac;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // INSTALLATIONTYPE BAR-TH-125
  const b2cinstallationkeys = Object.keys(INSTALLATIONTYPE);
  console.log('keys[0]', b2cinstallationkeys[0]);
  if (categoryGet === b2cinstallationkeys[0]) {
    let installation = MultiUpdate?.multisteptable2?.installation_type;
    console.log('installation', installation);
    let ZoneValue = INSTALLATIONTYPE['BAR-TH-125'];
    console.log('ZoneValue', ZoneValue);
    console.log('ZoneValue', ZoneValue[installation]);
    // H1,H2,H3 Filter 
    const filterValueZone = filteredValue[0]?.value;
    console.log('filterValueZone', filterValueZone);
    // let dwellings = Number(SplitDataZone?.dwellings_served);
    // console.log('dwellings',dwellings);
    let surface_store = Number(MultiUpdate?.multisteptable2?.heatpump_surface);
    console.log('surface_store', surface_store);
    let ZoneA;
    let zoneorsurface;
    if (installation === 'Self-adjusting collective') {
      const filteredCalcZone = ZoneValue[installation]?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
      zoneorsurface = Number(MultiUpdate?.multisteptable2?.dwellings_served);
      console.log('zoneorsurface', zoneorsurface);
    } else if (installation === 'Individual self-adjusting') {
      const filteredCalcZone = ZoneValue[installation]?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
      // SURFACE
      if (surface_store < 35) {
        zoneorsurface = 0.3;
      } else if (35 <= surface_store && surface_store < 60) {
        zoneorsurface = 0.5;
      } else if (60 <= surface_store && surface_store < 70) {
        zoneorsurface = 0.6;
      } else if (70 <= surface_store && surface_store < 90) {
        zoneorsurface = 0.7;
      } else if (90 <= surface_store && surface_store < 110) {
        zoneorsurface = 1;
      } else if (110 <= surface_store && surface_store <= 130) {
        zoneorsurface = 1.1;
      } else {
        zoneorsurface = 1.6;
      }
      console.log('zoneorsurface', zoneorsurface);
    } else {
      const filteredCalcZone = ZoneValue[installation]?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
      // SURFACE
      if (surface_store < 35) {
        zoneorsurface = 0.3;
      } else if (35 <= surface_store && surface_store < 60) {
        zoneorsurface = 0.5;
      } else if (60 <= surface_store && surface_store < 70) {
        zoneorsurface = 0.6;
      } else if (70 <= surface_store && surface_store < 90) {
        zoneorsurface = 0.7;
      } else if (90 <= surface_store && surface_store < 110) {
        zoneorsurface = 1;
      } else if (110 <= surface_store && surface_store <= 130) {
        zoneorsurface = 1.1;
      } else {
        zoneorsurface = 1.6;
      }
      console.log('zoneorsurface', zoneorsurface);
    }
    const finalcumac = ZoneA * zoneorsurface;
    console.log('finalcumac', finalcumac);
    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    initialValues.obligeCumacValue = finalcumac;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = finalcumac;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // INSTALLATIONTYPE BAR-TH-161 (B2B)
  const b2cplateheatkeys = Object.keys(PLATEHEAT);
  console.log('keys[0]', b2cplateheatkeys[0]);
  if (categoryGet === b2cplateheatkeys[0]) {
    let ZoneValue = PLATEHEAT['BAR-TH-161'];
    console.log('ZoneValue', ZoneValue);
    let fifty_onetwenty = ZoneValue['50-120'];
    let onetwenty_Above = ZoneValue['120-Above'];
    console.log('fifty_onetwenty', fifty_onetwenty, 'onetwenty_Above', onetwenty_Above);
    let diameter20to65V1 = MultiUpdate?.multisteptable2?.diameter20to65V1;
    let diameter66to100V1 = MultiUpdate?.multisteptable2?.diameter66to100V1;
    let diameter101to150V1 = MultiUpdate?.multisteptable2?.diameter101to150V1;
    let heatexchangerV1 = MultiUpdate?.multisteptable2?.heatexchangerV1;

    let diameter20to65V2 = MultiUpdate?.multisteptable2?.diameter20to65V2;
    let diameter66to100V2 = MultiUpdate?.multisteptable2?.diameter66to100V2;
    let diameter101to150V2 = MultiUpdate?.multisteptable2?.diameter101to150V2;
    let heatexchangerV2 = MultiUpdate?.multisteptable2?.heatexchangerV2;
    // let diameter20to65V1 = SplitDataZone?.diameter20to65V1;
    console.log('heatexchangerV1', heatexchangerV1, 'heatexchangerV2', heatexchangerV2);
    // H1,H2,H3
    const filterValueZone = filteredValue[0]?.value;
    console.log('filterValueZone', filterValueZone);
    let ZoneA;
    let firstzoneA = 0;
    let secondZoneA = 0;
    if (diameter20to65V1) {
      const filteredCalcZone = fifty_onetwenty['20-65']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      firstzoneA = ZoneA;
      console.log(Number(ZoneA));
    }
    if (diameter66to100V1) {
      const filteredCalcZone = fifty_onetwenty['65-100']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      const z = firstzoneA + ZoneA;
      firstzoneA = z;
      console.log(Number(ZoneA));
    }
    if (diameter101to150V1) {
      const filteredCalcZone = fifty_onetwenty['100-Above']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      const z = firstzoneA + ZoneA;
      firstzoneA = z;
      console.log(Number(ZoneA));
    }
    console.log('firstzoneA', firstzoneA);

    if (heatexchangerV1) {
      const filteredCalcZone = fifty_onetwenty['single-50-120']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
    }
    // 120 Above
    if (diameter20to65V2) {
      const filteredCalcZone = onetwenty_Above['20-65']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      secondZoneA = ZoneA;
      console.log(Number(ZoneA));
    }
    if (diameter66to100V2) {
      const filteredCalcZone = onetwenty_Above['65-100']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      const z = secondZoneA + ZoneA;
      secondZoneA = z;
      console.log(Number(ZoneA));
    }
    if (diameter101to150V2) {
      const filteredCalcZone = onetwenty_Above['100-Above']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      const z = secondZoneA + ZoneA;
      secondZoneA = z;
      console.log(Number(ZoneA));
    }
    if (heatexchangerV2) {
      const filteredCalcZone = fifty_onetwenty['single-50-120']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
    }
    const fifty_onetwenty_add = firstzoneA;
    const onetwenty_Above_add = secondZoneA;
    console.log('fifty_onetwenty_add', fifty_onetwenty_add, 'onetwenty_Above_add', onetwenty_Above_add);
    const cumacone = fifty_onetwenty_add * heatexchangerV1;
    const cumactwo = onetwenty_Above_add * heatexchangerV2;
    const finalcumac = cumacone + cumactwo;
    console.log('cumacone', cumacone, 'cumactwo', cumactwo, 'finalcumac', finalcumac);

    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    initialValues.obligeCumacValue = finalcumac;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = finalcumac;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // BAT-TH-155
  if (categoryGet === b2cplateheatkeys[1]) {
    let ZoneValue = PLATEHEAT['BAT-TH-155'];
    console.log('ZoneValue', ZoneValue);
    let fifty_onetwenty = ZoneValue['50-120'];
    let onetwenty_Above = ZoneValue['120-Above'];
    console.log('fifty_onetwenty', fifty_onetwenty, 'onetwenty_Above', onetwenty_Above);
    let diameter20to65V1 = MultiUpdate?.multisteptable2?.diameter20to65V1;
    let diameter66to100V1 = MultiUpdate?.multisteptable2?.diameter66to100V1;
    let diameter101to150V1 = MultiUpdate?.multisteptable2?.diameter101to150V1;
    let heatexchangerV1 = MultiUpdate?.multisteptable2?.heatexchangerV1;

    let diameter20to65V2 = MultiUpdate?.multisteptable2?.diameter20to65V2;
    let diameter66to100V2 = MultiUpdate?.multisteptable2?.diameter66to100V2;
    let diameter101to150V2 = MultiUpdate?.multisteptable2?.diameter101to150V2;
    let heatexchangerV2 = MultiUpdate?.multisteptable2?.heatexchangerV2;
    // let diameter20to65V1 = SplitDataZone?.diameter20to65V1;
    console.log('heatexchangerV1', heatexchangerV1, 'heatexchangerV2', heatexchangerV2);
    // H1,H2,H3
    const filterValueZone = filteredValue[0]?.value;
    console.log('filterValueZone', filterValueZone);
    let ZoneA;
    let firstzoneA = 0;
    let secondZoneA = 0;
    if (diameter20to65V1) {
      const filteredCalcZone = fifty_onetwenty['20-65']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      firstzoneA = ZoneA;
      console.log(Number(ZoneA));
    }
    if (diameter66to100V1) {
      const filteredCalcZone = fifty_onetwenty['65-100']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      const z = firstzoneA + ZoneA;
      firstzoneA = z;
      console.log(Number(ZoneA));
    }
    if (diameter101to150V1) {
      const filteredCalcZone = fifty_onetwenty['100-Above']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      const z = firstzoneA + ZoneA;
      firstzoneA = z;
      console.log(Number(ZoneA));
    }
    console.log('firstzoneA', firstzoneA);

    if (heatexchangerV1) {
      const filteredCalcZone = fifty_onetwenty['single-50-120']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
    }
    // 120 Above
    if (diameter20to65V2) {
      const filteredCalcZone = onetwenty_Above['20-65']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      secondZoneA = ZoneA;
      console.log(Number(ZoneA));
    }
    if (diameter66to100V2) {
      const filteredCalcZone = onetwenty_Above['65-100']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      const z = secondZoneA + ZoneA;
      secondZoneA = z;
      console.log(Number(ZoneA));
    }
    if (diameter101to150V2) {
      const filteredCalcZone = onetwenty_Above['100-Above']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      const z = secondZoneA + ZoneA;
      secondZoneA = z;
      console.log(Number(ZoneA));
    }
    if (heatexchangerV2) {
      const filteredCalcZone = fifty_onetwenty['single-50-120']?.filter(item => Object.keys(item)[0] === filterValueZone);
      console.log(filteredCalcZone);
      const ZoneFinal = filteredCalcZone?.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return value;
      });
      ZoneA = Number(ZoneFinal);
      console.log(Number(ZoneA));
    }
    const fifty_onetwenty_add = firstzoneA;
    const onetwenty_Above_add = secondZoneA;
    console.log('fifty_onetwenty_add', fifty_onetwenty_add, 'onetwenty_Above_add', onetwenty_Above_add);
    const cumacone = fifty_onetwenty_add * heatexchangerV1;
    const cumactwo = onetwenty_Above_add * heatexchangerV2;
    const finalcumac = cumacone + cumactwo;
    console.log('cumacone', cumacone, 'cumactwo', cumactwo, 'finalcumac', finalcumac);
    // Type de Batiment
    const typedebatiment = SplitData?.chantier_typeOfBuilding;
    console.log('typedebatiment', typedebatiment);
    console.log(ZoneValue.typedebatiment);
    const foundItem = ZoneValue?.typedebatiment.find(item => Object.keys(item)[0] === typedebatiment);
    console.log(foundItem);
    const singleBatiment = Object.values(foundItem)[0];
    console.log(singleBatiment);
    //  cumac + batiment
    const finalcumac_typebatiment = finalcumac * singleBatiment;
    console.log('finalcumac_typebatiment', finalcumac_typebatiment);

    let gigabite = initialValues.obligeValuePrice / 1000000;
    let gigabitepartnaire = initialValues.partenaireValuePrice / 1000000;
    console.log('gigabite', 'gigabitepartnaire', gigabite, gigabitepartnaire);
    initialValues.obligeCumacValue = finalcumac_typebatiment;
    initialValues.obligeTotalPremiumAmount = initialValues.obligeCumacValue * gigabite;

    initialValues.partnerCumacValue = finalcumac_typebatiment;
    initialValues.partnerTotalPremiumAmount = initialValues.partnerCumacValue * gigabitepartnaire;
  }
  // // -----------------------------OBLIGE CALCULATION------------------------
  const changeObligeBenificiaire = (values, setFieldValue) => {
    setFieldValue('obligeProfitShare', values?.obligeProfitShare);
    // setFieldValue('partnerProfitShare', values?.partnerProfitShare);
    // 1 - BAR-EN-101
    if (categoryGet === keys[0]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);

    }
    // 2 - BAR-EN-102
    if (categoryGet === keys[1]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 3 - BAR-EN-103
    if (categoryGet === keys[2]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // N CALCULATION (N) 
    // 1 - BAR-EN-104
    if (categoryGet === Ncalculationkeys[0]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 2 - BAR-SE-104
    if (categoryGet === Ncalculationkeys[1]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // L CALCULATION (L)
    // 1 - BAR-TH-160
    if (categoryGet === Lcalculationkeys[0]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 2 - BAT-TH-146
    if (categoryGet === Lcalculationkeys[1]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // TYBEDEPATIMENT CALCULATION
    // 1 - BAT-EN-101
    if (categoryGet === typeofbuildingkeys[0]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 2 - BAT-EN-102
    if (categoryGet === typeofbuildingkeys[1]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 3 - BAT-EN-103
    if (categoryGet === typeofbuildingkeys[2]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 4 - BAT-TH-104
    if (categoryGet === typeofbuildingkeys[3]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // RES-EC-104 CALCULATION
    if (categoryGet === 'RES-EC-104') {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // GTB CALCULATION
    // 1 BAT-TH-116
    if (categoryGet === GTPkeys[0]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 1 BAT-EQ-133
    if (categoryGet === GTPkeys[1]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // B2C
    // 1 - BAR-TH-143
    if (categoryGet === b2ckeys[0]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 2 - BAR-TH-113
    if (categoryGet === b2ckeys[1]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // DATE CALCULATION
    // 1 - BAR-TH-101
    if (categoryGet === b2cdatekeys[0]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 2 - BAR-TH-148
    if (categoryGet === b2cdatekeys[1]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // ENERGY & SURFACE
    // 1 - BAR-TH-104
    if (categoryGet === b2cenergykeys[0]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 1 BAR-TH-164
    if (categoryGet === 'BAR-TH-164') {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 1 BAR-TH-125
    if (categoryGet === b2cinstallationkeys[0]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // 1 BAR-TH-161
    if (categoryGet === b2cplateheatkeys[0]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
    // BAT-TH-155
    if (categoryGet === b2cplateheatkeys[1]) {
      const calculone = values?.obligeTotalPremiumAmount - values?.obligeProfitShare;
      const VAT = calculone * 0.2;
      const TOTALTTC = calculone + VAT + values?.obligeProfitShare;
      setFieldValue('obligeShareInstallerHT', calculone);
      setFieldValue('obligeVAT', VAT);
      setFieldValue('obligeTotalPrice', TOTALTTC);
    }
  }

  // // ---------------PARTNAIRE CALCULATION------------------------
  const changePartBenificiaire = (values, setFieldValue) => {
    setFieldValue('partnerProfitShare', values?.partnerProfitShare);
    console.log('partnerProfitShare', values.partnerProfitShare);
    // ZONE ONLY CALCULATION
    // 1 - BAR-EN-101
    if (categoryGet === keys[0]) {
      // if ('Classique' == Neighborhood) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);

    }
    // 2 - BAR-EN-102
    if (categoryGet === keys[1]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 3 - BAR-EN-103
    if (categoryGet === keys[2]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // N CALCULATION
    // 1 - BAR-EN-104
    if (categoryGet === Ncalculationkeys[0]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 2 - BAR-SE-104
    if (categoryGet === Ncalculationkeys[1]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // L CALCULATION (L)
    // 1 - BAR-TH-160
    if (categoryGet === Lcalculationkeys[0]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 2 - BAT-TH-146
    if (categoryGet === Lcalculationkeys[1]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // TYBEDEPATIMENT CALCULATION
    // 1 - BAT-EN-101
    if (categoryGet === typeofbuildingkeys[0]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 2 - BAT-EN-102
    if (categoryGet === typeofbuildingkeys[1]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 3 - BAT-EN-103
    if (categoryGet === typeofbuildingkeys[2]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 4 - BAT-TH-104
    if (categoryGet === typeofbuildingkeys[3]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // RES-EC-104 CALCULATION
    if (categoryGet === 'RES-EC-104') {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // GTB CALCULATION
    // 1 BAT-TH-116
    if (categoryGet === GTPkeys[0]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 1 BAT-EQ-133
    if (categoryGet === GTPkeys[1]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // BAR-TH-143
    // 1 - BAR-TH-143
    if (categoryGet === b2ckeys[0]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 2 - BAR-TH-113
    if (categoryGet === b2ckeys[1]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // DATE CALCULATION
    // 1 - BAR-TH-101
    if (categoryGet === b2cdatekeys[0]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 2 - BAR-TH-148
    if (categoryGet === b2cdatekeys[1]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // ENERGY & SURFACE
    // 1 - BAR-TH-104
    if (categoryGet === b2cenergykeys[0]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 1 BAR-TH-164
    if (categoryGet === 'BAR-TH-164') {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 1 BAR-TH-125
    if (categoryGet === b2cinstallationkeys[0]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // 1 BAR-TH-161
    if (categoryGet === b2cplateheatkeys[0]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
    // BAT-TH-155
    if (categoryGet === b2cplateheatkeys[1]) {
      const calcultwo = values?.partnerTotalPremiumAmount - values?.partnerProfitShare;
      const VATtwo = calcultwo * 0.2;
      const TOTALTTCtwo = calcultwo + VATtwo + values?.partnerProfitShare;
      setFieldValue('partnerShareInstallerHT', calcultwo);
      setFieldValue('partnerVAT', VATtwo);
      setFieldValue('partnerTotalPrice', TOTALTTCtwo);
    }
  }

  // FORM SUBMIT
  const handleSubmit = async (values) => {
    console.log("Estimation Page Values", values);
    sessionStorage.setItem("step-7", JSON.stringify(values));
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/update-multistep`, values,
        {
          headers: { authorization: Token }
        }).then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log('res.message', response?.data?.message);
            toastsuccess(response?.data?.message);
          }
        })
    } catch (error) {
      console.log(error.response?.data?.message);
      toasterror(error.response?.data?.message);
    }
  }
  // PREVIEW BUTTON
  const handlePrev = () => {
    navigate(-1);
  };
  // 
  const installerorobligelocal = localStorage.getItem('installerorobligelocal');
  console.log('installerorobligelocal', installerorobligelocal);
  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className='container-fluid pb-4'>
              <div className="save_button_container d-flex justify-content-between align-items-center">
                <p className="bar1-para">Estimate </p> <button type='submit' onClick={handleSubmit} className='btns-color'>Sauvegarder</button>
              </div>
              <div className='row  mt-2'>
                <div className='col-sm-4 input-box1'>
                  <p className='bar1-para'>OBLIGE</p>
                  <input
                    type="text"
                    name="oblige"
                    value={values.oblige}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.oblige && errors.oblige ? 'error' : 'form-control1 w-100 m-0'} ></input>
                  {touched.oblige && errors.oblige && <div className="error-message">{errors.oblige}</div>}
                </div>
                <div className='col-sm-4 input-box1'>
                  <p className='bar1-para'>PARTENAIRE</p>
                  <input
                    type="text"
                    name="partenaire"
                    value={values.partenaire}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.partenaire && errors.partenaire ? 'error' : 'form-control1 w-100 m-0'} ></input>
                  {touched.partenaire && errors.partenaire && <div className="error-message">{errors.partenaire}</div>}
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-sm-4 input-box1'>
                  <p className='bar1-para'>Tarif valo OBLIGE</p>
                  <input type="text"
                    name="obligeValuePrice"
                    value={values.obligeValuePrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.obligeValuePrice && errors.obligeValuePrice ? 'error' : 'form-control1 w-100 m-0'} ></input>
                  {touched.obligeValuePrice && errors.obligeValuePrice && <div className="error-message">{errors.obligeValuePrice}</div>}
                </div>
                <div className='col-sm-4 input-box1'>
                  <p className='bar1-para'>Tarif valo PARTENAIRE</p>
                  <input type="text"
                    name="partenaireValuePrice"
                    value={values.partenaireValuePrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.partenaireValuePrice && errors.partenaireValuePrice ? 'error' : 'form-control1 w-100 m-0'} ></input>
                  {touched.partenaireValuePrice && errors.partenaireValuePrice && <div className="error-message">{errors.partenaireValuePrice}</div>}
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-sm-4 input-box1'>
                  <p className='bar1-para'>Type de quartier</p>
                  <input type="text"
                    name="neighborhoodType"
                    value={values.neighborhoodType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.neighborhoodType && errors.neighborhoodType ? 'error' : 'form-control1 w-100 m-0'} ></input>
                  {touched.neighborhoodType && errors.neighborhoodType && <div className="error-message">{errors.neighborhoodType}</div>}
                </div>
                <div className='col-sm-4 input-box1'>
                  <p className='bar1-para'>Mode de chauffage</p>
                  <input type="text"
                    name="heatingMode"
                    value={values.heatingMode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.heatingMode && errors.heatingMode ? 'error' : 'form-control1 w-100 m-0'} ></input>
                  {touched.heatingMode && errors.heatingMode && <div className="error-message">{errors.heatingMode}</div>}
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-sm-4 input-box1'>
                  <p className='bar1-para'>Type de batiment</p>
                  <input type="text"
                    name="typeOfBuilding"
                    value={values.typeOfBuilding}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.typeOfBuilding && errors.typeOfBuilding ? 'error' : 'form-control1 w-100 m-0'} ></input>
                </div>
                <div className='col-sm-4 input-box1'>
                  <p className='bar1-para'>Zone</p>
                  <input type="text"
                    name="area"
                    value={values.area}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.area && errors.area ? 'error' : 'form-control1 w-100 m-0'} ></input>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-sm-4 input-box1'>
                  <p className='bar1-para'>Surface</p>
                  <input type="text"
                    name="surface"
                    value={values.surface}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.surface && errors.surface ? 'error' : 'form-control1 w-100 m-0'} ></input>
                </div>
                {Neighborhood === 'QPV' ? (
                  <div className='col-sm-4 input-box1'>
                    <p className='bar1-para'>PART CLASSIQUE</p>
                    <input type="text"
                      name="partclassique"
                      value={values.partclassique}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={touched.partclassique && errors.partclassique ? 'error' : 'form-control1 w-100 m-0'} ></input>
                  </div>
                ) : null}
              </div>
              {Neighborhood === 'QPV' ? (
                <div className='row mt-2'>
                  <div className='col-sm-4 input-box1'>
                    <p className='bar1-para'>PART GP ET P</p>
                    <input type="text"
                      name="partgpetp"
                      value={values.partgpetp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={touched.partgpetp && errors.partgpetp ? 'error' : 'form-control1 w-100 m-0'} ></input>
                  </div>
                  <div className='col-sm-4 input-box1'>
                    <p className='bar1-para'>P et GP*</p>
                    <input type="text"
                      name="petgp"
                      value={values.petgp}
                      onChange={handleChange}
                      onKeyUp={() => PETGP(values, setFieldValue)}
                      onBlur={handleBlur}
                      placeholder='Entrez sil vous plait P et GP'
                      className={touched.petgp && errors.petgp ? 'error' : 'form-control1 w-100 m-0'} ></input>
                  </div>
                </div>
              ) : null}

              {installerorobligelocal === 'Super Manager' || installerorobligelocal === 'oblige' ? (
                <>
                  {/* SECOND */}
                  <div className='row mt-5'>
                    <p className='bar1-head '>Estimation pour le oblige</p>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>VALEUR Kwh CUMAC</p>
                      <input
                        type="text"
                        name="obligeCumacValue"
                        value={values.obligeCumacValue}
                        className='form-control1 w-100 m-0'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled />
                    </div>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>MONTANT TOTALE DE LA PRIME</p>
                      <input
                        type="text"
                        name="obligeTotalPremiumAmount"
                        value={values.obligeTotalPremiumAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className='form-control1 w-100 m-0'
                        disabled />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>PART BENEFICIAIRE*</p>
                      <input
                        type="number"
                        name="obligeProfitShare"
                        value={values.obligeProfitShare}
                        onChange={handleChange}
                        onKeyUp={() => changeObligeBenificiaire(values, setFieldValue)}
                        onBlur={handleBlur}
                        placeholder='enter oblige part beneficiaire'
                        className={touched.obligeProfitShare && errors.obligeProfitShare ? 'error' : 'form-control1 w-100 m-0'} />
                      {touched.obligeProfitShare && errors.obligeProfitShare && <div className="error-message">{errors.obligeProfitShare}</div>}
                    </div>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>PART INSTALLATEUR HT</p>
                      <input
                        type="text"
                        name="obligeShareInstallerHT"
                        value={values.obligeShareInstallerHT}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='0'
                        className='form-control1 w-100 m-0' disabled />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>TVA</p>
                      <input
                        type="text"
                        name="obligeVAT"
                        value={values.obligeVAT}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='0'
                        className='form-control1 w-100 m-0' disabled />
                    </div>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>TOTAL TTC</p>
                      <input
                        type="text"
                        name="obligeTotalPrice"
                        value={values.obligeTotalPrice}
                        placeholder='0'
                        className='form-control1 w-100 m-0' disabled />
                    </div>
                  </div>
                </>
              ) : null}
              {installerorobligelocal === 'Super Manager' || installerorobligelocal === 'installer' ? (
                <>
                  {/* THREE */}
                  <div className='row mt-5'>
                    <p className='bar1-head  '>Estimation pour le partenaire</p>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>VALEUR Kwh CUMAC</p>
                      <input
                        type="text"
                        name="partnerCumacValue"
                        value={values.partnerCumacValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className='form-control1 w-100 m-0' disabled />
                    </div>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>MONTANT TOTALE DE LA PRIME</p>
                      <input
                        type="text"
                        name="partnerTotalPremiumAmount"
                        value={values.partnerTotalPremiumAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className='form-control1 w-100 m-0' disabled />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>PART BENEFICIAIRE*</p>
                      <input
                        type="number"
                        name="partnerProfitShare"
                        value={values.partnerProfitShare}
                        onChange={handleChange}
                        onKeyUp={() => changePartBenificiaire(values, setFieldValue)}
                        onBlur={handleBlur}
                        placeholder='entrez partenaire partie bénéficiaire'
                        className={touched.partnerProfitShare && errors.partnerProfitShare ? 'error' : 'form-control1 w-100 m-0'} />
                      {touched.partnerProfitShare && errors.partnerProfitShare && <div className="error-message">{errors.partnerProfitShare}</div>}
                    </div>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>PART INSTALLATEUR HT</p>
                      <input
                        type="text"
                        name="partnerShareInstallerHT"
                        value={values.partnerShareInstallerHT}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='0'
                        className='form-control1 w-100 m-0' disabled />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>TVA</p>
                      <input
                        type="text"
                        name="partnerVAT"
                        value={values.partnerVAT}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='0'
                        className='form-control1 w-100 m-0' disabled />
                    </div>
                    <div className='col-sm-4 input-box1'>
                      <p className='bar1-para'>TOTAL TTC</p>
                      <input
                        type="text"
                        name="partnerTotalPrice"
                        value={values.partnerTotalPrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='0'
                        className='form-control1 w-100 m-0' disabled />
                    </div>
                  </div>
                </>
              ) : null}
              {/* <div className='row mt-5 mb-2'>
                <div className='col text-start'>
                  <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                </div>
                <div className="col text-end">
                  <button type='submit' className='btns-color'>Suivant</button>
                </div>
              </div> */}
            </div>
          </form>
        );
      }}
    </Formik>
  )
}

export default EstimateB2C