import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
// FORMIK
import { Formik } from 'formik';

function Devis() {

    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    // ----------------------------------------------
    useEffect(() => {
        // Retrieve stored form values from local storage
        const storedFormValues = sessionStorage.getItem('step-10');

        if (storedFormValues) {
            // Parse the stored form values and set them as initial values
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.estimate_installerContactDetails = parsedFormValues.estimate_installerContactDetails;
            initialValues.estimate_subcontractorContactDetails = parsedFormValues.estimate_subcontractorContactDetails;
            initialValues.estimate_socialReason = parsedFormValues.estimate_socialReason;
            initialValues.estimate_tradeName = parsedFormValues.estimate_tradeName;
            initialValues.estimate_nSiret = parsedFormValues.estimate_nSiret;
            initialValues.estimate_address = parsedFormValues.estimate_address;
            initialValues.estimate_cp = parsedFormValues.estimate_cp;
            initialValues.estimate_city = parsedFormValues.estimate_city;
            initialValues.estimate_phone = parsedFormValues.estimate_phone;
            initialValues.estimate_siretInstaller = parsedFormValues.estimate_siretInstaller;
            initialValues.estimate_identityOftheBeneficiary = parsedFormValues.estimate_identityOftheBeneficiary;
            initialValues.estimate_quoteIssueDate = parsedFormValues.estimate_quoteIssueDate;
            initialValues.estimate_mentionsRequiredByTheOperationSheet = parsedFormValues.estimate_mentionsRequiredByTheOperationSheet;
            initialValues.estimate_beneficiarySiret = parsedFormValues.estimate_beneficiarySiret;
            initialValues.estimate_beneficiaryAddress = parsedFormValues.estimate_beneficiaryAddress;
            initialValues.estimate_beneficiaryName = parsedFormValues.estimate_beneficiaryName;
            initialValues.estimate_beneficiaryPhone = parsedFormValues.estimate_beneficiaryPhone;
            initialValues.estimate_beneficiaryFirstName = parsedFormValues.estimate_beneficiaryFirstName;
            initialValues.estimate_beneficiaryEmail = parsedFormValues.estimate_beneficiaryEmail;
            initialValues.estimate_beneficiaryFunction = parsedFormValues.estimate_beneficiaryFunction;
            initialValues.estimate_placeOfWork = parsedFormValues.estimate_placeOfWork;
            initialValues.estimate_purchaseOrderNo = parsedFormValues.estimate_purchaseOrderNo;
            initialValues.estimate_incentiveRoleCompliance = parsedFormValues.estimate_incentiveRoleCompliance;
            initialValues.estimate_mentionOfTheIncentiveRole = parsedFormValues.estimate_mentionOfTheIncentiveRole;
            initialValues.estimate_quoteNo = parsedFormValues.estimate_quoteNo;
            initialValues.estimate_goodForAgreement = parsedFormValues.estimate_goodForAgreement;
            initialValues.estimate_handwrittenDate = parsedFormValues.estimate_handwrittenDate;
            initialValues.estimate_surnameFirstName = parsedFormValues.estimate_surnameFirstName;
            initialValues.estimate_brand = parsedFormValues.estimate_brand;
            initialValues.estimate_reference = parsedFormValues.estimate_reference;
            initialValues.estimate_presenceOfTheStandard = parsedFormValues.estimate_presenceOfTheStandard;
            initialValues.estimate_attendanceDateandSignatureOfTheCustomer = parsedFormValues.estimate_attendanceDateandSignatureOfTheCustomer;
            initialValues.estimate_insulationClass = parsedFormValues.estimate_insulationClass;
            initialValues.estimate_otherMandatoryMaterialMentions = parsedFormValues.estimate_otherMandatoryMaterialMentions;
            initialValues.estimate_amountOfBeneficiaryPremium = parsedFormValues.estimate_amountOfBeneficiaryPremium;
            initialValues.estimate_raiPremium = parsedFormValues.estimate_raiPremium;
            initialValues.Marque = parsedFormValues.Marque;
            initialValues.Classedelisolant = parsedFormValues.Classedelisolant;
            initialValues.materialmentions = parsedFormValues.materialmentions;
            initialValues.estimate_comments = parsedFormValues.estimate_comments;
            initialValues.estimate_file = parsedFormValues.estimate_file;
        }
    }, []);

    const initialValues = {
        estimate_installerContactDetails: false,
        estimate_subcontractorContactDetails: false,
        estimate_socialReason: '',
        estimate_tradeName: '',
        estimate_nSiret: '',
        estimate_address: '',
        estimate_cp: '',
        estimate_city: '',
        estimate_phone: '',
        estimate_siretInstaller: '',
        estimate_identityOftheBeneficiary: false,
        estimate_quoteIssueDate: false,
        estimate_mentionsRequiredByTheOperationSheet: false,
        estimate_beneficiarySiret: '',
        estimate_beneficiaryAddress: false,
        estimate_beneficiaryName: false,
        estimate_beneficiaryPhone: false,
        estimate_beneficiaryFirstName: false,
        estimate_beneficiaryEmail: false,
        estimate_beneficiaryFunction: false,
        estimate_placeOfWork: false,
        estimate_purchaseOrderNo: false,
        estimate_incentiveRoleCompliance: false,
        estimate_mentionOfTheIncentiveRole: false,
        estimate_quoteNo: '',
        estimate_goodForAgreement: false,
        estimate_handwrittenDate: false,
        estimate_surnameFirstName: false,
        estimate_brand: false,
        estimate_reference: false,
        estimate_presenceOfTheStandard: false,
        estimate_attendanceDateandSignatureOfTheCustomer: false,
        estimate_insulationClass: false,
        estimate_otherMandatoryMaterialMentions: false,
        estimate_amountOfBeneficiaryPremium: '',
        estimate_raiPremium: false,
        Marque: '',
        Classedelisolant: '',
        materialmentions: '',
        estimate_comments: '',
        estimate_file: null,
    }
    // VALIDE
    const validate = (values) => {
        console.log("validate", values);
        const errors = {};

        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-10", JSON.stringify(values));
        navigate('/facture');
    }
    // BACK BUTTON
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-10');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid py-3'>
                        <div className="row">
                            <p className='bar1-head'>Devis</p>
                            <div className="col text-start">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="estimate_installerContactDetails" name="estimate_installerContactDetails" checked={values.estimate_installerContactDetails} onChange={() => {
                                        setFieldValue('estimate_installerContactDetails', !values.estimate_installerContactDetails);
                                        setFieldValue('estimate_subcontractorContactDetails', false);
                                    }} />
                                    <label className="form-check-label bar1-para" htmlFor="estimate_installerContactDetails">Coordonnées Installateur</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input " type="checkbox" id="estimate_subcontractorContactDetails" name="estimate_subcontractorContactDetails" checked={values.estimate_subcontractorContactDetails} onChange={() => {
                                        setFieldValue('estimate_subcontractorContactDetails', !values.estimate_subcontractorContactDetails);
                                        setFieldValue('estimate_installerContactDetails', false);
                                    }} />
                                    <label className="form-check-label bar1-para" htmlFor="estimate_subcontractorContactDetails">Coordonnées de sous traitant</label>
                                </div>
                            </div>
                            {values.estimate_subcontractorContactDetails == true && (
                                <>
                                    <div className='row mt-3'>
                                        <div className='col-sm-5 input-box1'>
                                            <p className='bar1-para'>Raison sociale*</p>
                                            <input
                                                type="text"
                                                name="estimate_socialReason"
                                                value={values.estimate_socialReason}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_socialReason && errors.estimate_socialReason ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.estimate_socialReason && errors.estimate_socialReason && <div className="error-message">{errors.estimate_socialReason}</div>}
                                        </div>
                                        <div className='col-sm-2 input-box1'>
                                            <p className='bar1-para'>Nom commercial</p>
                                            <input type="text"
                                                name="estimate_tradeName"
                                                value={values.estimate_tradeName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_tradeName && errors.estimate_tradeName ? 'error' : 'form-control1 w-100 m-0'} />
                                        </div>
                                        <div className='col-sm-5 input-box1'>
                                            <p className='bar1-para bar1-ul'>N Siret*</p>
                                            <input type="number"
                                                name="estimate_nSiret"
                                                value={values.estimate_nSiret}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_nSiret && errors.estimate_nSiret ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.estimate_nSiret && errors.estimate_nSiret && <div className="error-message">{errors.estimate_nSiret}</div>}
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-sm-6 input-box1'>
                                            <p className='bar1-para'>Adresse*</p>
                                            <input type="text"
                                                name="estimate_address"
                                                value={values.estimate_address}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_address && errors.estimate_address ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.estimate_address && errors.estimate_address && <div className="error-message">{errors.estimate_address}</div>}
                                        </div>
                                        <div className='col-sm-2 input-box1'>
                                            <p className='bar1-para'>CP*</p>
                                            <input type="number"
                                                name="estimate_cp"
                                                value={values.estimate_cp}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_cp && errors.estimate_cp ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.estimate_cp && errors.estimate_cp && <div className="error-message">{errors.estimate_cp}</div>}
                                        </div>
                                        <div className='col-sm-4 input-box1'>
                                            <p className='bar1-para'>Ville*</p>
                                            <input type="text"
                                                name="estimate_city"
                                                value={values.estimate_city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_city && errors.estimate_city ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.estimate_city && errors.estimate_city && <div className="error-message">{errors.estimate_city}</div>}
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-sm-3 input-box1'>
                                            <p className='bar1-para'>Telephone*</p>
                                            <input type="text"
                                                name="estimate_phone"
                                                value={values.estimate_phone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_phone && errors.estimate_phone ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.estimate_phone && errors.estimate_phone && <div className="error-message">{errors.estimate_phone}</div>}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row my-3">
                            <div className="col text-start">
                                <div className='bar1-para d-flex '>
                                    SIRET INSTALLATEUR &nbsp; <a href='https://avis-situation-sirene.insee.fr/' target='_blank' className='bar1-ul'> (vérification si ok avec SIRET installateur dans fiche installateur)</a>
                                    <div className='d-flex flex-column ms-3'>
                                        <input type="text "
                                            name="estimate_siretInstaller"
                                            value={values.estimate_siretInstaller}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.estimate_siretInstaller && errors.estimate_siretInstaller ? 'error' : 'form-control1  m-0'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <div className='bar1-para   pt-2'>Télécharger le document
                                        <div className='d-flex align-items-center'>
                                            <input
                                                type='file'
                                                name="estimate_file"
                                                onChange={(event) => {
                                                    setFieldValue('estimate_file', event.currentTarget.files[0]);
                                                }}
                                                onBlur={handleBlur}
                                                className={touched.estimate_file && errors.estimate_file ? 'error' : 'form-control1  m-0'}
                                            />
                                            {/* Display the selected file name */}
                                            {values.estimate_file && (
                                                <div className='ms-2'>{values.estimate_file.name}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <div className='bar1-para  align-items-center pt-2'>SIRET bénéficiaire
                                        <div className=''>
                                            <input
                                                name="estimate_beneficiarySiret"
                                                value={values.estimate_beneficiarySiret}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_beneficiarySiret && errors.estimate_beneficiarySiret ? 'error' : 'form-control1  m-0'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_identityOftheBeneficiary" name="estimate_identityOftheBeneficiary" checked={values.estimate_identityOftheBeneficiary} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_identityOftheBeneficiary">Identité du bénéficiaire</label>
                                </div>
                            </div>

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_quoteIssueDate" name="estimate_quoteIssueDate" checked={values.estimate_quoteIssueDate} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_quoteIssueDate">Date d’émission du devis</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_mentionsRequiredByTheOperationSheet" name="estimate_mentionsRequiredByTheOperationSheet" checked={values.estimate_mentionsRequiredByTheOperationSheet} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_mentionsRequiredByTheOperationSheet">Mentions exigées par la fiche d’opération</label>
                                </div>
                            </div>
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_beneficiaryName" name="estimate_beneficiaryName" checked={values.estimate_beneficiaryName} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryName">Nom bénéficiaire</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_beneficiaryAddress" name="estimate_beneficiaryAddress" checked={values.estimate_beneficiaryAddress} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryAddress">Adresse bénéficiaire</label>
                                </div>
                            </div>
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_beneficiaryPhone" name="estimate_beneficiaryPhone" checked={values.estimate_beneficiaryPhone} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryPhone">Telephone bénéficiaire</label>
                                </div>
                            </div>

                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_beneficiaryEmail" name="estimate_beneficiaryEmail" checked={values.estimate_beneficiaryEmail} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryEmail">Email bénéficiaire</label>
                                </div>
                            </div>

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_beneficiaryFirstName" name="estimate_beneficiaryFirstName" checked={values.estimate_beneficiaryFirstName} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryFirstName">Prenom bénéficiaire</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_beneficiaryFunction" name="estimate_beneficiaryFunction" checked={values.estimate_beneficiaryFunction} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_beneficiaryFunction">Fonction bénéficiaire</label>
                                </div>
                            </div>
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_placeOfWork" name="estimate_placeOfWork" checked={values.estimate_placeOfWork} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_placeOfWork">Lieu des travaux</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_purchaseOrderNo" name="estimate_purchaseOrderNo" checked={values.estimate_purchaseOrderNo} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_purchaseOrderNo">N° de devis/Bon de commande</label>
                                </div>
                            </div>
                            <div className="col input-box1">
                                <div className="bar1-para  ">Conformité du rôle Incitatif
                                    <input className="form-check-input ms-3" type="radio" name='estimate_incentiveRoleCompliance' id='estimate_incentiveRoleCompliance' value="Oui" checked={values.estimate_incentiveRoleCompliance === 'Oui'} onChange={handleChange} />
                                    <span className='span-bar bar1-para '>
                                        Oui
                                    </span>
                                    <input className="form-check-input ms-3" type="radio" name='estimate_incentiveRoleCompliance' id='estimate_incentiveRoleCompliance' value="Non" checked={values.estimate_incentiveRoleCompliance === 'Non'} onChange={handleChange} />
                                    <span className='span-bar bar1-para '>
                                        Non
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="row my-3">

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_mentionOfTheIncentiveRole" name="estimate_mentionOfTheIncentiveRole" checked={values.estimate_mentionOfTheIncentiveRole} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_mentionOfTheIncentiveRole">Mention du rôle incitatif</label>
                                </div>
                            </div>

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <div className='bar1-para d-flex align-items-center'>N° de Devis
                                        <div className='d-flex ms-3'>
                                            <input type="text"
                                                name="estimate_quoteNo"
                                                value={values.estimate_quoteNo}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_quoteNo && errors.estimate_quoteNo ? 'error' : 'form-control1  m-0'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_goodForAgreement" name="estimate_goodForAgreement" checked={values.estimate_goodForAgreement} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_goodForAgreement">Bon pour accord </label>
                                </div>
                            </div>
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="estimate_handwrittenDate" name="estimate_handwrittenDate" checked={values.estimate_handwrittenDate} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_handwrittenDate">Date manuscrite </label>
                                </div>
                            </div>
                        </div>

                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="estimate_surnameFirstName" name="estimate_surnameFirstName" checked={values.estimate_surnameFirstName} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_surnameFirstName">Nom, Prenom, Fonction manuscrite </label>
                                </div>
                            </div>
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="estimate_reference" name="estimate_reference" checked={values.estimate_reference} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_reference">Référence</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1 d-flex">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="estimate_brand" name="estimate_brand" checked={values.estimate_brand} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_brand">Marque</label>
                                </div>
                                {values.estimate_brand == true && (
                                    <>
                                        <input type="text"
                                            name="Marque"
                                            value={values.Marque}
                                            onChange={handleChange} className='form-control1 mx-3 m-0' />
                                    </>
                                )}
                            </div>
                            <div className="col input-box1 d-flex">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="estimate_presenceOfTheStandard" name="estimate_presenceOfTheStandard" checked={values.estimate_presenceOfTheStandard} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_presenceOfTheStandard">Presence de la norme</label>
                                </div>

                            </div>

                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="estimate_attendanceDateandSignatureOfTheCustomer" name="estimate_attendanceDateandSignatureOfTheCustomer" checked={values.estimate_attendanceDateandSignatureOfTheCustomer} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_attendanceDateandSignatureOfTheCustomer">Présence Date et signature du client</label>
                                </div>
                            </div>
                            <div className="col input-box1">
                                <div className="text-start d-flex  align-items-center">
                                    <label className="form-check-label bar1-para mx-2" htmlFor="inlineCheckbox1">Montant de la Prime Beneficiare(€)</label>
                                    <div className="col input-box1">
                                        <div className="text-start ">
                                            <input
                                                type="text "
                                                name="estimate_amountOfBeneficiaryPremium"
                                                value={values.estimate_amountOfBeneficiaryPremium}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.estimate_amountOfBeneficiaryPremium && errors.estimate_amountOfBeneficiaryPremium ? 'error' : 'form-control1  m-0'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1 d-flex ">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="estimate_insulationClass" name="estimate_insulationClass" checked={values.estimate_insulationClass} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_insulationClass">Classe de l’isolant</label>
                                </div>
                                {values.estimate_insulationClass == true && (
                                    <>
                                        <input type="text"
                                            name="Classedelisolant"
                                            value={values.Classedelisolant}
                                            onChange={handleChange} className='form-control1 mx-3 m-0' />
                                    </>
                                )}
                            </div>
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="estimate_raiPremium" name="estimate_raiPremium" checked={values.estimate_raiPremium} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_raiPremium">Prime RAI = Prime DEVIS</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1 d-flex">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="estimate_otherMandatoryMaterialMentions" name="estimate_otherMandatoryMaterialMentions" checked={values.estimate_otherMandatoryMaterialMentions} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="estimate_otherMandatoryMaterialMentions">Autre mentions obligatoire matériel</label>
                                </div>
                                {values.estimate_otherMandatoryMaterialMentions == true && (
                                    <>
                                        <input type="text"
                                            name="materialmentions"
                                            value={values.materialmentions}
                                            onChange={handleChange} className='form-control1 mx-3 m-0' />
                                    </>
                                )}
                            </div>
                            <div className="col-6">
                                <div className="mb-3 text-start">
                                    <label htmlFor="exampleFormControlTextarea1" className="bar1-para text-start">Commentaires :</label>

                                    <div className=" flex-row align-items-start">
                                        <textarea
                                            name="estimate_comments"
                                            value={values.estimate_comments}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.estimate_comments && errors.estimate_comments ? 'error' : 'form-control ml-1 shadow-none textarea'}>
                                        </textarea>
                                        {touched.estimate_comments && errors.estimate_comments && <div className="error-message">{errors.estimate_comments}</div>}
                                    </div></div>
                            </div>
                        </div>

                        <div className='row mt-5 mb-2'>
                            <div className='col text-start'>
                                <button type='button' onClick={handlePrev} className=' btns-color' >Précédent </button>
                            </div>
                            <div className="col text-end">
                                <button type='submit' className='btns-color' >Suivant</button>
                            </div>
                        </div>

                    </div >
                </form>
            )}
        </Formik>

    )
}

export default Devis