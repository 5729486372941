import React from 'react'
import winlogo from "../assets/win-logo.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import profilelogo from "../assets/bar 3.svg";
import Nav from 'react-bootstrap/Nav';

import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useLocation, useNavigate } from "react-router-dom"

function Sidebar1() {
    const navigate = useNavigate();
    const location = useLocation();
    const isLoginPage = location.pathname === '/' || location.pathname === '/login';
    const isChangedPassword = location.pathname === '/forgot-password';
    if (isChangedPassword) {
        return null;
    }
    // Render the Sidebar only if the current path is not '/login'
    if (isLoginPage) {
        return null;
    }
    const installerorobligelocal = localStorage.getItem('installerorobligelocal');
    console.log('installerorobligelocal', installerorobligelocal);

    const handleLogout = () => {
        localStorage.removeItem('Token');
        sessionStorage.clear(); //Edit on 09-06-2023
        navigate('/login');
    };
    return (
        <div className='container-fluid-fluid p-1 '>
            <div className='login-div border-div '>
                <Link to={'/dashboard'} className='pe-auto'>
                    <img
                        src={winlogo}
                        width="auto"
                        height="auto"
                        className="login-logo"
                        alt="win-energy Logo"
                    />
                </Link>
                <div className='profile-img'>
                    <Dropdown className='border-none'>
                        <Dropdown.Toggle className='bg-white border-none' variant="success" id="dropdown-basic">
                            <img
                                src={profilelogo}
                                width="20px"
                                height="20px"
                                className=""
                                alt="win-energy Logo"
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                            {installerorobligelocal === 'Super Manager' ? (<><Dropdown.Item href="" as={Link} to="/adduser">Ajouter un nouvel utilisateur</Dropdown.Item></>) : null}
                            <Dropdown.Item href="" as={Link} to="/users-dashboard">Rôles et droits</Dropdown.Item>
                            <Dropdown.Item href="" as={Link} to="/change-password">changer le mot de passe</Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}>Déconnexion</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {installerorobligelocal === 'Super Manager' ? (
                    <>
                        <div>
                            <div className="row mt-2 ">
                                <div className='col-lg-12 p-0 '>
                                    <Nav className='nav-bar-second '
                                        activeKey="/home"
                                        onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
                                    >
                                        <Nav.Item>
                                            <Nav.Link className="nav-bar-link " as={Link} to="/dashboard"> Dashboard </Nav.Link>
                                        </Nav.Item>
                                        <NavDropdown title="Dossiers" id="nav-dropdown">
                                            <NavDropdown.Item className="nav-bar-link m-0 " as={Link} to="/create-dossier" >
                                                Créer un dossier
                                            </NavDropdown.Item>
                                            <NavDropdown.Item className="nav-bar-link m-0" as={Link} to="/file-dashboard" >Voir tous les dossiers </NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="Liste" id="nav-dropdown">
                                            <NavDropdown.Item className="nav-bar-link m-0" as={Link} to="/liste-install" >
                                                Installateur
                                            </NavDropdown.Item>
                                            <NavDropdown.Item className="nav-bar-link m-0 " as={Link} to="/liste-oblige">

                                                Oblige
                                            </NavDropdown.Item>
                                            {/* <NavDropdown.Item className="nav-bar-link m-0" as={Link} >Regie </NavDropdown.Item> */}
                                        </NavDropdown>
                                    </Nav>
                                </div>

                            </div>
                        </div>
                    </>) : null}
            </div>
        </div >
    )
}

export default Sidebar1