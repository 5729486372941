import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
// FORMIK
import { Formik } from 'formik';
const ValiderCommantUpdate = () => {
    const navigate = useNavigate();
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);

    const [initialValues, setInitialValues] = useState(
        {
            comments: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            comments: MultiUpdate?.multisteptable1?.comments || '',
        }));
    }, []);


    const validate = (values) => {
        console.log("validate", values);
        const errors = {};
        // if (!values.comments) {
        //     errors.comments = 'Commentaires requise';
        // }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-13", JSON.stringify(values));
        navigate('/update-devis');
    }
    // BACK BUTTON
    const handlePrev = () => {
        navigate(-1);
    };
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid-fluid pl-30'>
                            <p className='bar1-head mt-3'>Instruction</p>
                            <div className="row mt-2">
                                <div className="col">
                                    <div className="mb-3 text-start">
                                        <label htmlFor="exampleFormControlTextarea1" className="bar1-para text-start">Commentaires :</label>

                                        <div className=" flex-row align-items-start">
                                            <textarea
                                                name="comments"
                                                value={values.comments}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.comments && errors.comments ? 'error' : 'form-control ml-1 shadow-none textarea'}>
                                            </textarea>
                                            {touched.comments && errors.comments && <div className="error-message">{errors.comments}</div>}
                                        </div></div>
                                </div>
                            </div>
                            <div className='row mt-5 mb-2'>
                                <div className='col text-start'>
                                    <button type='button' onClick={handlePrev} className=' btns-color' >Précédent </button>
                                </div>
                                <div className="col text-end">
                                    <button type='submit' className='btns-color' >Suivant</button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default ValiderCommantUpdate