import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import useFileUpload from 'react-use-file-upload';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RiDeleteBin6Line, RiEyeLine } from "react-icons/ri";
import { IoDocumentTextSharp } from "react-icons/io5";
import upload from '../../../assets/upload 1.svg'
import { Spinner } from 'react-bootstrap'
const DocumentB2C = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);
    const Token = localStorage.getItem('Token');
    const inputRef = useRef();
    // const MultiUpdate = props.MultiUpdate;

    const { id } = useParams();
    const [MultiUpdate, setMultiUpdate] = useState(null);

    useEffect(() => {
        fetchData();
    }, [Token]);
    const fetchData = async () => {
        const options = {
            headers: { authorization: Token }
        };
        try {
            const [multistepResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_API_URL}/get-multistep/${id}`, options),
            ]);
            setMultiUpdate(multistepResponse.data);
        } catch (error) {
            console.error(error);
        }
    };
    console.log('MultiUpdate', MultiUpdate);
    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        handleDragDropEvent,
        setFiles,
        removeFile,
    } = useFileUpload();
    // FILE UPLOAD
    const MAX_TOTAL_SIZE_MB = 10;
    const handleSubmit = async (values) => {
        if (files.length === 0) {
            toast.error('Sélectionner un fichier');
        } else {
            try {
                if (files.length > 7) {
                    toasterror('Vous ne pouvez télécharger que 7 fichiers au maximum.');
                    return;
                }

                // Calculate total size of uploaded files
                const totalUploadedSize = files.reduce((total, file) => total + file.size, 0);

                if (totalUploadedSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
                    toasterror('La taille totale des fichiers ne peut pas dépasser 10 Mo.');
                    return;
                }

                const formData = new FormData();
                files.forEach((file) => {
                    formData.append('files', file);
                });

                // Upload files
                const fileUploadResponse = await axios.post(`${process.env.REACT_APP_API_URL}/upload-files`, formData, {
                    headers: {
                        authorization: Token,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (fileUploadResponse.status === 200) {
                    const uploadedFiles = fileUploadResponse.data.files;
                    const values = {
                        id: id,
                        files: uploadedFiles
                    }
                    const createObligeResponse = await axios.post(`${process.env.REACT_APP_API_URL}/update-multistep`, values, {
                        headers: {
                            authorization: Token,
                        }
                    });

                    if (createObligeResponse.status === 200) {
                        console.log('res.message', createObligeResponse);
                        toastsuccess(createObligeResponse.data.message);
                    } else {
                        toasterror(createObligeResponse?.data?.message);
                    }
                } else {
                    toasterror('File upload failed');
                }
            } catch (error) {
                console.error('Error:', error.response?.data?.message);
                toasterror(error.response?.data?.message);
            }
        }
    };
    const handleFileClick = (name) => {
        const file = files.find((file) => file.name === name);

        if (file) {
            const fileUrl = URL.createObjectURL(file);
            window.open(fileUrl, "_blank");
        } else {
            console.log(file);
        }
    };
    //    MULTIUPDATE DATAS
    const removeAPIFile = async (id) => {
        console.log('id', id);
        try {
            if (window.confirm("Do You Want to Delete?")) {
                const deleteresponse = await axios.delete(`${process.env.REACT_APP_API_URL}/delete-files/${id}`,
                    {
                        headers: { authorization: Token },
                    }
                );
                if (deleteresponse?.status === 200) {
                    console.log('deleteresponse', deleteresponse);
                    fetchData();
                    toastsuccess(deleteresponse?.data?.message);
                };
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }
    if (isLoading) {
        return <Spinner animation="border" className='text-success' />;
    };
    return (
        <>
            <div className="save_button_container d-flex justify-content-between align-items-center">
                <p className="bar1-para">Document Infos</p> <button type='submit' onClick={handleSubmit} className='btns-color'>Sauvegarder</button>
            </div>

            <div className="row my-5 justify-space">
                <div className="col-lg-7 mt-3">
                    <div className="form-container-fluid">
                        <div >
                            <ul className='form-data-text'>
                                {MultiUpdate?.files?.map((filesname) => (
                                    <li key={filesname?.id}>
                                        <span className='bar1-para bar1-ul'><IoDocumentTextSharp /> {filesname?.files.split('/').pop().split('-')}</span>
                                        &nbsp; <span onClick={() => removeAPIFile(filesname?.id)} className='border-0 bg-white green'><RiDeleteBin6Line /></span>
                                        &nbsp; <span><Link to={`${filesname.files}`} className='border-0 bg-white green'><RiEyeLine /></Link></span>
                                    </li>
                                ))}
                                <br></br>
                                {fileNames?.map((name) => (
                                    <li key={name}>
                                        <span className='bar1-para bar1-ul'>
                                            <IoDocumentTextSharp /> {name}</span> <span onClick={() => removeFile(name)}><RiDeleteBin6Line /></span>
                                        <span><button type='button' className='border-0 bg-white' onClick={() => handleFileClick(name)}><RiEyeLine /></button></span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 border-lined ">
                    <div className='text-center mt-3'>
                        <p className='bar1-head text-center '>Téléchargement de fichiers</p>
                        <img src={upload} alt="upload icon" className="text-center ht-100 "></img>
                        <p className='bar1-para1 mt-3'>Sélectionnez un fichier ou faites-le glisser ici</p>
                        <div className=''>
                            <button type='button ' className='btn-upload' onClick={() => inputRef.current.click()}>Sélectionnez un fichier  </button>
                            <div
                                onDragEnter={handleDragDropEvent}
                                onDragOver={handleDragDropEvent}
                                onDrop={(e) => {
                                    handleDragDropEvent(e);
                                    setFiles(e, 'a');
                                }}
                            >
                                <input
                                    ref={inputRef}
                                    type="file"
                                    multiple
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setFiles(e, 'a');
                                        inputRef.current.value = null;
                                    }}
                                />
                            </div>
                        </div>
                        {files?.length > 0 && (
                            <>
                                <p className='bar1-para1 mt-3'>Type de fichier : {fileTypes.join(', ')} <br></br>
                                    Taille du fichier : {totalSize}</p>
                            </>
                        )}
                        {files?.length === 0 && (
                            <p className='bar1-para1 mt-3'>Type de fichier : DOC, PDF, JPEG<br />Taille du fichier : 1 MB</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentB2C
