import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"

// FORMIK
import { Formik } from 'formik';
function Facture() {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const estimate_session = JSON.parse(sessionStorage.getItem('step-10'));
    console.log('estimate_session', estimate_session?.estimate_siretInstaller);
    const estimate_validate = estimate_session?.estimate_siretInstaller;
    // ----------------------------------------------
    useEffect(() => {
        // Retrieve stored form values from local storage
        const storedFormValues = sessionStorage.getItem('step-11');

        if (storedFormValues) {
            // Parse the stored form values and set them as initial values
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.bill_installerContactDetails = parsedFormValues.bill_installerContactDetails;
            initialValues.bill_subcontractorContactDetails = parsedFormValues.bill_subcontractorContactDetails;
            initialValues.bill_socialReason = parsedFormValues.bill_socialReason;
            initialValues.bill_tradeName = parsedFormValues.bill_tradeName;
            initialValues.bill_nSiret = parsedFormValues.bill_nSiret;
            initialValues.bill_address = parsedFormValues.bill_address;
            initialValues.bill_cp = parsedFormValues.bill_cp;
            initialValues.bill_city = parsedFormValues.bill_city;
            initialValues.bill_phone = parsedFormValues.bill_phone;
            initialValues.bill_siretInstaller = parsedFormValues.bill_siretInstaller;
            initialValues.bill_identityOfTheBeneficiary = parsedFormValues.bill_identityOfTheBeneficiary;
            initialValues.bill_invoiceIssueDate = parsedFormValues.bill_invoiceIssueDate;
            initialValues.bill_beneficiarySiret = parsedFormValues.bill_beneficiarySiret;
            initialValues.bill_operationSheet = parsedFormValues.bill_operationSheet;
            initialValues.bill_beneficiaryAddress = parsedFormValues.bill_beneficiaryAddress;
            initialValues.bill_beneficiaryName = parsedFormValues.bill_beneficiaryName;
            initialValues.bill_beneficiaryFirstName = parsedFormValues.bill_beneficiaryFirstName;
            initialValues.bill_beneficiaryPhone = parsedFormValues.bill_beneficiaryPhone;
            initialValues.bill_beneficiaryEmail = parsedFormValues.bill_beneficiaryEmail;
            initialValues.bill_beneficiaryFunction = parsedFormValues.bill_beneficiaryFunction;
            initialValues.bill_lieuDesTravaux = parsedFormValues.bill_lieuDesTravaux;
            initialValues.bill_invoiceNumber = parsedFormValues.bill_invoiceNumber;
            initialValues.bill_vatRate = parsedFormValues.bill_vatRate;
            initialValues.bill_diametersAndThicknesses = parsedFormValues.bill_diametersAndThicknesses;
            initialValues.bill_quoteNo = parsedFormValues.bill_quoteNo;
            initialValues.bill_comments = parsedFormValues.bill_comments;
        }
    }, []);

    const initialValues = {
        bill_installerContactDetails: false,
        bill_subcontractorContactDetails: false,
        bill_socialReason: '',
        bill_tradeName: '',
        bill_nSiret: '',
        bill_address: '',
        bill_cp: '',
        bill_city: '',
        bill_phone: '',
        bill_siretInstaller: '',
        bill_identityOfTheBeneficiary: false,
        bill_invoiceIssueDate: false,
        bill_beneficiarySiret: '',
        bill_operationSheet: false,
        bill_beneficiaryAddress: false,
        bill_beneficiaryName: false,
        bill_beneficiaryFirstName: false,
        bill_beneficiaryPhone: false,
        bill_beneficiaryEmail: false,
        bill_beneficiaryFunction: false,
        bill_lieuDesTravaux: false,
        bill_invoiceNumber: false,
        bill_vatRate: false,
        bill_diametersAndThicknesses: false,
        bill_quoteNo: '',
        bill_comments: '',
    }

    // VALIDE
    const validate = (values) => {
        const errors = {};

        if (estimate_validate !== values.bill_siretInstaller) {
            errors.bill_siretInstaller = 'Saisir le numéro de SIRET INSTALLATEUR valide';
        }

        return errors;
    };
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-11", JSON.stringify(values));
        navigate('/attestation');
    }
    // BACK BUTTON
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-11');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isValid, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid px-4 py-3'>
                        <div className="row ">
                            <p className='bar1-head '> Facture </p>
                            <div className="col text-start">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="bill_installerContactDetails" name="bill_installerContactDetails" checked={values.bill_installerContactDetails} onChange={() => {
                                        setFieldValue('bill_installerContactDetails', !values.bill_installerContactDetails);
                                        setFieldValue('bill_subcontractorContactDetails', false);
                                    }} />
                                    <label className="form-check-label bar1-para" htmlFor="bill_installerContactDetails">Coordonnées Installateur</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input " type="checkbox" id="bill_subcontractorContactDetails" name="bill_subcontractorContactDetails" checked={values.bill_subcontractorContactDetails} onChange={() => {
                                        setFieldValue('bill_subcontractorContactDetails', !values.bill_subcontractorContactDetails);
                                        setFieldValue('bill_installerContactDetails', false);
                                    }} />
                                    <label className="form-check-label bar1-para" htmlFor="bill_subcontractorContactDetails">Coordonnées de sous traitant</label>
                                </div>
                            </div>
                            {values.bill_subcontractorContactDetails == true && (
                                <>
                                    <div className='row mt-3'>
                                        <div className='col-sm-5 input-box1'>
                                            <p className='bar1-para'>Raison sociale*</p>
                                            <input
                                                type="text"
                                                name="bill_socialReason"
                                                value={values.bill_socialReason}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_socialReason && errors.bill_socialReason ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.bill_socialReason && errors.bill_socialReason && <div className="error-message">{errors.bill_socialReason}</div>}
                                        </div>
                                        <div className='col-sm-2 input-box1'>
                                            <p className='bar1-para'>Nom commercial</p>
                                            <input type="text"
                                                name="bill_tradeName"
                                                value={values.bill_tradeName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_tradeName && errors.bill_tradeName ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.bill_tradeName && errors.bill_tradeName && <div className="error-message">{errors.bill_tradeName}</div>}
                                        </div>
                                        <div className='col-sm-5 input-box1'>
                                            <p className='bar1-para bar1-ul'>N Siren*</p>
                                            <input type="number"
                                                name="bill_nSiret"
                                                value={values.bill_nSiret}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_nSiret && errors.bill_nSiret ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.bill_nSiret && errors.bill_nSiret && <div className="error-message">{errors.bill_nSiret}</div>}
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-sm-6 input-box1'>
                                            <p className='bar1-para'>Adresse*</p>
                                            <input type="text"
                                                name="bill_address"
                                                value={values.bill_address}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_address && errors.bill_address ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.bill_address && errors.bill_address && <div className="error-message">{errors.bill_address}</div>}
                                        </div>
                                        <div className='col-sm-2 input-box1'>
                                            <p className='bar1-para'>CP*</p>
                                            <input type="number"
                                                name="bill_cp"
                                                value={values.bill_cp}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_cp && errors.bill_cp ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.bill_cp && errors.bill_cp && <div className="error-message">{errors.bill_cp}</div>}
                                        </div>
                                        <div className='col-sm-4 input-box1'>
                                            <p className='bar1-para'>Ville*</p>
                                            <input type="text"
                                                name="bill_city"
                                                value={values.bill_city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_city && errors.bill_city ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.bill_city && errors.bill_city && <div className="error-message">{errors.bill_city}</div>}
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-sm-3 input-box1'>
                                            <p className='bar1-para'>Telephone*</p>
                                            <input type="text"
                                                name="bill_phone"
                                                value={values.bill_phone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_phone && errors.bill_phone ? 'error' : 'form-control1 w-100 m-0'} />
                                            {touched.bill_phone && errors.bill_phone && <div className="error-message">{errors.bill_phone}</div>}
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>
                        <div className="row my-3">
                            <div className="col text-start">
                                <div className='bar1-para d-flex '> SIRET INSTALLATEUR &nbsp; <a href='https://avis-situation-sirene.insee.fr/' target='_blank' className='bar1-ul'> (vérification si ok avec SIRET installateur dans fiche installateur)</a>
                                    <div className=' ms-3'>
                                        <input
                                            type="text "
                                            name="bill_siretInstaller"
                                            value={values.bill_siretInstaller}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.bill_siretInstaller && errors.bill_siretInstaller ? 'error' : 'form-control1  m-0'} />
                                    </div>

                                </div>
                            </div>
                            {touched.bill_siretInstaller && errors.bill_siretInstaller && <div className="error-message">{errors.bill_siretInstaller}</div>}
                        </div>
                        <div className="row my-3">

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="bill_identityOfTheBeneficiary" name="bill_identityOfTheBeneficiary" checked={values.bill_identityOfTheBeneficiary} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_identityOfTheBeneficiary">Identité du bénéficiaire</label>
                                </div>
                            </div>

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input " type="checkbox" id="bill_invoiceIssueDate" name="bill_invoiceIssueDate" checked={values.bill_invoiceIssueDate} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_invoiceIssueDate">Date d’émission du facture</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <div className='bar1-para d-flex align-items-center pt-2'>SIRET bénéficiaire
                                        <div className=' ms-3'>
                                            <input
                                                type="text "
                                                name="bill_beneficiarySiret"
                                                value={values.bill_beneficiarySiret}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_beneficiarySiret && errors.bill_beneficiarySiret ? 'error' : 'form-control1  m-0'} />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="bill_operationSheet" name="bill_operationSheet" checked={values.bill_operationSheet} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_operationSheet">Mentions exigées par la fiche d’opération</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="bill_beneficiaryAddress" name="bill_beneficiaryAddress" checked={values.bill_beneficiaryAddress} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryAddress">Adresse bénéficiaire</label>
                                </div>
                            </div>

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="bill_beneficiaryName" name="bill_beneficiaryName" checked={values.bill_beneficiaryName} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryName">Nom bénéficiaire</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="bill_beneficiaryPhone" name="bill_beneficiaryPhone" checked={values.bill_beneficiaryPhone} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryPhone">Telephone bénéficiaire</label>
                                </div>
                            </div>

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="bill_beneficiaryFirstName" name="bill_beneficiaryFirstName" checked={values.bill_beneficiaryFirstName} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryFirstName">Prenom bénéficiaire</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="bill_beneficiaryEmail" name="bill_beneficiaryEmail" checked={values.bill_beneficiaryEmail} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryEmail">Email bénéficiaire</label>
                                </div>
                            </div>

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="bill_beneficiaryFunction" name="bill_beneficiaryFunction" checked={values.bill_beneficiaryFunction} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_beneficiaryFunction">Fonction bénéficiaire</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="bill_lieuDesTravaux" name="bill_lieuDesTravaux" checked={values.bill_lieuDesTravaux} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_lieuDesTravaux">Lieu des travaux</label>
                                </div>
                            </div>

                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="bill_invoiceNumber" name="bill_invoiceNumber" checked={values.bill_invoiceNumber} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_invoiceNumber">N° de facture/Bon de commande</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1">
                                <div className="text-start ">
                                    <input className="form-check-input" type="checkbox" id="bill_vatRate" name="bill_vatRate" checked={values.bill_vatRate} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_vatRate">Taux de TVA</label>
                                </div>
                            </div>
                            <div className="col input-box1">
                                <div className="text-start">
                                    <input className="form-check-input" type="checkbox" id="bill_diametersAndThicknesses" name="bill_diametersAndThicknesses" checked={values.bill_diametersAndThicknesses} onChange={handleChange} />
                                    <label className="form-check-label bar1-para ms-2" htmlFor="bill_diametersAndThicknesses">Presence des diametres  et epaisseurs</label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col input-box1 ">
                                <div className="text-start ">
                                    <div className='bar1-para d-flex align-items-center'>N° de Facture
                                        <div className=' ms-3'>
                                            <input
                                                type="text "
                                                name="bill_quoteNo"
                                                value={values.bill_quoteNo}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.bill_quoteNo && errors.bill_quoteNo ? 'error' : 'form-control1  m-0'} />
                                            {touched.bill_quoteNo && errors.bill_quoteNo && <div className="error-message">{errors.bill_quoteNo}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="mb-3 text-start">
                                    <label htmlFor="exampleFormControlTextarea1" className="bar1-para text-start">Commentaires :</label>

                                    <div className=" flex-row align-items-start">
                                        <textarea
                                            name="bill_comments"
                                            value={values.bill_comments}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.bill_comments && errors.bill_comments ? 'error' : 'form-control ml-1 shadow-none textarea'}>
                                        </textarea>
                                        {touched.bill_comments && errors.bill_comments && <div className="error-message">{errors.bill_comments}</div>}
                                    </div></div>
                            </div>
                        </div>

                        <div className='row mt-5 mb-2'>
                            <div className='col text-start'>
                                <button type='button' onClick={handlePrev} className=' btns-color' >Précédent </button>
                            </div>
                            <div className="col text-end">
                                <button type='submit' className='btns-color' >Suivant</button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default Facture

