import React, { useState, useRef } from 'react'
import useFileUpload from 'react-use-file-upload';
import axios from 'axios'
// USE NAVIGATION
import upload from '../../assets/upload 1.svg'
import { useNavigate } from "react-router-dom";
// FORMIK
import { Formik, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { RiDeleteBin6Line, RiEyeLine } from "react-icons/ri";
import { IoDocumentTextSharp } from "react-icons/io5";
import { Spinner } from 'react-bootstrap'
function Oblige() {
    const [isLoading, setIsLoading] = useState(false);
    const Token = localStorage.getItem('Token');
    // TOAST
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);
    const navigate = useNavigate();
    const inputRef = useRef();
    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        handleDragDropEvent,
        setFiles,
        removeFile,
    } = useFileUpload();
    // useFormik
    const initialValues = {
        b2b_b2c: '',
        socialreason: '',
        tradename: '',
        n_siret: '',
        representative_name: '',
        firstname: '',
        his_function: '',
        address: '',
        cp: '',
        city: '',
        email: '',
        n_fixed: '',
        n_mobile: '',
        partnership_contract_start_date: '',
        and_end: '',
        EEC_volume: '',
        total_remaining: '',
        in_progress_remaining: '',
        // files:files,
        contact_attachments: [{
            classic_BtoB_rate: '',
            precarious_BtoB_price: '',
        },
        ],

    }
    const validate = (values) => {
        console.log("validate", values);
        const errors = {};
        if (!values.socialreason) {
            errors.socialreason = 'Raison sociale requise';
        }
        if (!values.tradename) {
            errors.tradename = 'Nom commercial requise';
        }
        if (!values.n_siret) {
            errors.n_siret = 'N Siren requise';
        } else if (values.n_siret && !/^\d+$/.test(values.n_siret)) {
            errors.n_siret = 'Saisir uniquement le numéro';
        }
        if (!values.address) {
            errors.address = 'Adresse requise';
        }
        if (!values.cp) {
            errors.cp = 'CP requise';
        } else if (values.cp && !/^\d+$/.test(values.cp)) {
            errors.cp = 'Saisir uniquement le numéro';
        } else if (values.cp && !/^\d{5}$/i.test(values.cp)) {
            errors.cp = 'Cinq chiffres au minimum uniquement';
        }

        if (!values.city) {
            errors.city = 'Ville requise';
        }
        if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Saisir l'adresse électronique valide";
        }
        if (values.n_fixed && !/^\d+$/.test(values.n_fixed)) {
            errors.n_fixed = 'Saisir uniquement le numéro';
        }
        if (values.n_mobile && !/^\d+$/.test(values.n_mobile)) {
            errors.n_mobile = 'Saisir uniquement le numéro';
        }

        if (!values.partnership_contract_start_date) {
            errors.partnership_contract_start_date = 'Contrat de partenariat date début requise';
        }
        // Check if there are contact_attachments and validate if they exist


        return errors;
    }
    // HANDLE SUBMIT && DATA POST

    // const handleSubmit = async (values, { resetForm }) => {
    //     values.files = files;
    //     try {
    //         await axios.post(`${process.env.REACT_APP_API_URL}/create-oblige`, values,{
    //             headers: {
    //                 authorization: Token,
    //                 'Content-Type': 'multipart/form-data',
    //             }
    //         }).then((res) => {
    //             console.log(res);
    //             if (res.status == 200) {
    //                 console.log('res.message', res?.data?.message);
    //                 navigate('/liste-oblige');
    //                 toastsuccess(res?.data?.message);
    //             }
    //         })
    //         resetForm();
    //     } catch (error) {
    //         toasterror(error.res?.data?.message);
    //         console.error('Error:', error.res?.data?.message);
    //     }
    // }
    const MAX_TOTAL_SIZE_MB = 10;

    const handleSubmit = async (values, { resetForm }) => {
        setIsLoading(true);  // Set loading state to true

        try {
            if (files.length === 0) {
                // Handle submission without files
                const createObligeResponse = await axios.post(`${process.env.REACT_APP_API_URL}/create-oblige`, values, {
                    headers: {
                        authorization: Token,
                    }
                });

                if (createObligeResponse.status === 200) {
                    console.log('res.message', createObligeResponse.data.message);
                    navigate('/liste-oblige');
                    toastsuccess(createObligeResponse.data.message);
                } else {
                    toasterror(createObligeResponse?.data?.message);
                }
            } else {
                // Handle submission with files
                if (files.length > 7) {
                    toasterror('Vous ne pouvez télécharger que 7 fichiers au maximum.');
                    return;
                }

                // Calculate total size of uploaded files
                const totalUploadedSize = files.reduce((total, file) => total + file.size, 0);

                if (totalUploadedSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
                    toasterror('La taille totale des fichiers ne peut pas dépasser 10 Mo.');
                    return;
                }

                const formData = new FormData();
                files.forEach((file) => {
                    formData.append('files', file);
                });

                // Upload files
                const fileUploadResponse = await axios.post(`${process.env.REACT_APP_API_URL}/upload-files`, formData, {
                    headers: {
                        authorization: Token,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (fileUploadResponse.status === 200) {
                    const uploadedFiles = fileUploadResponse.data.files;

                    // Attach uploaded file info to values
                    values.files = uploadedFiles;

                    // Create an "oblige"
                    const createObligeResponse = await axios.post(`${process.env.REACT_APP_API_URL}/create-oblige`, values, {
                        headers: {
                            authorization: Token,
                        }
                    });

                    if (createObligeResponse.status === 200) {
                        console.log('res.message', createObligeResponse.data.message);
                        navigate('/liste-oblige');
                        toastsuccess(createObligeResponse.data.message);
                    } else {
                        toasterror(createObligeResponse?.data?.message);
                    }
                } else {
                    toasterror('File upload failed');
                }
            }
            resetForm();
        } catch (error) {
            console.error('Error:', error.response?.data?.message);
            toasterror(error.response?.data?.message);
        } finally {
            setIsLoading(false);  // Set loading state to false once processing is done
        }
    };

    const handleFileClick = (name) => {
        const file = files.find((file) => file.name === name);

        if (file) {
            const fileUrl = URL.createObjectURL(file);
            window.open(fileUrl, "_blank");
        } else {
            // Handle the case where the file with the given name is not found
        }
    };
    const handleButtonClick = (e) => {
        e.preventDefault();
        inputRef.current.click();
    };
    if (isLoading) {
        return <Spinner animation="border" className='text-success' />;
    };
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid-fluid px-4 py-3'>
                        <p className="bar1-head  mt-4">OBLIGÉ </p>
                        <div className="row">
                            <div className='col-4 input-box1'>
                                <p className='bar1-para '>B2B/B2C</p>
                                <div className=''>
                                    <select name='b2b_b2c'
                                        id='b2b_b2c'
                                        value={values.b2b_b2c}
                                        onChange={handleChange}
                                        className={touched.b2b_b2c && errors.b2b_b2c ? 'error' : 'form-select btn-buttons ps-3'} aria-label="choisir B2b/B2c">
                                        <option defaultValue='choisir B2b/B2c'>choisir B2b/B2c</option>
                                        <option value="B2B">B2B</option>
                                        <option value="B2C">B2C</option>
                                    </select>
                                    {touched.b2b_b2c && errors.b2b_b2c && <div className="error-message">{errors.b2b_b2c}</div>}
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm input-box1'>
                                <p className='bar1-para'>Raison sociale*</p>
                                <input
                                    type="text"
                                    name="socialreason"
                                    value={values.socialreason}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.socialreason && errors.socialreason ? 'error' : 'form-control1 w-100 m-0'} />
                                {touched.socialreason && errors.socialreason && <div className="error-message">{errors.socialreason}</div>}
                            </div>
                            <div className='col-sm input-box1'>
                                <p className='bar1-para'>Nom commercial*</p>
                                <input
                                    name="tradename"
                                    value={values.tradename}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.tradename && errors.tradename ? 'error' : 'form-control1 w-100 m-0'} />
                                {touched.tradename && errors.tradename && <div className="error-message">{errors.tradename}</div>}
                            </div>
                            <div className='col-sm input-box1'>
                                <p className='bar1-para'>N Siren*</p>
                                <input
                                    type='text'
                                    name="n_siret"
                                    value={values.n_siret}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.n_siret && errors.n_siret ? 'error' : 'form-control1 w-100 m-0'} />
                                {touched.n_siret && errors.n_siret && <div className="error-message">{errors.n_siret}</div>}
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm input-box1'>
                                <p className='bar1-para'>Représentant / Nom</p>
                                <input
                                    name="representative_name"
                                    value={values.representative_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.representative_name && errors.representative_name ? 'error' : 'form-control1 w-100 m-0'} />
                            </div>
                            <div className='col-sm input-box1'>
                                <p className='bar1-para'>Prénom</p>
                                <input
                                    type="text"
                                    name="firstname"
                                    value={values.firstname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.firstname && errors.firstname ? 'error' : 'w-100 form-controlz'}
                                    placeholder='Prénom' ></input>
                            </div>
                            <div className='col-sm input-box1'>
                                <p className='bar1-para'>Sa fonction</p>
                                <input
                                    type="text"
                                    name="his_function"
                                    value={values.his_function}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.his_function && errors.his_function ? 'error' : 'form-control1 w-100 m-0'} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm input-box1'>
                                <p className='bar1-para'>Adresse*</p>
                                <input
                                    type="text"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.address && errors.address ? 'error' : 'form-control1 w-100 m-0'} />
                                {touched.address && errors.address && <div className="error-message">{errors.address}</div>}
                            </div>
                            <div className='col-sm input-box1'>
                                <p className='bar1-para'>CP*</p>
                                <input
                                    type='text'
                                    name="cp"
                                    value={values.cp}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.cp && errors.cp ? 'error' : 'form-control1 w-100 m-0'} />
                                {touched.cp && errors.cp && <div className="error-message">{errors.cp}</div>}
                            </div>
                            <div className='col-sm input-box1'>
                                <p className='bar1-para'>Ville*</p>
                                <input
                                    type="text"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.city && errors.city ? 'error' : 'form-control1 w-100 m-0'} />
                                {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-6 input-box1'>
                                <p className='bar1-para'>Email</p>
                                <input
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.email && errors.email ? 'error' : 'form-control1 w-100 m-0'} />
                                {touched.email && errors.email && <div className="error-message">{errors.email}</div>}
                            </div>
                            <div className='col-sm-2 input-box1'>
                                <p className='bar1-para'>N fixe</p>
                                <input
                                    type='text'
                                    name="n_fixed"
                                    value={values.n_fixed}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.n_fixed && errors.n_fixed ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                {touched.n_fixed && errors.n_fixed && <div className="error-message">{errors.n_fixed}</div>}
                            </div>
                            <div className='col-sm-4 input-box1'>
                                <p className='bar1-para'>N mobile</p>
                                <input
                                    type="text"
                                    name="n_mobile"
                                    value={values.n_mobile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.n_mobile && errors.n_mobile ? 'error' : 'form-control1 w-100 m-0'} />
                                {touched.n_mobile && errors.n_mobile && <div className="error-message">{errors.n_mobile}</div>}
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-6'>
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className='bar1-para'>Contrat de partenariat date début*</p>
                                    <input
                                        type="date"
                                        name="partnership_contract_start_date"
                                        value={values.partnership_contract_start_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.partnership_contract_start_date && errors.partnership_contract_start_date ? 'error' : 'form-control1 ms-3 w-100'} />
                                </div>
                                {touched.partnership_contract_start_date && errors.partnership_contract_start_date && <div className="error-message">{errors.partnership_contract_start_date}</div>}
                            </div>
                            <div className="col-2">
                                <span className='bar1-para ms-3'>et fin</span>
                                <input
                                    type="text"
                                    name="and_end"
                                    value={values.and_end}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.and_end && errors.and_end ? 'error' : 'form-control1 ms-3'} />
                                {touched.and_end && errors.and_end && <div className="error-message">{errors.and_end}</div>}
                            </div>
                            <div className='col-4'>
                                <p className='bar1-para'>Volume CEE
                                    <input
                                        type="text"
                                        name="EEC_volume"
                                        value={values.EEC_volume}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.EEC_volume && errors.EEC_volume ? 'error' : 'form-control1 ms-3'} />
                                    <span className='bar1-para ms-3'> GIGAS</span>
                                </p>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-10 input-box1'>
                                <p className='bar1-para'>TOTAL RESTANT
                                    <input
                                        type="text"
                                        name="total_remaining"
                                        value={values.total_remaining}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.total_remaining && errors.total_remaining ? 'error' : 'form-control1 ms-3'} />

                                    <span className='bar1-para ms-3'> EN COURS RESTANT</span>
                                    <input
                                        type="text"
                                        name="in_progress_remaining"
                                        value={values.in_progress_remaining}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.in_progress_remaining && errors.in_progress_remaining ? 'error' : 'form-control1 ms-3'} />
                                </p>
                            </div>
                        </div>
                        <div className="row mt-5 ">
                            <div className="col-lg-3 border-lined">
                                <div className='text-center mt-3'>
                                    <p className='bar1-head text-center '>télécharger des documents</p>
                                    <img src={upload} alt="upload icon" className="text-center ht-100 "></img>
                                    <p className='bar1-para1 mt-3'>Sélectionnez un fichier</p>
                                    <div>
                                        <button
                                            type='button'
                                            className='btn-upload'
                                            onClick={handleButtonClick}
                                        >
                                            Sélectionnez un fichier
                                        </button>
                                        <div
                                            onDragEnter={handleDragDropEvent}
                                            onDragOver={handleDragDropEvent}
                                            onDrop={(e) => {
                                                handleDragDropEvent(e);
                                                setFiles(e, 'a');
                                            }}
                                        >
                                            <input
                                                ref={inputRef}
                                                type="file"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => {
                                                    setFiles(e, 'a');
                                                    inputRef.current.value = null;
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {files?.length > 0 && (
                                        <>
                                            <p className='bar1-para1 mt-3'>Type de fichier : {fileTypes.join(', ')} <br></br>
                                                Taille du fichier : {totalSize}</p>
                                        </>
                                    )}
                                    {files?.length === 0 && (
                                        <p className='bar1-para1 mt-3'>Type de fichier : DOC, PDF, JPEG<br />Taille du fichier : 1 MB</p>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-5 mt-3">
                                <div className="form-container-fluid">
                                    <div >
                                        <ul className='form-data-text'>
                                            {fileNames?.map((name) => (
                                                <li key={name}>
                                                    <span className='bar1-para bar1-ul'>
                                                        <IoDocumentTextSharp /> {name}</span> <span onClick={() => removeFile(name)}><RiDeleteBin6Line /></span>
                                                    <span><button type='button' className='border-0 bg-white' onClick={() => handleFileClick(name)}><RiEyeLine /></button></span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FieldArray name="contact_attachments">
                            {({ push, remove }) => (
                                <div>
                                    {values.contact_attachments.map((_, index) => (
                                        <div key={index} className='row mt-3' >
                                            <div className='col-lg-5 input-box1'>
                                                <p className='bar1-para'>Tarif BtoB classique :
                                                    <input type='text'
                                                        name={`contact_attachments.${index}.classic_BtoB_rate`}
                                                        id={`contact_attachments.${index}.classic_BtoB_rate`}
                                                        value={values.contact_attachments[index].classic_BtoB_rate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.contact_attachments?.[index]?.classic_BtoB_rate && errors?.contact_attachments?.[index]?.classic_BtoB_rate ? 'error' : 'form-control1 ms-3'}
                                                    >
                                                    </input>
                                                    <span className='bar1-para ms-3'> €/GIGA</span> </p>
                                                {/* {touched?.contract[index]?.valuePrice && errors?.contract[index]?.valuePrice && <div className="error-message">{errors?.contract[index]?.valuePrice}</div>} */}
                                            </div>
                                            <div className='col-lg-5 input-box1'>
                                                <p className='bar1-para'>Tarif BtoB précaire :
                                                    <input type='text'
                                                        name={`contact_attachments.${index}.precarious_BtoB_price`}
                                                        id={`contact_attachments.${index}.precarious_BtoB_price`}
                                                        value={values.contact_attachments[index].precarious_BtoB_price}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.contact_attachments?.[index]?.precarious_BtoB_price && errors?.contact_attachments?.[index]?.precarious_BtoB_price ? 'error' : 'form-control1 ms-3'}
                                                    ></input></p>
                                                {/* {touched?.contract[index]?.dateOfDeparture && errors?.contract[index]?.dateOfDeparture && <div className="error-message">{errors?.contract[index]?.dateOfDeparture}</div>} */}
                                            </div>
                                            {index !== 0 && (
                                                <div className='col-lg-2 input-box1'>
                                                    <button type='button' onClick={() => remove(index)} className='btns-color float-btn' >Clear</button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    <div className='row mt-4'>
                                        <div className='col'>
                                            <p className='bar1-head' onClick={() => push({ classic_BtoB_rate: '', precarious_BtoB_price: '' })}>Contrat pièces jointe    +</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </FieldArray>
                        <div className='row mt-4'>
                            <div className="col">
                                <button type='submit' className='btn-on1 h-auto w-auto float-btn mb-2' >Créer </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik >
    )
}

export default Oblige