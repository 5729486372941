import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// FORMIK
import { Formik } from 'formik';
const UpdateBeneficiary = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    // ----------------------------------------------
    // useEffect(() => {
    //     const storedFormValues = sessionStorage.getItem('step-3');

    //     if (storedFormValues) {
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.beneficiary_socialReason = parsedFormValues.beneficiary_socialReason;
    //         initialValues.beneficiary_nomCommercial = parsedFormValues.beneficiary_nomCommercial;
    //         initialValues.beneficiary_nSiret = parsedFormValues.beneficiary_nSiret;
    //         initialValues.beneficiary_representativeName = parsedFormValues.beneficiary_representativeName;
    //         initialValues.beneficiary_firstName = parsedFormValues.beneficiary_firstName;
    //         initialValues.beneficiary_hisFunction = parsedFormValues.beneficiary_hisFunction;
    //         initialValues.beneficiary_address = parsedFormValues.beneficiary_address;
    //         initialValues.beneficiary_cp = parsedFormValues.beneficiary_cp;
    //         initialValues.beneficiary_city = parsedFormValues.beneficiary_city;
    //         initialValues.beneficiary_email = parsedFormValues.beneficiary_email;
    //         initialValues.beneficiary_nFixed = parsedFormValues.beneficiary_nFixed;
    //         initialValues.beneficiary_nMobile = parsedFormValues.beneficiary_nMobile;
    //     }
    // }, []);

    const [initialValues, setInitialValues] = useState(
        {
            beneficiary_socialReason: '',
            beneficiary_nomCommercial: '',
            beneficiary_nSiret: '',
            beneficiary_representativeName: '',
            beneficiary_firstName: '',
            beneficiary_hisFunction: '',
            beneficiary_address: '',
            beneficiary_cp: '',
            beneficiary_city: '',
            beneficiary_email: '',
            beneficiary_nFixed: '',
            beneficiary_nMobile: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            beneficiary_socialReason: MultiUpdate?.multisteptable1?.beneficiary_socialreason || '',
            beneficiary_nomCommercial: MultiUpdate?.multisteptable1?.beneficiary_nomcommercialbeneficiary_nomcommercial || '',
            beneficiary_nSiret: MultiUpdate?.multisteptable1?.beneficiary_nsiret || '',
            beneficiary_representativeName: MultiUpdate?.multisteptable1?.beneficiary_socialreason || '',
            beneficiary_firstName: MultiUpdate?.multisteptable1?.beneficiary_firstname || '',
            beneficiary_hisFunction: MultiUpdate?.multisteptable1?.beneficiary_hisfunction || '',
            beneficiary_address: MultiUpdate?.multisteptable1?.beneficiary_socialreason || '',
            beneficiary_cp: MultiUpdate?.multisteptable1?.beneficiary_cp || '',
            beneficiary_city: MultiUpdate?.multisteptable1?.beneficiary_city || '',
            beneficiary_email: MultiUpdate?.multisteptable1?.beneficiary_email || '',
            beneficiary_nFixed: MultiUpdate?.multisteptable1?.beneficiary_nfixed || '',
            beneficiary_nMobile: MultiUpdate?.multisteptable1?.beneficiary_nMobile || '',
        }));
    }, []);

    // VALIDATE
    const validate = (values) => {
        const errors = {};
        if (!values.beneficiary_socialReason) {
            errors.beneficiary_socialReason = 'Raison sociale requise';
        }

        if (!values.beneficiary_nSiret) {
            errors.beneficiary_nSiret = 'N Siren requise';
        }

        if (!values.beneficiary_address) {
            errors.beneficiary_address = 'Aaddress requise';
        }
        if (!values.beneficiary_cp) {
            errors.beneficiary_cp = 'CP requise';
        }
        if (!values.beneficiary_city) {
            errors.beneficiary_city = 'Ville requise';
        }
        if (!values.beneficiary_email) {
            errors.beneficiary_email = 'Email requise';
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-3", JSON.stringify(values));
        navigate('/update-b2b-chantier');
    }
    // PREVIEW PAGE
    const handlePrev = () => {
        navigate(-1);
    };

    // useEffect(() => {
    //     const storedFormData = sessionStorage.getItem('step-3');
    //     if (storedFormData) {
    //         setStoredData(JSON.parse(storedFormData));
    //     }
    // }, []);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="container-fluid-fluid">
                            <p className='bar1-head mb-4 '>Bénéficiaire</p>
                            <div className='row mt-2'>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para'>Raison sociale*</p>
                                    <input type="text"
                                        name="beneficiary_socialReason"
                                        value={values.beneficiary_socialReason}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_socialReason && errors.beneficiary_socialReason ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.beneficiary_socialReason && errors.beneficiary_socialReason && <div className="error-message">{errors.beneficiary_socialReason}</div>}
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>Nom commercial</p>
                                    <input type="text"
                                        name="beneficiary_nomCommercial"
                                        value={values.beneficiary_nomCommercial}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_nomCommercial && errors.beneficiary_nomCommercial ? 'error' : 'form-control1 w-100 m-0'} />

                                </div>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para'>N Siren*</p>
                                    <input type="number"
                                        name="beneficiary_nSiret"
                                        value={values.beneficiary_nSiret}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_nSiret && errors.beneficiary_nSiret ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.beneficiary_nSiret && errors.beneficiary_nSiret && <div className="error-message">{errors.beneficiary_nSiret}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para'>Représentant / Nom</p>
                                    <input type="text"
                                        name="beneficiary_representativeName"
                                        value={values.beneficiary_representativeName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_representativeName && errors.beneficiary_representativeName ? 'error' : 'form-control1 text-initial w-100 m-0'} placeholder='Nom' />

                                </div>

                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>Prenom</p>
                                    <input type="text"
                                        name="beneficiary_firstName"
                                        value={values.beneficiary_firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_firstName && errors.beneficiary_firstName ? 'error' : ' w-100 form-controlz'} placeholder='Prénom' />

                                </div>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para'>Sa fonction</p>
                                    <input type="text"
                                        name="beneficiary_hisFunction"
                                        value={values.beneficiary_hisFunction}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_hisFunction && errors.beneficiary_hisFunction ? 'error' : 'form-control1 w-100 m-0'} />

                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>Address*</p>
                                    <input type="text"
                                        name="beneficiary_address"
                                        value={values.beneficiary_address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_address && errors.beneficiary_address ? 'error' : ' form-control1 w-100 m-0'} />
                                    {touched.beneficiary_address && errors.beneficiary_address && <div className="error-message">{errors.beneficiary_address}</div>}
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>CP*</p>
                                    <input type="number"
                                        name="beneficiary_cp"
                                        value={values.beneficiary_cp}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_cp && errors.beneficiary_cp ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.beneficiary_cp && errors.beneficiary_cp && <div className="error-message">{errors.beneficiary_cp}</div>}
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>Ville*</p>
                                    <input type="text"
                                        name="beneficiary_city"
                                        value={values.beneficiary_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_city && errors.beneficiary_city ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.beneficiary_city && errors.beneficiary_city && <div className="error-message">{errors.beneficiary_city}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>Email*</p>
                                    <input type="text"
                                        name="beneficiary_email"
                                        value={values.beneficiary_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_email && errors.beneficiary_email ? 'error' : 'form-control1 w-100 m-0'} />
                                    {touched.beneficiary_email && errors.beneficiary_email && <div className="error-message">{errors.beneficiary_email}</div>}
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>N fixe</p>
                                    <input type="number"
                                        name="beneficiary_nFixed"
                                        value={values.beneficiary_nFixed}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_nFixed && errors.beneficiary_nFixed ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>N mobile</p>
                                    <input type="text"
                                        name='beneficiary_nMobile'
                                        value={values.beneficiary_nMobile}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_nMobile && errors.beneficiary_nMobile ? 'error' : 'form-control1 w-100 m-0'} />
                                </div>
                            </div>
                            <div className='row mt-5 mb-2'>
                                <div className='col text-start'>
                                    <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                </div>
                                <div className="col text-end">
                                    <button type='submit' className='btns-color'>Suivant</button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default UpdateBeneficiary