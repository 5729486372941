import React from 'react'
import { MdDeleteSweep } from "react-icons/md";
import { Link } from "react-router-dom"
import axios from 'axios'
import { useEffect, useState, useRef } from 'react';
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from 'react-toastify';
import { Button, Spinner } from 'react-bootstrap'
// XLSX
import * as XLSX from 'xlsx';
import $ from 'jquery'; // Import jQuery
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net/js/jquery.dataTables.min.js';
import 'datatables.net';
function Liste() {
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toastsuccess = (val) => toast.success(val);
  const toasterror = (val) => toast.error(val);
  const Token = localStorage.getItem('Token');
  const tableRef = useRef(null);
  // FIRST METHOD
  useEffect(() => {
      if (!isLoading) {
          const table = $(tableRef.current).DataTable({
              orderCellsTop: true,
              fixedHeader: true,
              language: {
                  search: 'éléments',
              }
          }); return () => {
              table.destroy();
          };
      }
  }, [isLoading]);
 
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getall-installer`, {
        headers:
          { authorization: Token }
      });
      if (response.status === 200) {
        console.log(response?.data);
        setData(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('An error occurred:', error?.response?.data?.message);
    }
  };
   // FETCH DATA
   useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <Spinner animation="border" className='text-success' />;
  };

  const handleDelete = async (id) => {
    console.log('id', id);
    try {
      if (window.confirm("Voulez-vous supprimer ?")) {
        const deleteresponse = await axios.delete(`${process.env.REACT_APP_API_URL}/delete-installer/${id}`,
          {
            headers: { authorization: Token },
          }
        );
        if (deleteresponse?.status === 200) {
          toastsuccess(deleteresponse?.data?.message);
          fetchData();
        };
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  // MULTI DELETE
  const handleCheckboxChange = (id) => {
    console.log('id:', id);
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allItemIds = data?.installersData?.map((item) => item.installer.id);
      setSelectedIds(allItemIds);
    }
    setSelectAll(!selectAll);
  };
  const handleMultiDelete = async () => {
    try {
      if (selectedIds.length > 0 && window.confirm("Voulez-vous supprimer les éléments sélectionnés ?")) {
        const multidelete = await axios.post(
          `${process.env.REACT_APP_API_URL}/multi-delete-installer`,
          { ids: selectedIds },
          {
            headers: { authorization: Token },
          }
        );
        if (multidelete?.status === 200) {
          toastsuccess(multidelete?.data?.message);
          fetchData();
        }
      } else {
        toasterror("Veuillez sélectionner au moins un élément à supprimer");
        // alert("Please select at least one item to delete.");
      }
    } catch (error) {
      toasterror(error?.multidelete?.data?.message);
    }
  };
  // EXPORT INSTALLER
  const exportToExcel = async () => {
    console.log(selectedIds);
    try {
      if (selectedIds.length === 0) {
        toasterror("Veuillez sélectionner au moins un élément à exporter");
        return;
      }

      const getSingleData = await axios.post(
        `${process.env.REACT_APP_API_URL}/get-all-specific-installer`, { ids: selectedIds },
        {
          headers: { authorization: Token },
        }
      );
      if (getSingleData?.status === 200) {
        console.log(getSingleData);
        const selectedData = getSingleData?.data?.installersData;
        console.log(selectedData);

        const formattedData = selectedData?.map(item => {
          return {
            "Raison sociale": item?.installer?.socialreason,
            "Nom commercial": item?.installer?.tradename,
            "N Siren": item?.oblige?.n_siret,
            "Représentant / Nom": item?.installer?.representative_name,
            "Prénom": item?.installer?.first_name,
            "Sa fonction": item?.installer?.his_function,
            "Adresse": item?.installer?.address,
            "CP": item?.installer?.cp,
            "Ville": item?.installer?.city,
            "Email": item?.installer?.email,
            "N fixe": item?.installer?.n_fixed,
            "N mobile": item?.installer?.n_mobile,
            "QUALIFICATION RGE": item?.installer?.egr_qualification,
            "QUALIFICATION RGE Input": item?.installer?.egr_qualification_document,
          };
        });

        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'installercrm-export');

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'winenergyinstaller_data.xlsx';
        a.click();
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log(error?.message);
      toasterror(error?.message);
    }
  };
  return (
    <div className="container-fluid-fluid">

      <div className="row px-4">
        <div className="col d-flex justify-between black-bg ">
          <div className='mt-3 pe-auto'>
            <MdDeleteSweep className='icon_size pe-auto' title='Delete Selected Items' onClick={handleMultiDelete} />
            {/* <HiOutlineExternalLink className='icon_size' /> */}
          </div>
          <div>
            <p className='bar1-head mt-3'>PARTENAIRE/INSTALLATEUR</p>
          </div>
          <div className='mt-2'>
            <button type='button' className='btns-color mt-1'><Link
              to={"/create-install"}
              className='text-blacks text-decoration-none text-white'
            >
              Ajouter un nouveau
            </Link> </button>
          </div>
          {/* <div className='mt-3'>
            <p className='bar1-para'>Afficher
              <span className="text-sizes ms-2 border-1px">50</span></p>
          </div>
          <div className='mt-3'>
            <p className='bar1-para '>éléments
              <input type="text" className=" text-sizes  ms-2" placeholder='Recherche' size="7" ></input></p>
          </div> */}
        </div>
      </div>
      {/* API INTEGRATION */}
      <div className='table-responsive mt-3'>
        <table ref={tableRef} className="table px-4">
          <thead>
            <tr>
              <th scope="col"><input type='checkbox' checked={selectAll} onChange={handleSelectAllChange}></input></th>
              <th scope="col" className='bar1-para'>Raison sociale</th>
              <th scope="col" className='bar1-para'>Nom commercial</th>
              <th scope="col" className='bar1-para'>N Siren</th>
              <th scope="col" className='bar1-para'>Représentant / Nom</th>
              <th scope="col" className='bar1-para'>Sa fonction</th>
              <th scope="col" className='bar1-para'>Edit</th>
              <th scope="col" className='bar1-para'>Delete</th>
            </tr>
          </thead>

          <tbody>
            {data?.installersData?.map((item, index) => (
              <tr key={item?.installer?.id} className='bg-light'>
                <th scope="row"><input type="checkbox" checked={selectedIds.includes(item?.installer?.id)} onChange={() => handleCheckboxChange(item?.installer?.id)} /></th>
                <td className='bar1-para'>{item?.installer?.socialreason}</td>
                <td className='bar1-para'>{item?.installer?.tradename}</td>
                <td className='bar1-para'>{item?.installer?.n_siret}</td>
                <td className='bar1-para'>{item?.installer?.representative_name}</td>
                <td className='bar1-para'>{item?.installer?.his_function}</td>
                <td><Link to={`/update-installer/${item?.installer?.id}`}><FiEdit2 className='bar1-para pe-auto border-0' /></Link></td>
                <td><RiDeleteBin6Line className='bar1-para pe-auto border-0' onClick={() => handleDelete(item?.installer?.id)} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-end mt-4">
        <Button className='btns-color2 links' onClick={exportToExcel}>
          Export
        </Button>
      </div>
    </div>
  )
}

export default Liste