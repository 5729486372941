
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
// FORMIK
import { Formik } from 'formik';
const FinalValidateUpdate = () => {
    const filesstore = JSON.parse(sessionStorage.getItem('userFiles'));
    const files = { files: filesstore };
    console.log(files);
    const B2BFormProcess = [JSON.parse(sessionStorage.getItem('step-2')), JSON.parse(sessionStorage.getItem('step-3')), JSON.parse(sessionStorage.getItem('step-4')), JSON.parse(sessionStorage.getItem('step-5')), JSON.parse(sessionStorage.getItem('step-6')), JSON.parse(sessionStorage.getItem('step-7')), JSON.parse(sessionStorage.getItem('step-8')), JSON.parse(sessionStorage.getItem('step-9')), JSON.parse(sessionStorage.getItem('step-10')), JSON.parse(sessionStorage.getItem('step-11')), JSON.parse(sessionStorage.getItem('step-12')), JSON.parse(sessionStorage.getItem('step-13')), JSON.parse(sessionStorage.getItem('step-14')), files];


    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState('');
    const handleChange = (event) => {
        var datas = { 'final_status': event.target.value };
        console.log('values', event.target.value);
        setSelectedValue(datas);
        sessionStorage.setItem("step-14", JSON.stringify(datas));
    };
    console.log('selectedValue', selectedValue);
    const Token = localStorage.getItem('Token');
    const handleSubmit = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/update-multistep`, { B2BFormProcess: B2BFormProcess },
                {
                    headers: { authorization: Token }
                }).then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        console.log('res.message', response?.data?.message);
                        navigate('/all-datas');
                        sessionStorage.clear();
                    }
                })
        } catch (error) {
            console.log(error.response?.data?.message);
        }

    }
    const handlePrev = () => {
        navigate(-1);
    };
    return (
        <>
            <div className="container-fluid">
                <div className='row mt-5 mb-2 d-flex align-items-center justify-content-center'>
                    <div className='col-sm-5 input-box1'>
                        <p className='bar1-para'>Status*</p>
                        <div className='d-flex'>
                            <select
                                name='selectedValue'
                                id='selectedValue'
                                onChange={handleChange}
                                className='form-select ps-3 btn-buttons'
                                aria-label="Status">
                                <option value=''>-- Choisir un Status --</option>
                                <option value="Valide">Valide</option>
                                <option value="En cours de correction">En cours de correction</option>
                                <option value="Annulé">Annulé</option>
                            </select>
                        </div>
                        <div className='row mt-5 mb-2'>
                            <div className='col text-start'>
                                <button type='button' onClick={handlePrev} className=' btns-color' >Précédent </button>
                            </div>
                            <div className="col text-end">
                                <button type='submit' onClick={handleSubmit} className='btns-color' >Suivant</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FinalValidateUpdate