import React from 'react'
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import axios from 'axios';
import winlogo from "../../assets/win-logo.svg";
const ForgotPassword = () => {
    const initialValues = {
        email: '',
    }
    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'email requise';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        return errors;
    }
    const Token = localStorage.getItem('Token');
    const toastsuccess = (val) => toast.success(val);

    const handleSubmit = async (values, { resetForm }) => {
        console.log({ "values": values });
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, values,
                {
                    headers: { authorization: Token },
                });
            if (response?.status === 200) {
                console.log(response?.data?.message);
                toastsuccess(response?.data?.message);
            }
        } catch (error) {
            toastsuccess(error?.response?.data?.message);
            console.log(error);
        }
        resetForm();
    }
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="container-fluid-fluid">
                            <div className='login-div border-div-green '>
                                    <img
                                        src={winlogo}
                                        width="auto"
                                        height="auto"
                                        className="login-logo"
                                        alt="win-energy Logo"
                                    />
                                </div>
                            <div className='form-page'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-7 col-lg-5 col-sm-12 text-center '>
                                        <h3 className='form-text-1 text-center'>Mot de passe oublié</h3>
                                        <div className="col-lg-12 col-md-12 col-sm-12 text-start">
                                            <Form.Label className='form-text'>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.email && errors.email ? 'error' : 'form-controls'}
                                            />
                                            {touched.email && errors.email && <div className="error-message">{errors.email}</div>}
                                            <button type='submit' className=" mt-4 btn-form" >Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )
                }
            </Formik >
        </>
    )
}

export default ForgotPassword