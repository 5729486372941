import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate, Link } from "react-router-dom";


function Calcult155() {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    // ----------------------------------------------
    useEffect(() => {
        // Retrieve stored form values from local storage
        const storedFormValues = sessionStorage.getItem('step-6');

        if (storedFormValues) {
            // Parse the stored form values and set them as initial values
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.residentieltwoyears = parsedFormValues.residentieltwoyears;
            initialValues.codepostal = parsedFormValues.codepostal;
            initialValues.pipes_insulated = parsedFormValues.pipes_insulated;
            initialValues.singular_points = parsedFormValues.singular_points;
            initialValues.covers_system = parsedFormValues.covers_system;
            initialValues.cover_insulation = parsedFormValues.cover_insulation;
            initialValues.diameter20to65V1 = parsedFormValues.diameter20to65V1;
            initialValues.diameter66to100V1 = parsedFormValues.diameter66to100V1;
            initialValues.diameter101to150V1 = parsedFormValues.diameter101to150V1;
            initialValues.heatexchangerV1 = parsedFormValues.heatexchangerV1;
            initialValues.diameter20to65V2 = parsedFormValues.diameter20to65V2;
            initialValues.diameter66to100V2 = parsedFormValues.diameter66to100V2;
            initialValues.diameter101to150V2 = parsedFormValues.diameter101to150V2;
            initialValues.heatexchangerV2 = parsedFormValues.heatexchangerV2;
            initialValues.buildingindustry = parsedFormValues.buildingindustry;
        }
    }, []);
    const initialValues = {
        buildingindustry: '',
        residentieltwoyears: '',
        codepostal: '',
        pipes_insulated: '',
        singular_points: '',
        covers_system: '',
        cover_insulation: '',
        diameter20to65V1: '',
        diameter66to100V1: '',
        diameter101to150V1: '',
        heatexchangerV1: '',
        diameter20to65V2: '',
        diameter66to100V2: '',
        diameter101to150V2: '',
        heatexchangerV2: '',
    };
    const validate = (values) => {
        const errors = {};

        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
         // Validate the first set of fields
         if (!values.diameter20to65V1 && !values.diameter66to100V1 && !values.diameter101to150V1) {
            errors.diameter101to150V1 = "Veuillez saisir au moins une option";
        }

        // Validate the second set of fields
        if (!values.diameter20to65V2 && !values.diameter66to100V2 && !values.diameter101to150V2) {
            errors.diameter101to150V2 = "Veuillez saisir au moins une option";
        }
        // if (values.diameter20to65V1 < 20 || values.diameter20to65V1 > 65) {
        //     errors.diameter20to65V1 = "Enter a valid number between 20 and 65";
        // }
        // if (values.diameter66to100V1 < 66 || values.diameter66to100V1 > 100) {
        //     errors.diameter66to100V1 = "Enter a valid number between 66 and 100";
        // }
        // if (values.diameter101to150V1 < 101 || values.diameter101to150V1 > 150) {
        //     errors.diameter101to150V1 = "Enter a valid number between 101 and 150";
        // }

        // if (values.diameter20to65V2 < 20 || values.diameter20to65V2 > 65) {
        //     errors.diameter20to65V2 = "Enter a valid number between 20 and 65";
        // }
        // if (values.diameter66to100V2 < 66 || values.diameter66to100V2 > 100) {
        //     errors.diameter66to100V2 = "Enter a valid number between 66 and 100";
        // }
        // if (values.diameter101to150V2 < 101 || values.diameter101to150V2 > 150) {
        //     errors.diameter101to150V2 = "Enter a valid number between 101 and 150";
        // }

        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/estimation-page");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-6');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid px-4 py-3'>
                        <div className='row'>
                            <div className='col'>
                                <p className='bar1-para '>BAT-TH-155 : Isolation de points singuliers d’un réseau</p>
                                <p className="bar1-head  mt-4">Opération dans un bâtiment TERTIAIRE existant depuis plus de 2 ans ?  </p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Oui'
                                            name="residentieltwoyears"
                                            id="residentieltwoyears"
                                            onChange={handleChange} 
                                            checked={values.residentieltwoyears === "Oui"}/>
                                        <span className='span-bar bar1-para'>
                                            Oui
                                        </span>
                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Non'
                                            name="residentieltwoyears"
                                            id="residentieltwoyears"
                                            onChange={handleChange} 
                                            checked={values.residentieltwoyears === "Non"}/>
                                        <span className='span-bar bar1-para '>
                                            Non
                                        </span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>

                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-sm-12">
                                        <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <input
                                            type="number"
                                            name='codepostal'
                                            id='codepostal'
                                            value={values.codepostal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                    </div>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">Secteur d'activité du bâtiment ? </p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Bureaux"
                                            onChange={handleChange} 
                                            checked={values.buildingindustry === "Bureaux"}/>
                                        <span className='span-bar bar1-para'>
                                            Bureaux
                                        </span></label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Enseignement"
                                            onChange={handleChange} 
                                            checked={values.buildingindustry === "Enseignement"}/>
                                        <span className='span-bar bar1-para '>
                                            Enseignement
                                        </span>
                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Commerces"
                                            onChange={handleChange} 
                                            checked={values.buildingindustry === "Commerces"}/>
                                        <span className='span-bar bar1-para '>
                                            Commerces
                                        </span>
                                    </label>  </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Hôtellerie - Restauration"
                                            onChange={handleChange} 
                                            checked={values.buildingindustry === "Hôtellerie - Restauration"}/>
                                        <span className='span-bar bar1-para '>
                                            Hôtellerie / Restauration
                                        </span>
                                    </label>

                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Santé"
                                            onChange={handleChange} 
                                            checked={values.buildingindustry === "Santé"}/>
                                        <span className='span-bar bar1-para '>
                                            Santé
                                        </span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">Les canalisations existantes sont déjà isolées ?</p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Oui'
                                            name="pipes_insulated"
                                            id="pipes_insulated"
                                            onChange={handleChange}
                                            checked={values.pipes_insulated === "Oui"} />
                                        <span className='span-bar bar1-para'>
                                            Oui
                                        </span>

                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Non'
                                            name="pipes_insulated"
                                            id="pipes_insulated"
                                            onChange={handleChange}
                                            checked={values.pipes_insulated === "Non"} />
                                        <span className='span-bar bar1-para '>Non</span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">Les points singuliers à isoler sont tous situés dans une chaufferie ou dans une sous station ? </p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='OUI'
                                            name="singular_points"
                                            id="singular_points"
                                            onChange={handleChange}
                                            checked={values.singular_points === "OUI"} />
                                        <span className='span-bar bar1-para'>
                                            OUI
                                        </span></label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='NON'
                                            name="singular_points"
                                            id="singular_points"
                                            onChange={handleChange}
                                            checked={values.singular_points === "NON"} />
                                        <span className='span-bar bar1-para '>
                                            NON
                                        </span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">Les housses sont souples, démontables et équipées d’un système de fermeture ?</p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='OUI'
                                            name="covers_system"
                                            id="covers_system"
                                            onChange={handleChange}
                                            checked={values.covers_system === "OUI"} />
                                        <span className='span-bar bar1-para'>
                                            OUI
                                        </span> </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='NON'
                                            name="covers_system"
                                            id="covers_system"
                                            onChange={handleChange}
                                            checked={values.covers_system === "NON"} />
                                        <span className='span-bar bar1-para '>
                                            NON
                                        </span>
                                    </label> </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">La housse est constituée d’un isolant à base laine de verre ou de roche ?</p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='OUI'
                                            name="cover_insulation"
                                            id="cover_insulation"
                                            onChange={handleChange}
                                            checked={values.cover_insulation === "OUI"} />
                                        <span className='span-bar bar1-para'>
                                            OUI
                                        </span></label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='NON'
                                            name="cover_insulation"
                                            id="cover_insulation"
                                            onChange={handleChange}
                                            checked={values.cover_insulation === "NON"} />
                                        <span className='span-bar bar1-para '>
                                            NON
                                        </span>
                                    </label></div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <div className='button-1 d-flex'>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    <h3 className="bar1-head ms-2 mt-3 ">Pour un réseau de fluide d’une température nominale entre 50° et 120° Combien de points singuliers sont à isoler ? </h3> </div>

                                <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 20 mm et 65 mm (Qt) :
                                    <input type='number'
                                        name='diameter20to65V1'
                                        id='diameter20to65V1'
                                        value={values.diameter20to65V1}
                                        onChange={handleChange}
                                        className="form-control1"></input></p>
                                {/* {touched.diameter20to65V1 && errors.diameter20to65V1 && <div className="error-message">{errors.diameter20to65V1}</div>} */}

                                <hr />
                                <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 66 mm et 100 mm (Qt) : <input type='number'
                                    name='diameter66to100V1'
                                    id='diameter66to100V1'
                                    value={values.diameter66to100V1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-control1" ></input>

                                    {/* {touched.diameter66to100V1 && errors.diameter66to100V1 && <div className="error-message">{errors.diameter66to100V1}</div>} */}
                                </p><hr />
                                <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 101 mm et 150 mm (Qt) : <input type='number'
                                    name='diameter101to150V1'
                                    id='diameter101to150V1'
                                    value={values.diameter101to150V1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-control1" ></input>

                                    {touched.diameter101to150V1 && errors.diameter101to150V1 && <div className="error-message">{errors.diameter101to150V1}</div>}
                                </p><hr />
                                <p className='bar1-head mt-3'>Sur échangeur à plaque (Qt) :
                                    <input
                                        type="number"
                                        name='heatexchangerV1'
                                        id='heatexchangerV1'
                                        value={values.heatexchangerV1}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-control1" ></input></p><hr />
                                <div className='button-1 d-flex'>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    <h3 className="bar1-head ms-2 mt-3 ">Pour un réseau de fluide d’une température nominale supérieure à 120°
                                        Combien de points singuliers sont à isoler ?</h3> </div>

                                <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 20 mm et 65 mm (Qt) :
                                    <input type="number"
                                        name='diameter20to65V2'
                                        id='diameter20to65V2'
                                        value={values.diameter20to65V2}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-control1" ></input>
                                    {/* {touched.diameter20to65V2 && errors.diameter20to65V2 && <div className="error-message">{errors.diameter20to65V2}</div>} */}

                                </p><hr />
                                <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 66 mm et 100 mm (Qt) :
                                    <input type="number"
                                        name='diameter66to100V2'
                                        id='diameter66to100V2'
                                        value={values.diameter66to100V2}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-control1" ></input>
                                    {/* {touched.diameter66to100V2 && errors.diameter66to100V2 && <div className="error-message">{errors.diameter66to100V2}</div>} */}

                                </p><hr />
                                <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 101 mm et 150 mm (Qt) :
                                    <input type="number"
                                        name='diameter101to150V2'
                                        id='diameter101to150V2'
                                        value={values.diameter101to150V2}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-control1" ></input>
                                    {touched.diameter101to150V2 && errors.diameter101to150V2 && <div className="error-message">{errors.diameter101to150V2}</div>}

                                </p><hr />
                                <p className='bar1-head mt-3'>Sur échangeur à plaque (Qt) :
                                    <input type="number"
                                        name='heatexchangerV2'
                                        id='heatexchangerV2'
                                        value={values.heatexchangerV2}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-control1" ></input></p><hr />
                                <div className='row mt-5 mb-2'>
                                    <div className='col text-start'>
                                        <button type='button' onClick={handlePrev} className=' btns-color'>Précédent</button>
                                    </div>
                                    <div className="col text-end">
                                        <button type='submit' className='btns-color'>Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default Calcult155;