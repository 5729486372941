import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
function Calcult116() {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    // ----------------------------------------------
    useEffect(() => {
        // Retrieve stored form values from local storage
        const storedFormValues = sessionStorage.getItem('step-6');

        if (storedFormValues) {
            // Parse the stored form values and set them as initial values
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.signature = parsedFormValues.signature;
            initialValues.tertiarysector = parsedFormValues.tertiarysector;
            initialValues.codepostal = parsedFormValues.codepostal;
            initialValues.bmssystem = parsedFormValues.bmssystem;
            initialValues.operationconcern = parsedFormValues.operationconcern;
            initialValues.newBMSsystem = parsedFormValues.newBMSsystem;
            initialValues.buildingindustry = parsedFormValues.buildingindustry;
            initialValues.managedBMSsystem = parsedFormValues.managedBMSsystem;
            initialValues.surface_area = parsedFormValues.surface_area;
            initialValues.premiumamount = parsedFormValues.premiumamount;
        }
    }, []);
    const initialValues = {
        signature: '',
        tertiarysector: '',
        codepostal: '',
        bmssystem: '',
        operationconcern: '',
        newBMSsystem: '',
        buildingindustry: '',
        managedBMSsystem: '',
        surface_area: '',
        premiumamount: '',
    };
    const validate = (values) => {
        const errors = {};

        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.managedBMSsystem) {
            errors.managedBMSsystem = "Code postal de l'adresse des travaux requise";
        }
        if (!values.surface_area) {
            errors.surface_area = "Surface totale isolée (en m²) requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/estimation-page");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-6');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid px-4 py-3'>
                        <div className='row'>
                            <div className='col'>
                                <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Système de gestion technique du bâtiment tertiaire</p>
                                <p className='bar1-head mt-3'>Date du devis de signature :
                                    <input type="date"
                                        name="signature"
                                        id="signature"
                                        value={values.signature}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-control1" ></input></p><hr></hr>
                                <p className="bar1-head  mt-4">Bâtiment tertiaire existant depuis plus de 2 ans ?</p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="tertiarysector"
                                            id="tertiarysector"
                                            value="Oui"
                                            onChange={handleChange}
                                            checked={values.tertiarysector === "Oui"} />
                                        <span className='span-bar bar1-para'>
                                            Oui
                                        </span> </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="tertiarysector"
                                            id="tertiarysector"
                                            value="Non"
                                            onChange={handleChange}
                                            checked={values.tertiarysector === "Non"} />
                                        <span className='span-bar bar1-para '>
                                            Non
                                        </span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>

                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-sm-12">
                                        <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <input
                                            type="number"
                                            name='codepostal'
                                            id='codepostal'
                                            value={values.codepostal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                    </div>
                                </div>
                                <div className='button-1'>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">S’agit-il d’un simple raccordement ou extension d’un bâtiment à un système de GTB existant ? </p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Oui'
                                            name="bmssystem"
                                            id="bmssystem"
                                            onChange={handleChange}
                                            checked={values.bmssystem === "Oui"} />
                                        <span className='span-bar bar1-para'>
                                            Oui
                                        </span>
                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='Non'
                                            name="bmssystem"
                                            id="bmssystem"
                                            onChange={handleChange}
                                            checked={values.bmssystem === "Non"} />
                                        <span className='span-bar bar1-para '>
                                            Non
                                        </span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">L’opération concerne ? </p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='BMS system'
                                            name="operationconcern"
                                            id="operationconcern"
                                            onChange={handleChange}
                                            checked={values.operationconcern === "BMS system"} />
                                        <span className='span-bar bar1-para'> L’achat d’un système neuf de GTB  </span>
                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            value='2 years of BMS'
                                            name="operationconcern"
                                            id="operationconcern"
                                            onChange={handleChange}
                                            checked={values.operationconcern === "2 years of BMS"} />
                                        <span className='span-bar bar1-para '>
                                            L’amélioration d’un système existant de + de 2 ans de GTB </span>
                                    </label>
                                </div>

                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <div>
                                    <p className="bar1-head  mt-4">Le nouveau système de GTB est ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value='De classe A'
                                                name="newBMSsystem"
                                                id="newBMSsystem"
                                                onChange={handleChange}
                                                checked={values.newBMSsystem === "De classe A"} />
                                            <span className='span-bar bar1-para'>De classe A </span></label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value='De classe B'
                                                name="newBMSsystem"
                                                id="newBMSsystem"
                                                onChange={handleChange}
                                                checked={values.newBMSsystem === "De classe B"} />
                                            <span className='span-bar bar1-para '> De classe B</span>
                                        </label>

                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value='Autre'
                                                name="newBMSsystem"
                                                id="newBMSsystem"
                                                onChange={handleChange}
                                                checked={values.newBMSsystem === "Autre"} />
                                            <span className='span-bar bar1-para'>Autre </span> </label>
                                    </div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr></div>
                                <div>
                                    <p className="bar1-head  mt-4">Secteur d'activité du bâtiment ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Bureaux"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Bureaux"} />
                                            <span className='span-bar bar1-para'>
                                                Bureaux
                                            </span></label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Enseignement"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Enseignement"} />
                                            <span className='span-bar bar1-para '>
                                                Enseignement
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Commerces"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Commerces"} />
                                            <span className='span-bar bar1-para '>
                                                Commerces
                                            </span>
                                        </label>  </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Hôtellerie - Restauration"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Hôtellerie - Restauration"} />
                                            <span className='span-bar bar1-para '>
                                                Hôtellerie / Restauration
                                            </span>
                                        </label>

                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Santé"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Santé"} />
                                            <span className='span-bar bar1-para '>
                                                Santé
                                            </span>
                                        </label>

                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingindustry"
                                                id="buildingindustry"
                                                value="Autres secteurs tertiaires"
                                                onChange={handleChange}
                                                checked={values.buildingindustry === "Autres secteurs tertiaires"} />
                                            <span className='span-bar bar1-para '> Autres secteurs tertiaires </span>
                                        </label>
                                    </div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>
                                    <p className='bar1-head mt-3'>Usages et surfaces gérés par le système GTB :</p>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>
                                </div>

                                <div className='bar1-content mt-4 p-2'>
                                    <div className="row row-cols-lg-2">
                                        <div className="col p-3"><span className='bar1-head '>Usage géré par le système GTB* :</span></div>
                                        <div className="col p-3">
                                            <select
                                                name='managedBMSsystem'
                                                id='managedBMSsystem'
                                                value={values.managedBMSsystem}
                                                onChange={handleChange}
                                                className={touched.managedBMSsystem && errors.managedBMSsystem ? 'error' : 'form-select w-100 btn-buttons width-of-select '} aria-label="Sélectionnez un usage">
                                                <option selected >-- Sélectionnez un usage --</option>
                                                <option value="Chauffage">Chauffage</option>
                                                <option value="ECS">Eau chaude sanitaire</option>
                                                <option value="Refroidissement Climatisation">Refroidissement / climatisation</option>
                                                <option value="Eclairage">Eclairage</option>
                                                <option value="Auxiliaire">Auxiliaires</option>
                                            </select>
                                            {touched.managedBMSsystem && errors.managedBMSsystem && <div className="error-message d-block">{errors.managedBMSsystem}</div>}
                                        </div>
                                        <div className="col p-3"><span className='bar1-head '>Surface pour cet usage (en m²)* :</span></div>
                                        <div className="col p-3"> <input
                                            type="number"
                                            name="surface_area"
                                            id="surface_area"
                                            value={values.surface_area}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1 w-100'} ></input>
                                            {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}</div>
                                    </div>
                                </div>
                                <div className='text-start mt-3'>
                                    <button type='button' className='btns-color mt-1'  >
                                        Ajouter un nouveau
                                    </button>
                                </div>

                                <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                <div className='text-start'>
                                    <label>
                                        <input
                                            name="premiumamount"
                                            id="premiumamount"
                                            value={values.premiumamount}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="input-box" placeholder='3' ></input>
                                        <span className='span-bar bar1-para' > Euros du Méga / Prix du kilowater </span>
                                    </label>

                                </div><hr></hr>
                                <div className='row mt-5 mb-2'>
                                    <div className='col text-start'>
                                        <button type='button' onClick={handlePrev} className=' btns-color' >Précédent </button>
                                    </div>
                                    <div className="col text-end">
                                        <button type='submit' className='btns-color' >Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )
            }
        </Formik >
    )
}

export default Calcult116;