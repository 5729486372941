import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
const OperationB2B = (props) => {
    const Token = localStorage.getItem('Token');
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);

    //    MULTIUPDATE DATAS
    const MultiUpdate = props.MultiUpdate;
    const [initialValues, setInitialValues] = useState({
        id: '',
        b2boperations: MultiUpdate?.multisteptable1?.b2boperations,
        documents: MultiUpdate?.multisteptable1?.documents,
        // 1
        beneficiaryOperation: '',
        buildingmorethan2years: '',
        codepostal: '',
        thermalinsulation: '',
        heatedareaandanunheated: '',
        heatedareaandanunheated2: '',
        surface_area: '',
        order: '',
        departmental_precariousness: '',
        total_homes_affectedv1: '',
        approved_housing: '',
        total_homes_affectedv2: '',
        empty_households: '',
        classified_precarious: '',
        highly_precarious: '',
        without_proof: '',
        district_city_policy: '',
        // 2
        dateofcommitment: '',
        dateofproof: '',
        invoicereference: '',
        addressofwork: '',
        additionaladdress: '',
        luminaires: '',
        lighting: '',
        brand: '',
        reference: '',
        model: '',
        types: '',
        noofnewlights: '',
        brandv2: '',
        modelv2: '',
        typev2: '',
        noofnewlightsv2: '',
        city: '',
        // 3
        heatingEnergy: '',
        windowsorpatiodoors: '',
        roofwindows: '',
        // 4
        residentieltwoyears: '',
        pipes_insulated: '',
        singular_points: '',
        covers_system: '',
        cover_insulation: '',
        diameter20to65V1: '',
        diameter66to100V1: '',
        diameter101to150V1: '',
        heatexchangerV1: '',
        diameter20to65V2: '',
        diameter66to100V2: '',
        diameter101to150V2: '',
        heatexchangerV2: '',
        // 5
        tertiarysector: '',
        typeofpose: '',
        buildingindustry: '',
        startofwork: '',
        twelvemonths: '',
        // 6
        tertiaryBuilding: '',
        newradiators: '',
        boiler: '',
        premiumamount: '',
        // 7
        signature: '',
        bmssystem: '',
        operationconcern: '',
        newBMSsystem: '',
        managedBMSsystem: '',
        // 8
        classZshowerheads: '',
        classZZshowerheads: '',
        classZunregulated: '',
        classZZunregulated: '',
        kilowater: '',
        // 9
        replaced_after_2018: '',
        oldinsulation: '',
        hydraulicnetwork: '',
        primeamount: '',
        // 10
        residential_2years_old: '',
        installation: '',
        hydraulicheating: '',
        // 11
        commitmentdate: '',
        legalpersons: '',
        date_of_commitment: '',
        type_installation: '',
        dwellings_served: '',
        heatpump_surface: '',
        energy_class: '',
        exchanger_thermal: '',
        installation_type: '',
        static_exchanger: '',
        box_brand: '',
        casing_reference: '',
        valve_brand: '',
        unit_reference: '',
        // 12
        individual_house: '',
        lowtemperature: '',
        cop: '',
        nom: '',
        prénom: '',
        b2c_social_reason: '',
        siret_number: '',
        // 13
        work_name: '',
        housing_type: '',
        // 14
        cep_initial: '',
        cep_projet: '',
        cef_initial: '',
        cef_projet: '',
        energy_gain: '',
        greenhouse: '',
        b2c_siren_number: '',
        date_of_energy: '',
        energy_study_reference: '',
        software_name: '',
        software_version: '',
        representative_name: '',
        b2c_first_name: '',
        field_work: '',
        certification_reference: '',
        b2c_date: '',
        // 15
        residentialBuilding: '',
        noofApartment: '',
    });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            id: MultiUpdate?.multisteptable1?.id,
            b2boperations: MultiUpdate?.multisteptable1?.b2boperations || '',
            documents: MultiUpdate?.multisteptable1?.documents || '',
            // 
            roof: MultiUpdate?.multisteptable2?.roof || '',
            buildingmorethan2years: MultiUpdate?.multisteptable2?.buildingmorethan2years || '',
            codepostal: MultiUpdate?.multisteptable2?.codepostal || '',
            thermalinsulation: MultiUpdate?.multisteptable2?.thermalinsulation || '',
            heatedareaandanunheated: MultiUpdate?.multisteptable2?.heatedareaandanunheated || '',
            heatedareaandanunheated2: MultiUpdate?.multisteptable2?.heatedareaandanunheated2 || '',
            surface_area: MultiUpdate?.multisteptable2?.surface_area || '',
            order: MultiUpdate?.multisteptable2?.order || '',
            // 
            dateofcommitment: MultiUpdate?.multisteptable2?.dateofcommitment || '',
            dateofproof: MultiUpdate?.multisteptable2?.dateofproof || '',
            invoicereference: MultiUpdate?.multisteptable2?.invoicereference || '',
            addressofwork: MultiUpdate?.multisteptable2?.addressofwork || '',
            additionaladdress: MultiUpdate?.multisteptable2?.additionaladdress || '',
            luminaires: MultiUpdate?.multisteptable2?.luminaires || '',
            lighting: MultiUpdate?.multisteptable2?.lighting || '',
            brand: MultiUpdate?.multisteptable2?.brand || '',
            reference: MultiUpdate?.multisteptable2?.reference || '',
            model: MultiUpdate?.multisteptable2?.model || '',
            types: MultiUpdate?.multisteptable2?.types || '',
            noofnewlights: MultiUpdate?.multisteptable2?.noofnewlights || '',
            brandv2: MultiUpdate?.multisteptable2?.brandv2 || '',
            modelv2: MultiUpdate?.multisteptable2?.modelv2 || '',
            typev2: MultiUpdate?.multisteptable2?.typev2 || '',
            noofnewlightsv2: MultiUpdate?.multisteptable2?.noofnewlightsv2 || '',
            city: MultiUpdate?.multisteptable2?.city || '',
            // 
            tertiarysector: MultiUpdate?.multisteptable2?.tertiarysector || '',
            heatingEnergy: MultiUpdate?.multisteptable2?.heatingEnergy || '',
            buildingindustry: MultiUpdate?.multisteptable2?.buildingindustry || '',
            // 
            startofwork: MultiUpdate?.multisteptable2?.startofwork || '',
            twelvemonths: MultiUpdate?.multisteptable2?.twelvemonths || '',
            // 
            tertiaryBuilding: MultiUpdate?.multisteptable2?.tertiaryBuilding || '',
            newradiators: MultiUpdate?.multisteptable2?.newradiators || '',
            boiler: MultiUpdate?.multisteptable2?.boiler || '',
            premiumamount: MultiUpdate?.multisteptable2?.premiumamount || '',
            // 
            signature: MultiUpdate?.multisteptable2?.signature || '',
            bmssystem: MultiUpdate?.multisteptable2?.bmssystem || '',
            operationconcern: MultiUpdate?.multisteptable2?.operationconcern || '',
            newBMSsystem: MultiUpdate?.multisteptable2?.newBMSsystem || '',
            managedBMSsystem: MultiUpdate?.multisteptable2?.managedBMSsystem || '',
            // 
            classZshowerheads: MultiUpdate?.multisteptable2?.classZshowerheads || '',
            classZZshowerheads: MultiUpdate?.multisteptable2?.classZZshowerheads || '',
            classZunregulated: MultiUpdate?.multisteptable2?.classZunregulated || '',
            classZZunregulated: MultiUpdate?.multisteptable2?.classZZunregulated || '',
            kilowater: MultiUpdate?.multisteptable2?.kilowater || '',
            // 
            replaced_after_2018: MultiUpdate?.multisteptable2?.replaced_after_2018 || '',
            oldinsulation: MultiUpdate?.multisteptable2?.oldinsulation || '',
            hydraulicnetwork: MultiUpdate?.multisteptable2?.hydraulicnetwork || '',
            primeamount: MultiUpdate?.multisteptable2?.primeamount || '',
            // 
            residentieltwoyears: MultiUpdate?.multisteptable2?.residentieltwoyears || '',
            pipes_insulated: MultiUpdate?.multisteptable2?.pipes_insulated || '',
            singular_points: MultiUpdate?.multisteptable2?.singular_points || '',
            covers_system: MultiUpdate?.multisteptable2?.covers_system || '',
            cover_insulation: MultiUpdate?.multisteptable2?.cover_insulation || '',
            diameter20to65V1: MultiUpdate?.multisteptable2?.diameter20to65V1 || '',
            diameter66to100V1: MultiUpdate?.multisteptable2?.diameter66to100V1 || '',
            diameter101to150V1: MultiUpdate?.multisteptable2?.diameter101to150V1 || '',
            heatexchangerV1: MultiUpdate?.multisteptable2?.heatexchangerV1 || '',
            diameter20to65V2: MultiUpdate?.multisteptable2?.diameter20to65V2 || '',
            diameter66to100V2: MultiUpdate?.multisteptable2?.diameter66to100V2 || '',
            diameter101to150V2: MultiUpdate?.multisteptable2?.diameter101to150V2 || '',
            heatexchangerV2: MultiUpdate?.multisteptable2?.heatexchangerV2 || '',
            // 
            beneficiaryOperation: MultiUpdate?.multisteptable2?.beneficiaryOperation || '',
            residential_2years_old: MultiUpdate?.multisteptable2?.residential_2years_old || '',
            installation: MultiUpdate?.multisteptable2?.installation || '',
            hydraulicheating: MultiUpdate?.multisteptable2?.hydraulicheating || '',
            // 
            windowsorpatiodoors: MultiUpdate?.multisteptable2?.windowsorpatiodoors || '',
            roofwindows: MultiUpdate?.multisteptable2?.roofwindows || '',
            // 
            commitmentdate: MultiUpdate?.multisteptable2?.commitmentdate || '',
            legalpersons: MultiUpdate?.multisteptable2?.legalpersons || '',
            date_of_commitment: MultiUpdate?.multisteptable2?.date_of_commitment || '',
            housing_type: MultiUpdate?.multisteptable2?.housing_type || '',
            heatpump_surface: MultiUpdate?.multisteptable2?.heatpump_surface || '',
            seasonal_energy: MultiUpdate?.multisteptable2?.seasonal_energy || '',
            sizing_note: MultiUpdate?.multisteptable2?.sizing_note || '',
            heatpump_type: MultiUpdate?.multisteptable2?.heatpump_type || '',
            nom: MultiUpdate?.multisteptable2?.nom || '',
            prénom: MultiUpdate?.multisteptable2?.prénom || '',
            b2c_social_reason: MultiUpdate?.multisteptable2?.social_reason || '',
            siret_number: MultiUpdate?.multisteptable2?.siret_number || '',
            // 
            individual_house: MultiUpdate?.multisteptable2?.individual_house || '',
            sizingnote: MultiUpdate?.multisteptable2?.sizingnote || '',
            wood: MultiUpdate?.multisteptable2?.wood || '',
            rated_heat: MultiUpdate?.multisteptable2?.rated_heat || '',
            regulator_class: MultiUpdate?.multisteptable2?.regulator_class || '',
            boilers_225: MultiUpdate?.multisteptable2?.boilers_225 || '',
            buffer_tank: MultiUpdate?.multisteptable2?.buffer_tank || '',
            boiler_operated: MultiUpdate?.multisteptable2?.boiler_operated || '',
            boiler_7label: MultiUpdate?.multisteptable2?.boiler_7label || '',
            seasonal_particulate: MultiUpdate?.multisteptable2?.seasonal_particulate || '',
            seasonal_carbon: MultiUpdate?.multisteptable2?.seasonal_carbon || '',
            nitrogen_oxides: MultiUpdate?.multisteptable2?.nitrogen_oxides || '',
            // 
            type_installation: MultiUpdate?.multisteptable2?.type_installation || '',
            dwellings_served: MultiUpdate?.multisteptable2?.dwellings_served || '',
            energy_class: MultiUpdate?.multisteptable2?.energy_class || '',
            exchanger_thermal: MultiUpdate?.multisteptable2?.exchanger_thermal || '',
            installation_type: MultiUpdate?.multisteptable2?.installation_type || '',
            static_exchanger: MultiUpdate?.multisteptable2?.static_exchanger || '',
            box_brand: MultiUpdate?.multisteptable2?.box_brand || '',
            casing_reference: MultiUpdate?.multisteptable2?.casing_reference || '',
            valve_brand: MultiUpdate?.multisteptable2?.valve_brand || '',
            unit_reference: MultiUpdate?.multisteptable2?.unit_reference || '',
            // 
            lowtemperature: MultiUpdate?.multisteptable2?.lowtemperature || '',
            cop: MultiUpdate?.multisteptable2?.cop || '',
            // 
            work_name: MultiUpdate?.multisteptable2?.work_name || '',
            // 
            cep_initial: MultiUpdate?.multisteptable2?.cep_initial || '',
            cep_projet: MultiUpdate?.multisteptable2?.cep_projet || '',
            cef_initial: MultiUpdate?.multisteptable2?.cef_initial || '',
            cef_projet: MultiUpdate?.multisteptable2?.cef_projet || '',
            energy_gain: MultiUpdate?.multisteptable2?.energy_gain || '',
            greenhouse: MultiUpdate?.multisteptable2?.greenhouse || '',
            b2c_siren_number: MultiUpdate?.multisteptable2?.siren_number || '',
            date_of_energy: MultiUpdate?.multisteptable2?.date_of_energy || '',
            energy_study_reference: MultiUpdate?.multisteptable2?.energy_study_reference || '',
            software_name: MultiUpdate?.multisteptable2?.software_name || '',
            software_version: MultiUpdate?.multisteptable2?.software_version || '',
            representative_name: MultiUpdate?.multisteptable2?.representative_name || '',
            b2c_first_name: MultiUpdate?.multisteptable2?.first_name || '',
            field_work: MultiUpdate?.multisteptable2?.field_work || '',
            certification_reference: MultiUpdate?.multisteptable2?.certification_reference || '',
            b2c_date: MultiUpdate?.multisteptable2?.b2c_date || '',
            // 
            typeofpose: MultiUpdate?.multisteptable2?.typeofpose || '',
        }));
    }, [MultiUpdate]);
    const same_address = MultiUpdate?.multisteptable1?.beneficiary_address;
    console.log('same_address', same_address);
    const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
    // VALIDATE
    const validate = (values) => {
        const errors = {};
        return errors;
    }
    const handleSubmit = async (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/update-multistep`, values,
                {
                    headers: { authorization: Token }
                }).then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        console.log('res.message', response?.data?.message);
                        toastsuccess(response?.data?.message);
                    }
                })
        } catch (error) {
            console.log(error.response?.data?.message);
            toasterror(error.response?.data?.message);
        }
    }
    const handleCheckboxChange = (e, setFieldValue) => {

        const isChecked = e.target.checked;
        setIsSameAddressChecked(isChecked);
        if (isChecked) {
            setFieldValue('addressofwork', same_address);
        } else {
            setFieldValue('addressofwork', initialValues.addressofwork);
        }
    };
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="container-fluid pb-4">
                            <div className='row mt-3 pb-4'>
                                <div className="save_button_container d-flex justify-content-between align-items-center">
                                    <p className="bar1-head  mt-4">Operation infos </p> <button type='submit' className='btns-color'>Sauvegarder</button>
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para '>Operation</p>
                                    <div className='d-flex'>
                                        <select
                                            name='b2boperations'
                                            id='b2boperations'
                                            value={values.b2boperations}
                                            onChange={handleChange}
                                            className="form-select ps-3 btn-buttons" aria-label="Operation">
                                            <option defaultValue>-- Choisir un operation --</option>
                                            <option value="Résidentiel">Résidentiel</option>
                                            <option value="Tertiaire">Tertiaire</option>
                                            <option value="RESEAU">RESEAU</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>Operation Nom</p>
                                    <div className='d-flex'>
                                        <select
                                            name='documents'
                                            id='documents'
                                            value={values.documents}
                                            onChange={handleChange}
                                            className="form-select ps-3  btn-buttons" aria-label="Operation Nom">
                                            <option defaultValue>-- Choisir un operation nom --</option>
                                            <option value="BAR-EN-101">BAR-EN-101</option>
                                            <option value="BAR-EN-102">BAR-EN-102</option>
                                            <option value="BAR-EN-103">BAR-EN-103</option>
                                            <option value="BAR-EN-104">BAR-EN-104</option>
                                            <option value="BAR-SE-104">BAR-SE-104</option>
                                            <option value="BAR-TH-160">BAR-TH-160</option>
                                            <option value="BAR-TH-161">BAR-TH-161</option>
                                            <option value="BAT-EN-101">BAT-EN-101</option>
                                            <option value="BAT-EN-102">BAT-EN-102</option>
                                            <option value="BAT-EN-103">BAT-EN-103</option>
                                            <option value="BAT-TH-116">BAT-TH-116</option>
                                            <option value="BAT-TH-146">BAT-TH-146</option>
                                            <option value="BAT-TH-104">BAT-TH-104</option>
                                            <option value="BAT-EQ-133">BAT-EQ-133</option>
                                            <option value="BAT-TH-155">BAT-TH-155</option>
                                            <option value="RES-EC-104">RES-EC-104</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* BAR-EN-101 */}
                            {
                                values.b2boperations === 'Résidentiel' && values.documents === 'BAR-EN-101' ?
                                    <div className='container-fluid '>
                                        <div className='row'>
                                            <div className='col'>
                                                <p className='bar1-para'>Calculez et obtenez votre prime / Votre projet : Isolation des combles ou de toiture :</p>
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                                    <div className=''>
                                                        <label className=' para-bar1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="beneficiaryOperation"
                                                                id="beneficiaryOperation"
                                                                value="Bailleur Social"
                                                                onChange={handleChange}
                                                                checked={values.beneficiaryOperation === "Bailleur Social"} />
                                                            <span className='bar1-para span-bar'>
                                                                Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                                    Copropriété privée, Syndic </span>et autres cas</span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="beneficiaryOperation"
                                                                id="beneficiaryOperation"
                                                                value="Copropriété privée"
                                                                onChange={handleChange}
                                                                checked={values.beneficiaryOperation === "Copropriété privée"} />
                                                            <span className='span-bar bar1-para'>
                                                                <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                                            </span>
                                                        </label>
                                                        <div className='button-1 para-bar1'>
                                                            <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Bailleur Social */}
                                                {values.beneficiaryOperation === 'Bailleur Social' && (
                                                    <div className='bar1-content mt-4 pb-4'>
                                                        <div className='p-4'>
                                                            <label className=' para-bar1'>
                                                                <input className="form-check-input"
                                                                    type="radio"
                                                                    name="departmental_precariousness"
                                                                    id="departmental_precariousness"
                                                                    value="précarité départementale"
                                                                    onChange={handleChange}
                                                                    checked={values.departmental_precariousness === "précarité départementale"} />
                                                                <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                                            </label>
                                                            <label className=' para-bar1 mt-1'>
                                                                <input className="form-check-input"
                                                                    type="radio"
                                                                    name="departmental_precariousness"
                                                                    id="departmental_precariousness"
                                                                    value="de précarité réel"
                                                                    onChange={handleChange}
                                                                    checked={values.departmental_precariousness === "de précarité réel"} />
                                                                <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Depatmental */}
                                                {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                    <div className='bar1-content mt-4 pb-4'>
                                                        <div className='p-3'>
                                                            <label className=' para-bar1 d-block'>
                                                                <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                                <input
                                                                    type='number'
                                                                    name="total_homes_affectedv1"
                                                                    id="total_homes_affectedv1"
                                                                    value={values.total_homes_affectedv1}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className="form-control1" ></input>
                                                            </label>
                                                            <label className=' para-bar1 mt-1 d-block'>
                                                                <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                                <input
                                                                    type='number'
                                                                    name="approved_housing"
                                                                    id="approved_housing"
                                                                    value={values.approved_housing}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className="form-control1" placeholder='0'></input>
                                                            </label>
                                                            <label className=' para-bar1 mt-1 d-block'>
                                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                                <input
                                                                    type='number'
                                                                    name="empty_households"
                                                                    id="empty_households"
                                                                    value={values.empty_households}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className="form-control1" placeholder='0'></input>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* reel */}
                                                {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                    <div className='bar1-content mt-4 pb-4'>
                                                        <div className='p-3'>
                                                            <label className=' para-bar1 d-block'>
                                                                <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                                <input
                                                                    type='total_homes_affectedv2'
                                                                    name="total_homes_affectedv2"
                                                                    id="total_homes_affectedv2"
                                                                    value={values.total_homes_affectedv2}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className="form-control1"></input>
                                                            </label>
                                                            <label className='para-bar1 mt-1 d-block'>
                                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                                <input
                                                                    type='number'
                                                                    name="classified_precarious"
                                                                    id="classified_precarious"
                                                                    value={values.classified_precarious}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className="form-control1" placeholder='0'></input>
                                                            </label>
                                                            <label className=' para-bar1 mt-1 d-block'>
                                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                                <input
                                                                    type='number'
                                                                    name="highly_precarious"
                                                                    id="highly_precarious"
                                                                    value={values.highly_precarious}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className="form-control1" placeholder='0'></input>
                                                            </label>
                                                            <label className=' para-bar1 mt-1 d-block'>
                                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                                <input
                                                                    type='number'
                                                                    name="without_proof"
                                                                    id="without_proof"
                                                                    value={values.without_proof}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className="form-control1" placeholder='0'></input>
                                                            </label>
                                                            <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Copriete */}
                                                {values.beneficiaryOperation === 'Copropriété privée' && (
                                                    <div className='bar1-content mt-4 pb-4'>
                                                        <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                                        <div className=''>
                                                            <label className=' para-bar1 d-block'>
                                                                <input className="form-check-input"
                                                                    type="radio"
                                                                    name="district_city_policy"
                                                                    id="district_city_policy"
                                                                    value="Oui"
                                                                    onChange={handleChange}
                                                                    checked={values.district_city_policy === "Oui"} />
                                                                <span className='bar1-para span-bar'>Oui</span>
                                                            </label>
                                                            <label className=' para-bar1 mt-1 d-block'>
                                                                <input className="form-check-input"
                                                                    type="radio"
                                                                    name="district_city_policy"
                                                                    id="district_city_policy"
                                                                    value="Non"
                                                                    onChange={handleChange}
                                                                    checked={values.district_city_policy === "Non"} />
                                                                <span className='span-bar bar1-para'>Non</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                                <p className="bar1-head  mt-4">Le bâtiment a plus de 2 ans ?</p>
                                                <div className='text-start'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value='Oui'
                                                            name="buildingmorethan2years"
                                                            id="buildingmorethan2years"
                                                            onChange={handleChange}
                                                            checked={values.buildingmorethan2years === "Oui"} />
                                                        <span className='span-bar bar1-para'>
                                                            Oui
                                                        </span>

                                                    </label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingmorethan2years"
                                                            value='Non'
                                                            id="buildingmorethan2years"
                                                            onChange={handleChange}
                                                            checked={values.buildingmorethan2years === "Non"} />
                                                        <span className='span-bar bar1-para '>
                                                            Non
                                                        </span>
                                                    </label>
                                                </div>
                                                {touched.buildingmorethan2years && errors.buildingmorethan2years && <div className="error-message">{errors.buildingmorethan2years}</div>}
                                                <div className='button-1 '>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </OverlayTrigger>
                                                </div>

                                                <hr></hr>
                                                <div className="row align-items-center">
                                                    <div className="col-lg-4 col-sm-12">
                                                        <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-12">
                                                        <input
                                                            type="number"
                                                            name='codepostal'
                                                            id='codepostal'
                                                            value={values.codepostal}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                        {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <p className="bar1-head  mt-4">L’opération consiste en la mise en place d’un procédé d’isolation thermique ?</p>

                                                <div className='text-start'>
                                                    <label className='para-bar1 '>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            value="Oui"
                                                            name="thermalinsulation"
                                                            id="thermalinsulation"
                                                            onChange={handleChange}
                                                            checked={values.thermalinsulation === "Oui"} />
                                                        <span className='span-bar bar1-para'>
                                                            Oui
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className='text-start mt-2' >
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value="Non"
                                                            name="thermalinsulation"
                                                            id="thermalinsulation"
                                                            onChange={handleChange}
                                                            checked={values.thermalinsulation === "Non"} />
                                                        <span className='span-bar bar1-para '>
                                                            Non
                                                        </span>
                                                    </label>
                                                </div>
                                                {touched.thermalinsulation && errors.thermalinsulation && <div className="error-message">{errors.thermalinsulation}</div>}
                                                <div className='button-1 '>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </OverlayTrigger>
                                                </div>
                                                <hr></hr>
                                                <p className="bar1-head  mt-4">Le procédé d’isolation thermique est effectué entre une zone chauffée et une zone non chauffée ? </p>
                                                <div className='text-start'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value="Oui"
                                                            name="heatedareaandanunheated"
                                                            id="heatedareaandanunheated"
                                                            onChange={handleChange}
                                                            checked={values.heatedareaandanunheated === "Oui"} />
                                                        <span className='span-bar bar1-para'>
                                                            Oui
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value="Non"
                                                            name="heatedareaandanunheated"
                                                            id="heatedareaandanunheated"
                                                            onChange={handleChange}
                                                            checked={values.heatedareaandanunheated === "Non"} />
                                                        <span className='span-bar bar1-para'>
                                                            Non
                                                        </span>
                                                    </label>
                                                </div>
                                                {touched.heatedareaandanunheated && errors.heatedareaandanunheated && <div className="error-message">{errors.heatedareaandanunheated}</div>}
                                                <div className='button-1 '>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </OverlayTrigger>
                                                </div>
                                                <hr></hr>

                                                <p className="bar1-head  mt-4">Le procédé d’isolation thermique est effectué entre une zone chauffée et une zone non chauffée ? </p>
                                                <div className='text-start'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value="floor of an unfinished"
                                                            name="heatedareaandanunheated2"
                                                            id="heatedareaandanunheated2"
                                                            onChange={handleChange}
                                                            checked={values.heatedareaandanunheated2 === "floor of an unfinished"} />
                                                        <span className='span-bar bar1-para'>
                                                            en comble perdu uniquement (ex : sol d’un grenier non-aménagé)
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value="inclined wall of the roof"
                                                            name="heatedareaandanunheated2"
                                                            id="heatedareaandanunheated2"
                                                            onChange={handleChange}
                                                            checked={values.heatedareaandanunheated2 === "inclined wall of the roof"} />
                                                        <span className='span-bar bar1-para '>
                                                            en rampant de toiture uniquement (ex : paroi inclinée du toit)
                                                        </span>
                                                    </label>

                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value="lost attic and roof slope"
                                                            name="heatedareaandanunheated2"
                                                            id="heatedareaandanunheated2"
                                                            onChange={handleChange}
                                                            checked={values.heatedareaandanunheated2 === "lost attic and roof slope"} />
                                                        <span className='span-bar bar1-para '>
                                                            en comble perdu et en rampant de toiture
                                                        </span>
                                                    </label>
                                                </div>
                                                {touched.heatedareaandanunheated2 && errors.heatedareaandanunheated2 && <div className="error-message">{errors.heatedareaandanunheated2}</div>}

                                                <div className='button-1 '>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </OverlayTrigger>
                                                </div>
                                                <hr></hr>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <p className='bar1-head mt-3'>Surface totale isolée (en m²)* :</p></div>
                                                    <div className="col-4 text-start ">
                                                        <input
                                                            type="number"
                                                            name="surface_area"
                                                            id="surface_area"
                                                            value={values.surface_area}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1 w-100'}
                                                            placeholder='0' ></input>
                                                        {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}
                                                    </div>
                                                </div> <hr></hr>

                                                <p className="bar1-head  mt-4">Pour lutter contre la fraude, le début des travaux ne peut commencer que 8 jours après la date de commande ? </p>
                                                <div className='text-start'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value="8 days"
                                                            name="order"
                                                            id="order"
                                                            onChange={handleChange}
                                                            checked={values.order === "8 days"} />
                                                        <span className='span-bar bar1-para'>
                                                            Oui, un délai minimum de 8 jours est/sera respecté </span>
                                                    </label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value="8 days after the order"
                                                            name="order"
                                                            id="order"
                                                            onChange={handleChange}
                                                            checked={values.order === "8 days after the order"} />
                                                        <span className='span-bar bar1-para '>
                                                            Non, la pose est/sera réalisée moins de 8 jours après la commande
                                                        </span>
                                                    </label>
                                                    {touched.order && errors.order && <div className="error-message">{errors.order}</div>}
                                                </div>
                                                <div className='button-1 '>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </div><hr></hr>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {/* BAR-EN-102 */}
                            {values.b2boperations === 'Résidentiel' && values.documents === 'BAR-EN-102' ?
                                <div className='container-fluid '>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para'>Calculez et obtenez votre prime / Votre projet : Isolation des combles ou de toiture :</p>
                                            <div className='bar1-content mt-4 pb-4'>
                                                <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                                <div className=''>
                                                    <label className=' para-bar1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Bailleur Social"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Bailleur Social"} />
                                                        <span className='bar1-para span-bar'>
                                                            Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                                Copropriété privée, Syndic </span>et autres cas</span>
                                                    </label>
                                                    <label className=' para-bar1 mt-1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Copropriété privée"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Copropriété privée"} />
                                                        <span className='span-bar bar1-para'>
                                                            <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                                        </span>
                                                    </label>
                                                    <div className='button-1 para-bar1'>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Bailleur Social */}
                                            {values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-4'>
                                                        <label className=' para-bar1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="précarité départementale"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "précarité départementale"} />
                                                            <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="de précarité réel"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "de précarité réel"} />
                                                            <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Depatmental */}
                                            {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='number'
                                                                name="total_homes_affectedv1"
                                                                id="total_homes_affectedv1"
                                                                value={values.total_homes_affectedv1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" ></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                            <input
                                                                type='number'
                                                                name="approved_housing"
                                                                id="approved_housing"
                                                                value={values.approved_housing}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                            <input
                                                                type='number'
                                                                name="empty_households"
                                                                id="empty_households"
                                                                value={values.empty_households}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            {/* reel */}
                                            {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='total_homes_affectedv2'
                                                                name="total_homes_affectedv2"
                                                                id="total_homes_affectedv2"
                                                                value={values.total_homes_affectedv2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1"></input>
                                                        </label>
                                                        <label className='para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="classified_precarious"
                                                                id="classified_precarious"
                                                                value={values.classified_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="highly_precarious"
                                                                id="highly_precarious"
                                                                value={values.highly_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                            <input
                                                                type='number'
                                                                name="without_proof"
                                                                id="without_proof"
                                                                value={values.without_proof}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Copriete */}
                                            {values.beneficiaryOperation === 'Copropriété privée' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                                    <div className=''>
                                                        <label className=' para-bar1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Oui"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Oui"} />
                                                            <span className='bar1-para span-bar'>Oui</span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Non"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Non"} />
                                                            <span className='span-bar bar1-para'>Non</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            <p className="bar1-head  mt-4">Le bâtiment a plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="buildingmorethan2years"
                                                        id="buildingmorethan2years"
                                                        onChange={handleChange}
                                                        checked={values.buildingmorethan2years === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingmorethan2years"
                                                        value='Non'
                                                        id="buildingmorethan2years"
                                                        onChange={handleChange}
                                                        checked={values.buildingmorethan2years === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.buildingmorethan2years && errors.buildingmorethan2years && <div className="error-message">{errors.buildingmorethan2years}</div>}
                                            <div className='button-1 '>
                                                <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </OverlayTrigger>
                                            </div>

                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">L’opération consiste en la mise en place d’un procédé d’isolation thermique ?</p>

                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Oui"
                                                        name="thermalinsulation"
                                                        id="thermalinsulation"
                                                        onChange={handleChange}
                                                        checked={values.thermalinsulation === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2' >
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="Non"
                                                        name="thermalinsulation"
                                                        id="thermalinsulation"
                                                        onChange={handleChange}
                                                        checked={values.thermalinsulation === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.thermalinsulation && errors.thermalinsulation && <div className="error-message">{errors.thermalinsulation}</div>}
                                            <div className='button-1 '>
                                                <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </OverlayTrigger>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">Le procédé d’isolation thermique est effectué entre une zone chauffée et une zone non chauffée ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="Oui"
                                                        name="heatedareaandanunheated"
                                                        id="heatedareaandanunheated"
                                                        onChange={handleChange}
                                                        checked={values.heatedareaandanunheated === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="Non"
                                                        name="heatedareaandanunheated"
                                                        id="heatedareaandanunheated"
                                                        onChange={handleChange}
                                                        checked={values.heatedareaandanunheated === "Non"} />
                                                    <span className='span-bar bar1-para'>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.heatedareaandanunheated && errors.heatedareaandanunheated && <div className="error-message">{errors.heatedareaandanunheated}</div>}
                                            <div className='button-1 '>
                                                <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </OverlayTrigger>
                                            </div>
                                            <hr></hr>

                                            <p className="bar1-head  mt-4">Le procédé d’isolation thermique est effectué entre une zone chauffée et une zone non chauffée ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="floor of an unfinished"
                                                        name="heatedareaandanunheated2"
                                                        id="heatedareaandanunheated2"
                                                        onChange={handleChange}
                                                        checked={values.heatedareaandanunheated2 === "floor of an unfinished"} />
                                                    <span className='span-bar bar1-para'>
                                                        en comble perdu uniquement (ex : sol d’un grenier non-aménagé)
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="inclined wall of the roof"
                                                        name="heatedareaandanunheated2"
                                                        id="heatedareaandanunheated2"
                                                        onChange={handleChange}
                                                        checked={values.heatedareaandanunheated2 === "inclined wall of the roof"} />
                                                    <span className='span-bar bar1-para '>
                                                        en rampant de toiture uniquement (ex : paroi inclinée du toit)
                                                    </span>
                                                </label>

                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="lost attic and roof slope"
                                                        name="heatedareaandanunheated2"
                                                        id="heatedareaandanunheated2"
                                                        onChange={handleChange}
                                                        checked={values.heatedareaandanunheated2 === "lost attic and roof slope"} />
                                                    <span className='span-bar bar1-para '>
                                                        en comble perdu et en rampant de toiture
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.heatedareaandanunheated2 && errors.heatedareaandanunheated2 && <div className="error-message">{errors.heatedareaandanunheated2}</div>}

                                            <div className='button-1 '>
                                                <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </OverlayTrigger>
                                            </div>
                                            <hr></hr>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p className='bar1-head mt-3'>Surface totale isolée (en m²)* :</p></div>
                                                <div className="col-4 text-start ">
                                                    <input
                                                        type="number"
                                                        name="surface_area"
                                                        id="surface_area"
                                                        value={values.surface_area}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1 w-100'}
                                                        placeholder='0' ></input>
                                                    {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}
                                                </div>
                                            </div> <hr></hr>

                                            <p className="bar1-head  mt-4">Pour lutter contre la fraude, le début des travaux ne peut commencer que 8 jours après la date de commande ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="8 days"
                                                        name="order"
                                                        id="order"
                                                        onChange={handleChange}
                                                        checked={values.order === "8 days"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui, un délai minimum de 8 jours est/sera respecté </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="8 days after the order"
                                                        name="order"
                                                        id="order"
                                                        onChange={handleChange}
                                                        checked={values.order === "8 days after the order"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non, la pose est/sera réalisée moins de 8 jours après la commande
                                                    </span>
                                                </label>
                                                {touched.order && errors.order && <div className="error-message">{errors.order}</div>}
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAR-EN-103 */}
                            {values.b2boperations === 'Résidentiel' && values.documents === 'BAR-EN-103' ?
                                <div className='container-fluid '>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para'>Calculez et obtenez votre prime / Votre projet : Isolation des combles ou de toiture :</p>
                                            <div className='bar1-content mt-4 pb-4'>
                                                <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                                <div className=''>
                                                    <label className=' para-bar1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Bailleur Social"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Bailleur Social"} />
                                                        <span className='bar1-para span-bar'>
                                                            Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                                Copropriété privée, Syndic </span>et autres cas</span>
                                                    </label>
                                                    <label className=' para-bar1 mt-1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Copropriété privée"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Copropriété privée"} />
                                                        <span className='span-bar bar1-para'>
                                                            <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                                        </span>
                                                    </label>
                                                    <div className='button-1 para-bar1'>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Bailleur Social */}
                                            {values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-4'>
                                                        <label className=' para-bar1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="précarité départementale"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "précarité départementale"} />
                                                            <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="de précarité réel"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "de précarité réel"} />
                                                            <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Depatmental */}
                                            {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='number'
                                                                name="total_homes_affectedv1"
                                                                id="total_homes_affectedv1"
                                                                value={values.total_homes_affectedv1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" ></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                            <input
                                                                type='number'
                                                                name="approved_housing"
                                                                id="approved_housing"
                                                                value={values.approved_housing}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                            <input
                                                                type='number'
                                                                name="empty_households"
                                                                id="empty_households"
                                                                value={values.empty_households}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            {/* reel */}
                                            {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='total_homes_affectedv2'
                                                                name="total_homes_affectedv2"
                                                                id="total_homes_affectedv2"
                                                                value={values.total_homes_affectedv2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1"></input>
                                                        </label>
                                                        <label className='para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="classified_precarious"
                                                                id="classified_precarious"
                                                                value={values.classified_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="highly_precarious"
                                                                id="highly_precarious"
                                                                value={values.highly_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                            <input
                                                                type='number'
                                                                name="without_proof"
                                                                id="without_proof"
                                                                value={values.without_proof}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Copriete */}
                                            {values.beneficiaryOperation === 'Copropriété privée' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                                    <div className=''>
                                                        <label className=' para-bar1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Oui"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Oui"} />
                                                            <span className='bar1-para span-bar'>Oui</span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Non"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Non"} />
                                                            <span className='span-bar bar1-para'>Non</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            <p className="bar1-head  mt-4">Le bâtiment a plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="buildingmorethan2years"
                                                        id="buildingmorethan2years"
                                                        onChange={handleChange}
                                                        checked={values.buildingmorethan2years === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingmorethan2years"
                                                        value='Non'
                                                        id="buildingmorethan2years"
                                                        onChange={handleChange}
                                                        checked={values.buildingmorethan2years === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.buildingmorethan2years && errors.buildingmorethan2years && <div className="error-message">{errors.buildingmorethan2years}</div>}
                                            <div className='button-1 '>
                                                <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </OverlayTrigger>
                                            </div>

                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">L’opération consiste en la mise en place d’un procédé d’isolation thermique ?</p>

                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Oui"
                                                        name="thermalinsulation"
                                                        id="thermalinsulation"
                                                        onChange={handleChange}
                                                        checked={values.thermalinsulation === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2' >
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="Non"
                                                        name="thermalinsulation"
                                                        id="thermalinsulation"
                                                        onChange={handleChange}
                                                        checked={values.thermalinsulation === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.thermalinsulation && errors.thermalinsulation && <div className="error-message">{errors.thermalinsulation}</div>}
                                            <div className='button-1 '>
                                                <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </OverlayTrigger>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">Le procédé d’isolation thermique est effectué entre une zone chauffée et une zone non chauffée ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="Oui"
                                                        name="heatedareaandanunheated"
                                                        id="heatedareaandanunheated"
                                                        onChange={handleChange}
                                                        checked={values.heatedareaandanunheated === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="Non"
                                                        name="heatedareaandanunheated"
                                                        id="heatedareaandanunheated"
                                                        onChange={handleChange}
                                                        checked={values.heatedareaandanunheated === "Non"} />
                                                    <span className='span-bar bar1-para'>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.heatedareaandanunheated && errors.heatedareaandanunheated && <div className="error-message">{errors.heatedareaandanunheated}</div>}
                                            <div className='button-1 '>
                                                <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </OverlayTrigger>
                                            </div>
                                            <hr></hr>

                                            <p className="bar1-head  mt-4">Le procédé d’isolation thermique est effectué entre une zone chauffée et une zone non chauffée ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="floor of an unfinished"
                                                        name="heatedareaandanunheated2"
                                                        id="heatedareaandanunheated2"
                                                        onChange={handleChange}
                                                        checked={values.heatedareaandanunheated2 === "floor of an unfinished"} />
                                                    <span className='span-bar bar1-para'>
                                                        en comble perdu uniquement (ex : sol d’un grenier non-aménagé)
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="inclined wall of the roof"
                                                        name="heatedareaandanunheated2"
                                                        id="heatedareaandanunheated2"
                                                        onChange={handleChange}
                                                        checked={values.heatedareaandanunheated2 === "inclined wall of the roof"} />
                                                    <span className='span-bar bar1-para '>
                                                        en rampant de toiture uniquement (ex : paroi inclinée du toit)
                                                    </span>
                                                </label>

                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="lost attic and roof slope"
                                                        name="heatedareaandanunheated2"
                                                        id="heatedareaandanunheated2"
                                                        onChange={handleChange}
                                                        checked={values.heatedareaandanunheated2 === "lost attic and roof slope"} />
                                                    <span className='span-bar bar1-para '>
                                                        en comble perdu et en rampant de toiture
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.heatedareaandanunheated2 && errors.heatedareaandanunheated2 && <div className="error-message">{errors.heatedareaandanunheated2}</div>}

                                            <div className='button-1 '>
                                                <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </OverlayTrigger>
                                            </div>
                                            <hr></hr>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p className='bar1-head mt-3'>Surface totale isolée (en m²)* :</p></div>
                                                <div className="col-4 text-start ">
                                                    <input
                                                        type="number"
                                                        name="surface_area"
                                                        id="surface_area"
                                                        value={values.surface_area}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1 w-100'}
                                                        placeholder='0' ></input>
                                                    {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}
                                                </div>
                                            </div> <hr></hr>

                                            <p className="bar1-head  mt-4">Pour lutter contre la fraude, le début des travaux ne peut commencer que 8 jours après la date de commande ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="8 days"
                                                        name="order"
                                                        id="order"
                                                        onChange={handleChange}
                                                        checked={values.order === "8 days"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui, un délai minimum de 8 jours est/sera respecté </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="8 days after the order"
                                                        name="order"
                                                        id="order"
                                                        onChange={handleChange}
                                                        checked={values.order === "8 days after the order"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non, la pose est/sera réalisée moins de 8 jours après la commande
                                                    </span>
                                                </label>
                                                {touched.order && errors.order && <div className="error-message">{errors.order}</div>}
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAR-EN-104 */}
                            {values.b2boperations === 'Résidentiel' && values.documents === 'BAR-EN-104' ?
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>BAR-EN-104 : Fenêtre ou porte-fenêtre complète avec vitrage isolant </p>
                                            <div className='bar1-content mt-4 pb-4'>
                                                <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                                <div className=''>
                                                    <label className=' para-bar1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Bailleur Social"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Bailleur Social"} />
                                                        <span className='bar1-para span-bar'>
                                                            Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                                Copropriété privée, Syndic </span>et autres cas</span>
                                                    </label>
                                                    <label className=' para-bar1 mt-1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Copropriété privée"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Copropriété privée"} />
                                                        <span className='span-bar bar1-para'>
                                                            <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                                        </span>
                                                    </label>
                                                    <div className='button-1 para-bar1'>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Bailleur Social */}
                                            {values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-4'>
                                                        <label className=' para-bar1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="précarité départementale"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "précarité départementale"} />
                                                            <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="de précarité réel"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "de précarité réel"} />
                                                            <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Depatmental */}
                                            {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='number'
                                                                name="total_homes_affectedv1"
                                                                id="total_homes_affectedv1"
                                                                value={values.total_homes_affectedv1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" ></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                            <input
                                                                type='number'
                                                                name="approved_housing"
                                                                id="approved_housing"
                                                                value={values.approved_housing}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                            <input
                                                                type='number'
                                                                name="empty_households"
                                                                id="empty_households"
                                                                value={values.empty_households}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            {/* reel */}
                                            {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='total_homes_affectedv2'
                                                                name="total_homes_affectedv2"
                                                                id="total_homes_affectedv2"
                                                                value={values.total_homes_affectedv2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1"></input>
                                                        </label>
                                                        <label className='para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="classified_precarious"
                                                                id="classified_precarious"
                                                                value={values.classified_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="highly_precarious"
                                                                id="highly_precarious"
                                                                value={values.highly_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                            <input
                                                                type='number'
                                                                name="without_proof"
                                                                id="without_proof"
                                                                value={values.without_proof}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Copriete */}
                                            {values.beneficiaryOperation === 'Copropriété privée' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                                    <div className=''>
                                                        <label className=' para-bar1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Yes"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Yes"} />
                                                            <span className='bar1-para span-bar'>Oui</span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Non"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Non"} />
                                                            <span className='span-bar bar1-para'>Non</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            <p className="bar1-head  mt-4">Bâtiment résidentiel existant depuis plus de 2 ans</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="buildingmorethan2years"
                                                        id="buildingmorethan2years"
                                                        onChange={handleChange}
                                                        checked={values.buildingmorethan2years === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="buildingmorethan2years"
                                                        id="buildingmorethan2years"
                                                        onChange={handleChange}
                                                        checked={values.buildingmorethan2years === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label> </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">Énergie de chauffage utilisée :
                                            </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input" type="radio"
                                                        name="heatingEnergy"
                                                        id="heatingEnergy"
                                                        value="Electricité"
                                                        onChange={handleChange}
                                                        checked={values.heatingEnergy === "Electricité"} />
                                                    <span className='span-bar bar1-para'>
                                                        Electricité
                                                    </span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input" type="radio"
                                                        name="heatingEnergy"
                                                        id="heatingEnergy"
                                                        value="Gaz"
                                                        onChange={handleChange}
                                                        checked={values.heatingEnergy === "Gaz"} />
                                                    <span className='span-bar bar1-para'>
                                                        Gaz (de ville, butane, propane...)
                                                    </span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input" type="radio"
                                                        name="heatingEnergy"
                                                        id="heatingEnergy"
                                                        value="Fioul"
                                                        onChange={handleChange}
                                                        checked={values.heatingEnergy === "Fioul"} />
                                                    <span className='span-bar bar1-para'>
                                                        Fioul
                                                    </span> </label>
                                            </div>
                                            <div className='text-start mt-2' >
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="heatingEnergy"
                                                        id="heatingEnergy"
                                                        value="Bois"
                                                        onChange={handleChange}
                                                        checked={values.heatingEnergy === "Bois"} />
                                                    <span className='span-bar bar1-para '> Bois (biomasse)</span>
                                                </label></div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Combien de fenêtres ou porte-fenêtres à remplacer*</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='windowsorpatiodoors'
                                                        id='windowsorpatiodoors'
                                                        value={values.windowsorpatiodoors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.windowsorpatiodoors && errors.windowsorpatiodoors ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.windowsorpatiodoors && errors.windowsorpatiodoors && <div className="error-message">{errors.windowsorpatiodoors}</div>}
                                                </div>
                                            </div>

                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Combien de fenêtres de toiture (type Velux) à remplacer* </p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='roofwindows'
                                                        id='roofwindows'
                                                        value={values.roofwindows}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.roofwindows && errors.roofwindows ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.roofwindows && errors.roofwindows && <div className="error-message">{errors.roofwindows}</div>}
                                                </div>
                                            </div>

                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAR-SE-104 */}
                            {values.b2boperations === 'Résidentiel' && values.documents === 'BAR-SE-104' ?
                                <div className='container-fluid  '>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Isolation des combles ou de toiture :</p>
                                            <div className='bar1-content mt-4 pb-4'>
                                                <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                                <div className=''>
                                                    <label className=' para-bar1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Bailleur Social"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Bailleur Social"} />
                                                        <span className='bar1-para span-bar'>
                                                            Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                                Copropriété privée, Syndic </span>et autres cas</span>
                                                    </label>
                                                    <label className=' para-bar1 mt-1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Copropriété privée"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Copropriété privée"} />
                                                        <span className='span-bar bar1-para'>
                                                            <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                                        </span>
                                                    </label>
                                                    <div className='button-1 para-bar1'>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Bailleur Social */}
                                            {values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-4'>
                                                        <label className=' para-bar1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="précarité départementale"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "précarité départementale"} />
                                                            <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="de précarité réel"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "de précarité réel"} />
                                                            <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Depatmental */}
                                            {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='number'
                                                                name="total_homes_affectedv1"
                                                                id="total_homes_affectedv1"
                                                                value={values.total_homes_affectedv1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" ></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                            <input
                                                                type='number'
                                                                name="approved_housing"
                                                                id="approved_housing"
                                                                value={values.approved_housing}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                            <input
                                                                type='number'
                                                                name="empty_households"
                                                                id="empty_households"
                                                                value={values.empty_households}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            {/* reel */}
                                            {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='total_homes_affectedv2'
                                                                name="total_homes_affectedv2"
                                                                id="total_homes_affectedv2"
                                                                value={values.total_homes_affectedv2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1"></input>
                                                        </label>
                                                        <label className='para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="classified_precarious"
                                                                id="classified_precarious"
                                                                value={values.classified_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="highly_precarious"
                                                                id="highly_precarious"
                                                                value={values.highly_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                            <input
                                                                type='number'
                                                                name="without_proof"
                                                                id="without_proof"
                                                                value={values.without_proof}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Copriete */}
                                            {values.beneficiaryOperation === 'Copropriété privée' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                                    <div className=''>
                                                        <label className=' para-bar1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Oui"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Oui"} />
                                                            <span className='bar1-para span-bar'>Oui</span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Non"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Non"} />
                                                            <span className='span-bar bar1-para'>Non</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            <p className="bar1-head  mt-4">Bâtiment résidentiel existant depuis plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input" type="radio"
                                                        name="residentialBuilding"
                                                        id="residentialBuilding"
                                                        value="Oui"
                                                        onChange={handleChange}
                                                        checked={values.residentialBuilding === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="residentialBuilding"
                                                        id="residentialBuilding"
                                                        value="Non"
                                                        onChange={handleChange}
                                                        checked={values.residentialBuilding === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Nombre d’appartements équilibrés* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='noofApartment'
                                                        id='noofApartment'
                                                        value={values.noofApartment}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.noofApartment && errors.noofApartment ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.noofApartment && errors.noofApartment && <div className="error-message">{errors.noofApartment}</div>}
                                                </div>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                            <div className='text-start'>
                                                <label >
                                                    <input
                                                        type="number"
                                                        name='premiumamount'
                                                        id='premiumamount'
                                                        value={values.premiumamount}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} className="input-box" placeholder='3' ></input>
                                                    <span className='span-bar bar1-para'>Euros du Méga / Prix du kilowater </span>
                                                </label>

                                            </div><hr></hr>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAR-TH-160 */}
                            {values.b2boperations === 'Résidentiel' && values.documents === 'BAR-TH-160' ?
                                <div className='container-fluid  '>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Isolation d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire</p>
                                            <div className='bar1-content mt-4 pb-4'>
                                                <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                                <div className=''>
                                                    <label className=' para-bar1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Bailleur Social"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Bailleur Social"} />
                                                        <span className='bar1-para span-bar'>
                                                            Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                                Copropriété privée, Syndic </span>et autres cas</span>
                                                    </label>
                                                    <label className=' para-bar1 mt-1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Copropriété privée"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Copropriété privée"} />
                                                        <span className='span-bar bar1-para'>
                                                            <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                                        </span>
                                                    </label>
                                                    <div className='button-1 para-bar1'>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Bailleur Social */}
                                            {values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-4'>
                                                        <label className=' para-bar1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="précarité départementale"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "précarité départementale"} />
                                                            <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="de précarité réel"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "de précarité réel"} />
                                                            <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Depatmental */}
                                            {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='number'
                                                                name="total_homes_affectedv1"
                                                                id="total_homes_affectedv1"
                                                                value={values.total_homes_affectedv1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" ></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                            <input
                                                                type='number'
                                                                name="approved_housing"
                                                                id="approved_housing"
                                                                value={values.approved_housing}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                            <input
                                                                type='number'
                                                                name="empty_households"
                                                                id="empty_households"
                                                                value={values.empty_households}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            {/* reel */}
                                            {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='total_homes_affectedv2'
                                                                name="total_homes_affectedv2"
                                                                id="total_homes_affectedv2"
                                                                value={values.total_homes_affectedv2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1"></input>
                                                        </label>
                                                        <label className='para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="classified_precarious"
                                                                id="classified_precarious"
                                                                value={values.classified_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="highly_precarious"
                                                                id="highly_precarious"
                                                                value={values.highly_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                            <input
                                                                type='number'
                                                                name="without_proof"
                                                                id="without_proof"
                                                                value={values.without_proof}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Copriete */}
                                            {values.beneficiaryOperation === 'Copropriété privée' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                                    <div className=''>
                                                        <label className=' para-bar1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Yes"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Yes"} />
                                                            <span className='bar1-para span-bar'>Oui</span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Non"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Non"} />
                                                            <span className='span-bar bar1-para'>Non</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            <p className='bar1-head mt-3'>Date du devis de signature :
                                                <input type="date"
                                                    name='signature'
                                                    id='signature'
                                                    value={values.signature}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input></p>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">Bâtiment(s) résidentiel(s) collectif de plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Yes'
                                                        name="residential_2years_old"
                                                        id="residential_2years_old"
                                                        onChange={handleChange}
                                                        checked={values.residential_2years_old === "Yes"} />
                                                    <span className='span-bar bar1-para'>Oui</span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='No'
                                                        name="residential_2years_old"
                                                        id="residential_2years_old"
                                                        onChange={handleChange}
                                                        checked={values.residential_2years_old === "No"} />
                                                    <span className='span-bar bar1-para '> Non</span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div><hr></hr>

                                            <p className="bar1-head  mt-4">L’opération consiste en la mise en place ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value='hydraulic heating'
                                                        name="installation"
                                                        id="installation"
                                                        onChange={handleChange}
                                                        checked={values.installation === "hydraulic heating"} />
                                                    <span className='span-bar bar1-para'>d’une isolation sur un réseau hydraulique de chauffage ou d’ECS</span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value='pre-insulated pipe'
                                                        name="installation"
                                                        id="installation"
                                                        onChange={handleChange}
                                                        checked={values.installation === "pre-insulated pipe"} />
                                                    <span className='span-bar bar1-para '>d’une canalisation pré-isolée</span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Le réseau hydraulique de chauffage ou d’ECS est installé depuis plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Yes'
                                                        name="hydraulicheating"
                                                        id="hydraulicheating"
                                                        onChange={handleChange}
                                                        checked={values.hydraulicheating === "Yes"} />
                                                    <span className='span-bar bar1-para'>Oui</span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='No'
                                                        name="hydraulicheating"
                                                        id="hydraulicheating"
                                                        onChange={handleChange}
                                                        checked={values.hydraulicheating === "No"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">La chaudière ou le réseau hydraulique a été refait/remplacé après le 01/01/2018 ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Yes'
                                                        name="replaced_after_2018"
                                                        id="replaced_after_2018"
                                                        onChange={handleChange}
                                                        checked={values.replaced_after_2018 === "Yes"} />
                                                    <span className='span-bar bar1-para'> Oui</span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='NO'
                                                        name="replaced_after_2018"
                                                        id="replaced_after_2018"
                                                        onChange={handleChange}
                                                        checked={values.replaced_after_2018 === "No"} />
                                                    <span className='span-bar bar1-para '>Non, aucune modification après le 01/01/2018</span>
                                                </label></div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-sm-12">
                                                    <p className='bar1-head mt-3'>Longueur totale du (ou des) réseau(x) hydraulique(s) isolé(s) (en m linéaire)* :</p>
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='surface_area'
                                                        id='surface_area'
                                                        value={values.surface_area}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.surface_area && errors.surface_area && <div className="error-message">{errors.surface_area}</div>}
                                                </div>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                            <div className='text-start'>
                                                <label >
                                                    <input
                                                        type="number"
                                                        name='primeamount'
                                                        id='primeamount'
                                                        value={values.primeamount}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="input-box"  ></input>
                                                    <span className='span-bar bar1-para' >Euros du Méga / Prix du kilowater</span>
                                                </label>

                                            </div><hr></hr>
                                        </div>
                                    </div><div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Isolation d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire</p>
                                            <div className='bar1-content mt-4 pb-4'>
                                                <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                                <div className=''>
                                                    <label className=' para-bar1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Bailleur Social"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Bailleur Social"} />
                                                        <span className='bar1-para span-bar'>
                                                            Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                                Copropriété privée, Syndic </span>et autres cas</span>
                                                    </label>
                                                    <label className=' para-bar1 mt-1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Copropriété privée"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Copropriété privée"} />
                                                        <span className='span-bar bar1-para'>
                                                            <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                                        </span>
                                                    </label>
                                                    <div className='button-1 para-bar1'>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Bailleur Social */}
                                            {values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-4'>
                                                        <label className=' para-bar1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="précarité départementale"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "précarité départementale"} />
                                                            <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="de précarité réel"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "de précarité réel"} />
                                                            <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Depatmental */}
                                            {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='number'
                                                                name="total_homes_affectedv1"
                                                                id="total_homes_affectedv1"
                                                                value={values.total_homes_affectedv1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" ></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                            <input
                                                                type='number'
                                                                name="approved_housing"
                                                                id="approved_housing"
                                                                value={values.approved_housing}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                            <input
                                                                type='number'
                                                                name="empty_households"
                                                                id="empty_households"
                                                                value={values.empty_households}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            {/* reel */}
                                            {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='total_homes_affectedv2'
                                                                name="total_homes_affectedv2"
                                                                id="total_homes_affectedv2"
                                                                value={values.total_homes_affectedv2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1"></input>
                                                        </label>
                                                        <label className='para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="classified_precarious"
                                                                id="classified_precarious"
                                                                value={values.classified_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="highly_precarious"
                                                                id="highly_precarious"
                                                                value={values.highly_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                            <input
                                                                type='number'
                                                                name="without_proof"
                                                                id="without_proof"
                                                                value={values.without_proof}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Copriete */}
                                            {values.beneficiaryOperation === 'Copropriété privée' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                                    <div className=''>
                                                        <label className=' para-bar1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Oui"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Oui"} />
                                                            <span className='bar1-para span-bar'>Oui</span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Non"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Non"} />
                                                            <span className='span-bar bar1-para'>Non</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            <p className='bar1-head mt-3'>Date du devis de signature :
                                                <input type="date"
                                                    name='signature'
                                                    id='signature'
                                                    value={values.signature}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input></p>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">Bâtiment(s) résidentiel(s) collectif de plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="residential_2years_old"
                                                        id="residential_2years_old"
                                                        onChange={handleChange}
                                                        checked={values.residential_2years_old === "Oui"} />
                                                    <span className='span-bar bar1-para'>Oui</span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="residential_2years_old"
                                                        id="residential_2years_old"
                                                        onChange={handleChange}
                                                        checked={values.residential_2years_old === "Non"} />
                                                    <span className='span-bar bar1-para '> Non</span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div><hr></hr>
                                            {/* <hr></hr>
                                <div className="row">
                                    <div className="col-4">
                                        <p className='bar1-head mt-3'>Surface totale isolée (en m²)* :</p></div>
                                    <div className="col-4 text-start "><input
                                        type="number"
                                        name="surface_area"
                                        id="surface_area"
                                        value={values.surface_area}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1'}
                                        placeholder='0' ></input>
                                        {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}
                                    </div>
                                </div> <hr></hr> */}
                                            <p className="bar1-head  mt-4">L’opération consiste en la mise en place ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value='hydraulic heating'
                                                        name="installation"
                                                        id="installation"
                                                        onChange={handleChange}
                                                        checked={values.installation === "hydraulic heating"} />
                                                    <span className='span-bar bar1-para'>d’une isolation sur un réseau hydraulique de chauffage ou d’ECS</span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value='pre-insulated pipe'
                                                        name="installation"
                                                        id="installation"
                                                        onChange={handleChange}
                                                        checked={values.installation === "pre-insulated pipe"} />
                                                    <span className='span-bar bar1-para '>d’une canalisation pré-isolée</span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Le réseau hydraulique de chauffage ou d’ECS est installé depuis plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="hydraulicheating"
                                                        id="hydraulicheating"
                                                        onChange={handleChange}
                                                        checked={values.hydraulicheating === "Oui"} />
                                                    <span className='span-bar bar1-para'>Oui</span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="hydraulicheating"
                                                        id="hydraulicheating"
                                                        onChange={handleChange}
                                                        checked={values.hydraulicheating === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">La chaudière ou le réseau hydraulique a été refait/remplacé après le 01/01/2018 ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="replaced_after_2018"
                                                        id="replaced_after_2018"
                                                        onChange={handleChange}
                                                        checked={values.replaced_after_2018 === "Oui"} />
                                                    <span className='span-bar bar1-para'> Oui</span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="replaced_after_2018"
                                                        id="replaced_after_2018"
                                                        onChange={handleChange}
                                                        checked={values.replaced_after_2018 === "Non"} />
                                                    <span className='span-bar bar1-para '>Non, aucune modification après le 01/01/2018</span>
                                                </label></div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-sm-12">
                                                    <p className='bar1-head mt-3'>Longueur totale du (ou des) réseau(x) hydraulique(s) isolé(s) (en m linéaire)* :</p>
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='surface_area'
                                                        id='surface_area'
                                                        value={values.surface_area}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.surface_area && errors.surface_area && <div className="error-message">{errors.surface_area}</div>}
                                                </div>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                            <div className='text-start'>
                                                <label >
                                                    <input
                                                        type="number"
                                                        name='primeamount'
                                                        id='primeamount'
                                                        value={values.primeamount}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="input-box"  ></input>
                                                    <span className='span-bar bar1-para' >Euros du Méga / Prix du kilowater</span>
                                                </label>
                                            </div><hr></hr>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAR-TH-161 */}
                            {values.b2boperations === 'Résidentiel' && values.documents === 'BAR-TH-161' ?
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>BAR-TH-161 : Isolation points singuliers – Résidentiel </p>
                                            <div className='bar1-content mt-4 pb-4'>
                                                <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                                <div className=''>
                                                    <label className=' para-bar1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Bailleur Social"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Bailleur Social"} />
                                                        <span className='bar1-para span-bar'>
                                                            Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                                Copropriété privée, Syndic </span>et autres cas</span>
                                                    </label>
                                                    <label className=' para-bar1 mt-1'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="beneficiaryOperation"
                                                            id="beneficiaryOperation"
                                                            value="Copropriété privée"
                                                            onChange={handleChange}
                                                            checked={values.beneficiaryOperation === "Copropriété privée"} />
                                                        <span className='span-bar bar1-para'>
                                                            <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                                        </span>
                                                    </label>
                                                    <div className='button-1 para-bar1'>
                                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Bailleur Social */}
                                            {values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-4'>
                                                        <label className=' para-bar1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="précarité départementale"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "précarité départementale"} />
                                                            <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="departmental_precariousness"
                                                                id="departmental_precariousness"
                                                                value="de précarité réel"
                                                                onChange={handleChange}
                                                                checked={values.departmental_precariousness === "de précarité réel"} />
                                                            <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Depatmental */}
                                            {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='number'
                                                                name="total_homes_affectedv1"
                                                                id="total_homes_affectedv1"
                                                                value={values.total_homes_affectedv1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" ></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                            <input
                                                                type='number'
                                                                name="approved_housing"
                                                                id="approved_housing"
                                                                value={values.approved_housing}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                            <input
                                                                type='number'
                                                                name="empty_households"
                                                                id="empty_households"
                                                                value={values.empty_households}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            {/* reel */}
                                            {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <div className='p-3'>
                                                        <label className=' para-bar1 d-block'>
                                                            <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                            <input
                                                                type='total_homes_affectedv2'
                                                                name="total_homes_affectedv2"
                                                                id="total_homes_affectedv2"
                                                                value={values.total_homes_affectedv2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1"></input>
                                                        </label>
                                                        <label className='para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="classified_precarious"
                                                                id="classified_precarious"
                                                                value={values.classified_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                            <input
                                                                type='number'
                                                                name="highly_precarious"
                                                                id="highly_precarious"
                                                                value={values.highly_precarious}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                            <input
                                                                type='number'
                                                                name="without_proof"
                                                                id="without_proof"
                                                                value={values.without_proof}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control1" placeholder='0'></input>
                                                        </label>
                                                        <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Copriete */}
                                            {values.beneficiaryOperation === 'Copropriété privée' && (
                                                <div className='bar1-content mt-4 pb-4'>
                                                    <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                                    <div className=''>
                                                        <label className=' para-bar1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Oui"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Oui"} />
                                                            <span className='bar1-para span-bar'>Oui</span>
                                                        </label>
                                                        <label className=' para-bar1 mt-1 d-block'>
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="district_city_policy"
                                                                id="district_city_policy"
                                                                value="Non"
                                                                onChange={handleChange}
                                                                checked={values.district_city_policy === "Non"} />
                                                            <span className='span-bar bar1-para'>Non</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            <p className="bar1-head  mt-4">Opération dans un bâtiment RESIDENTIEL existant depuis plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="residentieltwoyears"
                                                        id="residentieltwoyears"
                                                        onChange={handleChange}
                                                        checked={values.residentieltwoyears === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="residentieltwoyears"
                                                        id="residentieltwoyears"
                                                        onChange={handleChange}
                                                        checked={values.residentieltwoyears === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Les canalisations existantes sont déjà isolées ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="pipes_insulated"
                                                        id="pipes_insulated"
                                                        onChange={handleChange}
                                                        checked={values.pipes_insulated === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="pipes_insulated"
                                                        id="pipes_insulated"
                                                        onChange={handleChange}
                                                        checked={values.pipes_insulated === "Non"} />
                                                    <span className='span-bar bar1-para '>Non</span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Les points singuliers à isoler sont tous situés dans une chaufferie ou dans une sous station ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='OUI'
                                                        name="singular_points"
                                                        id="singular_points"
                                                        onChange={handleChange}
                                                        checked={values.singular_points === "OUI"} />
                                                    <span className='span-bar bar1-para'>
                                                        OUI
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='NON'
                                                        name="singular_points"
                                                        id="singular_points"
                                                        onChange={handleChange}
                                                        checked={values.singular_points === "NON"} />
                                                    <span className='span-bar bar1-para '>
                                                        NON
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Les housses sont souples, démontables et équipées d’un système de fermeture ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='OUI'
                                                        name="covers_system"
                                                        id="covers_system"
                                                        onChange={handleChange}
                                                        checked={values.covers_system === "OUI"} />
                                                    <span className='span-bar bar1-para'>
                                                        OUI
                                                    </span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='NON'
                                                        name="covers_system"
                                                        id="covers_system"
                                                        onChange={handleChange}
                                                        checked={values.covers_system === "NON"} />
                                                    <span className='span-bar bar1-para '>
                                                        NON
                                                    </span>
                                                </label> </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">La housse est constituée d’un isolant à base laine de verre ou de roche ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='OUI'
                                                        name="cover_insulation"
                                                        id="cover_insulation"
                                                        onChange={handleChange}
                                                        checked={values.cover_insulation === "OUI"} />
                                                    <span className='span-bar bar1-para'>
                                                        OUI
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='NON'
                                                        name="cover_insulation"
                                                        id="cover_insulation"
                                                        onChange={handleChange}
                                                        checked={values.cover_insulation === "NON"} />
                                                    <span className='span-bar bar1-para '>
                                                        NON
                                                    </span>
                                                </label></div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <div className='button-1 d-flex'>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                <h3 className="bar1-head ms-2 mt-3 ">Pour un réseau de fluide d’une température nominale entre 50° et 120° Combien de points singuliers sont à isoler ? </h3> </div>

                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 20 mm et 65 mm (Qt) :
                                                <input type='number'
                                                    name='diameter20to65V1'
                                                    id='diameter20to65V1'
                                                    value={values.diameter20to65V1}
                                                    onChange={handleChange}
                                                    className="form-control1"></input></p>
                                            {/* {touched.diameter20to65V1 && errors.diameter20to65V1 && <div className="error-message">{errors.diameter20to65V1}</div>} */}

                                            <hr />
                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 66 mm et 100 mm (Qt) : <input
                                                type='number'
                                                name='diameter66to100V1'
                                                id='diameter66to100V1'
                                                value={values.diameter66to100V1}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control1" ></input>

                                                {/* {touched.diameter66to100V1 && errors.diameter66to100V1 && <div className="error-message">{errors.diameter66to100V1}</div>} */}
                                            </p><hr />
                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 101 mm et 150 mm (Qt) : <input
                                                type='number'
                                                name='diameter101to150V1'
                                                id='diameter101to150V1'
                                                value={values.diameter101to150V1}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control1" ></input>

                                                {touched.diameter101to150V1 && errors.diameter101to150V1 && <div className="error-message">{errors.diameter101to150V1}</div>}
                                            </p><hr />
                                            <p className='bar1-head mt-3'>Sur échangeur à plaque (Qt) :
                                                <input
                                                    type='number'
                                                    name='heatexchangerV1'
                                                    id='heatexchangerV1'
                                                    value={values.heatexchangerV1}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input></p><hr />
                                            <div className='button-1 d-flex'>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                <h3 className="bar1-head ms-2 mt-3 ">Pour un réseau de fluide d’une température nominale supérieure à 120°
                                                    Combien de points singuliers sont à isoler ?</h3> </div>

                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 20 mm et 65 mm (Qt) :
                                                <input type='number'
                                                    name='diameter20to65V2'
                                                    id='diameter20to65V2'
                                                    value={values.diameter20to65V2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input>
                                                {/* {touched.diameter20to65V2 && errors.diameter20to65V2 && <div className="error-message">{errors.diameter20to65V2}</div>} */}

                                            </p><hr />
                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 66 mm et 100 mm (Qt) :
                                                <input type='number'
                                                    name='diameter66to100V2'
                                                    id='diameter66to100V2'
                                                    value={values.diameter66to100V2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input>
                                                {/* {touched.diameter66to100V2 && errors.diameter66to100V2 && <div className="error-message">{errors.diameter66to100V2}</div>} */}

                                            </p><hr />
                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 101 mm et 150 mm (Qt) :
                                                <input type='number'
                                                    name='diameter101to150V2'
                                                    id='diameter101to150V2'
                                                    value={values.diameter101to150V2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input>
                                                {touched.diameter101to150V2 && errors.diameter101to150V2 && <div className="error-message">{errors.diameter101to150V2}</div>}

                                            </p><hr />
                                            <p className='bar1-head mt-3'>Sur échangeur à plaque (Qt) :
                                                <input type='number'
                                                    name='heatexchangerV2'
                                                    id='heatexchangerV2'
                                                    value={values.heatexchangerV2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input></p><hr />

                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAT-EN-101' */}
                            {values.b2boperations === 'Tertiaire' && values.documents === 'BAT-EN-101' ?
                                <div className='container-fluid   '>
                                    <div className='row'>
                                        <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Isolation d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire </p>
                                        <div className='col'>
                                            <p className="bar1-head  mt-4">Bâtiment secteur tertiaire existant depuis plus de 2 ans ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="tertiarysector"
                                                        id="tertiarysector"
                                                        value="Oui"
                                                        onChange={handleChange}
                                                        checked={values.tertiarysector === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="tertiarysector"
                                                        id="tertiarysector"
                                                        value="Non"
                                                        onChange={handleChange}
                                                        checked={values.tertiarysector === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>

                                            <p className="bar1-head  mt-4">L’isolation est effectuée entre une zone chauffée et une zone non chauffée ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="Oui"
                                                        name="thermalinsulation"
                                                        id="thermalinsulation"
                                                        onChange={handleChange}
                                                        checked={values.thermalinsulation === "Oui"} />
                                                    <span className='span-bar bar1-para'> Oui</span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="Non"
                                                        name="thermalinsulation"
                                                        id="thermalinsulation"
                                                        onChange={handleChange}
                                                        checked={values.thermalinsulation === "Non"} />
                                                    <span className='span-bar bar1-para '> Non</span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Type de pose ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="typeofpose"
                                                        id="typeofpose"
                                                        value="floor of an undeveloped space"
                                                        onChange={handleChange}
                                                        checked={values.typeofpose === "floor of an undeveloped space"} />
                                                    <span className='span-bar bar1-para'>
                                                        en comble perdu uniquement (ex : sol d’un espace non-aménagé)
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="typeofpose"
                                                        id="typeofpose"
                                                        value="sloping wall of the roof"
                                                        onChange={handleChange}
                                                        checked={values.typeofpose === "sloping wall of the roof"} />
                                                    <span className='span-bar bar1-para '>
                                                        en rampant de toiture uniquement (ex : paroi inclinée du toit)
                                                    </span>
                                                </label>

                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="typeofpose"
                                                        id="typeofpose"
                                                        value="lost attic and roof slope"
                                                        onChange={handleChange}
                                                        checked={values.typeofpose === "lost attic and roof slope"} />
                                                    <span className='span-bar bar1-para '>
                                                        en comble perdu et en rampant de toiture
                                                    </span>
                                                </label>

                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Secteur d'activité du bâtiment :</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Bureaux"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Bureaux"} />
                                                    <span className='span-bar bar1-para'>
                                                        Bureaux
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Enseignement"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Enseignement"} />
                                                    <span className='span-bar bar1-para '>
                                                        Enseignement
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Commerces"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Commerces"} />
                                                    <span className='span-bar bar1-para '>
                                                        Commerces
                                                    </span>
                                                </label>  </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Hôtellerie - Restauration"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Hôtellerie - Restauration"} />
                                                    <span className='span-bar bar1-para '>
                                                        Hôtellerie / Restauration
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Santé"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Santé"} />
                                                    <span className='span-bar bar1-para '>
                                                        Santé
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Autres secteurs tertiaires"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Autres secteurs tertiaires"} />
                                                    <span className='span-bar bar1-para '> Autres secteurs tertiaires </span>
                                                </label>
                                            </div>

                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div>
                                            <hr></hr>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p className='bar1-head mt-3'>Surface totale isolée (en m²)* :</p></div>
                                                <div className="col-4 text-start ">
                                                    <input
                                                        type="number"
                                                        name="surface_area"
                                                        id="surface_area"
                                                        value={values.surface_area}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1'}
                                                        placeholder='0' ></input>
                                                    {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}
                                                </div>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">Pour lutter contre la fraude, le début des travaux ne peut commencer que 8 jours après la date de commande ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="startofwork"
                                                        id="startofwork"
                                                        value="Oui"
                                                        onChange={handleChange}
                                                        checked={values.startofwork === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui, un délai minimum de 8 jours est/sera respecté
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="startofwork"
                                                        id="startofwork"
                                                        value="Non"
                                                        onChange={handleChange}
                                                        checked={values.startofwork === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non, la pose est/sera réalisée moins de 8 jours après la commande
                                                    </span>
                                                </label>
                                            </div><hr />
                                            <p className="bar1-head  mt-4">Aucune opération identique n’a été réalisée sur ce bâtiment depuis douze mois ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="twelvemonths"
                                                        id="twelvemonths"
                                                        value="Oui"
                                                        onChange={handleChange}
                                                        checked={values.twelvemonths === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui, aucune opération identique sur ce bâtiment depuis douze mois
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="twelvemonths"
                                                        id="twelvemonths"
                                                        value="Non"
                                                        onChange={handleChange}
                                                        checked={values.twelvemonths === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non, une opération identique a été réalisée il y a moins de douze mois sur ce bâtiment
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAT-EN-102 */}
                            {values.b2boperations === 'Tertiaire' && values.documents === 'BAT-EN-102' ?
                                <div className='container-fluid  '>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>BAT-EN-102 : Isolation des murs</p>
                                            <p className="bar1-head  mt-4">Bâtiment secteur tertiaire existant depuis plus de 2 ans ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="tertiarysector"
                                                        id="tertiarysector"
                                                        value="Oui"
                                                        onChange={handleChange}
                                                        checked={values.tertiarysector === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="tertiarysector"
                                                        id="tertiarysector"
                                                        value="Non"
                                                        onChange={handleChange}
                                                        checked={values.tertiarysector === "Non"} />
                                                    <span className='span-bar bar1-para'>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p className='bar1-head mt-3'>Surface totale isolée (en m²) :</p></div>
                                                <div className="col-4 text-start ">
                                                    <input
                                                        type="number"
                                                        name="surface_area"
                                                        id="surface_area"
                                                        value={values.surface_area}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1'}
                                                        placeholder='0' ></input>
                                                    {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>

                                            <p className="bar1-head  mt-4">Énergie de chauffage utilisée : </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="heatingEnergy"
                                                        id="heatingEnergy"
                                                        value="Electricité"
                                                        onChange={handleChange}
                                                        checked={values.heatingEnergy === "Electricité"} />
                                                    <span className='span-bar bar1-para'>
                                                        Electricité
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="heatingEnergy"
                                                        id="heatingEnergy"
                                                        value="Gaz"
                                                        onChange={handleChange}
                                                        checked={values.heatingEnergy === "Gaz"} />
                                                    <span className='span-bar bar1-para '>
                                                        Gaz (de ville, butane, propane...)
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="heatingEnergy"
                                                        id="heatingEnergy"
                                                        value="Fioul"
                                                        onChange={handleChange}
                                                        checked={values.heatingEnergy === "Fioul"} />
                                                    <span className='span-bar bar1-para '>
                                                        Fioul
                                                    </span>
                                                </label> </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="heatingEnergy"
                                                        id="heatingEnergy"
                                                        value="Bois"
                                                        onChange={handleChange}
                                                        checked={values.heatingEnergy === "Bois"} />
                                                    <span className='span-bar bar1-para '> Bois (biomasse) </span>
                                                </label></div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Secteur d'activité du bâtiment :</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Bureaux"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Bureaux"} />
                                                    <span className='span-bar bar1-para'>
                                                        Bureaux
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Enseignement"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Enseignement"} />
                                                    <span className='span-bar bar1-para '>
                                                        Enseignement
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Commerces"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Commerces"} />
                                                    <span className='span-bar bar1-para '>
                                                        Commerces
                                                    </span>
                                                </label>  </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Hôtellerie - Restauration"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Hôtellerie - Restauration"} />
                                                    <span className='span-bar bar1-para '>
                                                        Hôtellerie / Restauration
                                                    </span>
                                                </label>

                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Santé"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Santé"} />
                                                    <span className='span-bar bar1-para '>
                                                        Santé
                                                    </span>
                                                </label>

                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Autres secteurs tertiaires"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Autres secteurs tertiaires"} />
                                                    <span className='span-bar bar1-para '> Autres secteurs tertiaires </span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAT-EN-103 */}
                            {values.b2boperations === 'Tertiaire' && values.documents === 'BAT-EN-103' ?
                                <div className='container-fluid '>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>BAT-EN-103 : Isolation d’un plancher </p>
                                            <p className="bar1-head  mt-4">Bâtiment secteur tertiaire existant depuis plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="tertiarysector"
                                                        id="tertiarysector"
                                                        onChange={handleChange}
                                                        checked={values.tertiarysector === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="tertiarysector"
                                                        id="tertiarysector"
                                                        onChange={handleChange}
                                                        checked={values.tertiarysector === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">L’isolation est effectuée entre une zone chauffée et une zone non chauffée ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="Oui"
                                                        name="thermalinsulation"
                                                        id="thermalinsulation"
                                                        onChange={handleChange}
                                                        checked={values.thermalinsulation === "Oui"} />
                                                    <span className='span-bar bar1-para'> Oui</span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value="Non"
                                                        name="thermalinsulation"
                                                        id="thermalinsulation"
                                                        onChange={handleChange}
                                                        checked={values.thermalinsulation === "Non"} />
                                                    <span className='span-bar bar1-para '> Non</span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <p className="bar1-head  mt-4">Secteur d'activité du bâtiment :</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Bureaux"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Bureaux"} />
                                                    <span className='span-bar bar1-para'>
                                                        Bureaux
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Enseignement"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Enseignement"} />
                                                    <span className='span-bar bar1-para '>
                                                        Enseignement
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Commerces"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Commerces"} />
                                                    <span className='span-bar bar1-para '>
                                                        Commerces
                                                    </span>
                                                </label>  </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Hôtellerie - Restauration"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Hôtellerie - Restauration"} />
                                                    <span className='span-bar bar1-para '>
                                                        Hôtellerie / Restauration
                                                    </span>
                                                </label>

                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Santé"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Santé"} />
                                                    <span className='span-bar bar1-para '>
                                                        Santé
                                                    </span>
                                                </label>

                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Autres secteurs tertiaires"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Autres secteurs tertiaires"} />
                                                    <span className='span-bar bar1-para '> Autres secteurs tertiaires </span>
                                                </label>
                                            </div>

                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p className='bar1-head mt-3'>Surface totale isolée (en m²) :</p></div>
                                                <div className="col-4 text-start "><input
                                                    type="number"
                                                    name="surface_area"
                                                    id="surface_area"
                                                    value={values.surface_area}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1'}
                                                    placeholder='0' ></input>
                                                    {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">Pour lutter contre la fraude, le début des travaux ne peut commencer que 8 jours après la date de commande ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="startofwork"
                                                        id="startofwork"
                                                        value="Oui"
                                                        onChange={handleChange}
                                                        checked={values.startofwork === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui, un délai minimum de 8 jours est/sera respecté
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="startofwork"
                                                        id="startofwork"
                                                        value="Non"
                                                        onChange={handleChange}
                                                        checked={values.startofwork === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non, la pose est/sera réalisée moins de 8 jours après la commande
                                                    </span>
                                                </label>
                                            </div><hr />
                                            <p className="bar1-head  mt-4">Aucune opération identique n’a été réalisée sur ce bâtiment depuis douze mois ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="twelvemonths"
                                                        id="twelvemonths"
                                                        value="Oui"
                                                        onChange={handleChange}
                                                        checked={values.twelvemonths === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui, aucune opération identique sur ce bâtiment depuis douze mois
                                                    </span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="twelvemonths"
                                                        id="twelvemonths"
                                                        value="Non"
                                                        onChange={handleChange}
                                                        checked={values.twelvemonths === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non, une opération identique a été réalisée il y a moins de douze mois sur ce bâtiment
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAT-TH-116 */}
                            {values.b2boperations === 'Tertiaire' && values.documents === 'BAT-TH-116' ?
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Système de gestion technique du bâtiment tertiaire</p>
                                            <p className='bar1-head mt-3'>Date du devis de signature :
                                                <input type="date"
                                                    name="signature"
                                                    id="signature"
                                                    value={values.signature}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input></p><hr></hr>
                                            <p className="bar1-head  mt-4">Bâtiment tertiaire existant depuis plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="tertiarysector"
                                                        id="tertiarysector"
                                                        value="Oui"
                                                        onChange={handleChange}
                                                        checked={values.tertiarysector === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="tertiarysector"
                                                        id="tertiarysector"
                                                        value="Non"
                                                        onChange={handleChange}
                                                        checked={values.tertiarysector === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <div className='button-1'>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">S’agit-il d’un simple raccordement ou extension d’un bâtiment à un système de GTB existant ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="bmssystem"
                                                        id="bmssystem"
                                                        onChange={handleChange}
                                                        checked={values.bmssystem === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="bmssystem"
                                                        id="bmssystem"
                                                        onChange={handleChange}
                                                        checked={values.bmssystem === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">L’opération concerne ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='BMS system'
                                                        name="operationconcern"
                                                        id="operationconcern"
                                                        onChange={handleChange}
                                                        checked={values.operationconcern === "BMS system"} />
                                                    <span className='span-bar bar1-para'> L’achat d’un système neuf de GTB  </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='2 years of BMS'
                                                        name="operationconcern"
                                                        id="operationconcern"
                                                        onChange={handleChange}
                                                        checked={values.operationconcern === "2 years of BMS"} />
                                                    <span className='span-bar bar1-para '>
                                                        L’amélioration d’un système existant de + de 2 ans de GTB </span>
                                                </label>
                                            </div>

                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div>
                                                <p className="bar1-head  mt-4">Le nouveau système de GTB est ? </p>
                                                <div className='text-start'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value='De classe A'
                                                            name="newBMSsystem"
                                                            id="newBMSsystem"
                                                            onChange={handleChange}
                                                            checked={values.newBMSsystem === "De classe A"} />
                                                        <span className='span-bar bar1-para'>De classe A </span></label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value='De classe B'
                                                            name="newBMSsystem"
                                                            id="newBMSsystem"
                                                            onChange={handleChange}
                                                            checked={values.newBMSsystem === "De classe B"} />
                                                        <span className='span-bar bar1-para '> De classe B</span>
                                                    </label>

                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value='Autre'
                                                            name="newBMSsystem"
                                                            id="newBMSsystem"
                                                            onChange={handleChange}
                                                            checked={values.newBMSsystem === "Autre"} />
                                                        <span className='span-bar bar1-para'>Autre </span> </label>
                                                </div>
                                                <div className='button-1 '>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </div><hr></hr></div>
                                            <div>
                                                <p className="bar1-head  mt-4">Secteur d'activité du bâtiment ? </p>
                                                <div className='text-start'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Bureaux"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Bureaux"} />
                                                        <span className='span-bar bar1-para'>
                                                            Bureaux
                                                        </span></label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Enseignement"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Enseignement"} />
                                                        <span className='span-bar bar1-para '>
                                                            Enseignement
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Commerces"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Commerces"} />
                                                        <span className='span-bar bar1-para '>
                                                            Commerces
                                                        </span>
                                                    </label>  </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Hôtellerie - Restauration"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Hôtellerie - Restauration"} />
                                                        <span className='span-bar bar1-para '>
                                                            Hôtellerie / Restauration
                                                        </span>
                                                    </label>

                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Santé"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Santé"} />
                                                        <span className='span-bar bar1-para '>
                                                            Santé
                                                        </span>
                                                    </label>

                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Autres secteurs tertiaires"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Autres secteurs tertiaires"} />
                                                        <span className='span-bar bar1-para '> Autres secteurs tertiaires </span>
                                                    </label>
                                                </div>
                                                <div className='button-1 '>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </div><hr></hr>
                                                <p className='bar1-head mt-3'>Usages et surfaces gérés par le système GTB :</p>
                                                <div className='button-1 '>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </div><hr></hr>
                                            </div>

                                            <div className='bar1-content mt-4 p-2'>
                                                <div className="row row-cols-lg-2">
                                                    <div className="col p-3"><span className='bar1-head '>Usage géré par le système GTB* :</span></div>
                                                    <div className="col p-3">
                                                        <select
                                                            name='managedBMSsystem'
                                                            id='managedBMSsystem'
                                                            value={values.managedBMSsystem}
                                                            onChange={handleChange}
                                                            className={touched.managedBMSsystem && errors.managedBMSsystem ? 'error' : 'form-select w-100 btn-buttons width-of-select '} aria-label="Sélectionnez un usage">
                                                            <option selected >-- Sélectionnez un usage --</option>
                                                            <option value="Chauffage">Chauffage</option>
                                                            <option value="ECS">Eau chaude sanitaire</option>
                                                            <option value="Refroidissement Climatisation">Refroidissement / climatisation</option>
                                                            <option value="Eclairage">Eclairage</option>
                                                            <option value="Auxiliaire">Auxiliaires</option>
                                                        </select>
                                                        {touched.managedBMSsystem && errors.managedBMSsystem && <div className="error-message d-block">{errors.managedBMSsystem}</div>}
                                                    </div>
                                                    <div className="col p-3"><span className='bar1-head '>Surface pour cet usage (en m²)* :</span></div>
                                                    <div className="col p-3"> <input
                                                        type="number"
                                                        name="surface_area"
                                                        id="surface_area"
                                                        value={values.surface_area}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1 w-100'} ></input>
                                                        {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}</div>
                                                </div>
                                            </div>
                                            <div className='text-start mt-3'>
                                                <button type='button' className='btns-color mt-1'  >
                                                    Ajouter un nouveau
                                                </button>
                                            </div>

                                            <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                            <div className='text-start'>
                                                <label>
                                                    <input
                                                        name="premiumamount"
                                                        id="premiumamount"
                                                        value={values.premiumamount}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="input-box" placeholder='3' ></input>
                                                    <span className='span-bar bar1-para' > Euros du Méga / Prix du kilowater </span>
                                                </label>
                                            </div><hr></hr>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAT-TH-146 */}
                            {values.b2boperations === 'Tertiaire' && values.documents === 'BAT-TH-146' ?
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Calorifugeage de réseau de chauffage ou ECS</p>
                                            <p className="bar1-head  mt-4">Bâtiment(s) tertiaire(s) et existant(s) depuis plus de 2 ans ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="tertiarysector"
                                                        id="tertiarysector"
                                                        value="Oui"
                                                        onChange={handleChange}
                                                        checked={values.tertiarysector === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="tertiarysector"
                                                        id="tertiarysector"
                                                        value="Non"
                                                        onChange={handleChange}
                                                        checked={values.tertiarysector === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">La chaudière ou le réseau hydraulique a été refait/remplacé après le 01/01/2018 ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="replaced_after_2018"
                                                        id="replaced_after_2018"
                                                        onChange={handleChange}
                                                        checked={values.replaced_after_2018 === "Oui"} />
                                                    <span className='span-bar bar1-para'> Oui</span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="replaced_after_2018"
                                                        id="replaced_after_2018"
                                                        onChange={handleChange}
                                                        checked={values.replaced_after_2018 === "Non"} />
                                                    <span className='span-bar bar1-para '>Non, aucune modification après le 01/01/2018</span>
                                                </label></div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Une ancienne isolation existe sur ces canalisations ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="oldinsulation"
                                                        id="oldinsulation"
                                                        onChange={handleChange}
                                                        checked={values.oldinsulation === "Oui"} />
                                                    <span className='span-bar bar1-para'> Oui (totalement ou partiellement isolés)</span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="oldinsulation"
                                                        id="oldinsulation"
                                                        onChange={handleChange}
                                                        checked={values.oldinsulation === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>

                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div>
                                                <div className="row align-items-center">
                                                    <div className="col-lg-8 col-sm-12">
                                                        <p className='bar1-head mt-3'>Longueur totale du (ou des) réseau(x) hydraulique(s) isolé(s) (en m linéaire)* :</p>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-12">
                                                        <input
                                                            type="number"
                                                            name='hydraulicnetwork'
                                                            id='hydraulicnetwork'
                                                            value={values.hydraulicnetwork}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={touched.hydraulicnetwork && errors.hydraulicnetwork ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                        {touched.hydraulicnetwork && errors.hydraulicnetwork && <div className="error-message">{errors.hydraulicnetwork}</div>}
                                                    </div>
                                                </div>
                                                <div className='button-1 '>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </div><hr></hr>
                                            </div>
                                            <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                            <div className='text-start'>
                                                <label>
                                                    <input
                                                        type="text"
                                                        name='primeamount'
                                                        id='primeamount'
                                                        value={values.primeamount}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="input-box"  ></input>
                                                    <span className='span-bar bar1-para '>Euros du Méga / Prix du kilowater</span>
                                                </label>
                                            </div><hr></hr>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAT-TH-104 */}
                            {values.b2boperations === 'Tertiaire' && values.documents === 'BAT-TH-104' ?
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>BAT-TH-104 : Robinet thermostatique</p>
                                            <p className="bar1-head  mt-4">Bâtiment Tertiaire de plus de 2 ans ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="tertiaryBuilding"
                                                        id="tertiaryBuilding"
                                                        value="Oui"
                                                        onChange={handleChange}
                                                        checked={values.tertiaryBuilding === "Oui"} />
                                                    <span className='span-bar bar1-para'> Oui </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="tertiaryBuilding"
                                                        id="tertiaryBuilding"
                                                        value="Non"
                                                        onChange={handleChange}
                                                        checked={values.tertiaryBuilding === "Non"} />
                                                    <span className='span-bar bar1-para '> Non </span>
                                                </label>
                                            </div>
                                            <div className='button-1'>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <p className="bar1-head  mt-4">Installation sur des radiateurs neufs ?
                                            </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="newradiators"
                                                        id="newradiators"
                                                        value="OUI"
                                                        onChange={handleChange}
                                                        checked={values.newradiators === "OUI"} />
                                                    <span className='span-bar bar1-para'>
                                                        OUI, les radiateurs ont moins de 2 ans  </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="newradiators"
                                                        id="newradiators"
                                                        value="NON"
                                                        onChange={handleChange}
                                                        checked={values.newradiators === "NON"} />
                                                    <span className='span-bar bar1-para'>
                                                        NON, les radiateurs ont plus de 2 ans
                                                    </span>
                                                </label> </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">La chaudière est existante depuis plus de 2 ans ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input" type="radio"
                                                        name="boiler"
                                                        id="boiler"
                                                        value="OUI"
                                                        onChange={handleChange}
                                                        checked={values.boiler === "OUI"} />
                                                    <span className='span-bar bar1-para'>OUI, plus de deux ans </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input" type="radio"
                                                        name="boiler"
                                                        id="boiler"
                                                        value="NON"
                                                        onChange={handleChange}
                                                        checked={values.boiler === "NON"} />
                                                    <span className='span-bar bar1-para '>
                                                        NON, moins de deux ans
                                                    </span>
                                                </label>
                                            </div>

                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div>
                                                <p className="bar1-head  mt-4">Énergie de chauffage utilisée ?</p>
                                                <div className='text-start'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="heatingEnergy"
                                                            id="heatingEnergy"
                                                            value="Electricité"
                                                            onChange={handleChange}
                                                            checked={values.heatingEnergy === "Electricité"} />
                                                        <span className='span-bar bar1-para'>
                                                            Electricité
                                                        </span></label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="heatingEnergy"
                                                            id="heatingEnergy"
                                                            value="Gaz"
                                                            onChange={handleChange}
                                                            checked={values.heatingEnergy === "Gaz"} />
                                                        <span className='span-bar bar1-para '>
                                                            Gaz (de ville, butane, propane...)
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="heatingEnergy"
                                                            id="heatingEnergy"
                                                            value="Fioul"
                                                            onChange={handleChange}
                                                            checked={values.heatingEnergy === "Fioul"} />
                                                        <span className='span-bar bar1-para '>
                                                            Fioul
                                                        </span>
                                                    </label> </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="heatingEnergy"
                                                            id="heatingEnergy"
                                                            value="Bois"
                                                            onChange={handleChange}
                                                            checked={values.heatingEnergy === "Bois"} />
                                                        <span className='span-bar bar1-para '> Bois (biomasse) </span>
                                                    </label></div>
                                                <div className='button-1 '>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </div><hr></hr></div>
                                            <div>
                                                <p className="bar1-head  mt-4">Secteur d'activité du bâtiment ? </p>
                                                <div className='text-start'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Bureaux"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Bureaux"} />
                                                        <span className='span-bar bar1-para'>
                                                            Bureaux
                                                        </span></label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Enseignement"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Enseignement"} />
                                                        <span className='span-bar bar1-para '>
                                                            Enseignement
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Commerces"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Commerces"} />
                                                        <span className='span-bar bar1-para '>
                                                            Commerces
                                                        </span>
                                                    </label>  </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Hôtellerie - Restauration"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Hôtellerie - Restauration"} />
                                                        <span className='span-bar bar1-para '>
                                                            Hôtellerie / Restauration
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Santé"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Santé"} />
                                                        <span className='span-bar bar1-para '>
                                                            Santé
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            value="Autres secteurs tertiaires"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Autres secteurs tertiaires"} />
                                                        <span className='span-bar bar1-para '> Autres secteurs tertiaires </span>
                                                    </label>
                                                </div>
                                                <div className='button-1 '>
                                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                </div><hr></hr>
                                                <div className="row">
                                                    <div className="col"><p className='bar1-head mt-3'>Surface chauffée par les radiateurs équipés de robinets thermostatiques (en m²)</p></div>
                                                    <div className="col"> <input
                                                        type="number"
                                                        name="surface_area"
                                                        id="surface_area"
                                                        value={values.surface_area}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.surface_area && errors.surface_area ? 'error' : 'form-control1'}
                                                        placeholder='0' ></input>
                                                        {touched.surface_area && errors.surface_area && <div className="error-message d-block">{errors.surface_area}</div>}</div>
                                                </div>
                                                <hr></hr>
                                            </div>
                                            <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                            <div className='text-start'>
                                                <label >
                                                    <input
                                                        type="text"
                                                        name='premiumamount'
                                                        id='premiumamount'
                                                        value={values.premiumamount}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="input-box" ></input>
                                                    <span className='span-bar bar1-para '>Euros du Méga / Prix du kilowater</span>
                                                </label>
                                            </div><hr></hr>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAT-EQ-133 */}
                            {values.b2boperations === 'Tertiaire' && values.documents === 'BAT-EQ-133' ?
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para'>Calculez et obtenez votre prime / Votre projet : Systèmes hydro-économes (France métropolitaine)</p>
                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal du bâtiment tertiaire* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>

                                            <hr></hr>
                                            <div>
                                                <p className="bar1-head  mt-4">Secteur d'activité du bâtiment ? </p>
                                                <div className='text-start'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value='Bureaux'
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Bureaux"} />
                                                        <span className='span-bar bar1-para'>Bureaux</span></label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            value='Enseignement'
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Enseignement"} />
                                                        <span className='span-bar bar1-para '> Enseignement </span>
                                                    </label>

                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input" type="radio"
                                                            value='Hotelleric et habitat communautaire'
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Hotelleric et habitat communautaire"} />
                                                        <span className='span-bar bar1-para'>Hotelleric et habitat communautaire</span></label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 '>
                                                        <input className="form-check-input" type="radio"
                                                            value='Sante'
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Sante"} />
                                                        <span className='span-bar bar1-para'> Santé</span></label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input" type="radio"
                                                            value='Etablissements sportifs'
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Etablissements sportifs"} />
                                                        <span className='span-bar bar1-para '>
                                                            Etablissements sportifs </span>
                                                    </label>
                                                </div>
                                                <div className='text-start mt-2'>
                                                    <label className='para-bar1 text-initical'>
                                                        <input className="form-check-input" type="radio"
                                                            value='Autres secteurs'
                                                            name="buildingindustry"
                                                            id="buildingindustry"
                                                            onChange={handleChange}
                                                            checked={values.buildingindustry === "Autres secteurs"} />
                                                        <span className='span-bar bar1-para '> Autres secteurs  </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='button-1'>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className='bar1-head mt-3'>Nombre de pommes de douche de classe Z installées*
                                                <input
                                                    type="number"
                                                    name='classZshowerheads'
                                                    id='classZshowerheads'
                                                    value={values.classZshowerheads}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1'}></input>
                                                {touched.classZshowerheads && errors.classZshowerheads && <div className="error-message">{errors.classZshowerheads}</div>}
                                            </p>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-head mt-3'>Nombre de pommes de douche de classe ZZ ou « WaterSense » installées*
                                                <input
                                                    type="number"
                                                    name='classZZshowerheads'
                                                    id='classZZshowerheads'
                                                    value={values.classZZshowerheads}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1'}></input>
                                                {touched.classZZshowerheads && errors.classZZshowerheads && <div className="error-message">{errors.classZZshowerheads}</div>}</p>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-head mt-3'>Nombre d’aérateurs de robinet non régulés de classe Z installés*
                                                <input
                                                    type="number"
                                                    name='classZunregulated'
                                                    id='classZunregulated'
                                                    value={values.classZunregulated}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.classZunregulated && errors.classZunregulated ? 'error' : 'form-control1'}></input>
                                                {touched.classZunregulated && errors.classZunregulated && <div className="error-message">{errors.classZunregulated}</div>}
                                            </p>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div>
                                            <hr></hr>
                                            <p className='bar1-head mt-3'>Nombre d’aérateurs de robinet autorégulés installés*
                                                <input type="number"
                                                    name='classZZunregulated'
                                                    id='classZZunregulated'
                                                    value={values.classZZunregulated}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.classZZunregulated && errors.classZZunregulated ? 'error' : 'form-control1'} />
                                                {touched.classZZunregulated && errors.classZZunregulated && <div className="error-message">{errors.classZZunregulated}</div>}</p>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div>
                                            <hr></hr><p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                            <div className='text-start'>
                                                <label >
                                                    <input
                                                        type='number'
                                                        name='kilowater'
                                                        id='kilowater'
                                                        value={values.kilowater}
                                                        onChange={handleChange}
                                                        className="input-box" placeholder='3' ></input>
                                                    <span className='span-bar bar1-para '>Euros du Méga / Prix du kilowater</span>
                                                </label>
                                            </div><hr></hr>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* BAT-TH-155 */}
                            {values.b2boperations === 'Tertiaire' && values.documents === 'BAT-TH-155' ?
                                <div className='container-fluid '>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>BAT-TH-155 : Isolation de points singuliers d’un réseau</p>
                                            <p className="bar1-head  mt-4">Opération dans un bâtiment TERTIAIRE existant depuis plus de 2 ans ?  </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="residentieltwoyears"
                                                        id="residentieltwoyears"
                                                        onChange={handleChange}
                                                        checked={values.residentieltwoyears === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="residentieltwoyears"
                                                        id="residentieltwoyears"
                                                        onChange={handleChange}
                                                        checked={values.residentieltwoyears === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-sm-12">
                                                    <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Secteur d'activité du bâtiment ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Bureaux"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Bureaux"} />
                                                    <span className='span-bar bar1-para'>
                                                        Bureaux
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Enseignement"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Enseignement"} />
                                                    <span className='span-bar bar1-para '>
                                                        Enseignement
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Commerces"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Commerces"} />
                                                    <span className='span-bar bar1-para '>
                                                        Commerces
                                                    </span>
                                                </label>  </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Hôtellerie - Restauration"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Hôtellerie - Restauration"} />
                                                    <span className='span-bar bar1-para '>
                                                        Hôtellerie / Restauration
                                                    </span>
                                                </label>

                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="buildingindustry"
                                                        id="buildingindustry"
                                                        value="Santé"
                                                        onChange={handleChange}
                                                        checked={values.buildingindustry === "Santé"} />
                                                    <span className='span-bar bar1-para '>
                                                        Santé
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Les canalisations existantes sont déjà isolées ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="pipes_insulated"
                                                        id="pipes_insulated"
                                                        onChange={handleChange}
                                                        checked={values.pipes_insulated === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="pipes_insulated"
                                                        id="pipes_insulated"
                                                        onChange={handleChange}
                                                        checked={values.pipes_insulated === "Non"} />
                                                    <span className='span-bar bar1-para '>Non</span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Les points singuliers à isoler sont tous situés dans une chaufferie ou dans une sous station ? </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='OUI'
                                                        name="singular_points"
                                                        id="singular_points"
                                                        onChange={handleChange}
                                                        checked={values.singular_points === "OUI"} />
                                                    <span className='span-bar bar1-para'>
                                                        OUI
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='NON'
                                                        name="singular_points"
                                                        id="singular_points"
                                                        onChange={handleChange}
                                                        checked={values.singular_points === "NON"} />
                                                    <span className='span-bar bar1-para '>
                                                        NON
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">Les housses sont souples, démontables et équipées d’un système de fermeture ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='OUI'
                                                        name="covers_system"
                                                        id="covers_system"
                                                        onChange={handleChange}
                                                        checked={values.covers_system === "OUI"} />
                                                    <span className='span-bar bar1-para'>
                                                        OUI
                                                    </span> </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='NON'
                                                        name="covers_system"
                                                        id="covers_system"
                                                        onChange={handleChange}
                                                        checked={values.covers_system === "NON"} />
                                                    <span className='span-bar bar1-para '>
                                                        NON
                                                    </span>
                                                </label> </div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <p className="bar1-head  mt-4">La housse est constituée d’un isolant à base laine de verre ou de roche ?</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='OUI'
                                                        name="cover_insulation"
                                                        id="cover_insulation"
                                                        onChange={handleChange}
                                                        checked={values.cover_insulation === "OUI"} />
                                                    <span className='span-bar bar1-para'>
                                                        OUI
                                                    </span></label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='NON'
                                                        name="cover_insulation"
                                                        id="cover_insulation"
                                                        onChange={handleChange}
                                                        checked={values.cover_insulation === "NON"} />
                                                    <span className='span-bar bar1-para '>
                                                        NON
                                                    </span>
                                                </label></div>
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>
                                            <div className='button-1 d-flex'>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                <h3 className="bar1-head ms-2 mt-3 ">Pour un réseau de fluide d’une température nominale entre 50° et 120° Combien de points singuliers sont à isoler ? </h3> </div>

                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 20 mm et 65 mm (Qt) :
                                                <input type='number'
                                                    name='diameter20to65V1'
                                                    id='diameter20to65V1'
                                                    value={values.diameter20to65V1}
                                                    onChange={handleChange}
                                                    className="form-control1"></input></p>
                                            {/* {touched.diameter20to65V1 && errors.diameter20to65V1 && <div className="error-message">{errors.diameter20to65V1}</div>} */}

                                            <hr />
                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 66 mm et 100 mm (Qt) : <input type='number'
                                                name='diameter66to100V1'
                                                id='diameter66to100V1'
                                                value={values.diameter66to100V1}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control1" ></input>

                                                {/* {touched.diameter66to100V1 && errors.diameter66to100V1 && <div className="error-message">{errors.diameter66to100V1}</div>} */}
                                            </p><hr />
                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 101 mm et 150 mm (Qt) : <input type='number'
                                                name='diameter101to150V1'
                                                id='diameter101to150V1'
                                                value={values.diameter101to150V1}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control1" ></input>

                                                {touched.diameter101to150V1 && errors.diameter101to150V1 && <div className="error-message">{errors.diameter101to150V1}</div>}
                                            </p><hr />
                                            <p className='bar1-head mt-3'>Sur échangeur à plaque (Qt) :
                                                <input
                                                    type="number"
                                                    name='heatexchangerV1'
                                                    id='heatexchangerV1'
                                                    value={values.heatexchangerV1}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input></p><hr />
                                            <div className='button-1 d-flex'>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                                <h3 className="bar1-head ms-2 mt-3 ">Pour un réseau de fluide d’une température nominale supérieure à 120°
                                                    Combien de points singuliers sont à isoler ?</h3> </div>

                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 20 mm et 65 mm (Qt) :
                                                <input type="number"
                                                    name='diameter20to65V2'
                                                    id='diameter20to65V2'
                                                    value={values.diameter20to65V2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input>
                                                {/* {touched.diameter20to65V2 && errors.diameter20to65V2 && <div className="error-message">{errors.diameter20to65V2}</div>} */}

                                            </p><hr />
                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 66 mm et 100 mm (Qt) :
                                                <input type="number"
                                                    name='diameter66to100V2'
                                                    id='diameter66to100V2'
                                                    value={values.diameter66to100V2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input>
                                                {/* {touched.diameter66to100V2 && errors.diameter66to100V2 && <div className="error-message">{errors.diameter66to100V2}</div>} */}

                                            </p><hr />
                                            <p className='bar1-head mt-3'>D’un diamètre nominal compris entre 101 mm et 150 mm (Qt) :
                                                <input type="number"
                                                    name='diameter101to150V2'
                                                    id='diameter101to150V2'
                                                    value={values.diameter101to150V2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input>
                                                {touched.diameter101to150V2 && errors.diameter101to150V2 && <div className="error-message">{errors.diameter101to150V2}</div>}

                                            </p><hr />
                                            <p className='bar1-head mt-3'>Sur échangeur à plaque (Qt) :
                                                <input type="number"
                                                    name='heatexchangerV2'
                                                    id='heatexchangerV2'
                                                    value={values.heatexchangerV2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input></p><hr />

                                        </div>
                                    </div>
                                </div>
                                : null}
                            {/* RES-EC-104  */}
                            {values.b2boperations === 'RESEAU' && values.documents === 'RES-EC-104' ?
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col'>
                                            <p className='bar1-para '>Annexe 1 à la fiche d’opération standardisée RES-EC-104,
                                                définissant le contenu de la partie A de l’attestation sur l’honneur.</p>
                                            <p className="bar1-head  mt-4">A/ RES-EC-104 (v.A14.1) : Rénovation d’éclairage extérieur par dépose de luminaires et mise en place de
                                                luminaires neufs dont la source lumineuse peut être remplacée.</p>
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : date d’acceptation du devis) :</p>
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <input
                                                        type="date"
                                                        name='dateofcommitment'
                                                        id='dateofcommitment'
                                                        value={values.dateofcommitment}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.dateofcommitment && errors.dateofcommitment ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.dateofcommitment && errors.dateofcommitment && <div className="error-message">{errors.dateofcommitment}</div>}
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Date de preuve de réalisation de l’opération (ex : date de la facture) :</p>
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <input
                                                        type="date"
                                                        name='dateofproof'
                                                        id='dateofproof'
                                                        value={values.dateofproof}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.dateofproof && errors.dateofproof ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.dateofproof && errors.dateofproof && <div className="error-message">{errors.dateofproof}</div>}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-sm-12">
                                                    <p className='bar1-head mt-3'>Référence de la facture :</p>
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='invoicereference'
                                                        id='invoicereference'
                                                        value={values.invoicereference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.invoicereference && errors.invoicereference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.invoicereference && errors.invoicereference && <div className="error-message">{errors.invoicereference}</div>}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-sm-12 text-left">
                                                    <label className='bar1-head mt-3'>*Adresse des travaux (périmètre précis de réalisation de l’opération) :</label>
                                                    &nbsp; <input
                                                        type='checkbox'
                                                        id='same_address'
                                                        checked={isSameAddressChecked}
                                                        onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                                    /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='addressofwork'
                                                        id='addressofwork'
                                                        value={values.addressofwork}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.addressofwork && errors.addressofwork ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.addressofwork && errors.addressofwork && <div className="error-message">{errors.addressofwork}</div>}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-sm-12">
                                                    <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='additionaladdress'
                                                        id='additionaladdress'
                                                        value={values.additionaladdress}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.additionaladdress && errors.additionaladdress && <div className="error-message">{errors.additionaladdress}</div>}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Code postal :</p>
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='codepostal'
                                                        id='codepostal'
                                                        value={values.codepostal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Ville :</p>
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='city'
                                                        id='city'
                                                        value={values.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                                </div>
                                            </div>
                                            <hr />
                                            <p className="bar1-head  mt-4">*Les luminaires déposés sont existants depuis plus de 2 ans à la date d'engagement de l'opération :</p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Oui'
                                                        name="luminaires"
                                                        id="luminaires"
                                                        onChange={handleChange}
                                                        checked={values.luminaires === "Oui"} />
                                                    <span className='span-bar bar1-para'>
                                                        Oui
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='Non'
                                                        name="luminaires"
                                                        id="luminaires"
                                                        onChange={handleChange}
                                                        checked={values.luminaires === "Non"} />
                                                    <span className='span-bar bar1-para '>
                                                        Non
                                                    </span>
                                                </label>
                                            </div>
                                            {touched.luminaires && errors.luminaires && <div className="error-message">{errors.luminaires}</div>}
                                            <div className='button-1 '>
                                                <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                            </div><hr></hr>

                                            <p className="bar1-head  mt-4">*Type d’éclairage : </p>
                                            <div className='text-start'>
                                                <label className='para-bar1 '>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='public lighting'
                                                        name="lighting"
                                                        id="lighting"
                                                        onChange={handleChange}
                                                        checked={values.lighting === "public lighting"} />
                                                    <span className='span-bar bar1-para'>
                                                        Eclairage public de type « fonctionnel » (autoroutier, routier, urbain, permettant tous les types de circulation : motorisée ou
                                                        cycliste) </span>

                                                </label>
                                            </div>
                                            <div className='text-start mt-2'>
                                                <label className='para-bar1 text-initical'>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        value='private type lighting'
                                                        name="lighting"
                                                        id="lighting"
                                                        onChange={handleChange}
                                                        checked={values.lighting === "private type lighting"} />
                                                    <span className='span-bar bar1-para '>Eclairage de type « ambiance » ou privé (rues, avenues, parcs, allées, voies piétonnes, parkings, etc.)
                                                        NB : Cette fiche ne concerne ni les illuminations de mise en valeur des sites ni l’éclairage des terrains de sport. </span>
                                                </label>
                                            </div>
                                            {touched.lighting && errors.lighting && <div className="error-message">{errors.lighting}</div>}
                                            <p className="bar1-head  mt-4"><u>Cas n°1 :</u>  Efficacité lumineuse ≥ 90 lumens par Watt, IP ≥ 65 et ULOR ≤ 1 % (ou, pour les luminaires à LED, ULR ≤ 3%). </p>
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Marque :</p>
                                                </div>
                                                <div className="col-lg-5 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='brand'
                                                        id='brand'
                                                        value={values.brand}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {/* {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>} */}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Référence :</p>
                                                </div>
                                                <div className="col-lg-5 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='reference'
                                                        id='reference'
                                                        value={values.reference}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {/* {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>} */}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Modèle:</p>
                                                </div>
                                                <div className="col-lg-5 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='model'
                                                        id='model'
                                                        value={values.model}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.model && errors.model ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {/* {touched.model && errors.model && <div className="error-message">{errors.model}</div>} */}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Type:</p>
                                                </div>
                                                <div className="col-lg-5 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='types'
                                                        id='types'
                                                        value={values.types}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.types && errors.types ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {/* {touched.types && errors.types && <div className="error-message">{errors.types}</div>} */}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Nombre de luminaires neufs installés :</p>
                                                </div>
                                                <div className="col-lg-5 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='noofnewlights'
                                                        id='noofnewlights'
                                                        value={values.noofnewlights}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.noofnewlights && errors.noofnewlights ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.noofnewlights && errors.noofnewlights && <div className="error-message">{errors.noofnewlights}</div>}
                                                </div>
                                            </div>
                                            <hr />

                                            <p className="bar1-head  mt-4"><u>Cas n°2:</u> Efficacité lumineuse ≥ 70 lumens par Watt, IP ≥ 65 et ULOR ≤ 10 % (ou, pour les luminaires à LED,
                                                ULR ≤ 15 %) </p>
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Marque :</p>
                                                </div>
                                                <div className="col-lg-5 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='brandv2'
                                                        id='brandv2'
                                                        value={values.brandv2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.brandv2 && errors.brandv2 ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {/* {touched.brandv2 && errors.brandv2 && <div className="error-message">{errors.brandv2}</div>} */}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Référence :</p>
                                                </div>
                                                <div className="col-lg-5 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='referencev2'
                                                        id='referencev2'
                                                        value={values.referencev2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.referencev2 && errors.referencev2 ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {/* {touched.referencev2 && errors.referencev2 && <div className="error-message">{errors.referencev2}</div>} */}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Modèle:</p>
                                                </div>
                                                <div className="col-lg-5 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='modelv2'
                                                        id='modelv2'
                                                        value={values.modelv2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.modelv2 && errors.modelv2 ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {/* {touched.modelv2 && errors.modelv2 && <div className="error-message">{errors.modelv2}</div>} */}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Type:</p>
                                                </div>
                                                <div className="col-lg-5 col-sm-12">
                                                    <input
                                                        type="text"
                                                        name='typev2'
                                                        id='typev2'
                                                        value={values.typev2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.typev2 && errors.typev2 ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {/* {touched.typev2 && errors.typev2 && <div className="error-message">{errors.typev2}</div>} */}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 col-sm-12">
                                                    <p className='bar1-head mt-3'>*Nombre de luminaires neufs installés :</p>
                                                </div>
                                                <div className="col-lg-5 col-sm-12">
                                                    <input
                                                        type="number"
                                                        name='noofnewlightsv2'
                                                        id='noofnewlightsv2'
                                                        value={values.noofnewlightsv2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={touched.noofnewlightsv2 && errors.noofnewlightsv2 ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                    {touched.noofnewlightsv2 && errors.noofnewlightsv2 && <div className="error-message">{errors.noofnewlightsv2}</div>}
                                                </div>
                                            </div>
                                            <hr />
                                            <p className="bar1-head  mt-4">NB : l’efficacité lumineuse correspond au flux lumineux initial total sortant divisé par la puissance totale du système (y compris
                                                les auxiliaires). Dans le cas d’un éclairage fonctionnel, seul le cas n°1 est accepté</p>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default OperationB2B