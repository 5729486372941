import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ErrorLabels } from '../../arraystore/CountryCodeArray'
const EmptyfilesUpdate = () => {
    const navigate = useNavigate();
    console.log('ErrorLabels', ErrorLabels);
    const storedFormData3 = JSON.parse(sessionStorage.getItem('step-3'));
    const storedFormData4 = JSON.parse(sessionStorage.getItem('step-4'));
    const storedFormData6 = JSON.parse(sessionStorage.getItem('step-6'));
    const storedFormData8 = JSON.parse(sessionStorage.getItem('step-8'));
    const storedFormData10 = JSON.parse(sessionStorage.getItem('step-10'));
    const storedFormData11 = JSON.parse(sessionStorage.getItem('step-11'));
    const storedFormData12 = JSON.parse(sessionStorage.getItem('step-12'));
    const varrr = [storedFormData3, storedFormData4, storedFormData6, storedFormData8, storedFormData10, storedFormData11, storedFormData12];
    // const emptyFields = Object.keys(storedFormData).filter((key) => storedFormData[key] === "" || storedFormData[key] === false);
    const filteredObjects = varrr?.map(obj =>
        Object?.fromEntries(Object?.entries(obj)?.filter(([_, value]) =>
            value == "" && value == false
        ))
    );
    console.log('filteredObjects', filteredObjects);
    console.log('varrr', varrr);
    //    
    const matchedItems = ErrorLabels?.filter(twoItem => {
        const matchedId = twoItem.id;
        return filteredObjects?.some(oneItem => oneItem?.hasOwnProperty(matchedId));
    });

    const results = matchedItems?.map(matchedItem => {
        console.log(matchedItem.value);
        const pvalue = matchedItem.value;
        return pvalue;
    });

    console.log(results);
    // BACK BUTTON
    const handlePrev = () => {
        navigate(-1);
    };
    return (
        <>
            <section className='route-list'>
                <div className="container-fluid-fluid ">
                    <div className="row">
                        <div className="col-md-12">
                            <ul>
                                {results?.map((label, i) => (
                                    <li key={i} className='bar1-para-error'>{label}</li>
                                ))}
                            </ul>
                            {/* {
                                filteredObjects.map((item, index) => (
                                    <ul key={index}>
                                        {Object.entries(item).map(([key, value]) => (
                                            <li className='bar1-para-error' key={key}>
                                                {key}: {value.toString()}
                                            </li>
                                        ))}
                                    </ul>
                                ))
                            } */}
                        </div>
                    </div>
                    <div className='row mt-5 mb-2'>
                        <div className='col text-start'>
                            <button type='button' onClick={handlePrev} className=' btns-color' >Précédent </button>
                        </div>
                        <div className="col text-end">
                            {/* <button as='Link' to={'/liste-install'} type='submit' className='btns-color' >Suivant</button> */}
                            <Link to={'/update-finalvalitation'} type='submit' className='btns-color' >Suivant</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EmptyfilesUpdate