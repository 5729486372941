import React from 'react'
import { Link } from "react-router-dom"
const BARTH101Update = () => {
    return (
        <div className='container-fluid p-2 '>
            <div className='row'>
                <p className='bar1-para '>BAR-TH-101 : Chauffe-eau solaire individuel  (France métropolitaine)</p>
                <div className='button-1 mt-1'>
                    {/* <button type='button' className='btn-on' data-bs-dismiss="modal" aria-label='close'>Calculez le montant </button> */}
                    <Link to={"/calcult-bar-th-101"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
                </div>
                <div className='col bar1-tittle'>
                    <div className='bar1-tittle'>
                        <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                            <h3 className='bar1-head'>1. Secteur d’application</h3>
                            <p className='bar1-para'>Bâtiment résidentiel : maisons individuelles existantes en France métropolitaine. </p>
                            <h3 className='bar1-head'>2. Dénomination</h3>
                            <p className='bar1-para'>Mise en place d’un chauffe-eau solaire individuel (CESI). </p>
                            <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
                            <p className='bar1-para'>Les capteurs hybrides sont exclus.  </p>
                            <p className='bar1-para'>La mise en place est réalisée par un professionnel.</p>
                            <p className='bar1-para'>Les capteurs solaires ont : <br></br>
                                <span className='ms-4'>- une certification CSTBat ou SolarKeymark ;</span><br></br>
                                <span className='ms-4'>- ou des caractéristiques de performances et de qualité équivalentes, établies par un organisme établi dans l’Espace
                                    Economique Européen et accrédité selon les normes NF EN ISO/CEI 17025 et NF EN 45011 par le Comité Français
                                    d’Accréditation  (COFRAC)  ou  tout  autre  organisme  d’accréditation  signataire  de  l’accord  européen  multilatéral
                                    pertinent  pris  dans  le  cadre  de  European  co-operation  for  Accreditation  (EA),  coordination  européenne  des
                                    organismes d’accréditation. </span>
                            </p>
                            <p className='bar1-para'>Si  le  bénéficiaire  est  une  personne  physique,  le  professionnel  ayant  réalisé  l’opération  est  titulaire  d’un  signe  de
                                qualité répondant aux mêmes exigences que celles prévues à l’article 2 du décret n° 2014-812 du 16 juillet 2014 pris
                                pour l’application du second alinéa du 2 de l’article 200 quater du code général des impôts et du dernier alinéa du 2
                                du I de l’article 244 quater U du code général des impôts et des textes pris pour son application. </p>
                            <p className='bar1-para'>Ce signe de qualité correspond à des travaux relevant du 5 du I de l'article 46 AX de l'annexe III du code général des
                                impôts.</p>
                            <p className='bar1-para'>La preuve de réalisation de l’opération mentionne la mise en place d’un chauffe-eau solaire individuel (CESI). </p>
                            <p className='bar1-para'>À  défaut,  la  preuve  de  réalisation  de  l’opération  mentionne  la  mise  en  place  d’un  équipement  avec  ses  marque  et
                                référence et elle est complétée par un document issu du fabricant indiquant que l’équipement de marque et référence
                                installé est un chauffe-eau solaire individuel.
                            </p>

                            <p className='bar1-para'>Les documents justificatifs spécifiques à l’opération sont :<br></br>
                                <span className='ms-4'>– la certification CSTBat ou SolarKeymark des capteurs solaires ou les pièces justifiant de son équivalence ;</span><br></br>
                                <span className='ms-4'>– la  décision  de  qualification  ou  de  certification  du  professionnel  délivrée  selon  les  mêmes  exigences  que  celles
                                    prévues à l’article 2 du décret susvisé.</span><br></br></p>

                            <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                                <span className='bar1-para'>20 ans.</span></h3>

                            <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac</p>
                            <p className='bar1-ul bar1-para '>Pour les opérations engagées avant le 26/09/2017 : </p>

                            <div className="row">
                                <div className="col-4">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th >Zone climatique </th>
                                                <th >Montant en kWh cumac
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'>
                                                <td>H1 </td>
                                                <td>22 600</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>H2</td>
                                                <td>25 400</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>H3</td>
                                                <td>29 100</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <p className='bar1-ul bar1-para mt-3'>Pour les opérations engagées à partir du 26/09/2017 : </p>

                            <div className="row">
                                <div className="col-4">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th >Zone climatique </th>
                                                <th >Montant en kWh cumac
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'>
                                                <td>H1 </td>
                                                <td>21 500</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>H2</td>
                                                <td>24 100</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>H3</td>
                                                <td>27 600</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='bar1-content mt-4 pb-4'>
                                <p className='bar1-para para-bar '>Fourniture et pose par un professionnel RGE d’un procédé d’isolation en comble perdu ou en rampant de toiture dans un bâtiment résidentiel existant. Un pare-vapeur (ou équivalent) est posé pour protéger l’isolant thermique de l'humidité et de la condensation. </p>
                                <p className="bar1-head para-bar1 ">Éligible si : </p>
                                <ul className='ul-bar1'>  <li><span className='bar1-ul '>Le devis : </span>comporte la date de la pré-visite technique.</li>
                                    <li><span className='bar1-ul'>Le début des travaux</span> respecte un délai<span className='bar1-ul'> de 8 jours après la date de signature</span> de la commande.</li>
                                    <li><span className='bar1-ul'>L’isolant :</span> a une résistance thermique ≥ à 7 m².K/W en comble perdu et ≥ à 6 m².K/W en rampant de toiture, est certifié ACERMI (ou équivalent), est de classe A de protection au feu (ou équivalent ou pose d’une couche supplémentaire).</li>
                                    <li><span className='bar1-ul'>Sont installés :</span>  un coffrage autour des conduits de fumées et des éclairages encastrés ; une rehausse rigide au niveau de la trappe d’accès.</li></ul>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>La facture indique obligatoirement :</span> fourniture et pose d’une isolation de comble ou toiture, marque, référence, épaisseur, résistance thermique, n° ACERMI et surface réellement isolée (+ l’identité du sous-traitant, si c’est le cas), les aménagements réalisés, la présence d’un pare-vapeur + la date de pré-visite technique. </p>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>Contrôle post-chantier :</span> tous les chantiers font l’objet d’un questionnaire de satisfaction client, certains font l’objet d’une inspection qualité post-travaux par un organisme indépendant (attesté COFRAC).</p>

                                <p className='bar1-para para-bar1'><span className='bar1-head '>Attention :</span> Sans modification sous 5 ans, cette opération sera abrogée à partir du 1er mai 2027.</p>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime. En cliquant sur le Info , vous obtenez plus de détail sur une question.</p>
                            </div>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BARTH101Update