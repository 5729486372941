import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
const CalcultUpdate = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    // ----------------------------------------------
    // useEffect(() => {
    //     const storedFormValues = sessionStorage.getItem('step-6');

    //     if (storedFormValues) {
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.roof = parsedFormValues.roof;
    //         initialValues.buildingmorethan2years = parsedFormValues.buildingmorethan2years;
    //         initialValues.codepostal = parsedFormValues.codepostal;
    //         initialValues.thermalinsulation = parsedFormValues.thermalinsulation;
    //         initialValues.heatedareaandanunheated = parsedFormValues.heatedareaandanunheated;
    //         initialValues.heatedareaandanunheated2 = parsedFormValues.heatedareaandanunheated2;
    //         initialValues.surface = parsedFormValues.surface;
    //         initialValues.order = parsedFormValues.order;
    //     }
    // }, []);
    const [initialValues, setInitialValues] = useState(
        {
            roof: '',
            buildingmorethan2years: '',
            codepostal: '',
            thermalinsulation: '',
            heatedareaandanunheated: '',
            heatedareaandanunheated2: '',
            surface: '',
            order: '',
            departmental_precariousness: '',
            total_homes_affectedv1: '',
            approved_housing: '',
            total_homes_affectedv2: '',
            empty_households: '',
            classified_precarious: '',
            highly_precarious: '',
            without_proof: '',
            district_city_policy: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            roof: MultiUpdate?.multisteptable2?.roof || '',
            buildingmorethan2years: MultiUpdate?.multisteptable2?.buildingmorethan2years || '',
            codepostal: MultiUpdate?.multisteptable2?.codepostal || '',
            thermalinsulation: MultiUpdate?.multisteptable2?.thermalinsulation || '',
            heatedareaandanunheated: MultiUpdate?.multisteptable2?.heatedareaandanunheated || '',
            heatedareaandanunheated2: MultiUpdate?.multisteptable2?.heatedareaandanunheated2 || '',
            surface: MultiUpdate?.multisteptable2?.surface || '',
            order: MultiUpdate?.multisteptable2?.order || '',
        }));
    }, []);
    const validate = (values) => {
        const errors = {};
        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/update-estimate");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    // useEffect(() => {
    //     const storedFormData = sessionStorage.getItem('step-6');
    //     if (storedFormData) {
    //         setStoredData(JSON.parse(storedFormData));
    //     }
    // }, []);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid '>
                            <div className='row'>
                                <div className='col'>
                                    <p className='bar1-para'>Calculez et obtenez votre prime / Votre projet : Isolation des combles ou de toiture :</p>
                                    <div className='bar1-content mt-4 pb-4'>
                                    <p className="bar1-head para-bar1 mt-3 ">Le bénéficiaire de cette opération est : </p>
                                    <div className=''>
                                        <label className=' para-bar1'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="beneficiaryOperation"
                                                id="beneficiaryOperation"
                                                value="Bailleur Social"
                                                onChange={handleChange}
                                                checked={values.beneficiaryOperation === "Bailleur Social"} />
                                            <span className='bar1-para span-bar'>
                                                Un <span className='bar1-head'>Bailleur Social</span> (ou organisme d’habitations à loyer modéré), une <span className='bar1-head'>S.E.M.</span>société d’économie mixte (ou bailleur favorisant l'investissement locatif, l'accession à la propriété de logements sociaux) ou un<span className='bar1-head'>maître d’ouvrage d’insertion agréé
                                                    Copropriété privée, Syndic </span>et autres cas</span>
                                        </label>
                                        <label className=' para-bar1 mt-1'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="beneficiaryOperation"
                                                id="beneficiaryOperation"
                                                value="Copropriété privée"
                                                onChange={handleChange}
                                                checked={values.beneficiaryOperation === "Copropriété privée"} />
                                            <span className='span-bar bar1-para'>
                                                <span className='bar1-head'>Copropriété privée, Syndic</span> et autres cas
                                            </span>
                                        </label>
                                        <div className='button-1 para-bar1'>
                                            <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Bailleur Social */}
                                {values.beneficiaryOperation === 'Bailleur Social' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <div className='p-4'>
                                            <label className=' para-bar1'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="departmental_precariousness"
                                                    id="departmental_precariousness"
                                                    value="précarité départementale"
                                                    onChange={handleChange}
                                                    checked={values.departmental_precariousness === "précarité départementale"} />
                                                <span className='bar1-para span-bar'>Le bailleur peut simplement attester du nb de logements conventionnés <b>(calculs avec % de précarité départementale)</b></span>
                                            </label>
                                            <label className=' para-bar1 mt-1'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="departmental_precariousness"
                                                    id="departmental_precariousness"
                                                    value="de précarité réel"
                                                    onChange={handleChange}
                                                    checked={values.departmental_precariousness === "de précarité réel"} />
                                                <span className='span-bar bar1-para'>Le bailleur dispose des justificatifs de revenu/précarité de chaque locataire <b>(calculs avec % de précarité réel)</b>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                )}

                                {/* Depatmental */}
                                {values.departmental_precariousness === 'précarité départementale' && values.beneficiaryOperation === 'Bailleur Social' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <div className='p-3'>
                                            <label className=' para-bar1 d-block'>
                                                <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                <input
                                                    type='number'
                                                    name="total_homes_affectedv1"
                                                    id="total_homes_affectedv1"
                                                    value={values.total_homes_affectedv1}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" ></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de ménages habitant un logement conventionné :</span>
                                                <input
                                                    type='number'
                                                    name="approved_housing"
                                                    id="approved_housing"
                                                    value={values.approved_housing}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité ou vide :</span>
                                                <input
                                                    type='number'
                                                    name="empty_households"
                                                    id="empty_households"
                                                    value={values.empty_households}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                        </div>
                                    </div>
                                )}
                                {/* reel */}
                                {values.departmental_precariousness === 'de précarité réel' && values.beneficiaryOperation === 'Bailleur Social' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <div className='p-3'>
                                            <label className=' para-bar1 d-block'>
                                                <span className='bar1-para span-bar'>Nombre total de logement concernés par l’opération :</span>
                                                <input
                                                    type='total_homes_affectedv2'
                                                    name="total_homes_affectedv2"
                                                    id="total_homes_affectedv2"
                                                    value={values.total_homes_affectedv2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1"></input>
                                            </label>
                                            <label className='para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "précaires" :</span>
                                                <input
                                                    type='number'
                                                    name="classified_precarious"
                                                    id="classified_precarious"
                                                    value={values.classified_precarious}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages classés "grands précaires" :</span>
                                                <input
                                                    type='number'
                                                    name="highly_precarious"
                                                    id="highly_precarious"
                                                    value={values.highly_precarious}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <span className='span-bar bar1-para'>dont, nb de logements avec des ménages hors précarité, vide, ou sans justificatif :</span>
                                                <input
                                                    type='number'
                                                    name="without_proof"
                                                    id="without_proof"
                                                    value={values.without_proof}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control1" placeholder='0'></input>
                                            </label>
                                            <p className="span-bar bar1-para mt-2">(Rappel : n° de déclarant + n° d’avis d’imposition ou le justificatif de grande précarité seront à fournir pour les logements)</p>
                                        </div>
                                    </div>
                                )}

                                {/* Copriete */}
                                {values.beneficiaryOperation === 'Copropriété privée' && (
                                    <div className='bar1-content mt-4 pb-4'>
                                        <p className="bar1-head para-bar1 mt-3 ">Copropriété dans quartier prioritaire de la politique de la ville ?</p>
                                        <div className=''>
                                            <label className=' para-bar1 d-block'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="district_city_policy"
                                                    id="district_city_policy"
                                                    value="Yes"
                                                    onChange={handleChange}
                                                    checked={values.district_city_policy === "Yes"} />
                                                <span className='bar1-para span-bar'>Oui</span>
                                            </label>
                                            <label className=' para-bar1 mt-1 d-block'>
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="district_city_policy"
                                                    id="district_city_policy"
                                                    value="Non"
                                                    onChange={handleChange}
                                                    checked={values.district_city_policy === "Non"} />
                                                <span className='span-bar bar1-para'>Non</span>
                                            </label>
                                        </div>
                                    </div>
                                )}

                                    <p className="bar1-head  mt-4">Le bâtiment a plus de 2 ans ?</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value='yes'
                                                name="buildingmorethan2years"
                                                id="buildingmorethan2years"
                                                onChange={handleChange}
                                                checked={values.buildingmorethan2years === "yes"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="buildingmorethan2years"
                                                value='no'
                                                id="buildingmorethan2years"
                                                onChange={handleChange}
                                                checked={values.buildingmorethan2years === "no"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.buildingmorethan2years && errors.buildingmorethan2years && <div className="error-message">{errors.buildingmorethan2years}</div>}
                                    <div className='button-1 '>
                                        <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                            <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                        </OverlayTrigger>
                                    </div>

                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-4 col-sm-12">
                                            <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                        </div>
                                        <div className="col-lg-3 col-sm-12">
                                            <input
                                                type="number"
                                                name='codepostal'
                                                id='codepostal'
                                                value={values.codepostal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">L’opération consiste en la mise en place d’un procédé d’isolation thermique ?</p>

                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="yes"
                                                name="thermalinsulation"
                                                id="thermalinsulation"
                                                onChange={handleChange}
                                                checked={values.thermalinsulation === "yes"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2' >
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="no"
                                                name="thermalinsulation"
                                                id="thermalinsulation"
                                                onChange={handleChange}
                                                checked={values.thermalinsulation === "no"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.thermalinsulation && errors.thermalinsulation && <div className="error-message">{errors.thermalinsulation}</div>}
                                    <div className='button-1 '>
                                        <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                            <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                        </OverlayTrigger>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">Le procédé d’isolation thermique est effectué entre une zone chauffée et une zone non chauffée ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="yes"
                                                name="heatedareaandanunheated"
                                                id="heatedareaandanunheated"
                                                onChange={handleChange}
                                                checked={values.heatedareaandanunheated === "yes"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="no"
                                                name="heatedareaandanunheated"
                                                id="heatedareaandanunheated"
                                                onChange={handleChange}
                                                checked={values.heatedareaandanunheated === "no"} />
                                            <span className='span-bar bar1-para'>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.heatedareaandanunheated && errors.heatedareaandanunheated && <div className="error-message">{errors.heatedareaandanunheated}</div>}
                                    <div className='button-1 '>
                                        <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                            <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                        </OverlayTrigger>
                                    </div>
                                    <hr></hr>

                                    <p className="bar1-head  mt-4">Le procédé d’isolation thermique est effectué entre une zone chauffée et une zone non chauffée ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="floor of an unfinished"
                                                name="heatedareaandanunheated2"
                                                id="heatedareaandanunheated2"
                                                onChange={handleChange}
                                                checked={values.heatedareaandanunheated2 === "floor of an unfinished"} />
                                            <span className='span-bar bar1-para'>
                                                en comble perdu uniquement (ex : sol d’un grenier non-aménagé)
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="inclined wall of the roof"
                                                name="heatedareaandanunheated2"
                                                id="heatedareaandanunheated2"
                                                onChange={handleChange}
                                                checked={values.heatedareaandanunheated2 === "inclined wall of the roof"} />
                                            <span className='span-bar bar1-para '>
                                                en rampant de toiture uniquement (ex : paroi inclinée du toit)
                                            </span>
                                        </label>

                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="lost attic and roof slope"
                                                name="heatedareaandanunheated2"
                                                id="heatedareaandanunheated2"
                                                onChange={handleChange}
                                                checked={values.heatedareaandanunheated2 === "lost attic and roof slope"} />
                                            <span className='span-bar bar1-para '>
                                                en comble perdu et en rampant de toiture
                                            </span>
                                        </label>
                                    </div>
                                    {touched.heatedareaandanunheated2 && errors.heatedareaandanunheated2 && <div className="error-message">{errors.heatedareaandanunheated2}</div>}

                                    <div className='button-1 '>
                                        <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip id="button-tooltip-2">Un Bailleur Social (ou organisme d’habitations à loyer modéré), une S.E.M.</Tooltip>}>
                                            <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                        </OverlayTrigger>
                                    </div>
                                    <hr></hr>
                                    <div className="row">
                                        <div className="col-4">
                                            <p className='bar1-head mt-3'>Surface totale isolée (en m²) :</p></div>
                                        <div className="col-4 text-start align-items-center d-flex"><input
                                            type="number"
                                            name="surface"
                                            id="surface"
                                            value={values.surface}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.surface && errors.surface ? 'error' : 'form-control1'}
                                            placeholder='0,00' ></input>
                                        </div>
                                    </div> <hr></hr>

                                    <p className="bar1-head  mt-4">Pour lutter contre la fraude, le début des travaux ne peut commencer que 8 jours après la date de commande ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="8 days"
                                                name="order"
                                                id="order"
                                                onChange={handleChange}
                                                checked={values.order === "8 days"} />
                                            <span className='span-bar bar1-para'>
                                                Oui, un délai minimum de 8 jours est/sera respecté </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="8 days after the order"
                                                name="order"
                                                id="order"
                                                onChange={handleChange}
                                                checked={values.order === "8 days after the order"} />
                                            <span className='span-bar bar1-para '>
                                                Non, la pose est/sera réalisée moins de 8 jours après la commande
                                            </span>
                                        </label>
                                        {touched.order && errors.order && <div className="error-message">{errors.order}</div>}
                                    </div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>
                                    {/* <p className='bar1-head mt-3'>Définir le montant de la prime</p> */}

                                    <div className='row mt-5 mb-2'>
                                        <div className='col text-start'>
                                            <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                        </div>
                                        <div className="col text-end">
                                            <button type='submit' className='btns-color'>Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default CalcultUpdate