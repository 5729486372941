import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
// FORMIK
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
const StatusB2B = (props) => {
    const MultiUpdate = props.MultiUpdate;
    const { id } = useParams();
    const Token = localStorage.getItem('Token');
    const navigate = useNavigate();
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);
    const [initialValues, setInitialValues] = useState(
        {
            id: id,
            final_status: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            id: id,
            final_status: MultiUpdate?.multisteptable1?.final_status || '',
        }));
    }, [MultiUpdate]);
    const validate = (values) => {
        console.log('values', values);
        const errors = {};

        return errors;
    };

    const handleSubmit = async (values) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/update-multistep`, values,
                {
                    headers: { authorization: Token }
                }).then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        console.log('res.message', response?.data?.message);
                        navigate('/file-dashboard');
                        sessionStorage.clear();
                    }
                })
        } catch (error) {
            console.log(error.response?.data?.message);
        }

    }

    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="container-fluid px-4">
                            <div className='row mt-5 mb-2 d-flex align-items-center justify-content-center'>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para'>Status*</p>
                                    <div className='d-flex'>
                                        <select name='final_status'
                                            id='final_status'
                                            value={values.final_status}
                                            onChange={handleChange}
                                            className={touched.final_status && errors.final_status ? 'error' : 'form-select btn-buttons ps-3'} aria-label="Mode de chauffage">
                                            <option value=''>-- Choisir un Status --</option>
                                            <option value="Valide">Valide</option>
                                            <option value="En cours de correction">En cours de correction</option>
                                            <option value="Annulé">Annulé</option>
                                        </select>
                                    </div>
                                    <button type='submit' onClick={handleSubmit} className='btns-color mt-4' >Suivant</button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default StatusB2B