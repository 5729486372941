import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import winlogo from "../assets/win-logo.svg";
// FORMIK
import { Formik } from 'formik';
import { toast } from 'react-toastify';
function Index() {

    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: '',
    }
    const validate = (values) => {
        console.log("validate", values);
        const errors = {};
        if (!values.email) {    
            errors.email = 'email requise';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        if (!values.password) {
            errors.password = 'Mot de passe requise';
        }
        return errors;
    }
    // TOAST
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);
    const handleSubmit = async (values) => {
        console.log({ "values": values });
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/login`, values)
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.setItem('Token', res?.data?.token);
                        console.log(res?.data);
                      
                        if (res?.data?.user[1]?.rolename === 'Super Manager') {
                            navigate('/dashboard');
                            toastsuccess(res?.data?.message);
                            sessionStorage.setItem('installeroroblige', res?.data?.user[1]?.rolename);
                            localStorage.setItem('installerorobligelocal', res?.data?.user[1]?.rolename);
                            localStorage.setItem('installerorobligelocalid', res?.data?.user[0]?.id);
                        } else if (res?.data?.user[1]?.rolename === 'Installer') {
                            navigate('/installer-oblige');
                            toastsuccess(res?.data?.message);
                            sessionStorage.setItem('installeroroblige', res?.data?.user[1]?.rolename);
                            localStorage.setItem('installerorobligelocal', res?.data?.user[1]?.rolename);
                            localStorage.setItem('installerorobligelocalid', res?.data?.user[0]?.id);
                        } else if (res?.data?.user[1]?.rolename === 'Oblige') {
                            navigate('/installer-oblige');
                            toastsuccess(res?.data?.message);
                            sessionStorage.setItem('installeroroblige', res?.data?.user[1]?.rolename);
                            localStorage.setItem('installerorobligelocal', res?.data?.user[1]?.rolename);
                            localStorage.setItem('installerorobligelocalid', res?.data?.user[0]?.id);
                        } else {
                            toastsuccess(res?.data?.message);
                        }
                    }
                })
        }
        catch (error) {
            console.log(error?.response?.data?.message);
            toasterror(error?.response?.data?.message);
        }
    }
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <section>
                            <div className='container-fluid-fluid '>
                                <div className='login-div border-div-green '>
                                    <img
                                        src={winlogo}
                                        width="auto"
                                        height="auto"
                                        className="login-logo"
                                        alt="win-energy Logo"
                                    />
                                </div>
                                <div className='form-page pt-5'>
                                    <div className='row justify-content-center'>
                                        <div className='col-md-7 col-lg-5 col-sm-12 text-center '>
                                            <h3 className='form-text-1 text-center'>Connectez-vous à votre compte pour accéder à vos résultats.</h3>
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-start">
                                                <Form.Label className='form-text'>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.email && errors.email ? 'error' : 'form-controls'}
                                                />
                                                {touched.email && errors.email && <div className="error-message">{errors.email}</div>}
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12 text-start">
                                                <Form.Label className='form-text'>Mot de passe</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.password && errors.password ? 'error' : 'form-controls'}
                                                />
                                                {touched.password && errors.password && <div className="error-message">{errors.password}</div>}
                                            </div>

                                            <Link to={'/forgot-password'} className="form-book">Mot de passe oublié ?</Link>
                                            <button type='submit' className=" mt-4 btn-form" > Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </form>

                )
                }
            </Formik >
        </>
    )
}

export default Index;