import React from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
// FORMIK
import winlogo from "../../assets/win-logo.svg";
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Link, useLocation } from "react-router-dom"
function ChangePassword() {
    const navigate = useNavigate();
    // const location = useLocation();
    // const isChangedPassword = location.pathname === '/change-password';
    // if (isChangedPassword) {
    //     return null;
    // }

    const initialValues = {
        email: '',
        password: '',
        newpassword: '',
        // confirmnewpassword: '',
    }
    const validate = (values) => {
        console.log("validate", values);
        const errors = {};
        if (!values.email) {
            errors.email = 'email requise';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        // if (!values.password) {
        //     errors.password = 'ancien mot de passe requise';
        // }
        // if (!values.newpassword) {
        //     errors.newpassword = 'nouveau mot de passe requise';
        // } else if (values.newpassword !== values.confirmnewpassword) {
        //     errors.confirmnewpassword = 'incorrect password';
        // }
        return errors;
    }
    const Token = localStorage.getItem('Token');
    const toastsuccess = (val) => toast.success(val);

    const handleSubmit = async (values, { resetForm }) => {
        console.log({ "values": values });
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/change-password`, values,
                {
                    headers: { authorization: Token },
                });
            if (response?.status === 200) {
                console.log(response?.data?.message);
                toastsuccess(response?.data?.message);
            }
        } catch (error) {
            toastsuccess(error?.response?.data?.message);
            console.log(error);
        }
        resetForm();
    }

    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <section>
                            <div className='container-fluid-fluid  pl-30'>
                                {/* <div className='login-div border-div-green '>
                                    <img
                                        src={winlogo}
                                        width="auto"
                                        height="auto"
                                        className="login-logo"
                                        alt="win-energy Logo"
                                    />
                                </div> */}
                                <div className='form-page'>
                                    <div className='row justify-content-center'>
                                        <div className='col-md-7 col-lg-5 col-sm-12 text-center '>
                                            <h3 className='form-text-1 text-center'>Connectez-vous à votre compte</h3>
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-start">
                                                <Form.Label className='form-text'>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.email && errors.email ? 'error' : 'form-controls'}
                                                />
                                                {touched.email && errors.email && <div className="error-message">{errors.email}</div>}
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12 text-start">
                                                <Form.Label className='form-text'>ancien mot de passe</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.password && errors.password ? 'error' : 'form-controls'}
                                                />
                                                {touched.password && errors.password && <div className="error-message">{errors.password}</div>}
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12 text-start">
                                                <Form.Label className='form-text'>nouveau mot de passe</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="newpassword"
                                                    value={values.newpassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.newpassword && errors.newpassword ? 'error' : 'form-controls'}
                                                />
                                                {touched.newpassword && errors.newpassword && <div className="error-message">{errors.newpassword}</div>}
                                            </div>

                                            {/* <div className="col-lg-12 col-md-12 col-sm-12 text-start">
                                                <Form.Label className='form-text'>confirmer le nouveau mot de passe</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="confirmnewpassword"
                                                    value={values.confirmnewpassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.confirmnewpassword && errors.confirmnewpassword ? 'error' : 'form-controls'} />
                                                {touched.confirmnewpassword && errors.confirmnewpassword && <div className="error-message">{errors.confirmnewpassword}</div>}
                                            </div> */}
                                            <button type='submit' className=" mt-4 btn-form" >Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                )
                }
            </Formik >
        </>
    )
}

export default ChangePassword;