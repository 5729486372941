import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
const CalcultBARTH113 = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();

    // ----------------------------------------------
    useEffect(() => {
        const storedFormValues = sessionStorage.getItem('step-6');

        if (storedFormValues) {
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.commitmentdate = parsedFormValues.commitmentdate;
            initialValues.dateofproof = parsedFormValues.dateofproof;
            initialValues.codepostal = parsedFormValues.codepostal;
            initialValues.invoicereference = parsedFormValues.invoicereference;
            initialValues.legalpersons = parsedFormValues.legalpersons;
            initialValues.addressofworks = parsedFormValues.addressofworks;
            initialValues.additionaladdress = parsedFormValues.additionaladdress;
            initialValues.city = parsedFormValues.city;
            initialValues.individual_house = parsedFormValues.individual_house;
            initialValues.sizingnote = parsedFormValues.sizingnote;
            initialValues.wood = parsedFormValues.wood;
            initialValues.seasonal_energy = parsedFormValues.seasonal_energy;
            initialValues.rated_heat = parsedFormValues.rated_heat;
            initialValues.regulator_class = parsedFormValues.regulator_class;
            initialValues.boilers_225 = parsedFormValues.boilers_225;
            initialValues.buffer_tank = parsedFormValues.buffer_tank;
            initialValues.boiler_operated = parsedFormValues.boiler_operated;
            initialValues.boiler_7label = parsedFormValues.boiler_7label;
            initialValues.seasonal_particulate = parsedFormValues.seasonal_particulate;
            initialValues.seasonal_carbon = parsedFormValues.seasonal_carbon;
            initialValues.nitrogen_oxides = parsedFormValues.nitrogen_oxides;
            initialValues.gaseous_organic = parsedFormValues.gaseous_organic;
            initialValues.brand = parsedFormValues.brand;
            initialValues.reference = parsedFormValues.reference;
            initialValues.nom = parsedFormValues.nom;
            initialValues.prénom = parsedFormValues.prénom;
            initialValues.b2c_social_reason = parsedFormValues.b2c_social_reason;
            initialValues.siret_number = parsedFormValues.siret_number;
        }
    }, []);
    const initialValues = {
        commitmentdate: '',
        dateofproof: '',
        codepostal: '',
        invoicereference: '',
        legalpersons: '',
        addressofworks: '',
        additionaladdress: '',
        city: '',
        individual_house: '',
        sizingnote: '',
        wood: '',
        seasonal_energy: '',
        rated_heat: '',
        regulator_class: '',
        boilers_225: '',
        buffer_tank: '',
        boiler_operated: '',
        boiler_7label: '',
        seasonal_particulate: '',
        seasonal_carbon: '',
        nitrogen_oxides: '',
        gaseous_organic: '',
        brand: '',
        reference: '',
        nom: '',
        prénom: '',
        b2c_social_reason: '',
        siret_number: '',
    };
    const same_addressget = JSON.parse(sessionStorage.getItem('step-4'));
    console.log('same_address', same_addressget);
    const same_address = same_addressget.beneficiary_address;
    const same_cp = same_addressget.beneficiary_cp;
    const same_ville = same_addressget.beneficiary_city;
    console.log('same_address', same_address);
    const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
    const handleCheckboxChange = (e, setFieldValue) => {

        const isChecked = e.target.checked;
        setIsSameAddressChecked(isChecked);
        if (isChecked) {
            setFieldValue('addressofworks', same_address);
            setFieldValue('codepostal', same_cp);
            setFieldValue('city', same_ville);
        } else {
            setFieldValue('addressofworks', initialValues.addressofworks);
            setFieldValue('codepostal', initialValues.codepostal);
            setFieldValue('city', initialValues.city);
        }
    };
    const validate = (values) => {
        const errors = {};

        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.commitmentdate) {
            errors.commitmentdate = "Date d’engagement de l'opération (ex : acceptation du devis) requise";
        }
        if (!values.legalpersons) {
            errors.legalpersons = "Pour les personnes morales, nom du site des travaux ou nom de la copropriété requise";
        }
        if (!values.addressofworks) {
            errors.addressofworks = "Adresse des travaux requise";
        }
        if (!values.city) {
            errors.city = "Ville requise";
        }
        if (!values.individual_house) {
            errors.individual_house = "Maison individuelle existant depuis plus de 2 ans à la date d'engagement de l'opération requise";
        }
        if (!values.sizingnote) {
            errors.sizingnote = "Une note de dimensionnement a été remise au bénéficiaire requise";
        }
        if (!values.wood) {
            errors.wood = "La biomasse utilisée est de la biomasse ligneuse à base de bûches de bois, de copeaux de bois, de bois comprimé sous forme de granulés, de bois comprimé sous forme de briquettes ou de sciure de bois requise";
        }
        if (!values.seasonal_energy) {
            errors.seasonal_energy = "Efficacité énergétique saisonnière (ηs) de la chaudière biomasse (en %) requise";
        }
        if (!values.rated_heat) {
            errors.rated_heat = "Puissance thermique nominale P de la chaudière biomasse en kW requise";
        }
        if (!values.regulator_class) {
            errors.regulator_class = "Classe du régulateur requise";
        }
        if (!values.boilers_225) {
            errors.boilers_225 = "Pour les chaudières à alimentation automatique, présence d’un silo d’au moins 225 litres requise";
        }
        if (!values.buffer_tank) {
            errors.buffer_tank = "Pour les chaudières à alimentation manuelle, présence d’un ballon tampon requise";
        }
        if (!values.boiler_operated) {
            errors.boiler_operated = "Le chargement de la chaudière est opéré de manière (une seule case à cocher) requise";
        }
        if (!values.boiler_7label) {
            errors.boiler_7label = "La chaudière installée possède le label Flamme Verte 7 requise";
        }
        if (!values.seasonal_particulate) {
            errors.seasonal_particulate = "Émissions saisonnières de particules en mg/Nm3 requise";
        }
        if (!values.seasonal_carbon) {
            errors.seasonal_carbon = "Émissions saisonnières de monoxyde de carbone (CO) en mg/Nm3 requise";
        }
        if (!values.nitrogen_oxides) {
            errors.nitrogen_oxides = "Émissions saisonnières d'oxydes d'azote (NOx) en mg/Nm3 requise";
        }
        if (!values.gaseous_organic) {
            errors.gaseous_organic = "Émissions saisonnières de composés organiques gazeux en mg/Nm3 requise";
        }
        if (!values.brand) {
            errors.brand = "Marque requise";
        }
        if (!values.reference) {
            errors.reference = "Référence requise";
        }
        if (!values.nom) {
            errors.nom = "Nom requise";
        }
        if (!values.prénom) {
            errors.prénom = "Prénom requise";
        }
        if (!values.b2c_social_reason) {
            errors.b2c_social_reason = "Raison sociale requise";
        }
        if (!values.siret_number) {
            errors.siret_number = "N° SIREN requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/estimation-page");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-6');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid px-4 py-3'>
                            <div className='row'>
                                <div className='col'>
                                    <p className='bar1-para'>A/ BAR-TH-113 (v. A41.3) : Mise en place d’une chaudière biomasse individuelle.</p>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='commitmentdate'
                                                id='commitmentdate'
                                                value={values.commitmentdate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='dateofproof'
                                                id='dateofproof'
                                                value={values.dateofproof}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className='form-control1 mb-0 w-100' ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='invoicereference'
                                                id='invoicereference'
                                                value={values.invoicereference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className='form-control1 mb-0 w-100' ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Pour les personnes morales, nom du site des travaux ou nom de la copropriété :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                name='legalpersons'
                                                id='legalpersons'
                                                value={values.legalpersons}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.legalpersons && errors.legalpersons ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.legalpersons && errors.legalpersons && <div className="error-message">{errors.legalpersons}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12 text-left">
                                            <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                type='checkbox'
                                                id='same_address'
                                                checked={isSameAddressChecked}
                                                onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                            /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type='text'
                                                name='addressofworks'
                                                id='addressofworks'
                                                value={values.addressofworks}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                            {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type='text'
                                                name='additionaladdress'
                                                id='additionaladdress'
                                                value={values.additionaladdress}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Code postal : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='codepostal'
                                                id='codepostal'
                                                value={values.codepostal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Ville :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='city'
                                                id='city'
                                                value={values.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*Maison individuelle existant depuis plus de 2 ans à la date d'engagement de l'opération : </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="individual_house"
                                                id="individual_house"
                                                onChange={handleChange}
                                                checked={values.individual_house === "Oui"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="individual_house"
                                                id="individual_house"
                                                onChange={handleChange}
                                                checked={values.individual_house === "Non"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.individual_house && errors.individual_house && <div className="error-message">{errors.individual_house}</div>}
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*Une note de dimensionnement a été remise au bénéficiaire : </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="sizingnote"
                                                id="sizingnote"
                                                onChange={handleChange}
                                                checked={values.sizingnote === "Oui"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="sizingnote"
                                                id="sizingnote"
                                                onChange={handleChange}
                                                checked={values.sizingnote === "Non"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.sizingnote && errors.sizingnote && <div className="error-message">{errors.sizingnote}</div>}
                                    <hr></hr>
                                    {/* <p className="bar1-head  mt-4">*Caractéristiques de la chaudière biomasse :</p> */}
                                    <p className="bar1-head  mt-4">*La biomasse utilisée est de la biomasse ligneuse à base de bûches de bois, de copeaux de bois, de bois comprimé sous forme
                                        de granulés, de bois comprimé sous forme de briquettes ou de sciure de bois :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="wood"
                                                id="wood"
                                                onChange={handleChange}
                                                checked={values.wood === "Oui"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="wood"
                                                id="wood"
                                                onChange={handleChange}
                                                checked={values.wood === "Non"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.wood && errors.wood && <div className="error-message">{errors.wood}</div>}
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Efficacité énergétique saisonnière (ηs) de la chaudière biomasse (en %) :  </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='seasonal_energy'
                                                id='seasonal_energy'
                                                value={values.seasonal_energy}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.seasonal_energy && errors.seasonal_energy ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.seasonal_energy && errors.seasonal_energy && <div className="error-message">{errors.seasonal_energy}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Puissance thermique nominale P de la chaudière biomasse en kW :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='rated_heat'
                                                id='rated_heat'
                                                value={values.rated_heat}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.rated_heat && errors.rated_heat ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.rated_heat && errors.rated_heat && <div className="error-message">{errors.rated_heat}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Classe du régulateur :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='regulator_class'
                                                id='regulator_class'
                                                value={values.regulator_class}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.regulator_class && errors.regulator_class ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.regulator_class && errors.regulator_class && <div className="error-message">{errors.regulator_class}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">* Pour les chaudières à alimentation automatique, présence d’un silo d’au moins 225 litres :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="boilers_225"
                                                id="boilers_225"
                                                onChange={handleChange}
                                                checked={values.boilers_225 === "Oui"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="boilers_225"
                                                id="boilers_225"
                                                onChange={handleChange}
                                                checked={values.boilers_225 === "Non"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.boilers_225 && errors.boilers_225 && <div className="error-message">{errors.boilers_225}</div>}
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">* Pour les chaudières à alimentation manuelle, présence d’un ballon tampon :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="buffer_tank"
                                                id="buffer_tank"
                                                onChange={handleChange}
                                                checked={values.buffer_tank === "Oui"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="buffer_tank"
                                                id="buffer_tank"
                                                onChange={handleChange}
                                                checked={values.buffer_tank === "Non"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.buffer_tank && errors.buffer_tank && <div className="error-message">{errors.buffer_tank}</div>}
                                    <hr></hr>
                                    <p className='bar1-para'>NB1 : l’efficacité énergétique saisonnière est calculée selon le règlement (EU) 2015/1189 de la Commission du 28 avril 2015.</p>
                                    <p className='bar1-para'>NB2 : l’efficacité énergétique saisonnière prise en compte est celle de la chaudière seule pour les besoins de chauffage des
                                        locaux (hors dispositif de régulation).</p>

                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*Le chargement de la chaudière est opéré de manière (une seule case à cocher) :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="automatique"
                                                name="boiler_operated"
                                                id="boiler_operated"
                                                onChange={handleChange}
                                                checked={values.boiler_operated === "automatique"} />
                                            <span className='span-bar bar1-para'>automatique</span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="manuelle"
                                                name="boiler_operated"
                                                id="boiler_operated"
                                                onChange={handleChange}
                                                checked={values.boiler_operated === "manuelle"} />
                                            <span className='span-bar bar1-para '>manuelle</span>
                                        </label>
                                    </div>
                                    {touched.boiler_operated && errors.boiler_operated && <div className="error-message">{errors.boiler_operated}</div>}
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*La chaudière installée possède le label Flamme Verte 7* : </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="boiler_7label"
                                                id="boiler_7label"
                                                onChange={handleChange}
                                                checked={values.boiler_7label === "Oui"} />
                                            <span className='span-bar bar1-para'>OUI</span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="boiler_7label"
                                                id="boiler_7label"
                                                onChange={handleChange}
                                                checked={values.boiler_7label === "Non"} />
                                            <span className='span-bar bar1-para '>NON</span>
                                        </label>
                                    </div>
                                    {touched.boiler_7label && errors.boiler_7label && <div className="error-message">{errors.boiler_7label}</div>}
                                    <hr></hr>
                                    <p className='bar1-para'>*Si la chaudière installée ne possède pas le label Flamme Verte 7*, ses émissions saisonnières de polluants à 10 % d’O2 sont à renseigner ci-dessous :</p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>- Émissions saisonnières de particules en mg/Nm3</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='seasonal_particulate'
                                                id='seasonal_particulate'
                                                value={values.seasonal_particulate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.seasonal_particulate && errors.seasonal_particulate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.seasonal_particulate && errors.seasonal_particulate && <div className="error-message">{errors.seasonal_particulate}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>- Émissions saisonnières de monoxyde de carbone (CO) en mg/Nm3</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='seasonal_carbon'
                                                id='seasonal_carbon'
                                                value={values.seasonal_carbon}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.seasonal_carbon && errors.seasonal_carbon ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.seasonal_carbon && errors.seasonal_carbon && <div className="error-message">{errors.seasonal_carbon}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>- Émissions saisonnières d'oxydes d'azote (NOx) en mg/Nm3</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='nitrogen_oxides'
                                                id='nitrogen_oxides'
                                                value={values.nitrogen_oxides}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.nitrogen_oxides && errors.nitrogen_oxides ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.nitrogen_oxides && errors.nitrogen_oxides && <div className="error-message">{errors.nitrogen_oxides}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>- Émissions saisonnières de composés organiques gazeux en mg/Nm3</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='gaseous_organic'
                                                id='gaseous_organic'
                                                value={values.gaseous_organic}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.gaseous_organic && errors.gaseous_organic ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.gaseous_organic && errors.gaseous_organic && <div className="error-message">{errors.gaseous_organic}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>A ne remplir que si les marque et référence de la chaudière ne sont pas mentionnées sur la preuve de réalisation de l’opération :</p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Marque :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='brand'
                                                id='brand'
                                                value={values.brand}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Référence : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='reference'
                                                id='reference'
                                                value={values.reference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>Le professionnel réalisant l’opération est titulaire d’un signe de qualité conforme aux exigences prévues à l’article 2 du décret
                                        n° 2014-812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des impôts
                                        et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris pour son application. Ce
                                        signe de qualité correspond à des travaux relevant du 3° du I de l'article 1er du décret précité.</p>

                                    <p className='bar1-para'>Identité du professionnel titulaire du signe de qualité ayant réalisé l’opération, s’il n’est pas le signataire de cette attestation
                                        (sous-traitant par exemple) :</p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Nom</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='nom'
                                                id='nom'
                                                value={values.nom}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.nom && errors.nom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.nom && errors.nom && <div className="error-message">{errors.nom}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Prénom</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='prénom'
                                                id='prénom'
                                                value={values.prénom}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.prénom && errors.prénom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.prénom && errors.prénom && <div className="error-message">{errors.prénom}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Raison sociale : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='b2c_social_reason'
                                                id='b2c_social_reason'
                                                value={values.b2c_social_reason}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.b2c_social_reason && errors.b2c_social_reason ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.b2c_social_reason && errors.b2c_social_reason && <div className="error-message">{errors.b2c_social_reason}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*N° SIREN :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='siret_number'
                                                id='siret_number'
                                                value={values.siret_number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.siret_number && errors.siret_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.siret_number && errors.siret_number && <div className="error-message">{errors.siret_number}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className='row mt-5 mb-2'>
                                        <div className='col text-start'>
                                            <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                        </div>
                                        <div className="col text-end">
                                            <button type='submit' className='btns-color'>Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default CalcultBARTH113