import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
const CalcultBARTH143 = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();

    // ----------------------------------------------
    useEffect(() => {
        const storedFormValues = sessionStorage.getItem('step-6');

        if (storedFormValues) {
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.commitmentdate = parsedFormValues.commitmentdate;
            initialValues.dateofproof = parsedFormValues.dateofproof;
            initialValues.codepostal = parsedFormValues.codepostal;
            initialValues.invoicereference = parsedFormValues.invoicereference;
            initialValues.legalpersons = parsedFormValues.legalpersons;
            initialValues.addressofworks = parsedFormValues.addressofworks;
            initialValues.additionaladdress = parsedFormValues.additionaladdress;
            initialValues.city = parsedFormValues.city;
            initialValues.individual_house = parsedFormValues.individual_house;
            initialValues.lowtemperature = parsedFormValues.lowtemperature;
            initialValues.installation_type = parsedFormValues.installation_type;
            initialValues.cop = parsedFormValues.cop;
            initialValues.brand = parsedFormValues.brand;
            initialValues.reference = parsedFormValues.reference;
            initialValues.nom = parsedFormValues.nom;
            initialValues.prénom = parsedFormValues.prénom;
            initialValues.b2c_social_reason = parsedFormValues.b2c_social_reason;
            initialValues.siret_number = parsedFormValues.siret_number;
        }
    }, []);
    const initialValues = {
        commitmentdate: '',
        dateofproof: '',
        codepostal: '',
        invoicereference: '',
        legalpersons: '',
        addressofworks: '',
        additionaladdress: '',
        city: '',
        individual_house: '',
        lowtemperature: '',
        installation_type: '',
        cop: '',
        brand: '',
        reference: '',
        nom: '',
        prénom: '',
        b2c_social_reason: '',
        siret_number: '',
    };
    const same_addressget = JSON.parse(sessionStorage.getItem('step-4'));
    console.log('same_address', same_addressget);
    const same_address = same_addressget.beneficiary_address;
    const same_cp = same_addressget.beneficiary_cp;
    const same_ville = same_addressget.beneficiary_city;
    console.log('same_address', same_address);
    const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
    const handleCheckboxChange = (e, setFieldValue) => {

        const isChecked = e.target.checked;
        setIsSameAddressChecked(isChecked);
        if (isChecked) {
            setFieldValue('addressofworks', same_address);
            setFieldValue('codepostal', same_cp);
            setFieldValue('city', same_ville);
        } else {
            setFieldValue('addressofworks', initialValues.addressofworks);
            setFieldValue('codepostal', initialValues.codepostal);
            setFieldValue('city', initialValues.city);
        }
    };
    const validate = (values) => {
        const errors = {};

        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.commitmentdate) {
            errors.commitmentdate = "Date d’engagement de l'opération (ex : acceptation du devis) requise";
        }
        if (!values.legalpersons) {
            errors.legalpersons = "Pour les personnes morales, nom du site des travaux ou nom de la copropriété requise";
        }
        if (!values.addressofworks) {
            errors.addressofworks = "Adresse des travaux requise";
        }
        if (!values.city) {
            errors.city = "Ville requise";
        }
        if (!values.individual_house) {
            errors.individual_house = "Maison individuelle existant depuis plus de 2 ans à la date d'engagement de l'opération requise";
        }
        if (!values.lowtemperature) {
            errors.lowtemperature = "Le système est couplé à des émetteurs de chauffage central de type basse température requise";
        }
        if (!values.installation_type) {
            errors.installation_type = "Type d’installation requise";
        }
        if (!values.cop) {
            errors.cop = "COP requise";
        }
        if (!values.brand) {
            errors.brand = "Marque requise";
        }
        if (!values.reference) {
            errors.reference = "Référence requise";
        }
        if (!values.nom) {
            errors.nom = "Nom requise";
        }
        if (!values.prénom) {
            errors.prénom = "Prénom requise";
        }
        if (!values.b2c_social_reason) {
            errors.b2c_social_reason = "Raison sociale requise";
        }
        if (!values.siret_number) {
            errors.siret_number = "N° SIREN requise";
        }

        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/estimation-page");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-6');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid px-4 py-3'>
                            <div className='row'>
                                <div className='col'>
                                    <p className='bar1-para'>A/ BAR-TH-143 (v. A51.5) : Mise en place d’un système solaire combiné (SSC) destiné au chauffage et à la
                                        production d’eau chaude sanitaire</p>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='commitmentdate'
                                                id='commitmentdate'
                                                value={values.commitmentdate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='dateofproof'
                                                id='dateofproof'
                                                value={values.dateofproof}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className='form-control1 mb-0 w-100' ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='invoicereference'
                                                id='invoicereference'
                                                value={values.invoicereference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className='form-control1 mb-0 w-100' ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Pour les personnes morales, nom du site des travaux ou nom de la copropriété :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                name='legalpersons'
                                                id='legalpersons'
                                                value={values.legalpersons}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.legalpersons && errors.legalpersons ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.legalpersons && errors.legalpersons && <div className="error-message">{errors.legalpersons}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                    <div className="col-lg-6 col-sm-12 text-left">
                                            <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                type='checkbox'
                                                id='same_address'
                                                checked={isSameAddressChecked}
                                                onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                            /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type='text'
                                                name='addressofworks'
                                                id='addressofworks'
                                                value={values.addressofworks}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                            {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type='text'
                                                name='additionaladdress'
                                                id='additionaladdress'
                                                value={values.additionaladdress}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Code postal : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='codepostal'
                                                id='codepostal'
                                                value={values.codepostal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Ville :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='city'
                                                id='city'
                                                value={values.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*Maison individuelle existant depuis plus de 2 ans à la date d'engagement de l'opération : </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="individual_house"
                                                id="individual_house"
                                                onChange={handleChange} 
                                                checked={values.individual_house === "Oui"}/>
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="individual_house"
                                                id="individual_house"
                                                onChange={handleChange} 
                                                checked={values.individual_house === "Non"}/>
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.individual_house && errors.individual_house && <div className="error-message">{errors.individual_house}</div>}
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*Le système est couplé à des émetteurs de chauffage central de type basse température : </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="OUI"
                                                name="lowtemperature"
                                                id="lowtemperature"
                                                onChange={handleChange} 
                                                checked={values.lowtemperature === "OUI"}/>
                                            <span className='span-bar bar1-para'> OUI</span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="lowtemperature"
                                                id="lowtemperature"
                                                onChange={handleChange} 
                                                checked={values.lowtemperature === "Non"}/>
                                            <span className='span-bar bar1-para '> NON</span>
                                        </label>
                                    </div>
                                    {touched.lowtemperature && errors.lowtemperature && <div className="error-message">{errors.lowtemperature}</div>}
                                    <hr></hr>
                                    <p className='bar1-para'><u>Caractéristique des capteurs solaires :</u></p>
                                    <p className="bar1-head  mt-4">*Type d’installation : </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="sur air extrait"
                                                name="installation_type"
                                                id="installation_type"
                                                onChange={handleChange} 
                                                checked={values.installation_type === "sur air extrait"}/>
                                            <span className='span-bar bar1-para'> sur air extrait </span>

                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="autres types d’installations"
                                                name="installation_type"
                                                id="installation_type"
                                                onChange={handleChange} 
                                                checked={values.installation_type === "autres types d’installations"}/>
                                            <span className='span-bar bar1-para '> autres types d’installations </span>
                                        </label>
                                    </div>
                                    {touched.installation_type && errors.installation_type && <div className="error-message">{errors.installation_type}</div>}
                                    <hr></hr>

                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*COP </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='cop'
                                                id='cop'
                                                value={values.cop}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.cop && errors.cop ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.cop && errors.cop && <div className="error-message">{errors.cop}</div>}
                                        </div>
                                    </div>

                                    <hr></hr>
                                    <p className='bar1-para'>NB : Le coefficient de performance (COP) de l’équipement est mesuré conformément aux conditions de la
                                        norme EN 16147. </p>
                                    <p className='bar1-para'>À ne remplir que si les marque et référence du chauffe-eau thermodynamique ne sont pas mentionnées sur la
                                        preuve de réalisation de l’opération : </p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Marque :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='brand'
                                                id='brand'
                                                value={values.brand}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.brand && errors.brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.brand && errors.brand && <div className="error-message">{errors.brand}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Référence : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='reference'
                                                id='reference'
                                                value={values.reference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.reference && errors.reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.reference && errors.reference && <div className="error-message">{errors.reference}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>Si le bénéficiaire est une personne physique, le professionnel qui procède à la réalisation de l’opération est
                                        titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues à l’article 2 du décret n° 2014-
                                        812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des
                                        impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris pour
                                        son application. <br></br> Ce signe de qualité correspond à des travaux relevant du 7 du I de l’article 46 AX de l’annexe III du code général
                                        des impôts </p>
                                    <p className='bar1-para'>Identité du professionnel titulaire du signe de qualité ayant réalisé l'opération, s'il n’est pas le signataire de cette
                                        attestation (sous-traitance par exemple) :</p>

                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Nom</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='nom'
                                                id='nom'
                                                value={values.nom}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.nom && errors.nom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.nom && errors.nom && <div className="error-message">{errors.nom}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Prénom</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='prénom'
                                                id='prénom'
                                                value={values.prénom}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.prénom && errors.prénom ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.prénom && errors.prénom && <div className="error-message">{errors.prénom}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Raison sociale : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='b2c_social_reason'
                                                id='b2c_social_reason'
                                                value={values.b2c_social_reason}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.b2c_social_reason && errors.b2c_social_reason ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.b2c_social_reason && errors.b2c_social_reason && <div className="error-message">{errors.b2c_social_reason}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*N° SIREN :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='siret_number'
                                                id='siret_number'
                                                value={values.siret_number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.siret_number && errors.siret_number ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.siret_number && errors.siret_number && <div className="error-message">{errors.siret_number}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className='row mt-5 mb-2'>
                                        <div className='col text-start'>
                                            <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                        </div>
                                        <div className="col text-end">
                                            <button type='submit' className='btns-color'>Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default CalcultBARTH143