import React, { useState, useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
// Import Redux toolkit
import { useNavigate } from 'react-router';
import axios from 'axios';
function Dossier() {
    const navigate = useNavigate();
    // INITIAL VALUES
    const initialValues = {
        serviceSelect: '',
        installer_id: '',
        oblige_id: '',
    };
    // VALIDATE
    const validate = (values) => {
        console.log("validate", {values});
        const errors = {};
        if (!values.serviceSelect) {
            errors.serviceSelect = "choisir l'option b2b ou b2c";
        }
        if (!values.installer_id || !values.oblige_id) {
            errors.installer_id = "sélectionner l'installateur et l'obliger";
        }
        return errors;
    }
    // Values Submit
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-2", JSON.stringify(values));
        if (values?.serviceSelect === 'b2b') {
            navigate('/create-dossier/b2b-beneficiaire');
        } else if (values?.serviceSelect === 'b2c') {
            navigate('/create-dossier/b2c');
        }
    }
    const [partnersDetails, setpartnersDetails] = useState([]);
    const [obligeDetails, setobligeDetails] = useState([]);

    useEffect(() => {
        const Token = localStorage.getItem('Token');
        const fetchData = async () => {
            const option = {
                headers:
                    { authorization: Token }
            }
            try {
                await axios.get(`${process.env.REACT_APP_API_URL}/getallname-installer`, option)
                    .then(res => {
                        console.log(res?.data);
                        setpartnersDetails(res?.data);
                    })
            } catch (error) {
                console.error(error);
            }
            try {
                await axios.get(`${process.env.REACT_APP_API_URL}/getallname-oblige`, option)
                    .then(res => {
                        console.log(res?.data);
                        setobligeDetails(res?.data);
                    })
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="container-fluid px-4">
                        <p className='bar1-head '> Je creé un dossier  </p>
                        <Row>
                            <Col>
                                <div className="b2bbutton">
                                    <input type="radio" id="b2b" name='serviceSelect' value="b2b" onChange={handleChange} />
                                    <label className="btn btn-default radio-createdesire" htmlFor="b2b">B2B</label>
                                </div>
                                <div className="b2bbutton">
                                    <input type="radio" id="b2c" name='serviceSelect' value="b2c" onChange={handleChange} />
                                    <label className="btn btn-default radio-createdesire" htmlFor="b2c">B2C</label>
                                </div>
                                {touched.serviceSelect && errors.serviceSelect && (
                                    <div className="error-message">{errors.serviceSelect}</div>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} md={12}>
                                <div className='top-marign '>
                                    <Form.Select name='installer_id' id='installer_id' onChange={handleChange} aria-label="PARTENAIRE/INSTALLATEUR" className='reside1 reside1 w-30'>
                                        <option>PARTENAIRE/INSTALLATEUR</option>
                                        {partnersDetails?.installer?.map((items) => (
                                            <option key={items?.id} value={items?.id}>{items?.tradename}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                                <div className='top-marign'>
                                    <Form.Select name='oblige_id' id='oblige_id' onChange={handleChange} aria-label="OBLIGÉ" className='reside1 reside1 w-30'>
                                        <option>OBLIGÉ</option>
                                        {obligeDetails?.oblige?.map((items) => (
                                            <option key={items?.id} value={items?.id}>{items?.tradename}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                                {touched.installer_id && errors.installer_id && (
                                    <div className="error-message">{errors.installer_id}</div>
                                )}
                            </Col>
                        </Row>
                        <div className='row mt-5 mb-2'>
                            <div className="col text-end">
                                <button type='submit' className='btns-color' >Suivant</button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default Dossier