import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function Residentiel() {
  const navigate = useNavigate();
  const [b2bOperations, setB2bOperations] = useState('');
  console.log('b2bOperations', b2bOperations);

  const handleChange = (values) => {
    var datas = { 'b2bOperations': b2bOperations, 'documents': values };
    console.log('values', values);
    if (values === 'BAR-EN-101') {
      navigate('/bar1');
    }
    if (values === 'BAR-EN-102') {
      navigate('/bar2');
    }
    if (values === 'BAR-EN-103') {
      navigate('/bar3');
    }
    if (values === 'BAR-EN-104') {
      navigate('/bar4');
    }
    if (values === 'BAR-SE-104') {
      navigate('/bar-se-104');
    }
    if (values === 'BAR-TH-160') {
      navigate('/bar160');
    }
    if (values === 'BAR-TH-161') {
      navigate('/bar161');
    }
    // Tertiare
    if (values === 'BAT-EN-101') {
      navigate('/bat101');
    }
    if (values === 'BAT-EN-102') {
      navigate('/bat102');
    }
    if (values === 'BAT-EN-103') {
      navigate('/bat103');
    }
    if (values === 'BAT-TH-116') {
      navigate('/bat116');
    }
    if (values === 'BAT-TH-146') {
      navigate('/bat146');
    }
    if (values === 'BAT-TH-104') {
      navigate('/bat104');
    }
    if (values === 'BAT-EQ-133') {
      navigate('/bat133');
    }
    if (values === 'BAT-TH-155') {
      navigate('/bat155');
    }
    if (values === 'RES-EC-104') {
      navigate('/RES-EC-104');
    }
    // RESEAU

    sessionStorage.setItem("step-5", JSON.stringify(datas));
  }
  // TAB LIST VALUES

  const handlePrev = () => {
    navigate(-1);
  };
  return (
    <div className="container-fluid px-4">
      <Tab.Container id="left-tabs-example">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column reside mt-0">
              <Nav.Item>
                <Nav.Link className='reside1' onClick={() => setB2bOperations('Résidentiel')} eventKey="Résidentiel">Résidentiel</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='reside1' onClick={() => setB2bOperations('Tertiaire')} eventKey="Tertiaire">Tertiaire</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='reside1' onClick={() => setB2bOperations('RESEAU')} eventKey="RESEAU">RESEAU</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane className='bg-white mt-4' eventKey="Résidentiel">
                <div className='reside-page  justify-content-between d-flex mt-4' >
                  <span onClick={() => handleChange('BAR-EN-101')} className='reside-content text-center links' >BAR-EN-101</span>
                  <span className='reside-content1'>Isolation des combles ou de toiture</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-2'>
                  <span onClick={() => handleChange('BAR-EN-102')} className='reside-content text-center links'>BAR-EN-102</span>
                  <span className='reside-content1  '>Isolation de mur ou façade</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-2 '>
                  <span onClick={() => handleChange('BAR-EN-103')} className='reside-content text-center links'>BAR-EN-103</span>
                  <span className='reside-content1'>Isolation du sol ou plancher</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-2'>
                  <span onClick={() => handleChange('BAR-EN-104')} className='reside-content text-center links'>BAR-EN-104</span>
                  <span className='reside-content1'>Remplacement de fenêtre ou porte fenêtre isolante
                    (double vitrage)</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-2'>

                  <span onClick={() => handleChange('BAR-SE-104')} className='reside-content text-center links'>BAR-SE-104</span>
                  <span className='reside-content1'>Equilibrage du chauffage entre appartements</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-2'>

                  <span onClick={() => handleChange('BAR-TH-160')} className='reside-content text-center links'>BAR-TH-160</span>
                  <span className='reside-content1'>Isolation d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-2'>
                  <span onClick={() => handleChange('BAR-TH-161')} className='reside-content text-center links'>BAR-TH-161</span>
                  <span className='reside-content1'>Isolation des points singuliers d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire</span>
                </div>

              </Tab.Pane>
              {/* SECOND */}
              <Tab.Pane className='bg-white mt-4' eventKey="Tertiaire">
                <div className='reside-page  justify-content-between d-flex mt-2'>
                  <span onClick={() => handleChange('BAT-EN-101')} className='reside-content text-center links'>BAT-EN-101</span>
                  <span className='reside-content1'>Isolation des combles ou de toiture</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-1'>
                  <span onClick={() => handleChange('BAT-EN-102')} className='reside-content text-center links'>BAT-EN-102</span>
                  <span className='reside-content1  '>Isolation de mur ou façade</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-1 '>
                  <span onClick={() => handleChange('BAT-EN-103')} className='reside-content text-center links'>BAT-EN-103</span>
                  <span className='reside-content1  '>Isolation du sol ou plancher</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-1'>

                  <span onClick={() => handleChange('BAT-TH-116')} className='reside-content text-center links'>BAT-TH-116</span>
                  <span className='reside-content1  '>Système de gestion technique du bâtiment tertiaire</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-1'>

                  <span onClick={() => handleChange('BAT-TH-146')} className='reside-content text-center links'>BAT-TH-146</span>
                  <span className='reside-content1  '>Calorifugeage de réseau de chauffage ou ECS</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-1'>

                  <span onClick={() => handleChange('BAT-TH-104')} className='reside-content text-center links'>BAT-TH-104</span>
                  <span className='reside-content1  '>Robinet thermostatique pour radiateurs</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-1'>
                  <span onClick={() => handleChange('BAT-EQ-133')} className='reside-content text-center links'>BAT-EQ-133</span>
                  <span className='reside-content1  '>Systèmes hydro-économes (France métropolitaine)</span>
                </div>
                <div className='reside-page  justify-content-between d-flex mt-1'>
                  <span onClick={() => handleChange('BAT-TH-155')} className='reside-content text-center links'>BAT-TH-155</span>
                  <span className='reside-content1  '>Isolation des points singuliers d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire</span>
                </div>
              </Tab.Pane>
              {/* THREE */}
              <Tab.Pane className='bg-white mt-4' eventKey="RESEAU">
                <div className='reside-page  justify-content-between d-flex mt-2'>
                  <span onClick={() => handleChange('RES-EC-104')} className='reside-content text-center links'>RES-EC-104</span>
                  <span className='reside-content1'>Rénovation d’éclairage extérieur</span>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <div className='row mt-5 mb-2'>
        <div className='col text-start'>
          <button type='button' onClick={handlePrev} className='btns-color' >Précédent </button>
        </div>
      </div>
    </div>
  )
};
export default Residentiel;