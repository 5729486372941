import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
const Calcult102Update = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    // ----------------------------------------------
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    // useEffect(() => {
    //     // Retrieve stored form values from local storage
    //     const storedFormValues = sessionStorage.getItem('step-6');

    //     if (storedFormValues) {
    //         // Parse the stored form values and set them as initial values
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.tertiarysector = parsedFormValues.tertiarysector;
    //         initialValues.codepostal = parsedFormValues.codepostal;
    //         initialValues.heatingEnergy = parsedFormValues.heatingEnergy;
    //         initialValues.buildingindustry = parsedFormValues.buildingindustry;
    //         initialValues.surface = parsedFormValues.surface;
    //     }
    // }, []);
    const [initialValues, setInitialValues] = useState(
        {
        tertiarysector: '',
        codepostal: '',
        heatingEnergy: '',
        buildingindustry: '',
        surface: '',
    });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            tertiarysector: MultiUpdate?.multisteptable2?.tertiarysector || '',
            codepostal: MultiUpdate?.multisteptable2?.codepostal || '',
            heatingEnergy: MultiUpdate?.multisteptable2?.heatingEnergy || '',
            buildingindustry: MultiUpdate?.multisteptable2?.buildingindustry || '',
            surface:  MultiUpdate?.multisteptable2?.surface || '',
        }));
    }, []);
    const validate = (values) => {
        const errors = {};
        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }

        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/update-estimate");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    // useEffect(() => {
    //     const storedFormData = sessionStorage.getItem('step-6');
    //     if (storedFormData) {
    //         setStoredData(JSON.parse(storedFormData));
    //     }
    // }, []);
  return (
    <div>
       <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className='container-fluid  '>
                        <div className='row'>
                            <div className='col'>
                                <p className='bar1-para '>BAT-EN-102 : Isolation des murs</p>
                                <p className="bar1-head  mt-4">Bâtiment secteur tertiaire existant depuis plus de 2 ans ? </p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="tertiarysector"
                                            id="tertiarysector"
                                            value="yes"
                                            onChange={handleChange}
                                            checked={values.tertiarysector === "yes"} />
                                        <span className='span-bar bar1-para'>
                                            Oui
                                        </span>
                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="tertiarysector"
                                            id="tertiarysector"
                                            value="no"
                                            onChange={handleChange}
                                            checked={values.tertiarysector === "no"} />
                                        <span className='span-bar bar1-para'>
                                            Non
                                        </span>
                                    </label>
                                </div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>

                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-sm-12">
                                        <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <input
                                            type="number"
                                            name='codepostal'
                                            id='codepostal'
                                            value={values.codepostal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="row">
                                    <div className="col-4">
                                        <p className='bar1-head mt-3'>Surface totale isolée (en m²) :</p></div>
                                    <div className="col-4 text-start align-items-center d-flex"><input type="number"
                                        name="surface"
                                        id="surface"
                                        value={values.surface}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.surface && errors.surface ? 'error' : 'form-control1'}
                                        placeholder='0,00' ></input>
                                    </div>
                                </div>
                                <hr></hr>

                                <p className="bar1-head  mt-4">Énergie de chauffage utilisée : </p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="heatingEnergy"
                                            id="heatingEnergy"
                                            value="Electricité"
                                            onChange={handleChange}
                                            checked={values.heatingEnergy === "Electricité"} />
                                        <span className='span-bar bar1-para'>
                                            Electricité
                                        </span></label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="heatingEnergy"
                                            id="heatingEnergy"
                                            value="Gaz"
                                            onChange={handleChange}
                                            checked={values.heatingEnergy === "Gaz"} />
                                        <span className='span-bar bar1-para '>
                                            Gaz (de ville, butane, propane...)
                                        </span>
                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="heatingEnergy"
                                            id="heatingEnergy"
                                            value="Fioul"
                                            onChange={handleChange}
                                            checked={values.heatingEnergy === "Fioul"} />
                                        <span className='span-bar bar1-para '>
                                            Fioul
                                        </span>
                                    </label> </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="heatingEnergy"
                                            id="heatingEnergy"
                                            value="Bois"
                                            onChange={handleChange}
                                            checked={values.heatingEnergy === "Bois"} />
                                        <span className='span-bar bar1-para '> Bois (biomasse) </span>
                                    </label></div>
                                <div className='button-1 '>
                                    <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                </div><hr></hr>
                                <p className="bar1-head  mt-4">Secteur d'activité du bâtiment :</p>
                                <div className='text-start'>
                                    <label className='para-bar1 '>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Bureaux"
                                            onChange={handleChange}
                                            checked={values.buildingindustry === "Bureaux"} />
                                        <span className='span-bar bar1-para'>
                                            Bureaux
                                        </span></label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Enseignement"
                                            onChange={handleChange}
                                            checked={values.buildingindustry === "Enseignement"} />
                                        <span className='span-bar bar1-para '>
                                            Enseignement
                                        </span>
                                    </label>
                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Commerces"
                                            onChange={handleChange}
                                            checked={values.buildingindustry === "Commerces"} />
                                        <span className='span-bar bar1-para '>
                                            Commerces
                                        </span>
                                    </label>  </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Hôtellerie - Restauration"
                                            onChange={handleChange}
                                            checked={values.buildingindustry === "Hôtellerie - Restauration"} />
                                        <span className='span-bar bar1-para '>
                                            Hôtellerie / Restauration
                                        </span>
                                    </label>

                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Santé"
                                            onChange={handleChange}
                                            checked={values.buildingindustry === "Santé"} />
                                        <span className='span-bar bar1-para '>
                                            Santé
                                        </span>
                                    </label>

                                </div>
                                <div className='text-start mt-2'>
                                    <label className='para-bar1 text-initical'>
                                        <input className="form-check-input"
                                            type="radio"
                                            name="buildingindustry"
                                            id="buildingindustry"
                                            value="Autres secteurs tertiaires"
                                            onChange={handleChange}
                                            checked={values.buildingindustry === "Autres secteurs tertiaires"} />
                                        <span className='span-bar bar1-para '> Autres secteurs tertiaires </span>
                                    </label>
                                </div>
                                <div className='row mt-5 mb-2'>
                                    <div className='col text-start'>
                                        <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                    </div>
                                    <div className="col text-end">
                                        <button type='submit' className='btns-color'>Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    </div>
  )
}

export default Calcult102Update
