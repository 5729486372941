import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";

const Calcult146Update = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
    const MultiUpdate = JSON.parse(sessionStorage.getItem('multiupdate'));
    console.log(MultiUpdate);
    // ----------------------------------------------
    // useEffect(() => {
    //     const storedFormValues = sessionStorage.getItem('step-6');

    //     if (storedFormValues) {
    //         const parsedFormValues = JSON.parse(storedFormValues);
    //         initialValues.tertiarysector = parsedFormValues.tertiarysector;
    //         initialValues.codepostal = parsedFormValues.codepostal;
    //         initialValues.replaced_after_2018 = parsedFormValues.replaced_after_2018;
    //         initialValues.oldinsulation = parsedFormValues.oldinsulation;
    //         initialValues.hydraulicnetwork = parsedFormValues.hydraulicnetwork;
    //         initialValues.primeamount = parsedFormValues.primeamount;
    //     }
    // }, []);
    const [initialValues, setInitialValues] = useState(
        {
            tertiarysector: '',
            codepostal: '',
            replaced_after_2018: '',
            oldinsulation: '',
            hydraulicnetwork: '',
            primeamount: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            tertiarysector: MultiUpdate?.multisteptable2?.tertiarysector || '',
            codepostal: MultiUpdate?.multisteptable2?.codepostal || '',
            replaced_after_2018: MultiUpdate?.multisteptable2?.replaced_after_2018 || '',
            oldinsulation: MultiUpdate?.multisteptable2?.oldinsulation || '',
            hydraulicnetwork: MultiUpdate?.multisteptable2?.hydraulicnetwork || '',
            primeamount: MultiUpdate?.multisteptable2?.primeamount || '',
        }));
    }, []);
    const validate = (values) => {
        const errors = {};
        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.hydraulicnetwork) {
            errors.hydraulicnetwork = "Longueur totale du (ou des) réseau(x) hydraulique(s) isolé(s) (en m linéaire) requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/update-estimate");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    // useEffect(() => {
    //     const storedFormData = sessionStorage.getItem('step-6');
    //     if (storedFormData) {
    //         setStoredData(JSON.parse(storedFormData));
    //     }
    // }, []);
    return (
        <div>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid  '>
                            <div className='row'>
                                <div className='col'>
                                    <p className='bar1-para '>Calculez et obtenez votre prime / Votre projet : Calorifugeage de réseau de chauffage ou ECS</p>
                                    <p className="bar1-head  mt-4">Bâtiment(s) tertiaire(s) et existant(s) depuis plus de 2 ans ?</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="tertiarysector"
                                                id="tertiarysector"
                                                value="yes"
                                                onChange={handleChange}
                                                checked={values.tertiarysector === "yes"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span> </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                name="tertiarysector"
                                                id="tertiarysector"
                                                value="no"
                                                onChange={handleChange}
                                                checked={values.tertiarysector === "no"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-4 col-sm-12">
                                            <p className='bar1-head mt-3'>Code postal de l'adresse des travaux* :</p>
                                        </div>
                                        <div className="col-lg-3 col-sm-12">
                                            <input
                                                type="number"
                                                name='codepostal'
                                                id='codepostal'
                                                value={values.codepostal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">La chaudière ou le réseau hydraulique a été refait/remplacé après le 01/01/2018 ?</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value='Yes'
                                                name="replaced_after_2018"
                                                id="replaced_after_2018"
                                                onChange={handleChange}
                                                checked={values.replaced_after_2018 === "Yes"} />
                                            <span className='span-bar bar1-para'> Oui</span></label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value='NO'
                                                name="replaced_after_2018"
                                                id="replaced_after_2018"
                                                onChange={handleChange}
                                                checked={values.replaced_after_2018 === "No"} />
                                            <span className='span-bar bar1-para '>Non, aucune modification après le 01/01/2018</span>
                                        </label></div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>
                                    <p className="bar1-head  mt-4">Une ancienne isolation existe sur ces canalisations ? </p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input className="form-check-input"
                                                type="radio"
                                                value='Yes'
                                                name="oldinsulation"
                                                id="oldinsulation"
                                                onChange={handleChange}
                                                checked={values.oldinsulation === "Yes"} />
                                            <span className='span-bar bar1-para'> Oui (totalement ou partiellement isolés)</span> </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input className="form-check-input"
                                                type="radio"
                                                value='No'
                                                name="oldinsulation"
                                                id="oldinsulation"
                                                onChange={handleChange}
                                                checked={values.oldinsulation === "No"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>

                                    </div>
                                    <div className='button-1 '>
                                        <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                    </div><hr></hr>
                                    <div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-8 col-sm-12">
                                                <p className='bar1-head mt-3'>Longueur totale du (ou des) réseau(x) hydraulique(s) isolé(s) (en m linéaire)* :</p>
                                            </div>
                                            <div className="col-lg-4 col-sm-12">
                                                <input
                                                    type="number"
                                                    name='hydraulicnetwork'
                                                    id='hydraulicnetwork'
                                                    value={values.hydraulicnetwork}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.hydraulicnetwork && errors.hydraulicnetwork ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                                {touched.hydraulicnetwork && errors.hydraulicnetwork && <div className="error-message">{errors.hydraulicnetwork}</div>}
                                            </div>
                                        </div>
                                        <div className='button-1 '>
                                            <button type='button' className='btn-on h-auto w-auto p-1' >d’infos <BsArrowRight className='btn-on' /> </button>
                                        </div><hr></hr>
                                    </div>
                                    <p className='bar1-head mt-3'>Définir le montant de la prime</p>
                                    <div className='text-start'>
                                        <label>
                                            <input
                                                type="text"
                                                name='primeamount'
                                                id='primeamount'
                                                value={values.primeamount}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="input-box"  ></input>
                                            <span className='span-bar bar1-para '>Euros du Méga / Prix du kilowater</span>
                                        </label>

                                    </div><hr></hr>
                                    <div className='row mt-5 mb-2'>
                                        <div className='col text-start'>
                                            <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                        </div>
                                        <div className="col text-end">
                                            <button type='submit' className='btns-color'>Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default Calcult146Update
