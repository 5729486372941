import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import B2b_Beneficiary from './B2b_Beneficiary';
import B2b_Worksite from './B2b_Worksite';

function B2b() {
    const [key, setKey] = useState('');

    return (
        <div className='container-fluid px-4'>
            <Tabs
                id="justify-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 tab-btn-style"
                fill
            >
                <Tab eventKey="Bénéficiaire" className='bg-white' title="Bénéficiaire">
                    <B2b_Beneficiary setKey={setKey} />
                </Tab>
                <Tab eventKey="Chantier" className='bg-white' title="Chantier">
                    <B2b_Worksite />
                </Tab>
            </Tabs>
        </div >
    )
}

export default B2b