
import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from "react-router-dom";
// FORMIK
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import axios from 'axios';

const InfosB2C = (props) => {
    const toastsuccess = (val) => toast.success(val);
    const toasterror = (val) => toast.error(val);
    const Token = localStorage.getItem('Token');
    const MultiUpdate = props.MultiUpdate;
    console.log('MultiUpdate', MultiUpdate);
    const [initialValues, setInitialValues] = useState(
        {
            id: MultiUpdate?.multisteptable1?.id,
            chantier_chooseType: '',
            nomduclient: '',
            prénomduclient: '',
            beneficiary_email: '',
            nfixe: '',
            nmobile: '',
            beneficiary_address: '',
            beneficiary_cp: '',
            beneficiary_city: '',
            adressedestravaux2: '',
            nfiscal: '',
            nreffiscal: '',
            menages: '',
            taxnotice: false,
            chantier_buildingFloorArea: '',
        });
    useEffect(() => {
        setInitialValues((prevState) => ({
            ...prevState,
            id: MultiUpdate?.multisteptable1?.id,
            chantier_chooseType: MultiUpdate?.multisteptable1?.chantier_choosetype || '',
            nomduclient: MultiUpdate?.multisteptable2?.nomduclient || '',
            prénomduclient: MultiUpdate?.multisteptable2?.prénomduclient || '',
            beneficiary_email: MultiUpdate?.multisteptable2?.nomduclient || '',
            nfixe: MultiUpdate?.multisteptable2?.nfixe || '',
            nmobile: MultiUpdate?.multisteptable2?.nmobile || '',
            beneficiary_address: MultiUpdate?.multisteptable2?.beneficiary_address || '',
            beneficiary_cp: MultiUpdate?.multisteptable2?.beneficiary_cp || '',
            beneficiary_city: MultiUpdate?.multisteptable2?.beneficiary_city || '',
            adressedestravaux2: MultiUpdate?.multisteptable2?.adressedestravaux2 || '',
            nfiscal: MultiUpdate?.multisteptable2?.nfiscal || '',
            nreffiscal: MultiUpdate?.multisteptable2?.nreffiscal || '',
            menages: MultiUpdate?.multisteptable2?.menages || '',
            taxnotice: MultiUpdate?.multisteptable2?.taxnotice || false,
            chantier_buildingFloorArea: MultiUpdate?.multisteptable1?.chantier_buildingfloorarea || '',
        }));
    }, [MultiUpdate]);
    // VALIDE
    const validate = (values) => {
        const errors = {};


        return errors;
    };

    const handleSubmit = async (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-4", JSON.stringify(values));
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/update-multistep`, values,
                {
                    headers: { authorization: Token }
                }).then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        console.log('res.message', response?.data?.message);
                        toastsuccess(response?.data?.message);
                    }
                })
        } catch (error) {
            console.log(error.response?.data?.message);
            toasterror(error.response?.data?.message);
        }
    }
    const installerorobligelocal = localStorage.getItem('installerorobligelocal');
    console.log('installerorobligelocal', installerorobligelocal);
    return (
        <>

            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="container-fluid pb-4">
                            <div className="save_button_container d-flex justify-content-between align-items-center">
                                <p className="bar1-head  mt-4">Infos client et chantier</p> <button type='submit' className='btns-color'>Sauvegarder</button>
                            </div>
                            <div className='row'>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para '>*Mes bénéficiaires</p>
                                    <div className=''>
                                        <select name='chantier_chooseType'
                                            value={values.chantier_chooseType}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.chantier_chooseType && errors.chantier_chooseType ? 'error' : 'form-select ps-3 btn-buttons'}
                                            aria-label="Default select example">
                                            <option defaultValue>Choisir un type</option>
                                            <option value="Classique">Classique</option>
                                            <option value="QPV">QPV </option>
                                            {/* <option value="Bailleursocial">Bailleur social</option> */}
                                        </select>
                                        {touched.chantier_chooseType && errors.chantier_chooseType && <div className="error-message">{errors.chantier_chooseType}</div>}
                                    </div>
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>*Nom du client</p>
                                    <input
                                        type="text"
                                        name="nomduclient"
                                        value={values.nomduclient}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.nomduclient && errors.nomduclient ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.nomduclient && errors.nomduclient && <div className="error-message">{errors.nomduclient}</div>}
                                </div>
                                <div className='col-sm-5 input-box1'>
                                    <p className='bar1-para'>*Prénom du client</p>
                                    <input type="text"
                                        name="prénomduclient"
                                        value={values.prénomduclient}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.prénomduclient && errors.prénomduclient ? 'error' : 'form-control1 w-100 m-0'} >
                                    </input>
                                    {touched.prénomduclient && errors.prénomduclient && <div className="error-message">{errors.prénomduclient}</div>}
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>*Email</p>
                                    <input type="text"
                                        name="beneficiary_email"
                                        value={values.beneficiary_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_email && errors.beneficiary_email ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.beneficiary_email && errors.beneficiary_email && <div className="error-message">{errors.beneficiary_email}</div>}
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>N fixe</p>
                                    <input type="number"
                                        name="nfixe"
                                        value={values.nfixe}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.nfixe && errors.nfixe ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.nfixe && errors.nfixe && <div className="error-message">{errors.nfixe}</div>}
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>N mobile</p>
                                    <input type="text"
                                        name="nmobile"
                                        value={values.nmobile}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.nmobile && errors.nmobile ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.nmobile && errors.nmobile && <div className="error-message">{errors.nmobile}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>*Adresse des travaux</p>
                                    <input type="text" name="beneficiary_address"
                                        value={values.beneficiary_address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_address && errors.beneficiary_address ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.beneficiary_address && errors.beneficiary_address && <div className="error-message">{errors.beneficiary_address}</div>}
                                </div>
                                <div className='col-sm-2 input-box1'>
                                    <p className='bar1-para'>*CP des travaux</p>
                                    <input type="number" name="beneficiary_cp"
                                        value={values.beneficiary_cp}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_cp && errors.beneficiary_cp ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.beneficiary_cp && errors.beneficiary_cp && <div className="error-message">{errors.beneficiary_cp}</div>}
                                </div>
                                <div className='col-sm-4 input-box1'>
                                    <p className='bar1-para'>*Ville des travaux</p>
                                    <input type="text" name="beneficiary_city"
                                        value={values.beneficiary_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.beneficiary_city && errors.beneficiary_city ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.beneficiary_city && errors.beneficiary_city && <div className="error-message">{errors.beneficiary_city}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>*Adresse des travaux</p>
                                    <input type="text" name="adressedestravaux2"
                                        value={values.adressedestravaux2}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.adressedestravaux2 && errors.adressedestravaux2 ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.adressedestravaux2 && errors.adressedestravaux2 && <div className="error-message">{errors.adressedestravaux2}</div>}
                                </div>
                                <div className='col-sm-3 input-box1 text-center'>
                                    <div className='ms-2 mt-42'>
                                        <input type="checkbox"
                                            name='taxnotice'
                                            id='taxnotice'
                                            checked={values.taxnotice}
                                            onChange={handleChange} />
                                        <span className='span-bar bar1-para'>
                                            Verifier l’avis d’impot
                                        </span>
                                        {touched.taxnotice && errors.taxnotice && (
                                            <div className="error-message">{errors.taxnotice}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-3 input-box1'>
                                    <p className='bar1-para'>Menages</p>
                                    <input type="text" name="menages"
                                        value={values.menages}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.menages && errors.menages ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.menages && errors.menages && <div className="error-message">{errors.menages}</div>}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>N° fiscal</p>
                                    <input type="text" name="nfiscal"
                                        value={values.nfiscal}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.nfiscal && errors.nfiscal ? 'error' : 'form-control1 w-100 m-0'} ></input>
                                    {touched.nfiscal && errors.nfiscal && <div className="error-message">{errors.nfiscal}</div>}
                                </div>
                                <div className='col-sm-6 input-box1'>
                                    <p className='bar1-para'>N° Ref fiscal</p>
                                    <input type="text" name="nreffiscal"
                                        value={values.nreffiscal}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.nfiscal && errors.nreffiscal ? 'error' : 'form-control1 w-100 m-0'}></input>
                                    {touched.nreffiscal && errors.nreffiscal && <div className="error-message">{errors.nreffiscal}</div>}
                                </div>

                            </div>
                            {/* <div className='row mt-3 mb-3'>
                            <div className='col-sm-6 input-box1  '>
                                <p className='bar1-para m-0'>Surface au sol du batiment*</p>
                                <input type="number"
                                    name="chantier_buildingFloorArea"
                                    value={values.chantier_buildingFloorArea}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.chantier_buildingFloorArea && errors.chantier_buildingFloorArea ? 'error' : 'form-cont w-100 ms-0'} />
                                {touched.chantier_buildingFloorArea && errors.chantier_buildingFloorArea && <div className="error-message">{errors.chantier_buildingFloorArea}</div>}
                            </div>
                        </div> */}
                            {installerorobligelocal === 'Super Manager' ? (
                                <>
                                    <p className='bar1-head mt-5'>PARTENAIRE/INSTALLATEUR</p>
                                    <div className="row">
                                        <div className="col"><input type='text' value={MultiUpdate?.multisteptable2?.partenaire_name} readOnly className='form-control1'></input> &nbsp;
                                            <Link to={`/update-installer/${MultiUpdate?.multisteptable1?.installer_id}`} target='_blank' rel='noopener noreferrer' className='bar1-para '>
                                                view partenaire tarif valo
                                            </Link></div>
                                    </div>
                                    <p className='bar1-head mt-5'>OBLIGÉ</p>
                                    <div className="row  mb-3">
                                        <div className="col"><input type='text' value={MultiUpdate?.multisteptable2?.oblige_name} readOnly className='form-control1'></input> &nbsp;
                                            <Link to={`/update-oblige/${MultiUpdate?.multisteptable1?.oblige_id}`} target='_blank' rel='noopener noreferrer' className='bar1-para '>
                                                view oblige tarif valo
                                            </Link></div>
                                    </div>
                                </>) : null}
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default InfosB2C