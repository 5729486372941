import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs";
function Barth125() {
    const navigate = useNavigate();
    const handlePrev = () => {
      navigate(-1);
    };
    return (
        <div className='container-fluid py-3 px-4'>
            <div className='row'>
                <p className='bar1-para '>BAR-TH-125 : Système de ventilation double flux autoréglable ou modulé à haute performance (France métropolitaine) <span onClick={handlePrev}  className='border-0 bg-white pe-auto'><BsArrowLeft className='left-arow pe-auto' /></span></p>
                <div className='button-1 mt-1'>
                    <Link to={"/calcult-bar-th-125"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
                </div>
                <div className='col bar1-tittle'>
                    <div className='bar1-tittle'>
                        <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                            <h3 className='bar1-head'>1. Secteur d’application</h3>
                            <p className='bar1-para'>Bâtiments résidentiels existants en France métropolitaine. </p>
                            <h3 className='bar1-head'>2. Dénomination</h3>
                            <p className='bar1-para'>Mise en place d’un système de ventilation mécanique contrôlée double flux autoréglable en installation individuelle
                                ou collective, ou modulé avec bouches d’extraction hygroréglables en installation individuelle seulement.  </p>
                            <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
                            <p className='bar1-para'>La mise en place est réalisée par un professionnel.  </p>
                            <p className='bar1-para'>Pour les opérations engagées à compter du 1er janvier 2021, le professionnel réalisant l’opération est titulaire d’un
                                signe de qualité conforme aux exigences prévues à l’article 2 du décret n° 2014-812 du 16 juillet 2014 pris pour
                                l’application du second alinéa du 2 de l’article 200 quater du code général des impôts et du dernier alinéa du 2 du I
                                de l’article 244 quater U du code général des impôts et des textes pris pour son application. Ce signe de qualité
                                correspond à des travaux relevant du 8° du I de l'article 1er du décret précité.
                            </p>
                            <p className='bar1-para'>Pour les installations individuelles (un seul logement desservi par le système de ventilation), le caisson de ventilation
                                doit être de classe d’efficacité énergétique A ou supérieure selon le règlement européen (UE) n° 1254/2014 de la
                                Commission  du  11  juillet  2014.  L’échangeur  présente  une  efficacité  thermique  mesurée  selon  la  norme
                                NF EN 13141-7 supérieure à 85% certifiée par un organisme établi dans l’Espace économique européen et accrédité
                                selon la norme NF EN ISO/CEI 17065 par le comité français d’accréditation (COFRAC) ou tout autre organisme
                                d’accréditation signataire de l’accord européen multilatéral pertinent dans le cadre de la coordination européenne
                                des organismes d’accréditation. Est réputé satisfaire cette exigence d’efficacité thermique, un caisson de ventilation
                                certifié NF 205 ou équivalent.  </p>
                            <p className='bar1-para'>Pour les installations collectives (plusieurs logements desservis) :  <br></br>
                                <span className='ms-4'>- le caisson double flux est collectif ;</span><br></br>
                                <span className='ms-4'>- l’échangeur statique est collectif et a une efficacité supérieure ou égale à 75 % selon les normes NF EN 308 ou
                                    NF EN 51-763. Est réputé satisfaire cette exigence d’efficacité, un échangeur statique collectif certifié Eurovent
                                    Certified  Performance  Echangeurs  à  plaques air-air  (AAHE)  ou  Echangeur régénératif (AARE)  ou  possédant
                                    des caractéristiques de performance et de qualité équivalentes établies par un organisme implanté dans l’Espace
                                    économique européen et accrédité selon la norme NF EN ISO/CEI 17065 par le comité français d’accréditation
                                    (COFRAC) ou tout autre organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris
                                    dans le cadre de la coordination européenne des organismes d’accréditation. </span>
                            </p>
                            <p className='bar1-para'>La preuve de réalisation de l’opération mentionne :  <br></br>
                                <span className='ms-4'>- la mise en place d’un système de ventilation mécanique contrôlée double flux composé d’un caisson de
                                    ventilation double flux, d’un échangeur statique et selon  le cas, de bouches d’extraction autoréglables ou
                                    hygroréglables ;</span><br></br>
                                <span className='ms-4'>- dans le cas d’une installation collective, l’efficacité énergétique de l’échangeur statique déterminée selon la
                                    norme  NF EN 51-763  ou  NF EN 308,  ou  faisant  référence  à  la  certification  Eurovent  Certified  Performance
                                    Echangeurs à plaques air-air (AAHE) ou Echangeur régénératif (AARE) du matériel ;  </span>
                                <span className='ms-4'>-  dans le cas d’une installation individuelle :  la  classe  énergétique  du  caisson  de  ventilation  double  flux  et
                                    l’efficacité  thermique  de  l’échangeur  mesurée  selon  la  norme  NF EN 13141-7  ou  faisant  référence  à  la
                                    certification NF 205 ou équivalent.   </span>
                            </p>
                            <p className='bar1-para'>SA défaut, la preuve de réalisation de l’opération mentionne la mise en place des équipements avec leurs marques et
                                références et elle est accompagnée d’un ou plusieurs document(s) issu(s) du fabricant indiquant que les équipements
                                installés constituent un système de ventilation double flux composé d’un caisson de ventilation double flux avec un
                                échangeur statique, et selon le cas, de bouches d’extraction autoréglables ou hygroréglables. </p>
                            <p className='bar1-para'>
                                Dans le cas d’une installation collective, ce document précise l’efficacité énergétique de l’échangeur statique,
                                déterminée  selon  la  norme  NF EN 51-763  ou  NF EN 308  ou  en  référence  à  la  certification  Eurovent  Certified
                                Performance  Echangeurs  à plaques  air-air (AAHE)  ou Echangeur régénératif (AARE)  de  cet équipement ou son
                                équivalent. Dans le cas d’une installation individuelle, ce document précise la classe énergétique du caisson de
                                ventilation double flux et l’efficacité thermique de l’échangeur mesurée selon la norme NF EN 13141-7 ou faisant
                                référence à la certification NF 205 ou équivalent.</p><h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                                <span className='bar1-para'>17 ans.</span></h3> <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac<b></b>
                                <p className='bar1-ul bar1-para '>Installation d’une ventilation mécanique contrôlée double flux autoréglable </p></p>
                            <p className='bar1-ul bar1-para '>Pour une installation collective : </p>

                            <div className="row">
                                <div className="col-4">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th >Zone climatique</th>
                                                <th >Montant en kWh cumac
                                                    par logement </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'><td>H1</td>
                                                <td>23 000</td> </tr>
                                            <tr className='bar1-para text-center'> <td>H2</td>
                                                <td>18 8400</td></tr>
                                            <tr className='bar1-para text-center'><td>H3</td>
                                                <td>12 500</td></tr> </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-1 ">
                                    <h1 className='postion-tops '>*</h1>
                                </div>
                                <div className="col-lg-3">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th colSpan="3">Nombre de
                                                    logements</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center h-100'><td className='paddings-bottom  text-center'>N</td></tr></tbody>
                                    </table> </div>
                            </div>
                            <p className='bar1-ul bar1-para mt-3'>Pour une installation individuelle : </p>
                            <div className="row">
                                <div className="col-4">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th >Zone climatique</th>
                                                <th >Montant en kWh cumac </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'><td>H1</td>
                                                <td>39 700</td></tr>
                                            <tr className='bar1-para text-center'><td>H2</td>
                                                <td>32 500</td></tr>
                                            <tr className='bar1-para text-center'><td>H3</td>
                                                <td>21 600</td></tr></tbody>
                                    </table>
                                </div>
                                <div className="col-lg-1 ">
                                    <h1 className='postion-tops '>*</h1>
                                </div>
                                <div className="col-4">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th >Facteur correctif
                                                    selon la surface
                                                    habitable</th>
                                                <th >Surface habitable (m²)  </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'><td>0.3</td>
                                                <td>&lt; 35 </td></tr>
                                            <tr className='bar1-para text-center'><td>0.5</td>
                                                <td>35 &le; S &lt; 60</td></tr>
                                            <tr className='bar1-para text-center'><td>0.6</td>
                                                <td>60 &le; S &lt; 70</td></tr>
                                            <tr className='bar1-para text-center'><td>0.7</td>
                                                <td>70 &le; S &lt; 90</td></tr>
                                            <tr className='bar1-para text-center'><td>1</td>
                                                <td>90 &le; S &lt; 110</td></tr>
                                            <tr className='bar1-para text-center'> <td>1.1</td>
                                                <td>10 &le; S &le; 130</td> </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>1.6</td>
                                                <td> &gt; 130</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='bar1-content mt-4 pb-4'>
                                <p className='bar1-para para-bar '>Fourniture et pose par un professionnel RGE d’un procédé d’isolation en comble perdu ou en rampant de toiture dans un bâtiment résidentiel existant. Un pare-vapeur (ou équivalent) est posé pour protéger l’isolant thermique de l'humidité et de la condensation. </p>
                                <p className="bar1-head para-bar1 ">Éligible si : </p>
                                <ul className='ul-bar1'>  <li><span className='bar1-ul '>Le devis : </span>comporte la date de la pré-visite technique.</li>
                                    <li><span className='bar1-ul'>Le début des travaux</span> respecte un délai<span className='bar1-ul'> de 8 jours après la date de signature</span> de la commande.</li>
                                    <li><span className='bar1-ul'>L’isolant :</span> a une résistance thermique ≥ à 7 m².K/W en comble perdu et ≥ à 6 m².K/W en rampant de toiture, est certifié ACERMI (ou équivalent), est de classe A de protection au feu (ou équivalent ou pose d’une couche supplémentaire).</li>
                                    <li><span className='bar1-ul'>Sont installés :</span>  un coffrage autour des conduits de fumées et des éclairages encastrés ; une rehausse rigide au niveau de la trappe d’accès.</li></ul>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>La facture indique obligatoirement :</span> fourniture et pose d’une isolation de comble ou toiture, marque, référence, épaisseur, résistance thermique, n° ACERMI et surface réellement isolée (+ l’identité du sous-traitant, si c’est le cas), les aménagements réalisés, la présence d’un pare-vapeur + la date de pré-visite technique. </p>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>Contrôle post-chantier :</span> tous les chantiers font l’objet d’un questionnaire de satisfaction client, certains font l’objet d’une inspection qualité post-travaux par un organisme indépendant (attesté COFRAC).</p>

                                <p className='bar1-para para-bar1'><span className='bar1-head '>Attention :</span> Sans modification sous 5 ans, cette opération sera abrogée à partir du 1er mai 2027.</p>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime. En cliquant sur le Info , vous obtenez plus de détail sur une question.</p>

                            </div>
                            {/* <div className='button-1'>
                                <button type='button' className='btn-on' >Site du Ministère <TbMathGreater /> </button>
                            </div>
                            <div className='button-1 mb-3'>
                                <button type='button' className='btn-on' ><AiOutlineDownload />Télécharger le fiche </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Barth125;