import React from 'react'
import { Link } from "react-router-dom"
const BATTH116Update = () => {
  return (
    <>
    <div className='container-fluid  '>
            <div className='row'>
                <p className='bar1-para '>BAT-TH-116 : Système de GTB pour le chauffage et l’eau chaude sanitaire

                </p>
                <div className='button-1 mt-1'>
                    <Link to={"/update-calcult-batth116"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
                </div>
                <div className='col bar1-tittle'>
                    <div className='bar1-tittle'>
                        <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                            <h3 className='bar1-head'>1. Secteur d’application</h3>
                            <p className='bar1-para'>Bâtiment tertiaire existant.
                            </p>
                            <h3 className='bar1-head'>2. Dénomination</h3>
                            <p className='bar1-para'>Mise en place d’un système de gestion technique du bâtiment pour un usage chauffage ou un usage chauffage et eau chaude sanitaire.</p>
                            <h3 className='bar1-head m-0'>3. Conditions pour la délivrance de certificats</h3>
                            <p className='bar1-para '>Le système de gestion technique du bâtiment assure, par un système d’automatisation centralisé, les fonctions de régulation de classe B ou A au sens de la norme NF EN 15232-1 pour les usages chauffage et, le cas échéant, eau chaude sanitaire.<br></br>

                            </p>
                            <p className='bar1-para m-0'>La mise en place est réalisée par un professionnel.</p>
                            <p className='bar1-para '>La preuve de réalisation de l’opération mentionne la mise en place d’un système de gestion technique du bâtiment assurant, par un système d’automatisation centralisé, les fonctions de régulation de classe B ou A au sens de la norme NF EN 15232-1.À défaut, la preuve de réalisation mentionne la mise en place d’un système avec ses marque et référence et elle est accompagnée d’un document issu du fabricant indiquant que le système de marque et référence installé est un système de gestion technique du bâtiment assurant, par un système d’automatisation centralisé, les fonctions de régulation de classe B ou A au sens de la norme NF EN 15232-1.</p>
                            <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                                <span className='bar1-para'>15 ans.</span></h3>
                            <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac<br></br>
                                <span className='bar1-para'>S est la surface chauffée gérée par le système de gestion technique du bâtiment.</span></p>
                            <p className='bar1-para '>Pour un système de gestion technique du bâtiment installé de classe A :</p>
                            <div className="row">
                                <div className="col-7">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th colSpan="6">Montant en kWh cumac par m<sup>2</sup> de surface geree par le systeme pour I'usage considere</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'>
                                                <td>Secteur d'activite</td>
                                                <td>Chauffage</td>
                                                <td>Refroidissement Climatisation</td>
                                                <td>ECS</td>
                                                <td>Eclairage</td>
                                                <td>Auxiliaire</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Bureaux</td>
                                                <td>400</td>
                                                <td>260</td>
                                                <td>16</td>
                                                <td>190</td>
                                                <td>19</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Enseignement</td>
                                                <td>200</td>
                                                <td>71</td>
                                                <td>89</td>
                                                <td>49</td>
                                                <td>8</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Commerces</td>
                                                <td>560</td>
                                                <td>160</td>
                                                <td>32</td>
                                                <td>23</td>
                                                <td>8</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Hotellerie, restauration</td>
                                                <td>420</td>
                                                <td>71</td>
                                                <td>34</td>
                                                <td>74</td>
                                                <td>8</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Sante</td>
                                                <td>200</td>
                                                <td>71</td>
                                                <td>95</td>
                                                <td>12</td>
                                                <td>28</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Autres Sectures</td>
                                                <td>200</td>
                                                <td>71</td>
                                                <td>16</td>
                                                <td>12</td>
                                                <td>8</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-1 ">
                                    <h1 className='postion-tops '>*</h1>
                                </div>

                                <div className="col-1">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'> <th colSpan="2">Zone Climatique</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'>
                                                <td>H1</td>
                                                <td>1,1</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>H2</td>
                                                <td>0,9</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>H3</td>
                                                <td>0,6</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-1 ">
                                    <h1 className='postion-tops '>*</h1>
                                </div>
                                <div className="col-lg-2">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th colSpan="3">Surface geree par le système pour I'usage considére<br />(m<sup>2</sup>)</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center h-100'>
                                                <td className='pad-80  text-center'>s</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <p className='bar1-para '>Pour un système de gestion technique du bâtiment installé de classe B :</p>
                            <div className="row">
                                <div className="col-7">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th colSpan="6">Montant en kWh cumac par m<sup>2</sup> de surface geree par le systeme pour I'usage considere</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'>
                                                <td>Secteur d'activite</td>
                                                <td>Chauffage</td>
                                                <td>Refroidissement Climatisation</td>
                                                <td>ECS</td>
                                                <td>Eclairage</td>
                                                <td>Auxiliaire</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Bureaux</td>
                                                <td>300</td>
                                                <td>130</td>
                                                <td>8</td>
                                                <td>100</td>
                                                <td>10</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Enseignement</td>
                                                <td>120</td>
                                                <td>35</td>
                                                <td>45</td>
                                                <td>24</td>
                                                <td>5</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Commerces</td>
                                                <td>300</td>
                                                <td>66</td>
                                                <td>3</td>
                                                <td>23</td>
                                                <td>5</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Hotellerie, restauration</td>
                                                <td>230</td>
                                                <td>35</td>
                                                <td>17</td>
                                                <td>40</td>
                                                <td>5</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Sante</td>
                                                <td>140</td>
                                                <td>35</td>
                                                <td>48</td>
                                                <td>12</td>
                                                <td>18</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>Autres Sectures</td>
                                                <td>120</td>
                                                <td>35</td>
                                                <td>3</td>
                                                <td>12</td>
                                                <td>5</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-1 ">
                                    <h1 className='postion-tops '>*</h1>
                                </div>

                                <div className="col-1">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th colSpan="2">Zone Climatique</th> </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center'>
                                                <td>H1</td>
                                                <td>1,1</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>H2</td>
                                                <td>0,9</td>
                                            </tr>
                                            <tr className='bar1-para text-center'>
                                                <td>H3</td>
                                                <td>0,6</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <div className="col-lg-1 ">
                                    <h1 className='postion-tops '>*</h1>
                                </div>
                                <div className="col-lg-2">
                                    <table className="table table-bordered table-border">
                                        <thead>
                                            <tr className='bar1-head text-center'>
                                                <th colSpan="3">Surface geree par le système pour I'usage considére<br />(m<sup>2</sup>)</th> </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='bar1-para text-center h-100'> <td className='pad-80  text-center'>s</td> </tr> </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='bar1-content mt-4 pb-4'>
                                <p className='bar1-para para-bar1 m-0'>Installation d’un système de gestion technique du bâtiment (GTB) pour : le chauffage et/ou l’ECS et/ou le refroidissement/climatisation et/ou l’éclairage et/ou auxiliaire, dans un local du secteur TERTIAIRE de plus de 2 ans. </p>

                                <p className='bar1-para para-bar1 m-0'><span className='bar1-head '>Eligible :</span> Le système GTB assure l’automatisation centralisée avec des fonctions de régulation classe B ou A selon la norme NF EN ISO 52120-1 : 2022. </p>
                                <p className="bar1-head para-bar1 m-0 "> Exclus :</p>
                                <ul className='ul-bar1'>
                                    <li className='bar1-para'>le cumul de cette opération portant sur l’éclairage avec la fiche opération BAT EQ 127.</li>
                                    <li className='bar1-para'>le cumul de cette opération portant sur le chauffage avec la fiche opération BAT SE 103.</li>
                                    <li className='bar1-para'>Le simple raccordement d’un bâtiment à un système existant de gestion technique du bâtiment.</li>
                                    <li className='bar1-para'>Dans le cas de l’amélioration d’un système existant de GBT, le système existant avant l’opération n’est pas au plus de classe C au sens de la norme NF EN ISO 52120-1 : 2022.</li>
                                </ul>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>Attention :</span> L’opération doit être engagée avant le 1er janvier 2025. </p>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>Attention :</span> Si le dossier est monté pour un bâtiment tertiaire situé en Outre-mer, l’usage principal à considérer est l’usage refroidissement/climatisation et, le cas échant, eau chaude sanitaire, éclairage et auxiliaires.</p>
                                <p className='bar1-para para-bar1'><span className='bar1-head '>La facture :</span> indique obligatoirement : la mise en place d’un système GTB assurant les fonctions de régulation de classe B ou A au sens de la norme NF EN ISO 52120-1 : 2022 par un système d’automatisation centralisé, ainsi que ses marque et référence.</p>
                                <p className='bar1-para para-bar1 '><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime.</p>
                                <p className='bar1-para para-bar1'> En cliquant sur le + Info -> , vous obtenez plus de détail sur une question.</p>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default BATTH116Update