import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
// FORMIK
import { Formik } from 'formik';
const Synopsis = () => {
    const navigate = useNavigate();
    // BACK BUTTON
    const handlePrev = () => {
        navigate(-1);
    };
    const devis = JSON.parse(sessionStorage.getItem('step-10'));
    const facture = JSON.parse(sessionStorage.getItem('step-11'));
    const attestation = JSON.parse(sessionStorage.getItem('step-12'));
    console.log(devis, facture, attestation);
    // Initialize comments from session storage or set them to empty strings if not found
    const devisComments = (devis && devis.estimate_comments) || '';
    const factureComments = (facture && facture.bill_comments) || '';
    const attestationComments = (attestation && attestation.attestation_comments) || '';

    // Define functions to update and store comments in session storage
    const updateDevisComments = (newComments) => {
        const updatedDevis = { ...devis, estimate_comments: newComments };
        sessionStorage.setItem('step-10', JSON.stringify(updatedDevis));
    };

    const updateFactureComments = (newComments) => {
        const updatedFacture = { ...facture, bill_comments: newComments };
        sessionStorage.setItem('step-11', JSON.stringify(updatedFacture));
    };

    const updateAttestationComments = (newComments) => {
        const updatedAttestation = { ...attestation, attestation_comments: newComments };
        sessionStorage.setItem('step-12', JSON.stringify(updatedAttestation));
    };

    // Use the comments in your component and provide a way to edit them
    // For example, you can use state to manage the edited comments

    const [editedDevisComments, setEditedDevisComments] = useState(devisComments);
    const [editedFactureComments, setEditedFactureComments] = useState(factureComments);
    const [editedAttestationComments, setEditedAttestationComments] = useState(attestationComments);
    const [synopsisComments, setSynopsisComments] = useState();

    // Define functions to handle the editing of comments and updating them in session storage

    const handleDevisCommentsChange = (event) => {
        setEditedDevisComments(event.target.value);
    };

    const handleFactureCommentsChange = (event) => {
        setEditedFactureComments(event.target.value);
    };

    const handleAttestationCommentsChange = (event) => {
        setEditedAttestationComments(event.target.value);
    };
    const handlesynopsisCommentsChange = (event) => {
        setSynopsisComments(event.target.value);
    };

    const handleSaveDevisComments = () => {
        // Save edited comments for devis to session storage
        updateDevisComments(editedDevisComments);
    };

    const handleSaveFactureComments = () => {
        // Save edited comments for facture to session storage
        updateFactureComments(editedFactureComments);
    };

    const handleSaveAttestationComments = () => {
        // Save edited comments for attestation to session storage
        updateAttestationComments(editedAttestationComments);
    };
    console.log('synopsisComments', synopsisComments);
    const handleSubmit = () => {
        console.log('synopsisComments', synopsisComments);
        sessionStorage.setItem('step-15', JSON.stringify({ synopsisComments }));
        navigate('/finalvalidate');
    }

    return (
        <>

            <div className="container-fluid px-4 py-3">
                <div className="row">
                    <div className="col-5">
                        <div>
                            <div className='p-3 text-left'>
                                <label className='bar1-para'>Devis Commentaires:</label>
                                <textarea value={editedDevisComments} onChange={handleDevisCommentsChange} className='form-control mb-2 shadow-none textarea' />
                                <button onClick={handleSaveDevisComments} className='saveCommentsBtn' >Économiser Devis Comments</button>
                            </div>
                            <div className='p-3 text-left'>
                                <label className='bar1-para'>Facture Commentaires:</label>
                                <textarea value={editedFactureComments} onChange={handleFactureCommentsChange} className='form-control mb-2 shadow-none textarea' />
                                <button onClick={handleSaveFactureComments} className='saveCommentsBtn' >Économiser Facture Comments</button>
                            </div>
                            <div className='p-3 text-left'>
                                <label className='bar1-para'>Attestation Commentaires:</label>
                                <textarea value={editedAttestationComments} onChange={handleAttestationCommentsChange} className='form-control mb-2 shadow-none textarea' />
                                <button onClick={handleSaveAttestationComments} className='saveCommentsBtn' >Économiser Attestation Comments</button>
                            </div>
                            <div className='p-3 text-left'>
                                <label className='bar1-para'>Supplémentaires Commentaires:</label>
                                <textarea value={synopsisComments} onChange={handlesynopsisCommentsChange} className='form-control mb-2 shadow-none textarea' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <textarea
                                name="synopsis_comments"
                                value={values.synopsis_comments}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={touched.synopsis_comments && errors.synopsis_comments ? 'error' : 'form-control ml-1 shadow-none textarea'}
                            >
                            </textarea> */}
                {/* BUTTONS */}
                <div className='row mt-5 '>
                    <div className='col text-start'>
                        <button type='button' onClick={handlePrev} className='btns-color' >Précédent </button>
                    </div>
                    <div className="col text-end">
                        {/* <Link to={'/finalvalidate'} type='submit' className='btns-color' >Suivant</Link> */}
                        <button type='submit' className='btns-color' onClick={handleSubmit}>Suivant</button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Synopsis