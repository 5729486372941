import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs";
function Bat103() {
  const navigate = useNavigate();
  const handlePrev = () => {
    navigate(-1);
  };
  return (
    <div className='container-fluid py-3 px-4 '>
      <div className='row'>
        <p className='bar1-para '>BAT-EN-103 : Isolation d’un plancher <span onClick={handlePrev}  className='border-0 bg-white pe-auto'><BsArrowLeft className='left-arow pe-auto' /></span></p>
        <div className='button-1 mt-1'>
          <Link to={"/calcult103"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
        </div>
        <div className='col bar1-tittle'>
          <div className='bar1-tittle'>
            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
              <h3 className='bar1-head'>1. Secteur d’application</h3>
              <p className='bar1-para'>Locaux du secteur tertiaire existants réservés à une utilisation professionnelle.</p>
              <h3 className='bar1-head'>2. Dénomination</h3>
              <p className='bar1-para'>Mise en place d’un doublage isolant sur/sous plancher bas situé sur un sous-sol non chauffé, sur un vide sanitaire ou sur un passage ouvert.</p>
              <h3 className='bar1-head m-0'>3. Conditions pour la délivrance de certificats</h3>
              <p className='bar1-para '>La mise en place est réalisée par un professionnel.

              </p>
              <p className='bar1-para'>La résistance thermique R de l’isolation installée est supérieure ou égale à 3 m².K/W.</p>
              <p className='bar1-para'>La résistance thermique est évaluée selon la norme NF EN 12664, la norme NF EN 12667 ou la norme NF EN 12939 pour les isolants non réfléchissants et selon la norme NF EN 16012+A1 pour les isolants réfléchissants.</p>

              <p className="bar1-para ">Un pare-vapeur ou tout autre dispositif permettant d’atteindre un résultat équivalent est mis en place, lorsqu’il est nécessaire de protéger les matériaux d’isolation thermique contre les transferts d’humidité pour garantir la performance de l’ouvrage.
                Un délai minimal de sept jours francs est respecté entre la date d’acceptation du devis et la date début des travaux (pose de l’isolant).
                Une opération ne peut être engagée moins de douze mois suivant l’engagement d’une opération portant sur un même bâtiment et un même bénéficiaire.</p>

              <p className='bar1-para'>La preuve de la réalisation de l’opération mentionne :</p>

              <ul className=' bar1-para '>  <li>la mise en place d’une isolation d’un plancher ;</li>
                <li>la mise en place d’une isolation d’un plancher;</li>
                <li>et la surface d’isolant installé ;</li>
                <li>et la résistance thermique de l’isolation mise en place évaluée, suivant la nature de l’isolant, selon l’une des nonnes susvisées.</li>
                <li>les aménagements nécessaires à la mise en place de l’isolation (coffrage ou écran de protection autour des conduits de fumées et des dispositifs d’éclairage encastrés ; rehausse rigide au niveau de la trappe d’accès; pare-vapeur ou tout autre dispositif équivalent lorsqu’il est nécessaire de protéger les matériaux d’isolation thermique contre les transferts d’humidité pour garantir la performance de l’ouvrage).</li>
              </ul>
              <p className='bar1-para'>À défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un matériau avec ses marque et référence et la surface de matériau installée, et elle est complétée par un document issu du fabricant ou d’un organisme établi dans l’Espace économique européen et accrédité selon la norme NF EN ISO/IEC 17065 par le Comité français d’accréditation (COFRAC) ou tout autre organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris dans le cadre de la coordination européenne des organismes d’accréditation.</p>
              <p className='bar1-para'>Ce document indique que le matériau de marque et référence mis en place est un isolant et précise ses caractéristiques thermiques (résistance thermique ; ou conductivité thermique et épaisseur) évaluées, suivant la nature de l’isolant, selon l’une des normes susvisées. En cas de mention d’une date de validité, ce document est considéré comme valable jusqu’à un an après sa date de fin de validité. Pour les références proposées en différentes épaisseurs, la preuve de réalisation, si elle ne mentionne pas la résistance thermique de l’isolation installée, doit impérativement en préciser l’épaisseur.</p>
              <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                <span className='bar1-para'>30 ans.</span></h3>
              <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac
              </p>
              <div className="row">
                <div className="col-3">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th >Zone climatique</th>
                        <th className='p-4'>Montant en kWh cumac par m<sup>2</sup> d'isolant </th> </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center'><td>H1</td>
                        <td>5 200</td></tr>
                      <tr className='bar1-para text-center'> <td>H2</td>
                        <td>4 200</td> </tr>
                      <tr className='bar1-para text-center'><td>H3</td>
                        <td>2 800</td> </tr> </tbody>
                  </table>
                </div>
                <div className="col-lg-1 ">
                  <h1 className='postion-tops '>*</h1>
                </div>
                <div className="col-3">
                  <table className="table table-bordered table-border ">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th >Secteur d'activite</th>
                        <th >Facteur correctif </th> </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para '><td>Bureaux,<br />Enseignement,<br />Commerces</td>
                        <td className='text-center'>0,6</td></tr>
                      <tr className='bar1-para '> <td>Hotelleric -<br /> Restauration </td>
                        <td className='text-center'>0,7</td></tr><tr className='bar1-para '><td>Sante</td>
                        <td className='text-center'>1,2</td> </tr><tr className='bar1-para '> <td>Autres sectcurs</td>
                        <td className='text-center'>0,6</td> </tr> </tbody>
                  </table>
                </div>
                <div className="col-lg-1 ">
                  <h1 className='postion-tops '>*</h1>
                </div>
                <div className="col-lg-2">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th colSpan="3">Surface d'isolant en m<sup>2</sup></th> </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center h-100'><td className='pad-125 text-center'>s</td> </tr></tbody>
                  </table></div>
              </div>

              <div className='bar1-content mt-4 pb-4'>
                <p className='bar1-para para-bar1 m-0'>Mise en place par un professionnel d’un doublage isolant sur/sous plancher bas, situé sur un sous-sol non chauffé, sur un vide sanitaire ou sur un passage ouvert dans un bâtiment tertiaire existant, réservé à une utilisation professionnelle. Un pare-vapeur (ou équivalent) est posé pour protéger l’isolant thermique de l'humidité et de la condensation. </p>
                <p className="bar1-head para-bar1 m-0 "> Eligible si :</p>
                <ul className='ul-bar1'>
                  <li className='bar1-para'><span className='bar1-head '>Le début des travaux :</span>  respecte un délai <span className='bar1-head '> de 8 jours après la date de signature</span>  de la commande.</li>
                  <li className='bar1-para'> <span className='bar1-head '>L’isolant :</span>a une résistance thermique ≥ à 3 m².K/W, est certifié ACERMI (ou équivalent), est de classe A de protection au feu (ou équivalent ou pose d’une couche supplémentaire).</li>
                  <li className='bar1-para'><span className='bar1-head '>Sont installés : </span>un coffrage autour des conduits de fumées et des éclairages encastrés.</li>
                  <li className='bar1-para'><span className='bar1-head '>La facture :</span>indique obligatoirement : la mise en place d’une isolation du plancher ou du sol, marque, référence, épaisseur, résistance thermique (R ≥ 3m² K/W), n° ACERMI, est de classe A de protection au feu (ou équivalent ou pose d’une couche supplémentaire), la surface de l’isolant posé, les aménagements réalisés et la présence d’un pare-vapeur.</li>
                  <li className='bar1-para'><span className='bar1-head '>Attention :</span>Une opération ne peut être engagée moins de douze mois suivant l’engagement d’une opération portant sur un même bâtiment et un même bénéficiaire.</li>
                </ul>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Contrôle post-chantier obligatoire :</span> tous les chantiers font l’objet d’un questionnaire de satisfaction client, certains font l’objet d’une inspection qualité post-travaux par un organisme indépendant (attesté COFRAC). </p>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime. En cliquant sur le  Info  vous obtenez plus de détail sur une question.</p>
              </div>
              {/* <div className='button-1 '>
                <button type='button ' className='btn-on bg-green' >Site du Ministère <TbMathGreater /> </button>
              </div>
              <div className='button-1 mb-3'>
                <button type='button ' className='btn-on bg-green' ><AiOutlineDownload />Télécharger le fiche </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Bat103;