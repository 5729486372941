import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs";
function Barth104() {
  const navigate = useNavigate();
  const handlePrev = () => {
    navigate(-1);
  };
  return (
    <div className='container-fluid py-3 px-4'>
      <div className='row'>
        <p className='bar1-para '>BAR-TH-104 : Pompe à chaleur de type air/eau ou eau/eau <span onClick={handlePrev}  className='border-0 bg-white pe-auto'><BsArrowLeft className='left-arow pe-auto' /></span></p>
        <div className='button-1 mt-1'>
          {/* <button type='button' className='btn-on' data-bs-dismiss="modal" aria-label='close'>Calculez le montant </button> */}
          <Link to={"/calcult-bar-th-104"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
        </div>
        <div className='col bar1-tittle'>
          <div className='bar1-tittle'>
            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
              <h3 className='bar1-head'>1. Secteur d’application</h3>
              <p className='bar1-para'>Bâtiments résidentiels existants. </p>
              <h3 className='bar1-head'>2. Dénomination</h3>
              <p className='bar1-para'>Mise en place d’une pompe à chaleur (PAC) de type air/eau ou eau/eau. </p>
              <p className='bar1-para'>Ne donnent pas lieu à la délivrance de certificats d’économies d’énergie au titre de la présente fiche, les PAC
                associées à une chaudière à haute performance énergétique pour le chauffage des locaux et les PAC utilisées
                uniquement pour le chauffage de l'eau chaude sanitaire.  </p>
              <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
              <p className='bar1-para'>La mise en place est réalisée par un professionnel.  </p>
              <p className='bar1-para'>Le professionnel ayant réalisé l’opération est titulaire d’un signe de qualité répondant aux mêmes exigences que
                celles prévues à l’article 2 du décret n° 2014-812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de
                l’article 200 quater du code général des impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code
                général des impôts et des textes pris pour son application. Ce signe de qualité correspond à des travaux relevant du
                5° (dans le cas d’une PAC assurant uniquement le chauffage du logement) ou du 5° et du 6° (dans le cas d’une PAC
                assurant le chauffage du logement et de l’eau chaude sanitaire) du I de l’article 1er du décret précité.
              </p>
              <p className='bar1-para'>L’efficacité énergétique saisonnière (ηs) selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013
                supérieure ou égale à : <br></br>
                <span className='ms-4'>- 111% pour les PAC moyenne et haute température,</span><br></br>
                <span className='ms-4'>- 126% pour les PAC basse température.  </span>
              </p>
              <p className='bar1-para'>Le  professionnel rédige  une  note  de  dimensionnement  du  générateur  par rapport  aux  déperditions calculées  à
                T = Tbase.  Les  déperditions  concernent  les  pièces  du  logement  desservies  par  le  réseau  de  chauffage,  sans
                considération des éventuels autres générateurs présents. Cette note est remise au bénéficiaire à l’achèvement des
                travaux.  </p>
              <p className='bar1-para'>
                La preuve de la réalisation de l’opération mentionne :<br></br>
                <span className='ms-4'>–  la mise en place d’une pompe à chaleur air/eau ou eau/eau et le type de pompe à chaleur (basse, moyenne ou haute
                  température) ; </span><br></br>
                <span className='ms-4'>– et l’efficacité énergétique saisonnière (ηs) selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013. </span><br></br></p>
              <p className='bar1-para'>Ce signe de qualité correspond à des travaux relevant du 5 du I de l'article 46 AX de l'annexe III du code général des
                impôts.</p>
              <p className='bar1-para'>L’efficacité énergétique saisonnière prise en compte est celle de la pompe à chaleur seule pour les besoins de
                chauffage des locaux (hors dispositif de régulation). </p>
              <p className='bar1-para'>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un équipement avec ses marque et
                référence et elle est complétée par un document issu du fabricant ou d’un organisme établi dans l'Espace économique
                européen et accrédité selon la norme NF EN 45011 par le Comité français d'accréditation (COFRAC) ou tout autre organisme d'accréditation signataire de l'accord européen multilatéral pertinent pris dans le cadre de European co-
                operation for Accreditation (EA), coordination européenne des organismes d'accréditation.</p>

              <p className='bar1-para'>Ce document indique :<br></br>
                <span className='ms-4'>– que l’équipement de marque et référence mis en place est une pompe à chaleur air/eau ou eau/eau et le type de
                  pompe à chaleur (basse, moyenne ou haute température) ; </span><br></br>
                <span className='ms-4'>– et l’efficacité énergétique saisonnière (ηs) selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013. </span><br></br> </p>
              <p className='bar1-para'>En cas de mention d’une date de validité, ce document est considéré comme valable jusqu’à un an après sa date de
                fin de validité.</p>

              <p className='bar1-para'>Les documents justificatifs spécifiques à l’opération sont : <br></br>
                <span className='ms-4'>– la note de dimensionnement susmentionnée ;  </span><br></br>
                <span className='ms-4'>– la décision de qualification ou de certification du professionnel délivrée selon les mêmes exigences que celles
                  prévues à l’article 2 du décret susvisé.  </span><br></br> </p>
              <h3 className="bar1-head mt-4">4. Durée de vie conventionnelle<br></br>
                <span className='bar1-para'>1 ans.</span></h3>
              <p className="bar1-head mt-4">5. Montant de certificats en kWh cumac</p>
              <p className='bar1-ul bar1-para '>Pour un appartement :  </p>
              <div className="row">
                <div className="col-lg-5">
                  <table className='table table-bordered table-border '>
                    <tr className='bar1-para text-center p-0'>
                      <th className='border-thin p-0' >
                        Efficacité
                        énergétique
                        saisonnière (ηs) </th>
                      <th className='border-thin p-0' >Zone climatique</th>
                      <th className='border-thin p-0'>Montant
                        unitaire en
                        kWh cumac</th>
                    </tr>
                    <tr className=' bar1-para text-center p-0'>
                      <th className='border-thin p-0' rowspan="4">102% &le; ηs &le; 110% </th> </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H1</td>
                      <td className='border-thin p-0'>24 500</td> </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H2</td>
                      <td className='border-thin p-0'> 20 100</td></tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0'>H3</td>
                      <td className='border-thin p-0'>13 400</td> </tr>
                    <tr className=' bar1-para text-center p-0'>
                      <th className='border-thin p-0' rowspan="4">110% &le; ηs &le; 120%</th></tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H1</td>
                      <td className='border-thin p-0'>32 200 </td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H2</td>
                      <td className='border-thin p-0'> 26 400</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0'>H3</td>
                      <td className='border-thin p-0'>17 600</td>
                    </tr>
                    <tr className=' bar1-para text-center p-0'>
                      <th className='border-thin p-0' rowspan="4">120% &lt; ηs </th>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H1</td>
                      <td className='border-thin p-0'>39 700</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H2</td>
                      <td className='border-thin p-0'> 32 500</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0'>H3</td>
                      <td className='border-thin p-0'>21 700</td>
                    </tr>
                  </table>
                </div>
                <div className="col-lg-1 ">
                  <h1 className='postion-tops pt-5'>*</h1>
                </div>

                <div className="col-4">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th >Facteur
                          correctif selon
                          la surface
                          chauffée </th>
                        <th >Surface chauffée
                          S en m²
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center'>
                        <td>0.5</td>
                        <td>S &lt; 35</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>0.7</td>
                        <td>35 &le; S * &le; 60</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>1</td>
                        <td>60 &le; S * &le; 70</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>1.2</td>
                        <td>70 &le; S * &le; 90</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>1.5</td>
                        <td>90 &le; S * &le; 110</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>1.9</td>
                        <td>110 &le; S * &le; 130</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>2.5</td>
                        <td>130 &lt; S</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <p className='bar1-ul bar1-para mt-3'>Pour une maison individuelle :  </p>

              <div className="row">
                <div className="col-lg-5">
                  <table className='table table-bordered table-border '>
                    <tr className='bar1-para text-center p-0'>
                      <th className='border-thin p-0' >
                        Efficacité
                        énergétique
                        saisonnière (ηs) </th>
                      <th className='border-thin p-0' >Zone climatique</th>
                      <th className='border-thin p-0'>Montant
                        unitaire en
                        kWh cumac</th>
                    </tr>
                    <tr className=' bar1-para text-center p-0'>
                      <th className='border-thin p-0' rowspan="4">102% &le; ηs &le; 110% </th>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H1</td>
                      <td className='border-thin p-0'>52 700</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H2</td>
                      <td className='border-thin p-0'> 43 100</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0'>H3</td>
                      <td className='border-thin p-0'>28 700</td>
                    </tr>
                    <tr className=' bar1-para text-center p-0'>
                      <th className='border-thin p-0' rowspan="4">110% &le; ηs &le; 120%</th>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H1</td>
                      <td className='border-thin p-0'>66 400 </td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H2</td>
                      <td className='border-thin p-0'> 54 400</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0'>H3</td>
                      <td className='border-thin p-0'>36 200</td>
                    </tr>
                    <tr className=' bar1-para text-center p-0'>
                      <th className='border-thin p-0' rowspan="4">120% &lt; ηs </th>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H1</td>
                      <td className='border-thin p-0'>37 900</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0' >H2</td>
                      <td className='border-thin p-0'> 65 400</td>
                    </tr>
                    <tr className='bar1-para text-center p-0'>
                      <td className='border-thin p-0'>H3</td>
                      <td className='border-thin p-0'>43 600</td>
                    </tr>
                  </table>
                </div>
                <div className="col-lg-1 ">
                  <h1 className='postion-tops pt-5'>*</h1>
                </div>

                <div className="col-4">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th >Facteur
                          correctif selon
                          la surface
                          chauffée </th>
                        <th >Surface chauffée
                          S en m²
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center'>
                        <td>0.5</td>
                        <td>S &lt; 70</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>0.7</td>
                        <td>70 &le; S * &le; 90</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>1</td>
                        <td>90 &le; S * &le; 110</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>1.1</td>
                        <td>110 &le; S * &le; 130</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>1.6</td>
                        <td>130 &lt; S </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <p className='bar1-para '>NB : la surface prise en compte est la surface chauffée par la PAC installée.  </p>
              <div className='bar1-content mt-4 pb-4'>
                <p className='bar1-para para-bar '>Fourniture et pose par un professionnel RGE d’un procédé d’isolation en comble perdu ou en rampant de toiture dans un bâtiment résidentiel existant. Un pare-vapeur (ou équivalent) est posé pour protéger l’isolant thermique de l'humidité et de la condensation. </p>
                <p className="bar1-head para-bar1 ">Éligible si : </p>
                <ul className='ul-bar1'>  <li><span className='bar1-ul '>Le devis : </span>comporte la date de la pré-visite technique.</li>
                  <li><span className='bar1-ul'>Le début des travaux</span> respecte un délai<span className='bar1-ul'> de 8 jours après la date de signature</span> de la commande.</li>
                  <li><span className='bar1-ul'>L’isolant :</span> a une résistance thermique ≥ à 7 m².K/W en comble perdu et ≥ à 6 m².K/W en rampant de toiture, est certifié ACERMI (ou équivalent), est de classe A de protection au feu (ou équivalent ou pose d’une couche supplémentaire).</li>
                  <li><span className='bar1-ul'>Sont installés :</span>  un coffrage autour des conduits de fumées et des éclairages encastrés ; une rehausse rigide au niveau de la trappe d’accès.</li></ul>
                <p className='bar1-para para-bar1'><span className='bar1-head '>La facture indique obligatoirement :</span> fourniture et pose d’une isolation de comble ou toiture, marque, référence, épaisseur, résistance thermique, n° ACERMI et surface réellement isolée (+ l’identité du sous-traitant, si c’est le cas), les aménagements réalisés, la présence d’un pare-vapeur + la date de pré-visite technique. </p>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Contrôle post-chantier :</span> tous les chantiers font l’objet d’un questionnaire de satisfaction client, certains font l’objet d’une inspection qualité post-travaux par un organisme indépendant (attesté COFRAC).</p>

                <p className='bar1-para para-bar1'><span className='bar1-head '>Attention :</span> Sans modification sous 5 ans, cette opération sera abrogée à partir du 1er mai 2027.</p>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Information inconnue ?</span> Les données de cette simulation vous donnent une bonne estimation du montant de la prime. Les données réelles seront collectées après travaux avec l’installateur et réévalueront le calcul exact de cette prime. En cliquant sur le Info , vous obtenez plus de détail sur une question.</p>

              </div>
              {/* <div className='button-1'>
                <button type='button' className='btn-on' >Site du Ministère <TbMathGreater /> </button>
              </div>
              <div className='button-1 mb-3'>
                <button type='button' className='btn-on' ><AiOutlineDownload />Télécharger le fiche </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Barth104;