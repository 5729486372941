import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs";
function Bar104() {
  const navigate = useNavigate();
  const handlePrev = () => {
    navigate(-1);
  };
  return (
    <div className='container-fluid py-3 px-4'>
      <div className='row'>
        <p className='bar1-para '>BAR-EN-104 : Fenêtre ou porte-fenêtre complète avec vitrage isolant<span onClick={handlePrev}  className='border-0 bg-white pe-auto'><BsArrowLeft className='left-arow pe-auto' /></span></p>
        <div className='button-1 mt-1'>
          <Link to={"/calcult4"} className='reside-content text-center links bg-green'>Calculez le montant</Link>
        </div>
        <div className='col bar1-tittle'>
          <div className='bar1-tittle'>
            <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
              <h3 className='bar1-head'>1. Secteur d’application</h3>
              <p className='bar1-para'>Bâtiments résidentiels existants, à l’exclusion des parties communes non chauffées.</p>
              <h3 className='bar1-head'>2. Dénomination</h3>
              <p className='bar1-para'>Mise en place d’une fenêtre, fenêtre de toiture ou porte-fenêtre complète avec vitrage isolant.</p>
              <p className='bar1-para'>Le simple remplacement de vitrages sur une fenêtre ou porte-fenêtre existante, la fermeture d’une loggia par des parois vitrées, la construction d’une véranda à parois vitrées ou la création d’une ouverture dans une paroi opaque ne donnent pas lieu à la délivrance de certificats d’économies d’énergie.</p>
              <h3 className='bar1-head'>3. Conditions pour la délivrance de certificats</h3>
              <p className='bar1-para'>Le coefficient de transmission surfacique Uw et le facteur solaire Sw sont :
              </p>
              <ul className='ul-bar1  m-0'>
                <li className='bar1-para'>pour les fenêtres de toiture : Uw ≤ 1,5 W/m².K et Sw ≤ 0,36.</li>
                <li className='bar1-para '> pour les autres fenêtres ou portes-fenêtres :</li>
              </ul>
              <p className='bar1-para ms-5 mt-2'>
                <span  >– Uw ≤ 1,3 W/m2.K et Sw ≥ 0,3 ;</span><br></br>
                <span  >– Uw ≤ 1,3 W/m2.K et Sw ≥ 0,3 ;</span><br></br>

              </p>
              <p className='bar1-para'>La mise en place est réalisée par un professionnel.</p>
              <p className='bar1-para'>Si le bénéficiaire est une personne physique, le professionnel qui procède à la réalisation de l’opération doit être titulaire d’un signe de qualité répondant aux mêmes exigences que celles prévues à l’article 2 du décret n° 2014-812 du 16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code général des impôts et des textes pris pour son application.</p>
              <p className='bar1-para'>Ce signe de qualité correspond à des travaux relevant du 2 du I de l’article 46 AX de l’annexe III du code général des impôts.</p>
              <p className='bar1-para'>La preuve de la réalisation de l’opération mentionne : </p>
              <ul className='ul-bar1  m-0'>
                <li className='bar1-para'>la mise en place d’une ou plusieurs fenêtre(s), fenêtre(s) de toiture ou porte(s)-fenêtre(s) ;</li>
                <li className='bar1-para '>et le nombre de fenêtres ou portes-fenêtres ;</li>
                <li className='bar1-para '>et les Uw et Sw des équipements installés.</li>
              </ul>
              <p className='bar1-para mt-3'>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un ou plusieurs équipements avec leur marque et référence et la quantité installée et elle est complétée par un document issu du fabricant ou d’un organisme établi dans l’Espace économique européen et accrédité selon la norme NF EN 45011 par le Comité français d’accréditation (COFRAC) ou tout autre organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris dans le cadre de European co-operation for Accreditation (EA), coordination européenne des organismes d’accréditation.</p>
              <p className='bar1-para'>Ce document indique que l’équipement de marque et référence installé est une fenêtre, fenêtre de toiture ou portefenêtre complète et précise ses caractéristiques thermiques (Uw et Sw). En cas de mention d’une date de validité, ce document est considéré comme valable jusqu’à un an après sa date de fin de validité.</p>
              <p className='bar1-para '>Le document justificatif spécifique à l’opération est la décision de qualification ou de certification du professionnel délivrée selon les mêmes exigences que celles prévues à l’article 2 du décret susvisé.</p>
              <p className='bar1-head m-0 '>4. Durée de vie conventionnelle</p>
              <p className='bar1-para '>24 ans.</p>
              <p className='bar1-head '>5. Montant de certificats en kWh cumac</p>
              <div className="row">
                <div className="col-4">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th colSpan="3">Montant en kWh cumac par fenetre ou porte-fenetre complete avec vitrage isolant posee </th></tr>
                      <tr className='bar1-head text-center'>
                        <th rowSpan="2">zone climatique</th>
                        <th colSpan="2">Energie de chauffage</th>
                      </tr>
                      <tr className='bar1-head text-center'>
                        <td>Electricite</td>
                        <td>Combustible</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center'>
                        <td>H1</td>
                        <td>5 200</td>
                        <td>8 200</td>
                      </tr>
                      <tr className='bar1-para text-center'>

                        <td>H2</td>
                        <td>4 200</td>
                        <td>6 700</td>
                      </tr>
                      <tr className='bar1-para text-center'>
                        <td>H3</td>
                        <td>2 800</td>
                        <td>4 500</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-1 ">
                  <h1 className='postion-tops '>*</h1>
                </div>
                <div className="col-lg-2">
                  <table className="table table-bordered table-border">
                    <thead>
                      <tr className='bar1-head text-center'>
                        <th colSpan="3">Nombre de fenetres ou portes-fenetre complete avec vitrage isolant posees </th></tr>
                    </thead>
                    <tbody>
                      <tr className='bar1-para text-center h-100'><td className='pad-80  text-center'>N</td></tr></tbody>
                  </table> </div>
              </div>
              <div className='bar1-content mt-4 pb-4'>
                <p className='bar1-para para-bar '>Éligible : la fourniture et pose/remplacement par un professionnel RGE de fenêtre, de Velux ou de porte-fenêtre complète avec vitrage isolant dans un (ou des) appartement ou maison, de plus de 2 ans.</p>

                <ul className='ul-bar1'>  <li>La facture devra indiquer : la fourniture et pose de fenêtre, de Velux ou de porte-fenêtre avec leur nombre, leur coefficient de transmission surfacique (Uw) et leur facteur solaire (Sw).</li>
                </ul> <p className='bar1-para para-bar1'><span className='bar1-head '>La facture indique obligatoirement :</span> la mise en place d’une isolation thermique d’un plancher bas, marque, référence, épaisseur, résistance thermique, n° ACERMI et surface réellement isolée (+ l’identité du sous-traitant, si c’est le cas), les aménagements réalisés, la présence d’un pare-vapeur + la date de pré-visite technique. </p>
                <p className='bar1-para para-bar1'><span className='bar1-head '>Vous ignorez une réponse :</span> cette simulation vous donne un montant précis et permet de demander et obtenir cette prime. Après travaux, les données réelles seront collectées auprès de votre professionnel et votre prime sera automatiquement réévaluée.</p></div>
              {/* <div className='button-1'>
                <button type='button' className='btn-on' >Site du Ministère <TbMathGreater /> </button>
              </div>
              <div className='button-1 mb-3'>
                <button type='button' className='btn-on' ><AiOutlineDownload />Télécharger le fiche </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Bar104;