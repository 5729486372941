import React from 'react'
import { Link } from "react-router-dom"
function SubMenu() {
	return (
		<div className="container-fluid-fluid">
			<div className="row">
				<div className="col">
					<div className='mt-3 mb-3  text-start'>
						<button type='button' className='btns-color w-aut0 h-auto mt-1'  ><Link
							to={"/coordonnees"}


							className='text-blacks text-decoration-none text-white'
						>
							Coordonnées de sous traitant
						</Link> </button>
					</div>
					<div className='mt-3 mb-3  text-start'>
						<button type='button' className='btns-color w-aut0 h-auto mt-1'  ><Link
							to={"/valider-geoportail"}


							className='text-blacks text-decoration-none text-white'
						>
							Valider l'espace de travail
						</Link> </button>
					</div>
					<div className='mt-3 mb-3  text-start'>
						<button type='button' className='btns-color w-aut0 h-auto mt-1'  ><Link
							to={"/valider-comment"}


							className='text-blacks text-decoration-none text-white'
						>
							InValider comment
						</Link> </button>
					</div>
					<div className='mt-3 mb-3  text-start'>
						<button type='button' className='btns-color w-aut0 h-auto mt-1'  ><Link
							to={"/attestation"}


							className='text-blacks text-decoration-none text-white'
						>
							Attestation sur l’Honneur
						</Link> </button>
					</div>
					<div className='mt-3 mb-3  text-start'>
						<button type='button' className='btns-color w-aut0 h-auto mt-1'  ><Link
							to={"/estimation-page"}


							className='text-blacks text-decoration-none text-white'
						>
						 Estimation Page
						</Link> </button>
					</div>
					<div className='mt-3 mb-3  text-start'>
						<button type='button' className='btns-color w-aut0 h-auto mt-1'  ><Link
							to={"/control-contribution"}


							className='text-blacks text-decoration-none text-white'
						>
						 Control Page
						</Link> </button>
					</div>
					<div className='mt-3 mb-3  text-start'>
						<button type='button' className='btns-color w-aut0 h-auto mt-1'  ><Link
							to={"/upload-docs"}


							className='text-blacks text-decoration-none text-white'
						>
						 Upload docs
						</Link> </button>
					</div>
					<div className='mt-3 mb-3  text-start'>
						<button type='button' className='btns-color w-aut0 h-auto mt-1'  ><Link
							to={"/permissions"}


							className='text-blacks text-decoration-none text-white'
						>
						Permission
						</Link> </button>
					</div>
					<div className='mt-3 mb-3  text-start'>
						<button type='button' className='btns-color w-aut0 h-auto mt-1'  ><Link
							to={"/file-dashboard"}


							className='text-blacks text-decoration-none text-white'
						>
						File-dashboard
						</Link> </button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubMenu