import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap'
// DataTables
import $ from 'jquery'; // Import jQuery
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net/js/jquery.dataTables.min.js';
import 'datatables.net';
function Dashboard() {
    const [data, setData] = useState([]);
    const tableRef = useRef(null);
    const [cumulativeSum, setCumulativeSum] = useState(0);
    const [totalttc, setTotalTTC] = useState(0);
    const [partnaireSum, setPartnaireSum] = useState(0);
    const [partnairetotalttc, setPartnaireTotalTTC] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    // FIRST METHOD
    useEffect(() => {
        if (!isLoading) {
            const table = $(tableRef.current).DataTable({
                orderCellsTop: true,
                fixedHeader: true,
                language: {
                    search: 'éléments',
                }
            }); return () => {
                table.destroy();
            };
        }
    }, [isLoading]);
    const fetchData = async () => {
        const Token = localStorage.getItem('Token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/getall-multistep`, {
                headers: { authorization: Token }
            });

            const apiData = response?.data?.AllData;

            let sum = 0; // Declare a local sum variable
            let ttc = 0; // Declare a local sum variable
            let partnairesum = 0; // Declare a local sum variable
            let partnairesumttc = 0; // Declare a local sum variable

            const processedData = apiData.map(useItems => {
                sum += Number(useItems?.multistep2?.obligecumacvalue);
                return {
                    ...useItems,
                    multistep2: {
                        ...useItems.multistep2,
                        obligecumacvalue: sum
                    }
                };
            });
            const totaldData = apiData.map(ttctems => {
                ttc += Number(ttctems?.multistep2?.obligeTotalPrice);
                return {
                    ...ttctems,
                    multistep2: {
                        ...ttctems.multistep2,
                        obligeTotalPrice: ttc
                    }
                };
            });
            // PARTNAIRE
            const processedData02 = apiData.map(partnaireItems => {
                partnairesum += Number(partnaireItems?.multistep2?.partnercumacvalue);
                return {
                    ...partnaireItems,
                    multistep2: {
                        ...partnaireItems.multistep2,
                        partnercumacvalue: partnairesum
                    }
                };
            });
            const totaldData02 = apiData.map(partnairettctems => {
                partnairesumttc += Number(partnairettctems?.multistep2?.partnertotalprice);
                return {
                    ...partnairettctems,
                    multistep2: {
                        ...partnairettctems.multistep2,
                        partnertotalprice: partnairesumttc
                    }
                };
            });
            setIsLoading(false);
            setCumulativeSum(sum); // Update the state with the cumulative sum
            setTotalTTC(ttc); // Update the state with the cumulative sum
            setPartnaireSum(partnairesum);
            setPartnaireTotalTTC(partnairesumttc);
            setData(response?.data?.AllData);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    console.log(data);
    const validItems = data?.filter(item => item.multistep1.final_status === "Valide");
    const invalidItems = data?.filter(item => item.multistep1.final_status === "En cours de correction");
    const annuléidItems = data?.filter(item => item.multistep1.final_status === "Annulé");
    const validCount = validItems.length;
    const invalidCount = invalidItems.length;
    const AnnuléCount = annuléidItems.length;
    console.log(`Valid items: ${validCount}`);
    console.log(`Invalid items: ${invalidCount}`);
    console.log(`AnnuléCount items: ${AnnuléCount}`);

    console.log(validItems);
    console.log(cumulativeSum);
    console.log(totalttc);
    if (isLoading) {
        return <Spinner animation="border" className='text-success' />;
    };

    return (
        <>
            <div className="container-fluid px-4">
                <div className='d-flex'>
                    <div className='mt-4'>
                        <button type='button' className='btns-color ' >OBLIGE</button>
                    </div>
                </div>

                <div className="row btn-on6 m-0 mt-4">
                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>Valeur Kwh Cumac<br></br>
                            <span className='bar1-head text-white'>{cumulativeSum}</span>
                        </p>
                    </div>
                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>Total TTC<br></br>
                            <span className='bar1-head text-white'>{totalttc}</span>
                        </p>
                    </div>

                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>NB de dossiers<br></br>
                            <span ><Link to={'/file-dashboard'} className='bar1-head text-white text-decoration-none'>{data.length}</Link></span>
                        </p>
                    </div>
                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>Valide<br></br>
                            <span ><Link to={'/status/1'} className='bar1-head text-white text-decoration-none'>{validCount}</Link></span>
                        </p>
                    </div>
                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>En cours de correction<br></br>
                            <span ><Link to={'/status/0'} className='bar1-head text-white text-decoration-none'>{invalidCount}</Link></span>
                        </p>
                    </div>
                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>Annulé<br></br>
                            <span ><Link to={'/status/2'} className='bar1-head text-white text-decoration-none'>{AnnuléCount}</Link></span>
                        </p>
                    </div>
                </div>
            </div>
            {/* Partnaire */}
            <div className="container-fluid px-4 py-3">
                <div className='d-flex'>
                    <div className='mt-4'>
                        <button type='button' className='btns-color ' >PARTENAIRE</button>
                    </div>
                </div>

                <div className="row btn-on6 m-0 mt-4">
                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>Valeur Kwh Cumac<br></br>
                            <span className='bar1-head text-white'>{partnaireSum}</span>
                        </p>
                    </div>
                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>Total TTC<br></br>
                            <span className='bar1-head text-white'>{partnairetotalttc}</span>
                        </p>
                    </div>

                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>NB de dossiers<br></br>
                            <span ><Link to={'/file-dashboard'} className='bar1-head text-white text-decoration-none'>{data.length}</Link></span>
                        </p>
                    </div>
                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>Valide<br></br>
                            <span ><Link to={'/status/1'} className='bar1-head text-white text-decoration-none'>{validCount}</Link></span>
                        </p>
                    </div>
                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>En cours de correction<br></br>
                            <span><Link to={'/status/0'} className='bar1-head text-white text-decoration-none'>{invalidCount}</Link></span>
                        </p>
                    </div>
                    <div className="col">
                        <p className='bar1-para mt-2 text-white9 '>Annulé<br></br>
                            <span ><Link to={'/status/2'} className='bar1-head text-white text-decoration-none'>{AnnuléCount}</Link></span>
                        </p>
                    </div>
                </div>

                <div className="row mt-5">
                    <h3 className='bar1-head'>Derniers lots de dépôts</h3>
                    {/* <div className="col d-flex right-sides black-bg mt-2 ">
                        <div className='mt-3'>
                            <p className='bar1-para '>Afficher
                                <span className="text-sizes ms-2 border-1px">50</span></p>
                        </div>
                        <div className='mt-3 ms-2'>
                            <p className='bar1-para '>éléments
                                <input type="text" className=" text-sizes  ms-2" placeholder='Recherche' size="7" ></input></p>
                        </div>
                    </div> */}
                </div>

                <div className='table-responsive mt-3 mb-3'>
                    <table ref={tableRef} className="table" id='FileDashboard'>
                        <thead>
                            <tr>
                                <th scope="col" className='bar1-para'>Service sélectionné </th>
                                <th scope="col" className='bar1-para'>Oblige </th>
                                <th scope="col" className='bar1-para'>Installateur </th>
                                <th scope="col" className='bar1-para'>Operation </th>
                                <th scope="col" className='bar1-para'>Dossier number </th>
                                <th scope="col" className='bar1-para'>Raison sociale </th>
                                <th scope="col" className='bar1-para'>Address </th>
                                <th scope="col" className='bar1-para'>CP </th>
                                <th scope="col" className='bar1-para'>Ville </th>
                                <th scope="col" className='bar1-para'>Date de facture </th>
                                <th scope="col" className='bar1-para'>Statut </th>
                                <th scope="col" className='bar1-para'>Exporté Statut </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, index) => (
                                <tr key={item?.multistep1?.id} className='bg-light'>
                                    <td className='bar1-para'>{item?.multistep1?.documents}</td>
                                    <td className='bar1-para'>{item?.multistep2?.oblige_name}</td>
                                    <td className='bar1-para'>{item?.multistep2?.partenaire_name}</td>
                                    <td className='bar1-para'>{item?.multistep1?.serviceselect}</td>
                                    {/* <td className='bar1-para'>{index + 1}</td> */}
                                    <td className='bar1-para'>{item?.multistep1?.estimate_siretinstaller}</td>
                                    <td className='bar1-para'>{item?.multistep1?.beneficiary_socialreason}</td>
                                    <td className='bar1-para'>{item?.multistep1?.beneficiary_address}</td>
                                    <td className='bar1-para'>{item?.multistep1?.beneficiary_cp}</td>
                                    <td className='bar1-para'>{item?.multistep1?.beneficiary_city}</td>
                                    <td className='bar1-para'>{item?.multistep1?.createdAt.split('T')[0]}</td>
                                    <td className='bar1-para'>{item?.multistep1?.final_status}</td>
                                    <td className='bar1-para'>{item?.multistep1?.isdataExported}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Dashboard;