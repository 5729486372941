import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BsArrowRight } from "react-icons/bs";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
const CalcultBARTH125 = () => {
    const [storedData, setStoredData] = useState(null);
    const navigate = useNavigate();
  
    // ----------------------------------------------
    useEffect(() => {
        const storedFormValues = sessionStorage.getItem('step-6');

        if (storedFormValues) {
            const parsedFormValues = JSON.parse(storedFormValues);
            initialValues.commitmentdate = parsedFormValues.commitmentdate;
            initialValues.dateofproof = parsedFormValues.dateofproof;
            initialValues.codepostal = parsedFormValues.codepostal;
            initialValues.invoicereference = parsedFormValues.invoicereference;
            initialValues.legalpersons = parsedFormValues.legalpersons;
            initialValues.addressofworks = parsedFormValues.addressofworks;
            initialValues.additionaladdress = parsedFormValues.additionaladdress;
            initialValues.city = parsedFormValues.city;
            initialValues.date_of_commitment = parsedFormValues.date_of_commitment;
            initialValues.type_installation = parsedFormValues.type_installation;
            initialValues.dwellings_served = parsedFormValues.dwellings_served;
            initialValues.heatpump_surface = parsedFormValues.heatpump_surface;
            initialValues.energy_class = parsedFormValues.energy_class;
            initialValues.exchanger_thermal = parsedFormValues.exchanger_thermal;
            initialValues.installation_type = parsedFormValues.installation_type;
            initialValues.static_exchanger = parsedFormValues.static_exchanger;
            initialValues.box_brand = parsedFormValues.box_brand;
            initialValues.casing_reference = parsedFormValues.casing_reference;
            initialValues.valve_brand = parsedFormValues.valve_brand;
            initialValues.unit_reference = parsedFormValues.unit_reference;

        }
    }, []);
    const initialValues = {
        commitmentdate: '',
        dateofproof: '',
        codepostal: '',
        invoicereference: '',
        legalpersons: '',
        addressofworks: '',
        additionaladdress: '',
        city: '',
        date_of_commitment: '',
        type_installation: '',
        dwellings_served: '',
        heatpump_surface: '',
        energy_class: '',
        exchanger_thermal: '',
        installation_type: '',
        static_exchanger: '',
        box_brand: '',
        casing_reference: '',
        valve_brand: '',
        unit_reference: '',

    };
    const same_addressget = JSON.parse(sessionStorage.getItem('step-4'));
    console.log('same_address', same_addressget);
    const same_address = same_addressget.beneficiary_address;
    const same_cp = same_addressget.beneficiary_cp;
    const same_ville = same_addressget.beneficiary_city;
    console.log('same_address', same_address);
    const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
    const handleCheckboxChange = (e, setFieldValue) => {

        const isChecked = e.target.checked;
        setIsSameAddressChecked(isChecked);
        if (isChecked) {
            setFieldValue('addressofworks', same_address);
            setFieldValue('codepostal', same_cp);
            setFieldValue('city', same_ville);
        } else {
            setFieldValue('addressofworks', initialValues.addressofworks);
            setFieldValue('codepostal', initialValues.codepostal);
            setFieldValue('city', initialValues.city);
        }
    };
    const validate = (values) => {
        const errors = {};

        if (!values.codepostal) {
            errors.codepostal = "Code postal de l'adresse des travaux requise";
        }
        if (!values.commitmentdate) {
            errors.commitmentdate = "Date d’engagement de l'opération (ex : acceptation du devis) requise";
        }
        if (!values.legalpersons) {
            errors.legalpersons = "Pour les personnes morales, nom du site des travaux ou nom de la copropriété requise";
        }
        if (!values.addressofworks) {
            errors.addressofworks = "Adresse des travaux requise";
        }
        if (!values.city) {
            errors.city = "Ville requise";
        }
        if (!values.date_of_commitment) {
            errors.date_of_commitment = "Bâtiment résidentiel existant depuis plus de 2 ans à la date d'engagement de l'opération requise";
        }
        if (!values.type_installation) {
            errors.type_installation = "Type d’installation requise";
        }
        if (!values.dwellings_served) {
            errors.dwellings_served = "Nombre de logements desservis requise";
        }
        if (!values.heatpump_surface) {
            errors.heatpump_surface = "Surface habitable (m²) requise";
        }
        if (!values.energy_class) {
            errors.energy_class = "Classe énergétique du système de ventilation selon le règlement européen (UE) n° 1254/2014 requise";
        }
        if (!values.exchanger_thermal) {
            errors.exchanger_thermal = "Efficacité thermique de l’échangeur (%) requise";
        }
        if (!values.installation_type) {
            errors.installation_type = "Type d’installation requise";
        }
        if (!values.static_exchanger) {
            errors.static_exchanger = "Efficacité de l’échangeur statique (%) requise";
        }
        if (!values.box_brand) {
            errors.box_brand = "Marque du caisson  requise";
        }
        if (!values.casing_reference) {
            errors.casing_reference = "Référence du caisson requise";
        }
        if (!values.valve_brand) {
            errors.valve_brand = "Marque des bouches d’extraction requise";
        }
        if (!values.unit_reference) {
            errors.unit_reference = "Référence des bouches d’extraction requise";
        }
        return errors;
    }
    const handleSubmit = (values) => {
        console.log({ "values": values });
        sessionStorage.setItem("step-6", JSON.stringify(values));
        navigate("/estimation-page");
    }
    // 
    const handlePrev = () => {
        navigate(-1);
    };
    useEffect(() => {
        const storedFormData = sessionStorage.getItem('step-6');
        if (storedFormData) {
            setStoredData(JSON.parse(storedFormData));
        }
    }, []);
    return (
        <>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='container-fluid px-4 py-3'>
                            <div className='row'>
                                <div className='col'>
                                    <p className='bar1-para'>A/ BAR-TH-125 (v. A36.3) : Mise en place d’un système de ventilation mécanique contrôlée double flux
                                        autoréglable en installation individuelle ou collective, ou modulé avec bouches d’extraction hygroréglables
                                        en installation individuelle seulement</p>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Date d’engagement de l'opération (ex : acceptation du devis) :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="date"
                                                name='commitmentdate'
                                                id='commitmentdate'
                                                value={values.commitmentdate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.commitmentdate && errors.commitmentdate ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.commitmentdate && errors.commitmentdate && <div className="error-message">{errors.commitmentdate}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Date de preuve de réalisation de l’opération (ex : date de la facture) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="date"
                                                name='dateofproof'
                                                id='dateofproof'
                                                value={values.dateofproof}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className='form-control1 mb-0 w-100' ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Référence de la facture  : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='invoicereference'
                                                id='invoicereference'
                                                value={values.invoicereference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className='form-control1 mb-0 w-100' ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Pour les personnes morales, nom du site des travaux ou nom de la copropriété :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                name='legalpersons'
                                                id='legalpersons'
                                                value={values.legalpersons}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.legalpersons && errors.legalpersons ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.legalpersons && errors.legalpersons && <div className="error-message">{errors.legalpersons}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12 text-left">
                                            <label className='bar1-para '>Adresse des travaux*</label> &nbsp; <input
                                                type='checkbox'
                                                id='same_address'
                                                checked={isSameAddressChecked}
                                                onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                                            /> <label htmlFor='same_address' className='bar1-para'>Même adresse</label>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type='text'
                                                name='addressofworks'
                                                id='addressofworks'
                                                value={values.addressofworks}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.addressofworks && errors.addressofworks ? 'error' : 'form-control1 mb-0 w-100'}></input>
                                            {touched.addressofworks && errors.addressofworks && <div className="error-message">{errors.addressofworks}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>Complément d’adresse :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type='text'
                                                name='additionaladdress'
                                                id='additionaladdress'
                                                value={values.additionaladdress}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.additionaladdress && errors.additionaladdress ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Code postal : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='codepostal'
                                                id='codepostal'
                                                value={values.codepostal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.codepostal && errors.codepostal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.codepostal && errors.codepostal && <div className="error-message">{errors.codepostal}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Ville :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='city'
                                                id='city'
                                                value={values.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.city && errors.city ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.city && errors.city && <div className="error-message">{errors.city}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*Bâtiment résidentiel existant depuis plus de 2 ans à la date d'engagement de l'opération :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Oui"
                                                name="date_of_commitment"
                                                id="date_of_commitment"
                                                onChange={handleChange}
                                                checked={values.date_of_commitment === "Oui"} />
                                            <span className='span-bar bar1-para'>
                                                Oui
                                            </span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Non"
                                                name="date_of_commitment"
                                                id="date_of_commitment"
                                                onChange={handleChange}
                                                checked={values.date_of_commitment === "Non"} />
                                            <span className='span-bar bar1-para '>
                                                Non
                                            </span>
                                        </label>
                                    </div>
                                    {touched.date_of_commitment && errors.date_of_commitment && <div className="error-message">{errors.date_of_commitment}</div>}
                                    <hr></hr>
                                    <p className="bar1-head  mt-4">*Type d’installation :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Collective installation"
                                                name="type_installation"
                                                id="type_installation"
                                                onChange={handleChange}
                                                checked={values.type_installation === "Collective installation"} />
                                            <span className='span-bar bar1-para'>Installation collective où plusieurs logements sont desservis par le système de ventilation.</span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Individual installation"
                                                name="type_installation"
                                                id="type_installation"
                                                onChange={handleChange}
                                                checked={values.type_installation === "Individual installation"} />
                                            <span className='span-bar bar1-para '>Installation individuelle où un seul logement est desservi par le système de ventilation.</span>
                                        </label>
                                    </div>
                                    {touched.type_installation && errors.type_installation && <div className="error-message">{errors.type_installation}</div>}
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>A ne remplir que dans le cas d’une installation collective :<br></br>*Nombre de logements desservis :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='dwellings_served'
                                                id='dwellings_served'
                                                value={values.dwellings_served}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.dwellings_served && errors.dwellings_served ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.dwellings_served && errors.dwellings_served && <div className="error-message">{errors.dwellings_served}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <p className='bar1-para'>A ne remplir que dans le cas d’une installation individuelle :</p>
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Surface habitable (m²) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='heatpump_surface'
                                                id='heatpump_surface'
                                                value={values.heatpump_surface}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.heatpump_surface && errors.heatpump_surface ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.heatpump_surface && errors.heatpump_surface && <div className="error-message">{errors.heatpump_surface}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Classe énergétique du système de ventilation selon le règlement européen (UE) n° 1254/2014 : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='energy_class'
                                                id='energy_class'
                                                value={values.energy_class}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.energy_class && errors.energy_class ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.energy_class && errors.energy_class && <div className="error-message">{errors.energy_class}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Efficacité thermique de l’échangeur (%) : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='exchanger_thermal'
                                                id='exchanger_thermal'
                                                value={values.exchanger_thermal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.exchanger_thermal && errors.exchanger_thermal ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.exchanger_thermal && errors.exchanger_thermal && <div className="error-message">{errors.exchanger_thermal}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>NB : Efficacité thermique certifiée, et mesurée selon la norme NF EN 13141-7 ou le matériel est certifié NF 205 ou équivalent.</p>
                                    <p className="bar1-head  mt-4">*Type d’installation :</p>
                                    <div className='text-start'>
                                        <label className='para-bar1 '>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Individual self-adjusting"
                                                name="installation_type"
                                                id="installation_type"
                                                onChange={handleChange}
                                                checked={values.installation_type === "Individual self-adjusting"} />
                                            <span className='span-bar bar1-para'>Individuelle autoréglable</span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Individual modulated"
                                                name="installation_type"
                                                id="installation_type"
                                                onChange={handleChange}
                                                checked={values.installation_type === "Individual modulated"} />
                                            <span className='span-bar bar1-para '>Individuelle modulée</span>
                                        </label>
                                    </div>
                                    <div className='text-start mt-2'>
                                        <label className='para-bar1 text-initical'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="Self-adjusting collective"
                                                name="installation_type"
                                                id="installation_type"
                                                onChange={handleChange}
                                                checked={values.installation_type === "Self-adjusting collective"} />
                                            <span className='span-bar bar1-para '>Collective autoréglable</span>
                                        </label>
                                    </div>
                                    {touched.installation_type && errors.installation_type && <div className="error-message">{errors.installation_type}</div>}
                                    <hr></hr>
                                    <p className='bar1-para'>Le caisson double flux est collectif.</p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Efficacité de l’échangeur statique (%) :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="number"
                                                name='static_exchanger'
                                                id='static_exchanger'
                                                value={values.static_exchanger}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.static_exchanger && errors.static_exchanger ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.static_exchanger && errors.static_exchanger && <div className="error-message">{errors.static_exchanger}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>NB : l’efficacité énergétique de l’échangeur est déterminée selon la norme NF EN 51-763 ou NF EN 308, ou le matériel est
                                        certifié Eurovent Certified Performance Echangeurs à plaques air-air (AAHE) ou Echangeur régénératif (AARE) ou équivalent.</p>
                                    <p className='bar1-para'>A ne remplir que si les marque et référence de l’équipement ne sont pas mentionnées sur la preuve de réalisation de l’opération :</p>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Marque du caisson :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='box_brand'
                                                id='box_brand'
                                                value={values.box_brand}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.box_brand && errors.box_brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.box_brand && errors.box_brand && <div className="error-message">{errors.box_brand}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Référence du caisson : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='casing_reference'
                                                id='casing_reference'
                                                value={values.casing_reference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.casing_reference && errors.casing_reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.casing_reference && errors.casing_reference && <div className="error-message">{errors.casing_reference}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Marque des bouches d’extraction :</p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='valve_brand'
                                                id='valve_brand'
                                                value={values.valve_brand}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.valve_brand && errors.valve_brand ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.valve_brand && errors.valve_brand && <div className="error-message">{errors.valve_brand}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-sm-12">
                                            <p className='bar1-head mt-3'>*Référence des bouches d’extraction : </p>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <input
                                                type="text"
                                                name='unit_reference'
                                                id='unit_reference'
                                                value={values.unit_reference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.unit_reference && errors.unit_reference ? 'error' : 'form-control1 mb-0 w-100'} ></input>
                                            {touched.unit_reference && errors.unit_reference && <div className="error-message">{errors.unit_reference}</div>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p className='bar1-para'>Pour les opérations engagées à compter du 1er janvier 2021, le professionnel réalisant l’opération est titulaire d’un signe de
                                        qualité conforme aux exigences prévues à l’article 2 du décret n° 2014-812 du 16 juillet 2014 pris pour l’application du second
                                        alinéa du 2 de l’article 200 quater du code général des impôts et du dernier alinéa du 2 du I de l’article 244 quater U du code
                                        général des impôts et des textes pris pour son application. Ce signe de qualité correspond à des travaux relevant du 8° du I de
                                        l'article 1er du décret précité</p>
                                    <div className='row mt-5 mb-2'>
                                        <div className='col text-start'>
                                            <button type='button' onClick={handlePrev} className=' btns-color'>Précédent </button>
                                        </div>
                                        <div className="col text-end">
                                            <button type='submit' className='btns-color'>Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default CalcultBARTH125